import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import AvatarNameStyled from './styled'

const AvatarName = ({ name, imageUrl, ...rest }) => {
  const divStyle = {
    backgroundImage: 'url(' + imageUrl + ')'
  }

  return (
    <AvatarNameStyled {...rest}>
      <div className="avatar-wrapper">
        <div className="image-bg" style={divStyle} />
        <p className="name">{name}</p>
      </div>
    </AvatarNameStyled>
  )
}

AvatarName.propTypes = {
  name: PropTypes.string,
  imageUrl: PropTypes.string
}

const enharce = compose()

export default enharce(AvatarName)
