import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import { QUERY_KEYS } from '../../config/constants'

async function getInboxData({ queryKey }) {
  let [_key, vacancyRequestId] = queryKey
  const response = await get(`api/vacancy/selection/${vacancyRequestId}`)
  return response
}

const queryOptions = {
  staleTime: 120000, // 2 minutes
  cacheTime: 180000 // 3 minutes
}

export default function useVacancyRequest(vacancyRequestId, options = {}) {
  return useQuery([QUERY_KEYS.VACANCY_REQUEST, vacancyRequestId], getInboxData, { ...queryOptions, ...options })
}