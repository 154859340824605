import styled from 'styled-components'
import colors from '../../../assets/colors'

const ObservationsTableStyled = styled.div`
  @media print {
    && {
      padding: 30px 24px!important;
    }
    .header-table {
      background: ${colors.white}!important;
      -webkit-print-color-adjust: exact;

      p {
        color: ${colors.white}!important;
      }
    }
    .wrap-table {
    }
    .row-add {
      display: none;
    }
  }

  && {
    border-radius: 8px;
    padding: 30px 0;

    .title-table {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: ${colors.black};
      margin-bottom: 15px;
    }

    p {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.03em;
      position: relative;
    }

    .header-table {
      background: ${colors.grey7};
      border-radius: 8px;
      padding: 10px 15px;
      height: 60px;
      align-items: center;

      p {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: inherit;
        letter-spacing: 0.03em;
        color: ${colors.grey4};
        margin-bottom: 0;
      }
    }

    .wrap-table {
      padding: 0 15px;
      background:${colors.white}
    }

    .row-table {
      border-top: 1px solid ${colors.greyAlpha25};
      padding: 10px 0;

      :first-child {
        border-top: 0;
      }

      p {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .row-add {
      p,
      .image-bg {
        opacity: 0.3;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }

      .add-button {
        margin-left: 5px;
        text-transform: inherit;
        height: 40px;
        padding: 9px 20px;
        border-radius: 4px;
        color: ${colors.white}
      }
    }
  }
`

const MailSvgStyled = styled.a`
  svg {
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    margin-left: 0.5em;
  }
`

export {ObservationsTableStyled, MailSvgStyled}
