import React, { useEffect, useMemo, useRef, useState } from 'react'
import routes from "../../../config/routes"
import {useAppContext} from "../../../context/appContext";
import { PageTitle, SpinnerIcon } from '../../atoms'
import {find, isArray, isEqual, isNumber} from "lodash";
import {
  useClientDashboards,
  useDashboardData,
  useDashboardFilters,
  // useStatisticsExtraFilters
} from "../../../api/queries/useDashboard";
import {DateRangePiker} from "../../molecules";
import Grid from "@material-ui/core/Grid";
import { Box, Dialog, DialogActions, DialogTitle, Snackbar, Tab, Tabs } from '@material-ui/core'
import Typography from "@material-ui/core/Typography";
import DashboardHomeStyled from "./styled";
import moment from "moment";
import {AuthUtils} from "../../../redux/auth";
import {
  DEFAULT_WIDGET_DND_DATA_GRID,
  MODULES,
  QUERY_KEYS,
  ROLES
} from '../../../config/constants'
import { useHistory } from 'react-router-dom'
import i18n from '../../../assets/i18n'
import DynamicWidget from "../../molecules/dynamic-widget";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import { removeDashboardWidget, removePanel, updateDashboardWidgets } from '../../../api'
import {
  manageQueriesWithPrefix,
  removeQueriesByKeys
} from '../../../api/mutations/mutationCallbacks'
import { AddCircle, DeleteForever, Edit, Info, Save } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import _map from 'lodash/map'
import Select from 'react-select'

const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardHome = ({ match }) => {
  const history = useHistory();
  let {module} = useAppContext();
  module = module || MODULES.ID_TALENT_ACQUISITION;

  const isAdminUser = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_talent_acquisition) || AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_ROOT);

  const selectedDashboard = match.params.id ? parseInt(match.params.id) : null;

  const [isDraggable, setIsDraggable] = useState(false);
  const [currentTab, setCurrentTab] = useState(selectedDashboard);
  const [startDate, setStartDate] = useState(moment().clone().startOf('month'));
  const [endDate, setEndDate] = useState(moment());
  const [startDateValue, setStartDateValue] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
  const [endDateValue, setEndDateValue] = useState(moment().format('YYYY-MM-DD'));
  const [focused, setFocused] = useState('');
  const [isClientCreatedDashboard, setIsClientCreatedDashboard] = useState(false);
  const [currentDashboardWidgetsData, setCurrentDashboardWidgetsData] = useState([]);
  const layout = useRef([]);
  const [dashboardHasChanges, setDashboardHasChanges] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });

  const [deleteWidgetDialogOpen, setDeleteWidgetDialogOpen] = useState(false);
  const [currentWidgetToRemove, setCurrentWidgetToRemove] = useState(null);
  const [backendInProgress, setBackendInProgress] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const [showNoDashboardCreatedMessage, setShowNoDashboardCreatedMessage] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});

  // TODO: Implementar cuando usemos demás módulos, que sí tienen extra filtros.
  // const { data: extraFilters, isLoading: isLoadingExtraFilters } = useStatisticsExtraFilters(module);
  const { data: dashboardsList, isLoading: isLoadingDashboardList } = useClientDashboards(module);
  const { data: dashboardData, isLoading: isLoadingDashboardData } = useDashboardData(module, currentTab, startDateValue, endDateValue, currentFilters);
  const { data: dashboardFilters, isLoading: isLoadingDashboardFilters } = useDashboardFilters(module, currentTab, startDateValue, endDateValue);


  useEffect(() => {
    setIsDraggable(isAdminUser && isClientCreatedDashboard);
  }, [isClientCreatedDashboard]);

  useEffect(() => {
    if (!isLoadingDashboardList && isArray(dashboardsList) && dashboardsList.length === 0) {
      if(isAdminUser) {
        history.push(routes.dashboardWizard.replace(':module_id', module));
      } else {
        setShowNoDashboardCreatedMessage(true);
      }
    } else if (!selectedDashboard && isArray(dashboardsList) && dashboardsList.length > 0) {
      setCurrentTab(dashboardsList[0].dashboard.id);
    }
  }, [dashboardsList]);

  useEffect(() => {
    if (currentTab && isArray(dashboardsList) && dashboardsList.length > 0) {
      let currentDashboard = find(dashboardsList, {dashboard: {id: currentTab}});
      if(currentDashboard) {
        const isCustomDashboard = currentDashboard.dashboard && currentDashboard.dashboard.is_custom;
        // setDashboardFilters(isCustomDashboard ? [] : (currentDashboard.dashboard.filters || []))
        setIsClientCreatedDashboard(isCustomDashboard);
        setDashboardHasChanges(false);
        setAlert({ message: '', severity: '' });
        setShowAlert(false);
        layout.current = [];
      } else {
        setCurrentTab(null);
        history.push(routes.dashboardHome.replace(':module_id', module).replace('/:id?', ''));
      }
    }

    if(currentTab) {
      // Actualizamos la URL con el ID del dashboard actual
      history.push(routes.dashboardHome.replace(':module_id', module).replace(':id', currentTab));
    }
  }, [currentTab, dashboardsList]);

  useEffect(() => {
    if (isAdminUser && !isLoadingDashboardData && isArray(dashboardData) && dashboardData.length === 0) {
      history.push(routes.dashboardEdit.replace(':module_id', module).replace(':id', currentTab));
    }
    if (isArray(dashboardData) && dashboardData.length > 0 && currentDashboardWidgetsData !== dashboardData) {
      setCurrentDashboardWidgetsData(dashboardData);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      if (!dashboardHasChanges) {
        const timer = setTimeout(() => {
          setShowAlert(false);
          setAlert({ message: '', severity: '' });
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
  }, [alert]);

  const handleClickRemove = () => {
    setRemoveDialogOpen(true);
  }
  const handleCloseRemoveDialog = () => {
    setRemoveDialogOpen(false);
  }

  function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }} style={{padding: "0px"}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const calculateNewPosition = (previousDataGrid, currentSizes, maxCols) => {
    return {
      ...currentSizes,
      x: previousDataGrid.x + previousDataGrid.w + currentSizes.w <= maxCols ? previousDataGrid.x + previousDataGrid.w : 0,
      y: previousDataGrid.x + previousDataGrid.w + currentSizes.w <= maxCols ? previousDataGrid.y : previousDataGrid.y + currentSizes.h,
    };
  }

  const handleConfirmDeleteWidget = () => {
    if(backendInProgress) {
      return;
    }

    setBackendInProgress(true);

    const dashboardId = currentTab;
    const widgetId = currentWidgetToRemove;
    removeDashboardWidget(module, dashboardId, widgetId).then((res) => {
      if (res && res.success) {

        manageQueriesWithPrefix([
          `${module}:${QUERY_KEYS.DASHBOARDS}:data:${currentTab}`,
          `${module}:${QUERY_KEYS.DASHBOARDS}:widgets:${currentTab}`
        ]);
        setBackendInProgress(false);
        setDeleteWidgetDialogOpen(false);
        setCurrentWidgetToRemove(null);
        setAlert({message: i18n.dashboard.home.successfully_deleted, severity: 'success'})
      }
    }).catch((e) => {
      console.error(e)
      setAlert({message: i18n.dashboard.home.error_deleting, severity: 'error'})

      setBackendInProgress(false);
      setDeleteWidgetDialogOpen(false);
    });
  }

  const handleClickDeleteWidget = (widgetId) => {
    setDeleteWidgetDialogOpen(true);
    if(widgetId) {
      setCurrentWidgetToRemove(widgetId);
    }
  }
  const handleCloseDeleteWidgetDialog = () => {
    setDeleteWidgetDialogOpen(false);
  }

  const renderWidgets = useMemo(() => {
    let maxCols = 12;
    let previousPosition = {w: 0, h: 0, x: 0, y: 0};
    let refLayout = [];
    return currentDashboardWidgetsData.map((widget, idx) => {
      let dataGrid;
      if (layout.current && layout.current.length > 0) {
        layout.current.map(row => {
          if (row.i === widget.id || row.i === `${widget.id}`) {
            dataGrid = row;
          }
        });
      }
      if (!dataGrid) {
        if (widget.params.dataGrid) {
          dataGrid = widget.params.dataGrid;
          let dataGridKeys = Object.keys(dataGrid);
          dataGridKeys.map(key => {
            if (!isNumber(dataGrid[key])) dataGrid[key] = parseInt(dataGrid[key]);
          });
          //? Realizamos esta comprobación para los widget que necesitamos un tamaño diferente al que tenemos por defecto.
          if (dataGrid.x === undefined || dataGrid.y === undefined) {
            dataGrid = calculateNewPosition(previousPosition, dataGrid, maxCols);
          }
        } else {
          dataGrid = DEFAULT_WIDGET_DND_DATA_GRID[widget.front_component.name];
          if(!dataGrid) {
            console.error(`dataGrid default values not found for ${widget.front_component.name}`)
          }
          dataGrid = calculateNewPosition(previousPosition, dataGrid, maxCols);
        }
        if (dataGrid.i === undefined || dataGrid.id === undefined) dataGrid.i = widget.id;
        refLayout.push(dataGrid);
        previousPosition = dataGrid;
      }
      if (refLayout.length > 0 && idx === currentDashboardWidgetsData.length - 1) {
        layout.current = refLayout;
      }

      return (
        <div
          key={widget.id}
          data-grid={dataGrid}
          style={{height: `${dataGrid.h * 10 + (20)}!important`}}
        >
          <DynamicWidget
            isDraggable={isDraggable}
            handleDelete={() => handleClickDeleteWidget(widget.id)} widgetId={widget.id}
            name={widget.name} widget={widget.front_component.name} params={widget.params} />
        </div>
      )
  })}, [currentDashboardWidgetsData]);

  const handleDragAndResizeStop = (newLayout, oldItem, newItem) => {
    if (!isEqual(oldItem, newItem)) {
      layout.current = newLayout;
      setDashboardHasChanges(true);
      setShowAlert(true);
      setAlert({message: i18n.dashboard.home.changes_not_saved, severity: 'info'})
    }
  }

  const renderDashboardWidgets = useMemo(() => {
    if(!dashboardsList || !dashboardsList.length) {
      return <></>;
    }

    return dashboardsList.map(item => (
      <TabPanel key={item.dashboard.id} value={currentTab} index={item.dashboard.id}>
        {!isLoadingDashboardData && dashboardData && currentDashboardWidgetsData && currentDashboardWidgetsData.length > 0 &&
        <Grid container spacing={4}>
          <ResponsiveGridLayout
            draggableHandle=".header-stat"
            draggableCancel=".RGL-widgetDeleteButton"
            className="layout"
            isDraggable={isDraggable}
            isResizable={false}
            width={"100%"}
            rowHeight={50}
            margin={[10, 10]}
            preventCollision={false}
            useCSSTransforms={false}
            verticalCompact={true}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
            onDragStop={handleDragAndResizeStop}
            onResizeStop={handleDragAndResizeStop}
          >
            {renderWidgets}
          </ResponsiveGridLayout>
        </Grid>
        }
      </TabPanel>
    ))
  }, [isDraggable, isLoadingDashboardData, dashboardData, dashboardsList, currentDashboardWidgetsData, isAdminUser]);

  const saveCurrentLayout = () => {
    let widgets = [];

    setDashboardHasChanges(false);
    setShowAlert(false);
    setAlert({message: '', severity: ''});

    layout.current.map(row => {
      widgets.push({id: parseInt(row.i), params: {dataGrid: {x: row.x, y: row.y, w: row.w, h: row.h, minW: row.minW, maxW: row.maxW, minH: row.minH, maxH: row.maxH}}});
    });

    if (widgets.length > 0) {
      updateDashboardWidgets(widgets, module, currentTab)
        .then(res => {
          setShowAlert(true);
          setAlert({message: i18n.dashboard.home.successfully_saved, severity: 'success'});
          manageQueriesWithPrefix([`${module}:${QUERY_KEYS.DASHBOARDS}:data:${currentTab}`]);
        }).catch(e => {
          setShowAlert(true);
          setAlert({message: i18n.dashboard.home.error_saving, severity: 'error'});
      });
    }
  }

  const deletePreconfiguredDashboard = () => {
    removePanel(module, selectedDashboard).then(() => {
      setRemoveDialogOpen(false);
      setShowAlert(true);
      setAlert({message: i18n.dashboard.home.removeDashboardSuccess, severity: "success"});
    }).catch(() => {
      setRemoveDialogOpen(false);
      setShowAlert(true);
      setAlert({message: i18n.dashboard.home.removeDashboardError, severity: "error"});
    });
    setTimeout(async () => {
      setShowAlert(false);
      let currentDashboardList = dashboardsList;
      await removeQueriesByKeys([`${module}:${QUERY_KEYS.DASHBOARDS}`]);

      setCurrentTab((prevTab) => {
        if (!currentDashboardList || currentDashboardList.length < 2) {
          return null;
        }
        if (currentDashboardList[0].dashboard.id !== prevTab) {
          return currentDashboardList[0].dashboard.id;
        }
        if (currentDashboardList.length > 1) {
          return currentDashboardList[1].dashboard.id;
        }
        return null;
      });
    }, 3000);
  }

  const addFilter = (filter, e) => {
    const activeFilters = currentFilters;
    if(e && e.value) {
      activeFilters[filter.remoteName] = e.value;
    }
    else {
      delete activeFilters[filter.remoteName];
    }

    setCurrentFilters({...activeFilters });
  }

  const getDefaultValuesForFilters = (filter) => {
    if (currentFilters && currentFilters[filter.remoteName]) {
      return filter.options.filter(opt => {
        if(opt.id == currentFilters[filter.remoteName]) {
          return {
            value: opt.id,
            label: opt.label,
          }
        }
      })
    }
    return null;
  }

  return (
    isLoadingDashboardList ? <SpinnerIcon/>
      : <DashboardHomeStyled>
        <Grid container className="title-container">
          <Grid item xs={12} sm={12} className="title">
            <PageTitle title={i18n.dashboard.home.title} />
            <div style={{display: "flex", gap: 16}}>
              {isAdminUser && isClientCreatedDashboard && dashboardHasChanges &&
                <Button
                  onClick={saveCurrentLayout}
                  variant="contained"
                  startIcon={<Save/>}
                  color={'primary'}
                >
                  {i18n.dashboard.home.saveDashboard}
                </Button>
              }
              {isAdminUser &&
                <Button
                  onClick={() => {history.push(routes.dashboardWizard.replace(':module_id', module))}}
                  variant="outlined"
                  startIcon={<AddCircle/>}
                  color={'primary'}
                >
                  {i18n.dashboard.home.create}
                </Button>
              }
              {isAdminUser && !isClientCreatedDashboard &&
                <Button
                  onClick={handleClickRemove}
                  variant="contained"
                  startIcon={<DeleteForever/>}
                  color={'primary'}
                >
                  {i18n.dashboard.home.removeDashboard}
                </Button>
              }
              {isAdminUser && isClientCreatedDashboard &&
                <Button
                  onClick={() => {history.push(routes.dashboardEdit.replace(':module_id', module).replace(':id', currentTab))}}
                  variant="contained"
                  startIcon={<Edit/>}
                  color={'primary'}
                >
                  {i18n.dashboard.home.editDashboard}
                </Button>
              }

              <Dialog
                open={deleteWidgetDialogOpen}
                onClose={handleCloseDeleteWidgetDialog}
              >
                <DialogTitle>{i18n.dashboard.home.deleteWidgetDialogTitle}</DialogTitle>
                <DialogActions>
                  <Button onClick={handleCloseDeleteWidgetDialog} color="primary">
                    {i18n.dashboard.home.deleteCancel}
                  </Button>
                  <Button onClick={handleConfirmDeleteWidget} color="primary" autoFocus disabled={backendInProgress}>
                    {i18n.dashboard.home.deleteConfirm}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={removeDialogOpen}
                onClose={handleCloseRemoveDialog}
              >
                <DialogTitle>{i18n.dashboard.edit.removeDialogTitle}</DialogTitle>
                <DialogActions>
                  <Button onClick={handleCloseRemoveDialog} color="primary">
                    {i18n.dashboard.edit.cancel}
                  </Button>
                  <Button onClick={deletePreconfiguredDashboard} color="primary" autoFocus disabled={backendInProgress}>
                    {i18n.dashboard.edit.removeConfirm}
                  </Button>
                </DialogActions>
              </Dialog>

            </div>
          </Grid>
        </Grid>

        {showNoDashboardCreatedMessage ?
          <div className="no-dashboards-created">
            <Info fontSize="large" />
            <p>{i18n.dashboard.home.no_dashboards_created}</p>
          </div>

          :

          (!dashboardsList || isLoadingDashboardList ?
              <SpinnerIcon/>
              :
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ padding: '8px 0' }}>
                  <Box sx={{ m: 2 }} style={{ margin: '16px 0' }}>
                    {/* Tabs */}
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={currentTab} onChange={handleTabChange} aria-label="client dashboard tabs"
                            className="tabpanel-tabs">
                        {dashboardsList.map(item => {
                          return <Tab key={item.dashboard.id} label={i18n.dashboard.wizard.name.hasOwnProperty(item.dashboard.name) ? i18n.dashboard.wizard.name[item.dashboard.name] : item.dashboard.name}
                                      className={'documentsInfoTitle tab-item'} {...a11yProps(item.dashboard.id)} />
                        })}
                      </Tabs>
                    </Box>
                    <Grid item xs={12} sm={12} className="filter-n-edit">
                      <div className="container-filters">
                        {!isLoadingDashboardFilters && dashboardFilters && _map(dashboardFilters, filter => {
                          if (filter.name) {
                            return <Select
                              isMulti={false}
                              isSearchable={true}
                              isClearable={true}
                              key={filter.name}
                              placeholder={i18n.dashboard.filters[filter.name] || filter.name}
                              options={_map(filter.options, option => {
                                if(option instanceof String){
                                  return {value: option, label:option}
                                }
                                return {value: option.id, label:option.label};
                              })}
                              onChange={e => addFilter(filter, e)}
                              defaultValue={getDefaultValuesForFilters(filter)}
                              styles={{
                                control: base => ({
                                  ...base,
                                  minWidth: filter.minWidth || 200,
                                  maxWidth: 480,
                                  textAlign: 'left'
                                }),
                                menuList: base => ({
                                  textAlign: 'left',
                                  ...base,
                                })
                              }}
                            />}}
                        )}

                        <DateRangePiker
                          startDate={startDate}
                          onStartDateChange={value => {setStartDate(value)}}
                          endDate={endDate}
                          onEndDateChange={value => {setEndDate(value)}}
                          onFocusChange={({ focused: value }) => {setFocused(value)}}
                          focused={focused}
                          isFetching={isLoadingDashboardData}
                          onSubmit={() => {
                            let from = moment.isMoment(startDate) ? startDate : moment().clone().startOf('month')
                            let to = moment.isMoment(endDate) ? endDate : moment()
                            setStartDateValue(from.format('YYYY-MM-DD'))
                            setEndDateValue(to.format('YYYY-MM-DD'))
                          }}
                        />
                      </div>
                    </Grid>

                    {renderDashboardWidgets}
                  </Box>
                </Grid>
              </Grid>
          )
        }

        <Snackbar
          open={showAlert}
          key="custom-alert"
          className={alert.severity}
          message={alert.message}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        </Snackbar>
      </DashboardHomeStyled>
  )
}

export default DashboardHome
