import React, { useRef, useState } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import CardEvaluativeTestsStyled from './styled'
import Grid from '@material-ui/core/Grid'
import { IconMenuEvaluativeTests, CalendarIconEvaluativeTests, IconListEvalutiveTests, IconEdit, IconDuplicate, IconDeleteTrash } from '../../atoms'
import moment from 'moment'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, LinearProgress } from '@material-ui/core'
import { ListAlt } from '@material-ui/icons'
import { checkCsvCandidates } from '../../../api'
import CsvUploader from '../csv-uploader';
import { useAppContext } from '../../../context/appContext'
import { ModalImportEvaluativeTestsCandidates } from '../index'

const useStyles = makeStyles(() => ({
  customMenuItemText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '600',
    marginLeft: '10px',
  },
}));

const CardEvaluativeTests = ({ i18n, title, pretitle, rangeDate, quantityQuestions, onEdit, onDuplicate, onDelete, testId, statusCard, isInactive
}) => {

  const [uploadCsvInProgress, setUploadCsvInProgress] = useState(false);
  const [showSendNotificationModal, setShowSendNotificationModal] = useState(false);
  const [uploadResult, setUploadResult] = useState([]);
  const [currentForm, setCurrentForm] = useState(null);
  const csvUploaderTriggerRef = useRef(null);
  const {module} = useAppContext();

  const allowCsvUpload = true;

  const onAddCandidatesCsv = function(e) {
    if(csvUploaderTriggerRef.current) {
      csvUploaderTriggerRef.current();
    }
  }
  const setCsvUploaderTrigger = (trigger) => {
    csvUploaderTriggerRef.current = trigger;
  };
  const uploadCsv = function(form) {
    setCurrentForm(form);
    setUploadCsvInProgress(true);
    return checkCsvCandidates(testId, module, form);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const formattedRangeDate = rangeDate.split(' - ').map(date => moment(date).format('DD/MM/YYYY')).join(' - ');
  const classes = useStyles();

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const uploadCsvFinished = function(result) {
    setUploadCsvInProgress(false);
    setShowSendNotificationModal(true);
    setUploadResult(result);

    handleCloseMenu();
  }

  return (
    <CardEvaluativeTestsStyled>
      {uploadCsvInProgress ? <LinearProgress /> : <></>}

      <Grid container className="card-container">
        <Grid item className="card-header">
          <Grid item className="card-header-text">
            <span className="card-pretitle">{pretitle}</span>
            <span className="card-title">{title}</span>
          </Grid>
          <div className="container-icon" onClick={handleClickMenu}>  <IconMenuEvaluativeTests /></div>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={() => onEdit(testId)}>
            <IconEdit />
            <span className={classes.customMenuItemText}>{i18n.evaluativeTests.menuEdit}</span>
          </MenuItem>
          {allowCsvUpload &&
            <MenuItem onClick={() => onAddCandidatesCsv(testId)}>
              <CsvUploader
                uploadFn={uploadCsv}
                callbackFn={uploadCsvFinished}
                triggerClick={setCsvUploaderTrigger}
                showAlert={false}
              />
              <ListAlt />
              <span className={classes.customMenuItemText}>{i18n.evaluativeTests.menuAddCsv}</span>
            </MenuItem>
          }
          <MenuItem onClick={() => {
            onDuplicate();
            handleCloseMenu();
          }}>
            <IconDuplicate />
            <span className={classes.customMenuItemText}>{i18n.evaluativeTests.menuDuplicate}</span>
          </MenuItem>
          <MenuItem onClick={() => {
            onDelete();
            handleCloseMenu();
          }}>
            <IconDeleteTrash />
            <span className={classes.customMenuItemText}>{i18n.evaluativeTests.menuDelete}</span>
          </MenuItem>
        </Menu>

        <Grid item className="card-content">
          <div className="card-icon"> <CalendarIconEvaluativeTests /></div>
          <span className="card-text">{formattedRangeDate}</span>
        </Grid>
        <Grid item className="card-content">
          <div className="card-icon"><IconListEvalutiveTests /></div>
          <span className="card-text">{quantityQuestions}</span>
          {isInactive ? (
            <Chip className="chip-custom" label={statusCard}></Chip>
          ) : null}
        </Grid>
      </Grid>

      {showSendNotificationModal && <ModalImportEvaluativeTestsCandidates
        evaluativeConfigId={testId}
        form={currentForm}
        isModalOpen={showSendNotificationModal}
        handleClose={() => setShowSendNotificationModal(false)}
        uploadResult={uploadResult}/>}
    </CardEvaluativeTestsStyled>
  )
}

const enharce = compose(withI18n)

export default enharce(CardEvaluativeTests)
