import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
//import {ProcessesActions, ProcessesOperations} from '../../../redux/processes'
//import {SaveSearchesActions, SaveSearchesOperations} from '../../../redux/savesearches'
//import {CitationsOperations} from "../../../redux/citations";
//import {operations as ProfileProcessesOperations} from "../../../redux/profileProcesses";
//import {InboxActions} from '../../../redux/inbox'

const mapStateToProps = state => {
  // console.log('logg state redux', state)
  return {
    // data: state.processes.dataInbox.data,
    // ready: state.processes.ready,
    // initialDataInbox: state.processes.initialDataInbox,
    // tablePage: state.processes.page,
    // tablePerPage: state.processes.perPage,
    // tableSort: state.processes.sort,
    // dataFields: state.processes.dataFields,
    // reportFields: state.processes.reportFields,
    // total: state.processes.dataInbox.total,
    // statusTypes: state.processes.statusTypes,
    // tagsTypes: state.processes.tagsTypes,
    // isFetching: state.processes.isFetching,
    // inboxModule: state.processes.module,
    client: state.auth.client,
    auth: state.auth.data,
    user_email: state.auth.email,
    // rowsSelected: state.inbox.rowsSelected
  }
}

const mapDispatchToProps = () => {
  return {
    // initInbox: (filters, range, sort, module, filtersGroups) => dispatch(ProcessesOperations.initInbox(filters, range, sort, module, filtersGroups)),
    // fetchProcesses: (filters, range, sort, module, updateInitData) => dispatch(ProcessesOperations.fetchItemsInbox(filters, range, sort, module, updateInitData)),
    // fetchFilterGroupInbox: (filters, module) => dispatch(ProcessesOperations.fetchFilterGroupInbox(filters, module)),
    // postFavorites: (ids, isFavorite, queryParams, module) => dispatch(ProcessesOperations.postFavorite(ids, isFavorite, queryParams, module)),
    // requireDocumentation: (ids, queryParams, module) => dispatch(ProcessesOperations.requireDoc(ids, queryParams, module)),
    // postCitations: (ids, data) => dispatch(CitationsOperations.postCitations(ids, data)),
    // markAsRead: (ids, queryParams, module) => dispatch(ProcessesOperations.markAsRead(ids, queryParams, module)),
    // unmarkAsRead: (ids, queryParams, module) => dispatch(ProcessesOperations.unmarkAsRead(ids, queryParams, module)),
    // changeStatus: (ids, status, queryParams, date, module, showFilterGroup) => dispatch(ProcessesOperations.changeStatus(ids, status, queryParams, date, module, showFilterGroup)),
    // changeTags: (ids, tags, queryParams, module, showFilterGroup) => dispatch(ProcessesOperations.changeTags(ids, tags, queryParams, module, showFilterGroup)),
    // setFilterGroup: selected => dispatch(SaveSearchesActions.setFilterGroup(selected)),
    // exportCsv: ( fields, filters, sort, module ) => dispatch(ProcessesOperations.exportCsv( fields, filters, sort, module )),
    // tableSetPage: ( page ) => dispatch(ProcessesActions.setPage( page )),
    // tableSetSort: ( sort ) => dispatch(ProcessesActions.setSort( sort )),
    // tableSetPerPage: ( perPage ) => dispatch(ProcessesActions.setPerPage( perPage )),
    // setReady: ( ready ) => dispatch(ProcessesActions.setReady( ready )),
    // filtersClear: () => dispatch(SaveSearchesOperations.filtersClear()),
    // sendEmailCustom: (id, subject, message, module) => dispatch(ProfileProcessesOperations.sendEmailsCustom(id, subject, message, module)),
    // setRowsSelected: (rowsSelected) => dispatch(InboxActions.setRowsSelected(rowsSelected))
  }
}

const enharce = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withI18n)

export default enharce(View)
