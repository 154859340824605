import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import RichTextEditorStyled from './styled'

const RichTextEditor = ({ value, onChange, placeholder }) => {
  return (
    <RichTextEditorStyled >
      <ReactQuill
      theme="snow"
      className='custom-editor'
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
    </RichTextEditorStyled>
  );
};

RichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default RichTextEditor;
