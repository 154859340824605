export const SET_DATA = 'citations/SET_DATA'
export const SET_IS_FETCHING = 'citations/SET_IS_FETCHING'
export const SET_RESPONSIBLE = 'citations/SET_RESPONSIBLE'
export const SET_FILTERS = 'citations/SET_FILTERS'
export const SET_PAGE = 'citations/SET_PAGE'
export const SET_SORT = 'citations/SET_SORT'
export const SET_PAGE_SIZE = 'citations/SET_PAGE_SIZE'
export const SET_REPORT_FIELDS = 'citations/SET_REPORT_FIELDS'
export const SET_ROWS_SELECTED = 'inbox/SET_ROWS_SELECTED'
export const SET_EDIT_COUNTER = 'inbox/SET_EDIT_COUNTER'
export const SET_WHATSAPP_COUNTER = 'citations/SET_WHATSAPP_COUNTER'
export const SET_MEETING_DETAILS = 'citations/SET_MEETING_DETAILS'
