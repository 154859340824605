import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const icon = props => (
  <SvgIcon {...props} width="12" height="11" viewBox="0 0 12 11" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.1425 5.84228C2.41148 6.57329 2.41148 7.7585 3.1425 8.48951C3.87277 9.21978 5.05631 9.22052 5.7875 8.49173L9.36935 4.79423L9.37169 4.7919C9.49431 4.66928 9.61999 4.40776 9.64075 4.09643C9.66081 3.79552 9.57933 3.50795 9.37169 3.30031C9.10419 3.03281 8.82942 2.8924 8.57223 2.87526C8.32818 2.85899 8.02383 2.94859 7.67211 3.30031L4.34434 6.62809C4.19093 6.78149 4.10999 6.9546 4.09357 7.09702C4.07848 7.22789 4.11461 7.33501 4.2118 7.42197C4.23062 7.43881 4.24641 7.45307 4.26093 7.46618C4.27991 7.48332 4.29673 7.49851 4.3153 7.51493C4.32928 7.52729 4.34144 7.53782 4.35219 7.54687C4.38076 7.5244 4.41988 7.49014 4.47143 7.43863L7.49816 4.37759C7.65969 4.21423 7.92306 4.21274 8.08642 4.37427C8.24978 4.5358 8.25127 4.79917 8.08974 4.96253L5.06216 8.02444L5.06051 8.0261C4.85504 8.23157 4.58614 8.45308 4.2441 8.40029C4.08496 8.37573 3.96352 8.29763 3.88211 8.23599C3.81879 8.18805 3.75095 8.12673 3.69382 8.07509C3.68096 8.06346 3.66865 8.05233 3.65705 8.04196C3.33827 7.75671 3.22431 7.37281 3.2671 7.00172C3.30856 6.64217 3.4935 6.30238 3.75606 6.03981L7.08384 2.71203C7.56407 2.2318 8.09166 2.00943 8.62757 2.04516C9.15032 2.08001 9.6035 2.35557 9.95996 2.71203C10.3763 3.12835 10.5028 3.67272 10.4708 4.15177C10.4397 4.61902 10.2544 5.0841 9.96252 5.3776L6.38034 9.07545L6.378 9.07778C5.32209 10.1337 3.61013 10.1337 2.55422 9.07778C1.49832 8.02188 1.49832 6.30991 2.55422 5.254L6.66787 1.14036C6.83032 0.977912 7.0937 0.977912 7.25614 1.14036C7.41859 1.30281 7.41859 1.56619 7.25614 1.72863L3.1425 5.84228Z"
    />
  </SvgIcon>
)

export default icon
