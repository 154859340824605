import React, { useMemo } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import CircleStatisticStyled from './styled'
import { ReactComponent as UserIcon } from '../../../assets/images/stats.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import i18n from '../../../assets/i18n'

const CircleStatistic = ({ title = null, overrideTitle = null, value, data = null, percentText, whatsappCounter = false, size = 100, thickness = 1 }) => {
  if(typeof value === 'undefined') {
    value = data;
  }

  if (value < 0) value = 0

  const chart = useMemo(() => {
    if(!percentText && !isNaN(value)) {
      try {
        value = Intl.NumberFormat("es-ES").format(value);
      } catch(e) {
        console.warn(`Error al procesar valor ${value}`)
      }
    }
    return (
      <div className="chart-wrapper">
        <CircularProgress variant="static" className={'top-circle'} size={size} value={100} thickness={thickness} />
        <CircularProgress variant="static" className={'bottom-circle'} size={size} value={percentText ? value : 100} thickness={thickness} />
        <p className="value-percent">
          {value}
          {percentText}
        </p>
      </div>)
  }, [value, percentText])

  return (
    <CircleStatisticStyled whatsappCounter={whatsappCounter}>
      {overrideTitle}
      {!overrideTitle && title &&
        <div className="header-stat">
          {<UserIcon />}
          <p>{title}</p>
        </div>
      }
      {chart}
    </CircleStatisticStyled>
  )
}

CircleStatistic.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  percentText: PropTypes.string
}

CircleStatistic.defaultProps = {
  title: i18n.statistics
}

const enharce = compose(withI18n)

export default enharce(CircleStatistic)
