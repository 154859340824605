import React from 'react'
import { PageTitle } from '../../atoms'
import { Grid } from '@material-ui/core'
import SuperAdministratorManualStyled from './styled'

const SuperAdministratorManual = ({ i18n }) => {

  return (
    <SuperAdministratorManualStyled>
      <Grid container className="title-container">
        <Grid item xs={12} sm={6} className="title">
          <PageTitle title={i18n.superadministratorManual.title} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
          <iframe 
          className="iframe-container" 
          title="Manual Administrador ATS" 
          src="https://scribehow.com/page-embed/Manual_Administrador_ATS__Qmz9dO5NR8WiFHvvSYaRsg?removeLogo=true">
          </iframe>       
      </Grid>
    </SuperAdministratorManualStyled>
  )
}

export default SuperAdministratorManual
