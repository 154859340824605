import React from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import { FilterButton } from '../../atoms'
import PeopleTableFiltersStyled from './styled'
import StatusGroupType from '../../../models/status-group-type'

const PeopleTableFilters = ({ i18n, values, selected, onFilterSelected }) =>{
  let totalToManage = 0
  let totalInProgress = 0
  let totalFinalists = 0
  let statusTotal = 0
  
  _.map(StatusGroupType.TO_MANAGE.status, (status) => {
    const value = _.find(values, { id: status })
    if(value && value.total){
      totalToManage = totalToManage + value.total
    }
  })
  _.map(StatusGroupType.IN_PROGRESS.status, (status) => {
    const value = _.find(values, { id: status })
    if(value && value.total){
      totalInProgress = totalInProgress + value.total
    }
  })
  _.map(StatusGroupType.FINALISTS.status, (status) => {
    const value = _.find(values, { id: status })
    if(value && value.total){
      totalFinalists = totalFinalists + value.total
    }
  })

  statusTotal = totalToManage + totalInProgress + totalFinalists

  return (
    <PeopleTableFiltersStyled>
    <FilterButton
      label={i18n.peopleTableFilters.toManage}
      total={statusTotal}
      value={totalToManage || 0}
      isSelected={selected === StatusGroupType.TO_MANAGE.id}
      onClick={() => onFilterSelected(StatusGroupType.TO_MANAGE.id)}
      disabled={!totalToManage && totalToManage < 1}
    />
    <FilterButton
      label={i18n.peopleTableFilters.inProgress}
      total={statusTotal}
      value={totalInProgress || 0}
      isSelected={selected === StatusGroupType.IN_PROGRESS.id}
      onClick={() => onFilterSelected(StatusGroupType.IN_PROGRESS.id)}
      disabled={!totalInProgress && totalInProgress < 1}
    />
    <FilterButton
      label={i18n.peopleTableFilters.finalists}
      total={statusTotal}
      value={totalFinalists || 0}
      isSelected={selected === StatusGroupType.FINALISTS.id}
      onClick={() => onFilterSelected(StatusGroupType.FINALISTS.id)}
      disabled={!totalFinalists && totalFinalists < 1}
    />
    {/* <FilterButton
      label={i18n.peopleTableFilters.others}
      total={total}
      value={_.get(values, `[${StatusGroupType.OTHERS.id}]`)}
      isSelected={selected === StatusGroupType.OTHERS.id}
      onClick={() => onFilterSelected(StatusGroupType.OTHERS.id)}
      disabled = {_.findIndex(disabledGroups, id => id===StatusGroupType.OTHERS.id)>=0}
    /> */}
  </PeopleTableFiltersStyled>
  )
}

PeopleTableFilters.propTypes = {
  i18n: PropTypes.object.isRequired,
  total: PropTypes.number,
  values: PropTypes.object,
  selected: PropTypes.number,
  onFilterSelected: PropTypes.func,
  disabledGroups: PropTypes.array
}

PeopleTableFilters.defaultProps = {
  total: 0,
  values: null,
  selected: -1,
  onFilterSelected: () => {}
}

const enharce = compose(withI18n)

export default enharce(PeopleTableFilters)
