import {useQuery} from '@tanstack/react-query';
import {getList} from '../utils'
import * as FiltersUtils from "../../utils/filters";
import {MODULES, QUERY_KEYS} from "../../config/constants";
import {addItemCount} from "../index";
import _ from "lodash";


async function getCitationsData({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  let [_key, {module, filtersOriginal, rangeArr, sort}] = queryKey;
  if (!module || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;

  let filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filtersOriginal)

  const sortField = _.get(sort, '[0]', '')

  if (sortField === 'availability') {
    sort[0] = 'availability.position'
  } else if (sortField === 'not_interested') {
    sort[0] = 'not_interested.position'
  }

  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  let range = rangeArr ? encodeURI(JSON.stringify(rangeArr)) : ''

  let ep = `/api/citations/v2?filter=${filters}&range=${range}`
  if (_.get(sort, '[0]') && _.get(sort, '[1]')) {
    sort = sort ? encodeURI(JSON.stringify(sort)) : ''
    ep = `${ep}&sort=${sort}`
  }
  return getList(ep).then(({total, data}) => {
    data = addItemCount(data, rangeArr)
    return {data, total}
  });
}

export default function useCitations(module, filtersOriginal, rangeArr, sort, options = {}) {
  options = {...options, enabled: module !== null && module !== undefined}
  return useQuery([`${module}:${QUERY_KEYS.CITATIONS}`, {module, filtersOriginal, rangeArr, sort}], getCitationsData, options);
}