import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import {
  actions as ProfileProcessesActions,
  operations as ProfileProcessesOperations
} from '../../../redux/profileProcesses'
import {ProcessesActions, ProcessesOperations} from '../../../redux/processes'

const mapStateToProps = state => ({
  data: state.profileProcesses.data,
  isFetching: state.profileProcesses.isFetching,
  statusTypes: state.processes.statusTypes,
  dataInboxRows: state.processes.dataInbox,
  tablePage: state.processes.page,
  tablePerPage: state.processes.perPage,
})

const mapDispatchToProps = dispatch => {
  return {
    setData: data => dispatch(ProfileProcessesActions.setData(data)),
    changeStatus: (id, status, module) => dispatch(ProfileProcessesOperations.changeStatus(id, status, module)),
    addFav: (id, isFAv, module) => dispatch(ProfileProcessesOperations.addFav(id, isFAv, module)),
    sendEmailCustom: (id, subject, message, module) => dispatch(ProfileProcessesOperations.sendEmailCustom(id, subject, message, module)),
    fetchStatusTypes: (module) => dispatch(ProcessesOperations.fetchStatusTypes(module)),
    deleteProcess: (id, module) => dispatch(ProfileProcessesOperations.deleteProcess(id, module)),
    tableSetPage: ( page ) => dispatch(ProcessesActions.setPage(page)),
  }
}

const enharce = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withI18n)

export default enharce(View)
