import styled from 'styled-components'

const EvaluativeTestsStyled = styled.div`
  .title-container {
    padding: 30px 60px;
    display: flex;
    flex-wrap: wrap;
  }

  .button-custom {
    border-radius: 10px;
    color: #ffffff;
  }
  
  .buttons-wrapper {
    text-align: right;
  }

  .date-container {
    padding: 30px 60px;
  }

  .description-section {
    color: #7a808d;
    font-size: 14px;
    font-weight: 400;
  }

  .cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    place-content: start;
    gap: 20px;
    padding: 70px 70px;
  }

  .MuiTypography-body1 {
    font-family: "Poppins";
    font-weight: 500;
  }

  .alert-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
  }

`
export default EvaluativeTestsStyled
