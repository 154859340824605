import styled from 'styled-components'
import colors from '../../../assets/colors'

const TitleLineStyled = styled.div`
  p {
    text-align: center;
    position: relative;
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    color: ${colors.black};

    span {
      position: relative;
      padding: 0 20px 0 60px;
      background-color: ${colors.grey2};
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
    }

    ::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 2px;
      border: 2px solid ${colors.greyAlpha25};
      border-radius: 8px;
    }
  }

  @media print {
    span {
      background-color: ${colors.grey2}!important;
    }
  }
`

export default TitleLineStyled
