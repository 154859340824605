import React, {useState} from 'react'
import Modal from '@material-ui/core/Modal'
import * as S from './styled'
import {ReactComponent as ModalIcon} from '../../../assets/images/notebook.svg'
import {ModalHeader, RegularButton} from '../../atoms'
import Grid from "@material-ui/core/Grid";
import {AlertDialog} from "../index";
import i18n from "../../../assets/i18n";
import {assignJobOfferToCandidates} from "../../../api";
import {useAssignJobOffersOptions} from "../../../api/queries/useJobOffers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {useAppContext} from "../../../context/appContext";
import {ALERT_LEVEL} from "../../../config/constants";

const customStyles = {
  overflow: 'scroll'
}

const AssignJobOfferButton = ({ ids, totalRows, setRowsSelected, tableRef}) => {

  const {createAlert} = useAppContext();
  const [open, setOpen ] = useState(false)
  const [areUSure, setAreUSure ] = useState(false)
  const [offerId, setOfferId] = useState(null);
  const {data: jobOfferAssignOptions} = useAssignJobOffersOptions();

  const handleSubmit = e => {
    e.preventDefault();
    if (!offerId || !ids || ids.length === 0) {
      return;
    }
    if (!areUSure) {
      setAreUSure(true);
      return;
    }

    setAreUSure(false);

    /* eslint-disable no-useless-computed-key */
    assignJobOfferToCandidates({offer_id: offerId, ids} ).then(() => {
      setOpen(false);
      setOfferId(null);
      setRowsSelected([]);
      tableRef.current && tableRef.current.onAllSelected(false);
      createAlert(i18n.assignJobOfferModal.successfully_assigned, ALERT_LEVEL.SUCCESS);
    });
  }

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={()=>setOpen(false)}
      >
        <S.ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={i18n.assignJobOfferModal.title} closeClick={()=>setOpen(false)} />
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.assignJobOfferModal.offer}</label>
                  <Autocomplete
                    className='autocomplete-custom'
                    options={jobOfferAssignOptions}
                    getOptionLabel={(option) => option.internalName}
                    onChange={(_, value) => {
                      setOfferId(value ? value.id : "");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={i18n.assignJobOfferModal.offer_placeholder} variant="outlined"/>
                    )}
                  />
                </Grid>
              </Grid>

              <AlertDialog
                open={areUSure}
                onClose={() => setAreUSure(false)}
                onSuccess={(e) => handleSubmit(e)}
                title={i18n.process.deleteModalTitle}
                text={i18n.assignJobOfferModal.areUSure.description.replaceAll('{num}', (ids && ids.length > 0) ? ids.length : totalRows)}
              />
              <div className="button-wrapper">
                <RegularButton type="submit" title={i18n.assignJobOfferModal.assign} disabled={!offerId}/>
              </div>
            </form>
          </div>
        </S.ModalStyled>
      </Modal>
      <S.ButtonExport
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
        disabled={!ids || ids.length === 0}
      >
        {i18n.assignJobOfferModal.button_title} ({(ids) ? ids.length : "0"})
      </S.ButtonExport>
    </>
  )
}

export default AssignJobOfferButton