import React, {useState} from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'
import moment from 'moment'
import {withI18n} from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import {ModalStyled} from './styled'
import Modal from '@material-ui/core/Modal'
import {ModalHeader, RegularButton, InputField, DateInput, TimeInput, InputEditor} from '../../atoms'
import {AlertDialog, SimpleDialog} from '../../molecules'
import {ReactComponent as ModalIcon} from '../../../assets/images/calendar.svg'
import * as api from '../../../api'
import {StatusType} from "../../../models";
import {useDispatch, useStore} from "react-redux";
import {CitationsOperations} from "../../../redux/citations";
import {useLocation} from "react-router";
import {getCurrentSectionKey, INTEGRATIONS, SECTION_KEYS} from "../../../config/constants";
import Switch from "@material-ui/core/Switch";

const ModalAnnouncement = ({open, handleClose, i18n, onSubmit, sms, self, isEdit = false, data = null}) => {
  const dispatch = useDispatch();
  const store = useStore();
  const currentLocation = useLocation();

  const [openPopup, setOpenPopup] = React.useState(false)
  const [isSwitchActive, setIsSwitchActive] = React.useState(false)
  const [eventName, setEventName] = React.useState('')
  const [optionalAttendees, setOptionalAttendees] = React.useState('')
  const [location, setLocation] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [date, setDate] = React.useState(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Sumar un día
    return currentDate;
  });

  const [startTime, setStartTime] = React.useState(moment().add(1, 'days').set({ hour: 8, minute: 0 }).format('HH:mm'));
  const [ endTime, setEndTime ] = React.useState(moment().add(1, 'days').set({ hour: 9, minute: 0 }).format('HH:mm'));
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [confirmationData, setConfirmationData] = useState(null)
  const [ external_id, setExternalId ] = useState(null)
  const [ citation_id, setCitationId ] = useState(null);

  const [defaultMessages, setDefaultMessages] = React.useState([])

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await api.getDefaultMessages()
      setDefaultMessages(result)
    }
    fetchData();
  }, [])

  React.useEffect(() => {
    if (data && !_.isEmpty(data)) {
      if (!isEdit) {
        let filteredData = data.filter(item => ![StatusType.HIRED, StatusType.DISCARDED, StatusType.DISCARDED_PHASE_2].includes(item.estado));
        setConfirmationData(filteredData);
      } else {

        let citationData = Array.isArray(data) ? data[0] : data;
        if (citationData.hasOwnProperty('citation_date')) {
          let dateObj = new Date(citationData.citation_date);
          let startTimeValue = citationData.citation_date ? citationData.citation_date.split('T').pop().split('+').shift().substring(0, 5) : null;
          let endTimeValue = citationData.citation_end_date ? citationData.citation_end_date.split('T').pop().split('+').shift().substring(0, 5) : null;
          setDate(dateObj);
          setStartTime(startTimeValue);
          setEndTime(endTimeValue);
        }

        setEventName(getCurrentSectionKey() !== SECTION_KEYS.INBOX && citationData.hasOwnProperty('event_name') ? citationData.event_name : '');
        setOptionalAttendees(getCurrentSectionKey() !== SECTION_KEYS.INBOX && citationData.hasOwnProperty('optionalAttendees') ? citationData.optionalAttendees : '');
        setLocation(citationData.hasOwnProperty('location') ? citationData.location : '');
        setMessage(citationData.hasOwnProperty('message') ? citationData.message : '');

        let dataValues = Array.isArray(data) ? data : [data];
        let filteredData = dataValues.filter(() => {

          // if (item.candidature) {
          //   return ![StatusType.HIRED, StatusType.DISCARDED, StatusType.DISCARDED_PHASE_2].includes(item.candidature.state.id)
          // }
          return false;
        });
        setConfirmationData(filteredData);
      }
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleClickOpenPopup() {
    setOpenPopup(true)
  }

  const handleClosePopup = value => {
    setOpenPopup(false)

    if (value) {
      const eventName = value.event_name || ''
      const optionalAttendees = value.optionalAttendees || ''
      const location = value.location || ''
      const body = value.body || ''

      setEventName(eventName)
      setOptionalAttendees(optionalAttendees)
      setLocation(location)
      setMessage(body)
    }
  }

  const clear = () => {
    setEventName('')
    setOptionalAttendees('')
    setLocation('')
    setMessage('')
    setDate(new Date())
    setStartTime(moment().format('HH:mm'))
    setEndTime(moment().format('HH:mm'))
    setIsSwitchActive(false)
    setExternalId(null)
    setCitationId(null)
  }

  const handleSubmit = (e, send = false) => {
    e.preventDefault()
    handleClose()

    if (!send) {
      if (!isEdit) {
        let ids = _.map(confirmationData, 'id');

        if (!_.isEmpty(ids)) {
          dispatch(CitationsOperations.getEditCitationsCounter(ids)).then(() => {
            let state = store.getState();

            if (state.citations.editCounter > 0) {
              toggleConfirmationModal();
            } else {
              submitAction();
            }
          });
        }
      } else {

        let state = store.getState();
        let profileData = state && state.profile && state.profile.data ? state.profile.data : null;

        if (currentLocation.pathname.includes('profile') && profileData && _.isEmpty(profileData.citation)) {
          submitAction();
        } else {
          toggleConfirmationModal();
        }

      }
    } else {
      submitAction();
    }
  }

  const toggleConfirmationModal = () => {
    if (!confirmationModal) {
      setConfirmationModal(true)
      return
    }
    setConfirmationModal(false)
  }

  const submitAction = () => {
    const citation_date = new moment(`${moment(date).format('YYYY/DD/MM')} ${startTime}`, 'YYYY/DD/MM HH:mm').format('YYYY-MM-DD HH:mm:ss');
    const citation_end_date = new moment(`${moment(date).format('YYYY/DD/MM')} ${endTime}`, 'YYYY/DD/MM HH:mm').format('YYYY-MM-DD HH:mm:ss');
    const sms_notification = sms;

    onSubmit({
      event_name: eventName,
      optional_attendees: optionalAttendees,
      location,
      citation_date,
      citation_end_date,
      online_meeting: isSwitchActive,
      sms_notification,
      message,
      external_id,
      citation_id,
    })
    setConfirmationModal(false);
    clear();
  }

  const toggleSwitch = () => setIsSwitchActive(!isSwitchActive);

  const getCurrentIntegration = () => {
    if (self && INTEGRATIONS) {
      let currentIntegration = _.find(INTEGRATIONS, {event_type: self.event_type})
      return currentIntegration ? currentIntegration : null;
    }
    return null
  }

  return (
    <>
      <Modal
        className="modal-announcement-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon/>}
                       title={!isEdit ? i18n.modalAnnouncement.quoteCall : i18n.modalAnnouncement.editQuoteCall}
                       closeClick={handleClose}/>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalAnnouncement.new_event}</label>
                  <InputField
                    fontSize="small"
                    placeholder={i18n.modalAnnouncement.new_event}
                    name="event_name"
                    value={eventName}
                    handleOnChange={value => setEventName(value)}
                  />
                </Grid>
              </Grid>
              {getCurrentIntegration() &&
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalAnnouncement.invite_people}</label>
                  <InputField
                    fontSize="small"
                    placeholder={i18n.modalAnnouncement.invite_people}
                    name="optionalAttendees"
                    value={optionalAttendees}
                    handleOnChange={value => setOptionalAttendees(value)}
                  />
                </Grid>
              </Grid>
              }
              <Grid container alignItems="flex-end" spacing={3}>
                <Grid item xs={12} sm={4}>
                  <label>{i18n.modalAnnouncement.citationDate}</label>
                  <DateInput fontSize="small" name="date" onChange={e => setDate(e)} value={date}/>
                </Grid>
                <Grid item xs={12} sm={6} className="grid-decoration" style={{display: "flex", alignItems: "center", gap: 16}}>
                  <TimeInput fontSize="small" name="start_time" onChange={e => setStartTime(e.target.textContent)} value={startTime}/>
                  <span style={{fontSize: 14}}>{i18n.modalAnnouncement.to_time}</span>
                  <TimeInput fontSize="small" name="end_time" onChange={e => setEndTime(e.target.textContent)} value={endTime}/>
                </Grid>
              </Grid>
              {getCurrentIntegration() && <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalAnnouncement.online_meeting}</label>
                  <Grid item xs={12} sm={12}>
                    <img src={require(`../../../assets/images/${getCurrentIntegration().logo}`).default} height={20} style={{marginRight: 8}} alt={getCurrentIntegration().event_type}/>
                    <Switch
                      checked={isSwitchActive}
                      onClick={() => {toggleSwitch()}}
                      name={"online_meeting"}
                      value="isSwitchActive"
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                </Grid>
              </Grid>}
              {!isSwitchActive && <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalAnnouncement.location}</label>
                  <InputField
                    fontSize="small"
                    placeholder={i18n.modalAnnouncement.writeLocation}
                    name="location"
                    value={location}
                    handleOnChange={value => setLocation(value)}
                  />
                </Grid>
              </Grid>}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalAnnouncement.notes}</label>
                  <InputEditor
                    fontSize="small"
                    placeholder={i18n.modalAnnouncement.writeDetails}
                    name="message"
                    value={message}
                    onChange={value => setMessage(value)}
                  />
                </Grid>
              </Grid>

              <div className="button-wrapper">
                <p onClick={handleClickOpenPopup}>{i18n.modalAnnouncement.defaultMessages}</p>
                <SimpleDialog defaultMessages={defaultMessages} open={openPopup} onClose={handleClosePopup}/>
                <RegularButton type="submit" title={!isEdit ? i18n.modalAnnouncement.sendCitation : i18n.modalAnnouncement.updateCitation}/>
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
      <AlertDialog
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onSuccess={(e) => handleSubmit(e, true)}
        title={i18n.process.deleteModalTitle}
        text={confirmationData && confirmationData.length > 0 ? i18n.modalAnnouncement.confirmation.description_update.replaceAll('{num}', confirmationData.length) : i18n.modalAnnouncement.confirmation.description_update_one}
      />
    </>
  )
}

ModalAnnouncement.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

const enharce = compose(withI18n)

export default enharce(ModalAnnouncement)
