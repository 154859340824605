import React, {useEffect, useState} from 'react'
import {AppBarInboxQuery, ProcessTableQuery, ProfileInfoQuery} from '../../organism'
import {BackButton, PageTitleSubtitle} from '../../atoms'
import {useParams} from "react-router";
import {getCurrentSectionKey, MODULES, SECTION_KEYS} from "../../../config/constants";
import {useAppContext} from "../../../context/appContext";
import useSavedSearchesList from "../../../api/queries/useSavedSearchesList";
import _ from "lodash";
import useInboxFilters from "../../../api/queries/useInboxFilters";

const SavedSearchesItemQuery = ({
                             classes,
                             i18n,
                             history,
                             location
                           }) => {


  const {filters, setFilters, sort, module, savedSearchItems, setSavedSearchItems, clearContextIfNeeded} = useAppContext();

  const {moduleId, savedSearchItemId} = useParams();
  const [total, setTotal] = useState(0);
  const [rowsSelected, setRowsSelected] = useState([]);
  const {data: savedSearchesList, isFetched} = useSavedSearchesList(sort[SECTION_KEYS.SAVED_SEARCHES], module);
  const {data: moduleFilters, isFetched: moduleFiltersIsFetched} = useInboxFilters(module);

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    clearContextIfNeeded(MODULES.currentModule());
  }, [module, location.pathname]);

  useEffect(() => {
    if (isFetched && moduleFiltersIsFetched) {
      let contextItems = {};
      let contextFilters = {};
      savedSearchesList.map(item => {

        item.filterGroupSelected = 1;
        contextItems[item.id] = item;
        let itemFilters = JSON.parse(item.fields);
        let itemFiltersKeys = Object.keys(itemFilters);
        let resultFilter = {};
        itemFiltersKeys.map(key => {
          let targetFilter = _.find(moduleFilters, {remote_name: key})
          let filterValues = itemFilters[key];
          let processedFilter = [];
          if (!Array.isArray(filterValues)) {
            filterValues = [filterValues];
          }
          filterValues.map(value => {
            let valueString = value;
            if (value.hasOwnProperty('from') || value.hasOwnProperty('to')) {
              valueString = value.from ? value.from : '/';
              valueString = valueString + ' - ' + (value.to ? value.to : '/');
            }

            let filterItem = {};
            filterItem.filter = targetFilter;
            filterItem.id = `${key}-${key === 'estado' ? i18n.status[value] : valueString}`;
            filterItem.label = key === 'estado' ? i18n.status[value] : valueString;
            filterItem.name = key;
            filterItem.value = value;
            processedFilter.push(filterItem);
          });
          resultFilter[key] = Array.isArray(processedFilter) ? processedFilter : [processedFilter];
        })
        contextFilters[item.id] = resultFilter;
      });
      setSavedSearchItems(contextItems);
      setFilters({...filters, [getCurrentSectionKey()]: contextFilters, [SECTION_KEYS.SAVED_SEARCHES_ITEM]: contextFilters});
      setIsReady(true);
    }

  }, [isFetched, moduleFiltersIsFetched]);

  return (
    <div className={classes.root}>
      <AppBarInboxQuery
        showActions
        module={moduleId ? moduleId : MODULES.currentModule()}
        saveSearchOption={ true }
        canEditFilters={false}
        section={SECTION_KEYS.SAVED_SEARCHES_ITEM}
        isSaveSearchItem={1}
        rowsSelected={rowsSelected}
        forceTotal={total}
      />
      <BackButton title={ i18n.saveSearchesTable.backToSaveSearches }  onClick={ () => {  history.push(window.location.pathname.replace(/\/\d+/, '')) } }/>
      {isReady &&
        <>
          <PageTitleSubtitle title={ i18n.inboxPeople.filtersSearch } subtitle={savedSearchItems && savedSearchItems[savedSearchItemId] ? savedSearchItems[savedSearchItemId].title : ''}  module={ moduleId }/>
          <ProcessTableQuery
            showFilterGroup={MODULES.currentModule() !== MODULES.ID_ON_BOARDING}
            showChangeStatus
            rowViewRender={(id) => <ProfileInfoQuery id={id}/>}
            updateParentRowsSelected={setRowsSelected}
            updateParentTotal={setTotal}
            resetFiltersOnLoad={true}
          />
        </>
      }
    </div>
  )
}

export default SavedSearchesItemQuery
