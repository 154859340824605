import styled from 'styled-components'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { Page } from 'react-pdf';
import { MTText } from '../'
import colors from '../../../assets/colors'
import React from "react";

export const Container = styled.div`
  
`

export const Content = styled.div`
    position: relative;
    display: inline-block;
    border: solid 2px ${({theme, selected})=>selected?theme.primary:theme.colors.greyAlpha50}; ;
    border-radius: 4px;
    overflow: hidden;
    transition: all .3s;
    cursor: pointer;
    box-shadow: 0px 0px 0px ${({theme})=>theme.colors.greyAlpha25};
    &:hover {
        border-color: ${({theme})=>theme.primary}; 
        box-shadow: 0px 6px 0px ${({theme})=>theme.colors.greyAlpha25};
    }
`

export const CurrentPage = styled.div`
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX( -50% );
`

export const PageContainer = styled(props => <Page {...props}/>)`
    width: 200px;
`

export const PaginateLeft = styled.div`

`

export const PaginateRight = styled.div`

`

export const Name = styled(props => <MTText {...props} />)`
    text-align: center;
    margin-top: 10px;
`

export const Placeholder = styled.div`
    width: 200px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 283px; 
    flex-direction:  column;
`

export const Image = styled.img`
`
export const IconPlaceholder = styled(props => <InsertDriveFileIcon {...props}/>)`
    width: 100px !important;
    height: 100px !important;
    fill: ${colors.grey} !important;
`

export const PlaceholderFormat = styled.div`
    font-size: 13px;
    color: ${colors.grey};
`