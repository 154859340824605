import styled from 'styled-components'

const ProfileStyled = styled.div`
  && {
    padding: 30px 60px;
    position: relative;
  }
  p.user-name-title {
    span { 
        font-weight: 700 !important;
        svg {
          width: 1.4em !important;
          height: 1.4em !important;
        }
    }
  }
  
  @media print {
    
    p {
      font-size: 14px !important;
      margin-bottom: 0 !important;
    }
    div, p.title, p.user-info, span{
      font-size: 10px !important
    }
    div#ProfileActionsButtons {
      padding-right: 25px;
      width: 50%;
    }
    p.user-name-title {
      span { 
        font-weight: 700 !important;
        font-size: 20px !important;
        svg {
          width: 60px !important;
        }
      }
    }
    .header-table {
      padding: 7px 20px!important;
    }
    .row-table {
      padding: 5px 0 !important;
    }
  }
`

export default ProfileStyled
