export const loadFacebookSDK = () => {
    return new Promise((resolve, reject) => {
      if (window.FB) {
        resolve(window.FB);
      } else {
        window.fbAsyncInit = function () {
          window.FB.init({
            appId: '1112145529798982',
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v18.0'
          });
          resolve(window.FB);
          window.FB.initCalled = true;
        };
  
        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        script.onload = () => resolve(window.FB);
        script.onerror = (error) => reject(error);
        document.body.appendChild(script);
      }
    });
  };
  