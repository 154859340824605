export default {
  SUITABLE: 1,
  NOT_SUITABLE: 2,
  TO_BE_CONFIRMED: 3,
  CONFIRMED: 4,
  NOT_INTERESTED: 5,
  RESCHEDULE: 6,
  HIRED: 7,
  BOOKING: 8,
  NOT_ATTEND: 9,
  CITED_PHASE_2: 10,
  DISCARDED: 11,
  DISCARDED_PHASE_2: 12,
  NOT_INTERESTED_PHASE_2: 13,
  NOT_ATTEND_PHASE_2: 14,
  IN_PROGRESS: 15,
  DOC_REQUIRED: 16,
  DOC_RECEIVED: 17,
  PENDING_UPDATE: 18,
  CITED: 19
}
