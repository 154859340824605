import React from 'react'
import RegularButtonSecondaryStyled from './styled'
import { useTheme } from '@material-ui/styles'

const RegularButtonSecondary = ({ title, type, styledType, disabled, handleOnClick, onClick, color, style, isActive, ...rest }) => {
  const theme = useTheme()
  return (
    <RegularButtonSecondaryStyled
      {...rest}
      color={color || theme.primary}
      styledType={styledType}
      type={type}
      disabled={disabled}
      onClick={handleOnClick || onClick}
      style={style}
      isActive={isActive}
    >
      {title}
    </RegularButtonSecondaryStyled>
  )
}

export default RegularButtonSecondary