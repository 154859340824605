import styled from 'styled-components'

export const CustomAlertStyled = styled.div`

.alertCustom {
    font-family: Poppins;
    width: 100%;   
    padding: 2px 14px;    
    border-radius: 10px;
    
    position: ${(props) => props.fixed ? 'fixed' : ''};
    bottom: ${(props) => props.fixed ? '30px' : ''};
    right: ${(props) => props.fixed ? '30px' : ''};
    // right: ${(props) => props.fixed ? '50%' : ''};
    // transform: ${(props) => props.fixed ? 'translateX(50%)' : ''};
    width: ${(props) => props.fixed ? '300px' : 'auto'};
    font-size: ${(props) => props.fixed ? '12px' : 'auto'};
    z-index: ${(props) => props.fixed ? '3000' : '100'};
}
`