import {useQuery} from '@tanstack/react-query';
import {MODULES, QUERY_KEYS} from "../../config/constants";
import {getSaveSearches} from "../index";

async function getSavedSearchesList({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  const [_key, {sort, module}] = queryKey;
  // if (!module || ![MODULES.ID_TALENT_ACQUISITION, MODULES.ID_EMPLOYEE_LINE].includes(module)) return;
  if (!module || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;
  return await getSaveSearches(sort, module);
}

export default function useSavedSearchesList(sort, module) {
  return useQuery([`${module}:${QUERY_KEYS.SAVED_SEARCHES}`, {sort, module}], getSavedSearchesList);
}