import 'core-js'
import { StatisticsActions } from './'

export const fetchGlobalStatistics = ({ startDate, endDate }, module, filtersActive) => (dispatch, getState, { api, handleError }) => {
  if (!startDate || !endDate) return
  let newFilters = Object.assign({}, filtersActive);
  delete newFilters.startDate;
  delete newFilters.endDate;
  delete newFilters.filtersAvailable;

  dispatch(StatisticsActions.setIsFetching(true))

  const filter = {
    from: startDate.format('YYYY-MM-DD'),
    to: endDate.format('YYYY-MM-DD')
  }
  return Promise.all([
      api.getGlobalStatistics(filter, module, newFilters),
      api.getPositionStatistics(filter, module, newFilters),
      api.getFiltersStatistics(module),
  ])
    .then(responses => {
      dispatch(StatisticsActions.setGlobalData(responses[0]))
      dispatch(StatisticsActions.setPositionData(responses[1]))
      dispatch(StatisticsActions.setFilters(responses[2]))
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => setTimeout(() => dispatch(StatisticsActions.setIsFetching(false)), 250))
}


export const resetStats = () => (dispatch) => {
  dispatch(StatisticsActions.setGlobalData(null))
  dispatch(StatisticsActions.setPositionData(null))
  dispatch(StatisticsActions.setFilters(null))
}

export const initStats = ({ startDate, endDate }, module, filtersActive = {}, needReset = true) => (dispatch) => {
  if (needReset) {
    dispatch(resetStats())
  }
  dispatch(fetchGlobalStatistics({ startDate, endDate }, module, filtersActive))
}