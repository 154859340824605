import * as types from './types'

export const setIsFetching = isFetching => ({
  type: types.SET_IS_FETCHING,
  payload: isFetching
})

export const setFields = fields => ({
  type: types.SET_FIELDS,
  payload: fields
})

export const setData = data => ({
  type: types.SET_DATA,
  payload: data
})
