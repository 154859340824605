import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import {useLocation} from "react-router";
import eventTracker from "../../../utils/eventTracker";

const NorFound = ({ classes, i18n }) => {

  const location = useLocation();
  useEffect(() => {
    let pathname = location.pathname;
    eventTracker.track('PageNotFound', {
      id: pathname
    });
  }, []);

  return <Container component="main" maxWidth="xs" className={classes.root}>
    <Typography className={classes.title}>{'404'}</Typography>
    <Typography className={classes.info}>{i18n.errorPage.title}</Typography>
  </Container>
}

NorFound.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default NorFound
