import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import {MODULES, QUERY_KEYS} from "../../config/constants";


async function getProfileItem({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  let [_key, {module, slug}] = queryKey;
  if (!module || !slug || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;

  return get(`/api/module/${module}/inbox/${slug}`);
}

export default function useProfile(module, slug) {
  return useQuery([`${module}:${QUERY_KEYS.PROFILE}:${slug}`, {module, slug}], getProfileItem);
}