import React, {useEffect, useState} from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import {CustomAlert, ModalHeader, RegularButton, IconTag, InputField, RegularButtonOutline} from '../../atoms'
import {useMutation} from "@tanstack/react-query";
import {deleteTag} from "../../../api";
import {CircularProgress} from "@material-ui/core";
import {ArrowIcon, CenteredIconGrid, FormGridItem} from "../modal-delete-state/styled";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {FormGridContainer} from "../modal-delete-phase/styled";

const ModalDeleteTag = ({ open, handleClose, i18n, tagId, tagName, handleOnClickDelete, deleteParams }) => {

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [targetTagValue, setTargetTagValue] = useState(null);
  const [isInUse, setIsInUse] = useState(false);

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  useEffect(() => {
    if (deleteParams && deleteParams.ids && deleteParams.ids.length > 0) {
      setIsInUse(true);
    } else {
      setIsInUse(false);
    }
  }, [deleteParams]);

  const removeTag = async (data) => {
    try {
      const response = await deleteTag(data);
      return response.data;
    } catch (error) {
      console.error("Error while deleting tag:", error);
      setAlert({ message: i18n.tag_management.deleteTagError, severity: 'error' });
      throw error;
    }
  }

  const mutationDeleteTag = useMutation(removeTag);

  const handleDeleteButton = async (targetTagToMove = null) => {
    try {
      if (tagId) {
        let data = {id: tagId}
        if (deleteParams && deleteParams.ids && deleteParams.ids.length > 0) {
          data.is_in_use = true;
          if (targetTagToMove && targetTagToMove.id && targetTagToMove.module) {
            data.target_tag_to_move = targetTagToMove;
          }
        }
        await mutationDeleteTag.mutateAsync(data);

        setAlert({message: i18n.tag_management.deleteTagSuccess, severity: 'success'});
        setShowAlert(true);
        await handleOnClickDelete();
      }
    } catch (error) {
      console.error("Error while deleting tag:", error);
      setAlert({message: i18n.tag_management.deleteTagError, severity: 'error'});
      setShowAlert(true);
    }
    setTimeout(() => {
      handleClose();
    }, 1000);
  }

  const moveTags = () => {
    if (!targetTagValue) {
      setShowAlert(true);
      setAlert({message: i18n.tag_management.moveToEmpty, severity: 'error'});
    } else {
      handleDeleteButton(targetTagValue);
    }
  }

  return (
    <div>
      <Modal className="modal-wrapper" aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
        <ModalStyled>
          <ModalHeader Icon={<IconTag />} title={`${i18n.tag_management.removeTag} ${tagName}`} closeClick={handleClose} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12}>
                  <h5 style={{textAlign: "justify"}}>
                    {isInUse
                      ? i18n.tag_management.tag_in_use.replace('{tag_name}', tagName)
                      : i18n.tag_management.remove_tag_confirmation.replace('{tag_name}', tagName)
                    }
                  </h5>
                </Grid>
              </Grid>
              <FormGridContainer container spacing={2}  className='move-tag-row-container'>
              <FormGridItem item
                            xs={deleteParams && deleteParams.ids && deleteParams.ids.length > 0 ? 4 : 12}
                            sm={deleteParams && deleteParams.ids && deleteParams.ids.length > 0 ? 4 : 12}
              >
                <label>{i18n.tag_management.labelCurrentTag}</label>
                <InputField value={tagName} disabled={true} />
              </FormGridItem>
              {isInUse && <>
                <CenteredIconGrid item xs={1} sm={1}>
                  <ArrowIcon className='arrow-icon' />
                </CenteredIconGrid>
                <FormGridItem item xs={4} sm={4}>
                  <label>{i18n.tag_management.labelNewTag}</label>
                  <Autocomplete
                    id="change-tag"
                    size="small"
                    value={targetTagValue}
                    options={deleteParams ? deleteParams.availableTags : []}
                    getOptionLabel={(option) => option.name}
                    style={{width: 200}}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    disableClearable={true}
                    onChange={(event, newValue) => {
                      setTargetTagValue(newValue);
                    }}
                  />
                </FormGridItem>
                <Grid item xs={3} sm={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <RegularButton type="button" className="move-tag-button-container" title={i18n.phases_states.buttonMove} onClick={() => moveTags()} />
                </Grid>
              </>}
              </FormGridContainer>
              <div className="button-wrapper">
                {mutationDeleteTag.isLoading && <CircularProgress />}
                {showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
                <RegularButtonOutline title={i18n.phases_states.buttonCancel} handleOnClick={handleClose} />
                <RegularButton className="button-custom" handleOnClick={handleDeleteButton}
                               type="reset" title={isInUse ? i18n.tag_management.remove_anyways : i18n.tag_management.remove_confirmed}/>
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </div>
  )
}

ModalDeleteTag.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  tagName: PropTypes.string,
  handleOnClickDelete: PropTypes.func
}

const enharce = compose(withI18n)

export default enharce(ModalDeleteTag)
