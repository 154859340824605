import styled from 'styled-components'

export const SelectChangeStatusFunnelStyle = styled.div`
  .MuiInputLabel-animated {
    transition: none !important;
  }

  .MuiInputBase-input {
    animation: none !important;
    animation-name: none !important;
  }

  .MuiOutlinedInput-notchedOutline legend {
    transition: none !important;
  }

  .MuiAutocomplete-endAdornment {
    top: calc(50% - 12px) !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #7a808d !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 6px !important;
  }

  .MuiInputBase-root {
    color: #7a808d !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 0 !important;
  }
  
  .MuiAutocomplete-inputRoot {
    padding: 5px !important;
  }
`
