import View from './view'
import {connect} from 'react-redux'
import compose from 'recompose/compose'
import {withRouter} from 'react-router'
import {withTheme} from '@material-ui/core/styles'
import {withI18n} from '../../hocs'
import {SaveSearchesOperations} from '../../../redux/savesearches'

const mapStateToProps = (state, props) => {
  return {
    // title: props.section in i18n? i18n[props.section].title : 'SIN TITULO',
    data: state.saveSearches.data,
    // inboxFilters: state.inbox.inboxFilters,
    filters: state.saveSearches.filters,
    isFetching: state.saveSearches.isFetching,
    client: state.auth.client,
    total: props.section === "planning" ? (state.citations.data ? state.citations.data.total:0): (state.processes.dataInbox ? state.processes.dataInbox.total:0),
    // reportFields: state.processes.reportFields,
    // rowsSelected: props.section === "planning" ? (state.citations ? state.citations.rowsSelected: 0): (state.inbox.rowsSelected),
    tableSort: state.processes.sort,
    filtersInState: state.saveSearches.filtersInState,
    isExitInterview: state.processes.module === 'exit_interview',
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // fetchInboxFilters: (module) => dispatch(InboxOperations.fetchInboxFilters(module)),
    postSaveSearches: (data, module) => dispatch(SaveSearchesOperations.postSaveSearches(data, module)),
    filtersOnSelectHandle: (list, event) => dispatch(SaveSearchesOperations.filtersOnSelectHandle(list, event)),
    filtersOnSubmitHandle: () => dispatch(SaveSearchesOperations.filtersOnSubmitHandle()),
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withTheme
)

export default enharce(View)
