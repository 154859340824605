import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const InputFieldStyled = styled.div`
.input-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .eye-icon {
    position: absolute;
    right: 15px;
    cursor: pointer;
    margin-top: 5px;
  }
}

  input {
    min-height: 40px;
    background: ${colors.white};
    border: 1px solid ${colors.blackAlpha41};
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 15px;
    font-weight: 400;
    font-size: 14px;
    color: ${colors.black};
    outline: none;
    width: 100%;

    ::placeholder {
      font-weight: 100;
      font-size: 14px;
      color: #9999b3;
    }

    :disabled {
      color: ${colors.blackAlpha41};
    }

    ${props =>
      props.fontSize === 'small' &&
      css`
        line-height: 21px;

        ::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: #9999b3;
        }
      `}
  }
`

export default InputFieldStyled
