import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import {MODULES, QUERY_KEYS} from "../../config/constants";

async function getFunnelStatusTypes({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  const [_key, {module}] = queryKey;
  if (!module || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;
  return await get(`api/module/${module}/phases/status`);
}

export function useGetPhasesOfStatusTypesQuery(module) {
  const { data: statusFunnelTypes } = useFunnelStatusTypes(module);
  let phasesFunnelTypes = new Set();
  (statusFunnelTypes?.data ?? []).map(el => JSON.stringify(el.phase)).filter(obj => {
      const isUnique = !phasesFunnelTypes.has(obj);
      phasesFunnelTypes.add(obj);
      return isUnique;
  });
  phasesFunnelTypes = [...phasesFunnelTypes].map(el => JSON.parse(el));
  return phasesFunnelTypes;
}

export function useFunnelStatusTypes(module = MODULES.ID_TALENT_ACQUISITION) {
  return useQuery([`${module}:${QUERY_KEYS.FUNNEL_STATUS_TYPES}`, {module}], getFunnelStatusTypes)
}
