import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _map from 'lodash/map'
import {Grid, Box, Tab, Tabs, Stepper, StepLabel, Step} from "@material-ui/core";
import {
  BoxInfoHeader,
  BoxOtherInfo,
  DocumentsList,
  HeaderProfileOnboarding,
  Panel,
  ProfileStats,
  UserNameTitle
} from '../../molecules'
import ProfileInfoStyled from './styled'
import i18n from '../../../assets/i18n'
import {SpinnerIcon} from '../../atoms'
import {ReactComponent as MarkerIcon} from '../../../assets/images/marker.svg'
import {FavoritesUtils} from '../../../redux/favorites'
import * as S from "../profile-processes-info/styled";
import JSZip from "jszip";
import {useParams} from "react-router";
import Typography from "@material-ui/core/Typography";
import _, {isNull} from "lodash";
import DraggableModal from "../../molecules/draggable-modal/view";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { MODULES } from "../../../config/constants";
import eventTracker from '../../../utils/eventTracker';

const ProfileInfo = ({
                       initOnboarding,
                       id,
                       favorites,
                       deleteFavorites,
                       postFavorites,
                       fetchOnboarding,
                       onGoBack,
                       showFav,
                       data,
                       isFetching,
                       user,
                       match,
                       tagsTypes,
                       changeTags,
                       fetchUser,
                       fetchObservations,
                     }) => {

  eventTracker.track('OnboardingProfileView', {
    url: document.location.href,
  })
  const {slug} = useParams()

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [tabValue, setTabValue] = useState( 0 );
  const [ interviewerNote, setInterviewerNote ] = useState( '' );
  const [ printNotes, setPrintNotes ] = useState( false );
  const [phases, setPhases ] = useState([]);
  const [activePhase, setActivePhase ] = useState(null);
  const onBoardingId = id || match.params.slug;

  useEffect(() => {
    initOnboarding(onBoardingId);
  }, []);

  useEffect(() => {
    if (data) {
      setInterviewerNote(data.nota_entrevistador)
      if (data.variable_fields && Object.keys(data.variable_fields).length) {
        let keys = Object.keys(data.variable_fields);
        setPhases(keys)
      }
      if (data.mongo_temp && data.mongo_temp.fase) {
        setActivePhase(Number.parseInt(data.mongo_temp.fase))
      }
    }
  }, [data])

  //Tab Panel
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }} style={{padding: "0px"}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps( index ) {
    return {
      id: `simple-tab-${ index }`,
      'aria-controls': `simple-tabpanel-${ index }`,
    };
  }

  const handleTabChange = ( event, newValue ) => {
    setTabValue( newValue );
  };
  const getInterviewerNote = () => {
    return {__html: _.split( interviewerNote, '-*{}*-')[0]};
  }

  const getInterviewerNoteAuthor = () => {
    if(_.isEmpty(interviewerNote))
    {
      return '';
    }
    //Pasamos la traducción
    return i18n.interviewerNotes.updatedAt
      .replace('{author}',_.split(interviewerNote, '-*{}*-')[1])
      .replace('{date}', _.split(interviewerNote, '-*{}*-')[3])
      .replace('{hour}', _.split(interviewerNote, '-*{}*-')[2]);

    //i18n.modalAnnouncement.confirmation[!isEdit ? 'description_create' : 'description_update'].replaceAll('{num}'
  }

  const updateSelectedDocuments = (docs) => setSelectedDocuments(docs);

  const handleShowAttachmentsPreview = (data) => {
    let attachments = _get(data, 'attachments');
    return attachments && attachments.length > 0;
  }

  const handleOnClickFav = () => {
    const onBoardingId = id || slug
    const isFavorite = FavoritesUtils.isFavorite({id: onBoardingId}, favorites)

    if (isFavorite) {
      deleteFavorites([onBoardingId])
    } else {
      postFavorites([onBoardingId])
    }
  }

  const reloadById = (onboardingId) => {
    fetchOnboarding(onboardingId)
  };

  const renderTabs = ( profileData ) => {

    if (!profileData || isFetching) {
      return <SpinnerIcon/>
    } else {
      return <>
        <Grid container xs={12} spacing={ 2 } style={{marginTop: "50px", marginBottom:"0px"}}>

          <Grid item xs={ 12 }>

            <Box sx={{ m:-2 }}>

              {/* Tabs */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={"no-print"} >
                <Tabs value={ tabValue } onChange={ handleTabChange } aria-label="basic tabs example" >
                  <Tab  label={i18n.onboarding.notesTitle} className={ `documentsInfoTitle tab-item ${ printNotes ? 'print' : 'no-print' }` } {...a11yProps(0)} />
                </Tabs>
              </Box>

              {/*Notas entrevista*/}
              <TabPanel value={ tabValue } index={0} >
                <Grid id="interviewer_notes_container" spacing={10} item style={{padding: "30px"}}
                      className={printNotes ? 'print' : 'no-print'}>
                  <p className={`interview-otes-title-print ${ printNotes ? 'print' : 'no-print' }` }>{ i18n.interviewerNotes.titleTab }</p>
                  <p className={"interviewerNote"} dangerouslySetInnerHTML={getInterviewerNote()}></p>
                  <Grid className={"no-print"}>
                    <DraggableModal user={user} id={onBoardingId} titleTab={i18n.interviewerNotes.titleTab}
                                    interviewerNote={interviewerNote} setInterviewerNote={setInterviewerNote} module={MODULES.ID_ON_BOARDING}/>
                    <FormControlLabel control={<Checkbox checked={ printNotes } style={{ marginLeft: "15px" }} onClick={ () => setPrintNotes( !printNotes ) }/>} label={ i18n.interviewerNotes.showOnPrint } />
                    <span className={"interviewerNote_metadata pull-right"}>{getInterviewerNoteAuthor()}</span>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </>
    }
  }

  const renderProfile = (data, showFav) => {
    const userId = _get(data, 'id', '');
    const userName = `${_get(data, 'mongo_temp.nombre', '')} ${_get(data, 'mongo_temp.apellidos', '')}`
    const isFavorite = _get(data, 'is_favorite')

    const candidature = _get(data, 'mongo_temp.candidatura_seleccionada', '')
    const workingDay = _get(data, 'mongo_temp.jornada', '')
    //const photo = _get(data, 'mongo_temp.foto', null)

    let email = _get(data, 'mongo_temp.email', '')
    let phone = _get(data, 'mongo_temp.telefono', '')
    let tags = _get(data, 'tags', [])
    //let formation = _get(data, 'mongo_temp.formacion', '')
    //let experience = _get(data, 'mongo_temp.anos_experiencia', '')
    //let lastJob = _get(data, 'mongo_temp.ultimo_puesto', '')
    let address = _get(data, 'mongo_temp.direccion', '')
    let location = _get(data, 'cp', '')
    let zone = _get(data, 'mongo_temp.zona_deseada', '')
    let variableFields = _get(data, 'variable_fields', '')

    let availability = _get(data, 'mongo_temp.disponibilidad', '')
    availability = moment(availability).isValid() ? moment(availability).format('DD/MM/YYYY') : availability
    let dateOfBirth = _get(data, 'fecha_nacimiento', '')
    const dateOfBirthFormat = moment(dateOfBirth, _includes(dateOfBirth, '-') ? 'YYYY-MM-DD' : 'DD/MM/YYYY')
    const formatYears = dateOfBirth ? `${moment().diff(dateOfBirthFormat.format('YYYY-MM-DD'), 'years')} ${i18n.profileInfo.years}` : ''
    dateOfBirth = dateOfBirth ? `${formatYears} - ${dateOfBirthFormat.format('DD/MM/YYYY')}` : ''
    const yearsOld = _get(data, 'edad') ? `${_get(data, 'edad')} ${i18n.profileInfo.years} ` : ''
    const years = dateOfBirth ? dateOfBirth : yearsOld
    let updatedAt = _get(data, 'updated_at', '');
    updatedAt = moment(updatedAt).isValid() ? moment(updatedAt).format('DD/MM/YYYY') : updatedAt;

    const dni = _get(data, 'mongo_temp.dni', '')

    const formatAttachments = data => {
      if (!data) return []
      if (data.length <= 1 && "id" in data) data = [data]

      return _map(data, ({id, original_name, url}) => {
        return {id, name: original_name, file: url}
      });
    }

    const handleDownload = () => {
      const newDocuments = selectedDocuments.map(item => {
        return fetch(item.file, {cache: 'no-cache'})
          .then(response => response.arrayBuffer())
          .then(arrayBuffer => {
            return Promise.resolve({
              data: Buffer.from(arrayBuffer, 'binary').toString('base64'),
              name: item.name,
              file: item.file
            })
          });
      })

      var zip = new JSZip()
      Promise.all(newDocuments).then(response => {
        response.map(item => zip.file(item.name, item.data, {base64: true}))
        zip.generateAsync({type: 'blob'}).then(function (content) {
          downloadBlob(content, 'files_' + moment().format('YYYYMMDD_Hms') + '.zip')
        })
      })
    }

    const downloadBlob = (blob, name = 'myFile') => {
      const blobUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = blobUrl
      link.download = name
      document.body.appendChild(link)
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      )
      document.body.removeChild(link)
    }

    return (
      <ProfileInfoStyled id="profilePrint">
        <HeaderProfileOnboarding
          onGoBack={onGoBack}
          className="no-print"
          userId={userId}
          userName={userName}
          onChangeProfile={reloadById}
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <UserNameTitle userName={userName}/>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
          {phases && !isNull(activePhase) &&
            <Stepper activeStep={activePhase} alternativeLabel>
              {phases.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          }
        </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            {tagsTypes && <ProfileStats
              showFav={showFav}
              userName={userName}
              isFav={isFavorite}
              tags={tags}
              tagsTypes={tagsTypes}
              changeTags={changeTags}
              fetchUser={fetchUser}
              fetchOnboarding={fetchOnboarding}
              candidatureId={onBoardingId}
              fetchObservations={fetchObservations}
              handleOnClickFav={handleOnClickFav}
              avatarImage={null}
              section={'onboarding'}
              job={candidature}
              info1={workingDay}
              info2={years}
              titleInfo3={i18n.profileInfo.availableFrom}
              info3={availability}
              titleInfo4={''}
              info4={dni} mail={email} tel={phone}
              updatedAt={updatedAt}
            />}
          </Grid>
          <Grid item xs={6} sm={6}>
            <BoxInfoHeader
              Icon={<MarkerIcon/>}
              title={i18n.profileInfo.zone}
              titleInfo1={i18n.profileInfo.city}
              titleInfo2={i18n.profileInfo.desiredLocation}
              titleInfo3={i18n.profileInfo.desiredZone}
              value1={address}
              value2={location}
              value3={zone}
              section={'onboarding'}
            />
          </Grid>
        </Grid>

        {/*<Grid container spacing={6}>

          <Grid item xs={6} sm={6}>
            <BoxProfileCite section={'onboarding'} mail={email} tel={phone} />
          </Grid>
          <Grid item xs={6} sm={6}>
            <BoxInfoHeader
              Icon={<UserIcon />}
              title={i18n.profileInfo.profile}
              titleInfo1={i18n.profileInfo.formation}
              titleInfo2={i18n.profileInfo.exp}
              titleInfo3={i18n.profileInfo.lastJob}
              value1={formation}
              value2={experience}
              value3={lastJob}
            />
          </Grid>
        </Grid>*/}
        {variableFields && Object.keys(variableFields).map(phase => {
          return <Grid id='boxOtherInfoContainer' container  key={phase} className="pagebreakavoid">
            <Grid item xs={12}>
              <BoxOtherInfo variableFields={variableFields[phase]} profile={data} phase={phase}/>
            </Grid>
          </Grid>
        })}

        {handleShowAttachmentsPreview(data) &&
          <Grid container spacing={6} className="pagebreakavoid no-print">
            <Grid item xs={12}>
              <Panel title={i18n.process.documentation}>
                <S.Toolbar className="no-print">
                  <S.ActionButton
                    disabled={!selectedDocuments || selectedDocuments.length <= 0}
                    handleOnClick={handleDownload}
                    title={i18n.process.downloadDocs}
                    styledType="invert"
                  />
                </S.Toolbar>
                <DocumentsList
                  check
                  value={selectedDocuments}
                  options={formatAttachments(_get(data, 'attachments'))}
                  onChange={value => updateSelectedDocuments(value)}
                />
              </Panel>
            </Grid>
          </Grid>}
        {renderTabs( data )}

      </ProfileInfoStyled>
    )
  }

  if (!data) {
    return <SpinnerIcon/>
  } else {
    return renderProfile(data, showFav)
  }
}

ProfileInfo.defaultProps = {
  showActions: true,
  showFav: true,
  showObsInput: true
}

ProfileInfo.propTypes = {
  i18n: PropTypes.object.isRequired,
  data: PropTypes.object,
  favorites: PropTypes.array,
  fetchUser: PropTypes.func,
  fetchUserList: PropTypes.func,
  profileData: PropTypes.object,
  profileMultipleData: PropTypes.array,
  match: PropTypes.object,
  isFetching: PropTypes.bool,
  isFetchingObservations: PropTypes.bool,
  isFetchingUserList: PropTypes.bool,
  fetchObservations: PropTypes.func,
  observations: PropTypes.array,
  postObservations: PropTypes.func,
  deleteFavorites: PropTypes.func,
  postFavorites: PropTypes.func,
  postReads: PropTypes.func,
  showActions: PropTypes.bool,
  showFav: PropTypes.bool,
  showObsInput: PropTypes.bool,
  initOnboarding: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onGoBack: PropTypes.func
}

export default ProfileInfo
