import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { InputField, ModalHeaderProcess, RegularButton, RegularButtonOutline } from '../../atoms'
import Grid from '@material-ui/core/Grid'
import { Chip } from '@material-ui/core'

const customStyles = {
  overflow: 'scroll'
}

const ModalDeletePhase = ({ open, handleClose, i18n, onSubmitDelete, itemId, initialValue, allPhasesData }) => {
  const phaseName = initialValue

  const [phaseStates, setPhaseStates] = useState([]);

  useEffect(() => {
    if (typeof phaseName === 'string' && phaseName.trim() !== '' && allPhasesData) {
      const selectedPhase = allPhasesData.find(fase => fase.name === phaseName);

      if (selectedPhase) {
        setPhaseStates(selectedPhase.status_candidates || []);
      } else {
        setPhaseStates([]);
      }
    } else {
      setPhaseStates([]);
    }
  }, [phaseName, allPhasesData]);

  const onClickDelete = async e => {
    e.preventDefault();

    onSubmitDelete(itemId)
    handleClose()
  }

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeaderProcess title={i18n.phases_states.modalDeletePhaseTitle} closeClick={handleClose} hasCloseIcon={true}/>
          <div className="modal-body">
            <form>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12}>
                  <label>{i18n.phases_states.labelPhase}</label>
                  <InputField placeholder={i18n.phases_states.placeholderPhase} value={phaseName} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.phases_states.titleState}</label>
                  <div className="wrapper-tags">
                    {phaseStates.length > 0 ? (
                      phaseStates.map((status) => (
                        <Chip
                          key={status.id}
                          label={status.name}
                          className='chip-custom'
                          style={{ backgroundColor: status ? status.color : '#d3d3d3' }}
                        />
                      ))
                    ) : (
                      <Chip label={i18n.phases_states.chipUnAssigned} className='chip-custom' />
                    )}
                  </div>
                </Grid>
              </Grid>
              <div className="button-wrapper">
                <RegularButtonOutline title={i18n.phases_states.buttonCancel} handleOnClick={handleClose} />
                <RegularButton className="button-custom" title={i18n.phases_states.buttonDelete} handleOnClick={onClickDelete} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

const enhance = compose(withI18n)

export default enhance(ModalDeletePhase)
