import styled from 'styled-components'

const DashboardCardStyled = styled.div`
  //width: 340px;
  //height: 120px;
  .dashboard_preconfigured_card, .dashboard_from_scratch_card {
    border: 2px solid transparent;
    cursor: pointer;
    //min-width: 340px;
    min-height: 120px;
    .MuiCardHeader-root {
      position: relative;
      .MuiCardHeader-title {
        font-size: 14px;
      }
      .MuiCardHeader-subheader {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .MuiCardHeader-action {
        position: absolute;
        right: 24px;
      }
    }
  }

  .default {
    opacity: 0.85;
    filter: grayscale(1);
  }
  
  .selected, .default:hover {
    border-color: ${({ theme }) => theme.primary};
    filter: none;
    box-shadow: 0 2px 4px -2px gray;
  }
  
  .wizard {
    &:hover {
      border-color: ${({ theme }) => theme.primary};
      box-shadow: 0 2px 4px -2px gray;
    }
    
    .MuiAvatar-img {
      border: 2px solid #ddd;
      padding: 1rem;
      border-radius: 10px;
    }
  }
  
  .MuiCardHeader-avatar {
    .MuiAvatar-square {
      width: 80px;
      height: 80px;
      border-radius: 8px;
    }
  }
  .dashboard_from_scratch_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .alertCustom {
      margin-bottom: 12px;
    }
    .MuiCardHeader-avatar {
      .MuiAvatar-square {
        width: 50px;
        height: 50px;
      }
    }
    .MuiCardContent-root {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 16px;
    }
  }
`
export default DashboardCardStyled
