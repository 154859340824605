export const SET_DATA = 'exit_interview/SET_DATA'
export const SET_INITIAL_DATA = 'exit_interview/SET_INITIAL_DATA'
export const SET_PAGE = 'exit_interview/SET_PAGE'
export const SET_PER_PAGE = 'exit_interview/SET_PER_PAGE'
export const SET_SORT = 'exit_interview/SET_SORT'
export const SET_MODULE = 'exit_interview/SET_MODULE'
export const RESET_DATA = 'exit_interview/RESET_DATA'
export const SET_TOTAL = 'exit_interview/SET_TOTAL'
export const SET_IS_FETCHING = 'exit_interview/SET_IS_FETCHING'
export const SET_DATA_FIELDS = 'exit_interview/SET_DATA_FIELDS'
export const SET_STATUS_TYPES = 'exit_interview/SET_STATUS_TYPES'
export const SET_TAGS_TYPES = 'exit_interview/SET_TAGS_TYPES'
export const SET_REPORT_FIELDS = 'exit_interview/SET_REPORT_FIELDS'
export const SET_READY = 'exit_interview/SET_READY'

