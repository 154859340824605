import React, { useEffect, useMemo, useState } from 'react'
import { Modal, Box, TextField, Typography, List, Chip, ListItem, ListItemText, InputAdornment } from '@material-ui/core'
import compose from 'recompose/compose'
import * as S from './styled'
import { withI18n } from '../../hocs'
import { ReactComponent as ModalIcon } from '../../../assets/images/planning.svg'
import { ModalHeader, RegularButton } from '../../atoms'
import SearchIcon from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid'
import _, { debounce } from 'lodash'
import { assignProcess } from '../../../api/index'
import { AlertDialog } from '../index'
import { useMutation } from '@tanstack/react-query'
import i18n from '../../../assets/i18n'
import { Autocomplete } from '@material-ui/lab'
import { useVacancyRequestToAssignCandidates } from '../../../api/queries/useVacancyRequestManager'
import { useAppContext } from '../../../context/appContext'
import { ALERT_LEVEL } from '../../../config/constants'
import moment from 'moment'

const customStyles = {
  overflow: 'scroll'
}

const AddCandidateButton = ({ ids, postOffer, totalRows, disabled }) => {
  const { createAlert } = useAppContext()

  const [open, setOpen] = useState(false)
  const [areUSure, setAreUSure] = useState(false)
  const [dataProcess, setDataProcess] = useState([])
  const [hoveredItem, setHoveredItem] = useState(null)
  const [autocompleteKey, setAutocompleteKey] = useState(0)
  const [formData, setFormData] = React.useState({ process: {} })
  const [selectedProcessId, setSelectedProcessId] = useState(null)
  const [autocompleteValue, setAutocompleteValue] = useState(formData.vacancies)

  const { data, refetch: vacancyRequestsRefetch, isRefetching: isVacancyRequestsRefetching } = useVacancyRequestToAssignCandidates(autocompleteValue, {
    enabled: false
  })

  useEffect(() => {
    vacancyRequestsRefetch();
  }, [vacancyRequestsRefetch]);

  const addProcess = async data => {
    try {
      await assignProcess(data)
    } catch (error) {
      console.error('Error while creating process:', error)
      throw error
    }
  }

  const mutationAssignProcess = useMutation(addProcess)

  const handleSubmit = e => {
    e.preventDefault()

    if (!isValid) {
      return
    }

    setAreUSure(true)
  }

  const isValid = () => {
    return !_.isEmpty(formData.process)
  }

  const handleConfirm = () => {
    try {
      ids.forEach(id => {
        const data = {
          candidature_id: id.id,
          vacancy_id: formData.process.id
        }
        mutationAssignProcess.mutateAsync(data)
      })
      createAlert(i18n.phases_states.assignedSuccessfully, ALERT_LEVEL.SUCCESS)
      setAreUSure(false)
      setOpen(false)
    } catch (error) {
      console.log(error)
      createAlert(error.msg.message, ALERT_LEVEL.ERROR)
    }
  }

  const handleChangeProcess = (event, newValue) => {
    setFormData({ ...formData, process: newValue })
    setSelectedProcessId(null)
  }

  const handleOnInputChange = (event, newValue, reason) => {
    if (['reset', 'clear'].includes(reason)) { 
      return;
    }
    setAutocompleteValue(newValue)
  }

  const colors = {
    active: '#D1E5FC',
    closed: '#D8DDE3',
    rejected: '#FF6961',
    empty: '#A699FA',
    pending: '#FFCAA2',
    cancelled: '#FFA2A2'
  }

  useEffect(() => {
    const dataFiltered = data?.data.map(item => {
      return {
        id: item.id,
        name: item?.search_name ?? item?.candidature_settings?.name + ' - ' + item?.client_place?.name + ' - ' + moment(item?.created_at).format('DD/MM/YYYY'),
        state: item?.state,
        color: colors[item?.state] || colors.closed
      }
    })
    setDataProcess(dataFiltered ?? [])
  }, [data])

  const debouncedRefetch = useMemo(() => debounce(vacancyRequestsRefetch, 500), [vacancyRequestsRefetch]);

  useEffect(() => {
    if (autocompleteValue) {
      debouncedRefetch();
    }
    return () => {
      debouncedRefetch.cancel();
    };
  }, [autocompleteValue, debouncedRefetch])

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => setOpen(false)}
      >
        <S.ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={i18n.assignedModal.title} closeClick={() => setOpen(false)} />
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <Box display="flex" alignItems="center" bgcolor="#fbfbfb" borderRadius={25} p={2} boxShadow={3}>
                <Grid container spacing={3} className="group-input">
                  <Grid item xs={12}>
                    <Autocomplete
                      key={autocompleteKey}
                      size="small"
                      className="autocomplete-custom"
                      value={autocompleteValue}
                      options={dataProcess}
                      getOptionLabel={option => option.name || ''}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={i18n.assignedModal.placeholder}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                      renderOption={option => (
                        <>
                          <ListItemText primary={option.name} />
                          <Chip
                            label={i18n.templateRequestManager.vacancyStates[option.state]}
                            size="small"
                            style={{
                              backgroundColor: option.color,
                              marginLeft: 'auto'
                            }}
                          />
                        </>
                      )}
                      loading={isVacancyRequestsRefetching}
                      onChange={(event, newValue) => handleChangeProcess(event, newValue)}
                      onInputChange={handleOnInputChange}
                    />

                    <Box pt={2}>
                      <Typography variant="subtitle1">
                        <strong>{i18n.assignedModal.latest}</strong>
                      </Typography>
                    </Box>
                    <List>
                      {dataProcess?.filter(process => process?.state === 'empty')?.slice(0, 5).map(process => (
                        <ListItem
                          key={process?.id}
                          disablePadding
                          style={{
                            cursor: 'pointer',
                            backgroundColor: selectedProcessId === process?.id ? '#e0e0e0' : hoveredItem === process?.id ? '#f0f0f0' : 'inherit'
                          }}
                          onMouseEnter={() => setHoveredItem(process?.id)}
                          onMouseLeave={() => setHoveredItem(null)}
                          onClick={() => {
                            setAutocompleteKey(prevKey => prevKey + 1)
                            setFormData({
                              ...formData,
                              process: {
                                id: process?.id,
                                name: process?.name,
                                state: process?.state
                              }
                            })
                            setSelectedProcessId(process?.id)
                          }}
                        >
                          <ListItemText primary={process?.name} />
                          <Chip
                            label={i18n.templateRequestManager.vacancyStates[process?.state]}
                            size="small"
                            style={{
                              backgroundColor: colors[process?.state] || colors.closed
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Box>

              <AlertDialog
                open={areUSure}
                onClose={() => setAreUSure(false)}
                onSuccess={e => handleConfirm(e)}
                title={i18n.assignedModal.subModalTitle}
                size="sm"
                text={
                  <>
                    <Typography variant="body1">{i18n.assignedModal.subModalSubTitle}</Typography>
                    <Box mt={2}>
                      <ul>
                        {ids.map(id => (
                          <li key={id}>
                            <strong>{id.name}</strong>
                          </li>
                        ))}
                      </ul>
                    </Box>

                    <Typography variant="body1">
                      {i18n.assignedModal.subModalSubDescription} <strong>{formData.process.name}</strong>
                    </Typography>
                  </>
                }
              />
              <Box display="flex" justifyContent="flex-end" className="button-wrapper">
                <RegularButton type="submit" title={i18n.assignedModal.assign} disabled={!isValid()} />
              </Box>
            </form>
          </div>
        </S.ModalStyled>
      </Modal>

      <S.ButtonExport variant="contained" color="secondary" onClick={() => setOpen(true)} disabled={disabled}>
        {i18n.processesTable.assignProcess} ({ids && ids.length > 0 ? ids.length : 0})
      </S.ButtonExport>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(AddCandidateButton)
