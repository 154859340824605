import styled from 'styled-components'
import { colors } from '../../../assets'

export const PhasesStyled = styled.div`
  .sectionSecond {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;

    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 12px;
      ::after {
        content: '*';
        color: #d95117;
        padding-left: 2px;
      }
    }
    input {
      font-size: 14px;
    }
    & h2 {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0;
    }

    & p {
      font-weight: 400;
      color: #7a808d;
    }
  }

  .containerButtonForm {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .containerDragAndDrop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0px;
    background: #ffffff;
    border-radius: 12px;
    height: auto;
  }
  .containerDragAndDropList {
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }

  .MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;
  }

  .containerColorPicker {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 10px !important;
    width: 100% !important;
    border: 1.5px solid ${colors.blackAlpha41} !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
  }

  .ColorPicker-MuiFormControl-root {
    flex-direction: row !important;
  }

  .ColorPicker-MuiInput-underline:before {
    content: none !important;
  }
  .ColorPicker-MuiInput-underline:after {
    transform: none !important;
    border-bottom: none !important;
  }
  .ColorPicker-MuiInputBase-root {
    font-family: 'Poppins' !important;
  }

  .makeStyles-root-81 {
    align-items: center !important;
  }
`
