import View from './view'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router'
import { withTheme } from '@material-ui/core/styles'
import { withI18n } from '../../hocs'
import {ProcessesOperations} from "../../../redux/processes";

const mapStateToProps = state => {
  return {
    tagsTypes: state.processes.tagsTypes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTags: (ids, tags, module) => dispatch(ProcessesOperations.changeTags(ids, tags, module)),
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withTheme
)

export default enharce(View)
