import React, { useEffect, useState } from 'react'
import ListItemStyled from './styled'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CustomAlert, IconDraggableHandle, IconEditDraggableItem, IconTrashDraggableItem } from '../../atoms'
import { CircularProgress } from '@material-ui/core'
import ModalEditPhase from '../modal-edit-phase'
import ModalEditState from '../modal-edit-state'
import ModalDeleteState from '../modal-delete-state'
import ModalDeletePhase from '../modal-delete-phase'

const ListItem = ({ i18n, item, onSubmitEditPhase, elementType, onSubmitEditState, phaseId, onSubmitDelete, phasesData, module }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [showAlert, setShowAlert] = useState(false)

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false)
      }, 8000)
    }
  }, [showAlert])

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const handleEditClick = () => {
    setIsModalOpen(true)
  }

  const handleDeletePhaseClick = async item => {
    const phase = phasesData.data.find(phaseData => phaseData.id === item.id)
    if (phase) {
      const hasStates = phase.status_candidates.length > 0

      if (hasStates) {
        setShowAlert(true)
      } else {
        setItemToDelete(item)
        setIsDeleteModalOpen(true)
      }
    }
  }

  const handleDeleteStatusClick = async item => {
    setItemToDelete(item);
    setIsDeleteModalOpen(true);
  }

  const confirmDelete = async itemToDelete => {
    setIsLoading(true);
    try {
      if (elementType === 'phase') {
        return await onSubmitDelete(itemToDelete);
      } else {
        const itemWithPhaseId = {
          ...itemToDelete,
          phaseId
        };
        return await onSubmitDelete(itemWithPhaseId);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
    return null;
  }

  const isCustomPhase = item.type === 'CUSTOM'

  const handleSubmitEditState = updatedState => {
    onSubmitEditState(updatedState)
  }

  const handleSubmitEditPhase = updatedPhase => {
    onSubmitEditPhase(updatedPhase)
  }

  return (
    <ListItemStyled>
      <div style={style} ref={setNodeRef} className="dragHandle">
        <div className="listItem">
          <span className="dragIcon" {...attributes} {...listeners}>
            {isCustomPhase && <IconDraggableHandle />}
          </span>
          <span className="content"> {item.name} </span>
        </div>
        <span className="optionsIcons">

          <span className="editIcon" onClick={handleEditClick}>
            <IconEditDraggableItem />
          </span>

          {isCustomPhase && (
            <span className="trashIcon" onClick={() => elementType === 'phase' ? handleDeletePhaseClick(item) : handleDeleteStatusClick(item)}>
              {isLoading ? <CircularProgress size={24} /> : <IconTrashDraggableItem />}
            </span>
          )}
        </span>
        {elementType === 'phase' ? (
          <>
            <ModalEditPhase
              open={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
              initialValue={item.name}
              onSubmitEditPhase={handleSubmitEditPhase}
              itemId={item.id}
              allPhasesData={phasesData.data}
              color={item.color}
            />

            <ModalDeletePhase
              open={isDeleteModalOpen}
              handleClose={() => setIsDeleteModalOpen(false)}
              initialValue={item.name}
              onSubmitDelete={() => confirmDelete(itemToDelete)}
              itemId={item.id}
              allPhasesData={phasesData.data}
              color={item.color}
            />
          </>
        ) : (
          <>
            <ModalEditState
              open={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
              phaseId={phaseId}
              onSubmitEditState={handleSubmitEditState}
              statusItem={item}
              phasesData={phasesData}
              color={item.color}
              description={item.description}
            />
            <ModalDeleteState
              open={isDeleteModalOpen}
              handleClose={() => setIsDeleteModalOpen(false)}
              onSubmitDelete={() => confirmDelete(itemToDelete)}
              item={item}
              module={module}
            />
          </>
        )}
        {/* {!isCustomPhase && <IconLockDraggableItem />} */}
      </div>
      {showAlert && (
        <CustomAlert
          severity="error"
          alertTitle={i18n.phases_states.alertTitleCannotDeletePhase}
          message={i18n.phases_states.messageAlertCannotDeletePhase}
        />
      )}
    </ListItemStyled>
  )
}

export default ListItem
