import * as types from './types'

const initialState = {
  isFetching: false,
  globalData: null,
  positionData: null,
  filtersAvailable: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_GLOBAL_DATA:
      return { ...state, globalData: action.payload }
    case types.SET_POSITION_DATA:
      return { ...state, positionData: action.payload }
    case types.SET_FILTERS_AVAILABLE:
      return { ...state, filtersAvailable: action.payload }
    default:
      return state
  }
}
