import React, {useState} from 'react';
import compose from 'recompose/compose';
import { withI18n } from '../../hocs';
import {ArrowIcon, CenteredIconGrid, FormGridContainer, FormGridItem, ModalStyled} from './styled';
import Modal from '@material-ui/core/Modal';
import {CustomAlert, InputField, ModalHeaderProcess, RegularButton, RegularButtonOutline} from '../../atoms';
import Grid from "@material-ui/core/Grid";
import SelectChangeStatusFunnel from '../../molecules/select-change-status-funnel';
import {useFunnelStatusTypes} from "../../../api/queries/useFunnelStatusTypes";
import { useMutation } from '@tanstack/react-query';
import { moveCandidatesFromStateTo } from '../../../api';
import { timer } from '../../../utils/clock';

const customStyles = {
    overflow: 'scroll'
};

const ModalDeleteState = ({ open, handleClose, i18n, onSubmitDelete, item, module }) => {
    const [deleteButtonIsDisabled, setDeleteButtonIsDisabled] = useState(false);
    const [stateCannotBeDeleted, setStateCannotBeDeleted] = useState(false);
    const [usedByCandidatures, setUsedByCandidatures] = useState(null);
    const [statusToReceiveCandidatures, setStatusToReceiveCandidatures] = useState({});
    const { data: statusFunnelTypes, isSuccess } = useFunnelStatusTypes(module);
    let filteredStatusFunnelTypes = [];
    if (isSuccess) {
        filteredStatusFunnelTypes = (statusFunnelTypes?.data ?? []).filter((status) => {
            return item.id != status.id;
        });
    }
    
    const onClickDelete = async e => {
        e.preventDefault();
        setStateCannotBeDeleted(false);
        setUsedByCandidatures(null);
        setStatusToReceiveCandidatures({});

        const response = await onSubmitDelete(item);
        if (response.hasBeenDeleted === false) {
            setStateCannotBeDeleted(true);
            setDeleteButtonIsDisabled(true);
            setUsedByCandidatures(response.usedByCandidatures);
            return;
        }
        handleClose();
    }

    const mutationMoveCandidatesFromState = useMutation((data) => moveCandidatesFromStateTo({
        module: module,
        data: data
    }));

    const moveCandidatesFromToStates = () => {
        mutationMoveCandidatesFromState.mutateAsync(
            {
                current_status: item.id,
                new_status: statusToReceiveCandidatures?.id ?? null,
            },
            {
                onSuccess: () => {
                    setStateCannotBeDeleted(false);
                    setDeleteButtonIsDisabled(false);
                },
                onError: (error) => {
                    // eslint-disable-next-line no-console
                    console.error("Error during delete:", error);
                }
            }
        );
    }

    return (
        <>
            <Modal
                style={customStyles}
                className="modal-wrapper"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <ModalStyled>
                    <ModalHeaderProcess title={i18n.phases_states.modalDeleteStateTitle} closeClick={handleClose} hasCloseIcon={true} />
                    <div className="modal-body">
                        {usedByCandidatures && timer(12000, () => setUsedByCandidatures(null)) && <CustomAlert severity="error" message={i18n.phases_states.errorOnDeleteStatus.replace('{candidatures}', usedByCandidatures)} />}
                        <form>
                            <FormGridContainer container spacing={2}>
                                <FormGridItem item xs={stateCannotBeDeleted ? 4 : 12} sm={stateCannotBeDeleted ? 4 : 12}>
                                    <label>{i18n.phases_states.labelCurrentState}</label>
                                    <InputField placeholder={i18n.phases_states.placeholderPhase} value={item.name} />
                                </FormGridItem> 
                                {stateCannotBeDeleted ? (
                                    <>
                                        <CenteredIconGrid item xs={1} sm={1}>
                                            <ArrowIcon />
                                        </CenteredIconGrid>
                                        <FormGridItem item xs={4} sm={4}>
                                            <label>{i18n.phases_states.labelNewState}</label>
                                            <SelectChangeStatusFunnel options={filteredStatusFunnelTypes} label={false} onSelectedItemChange={(newStatus) => setStatusToReceiveCandidatures(newStatus)} />
                                        </FormGridItem>
                                        <Grid item xs={3} sm={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <RegularButton type="button" className="save-button-container" title={i18n.phases_states.buttonMove} onClick={() => moveCandidatesFromToStates()} />
                                        </Grid>
                                    </>
                                ) : '' }
                            </FormGridContainer>
                            
                            <div className="button-wrapper">
                                <RegularButtonOutline title={i18n.phases_states.buttonCancel} handleOnClick={handleClose} />
                                <RegularButton className="button-custom" title={i18n.phases_states.buttonDelete} handleOnClick={onClickDelete} disabled={deleteButtonIsDisabled} />
                            </div>
                        </form>
                    </div>
                </ModalStyled>
            </Modal>
        </>
    )
}

const enhance = compose(withI18n);

export default enhance(ModalDeleteState);
