import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import * as S from './styled'
import PropTypes from 'prop-types'
import { MTDocument } from '../../atoms'
import { Link } from '@material-ui/core'
import { Document, pdfjs } from 'react-pdf'
import GetAppIcon from '@material-ui/icons/GetApp'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import { IconButton } from '@material-ui/core'
import { SpinnerIcon } from '../../atoms'
import _ from 'lodash'
import i18n from "../../../assets/i18n";
import {useHtmlParsedTxt} from "../../../api/queries/useUtils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Panel = ({ options, selected, embedded, singleDoc = false, ...rest }) => {
  const [docSelected, setDocSelected] = useState(0)
  const [numPages, setNumPages] = useState(null)
  const [zoom, setZoom] = useState(100)
  const [txt, setTxt] = useState(null)

  const {data: htmlParsedTxt} = useHtmlParsedTxt(txt);

  useEffect(() => {
    let selectedIndex = _.findIndex(options, { id: selected });
    if (selected) {
      setDocSelected(selectedIndex);
    }

    if (singleDoc && options && options[0]) {
      let file = options[0].file;
      const dotIndex = file.lastIndexOf('.')
      let format = file.substring(dotIndex)
      if (options && format.search(/^\.txt/i) !== -1) {
        setTxt(file);
      }
    }
  }, [selected, options])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const handleZoom = () => {
    const newZoom = zoom + 10
    if (newZoom <= 200) setZoom(newZoom)
  }

  const handleZoomOut = () => {
    const newZoom = zoom - 10
    if (newZoom >= -200) setZoom(newZoom)
  }

  const isPdf = file => {
    return file.search(/\.(pdf)/i) !== -1
  }


  const isIframeDoc = file => {
    return getFormat(file).search(/^\.(doc|txt)/i) !== -1;
  }

  const canPreview = file => {
    return file.search(/\.(png|jpg|jpeg|gif)/i) !== -1
  }

  const getFormat = (file) => {
    const dotIndex = file.lastIndexOf('.')
    return file.substring(dotIndex)
  }

  const Placeholder = ({ file }) => (
    <S.Placeholder><S.IconPlaceholder /><S.PlaceholderFormat>{getFormat(file)}</S.PlaceholderFormat></S.Placeholder>
  )

  Placeholder.propTypes = {
    file: PropTypes.object
  }

  const renderIframeDocument = (file) => {
    const format = getFormat(file);
    let src = file;
    let height = "300px";
    if(format.search(/^\.doc/i) !== -1) {
      src = "https://view.officeapps.live.com/op/embed.aspx?src="+encodeURIComponent(file);
      height = "600px";
    } else if (format.search(/^\.txt/i) !== -1) {
      return <iframe className="txtIframe" title={"Doc"} width="100%" height={height} srcDoc={htmlParsedTxt}></iframe>
    }

    return <iframe title={"Doc"} width="100%" height={height}  src={src}></iframe>
  }

  const newWidth = (700 * zoom) / 100

  return (
    <S.Container {...rest} className={'embedded-'+embedded}>
      {!embedded && <><S.Toolbar>
        {options[docSelected] && (
          <Link download={options[docSelected].name} href={options[docSelected].file} target="_blank">
            <IconButton>
              <GetAppIcon />
            </IconButton>
          </Link>
        )}
        <IconButton onClick={handleZoom}>
          <ZoomInIcon />
        </IconButton>
        <IconButton>
          <ZoomOutIcon onClick={handleZoomOut} />
        </IconButton>
        {`${zoom}%`}
      </S.Toolbar>
      <S.Sidebar>
        <S.List>
          {options.map((item, index) => {
            const { id, file, name } = item
            return (
              <S.Item
                key={id}
                onClick={() => {
                  setDocSelected(index)
                }}
              >
                <MTDocument width={200} url={file} name={name} selected={index === docSelected} />
              </S.Item>
            )
          })}
        </S.List>
      </S.Sidebar></>}

      {options[docSelected] && isIframeDoc(options[docSelected].name) && (renderIframeDocument(options[docSelected].file))}
      {options[docSelected] && !isIframeDoc(options[docSelected].name) &&
      (<S.Viewer className="doc-viewer">
        {(options[docSelected] && !isPdf(options[docSelected].name) && !canPreview(options[docSelected].name)) && <><Placeholder file={i18n.modalFilesPreview.no_preview}/></>}
        {(options[docSelected] && !isPdf(options[docSelected].name) && canPreview(options[docSelected].name)) && <S.Image src={options[docSelected].file} width={newWidth} />}
        {options[docSelected] && isPdf(options[docSelected].name) && (
          <>
            <Document
            file={{url: options[docSelected].file}}
            loading={
              <S.Placeholder>
                <SpinnerIcon />
              </S.Placeholder>
            }
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {numPages &&
              _.times(numPages, page => {
                return <S.PagePdf pageNumber={page + 1} width={newWidth} />
              })}
          </Document>
          </>
        )}
      </S.Viewer>)}
    </S.Container>
  )
}

Panel.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  check: PropTypes.bool,
  options: PropTypes.array
}

Panel.defaultValues = {
  value: [],
  onChange: () => null,
  check: false,
  options: []
}

const enharce = compose()

export default enharce(Panel)
