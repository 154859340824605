import React from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import { MTImage } from '../../atoms'
import { Grid } from '@material-ui/core'
import BoxAvatarImageStyled from './styled'

const BoxAvatarImage = ({ avatarImage }) => {
    return (
        <BoxAvatarImageStyled>
            <Grid container id='imageContainer'>
                <MTImage size='big' foto={avatarImage} />
            </Grid>
        </BoxAvatarImageStyled>
    )
}

BoxAvatarImage.defaultProps = {
    avatarImage: '-',
}
BoxAvatarImage.propTypes = {
    avatarImage: PropTypes.string,
}
const enharce = compose(withI18n)

export default enharce(BoxAvatarImage)
