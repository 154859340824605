import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import {BarChartStatistic, CircleStatistic, DateRangePiker, TableProcessesStatistic} from '../../molecules'
import {PageTitle} from '../../atoms'
import i18n from '../../../assets/i18n'
import ProfileInfoStyled from './styled'

class ProcessesStatisticsItems extends Component {
  static propTypes = {
    fetchGlobalStatistics: PropTypes.func,
    globalData: PropTypes.object,
    positionData: PropTypes.array,
    isFetching: PropTypes.bool
  }

  state = {
    focused: '',
    startDate: moment().add(-7, 'days'),
    endDate: moment()
  }

  componentDidMount() {
    this.props.initStats(this.state, this.props.module)
  }

  fetchGlobalStatistics = () => this.props.fetchGlobalStatistics(this.state, this.props.module)

  render() {
    const { globalData, positionData, isFetching } = this.props

    const processes = _.get(globalData, 'processes_transactional', 0)
    const suitables = Math.round(_.get(globalData, 'processes_processed_percent', 0))
    const processesTransaccionalGroup = _.get(globalData, 'processes_transaccional_group', [])
    const polls = _.get(globalData, 'polls', 0);
    const pollsCounter = polls.count ?? 0;
    const pollsAverage = polls.avg ?? 0;
    let processesConsultivoGroup = _.get(globalData, 'processes_consultivo_group', [])
    const processesCollectiveAgreementGroup = _.get(globalData, 'processes_collective_agreement_group', [])

    return (
      <ProfileInfoStyled>
        <Grid container className="title-container">
          <Grid item xs={12} sm={6} className="title">
            <PageTitle title={i18n.statistic.title} />
          </Grid>
          <Grid item xs={12} sm={6} className="buttons-wrapper">
            <DateRangePiker
              startDate={this.state.startDate}
              onStartDateChange={startDate => this.setState({ startDate })}
              endDate={this.state.endDate}
              onEndDateChange={endDate => this.setState({ endDate })}
              onFocusChange={({ focused }) => this.setState({ focused })}
              focused={this.state.focused}
              isFetching={isFetching}
              onSubmit={this.fetchGlobalStatistics}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CircleStatistic title={i18n.processesStatisticsItems.processesTransaccionalRecived} value={processes} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CircleStatistic title={i18n.processesStatisticsItems.suitables} value={suitables} percentText="%" />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CircleStatistic title={i18n.processesStatisticsItems.numberOfPolls} value={pollsCounter} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CircleStatistic title={i18n.processesStatisticsItems.averagePolls} value={pollsAverage}  />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <BarChartStatistic
              title={`${i18n.processesStatisticsItems.processesTransaccionalGroup} (${_.sumBy(processesTransaccionalGroup, item => {
                return item.total
              })})`}
              data={processesTransaccionalGroup}
              xDataKey="process_name"
              yDataKey="total"
              label={i18n.processesStatisticsItems.candidatures}
              unit=""
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <BarChartStatistic
              title={`${i18n.processesStatisticsItems.processesConsultivoGroup} (${_.sumBy(processesConsultivoGroup, item => {
                return item.total
              })})`}
              data={processesConsultivoGroup}
              xDataKey="process_name"
              yDataKey="total"
              label={i18n.processesStatisticsItems.candidatures}
              unit=""
            />
          </Grid>
          {processesCollectiveAgreementGroup.length > 1 && (
            <Grid item xs={12} sm={12}>
              <BarChartStatistic
                title={`${i18n.processesStatisticsItems.processesCollectiveAgreementGroup} (${_.sumBy(processesCollectiveAgreementGroup, item => {
                  return item.total
                })})`}
                data={processesCollectiveAgreementGroup}
                xDataKey="collectiveAgreement"
                yDataKey="total"
                label={i18n.processesStatisticsItems.candidatures}
                unit=""
              />
            </Grid>
          )}
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <TableProcessesStatistic data={positionData} isFetching={isFetching} />
          </Grid>
        </Grid>
      </ProfileInfoStyled>
    )
  }
}

ProcessesStatisticsItems.propTypes = {
  module: PropTypes.string,
  initStats: PropTypes.func
}

ProcessesStatisticsItems.defaultProps = {
  initStats: () => null
}

export default ProcessesStatisticsItems
