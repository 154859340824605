import React from 'react'


const GeroBot = ({ open }) => {

  React.useEffect(() => {
    /*window.widget_open=open;
    window.client="ATS";

    const link = document.createElement("link");
    link.rel="stylesheet";
    link.type="text/css";
    link.href="https://cell.hrbotfactory.com/widget/index.css";
    document.head.appendChild(link);

    const container=document.createElement("div");
    container.id="hrbf-cell_root";
    container.className="widget";
    document.body.appendChild(container);

    const script=document.createElement("script");
    script.src="https://cell.hrbotfactory.com/widget/index.js";
    document.body.appendChild(script);*/

    return () => {
      // eslint-disable-next-line no-unused-expressions
      document.querySelector("#hrbf-cell_root")?.remove();
    };
  }, []);

  return <></>;

}


export default GeroBot
