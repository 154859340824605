import {useQuery} from '@tanstack/react-query';
import {getDefaultMessages} from "../index";
import {MODULES} from "../../config/constants";

async function getDefaultMessagesFn({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  const [_key, {module}] = queryKey;
  if (!module || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;
  return await getDefaultMessages(module);
}

export default function useDefaultMessages(module) {
  return useQuery([module, {module}], getDefaultMessagesFn);
}