import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import {BarChartStatistic, CircleStatistic, DateRangePiker, TableStatisticFeedback} from '../../molecules'
import {PageTitle} from '../../atoms'
import i18n from '../../../assets/i18n'
import ProfileInfoStyled from './styled'
import Select from "react-select";
import _map from "lodash/map";

class ProcessesStatisticsItems extends Component {
  static propTypes = {
    fetchGlobalStatistics: PropTypes.func,
    globalData: PropTypes.object,
    positionData: PropTypes.array,
    filtersAvailable: PropTypes.array,
    isFetching: PropTypes.bool
  }

  state = {
    focused: '',
    startDate: moment().add(-7, 'days'),
    endDate: moment(),
    filtersActive: {}
  }

  componentDidMount() {
    this.props.initStats(this.state, this.props.module)
  }

  fetchGlobalStatistics = () => this.props.fetchGlobalStatistics(this.state, this.props.module, this.state.filtersActive)

  addValueFilterActive(remoteName, elementsSelected) {
    let filtersActive = this.state.filtersActive
    filtersActive[remoteName] = _map(elementsSelected, element => element.value)
    if (filtersActive[remoteName].length === 0)
      delete filtersActive[remoteName]
    this.setState({ 'filtersActive': filtersActive})
  }

  render() {
    const { globalData, positionData, isFetching } = this.props

    const processes = _.get(globalData, 'processes', 0)
    const suitables = Math.round(_.get(globalData, 'processed_percent', 0))

    let suggestionCategoryGroup = _.get(globalData, 'suggestion_category_group', [])
    let employeeDepartmentGroup = _.get(globalData, 'employee_department_group', [])
    let employeeCenterTypeGroup = _.get(globalData, 'employee_center_type_group', [])
    let stateTypeGroup = _.get(globalData, 'state_group', [])
    return (
      <ProfileInfoStyled>
        <Grid container className="title-container">
          <Grid item xs={12} sm={3} className="title">
            <PageTitle title={i18n.statistic.title} />
          </Grid>
          <Grid item xs={12} sm={9} className="buttons-wrapper">
            <div className={"container-filters"+(this.props.isFetching ? ' is-fetching' : '') }>
              {_map(this.props.filtersAvailable, filter => (
                <Select
                  isMulti={true}
                  isSearchable={true}
                  isClearable={true}
                  placeholder={filter.name}
                  options={_map(filter.options, option => {
                    if(option instanceof String){
                      return {value: option, label:option}
                    }
                    return {value: option.id, label:option.label};
                  })}
                  onChange={e => this.addValueFilterActive(filter.remoteName, e)}
                  styles={{
                    control: base => ({
                      ...base,
                      minWidth: 150,
                      maxWidth: 450,
                      textAlign: 'left'
                    }),
                    menuList: base => ({
                      textAlign: 'left',
                      ...base,
                    })
                  }}
                />
              ))}

              <DateRangePiker
                startDate={this.state.startDate}
                onStartDateChange={startDate => this.setState({ startDate })}
                endDate={this.state.endDate}
                onEndDateChange={endDate => this.setState({ endDate })}
                onFocusChange={({ focused }) => this.setState({ focused })}
                focused={this.state.focused}
                isFetching={isFetching}
                onSubmit={this.fetchGlobalStatistics}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CircleStatistic title={i18n.feedbackStatisticsItems.Received} value={processes} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CircleStatistic title={i18n.feedbackStatisticsItems.suitables} value={suitables} percentText="%" />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <BarChartStatistic
            title={i18n.feedbackStatisticsItems.statesTypes}
            data={stateTypeGroup}
            xDataKey="name"
            yDataKey="total"
            label={i18n.feedbackStatisticsItems.statesTypes}
            totalToCalcPercent={processes}
          />
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <BarChartStatistic
              title={`${i18n.feedbackStatisticsItems.category}`}
              data={suggestionCategoryGroup}
              xDataKey="name"
              yDataKey="total"
              label={i18n.feedbackStatisticsItems.category}
              totalToCalcPercent={processes}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <BarChartStatistic
              title={`${i18n.feedbackStatisticsItems.department}`}
              data={employeeDepartmentGroup}
              xDataKey="name"
              yDataKey="total"
              label={i18n.feedbackStatisticsItems.department}
              totalToCalcPercent={processes}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <BarChartStatistic
              title={`${i18n.feedbackStatisticsItems.centerType}`}
              data={employeeCenterTypeGroup}
              xDataKey="name"
              yDataKey="total"
              label={i18n.feedbackStatisticsItems.centerType}
              totalToCalcPercent={processes}
            />
          </Grid>

        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <TableStatisticFeedback data={positionData} isFetching={isFetching} title={i18n.feedbackStatisticsItems.category} />
          </Grid>
        </Grid>
      </ProfileInfoStyled>
    )
  }
}

ProcessesStatisticsItems.propTypes = {
  module: PropTypes.string,
  initStats: PropTypes.func,
  positionData: PropTypes.object
}

ProcessesStatisticsItems.defaultProps = {
  initStats: () => null
}

export default ProcessesStatisticsItems
