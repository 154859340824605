import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const BackButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  && {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: ${props => (props.disabled ? '#e0e0e0' : props.theme.textPrimary)};
    background: ${props => props.color};
    border-radius: 5px;
    box-shadow: none;
    padding: 10px 25px;
    outline: none;
    border: 0;
    transition: 0.5s ease all;
    opacity: ${props => (props.disabled ? 0.6: 1)};


    :hover {
      opacity: 0.8;
    }

    ${props =>
      props.styledType === 'reset' &&
      css`
        background-color: transparent;
        color: ${colors.black};
        padding: 0;
        font-weight: 500;
        font-size: 14px;

        :hover {
          background-color: transparent;
        }
      `};
    ${props =>
      props.disabled === true &&
      css`
        background: ${colors.greyAlpha50};
        :hover {
          background: ${colors.greyAlpha50};
        }
      `};

    ${props =>
      props.styledType === 'invert' &&
      css`
        background-color: transparent;
        color: ${props.color};
        border: solid 2px ${props.color};
        :hover {
          background-color: transparent;
        }
      `};
    ${props =>
      props.disabled === true &&
      css`
        background: ${colors.greyAlpha50};
        :hover {
          background: ${colors.greyAlpha50};
        }
      `};
  }
`

export default BackButtonStyled
