import { createStyles } from '@material-ui/core/styles'

export default () =>
  createStyles({
    root: {
      marginRight: 10,
      flex: 'auto',
      display: 'inline'
    }
  })
