import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import RegularButtonOutlineStyled from './styled'
import i18n from '../../../assets/i18n'
import { useTheme } from '@material-ui/styles'

const RegularButtonOutline = ({ title, type, styledType, disabled, handleOnClick, onClick, color, style, ...rest }) => {
  const theme = useTheme()
  return (
    <RegularButtonOutlineStyled
      {...rest}
      color={color || theme.primary}
      styledType={styledType}
      type={type}
      disabled={disabled}
      onClick={handleOnClick || onClick}
      style={style}
    >
      {title}
    </RegularButtonOutlineStyled>
  )
}

RegularButtonOutline.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  styledType: PropTypes.string,
  disabled: PropTypes.bool,
  handleOnClick: PropTypes.func,
  onClick: PropTypes.func
}

RegularButtonOutline.defaultProps = {
  title: i18n.regularButton.apply
}

const enharce = compose()

export default enharce(RegularButtonOutline)
