import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { AppBarStyled, InputStyled } from './styled'
import { PageTitle } from '../../atoms'
import { FilterFormQuery, ModalFilterQuery, ModalSaveSearch, OfferInboxButton, AddCandidateButton } from '../../molecules'
import moment from 'moment'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { ExportButton } from '../process-table/styled'
import { AuthUtils } from '../../../redux/auth'
import {ALLOWED_EMAILS, getCurrentSectionKey, MODULES, QUERY_KEYS, ROLES, SECTION_KEYS} from '../../../config/constants'
import routes from '../../../config/routes'
import { matchPath, useParams } from 'react-router'
import { postSaveSearches } from '../../../api'
import { useAppContext } from '../../../context/appContext'
import useInboxFilters from '../../../api/queries/useInboxFilters'
import useReportFields from '../../../api/queries/useReportFields'
import useTotalCount from '../../../api/queries/useTotalCount'
import { refetchQueriesByKeys } from '../../../api/mutations/mutationCallbacks'
import eventTracker from '../../../utils/eventTracker'
import { FeatureGuard } from '../../../utils/guard'
import AssignJobOfferButton from "../../molecules/assign-job-offer-button";

const AppBarInbox = ({
  i18n,
  history,
  hasFeaturePush = FeatureGuard.canAccessToPushOffers(),
  filterName,
  canEditFilters,
  showActions,
  forceTotal,
  defaultFilters,
  rowsSelected,
  setRowsSelected,
  tableRef,
  location,
  isSaveSearchItem,
  client,
  canCloneSearch,
  match,
  saveSearchOption,
  isExitInterview,
  auth,
}) => {

  const currentUserEmail = auth?.data?.email;

  const [openSaveModal, setOpenSaveModal] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [titleSaveSearch, setTitleSaveSearch] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [total, setTotal] = useState(0)
  const { slug } = useParams()

  const { filters, setFilters, sort, setSort, range, setRange, page, setPage, module, clearContextIfNeeded, clearTargetFilters } = useAppContext()

  useEffect(() => {
    clearContextIfNeeded(MODULES.currentModule())
  }, [module, location.pathname])

  const { data: inboxTotal, isFetched: isFetchedTotal } = useTotalCount(
    module,
    getCurrentSectionKey(),
    ![SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey()) ? filters[getCurrentSectionKey()] : null
  )
  const { data: reportFields } = useReportFields(module)
  const { data: inboxFilters } = useInboxFilters(module)

  let isOnlyReader
  if (module === MODULES.ID_FEEDBACK) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_feedback)
  } else if (module === MODULES.ID_EMPLOYEE_LINE) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_employee_line)
  } else if (module === MODULES.ID_ON_BOARDING) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_on_board)
  } else if (module === MODULES.ID_EXIT_INTERVIEW) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_exit_interview)
  } else {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_talent_acquisition)
  }

  useEffect(() => {
    getTotal()
  }, [inboxTotal])

  const getTotal = () => {
    switch (getCurrentSectionKey()) {
      case SECTION_KEYS.INBOX:
      case SECTION_KEYS.FAVORITES:
      case SECTION_KEYS.PUSH_OFFER_CANDIDATES:
        setTotal(isFetchedTotal && inboxTotal ? inboxTotal.total : 0) // eslint-disable-line
        break
      case SECTION_KEYS.PLANNING:
        // return citationsData ? citationsData.total : 0;
        setTotal(0)
        break
      default:
        setTotal(0)
        break
    }
  }

  const [tempFilters, setTempFilters] = useState(filters[getCurrentSectionKey()])

  const handleOpenSaveModal = () => {
    setOpenSaveModal(true)
  }

  const handleCloseSaveModal = () => {
    setOpenSaveModal(false)
  }

  const handleOpenFilterModal = () => {
    setOpenFilterModal(true)
  }

  const handleCloseFilterModal = () => {
    setOpenFilterModal(false)
  }

  //const noOptionsMessage = () => i18n.appBar.noResults

  const handleOnChangeTitle = value => {
    setTitleSaveSearch(value)
  }

  const handleSubmitSave = event => {
    event.preventDefault()
    const date = moment().format('YYYY-MM-DD HH:mm:ss')
    const data = {
      title: titleSaveSearch,
      date: date,
      fields: filters[getCurrentSectionKey()]
    }

    if (titleSaveSearch) {
      handleCloseSaveModal()
      postSaveSearches(data, module).then(() => {
        refetchQueriesByKeys([`${module}:${QUERY_KEYS.SAVED_SEARCHES}`])
        history.push(routes.savedSearches)
      })
    } else {
      // const bounced = _.debounce(() => this.setState({ errorMsg: '' }), 2000)
      _.debounce(() => setErrorMsg(''), 2000)
      setErrorMsg(i18n.appBar.errorNoTitle)
      // this.setState(
      //   {
      //     errorMsg: this.props.i18n.appBar.errorNoTitle
      //   },
      //   bounced
      // )
    }
  }

  // const handleOnChange = (value, index) => {
  //   handleOnChange(value, index)
  // }

  const renderModalSaveSearch = () => (
    <ModalSaveSearch
      handleOnChangeTitle={handleOnChangeTitle}
      handleSubmitSave={handleSubmitSave}
      open={openSaveModal}
      handleClose={handleCloseSaveModal}
      errorMsg={errorMsg}
    />
  )

  const handleSubmitFilters = () => {
    let sectionFilterSelection = { ...filters, [getCurrentSectionKey()]: tempFilters }

    setFilters(sectionFilterSelection)
    setPage({ ...page, inbox: 0 })
    setPage({ ...page, citations: 0 })
    setRange({ ...range, inbox: [0, 19] })
    setSort({ ...sort, inbox: ['', ''] })

    eventTracker.track('SearchAction', {
      url: document.location.href,
      filters: sectionFilterSelection[getCurrentSectionKey()]
    })
  }

  const updateTempFilters = (arrObjSelected, name) => {
    setTempFilters(tempFilters => {
      const resultTempFilters = { ...tempFilters }
      if (
        !arrObjSelected ||
        _.isEmpty(arrObjSelected) ||
        (arrObjSelected.length === 1 &&
          (_.get(arrObjSelected, '[0].value', null) === null ||
            !_.get(arrObjSelected, '[0].value', null) ||
            (arrObjSelected[0].value.hasOwnProperty('from') && !_.get(arrObjSelected, '[0].value.from', null) && !_.get(arrObjSelected, '[0].value.to', null))))
      ) {
        _.unset(resultTempFilters, name)
      } else {
        _.assign(resultTempFilters, { [name]: arrObjSelected })
      }

      return resultTempFilters
    })
  }

  const renderModalFilter = () => {
    if (inboxFilters) {
      return (
        <ModalFilterQuery handleSearchClick={handleSubmitFilters} open={openFilterModal} handleClose={handleCloseFilterModal} title={filterName}>
          <FilterFormQuery module={module} filters={inboxFilters} filtersValues={tempFilters} updateFiltersValues={updateTempFilters} />
        </ModalFilterQuery>
      )
    }
  }

  const selectedFiltersHandle = (list, event) => {
    const resultFilters = { ...filters[getCurrentSectionKey()] }

    if (event.action === 'remove-value') {
      let filterAssoc = resultFilters[event.removedValue.name]
      let filterToRemove = _.find(filterAssoc, { id: event.removedValue.id })
      _.pull(filterAssoc, filterToRemove)

      if (_.isEmpty(filterAssoc)) {
        _.unset(resultFilters, event.removedValue.name)
      }
      setTempFilters(resultFilters)
      setFilters({ ...filters, [getCurrentSectionKey()]: resultFilters })
    } else if (event.action === 'clear') {
      setTempFilters({})
      clearTargetFilters(getCurrentSectionKey())
    }
    // dispatch(CitationsActions.setPage(0))
    // dispatch(ProcessesActions.setPage(0))
  }

  const renderSearchOptions = options => {
    let selectOptions = {}

    //Modifica los estilos del select de los filtros. EL primero quita las x de cada elemento, y el segundo la x general
    if (!canEditFilters) {
      selectOptions.styles = { multiValueRemove: () => ({ display: 'none' }), indicatorsContainer: () => ({ display: 'none' }) }
    }

    return (
      <>
        <InputStyled className="selectContainer">
          <Select
            value={options}
            isMulti
            isSearchable={false}
            isClearable={true}
            placeholder={i18n.appBar.filters}
            onChange={selectedFiltersHandle}
            components={{ DropdownIndicator }}
            menuIsOpen={false}
            filterOption={(options, filter) => options.keywords.indexOf(filter.toLowerCase()) >= 0}
            {...selectOptions}
          />
        </InputStyled>
        {saveSearchOption && !isExitInterview && (
          <Button variant="contained" className="saveButton" onClick={handleOpenSaveModal}>
            {i18n.appBar.saveSearchs}
          </Button>
        )}
        {canCloneSearch && (
          <Button variant="contained" className="saveButton" onClick={onClickCloneSearch}>
            {i18n.appBar.cloneSearch}
          </Button>
        )}
      </>
    )
  }

  const onClickCloneSearch = () => {
    history.push(`/${module}/inbox?keepFilters=1`) // TODO: eliminar keepFilters tras implementación de cloneSearch y testeo
  }

  const renderSearch = () => {
    let filtersCopy
    let options = []

    const isSaveSearchItemPage = matchPath(match.path, { path: routes.savedSearchesItem })

    let currentFilters
    if (!isSaveSearchItemPage) {
      currentFilters = filters[getCurrentSectionKey()]
    } else {
      currentFilters = filters[getCurrentSectionKey()] && filters[getCurrentSectionKey()][slug]
      if (currentFilters) {
        if (typeof currentFilters == 'string') {
          currentFilters = JSON.parse(currentFilters)
        }
      }
    }
    filtersCopy = _.cloneDeep(currentFilters)

    _.map(filtersCopy, label => {
      _.map(label, obj => {
        obj.label = (obj.filter && obj.filter.name ? obj.filter.name : obj.name) + ': ' + obj.label
      })

      options = !_.isEmpty(label) ? _.concat(options, label) : []
    })

    return (
      getCurrentSectionKey() !== 'offerCard' && ( // TODO: Actualizar al meter section para OfferCard
        <>
          {canEditFilters && (
            <Button variant="contained" className="filterButton" onClick={handleOpenFilterModal}>
              {filterName}
            </Button>
          )}

          {options.length > 0 ? renderSearchOptions(options) : null}
        </>
      )
    )
  }

  const numberWithDots = x => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0
  }

  const newFilters = { ...defaultFilters, ...filters[getCurrentSectionKey()] }

  return (
    <AppBarStyled className="no-print">
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={4}>
          <PageTitle title={module in i18n && getCurrentSectionKey() in i18n[module] ? i18n[module][getCurrentSectionKey()].title : 'SIN TITULO'} />
          {!matchPath(location.pathname, { path: routes.savedSearchesItem }) && <span id={'total'}>({numberWithDots(forceTotal ? forceTotal : total)})</span>}
        </Grid>

        {showActions ? (
          <Grid className={'filterButtonDiv'} item xs={12} sm={12} md={8}>
            {[SECTION_KEYS.FAVORITES, SECTION_KEYS.SAVED_SEARCHES, SECTION_KEYS.PUSH_OFFER_CANDIDATES].includes(getCurrentSectionKey()) === false &&
              renderSearch()}
            {!isOnlyReader &&
              hasFeaturePush &&
              ([SECTION_KEYS.SAVED_SEARCHES, SECTION_KEYS.FAVORITES, SECTION_KEYS.PLANNING].includes(getCurrentSectionKey()) === false ||
                (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES && rowsSelected && rowsSelected.length > 0)) &&
              !matchPath(location.pathname, { path: routes.offerCandidatures }) &&
              module === MODULES.ID_TALENT_ACQUISITION && (
                <OfferInboxButton
                  filters={newFilters}
                  sort={sort[getCurrentSectionKey()]}
                  ids={_.map(rowsSelected, item => item.id)}
                  sms={client ? client.sms_available : false}
                  totalRows={forceTotal ? forceTotal : total}
                />
              )}
            {!isOnlyReader &&
              (ALLOWED_EMAILS.indexOf(currentUserEmail)>-1 || FeatureGuard.canAccessToJobOffers()) &&
              ([SECTION_KEYS.INBOX].includes(getCurrentSectionKey())) && module === MODULES.ID_TALENT_ACQUISITION && (
                <AssignJobOfferButton
                  ids={_.map(rowsSelected, item => item.id)}
                  totalRows={forceTotal ? forceTotal : total}
                  setRowsSelected={setRowsSelected}
                  tableRef={tableRef}
                />
              )}

            {((getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES && isSaveSearchItem === 1) ||
              [SECTION_KEYS.PLANNING, SECTION_KEYS.SAVED_SEARCHES].includes(getCurrentSectionKey()) === false) && (
              <ExportButton
                module={module}
                fields={reportFields}
                filters={newFilters}
                sort={sort[getCurrentSectionKey()]}
                ids={_.map(rowsSelected, item => item.id)}
                totalRows={forceTotal ? forceTotal : total}
              />
            )}

            {FeatureGuard.canAccessToVacancyRequest() && (
              <AddCandidateButton
                ids={_.map(rowsSelected, item => ({ id: item.id, name: `${item.nombre} ${item.apellidos}`, state: item.state }))}
                totalRows={forceTotal ? forceTotal : total}
                disabled={_.isEmpty(rowsSelected)}
              />
            )}
          </Grid>
        ) : null}
      </Grid>
      {renderModalFilter()}
      {renderModalSaveSearch()}
      <Divider variant="middle" />
    </AppBarStyled>
  )
}

AppBarInbox.defaultProps = {
  showActions: false,
  saveSearchOption: true,
  canEditFilters: true,
  canCloneSearch: false
}

const DropdownIndicator = () => null

export default AppBarInbox
