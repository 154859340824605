import { Grid } from '@material-ui/core'
import { ModalSimpleSmall } from '../index'
import React from 'react'
import i18n from '../../../assets/i18n';
import { importCsvCandidates } from '../../../api'
import { useAppContext } from '../../../context/appContext'
import { ALERT_LEVEL } from '../../../config/constants'

const ModalImportEvaluativeTestsCandidates = ({ evaluativeConfigId, isModalOpen, handleClose, form, uploadResult = {} }) => {
  const { createAlert } = useAppContext();

  const {module} = useAppContext();

  const numTotal = (uploadResult?.total || 0).toString();
  const numProcessed = (uploadResult?.processed || 0).toString();
  let isLoading = false;

  const handleSendClick = async (e) => {
    e.preventDefault();
    if(isLoading) {
      return;
    }

    isLoading = true;

    const response = await importCandidates();
    isLoading = false;
    if (response.success) {
      const text = i18n.evaluativeTests.csvUpload.candidatesImported
        .replace("%ok%", response.imported)
        .replace("%total%", response.total);

      createAlert(text, ALERT_LEVEL.SUCCESS, 5000);
      handleClose && handleClose();
    } else {
      createAlert(i18n.evaluativeTests.csvUpload.candidatesNotifiedError, ALERT_LEVEL.ERROR, 5000);
    }
  }

  const importCandidates = async function() {
    return await importCsvCandidates(evaluativeConfigId, module, form);
  }

  return (<>
    <ModalSimpleSmall
      open={isModalOpen}
      cancelButton={i18n.modalGeneric.cancel}
      handleClose={handleClose}
      buttonTitle={i18n.modalGeneric.send}
      handleButtonClick={handleSendClick}
      textContent={<>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            {/*<p>{title}</p>*/}
            <h4 style={{ fontWeight: 'bold' }}>{i18n.evaluativeTests.csvUpload.modalCheckCandidates
              .replace('%ok%', numProcessed)
              .replace('%total%', numTotal)}</h4>
            <br/>
            <h5>{i18n.evaluativeTests.csvUpload.modalImportCandidates}</h5>
          </Grid>
        </Grid>
      </>
      }
    />
  </>)
}

export default ModalImportEvaluativeTestsCandidates;
