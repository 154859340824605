import styled from 'styled-components'
import colors from '../../../assets/colors'

const ProfileButtonsStyled = styled.div`

.btnsNavigation{
  background-color: #E4E6E8 !important; 
  height: 48px !important;
  line-height: 48px !important;
  border-radius: 6px !important;
  border:unset;
}

  .buttons-wrapper {
    text-align: right;
  }

  a {
    text-decoration: none;
  }

  .download-btn {
    background-color: #e0a93f;
    color: ${colors.white};
    text-transform: inherit;
    margin-right: 15px;
    position: relative;
    top: -2px;
    svg {
      margin-right: 10px;
    }
  }

  .print-btn {
    text-transform: 'none';
    font-size: 14px;
    margin-right: 13px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    top: -2px;
    min-width: 110px;
  }

  .header-profile-actions {
    text-transform: 'none';
    font-size: 14px;
    padding-left: 20px;
    padding-right: 13px;

    span {
      margin-right: 4px;
    }
  }
  
  .header-pagination-count {
    display: inline-block;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color:#666;
    opacity: 0,75 !important;

  }
  
  .header-profile-arrows {
    margin-left: 15px;
    button {
      padding: 7px;
      margin-left: 10px;
    }
    svg {
      height: 25px;
      width: 30px;
    }
  }

  .currentItemNumber{
    color: ${colors.grey4};
  }
`

export default ProfileButtonsStyled
