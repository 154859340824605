import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import TextAreaFieldStyled from './styled'

const TextAreaField = ({ placeholder, name, handleOnChange, value, autoComplete, rows }) => (
  <TextAreaFieldStyled>
    <textarea
      rows={rows}
      name={name}
      onChange={handleOnChange ? e => handleOnChange(e.target.value, e.target.name) : null}
      autoComplete={autoComplete}
      placeholder={placeholder}
      value={value}
    />
  </TextAreaFieldStyled>
)

TextAreaField.propTypes = {
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  handleOnChange: PropTypes.func,
  name: PropTypes.string,
  autoComplete: PropTypes.string
}

TextAreaField.defaultProps = {
  type: 'text',
  autoComplete: 'off'
}

const enharce = compose()

export default enharce(TextAreaField)
