import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withI18n } from '../../hocs'
import { ProfileOperations } from '../../../redux/profile'

const mapStateToProps = state => ({
  profileData: state.profile.data
})

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: candidatureId => dispatch(ProfileOperations.fetchUser(candidatureId))
  }
}

const enharce = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withI18n)

export default enharce(View)
