import React, {useEffect, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid'
import JobOffersStyled from './styled'
import {Box, LinearProgress, TablePagination} from '@material-ui/core'
import RegularButton from '../../atoms/regular-button';
import MaterialTable from 'material-table';
import {
  ChipCustom,
  IconEdit,
  MTText
} from '../../atoms'
import {
  FilterFormQuery,
  ModalFilterQuery,
  TableToggle
} from '../../molecules'
import _ from 'lodash';
import i18n from '../../../assets/i18n'
import routes from "../../../config/routes";
import {useHistory} from "react-router-dom";
import {
  useJobOfferActiveNamesOptions, useJobOfferCandidatureSettingsOptions,
  useJobOfferInactiveNamesOptions,
  useJobOfferLocationOptions,
  useJobOfferRecruitmentSourcesOptions,
  useJobOffers
} from '../../../api/queries/useJobOffers'
import moment from "moment/moment";
import {isJSON} from "../../../utils/validation";
import {jsonToShow} from "../../../utils/formatting";
import {useAppContext} from "../../../context/appContext";
import {getCurrentSectionKey} from "../../../config/constants";
// import {useEvaluationProfileOptions} from "../../../api/queries/useIaEvaluationProfile";
import {InputStyled} from "../../organism/app-bar-inbox-query/styled";
import Select from "react-select";
import eventTracker from '../../../utils/eventTracker';
import {refetchQueryOnCacheDataLost} from "../../../api/utils";

const JobOffers = () => {
  eventTracker.track('JobOffersView', {
    url: document.location.href,
  })

  const {filters, setFilters, page, setPage, perPage, setPerPage, range, setRange, sort, setSort, module} = useAppContext();
  const tableRef = useRef(null);
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' })

  const [showActivesTable, setShowActivesTable] = useState(true);
  const [showActiveNames, setShowActiveNames] = useState(true);

  const [tempFilters, setTempFilters] = useState(filters[getCurrentSectionKey()]);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const [formattedEvaluationOptions, setFormattedEvaluationOptions] = useState([]);
  const [formattedLocationOptions, setFormattedLocationOptions] = useState([]);
  const [formattedRecruitmentSourcesOptions, setFormattedRecruitmentSourcesOptions] = useState([]);
  const [formattedCandidatureSettingsOptions, setFormattedCandidatureSettingsOptions] = useState([]);
  const [formattedActiveNamesOptions, setFormattedActiveNamesOptions] = useState([]);
  const [formattedInactiveNamesOptions, setFormattedInactiveNamesOptions] = useState([]);

  const { data: jobOffers, isLoading, dataUpdatedAt, refetch} = useJobOffers(filters[getCurrentSectionKey()], range[getCurrentSectionKey()], sort[getCurrentSectionKey()]);
  // const { data: evaluationProfileOptions, isLoading: isLoadingEvaluationProfilesOptions} = useEvaluationProfileOptions();
  const { data: locationOptions, isLoading: isLoadingLocationsOptions} = useJobOfferLocationOptions();
  const { data: recruitmentSourcesOptions, isLoading: isLoadingRecruitmentSourcesOptions} = useJobOfferRecruitmentSourcesOptions();
  const { data: candidatureSettingsOptions, isLoading: isLoadingCandidatureSettingsOptions} = useJobOfferCandidatureSettingsOptions();
  const { data: activeNamesOptions, isLoading: isLoadingActiveNamesOptions} = useJobOfferActiveNamesOptions();
  const { data: inactiveNamesOptions, isLoading: isLoadingInactiveNamesOptions} = useJobOfferInactiveNamesOptions();

  const updateTableData = () => tableRef && tableRef.current && tableRef.current.onQueryChange();

  useEffect(() => {
    refetchQueryOnCacheDataLost(jobOffers, dataUpdatedAt, refetch);
    updateTableData();
  }, [jobOffers]);

  useEffect(() => {
    setFilters({...filters, [getCurrentSectionKey()]: {...filters[getCurrentSectionKey()], status: showActivesTable}});
    setPage({...page, [getCurrentSectionKey()]: 0});
    setRange({...range, [getCurrentSectionKey()]: getRange(0, perPage[getCurrentSectionKey()])});
  }, [showActivesTable]);

  useEffect(() => {
    setTempFilters({...tempFilters, internal_name: []});
  }, [showActiveNames]);

  // Seteamos el name al valor del filtro del contexto al primer renderizado. Debe ejecutarse después del useEffect de showActivesTable y showActiveNames
  useEffect(() => {
    setTempFilters({...tempFilters, internal_name: filters[getCurrentSectionKey()].internal_name});
  }, []);

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  /*useEffect(() => {
    if (!isLoadingEvaluationProfilesOptions && evaluationProfileOptions) {
      let options = evaluationProfileOptions.map(item => {
        item.text = item.name;
        item.value = item.id;
        delete item.name;
        delete item.id;
        return item;
      });

      setFormattedEvaluationOptions(options);
    }
  }, [evaluationProfileOptions]);*/

  useEffect(() => {
    if (!isLoadingLocationsOptions && locationOptions) {
      let options = locationOptions.map(item => {
        return {text: item, value: item};
      });

      setFormattedLocationOptions(options);
    }
  }, [locationOptions]);

  useEffect(() => {
    if (!isLoadingRecruitmentSourcesOptions && recruitmentSourcesOptions) {
      let options = recruitmentSourcesOptions.map(item => {
        return {text: i18n.job_offers[item] || item, value: item};
      });

      setFormattedRecruitmentSourcesOptions(options);
    }
  }, [recruitmentSourcesOptions]);

  useEffect(() => {
    if (!isLoadingCandidatureSettingsOptions && candidatureSettingsOptions) {
      let options = candidatureSettingsOptions.map(item => {
        return {text: item.name, value: item.id};
      });

      setFormattedCandidatureSettingsOptions(options);
    }
  }, [candidatureSettingsOptions]);

  useEffect(() => {
    if (!isLoadingActiveNamesOptions && activeNamesOptions) {
      let options = activeNamesOptions.map(item => {
        return {text: item, value: item};
      });

      setFormattedActiveNamesOptions(options);
    }
  }, [activeNamesOptions]);

  useEffect(() => {
    if (!isLoadingInactiveNamesOptions && inactiveNamesOptions) {
      let options = inactiveNamesOptions.map(item => {
        return {text: item, value: item};
      });

      setFormattedInactiveNamesOptions(options);
    }
  }, [inactiveNamesOptions]);

  const getRange = (pageVal, perPageVal) => {
    return [pageVal * perPageVal, perPageVal * (pageVal + 1) - 1];
  }

  useEffect(() => {
    setRange({...range, [getCurrentSectionKey()]: getRange(page[getCurrentSectionKey()], perPage[getCurrentSectionKey()])});
  }, [page, perPage]);

  const handleChangePage = (event, newPage) => {setPage({...page, [getCurrentSectionKey()]: newPage})}

  const handleChangePerPage = (event) => {
    setPerPage({...perPage, [getCurrentSectionKey()]: parseInt(event.target.value,10)});
    setPage({...page, [getCurrentSectionKey()]: 0});
  }

  const handleOrderChange = (column, orderDirection) => {
    setPage({...page, [getCurrentSectionKey()]: 0});
    setSort({...sort, [getCurrentSectionKey()]: orderDirection ? [column.field, orderDirection] : ["",""]});
  }

  const formattedValue = (value) => {
    if (_.isString(value) && (
      moment(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid() ||
      moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ||
      moment(value, 'YYYY-MM-DD', true).isValid()
    )) {
      return moment(value).format('DD/MM/YYYY');
    } else if (_.isString(value) && isJSON(value)) {
      return jsonToShow(value);
    } else if (!_.isArray(value) && !_.isObject(value)) {
      return value;
    }
  }

  const updateTempFilters = (arrObjSelected, name) => {
    setTempFilters(tempFilters => {
      const resultTempFilters = {...tempFilters};
      if ( !arrObjSelected || _.isEmpty(arrObjSelected) || (arrObjSelected.length === 1 && (
        _.get(arrObjSelected, '[0].value', null) === null
        || (!_.get(arrObjSelected, '[0].value', null) && _.get(arrObjSelected, '[0].value', null) !== false)
        || (arrObjSelected[0].value.hasOwnProperty('from') && !_.get(arrObjSelected, '[0].value.from', null) && !_.get(arrObjSelected, '[0].value.to', null))
      ))) {
        _.unset(resultTempFilters, name);
      } else {
        _.assign(resultTempFilters, { [name]: arrObjSelected });
        if (name === 'status') {
          setShowActiveNames(arrObjSelected[0].value);
        }
      }

      return resultTempFilters;
    })
  }

  const jobOffersFilters = [
    {
      answers: showActiveNames ? (formattedActiveNamesOptions ? formattedActiveNamesOptions : []) : (formattedInactiveNamesOptions ? formattedInactiveNamesOptions : []),
      description: null,
      id: -1,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.modalFilter.name,
      remote_name: 'internal_name',
      type: 'select',
      filter_block: { name: i18n.modalAnnouncement.filterBlockGeneral}
    },
    // {
    //   answers: formattedEvaluationOptions ? formattedEvaluationOptions : [],
    //   description: null,
    //   id: -1,
    //   module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
    //   name: i18n.job_offers.candidature,
    //   remote_name: 'evaluation_profile.id',
    //   type: 'select',
    //   filter_block: { name: i18n.modalAnnouncement.filterBlockGeneral}
    // },
    {
      answers: formattedCandidatureSettingsOptions ? formattedCandidatureSettingsOptions : [],
      description: null,
      id: -1,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.modalFilter.candidature,
      remote_name: 'candidature.id',
      type: 'select',
      filter_block: { name: i18n.modalAnnouncement.filterBlockGeneral}
    },
    {
      answers: formattedLocationOptions ? formattedLocationOptions : [],
      description: null,
      id: -1,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.job_offers.location,
      remote_name: 'location',
      type: 'select',
      filter_block: { name: i18n.modalAnnouncement.filterBlockGeneral}
    },
    {
      answers: [],
      description: null,
      id: -1,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.job_offers.createdAt,
      remote_name: 'created_at',
      type: 'range_date',
      filter_block: { name: i18n.modalAnnouncement.filterBlockGeneral}
    },
    {
      answers: formattedRecruitmentSourcesOptions ? formattedRecruitmentSourcesOptions : [],
      description: null,
      id: -1,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.job_offers.recruitmentSource,
      remote_name: 'recruitment_source',
      type: 'select',
      filter_block: { name: i18n.modalAnnouncement.filterBlockGeneral}
    },
    {
      answers: [],
      description: null,
      id: -1,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.job_offers.offer_status,
      remote_name: 'status',
      type: 'boolean',
      filter_block: { name: i18n.modalAnnouncement.filterBlockGeneral},
    }
  ];

  const handleOpenFilterModal = () => {setOpenFilterModal(true)}
  const handleCloseFilterModal = () => {setOpenFilterModal(false)}

  const handleCreateOffer = () => {
    history.push(routes.job_offer_create);
  }

  const handleSubmitFilters = () => {
    setShowActivesTable(tempFilters && tempFilters.status && tempFilters.status.length ? tempFilters.status[0].value : true);
    setFilters({...filters, [getCurrentSectionKey()]: tempFilters});
    setPage({...page, [getCurrentSectionKey()]: 0});
    setRange({...range, [getCurrentSectionKey()]: getRange(0, perPage[getCurrentSectionKey()])});
  }

  const handleRowClick = (e, row) => {
    history.push(routes.job_offer_applications.replace(':job_offer_id', row.id));
  }

  const handleEditIconClick = (e, row) => {
    e.stopPropagation();
    e.preventDefault();
    history.push(routes.job_offer_edit.replace(':id', row.id));
  }

  let tableColumns = {
    name: {
      title: i18n.job_offers.name,
      render: (row) => {
        return <MTText className="offerName" value={row.internal_name} style={{ fontWeight: '400' }} />;
      },
      field: 'internal_name',
    },
    /*"user.email": {
      title: i18n.job_offers.createdBy,
      render: (row) => {
        return <MTText value={row.user?.email || '-'} style={{ fontWeight: '400' }} />;
      },
      field: 'user.email',
    },*/
    /*"keyword": {
      title: i18n.job_offers.keyword,
      render: (row) => {
        return <MTText value={row.keyword || "-"} style={{ fontWeight: '400' }} />;
      },
      field: 'keyword',
      sorting: false,
    },*/
    "candidature.name": {
      title: i18n.job_offers.candidature_name,
      render: (row) => {
        return <MTText value={row.candidature?.name || "-"} style={{ fontWeight: '400' }} />;
      },
      field: 'candidature.name'
    },
    /*"evaluation_profile.name": {
      title: i18n.job_offers.profile,
      render: (row) => {
        return <MTText value={row.evaluation_profile?.name || i18n.job_offers.not_available} style={{ fontWeight: '400' }} />;
      },
      field: 'evaluation_profile.name',
    },*/
    location: {
      title: i18n.job_offers.location,
      render: (row) => {
        return <MTText value={row.location} style={{ fontWeight: '400' }} />;
      },
      field: 'location',
    },
    created_at: {
      title: i18n.job_offers.createdAt,
      render: (row) => {
        return <MTText value={formattedValue(row.created_at)} style={{ fontWeight: '400'}} />;
      },
      field: 'created_at',
    },
    end_date: {
      title: i18n.job_offers.endDate,
      render: (row) => {
        return <MTText value={formattedValue(row.end_date)} style={{ fontWeight: '400'}} />;
      },
      field: 'end_date',
    },
    new_candidates_count: {
      title: i18n.job_offers.newCandidates,
      render: (row) => {
        return <MTText value={row.new_candidates_count} style={{ fontWeight: '700', textAlign: "center" }} />;
      },
      field: 'new_candidates_count',
      sorting: false,
    },
    total_candidates_count: {
      title: i18n.job_offers.totalCandidates,
      render: (row) => {
        return <MTText value={row.total_candidates_count} style={{ fontWeight: '700', textAlign: "center" }} />;
      },
      field: 'total_candidates_count',
      sorting: false,
    },
    recruitment_source: {
      title: i18n.job_offers.recruitmentSource,
      render: (row) => {
        let label = i18n.job_offers[row.recruitment_source] || row.recruitment_source;
        let color = "#000";
        switch (row.recruitment_source) {
          case "infojobs":
            color = "#2D9AFF";
            break;
          case "epreselec":
            color = "#F6C036";
            break;
          case "pandape":
          case "pandapePriority":
            color = "#d03cce";
            break;
          case "turijobs":
            color = "#e34e18";
            break;
          case "computrabajo":
            color = "#37BC6A";
            break;
          case "indeed":
            color = "#2557a7";
            break;
          default:
            break;
        }

        return <div style={{display: "flex", justifyContent: "center"}}>
          <ChipCustom color={color} label={label}/>
          {row.recruitment_source === "manual" && <div className='custom-edit' onClick={(e) => handleEditIconClick(e, row)}><IconEdit/></div>}
        </div>
      },
      field: 'recruitment_source',
    },
  };

  let tableOptions = {
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    cellStyle: { textAlign: 'center' },
    pageSize: perPage[getCurrentSectionKey()],
    pageSizeOptions: [10, 20, 50, 100],
    toolbar: false,
    draggable: false,
    selection: false,
  }

  const dataQuery = async () => {
    try {
      const totalCount = jobOffers ? jobOffers.total : 0;
      if ((!jobOffers || !jobOffers.data || jobOffers.data.length <= 0) || (!tableColumns || tableColumns.length <= 0)) {
        return {data: [], page: 0, totalCount: 0}
      }
      return {data: jobOffers.data, page: page[getCurrentSectionKey()], totalCount}
    } catch (e) {
      return {data: [], page: 0, totalCount: 0}
    }
  }

  const columnsArray = Object.values(tableColumns);

  columnsArray.forEach(column => {
    if (column.render) {
      column.render.displayName = `${column.field}_render`;
    }
  });

  const selectedFiltersHandle = (list, event) => {
    const resultFilters = { ...filters[getCurrentSectionKey()] }

    if (event.action === 'remove-value') {
      let filterAssoc = resultFilters[event.removedValue.name]
      let filterToRemove = _.find(filterAssoc, { id: event.removedValue.id })
      _.pull(filterAssoc, filterToRemove)

      if (_.isEmpty(filterAssoc)) {
        _.unset(resultFilters, event.removedValue.name)
      }
      setTempFilters(resultFilters)
      setFilters({ ...filters, [getCurrentSectionKey()]: resultFilters })
    } else if (event.action === 'clear') {
      setTempFilters({status: showActivesTable})
      setFilters({...filters, [getCurrentSectionKey()]: {status: showActivesTable}});
      setPage({...page, [getCurrentSectionKey()]: 0});
      setRange({...range, [getCurrentSectionKey()]: [0, 19]});
      setSort({...sort, [getCurrentSectionKey()]: ['', '']});
    }
  }

  const renderSearchOptions = options => {
    let selectOptions = {}

    return (
      <>
        <InputStyled className="selectContainer">
          <Select
            value={options}
            isMulti
            isSearchable={false}
            isClearable={true}
            placeholder={i18n.appBar.filters}
            onChange={selectedFiltersHandle}
            components={{ DropdownIndicator }}
            menuIsOpen={false}
            filterOption={(options, filter) => options.keywords.indexOf(filter.toLowerCase()) >= 0}
            {...selectOptions}
          />
        </InputStyled>
      </>
    )
  }

  const renderSearch = () => {
    let filtersCopy
    let options = []

    let currentFilters = filters[getCurrentSectionKey()]
    filtersCopy = _.cloneDeep(currentFilters)

    _.map(filtersCopy, label => {
      _.map(label, obj => {
        obj.label = (obj.filter && obj.filter.name ? obj.filter.name : obj.name) + ': ' + obj.label
      })

      options = !_.isEmpty(label) ? _.concat(options, label) : []
    })

    return (
      <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "flex-end", placeSelf: 'flex-end' }}>
        <RegularButton className="button-custom" title={i18n.job_offers.filterJobs}
                       onClick={handleOpenFilterModal}
        />
        {options.length > 0 ? renderSearchOptions(options) : null}
      </Box>
    )
  }

  return (
    <JobOffersStyled>
      <div className="containerHeader">
        <Grid container spacing={4}>
          <Grid item className="sectionSecond" xs={12} sm={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start", gridGap: '15px', maxWidth: "800px", }}>
              <h2>{i18n.job_offers.titleSection}</h2>
              <h5>{i18n.job_offers.descriptionSection}</h5>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-end", gridGap: '15px', justifyContent: 'flex-end' }}>
              <Box sx={{ display: 'flex', alignItems: "flex-end", placeSelf: 'flex-end' }}>
                <RegularButton className="button-custom" title={i18n.job_offers.createJob}
                               onClick={handleCreateOffer}
                />
              </Box>
              {renderSearch()}
            </Box>
          </Grid>

          {isLoading && <LinearProgress/>}
          {<>
              {<Grid item xs={12} sm={12} >
                <Grid item className="headerTable">
                  <div>
                    <h5 style={{fontWeight: 700, margin: 0}}>{jobOffers && jobOffers.total ? `${jobOffers.total} ${i18n.job_offers[showActivesTable ? "active_offers" : "inactive_offers"]}` : ""}</h5>
                  </div>
                  <TableToggle
                    buttonLabels={[i18n.ia_evaluation.active, i18n.ia_evaluation.inactive]}
                    handleButtonClick={() => {
                      setShowActivesTable(prevState => {
                        setTempFilters({...tempFilters, internal_name: []});
                        setShowActiveNames(!prevState);
                        return !prevState;
                      });
                      setPage({...page, [getCurrentSectionKey()]: 0});
                    }}
                    showOpen={showActivesTable}
                  />
                </Grid>
                <MaterialTable
                  tableRef={tableRef}
                  data={dataQuery}
                  isLoading={isLoading}
                  columns={columnsArray}
                  options={tableOptions}
                  onRowClick={handleRowClick}
                  onOrderChange={(orderedColumnId, orderDirection) => handleOrderChange(tableColumns[Object.keys(tableColumns)[orderedColumnId]], orderDirection)}
                  components={{
                    Pagination: () => <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      rowsPerPage={perPage[getCurrentSectionKey()]}
                      count={!isLoading && jobOffers && jobOffers.total ? jobOffers.total : 0}
                      page={page[getCurrentSectionKey()]}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangePerPage}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  }}
                />
              </Grid>}
            </>
          }
        </Grid>
      </div>

      <ModalFilterQuery
        handleSearchClick={handleSubmitFilters}
        open={openFilterModal}
        handleClose={handleCloseFilterModal}
        title={i18n.job_offers.filter_offers}
      >
        <FilterFormQuery module={module}
                         filters={jobOffersFilters}
                         filtersValues={tempFilters}
                         updateFiltersValues={updateTempFilters}
                         overrideFilters={true}
                         overrideSwitchActive={showActiveNames}
        />
      </ModalFilterQuery>

    </JobOffersStyled>
  );
};

const DropdownIndicator = () => null

export default JobOffers;
