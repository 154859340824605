import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withI18n} from '../../hocs'
import { ProcessesOperations } from '../../../redux/processes'

const mapStateToProps = state => ({state})

const mapDispatchToProps = dispatch => {
  return {
    exportCsvOnBackground: ( fields, filters, sort, module, isPlanning, receivers ) => dispatch(ProcessesOperations.exportCsvOnBackground( fields, filters, sort, module, isPlanning, receivers )),
  }
}

const enharce = compose(connect(mapStateToProps, mapDispatchToProps), withI18n)

export default enharce(View)
