import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withI18n} from '../../hocs'

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => {
  return {
  }
}

const enharce = compose(connect(mapStateToProps, mapDispatchToProps), withI18n)

export default enharce(View)
