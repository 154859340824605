export const objectToShow = (obj) => {
  return Object.entries(obj).map(([key, value]) => `${key}: ${value}`).join(", ");
}

export const arrayToShow = (arr) => {
  return arr.join(", ");
}


export const jsonToShow = (json) => {
  try {
    const obj = JSON.parse(json);
    if (obj instanceof Array) {
      return arrayToShow(obj);
    } else if (objectToShow(obj)) {
      return objectToShow(obj);
    } else {
      return obj;
    }
  }catch (e) {
    return json;
  }
}