import styled from "styled-components";
import { RegularSelect } from "../../atoms";
import React from "react";

export const RegularSelectStyled = styled(props => <RegularSelect {...props}/>)`

    && {
        border: 3px solid red !important;
    }

    select {
        text-align: left !important;
        line-height: 37px !important;
    }
`