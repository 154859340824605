import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import {QUERY_KEYS} from "../../config/constants";
async function getPerson({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  let [_key, {slug}] = queryKey;
  if (!slug) return;

  return get(`/api/person/${slug}`);
}

export default function usePerson(slug) {
  return useQuery([`${QUERY_KEYS.PERSON}:${slug}`, {slug}], getPerson, {enabled: slug !== null && slug !== undefined});
}
async function getPersonWithCandidature({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  let [_key, {slug, jobOfferApplicationId}] = queryKey;
  if (!slug) return;

  return get(`/api/job_offer_application/${jobOfferApplicationId}/person/${slug}`);
}

export function usePersonWithCandidature(slug, jobOfferApplicationId) {
  return useQuery([`${QUERY_KEYS.PERSON}:${slug}:${jobOfferApplicationId}`, {slug, jobOfferApplicationId}], getPersonWithCandidature, {enabled: slug !== null && slug !== undefined && jobOfferApplicationId !== null && jobOfferApplicationId !== undefined});
}

