import styled from 'styled-components'

const JobOfferWizardStyled = styled.div`
  .containerHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    padding: 36px 49px;
    border-radius: 25px;
    background: #ffffff;
    gap: 50px;
  }

  .sectionSecond, .headerSection {

    label {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0px;
      margin-right: 20px;
    }
    input {
      font-size: 14px;
      font-family: 'Poppins';
    }

    & h2 {
      font-size: 28px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0;
    }
    & h5 {
      color: #7a808d;
      font-weight: 400;
      line-height: inherit;
    }

    & p {
      font-weight: 400;
      color: #7a808d;
    }
  }
    
  .fieldsSection {
      display: flex;
      flex-direction: column;
      margin: 16px;
  }
    
  .columnGroup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 50%;
      gap: 32px;
      
      textarea, textarea::placeholder, .DateInput_input, .MuiAutocomplete-input, .MuiAutocomplete-popper {
          font-size: 14px;
          font-weight: 400;
      }
      
      .input-container input, textarea, .SingleDatePickerInput {
          min-width: 400px;
          width: 400px;
      }
      .input-container input, textarea, .SingleDatePickerInput {
          border: solid 1px lightgrey;
      }
      .SingleDatePickerInput {
          border-radius: 5px;
      }
      .DateInput {
          width: calc(100% - 60px);
      }
  }
    
  .autocomplete-custom {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 82px !important;
    width: 400px !important;
    min-width: 400px!important;
  }

  .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-input:first-child {
    padding-left: 12px !important;
  }
  .MuiAutocomplete-inputRoot {
    padding: 4px !important;
    height: 40px;
  }
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 12px) !important;
  }
`

export default JobOfferWizardStyled
