import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  .input-editor {
    .ql-container.ql-snow {
      border: 0;
    }

    .ql-editor {
      min-height: 130px;
      background: ${colors.white};
      border: 1px solid ${colors.greyAlpha50};
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px 15px;
      font-weight: normal;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.03em;
      color: ${colors.black};
      outline: none;
      width: 100%;
      resize: none;
      font-family: Poppins, sans-serif;

      ::before {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;
        letter-spacing: 0.03em;
        font-family: Poppins, sans-serif;
        color: ${colors.blackAlpha40};
      }

      ${props =>
        props.fontSize === 'small' &&
        css`
          font-size: 14px;
          line-height: 21px;

          ::before {
            font-size: 14px;
            line-height: 21px;
          }
        `}
    }
  }
`

export default ComponentStyled
