import React from 'react'

const icon = props => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path d="M21.9524 9.54167C21.8272 9.20833 21.5351 8.95833 21.1596 8.95833H14.7752L12.814 2.58333C12.6889 2.25 12.355 2 11.9795 2C11.6039 2 11.2701 2.25 11.1449 2.58333L9.18375 8.95833H2.84116C2.46561 8.95833 2.13179 9.16667 2.04834 9.54167C1.92315 9.875 2.04834 10.25 2.34043 10.5L7.51465 14.5L5.51172 20.875C5.38654 21.25 5.51172 21.625 5.84554 21.8333C6.13764 22.0417 6.55491 22.0417 6.84701 21.7917L11.9795 17.9167L17.0703 21.8333C17.2372 21.9583 17.4041 22 17.571 22C17.7379 22 17.9048 21.9583 18.0717 21.8333C18.3638 21.625 18.5307 21.25 18.4055 20.875L16.4443 14.4583L21.6186 10.5C21.9524 10.25 22.0776 9.875 21.9524 9.54167Z" stroke="white" strokeWidth="2" strokeLinejoin="round" />
      </g>
    </svg>
)

export default icon
