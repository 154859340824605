import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withStyles} from '@material-ui/core/styles'
import {withI18n} from '../../hocs'
import styles from './styles'
import {SaveSearchesOperations} from '../../../redux/savesearches'

const mapStateToProps = state => {
  return {
    searchFilters: state.saveSearches.filters,
    searchFilterTitle: state.saveSearches.filterTitle,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetFilters: () => dispatch(SaveSearchesOperations.filtersClear()),
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withStyles(styles)
)

export default enharce(View)
