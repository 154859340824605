import React, {useState} from 'react'
import ArrowDropDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Grid from '@material-ui/core/Grid'
import {toast} from 'react-toastify'
import PropTypes from 'prop-types'
import ProfileButtonsStyled, * as S from './styled'
import {BackButton, SimpleMenu} from '../../atoms'
import {AlertDialog} from '../../molecules'
import {getCurrentSectionKey, MODULES} from '../../../config/constants'
import {print} from '../../../utils/print'
import routes from "../../../config/routes";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/KeyboardArrowLeft";
import ArrowForward from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";
import useTotalCount from "../../../api/queries/useTotalCount";

const HeaderPofile = ({
                        history,
                        i18n,
                        data,
                        addFav,
                        className,
                        deleteOnboarding,
                        match,
                        onChangeProfile,
                        userId,
                        dataInboxRows,
                        tablePage,
                        tableSetPage,
                        tableProcessFeching,
                        tablePerPage,
                        filters
                      }) => {

  const {data: totalRQuery } = useTotalCount(
    MODULES.currentModule(),
    getCurrentSectionKey(),
    filters
  );

  const anchorRef = React.useRef(null)
  const [removeModal, setRemoveModal] = React.useState(false)
  const actionsOptions = [
    {
      value: 'markFavorite',
      text: data && data.is_favorite ? i18n.headerProfileButtons.removeFavorites : i18n.headerProfileButtons.markFavorite
    },
    {value: 'print', text: i18n.headerProfileButtons.print},
    {value: 'removeOnboarding', text: i18n.headerProfileButtons.remove}
  ]

  const handleClickActionsButton = item => {
    switch (item) {
      case 'markFavorite':
        addFav(data.id, !data.is_favorite, MODULES.ID_ON_BOARDING)
        break
      case 'print':
        print('profilePrint')
        break
      case 'removeOnboarding':
        setRemoveModal(true)
        break
      default:
        break
    }
  }

  const ButtonTitle = ({title}) => (
    <>
      <span>{title}</span> <ArrowDropDownIcon/>
    </>
  )

  const handleDeleteOnboarding = () => {
    if (data.id) {
      deleteOnboarding(data.id, MODULES.ID_ON_BOARDING).then(() => {
        toast.success(i18n.onboarding.deleteSuccess, {
          position: toast.POSITION.BOTTOM_CENTER
        })
        history.push(routes.onboarding + '?keepFilters=1');
      })
    }
  }

  const [id, setId] = useState(userId || _.get(match, 'params.slug'))
  const [loadingNextPage, setLoadingNextPage] = React.useState(false)
  const [loadingPreviousPage, setLoadingPreviousPage] = React.useState(false)

  let currentTableRows
  let currentItemNum = 1

  const handleOnChangePage = (newId) => {
    setId(newId)
    onChangeProfile(newId)
  }

  const pageGetCurrentIndexFromTable = () => {
    return _.findIndex(currentTableRows, {id})
  }

  const hasNextPage = () => {
    return currentItemNum + 1 <= totalRQuery && totalRQuery.total ? totalRQuery.total : 0
  }

  const hasPreviousPage = () => {
    return currentItemNum - 1 >= 1
  }

  const getNextPageId = () => {
    const currentIndex = pageGetCurrentIndexFromTable()
    if (currentTableRows[currentIndex + 1]) {

      history.push(`/profile/${MODULES.ID_ON_BOARDING}/${currentTableRows[currentIndex + 1].id}`)

      return handleOnChangePage(currentTableRows[currentIndex + 1].id)
    } else {
      changePageInTable(+1)
    }
    return false
  }

  const getPreviousPageId = () => {
    const currentIndex = pageGetCurrentIndexFromTable()
    if (currentTableRows[currentIndex - 1]) {

      history.push(`/profile/${MODULES.ID_ON_BOARDING}/${currentTableRows[currentIndex - 1].id}`)
      return handleOnChangePage(currentTableRows[currentIndex - 1].id)
    } else {
      changePageInTable(-1)
    }
    return false
  }


  const changePageInTable = (sumOrRest) => {
    let newTablePage
    newTablePage = tablePage + sumOrRest
    tableSetPage(newTablePage)

    if (sumOrRest > 0) {
      setLoadingNextPage(newTablePage)
    } else {
      setLoadingPreviousPage(newTablePage)
    }
  }

  const onInit = () => {

    let currentPage = tablePage
    let currentPerPage = tablePerPage
    let currentIsProcessing = tableProcessFeching

    // wait until data is loaded
    if (
      (loadingNextPage !== false && loadingNextPage !== currentPage) || currentIsProcessing
      || (loadingPreviousPage !== false && loadingPreviousPage !== currentPage) || currentIsProcessing
    ) {
      return false
    }

    currentTableRows = dataInboxRows.data

    if (!id || !currentTableRows) {
      return false
    }

    if (loadingNextPage !== false && currentTableRows[0].itemCount === (loadingNextPage * currentPerPage) + 1) {
      handleOnChangePage(currentTableRows[0].id)
      setLoadingNextPage(false)
      return false
    }

    if (loadingPreviousPage !== false && currentTableRows[0].itemCount === ((loadingPreviousPage * currentPerPage) + 1)) {
      handleOnChangePage(currentTableRows[currentPerPage - 1].id)
      setLoadingPreviousPage(false)
      return false
    }

    if (!currentTableRows[pageGetCurrentIndexFromTable()]) {
      return false
    }

    currentItemNum = currentTableRows[pageGetCurrentIndexFromTable()].itemCount

    return true
  }

  if (!onInit()) {
    return null;
  }

  return (
    <ProfileButtonsStyled className={className}>
      <Grid container alignItems="center">
        <Grid item sm={3}>
          <BackButton title={i18n.headerProfileButtons.back} onClick={() => {
            localStorage.getItem('backLocation') ? history.push(localStorage.getItem('backLocation')) : history.goBack();
          }}/>
        </Grid>
        <Grid item sm={9} className={'buttons-wrapper'}>
          <S.ButtonContainer variant="contained" color="primary" ref={anchorRef} aria-label="Split button">
            <SimpleMenu options={actionsOptions} onChange={handleClickActionsButton}>
              <S.HeaderButton title={<ButtonTitle title={i18n.headerProfileButtons.actions}/>}/>
            </SimpleMenu>
          </S.ButtonContainer>

          <p className={"header-pagination-count"}>
            <span>
              <span className="currentItemNumber">{currentItemNum}</span> / {totalRQuery && totalRQuery.total ? totalRQuery.total : 0}
            </span>
          </p>
          <ButtonGroup color="primary" aria-label="Split button" className={"header-profile-arrows"}>

            <Button variant="contained"
                    onClick={() => getPreviousPageId()}
                    disabled={hasPreviousPage() === false}
            >
              <span>&#8205;</span>
              <ArrowBack/>
            </Button>

            <Button variant="contained"
                    onClick={() => getNextPageId()}
                    disabled={hasNextPage() === false}
            >
              <span>&#8205;</span>
              <ArrowForward/>
            </Button>

          </ButtonGroup>
        </Grid>
      </Grid>
      <AlertDialog open={removeModal} onClose={() => setRemoveModal(false)} onSuccess={handleDeleteOnboarding}
                   title={i18n.process.deleteModalTitle} text={i18n.process.deleteModalText}/>
    </ProfileButtonsStyled>
  )
}

HeaderPofile.propTypes = {
  i18n: PropTypes.object.isRequired,
  data: PropTypes.object,
  addFav: PropTypes.func,
  onGoBack: PropTypes.func,
  className: PropTypes.string,
  deleteOnboarding: PropTypes.func
}

export default HeaderPofile
