import styled from 'styled-components'
import { MTText } from '../../atoms'
import React from "react";

export const Container = styled.div``
export const Title = styled.h2`
  margin-bottom: 30px;
`
export const InfoList = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;

`
export const InfoItem = styled.div`
  display: flex;
`
export const InfoItemUnderlined = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Label = styled(props => <MTText {...props} />)``
export const Text = styled(props => <MTText {...props} />)`
  text-align: left;
`
export const UserName = styled.p`
  font-size: 36px;
  font-weight: bold;
`