import styled from 'styled-components'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { Page } from 'react-pdf'
import colors from '../../../assets/colors'
import React from "react";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 230px 1fr;
  grid-template-rows: 56px 1fr;
  grid-template-areas:
    'sidebar toolbar'
    'sidebar viewer'
    'sidebar viewer';
  overflow: hidden;
  flex: 1;
  
  &.embedded-true{
    display: initial;
    .doc-viewer{
      height: 800px;
      overflow: auto;
    }
  }
    
  .txtIframe {
      box-shadow: 0 4px 6px rgba(98,102,113,0.06),0 2px 2px rgba(98,102,113,0.06);
      border-radius: 6px;
      overflow-x: hidden;
      background-color: #fff;
      border: none;
  }
`

export const Sidebar = styled.div`
  grid-area: sidebar;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Toolbar = styled.div`
  grid-area: toolbar;
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  border: solid 2px ${({ theme }) => theme.colors.greyAlpha25};
  border-top: none;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 10px 0px ${({ theme }) => theme.colors.greyAlpha25};
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
`

export const Viewer = styled.div`
  grid-area: viewer;
  position: relative;
  padding: 10px;
  overflow: auto;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgba(98, 102, 113, 0.06), 0px 2px 2px rgba(98, 102, 113, 0.06);
  
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({ underlined, theme }) => (underlined ? `solid 1px ${theme.colors.greyAlpha25}` : 'none')};
`
export const Item = styled.div`
  position: relative;
  display: inline-block;
  margin: 10px;
  text-align: center;
`

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction:  column;
`

export const PagePdf = styled(props => <Page {...props}/>)`
  padding: 10px;
  padding-bottom: 30px;
  canvas {
    display: inline-block !important;
    box-shadow: 0px 7px 12px ${({ theme }) => theme.colors.grey};
  }
`

export const Image = styled.img`
  box-shadow: 0px 7px 12px ${({ theme }) => theme.colors.grey};
`
export const IconPlaceholder = styled(props => <InsertDriveFileIcon {...props}/>)`
    width: 100px !important;
    height: 100px !important;
    fill: ${colors.grey} !important;
`

export const PlaceholderFormat = styled.div`
    font-size: 20px;
`