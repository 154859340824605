import React from "react";

export const breakSvgText = (text, cx, cy, moveLineX = 0, moveLineY = 0, countBreak = 12, dy = 12, textAnchor = "middle") => {
  text = text.split("");
  let pieces = [];
  let count = 0;
  let line = "";

  text.map((character, index) => {
    line += character;
    count++;
    if ((count > countBreak && character === ' ' && pieces.length <= 5) || index === text.length - 1) {
      if (pieces.length === 4 && index < text.length - 1) {
        line += '...';
      }
      pieces.push(line);
      count = 0;
      line = "";
    }
    return null;
  });

  pieces = pieces.slice(0, 5);

  return <text x={cx + (pieces.length * moveLineX)} y={cy + (pieces.length * moveLineY)} dy={dy} textAnchor={textAnchor}>
    {pieces.map((piece, index) => <tspan key={index} x={cx + (pieces.length * moveLineX)} dy={dy}>{piece}</tspan>)}
  </text>
}

export const ellipsisFromXCharacter = (text, xCharacter) => {
  text = text.split("");
  if (text.length > xCharacter) {
    text = text.slice(0, xCharacter - 1);
    text = text.join("");
    text += "...";
  }
  return text;
}