import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab'
import { CustomAlertStyled } from './styled';

const CustomAlert = ({ severity, message, alertTitle, variant, fixed = false }) => {
  const messages = Array.isArray(message) ? message : [message];

  return (
    <CustomAlertStyled fixed={fixed}>
        <Alert className="alertCustom" severity={severity} variant={variant} >
            <AlertTitle>{alertTitle}</AlertTitle>
          {messages.map((str, idx) => (
            <React.Fragment key={idx}>
              {str}
              {idx !== messages.length-1 && <br />}
            </React.Fragment>
          ))}
        </Alert>
    </CustomAlertStyled>
  );
};

export default CustomAlert;
