import styled from 'styled-components'
import colors from '../../../assets/colors'

const ProfileStatsStyled = styled.div`
  && {
    padding: 15px 0;
  }

  .user-name-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${colors.grey4} !important;
    font-family: 'inter';

    .user-name-email {
      font-size: 20px;
      word-break: break-all;
    }

    svg {
      margin-left: 15px;
    }
  }

  #svgFavorite{
    margin-left: 0;
    margin-right: 10px;
    transform: scale(1.2);
  }
  
  .profile_dates {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    align-items: end;
    flex-wrap: wrap;
  }
  
  .isPullAcceptedIcon {
    font-size: 32px;
    margin-left: 12px;
    position: relative;
    top: 3px;
  }
  
  .date_value {
    font-weight: 700;
  }

  .col-profile-match {
    display: flex;
    justify-content: space-between;
    flex-flow: column;
  }

  .user-info {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: ${colors.grey5};
    
    span{
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      margin: 5px;
      color: ${colors.grey4};
    }
  }
  }

  #profileStatsDataContainer{
    padding-left: 52px;
    padding-right: 52px;
  }

  #info-contact-container{
    display: flex;
    flex-direction: row;

    .user-contact{
      font-weight: 600;
      font-size: 14px;
      line-height: 32px;
      color:${colors.grey3};
      margin:0;
    }
  }
  #info-contact-container div p {
    display:flex;
    align-items:center;
  }
  #info-contact-container div p svg{
    margin-right:10px;
  }

  #tagTextStyle{
    display: inline-block;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: #494D55;
  }

  // #imageContainer{
  //   border-right: 1px solid #E4E6E8;
  // }

  #tagsContainer{
    margin-bottom: 20px !important;
  }

  .match-wrapper {
    .linear-percent {
      color: ${colors.orange};
    }

    .MuiLinearProgress-bar {
      background-color: ${colors.orange};
    }
  }

  @media print {
    
    .job-title {
      color: ${colors.grey4}!important;
      svg {
        display: none !important;
      }
    }

    .profile_dates {
      flex-direction:column;
    }

    #info-contact-container {
      display:inherit;
    }

    #ProfileActionsButtons {
      margin-right: 15px;
    }
    
    .profileStatsContainer {
      margin: 0!important;
    }
    
    #profileStatsDataContainer{
      padding: 0!important;
    }
    
    .match-wrapper {
      .linear-percent {
        color: ${colors.orange}!important;
      }
    }

    .user-info {
      font-size: 16px !important;
    }
  }
`

export default ProfileStatsStyled
