import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import colors from '../../../assets/colors'
import React from "react";

export const ModalStyled = styled.div`
  && {
    border-radius: 8px;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    width: 685px;
    outline: none;
    margin-bottom: 50px;

    .modal-body {
      background-color: ${colors.white};
      padding: 30px 50px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      outline: none;
      .grid-decoration {
        position: relative;
        ::before {
          content: '-';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -4px;
        }
      }
      label {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
      .sms {
        margin-top: 15px;
      
        .box-check {
          margin-left: 10px;
        }
      }

      .button-wrapper {
        text-align: right;

        button {
          margin-top: 25px;
        }
      }
    }
  }
`

export const ButtonExport = styled(props => <Button {...props}/>)`
    margin-right: 10px !important;
`