import Chip from '@material-ui/core/Chip'
import styled from 'styled-components'
import React from 'react'

export const ChipStyled = styled(props => <Chip {...props} />)`
  width: fit-content !important;
  font-family: 'Poppins';
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)} !important;
  font-size: ${props => (props.fontWeight ? props.fontWeight : "12px")} !important;
  line-height: 16px !important;
  margin: ${props => (props.margin ? props.margin : "0 5px 5px 10px")} !important;
  text-align: center;
  color: #ffffff !important;
  background-color: ${props => (props.color ? props.color : '#e0e0e0')} !important;
  align-items: center;
  padding: 0px 0px 0px 12px;
  width: 100px;
  height: 24px !important;

  svg {
    width: unset !important ;
    height: unset !important ;
    margin-right: 11px !important;
  }
`
