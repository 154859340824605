import { FavoritesActions } from './'
import 'core-js'

export const initFavorites = (module) => (dispatch) => {
  dispatch(resetFavorites())
  dispatch(fetchFavorites(module))
}

export const resetFavorites = () => (dispatch) => {
  dispatch(FavoritesActions.setData([]))
}

export const fetchFavorites = (module) => (dispatch, getState, { api, handleError }) => {
  dispatch(FavoritesActions.setIsFetching(true))

  return api
    .getFavorites(module)
    .then(reponse => dispatch(FavoritesActions.setData(reponse)))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(FavoritesActions.setIsFetching(false)))
}

export const postFavorites = (ids, module) => (dispatch, getState, { api, handleError }) => {
  dispatch(FavoritesActions.setIsFetching(true))

  return api
    .postFavorites(ids, module)
    .then(() => dispatch(fetchFavorites()))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(FavoritesActions.setIsFetching(false)))
}

export const deleteFavorites = (ids, module) => (dispatch, getState, { api, handleError }) => {
  dispatch(FavoritesActions.setIsFetching(true))

  return api
    .deleteFavorites(ids, module)
    .then(() => dispatch(fetchFavorites()))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(FavoritesActions.setIsFetching(false)))
}
