import React, {useEffect, useState} from 'react'
import {AppBarInbox, PlanningTable} from '../../organism'
import {MODULES} from '../../../config/constants'
import Grid from "@material-ui/core/Grid";
import {DateRangePiker} from "../../molecules";
import {Badge} from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import moment from "moment";
import PlanningCountersStyled from "./styled";
import {getCitationWhatsappCounter} from "../../../api";

const Planning = ({
                    classes,
                    i18n,
                    client,
                    filtersInState,
                    // reduxWhatsappCounter
}) => {

  const [startDate, setStartDate] = useState(moment().add(-7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [focused, setFocused] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [whatsappCounter, setWhatsappCounter] = useState(null);

  useEffect(() => {
    fetchCitationCounters();
  }, []);

  // useEffect(() => {
  //   setWhatsappCounter(reduxWhatsappCounter)
  // }, [reduxWhatsappCounter]);

  const fetchCitationCounters = () => {
    let start = startDate.format('DD/MM/YYYY');
    let end = endDate.format('DD/MM/YYYY');
    let filters = {"date": {"from": start, "to": end}};
    setIsFetching(true);
    getCitationWhatsappCounter(filters).then((res) => setWhatsappCounter(res));
    setIsFetching(false);
  }

    return (
      <div className={classes.root}>
        <AppBarInbox
          showActions
          module={MODULES.ID_TALENT_ACQUISITION}
          filterName={i18n.appBar.filterPlanning}
          saveSearchOption={false}
          section={"planning"}
        />
        {client && client.whatsapp_available && filtersInState && Object.keys(filtersInState).length === 0 &&
          <PlanningCountersStyled>
          <Grid container className="title-container">
            <Grid item xs={12} sm={12} className="buttons-wrapper"
                  style={{display: "flex", justifyContent: "space-between", marginTop: 16}}
            >
              <DateRangePiker
                startDate={startDate}
                onStartDateChange={date => setStartDate(date)}
                endDate={endDate}
                onEndDateChange={date => setEndDate(date)}
                onFocusChange={({focused}) => setFocused(focused)}
                focused={focused}
                isFetching={isFetching}
                onSubmit={fetchCitationCounters}
              />
              {whatsappCounter &&
                <Badge badgeContent={whatsappCounter} color="error" max={1000000}>
                  <WhatsAppIcon className="counter_icon"/>
                </Badge>
              }
            </Grid>
          </Grid>
        </PlanningCountersStyled>
        }
        <PlanningTable module={MODULES.ID_TALENT_ACQUISITION}/>
      </div>
    )
}

export default Planning
