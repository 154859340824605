import styled from 'styled-components'
import { headerStatStyles, chartWrapperStyles } from '../dynamic-widget/styled'

const TableStatisticStyled = styled.div`
  && {
    padding: ${props => (props.isDashboard ? 0 : "30px 0")};
    height: ${props => (props.inheritHeight ? "auto" : "inherit")};
  }
  
  ${headerStatStyles}
  
  ${chartWrapperStyles}

  .chart-wrapper {
    display: block;
    padding: 0;
    z-index: 0;
    height: ${props => props.inheritHeight ? "auto" : (props.customHeight ? props.customHeight : '300px')};
    max-height: ${props => props.maxHeight ? `${props.maxHeight.replace('px', '')}px` : 'initial'};
    overflow-y: auto;
  }

  .MuiTableHead-root {
    th {
      span {
        font-weight: 600;
        line-height: 12px;
      }
    }
  }

  .MuiTableBody-root {
    tr:first-child td,
    tr td {
      padding-top: 10px;
      padding-bottom: 10px;
      span {
        font-weight: 600;
        line-height: 12px;
      }
    }
  }
`

export default TableStatisticStyled
