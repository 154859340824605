import styled from 'styled-components'

const PushOffersStyled = styled.div`
  .push_offer {
  
    &__header {
      margin: 50px 0 10px;
    }
  
    &__empty_content {
      text-align: center;
      position: relative;
      top: 50px;
    }
  }
  
  .SingleDatePicker_picker {
    z-index: 10;
  }
  
  .container_icon{
    margin-left: auto;
  }

  .counter_icon {
    width: 36px;
    height: 36px;
    color: #47C057;
  }

  .primereact-paginator {
    margin-top: 18px;
  
    .p-paginator-pages .p-paginator-page.p-highlight {
      background: lightgrey;
    }
  }

  .title-checkbox{
    display: flex;
    align-items: center;
    margin: 0;
  }

`

export default PushOffersStyled
