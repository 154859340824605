import React from 'react'

const icon = () => (
  <svg
    fill="none"
    stroke="white"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
    width="24" height="24"

  >
    <path stroke="none" d="M0 0h24v24H0z" opacity={0.6} />
    <path d="M13 7 A4 4 0 0 1 9 11 A4 4 0 0 1 5 7 A4 4 0 0 1 13 7 z" opacity={0.6} />
    <path d="M3 21v-2a4 4 0 014-4h4a4 4 0 014 4v2M16 3.13a4 4 0 010 7.75M21 21v-2a4 4 0 00-3-3.85" opacity={0.6} />
  </svg>
)

export default icon
