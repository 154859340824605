import React, {Component} from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import SaveSearchesTableStyled from './styled'
import {MTMultipleFields, MTText, MTToolbar} from '../../atoms'
import i18n from '../../../assets/i18n'
import * as API from '../../../api'
import moment from 'moment'
import _ from 'lodash'
import {withRouter} from 'react-router'

const MTTitle = rowData => <MTText value={rowData.title}/>
const MTDate = rowData => <MTText value={moment(rowData.date).format('DD/MM/YYYY')}/>
const MTFields = rowData => <MTMultipleFields values={JSON.parse(rowData.fields)}/>

const columns = [
  {title: i18n.saveSearchesTable.title, field: 'title', render: MTTitle},
  {title: i18n.saveSearchesTable.date, field: 'date', render: MTDate},
  {title: i18n.saveSearchesTable.fields, field: 'fields', render: MTFields}
]

class SaveSearchesTable extends Component {
  constructor(props) {
    super(props)

    this.tableRef = React.createRef()

    this.state = {
      inboxData: [],
      selectedRows: ''
    }
  }

  componentDidMount() {
    const {location} = this.props;
    let reload = location.search.includes('reload=1');
    if (reload) {
      this.updateDataQuery();
    }
  }

  checkRow = event => {
    this.setState({selectedRows: event})
  }

  updateDataQuery = () => this.tableRef.current && this.tableRef.current.onQueryChange()

  clearSelectedRows = () => {
    this.setState({selectedRows: []})
    this.tableRef.current && this.tableRef.current.onAllSelected(false)
  }

  toolbarOptions = [
    {
      id: 'delete',
      label: i18n.saveSearchesTable.delete,
      onClick: () => {
        const {selectedRows} = this.state
        if (_.size(selectedRows) > 0) {
          const ids = _.map(selectedRows, 'id')
          this.props.deleteSaveSearches(ids).then(this.updateDataQuery)
          this.clearSelectedRows()
        }
      }
    }
  ]

  render() {
    const tableOptions = {
      selection: true,
      cellStyle: {textAlign: 'left !important'},
      paging: false,
      loadingType: 'linear',
      pageSize: 20,
      pageSizeOptions: [20, 50, 100, 200],
    }

    const tableComponents = {
      Container: props => <div {...props} />,
      Toolbar: () => (
        <MTToolbar module={this.props.module} options={this.toolbarOptions}
                   disabled={_.isEmpty(this.state.selectedRows)}>
        </MTToolbar>
      )
    }

    const onRowClick = (event, rowData) => {
      const saveSearchId = rowData.id
      this.props.history.push(`/${this.props.module}/saved-searches/${saveSearchId}`)
    }

    const newData = async query => {
      const {sort} = API.getQueryData(query)
      try {
        const result = await Promise.all([API.getSaveSearches(sort, this.props.module)])
        const saveSearchData = result[0]
        const totalData = saveSearchData.length
        this.props.setTotal && this.props.setTotal(totalData);
        return {data: saveSearchData, page: 0, totalCount: totalData}
      } catch (e) {
        return {data: [], page: 0, totalCount: 0}
      }
    }

    return (
      <SaveSearchesTableStyled>
        <MaterialTable
          tableRef={this.tableRef}
          columns={columns}
          onRowClick={onRowClick}
          data={newData}
          options={tableOptions}
          onSelectionChange={this.checkRow}
          components={tableComponents}
          localization={i18n.tableLocalization}
        />
      </SaveSearchesTableStyled>
    )
  }
}

SaveSearchesTable.propTypes = {
  i18n: PropTypes.object.isRequired,
  searchFilters: PropTypes.object
}

const enharce = compose(withRouter)

export default enharce(SaveSearchesTable)
