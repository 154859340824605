import styled from 'styled-components'

export const DialogStyled = styled.div`
  && {
    padding: 20px;

    .list {
      overflow: auto;
      max-height: 50%;
    }

    .item {
      font-size: 15px;
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    .title {
      font-weight: bold;
    }

    .body {
      color: grey;
      font-size: 12px;
    }

    .button-wrapper {
      text-align: right;
    }
  }
`
