import React from 'react'
import {MTTextStyled, TooltipStyled} from './styled'
import LabelImportant from '@material-ui/icons/LabelImportant'
import Chip from '@material-ui/core/Chip'
// import Tooltip from '@material-ui/core/Tooltip'
import _ from 'lodash'

const Tag = ({ tag, tagsTypes, tagTypesReactQuery, colorDefault, shortVersion, ...rest }) => {

  let currentTag = _.find(tagTypesReactQuery ? tagTypesReactQuery : tagsTypes, { id: tag.id })

  if (!currentTag) {
    return <></>;
  }

  return (
    <TooltipStyled title={currentTag.description}>
      <MTTextStyled {...rest} >
        {/* {shortVersion ? <div className="short-version" style={{backgroundColor: currentTag.color || colorDefault}}></div> : <Chip icon={icon} label={currentTag.name} style={{backgroundColor: currentTag.color || colorDefault}}/>} */}
        {shortVersion ? <div className="short-version" style={{backgroundColor: currentTag.color || colorDefault}}></div> : <Chip label={"#"+ (currentTag.name)}  style={{color: currentTag.color || colorDefault}}/>}
      </MTTextStyled>
    </TooltipStyled>

  )
}

Tag.defaultProps = {
  colorDefault: '#000',
  fontSize: '1.2em',
  icon: <LabelImportant />,
  shortVersion: false
}

export default Tag
