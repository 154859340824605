import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Icon = () => (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns = "http://www.w3.org/2000/svg" >
        <path d="M9 6.75V11.25" stroke="#7A808D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.75 9H11.25" stroke="#7A808D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </SvgIcon>
)

export default Icon