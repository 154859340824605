import styled from 'styled-components'
import colors from '../../../assets/colors'

const ProfileInfoStyled = styled.div`

  #firstSectionStyle, #boxOtherInfoBox, #boxObsevationsContainer, .documentsInfoBody {
    box-shadow: 0 4px 6px rgba(98, 102, 113, 0.06), 0 2px 2px rgba(98, 102, 113, 0.06);
    border-radius: 6px;
  }

  #observations-wrapper {
    padding: 0;
  }

  #ProfileActionsButtons {
    justify-content: flex-end;
    align-items: center;
  }

  .tabpanel_box {
    margin: 35px 0 12px -16px;
  }

  #firstSectionStyle {
    overflow: hidden;
    background-color: ${colors.white};
  }

  #analiticSection {
    border-top: 4px dotted #E4E6E8;
    padding-top: 38px;
  }

  .divider-horizontal {
    width: 100% !important;
    margin: -1px !important;
  }

  .profileActionButtonsWrapper .divider-horizontal {
    margin-top: 10px!important;
  }

  .divider-vertical {
    margin: -1px !important;
  }

  #imageContainer {
    align-self: start;
  }

  .divider-profile-stats {
    position: relative !important;;
    height: 163px !important;;
  }

  .divider-box-infoheader {
    top: 20px !important;
    position: relative !important;
    height: 110px !important;
  }

  #boxProfileCiteGridContainer {
    padding-right: 35px;
  }

  #documentsInfoContainer {
    margin-top: 35px;
  }
  .documentsInfoBody {
    background-color: #fff;
  }

  .documentsInfoTitle {
    padding: 15px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: ${colors.grey4};
    margin-bottom: 0;
    margin-right: 3px;
  }
  
  .tab-item, .candidatureTabs {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid rgba(148,163,178,0.25);
    padding: 15px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: ${colors.grey4};
    margin-bottom: 0;
    background-color: rgba(148,163,178,0.18);
    margin-right: 3px;
  }
  
  .candidature_tabs_container .MuiTabs-indicator {
    display: none!important;
  }
  
  .candidature_tab_selected {
    background-color: #fff!important;
    font-weight: 600!important;
    opacity: 1!important;
    border-bottom: solid 2px ${props => props.theme.primary};
  }

  .candidature_tab_not_selected {
    background-color: rgba(148,163,178,0.18)!important;
    font-weight: 400!important;
    opacity: 0.5!important;
  }

  @media print {
    .profileStatsInfo-print{
      justify-content:space-between;
    }
    .otherInfoContainer {
      width: 50%;
      //padding: 35px 24px 35px 55px;
      margin-top: 20px;
      p.value{
        font-size: 11px !important;
      }
    }
    

    #boxInfoHeaderGridContainer {
      width: 51%;
    }

    #boxProfileCiteGridContainer {
      padding-right: 0 !important;
      padding-left: 24px !important;
      margin-top: -4px;
    }

    .interview-otes-title-print{
      display: block !important;
      font-size: 18px !important;
    }
  }
    
  //InterviewNotes
  #interviewer_notes_container{
    background-color: ${colors.white};
    box-shadow: 0 4px 6px rgb(98 102 113 / 6%), 0 2px 2px rgb(98 102 113 / 6%);
    min-height:70px;
    padding: 20px 30px;
  }

  .interviewerNote {
    font-size: 15px;
  }

  .interviewerNote_metadata{
    color: gray;
    margin-top: 10px;
  }
  
  //Tabs
  .tab-item {
    text-transform: none;
    padding: 0 15px;
  }
  .tab-item.Mui-selected{
    background-color: #fff;
  }
  
  .tab-item:not(.Mui-selected) {
    font-weight: 400;
    
  }
  
  .interview-otes-title-print{
    display:none;
  
  }
  
  .offer_table_container table th:first-child,.offer_table_container table th:nth-child(2),.offer_table_container table th:nth-child(3) {
    text-align: left!important;
  }
  
  .offer_description {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    text-align: start;
    color: black;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .offer_user_name {
    text-transform: capitalize;
  }

`



export default ProfileInfoStyled
