import React from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'
import {withI18n} from '../../hocs'
import Grid from '@material-ui/core/Grid'
import {ModalStyled} from './styled'
import Modal from '@material-ui/core/Modal'
import {InputEditor, InputField, ModalHeader, RegularButton} from '../../atoms'
import {SimpleDialog} from '../../molecules'
import {ReactComponent as ModalIcon} from '../../../assets/images/calendar.svg'
import * as api from '../../../api'
import {MODULES} from "../../../config/constants";

const ModalMailCustom = ({ open, handleClose, i18n, onSubmit, subjectDefault, messageDefault }) => {
  const [openPopup, setOpenPopup] = React.useState(false)
  const [subject, setSubject] = React.useState(subjectDefault || '')
  const [message, setMessage] = React.useState(messageDefault || '')

  const [defaultMessages, setDefaultMessages] = React.useState([])

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await api.getDefaultMessages(MODULES.ID_EMPLOYEE_LINE)
      setDefaultMessages(result)
    }
    fetchData()
  }, [])

  function handleClickOpenPopup() {
    setOpenPopup(true)
  }

  const handleClosePopup = value => {
    setOpenPopup(false)

    if (value) {
      const subject = value.subject || ''
      const body = value.body || ''

      setSubject(subject)
      setMessage(body)
    }
  }

  const clear = () => {
    setSubject('')
    setMessage('')
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleClose()

    onSubmit({
      subject,
      message
    })

    clear()
  }

  const submitDisabled = _.isEmpty(subject) || _.isEmpty(message)
  return (
    <>
      <Modal
        className="modal-announcement-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={i18n.modalMailCustom.quoteCall} closeClick={handleClose} />
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalMailCustom.subject}</label>
                  <InputField
                    fontSize="small"
                    placeholder={i18n.modalMailCustom.writeSubject}
                    name="subject"
                    value={subject}
                    handleOnChange={value => setSubject(value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalMailCustom.message}</label>
                  <InputEditor
                    fontSize="small"
                    placeholder={i18n.modalMailCustom.writeMessage}
                    name="message"
                    value={message}
                    onChange={value => setMessage(value)}
                  />
                </Grid>
              </Grid>
              <div className="button-wrapper">
                <p onClick={handleClickOpenPopup}>{i18n.modalMailCustom.defaultMessages}</p>
                <SimpleDialog defaultMessages={defaultMessages} open={openPopup} onClose={handleClosePopup} />
                <RegularButton type="submit" title={i18n.modalMailCustom.send} disabled={submitDisabled} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(ModalMailCustom)
