import styled from 'styled-components'

const ModalHeaderProcessStyled = styled.div`
  .header-modal {
    background-color: #ffffff;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 50px 50px 5px 50px;
    display: flex;
    align-items: start;
    position: relative;
    justify-content: space-between;

    path {
      fill: #1c1c1c;
    }

    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #1c1c1c;
      margin: 0;
      font-family: Poppins;
    }
  }

  .close-icon {
    display: flex;
    right: 65px;
    cursor: pointer;
  }
`

export default ModalHeaderProcessStyled
