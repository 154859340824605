import React from 'react'
import PropTypes from 'prop-types'
import { ChipStyled } from './style';
import { XIcon } from '..';

const ChipCustom = ({ label, color, onDelete, targetId, fontSize, fontWeight, margin }) => (
    <ChipStyled targetId={targetId} onDelete={onDelete} deleteIcon={<XIcon />} label={label} color={color} onClick={onDelete} fontSize={fontSize} fontWeight={fontWeight} margin={margin}/>
)

ChipCustom.propType = {
    label: PropTypes.string,
    color: PropTypes.string,
    onDelete: PropTypes.func,
    targetId: PropTypes.number
}

export default ChipCustom;