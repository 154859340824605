import _ from 'lodash'

/* eslint-disable no-console */
export const handleError = error => (dispatch, getState, { toast, i18n }) => {

  let message = ''
  if (_.get(error, 'code') === 401) {
    message = 'Se ha cerrado la sesión'
  } else if (_.get(error, 'code') === 400) {
    _.forEach(_.get(error, 'msg'), (value, key) => {
      message = `${message}\n${key}: ${value}`
    })
  } else {
    message = i18n.errors.internalServerError
  }

  toast(message, { type: toast.TYPE.ERROR })
}
