import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {AppBarInbox, ProcessTable, ProfileInfo, SaveSearchesTable} from '../../organism'
import {PageTitleSubtitle} from '../../atoms'
import {MODULES} from '../../../config/constants'

const SavedSearches = ({ classes, i18n, searchFilters, searchFilterTitle, resetFilters, filterGroupSelected }) =>{
    const [total, setTotal] = useState(0);

  useEffect(() => {
    resetFilters()
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.root}>
      <AppBarInbox
        showActions
        module={MODULES.ID_TALENT_ACQUISITION}
        saveSearchOption={false}
        canEditFilters={false}
        canCloneSearch
        forceTotal={total}
        section={"savedSearches"}
      />
      {_.isEmpty(searchFilters) ? (
          <SaveSearchesTable module={MODULES.ID_TALENT_ACQUISITION} setTotal={setTotal}/>
      ) : (
        <>
          <PageTitleSubtitle title={i18n.inboxPeople.filtersSearch} subtitle={searchFilterTitle} module={MODULES.ID_TALENT_ACQUISITION} />
          <ProcessTable
            showFilterGroup
            showChangeStatus
            filters={searchFilters}
            filterGroupSelected={filterGroupSelected}
            module={MODULES.ID_TALENT_ACQUISITION}
            rowViewRender={(id) => <ProfileInfo id={id} />}
            resetFiltersOnLoad={false}
          />
        </>
      )}
    </div>
  )
}

export default SavedSearches
