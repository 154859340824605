import { useQuery } from '@tanstack/react-query'
import {getHtmlFromTxt} from "../index";

async function getHtmlFromTxtAction({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  const [_key, {txt}] = queryKey;
  if (!txt) return;
  return await getHtmlFromTxt(txt);
}

export function useHtmlParsedTxt(txt) {
  return useQuery(["utils:html_from_txt", {txt}], getHtmlFromTxtAction);
}
