export const iso8601Format = (date = null) => {
  if (! date || date instanceof Date == false) {
    date = new Date();
  }
  let yyyy = date.getFullYear().toString();
  let MM = (date.getMonth() + 1).toString().padStart(2, '0');
  let dd = date.getDate().toString().padStart(2, '0');
  let hh = date.getHours().toString().padStart(2, '0');
  let mm = date.getMinutes().toString().padStart(2, '0');
  let ss = date.getSeconds().toString().padStart(2, '0');

  return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
}