import styled from 'styled-components'
import colors from '../../../assets/colors'

const CircleStatisticStyled = styled.div`
  && {
    padding: 30px 0;
  }
  .header-stat {
    background-color: ${props => props.theme.primary};
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 10px 50px;
    display: flex;
    align-items: center;

    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: ${colors.white};
      margin: 0;
      margin-left: 15px;
    }
  }

  .table-wrapper {
    background-color: ${colors.white};
    padding: 10px;
    z-index: 0;
  }

  .MuiTableHead-root {
    th {
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }

    th:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    th:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .MuiTableBody-root {
    td {
      padding-top: 20px;
      padding-bottom: 20px;
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`

export default CircleStatisticStyled
