import styled from 'styled-components'
import colors from '../../../assets/colors'

export const MTFieldsStyled = styled.div`
  .tags {
    background: ${colors.greyAlpha15};
    border-radius: 4px;
    display: inline-block;
    margin: 5px 15px 5px 0;
    padding: 10px;
    text-transform: ${props => props.useCapitalize};
    font-size: 16px;
    line-height: 24px;
  }
`
