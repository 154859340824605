import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import _ from "lodash";
import {ModalStyled} from "./styled";
import {ModalHeader, RegularButton} from "../../atoms";
import {ReactComponent as ModalIcon} from "../../../assets/images/user-white.svg";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import {updateCandidature} from "../../../api";
import {toast} from "react-toastify";
import {manageQueriesWithPrefix, refetchQueriesByKeys} from "../../../api/mutations/mutationCallbacks";
import {MODULES, QUERY_ACTIONS, QUERY_KEYS} from "../../../config/constants";
import {useAppContext} from "../../../context/appContext";
import { FeatureGuard } from '../../../utils/guard';
import moment from 'moment';
import { isJSON } from "../../../utils/validation";
import { jsonToShow } from "../../../utils/formatting";
import {useParams} from "react-router";

const EditFieldAction = ({i18n, profile, client, clientColor, updateProfileField, remoteName, defaultValue, phase = null}) => {
  const {module} = useAppContext();
  const {job_offer_application_id, slug} = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldLabel, setFieldLabel] = useState('');
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [formData, setFormData] = useState({});

  const getInputType = (remoteName) => {
    let dateFieldsPattern = ['_date'];
    let timeFieldsPattern = ['_time'];
    let dateTimeFieldsPattern = ['_at'];

    if (dateFieldsPattern.some(pattern => remoteName.endsWith(pattern))) {
      return 'date';
    } else if (timeFieldsPattern.some(pattern => remoteName.endsWith(pattern))) {
      return 'time';
    } else if (dateTimeFieldsPattern.some(pattern => remoteName.endsWith(pattern))) {
      return 'datetime-local';
    }
    return 'text';
  };

  useEffect(() => {
    let editableFields = null;
    if (profile && phase) {
      editableFields = profile.editable_fields[phase];
    } else if (profile) {
      editableFields = profile.editable_fields;
    }

    if(!editableFields || !FeatureGuard.canAccessToEditableFields()) return setIsEditable(false);

    if (typeof editableFields == 'object' && !Array.isArray(editableFields)) {
      editableFields = [editableFields];
    }

    let editableFieldData = _.find(editableFields, {remote_name: remoteName});
    if (editableFieldData) {
      setFormData({
        dni: profile.dni,
        [phase ? 'onboarding_id' : 'candidature_id']: profile.id,
        candidatura_seleccionada_id: profile.candidatura_id,
        editingFieldData: {
          [phase ? 'onboardingId' : 'candidatureId']: profile.id,
          fieldName: editableFieldData.name,
          oldValue: editableFieldData.value,
          value: value,
        }
      });
      setIsEditable(true);
      setFieldLabel(editableFieldData.name);
      setValue(editableFieldData.value);
      let editableOptions = editableFieldData.options;

      editableOptions = editableOptions.sort(function (a, b) {
        if (!isNaN(a.value) && !isNaN(b.value)) {
          let valueA = +a.value;
          let valueB = +b.value;

          return valueA > valueB ? 1 : -1;
        }
        return a.value.localeCompare(b.value);
      });
      if (editableOptions.length > 0 && !_.find(editableOptions, {label: i18n.editFieldAction.empty})) {
        editableOptions.unshift({label: i18n.editFieldAction.empty, value: ''});
      }
      setOptions(editableOptions);
    } else {
      setIsEditable(false);
    }
  }, []);

  const handleOpenPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  const updateFormData = (remoteName, value) => {
    let editingFieldData = formData.editingFieldData;
    setFormData({...formData, [remoteName]: value, editingFieldData: {...editingFieldData, value: value}});
  }

  const handleSubmit = (event) => {
    event && event.preventDefault();
    setIsSubmitting(true);

    updateCandidature(formData, client, phase ? MODULES.ID_ON_BOARDING : MODULES.ID_TALENT_ACQUISITION).then(res => {
      setValue(res[remoteName]);
      setIsSubmitting(false);
      if (job_offer_application_id) {
        manageQueriesWithPrefix([`${QUERY_KEYS.PERSON}:${slug}`], QUERY_ACTIONS.REFETCH);
      } else {
        updateProfileField(profile, remoteName, res[remoteName], phase);
        handleClosePopup();
        if (module === MODULES.ID_TALENT_ACQUISITION) {
          refetchQueriesByKeys([
            `${module}:${QUERY_KEYS.INBOX}`,
            `${module}:${QUERY_KEYS.OBSERVATIONS}:${profile.id}`,
            `${module}:${QUERY_KEYS.PROFILE}:${profile.id}`
          ]);
        }
      }

    }).catch(() => {
      setIsSubmitting(false);
      toast.error(i18n.boxEditableInfo.error);
    });
  }

  const formattedValue = (value) => {
    if (_.isString(value) && (
      moment(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid() ||
      moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() || 
      moment(value, 'YYYY-MM-DD', true).isValid() 
    )) {
      return moment(value).format('DD/MM/YYYY');
    } else if (_.isString(value) && isJSON(value)) {
      return jsonToShow(value);
    } else if (!_.isArray(value) && !_.isObject(value)) {
      return value;
    }
  }

  return (<>
    {value ? formattedValue(value) : (value == null && (defaultValue || defaultValue === 0) ? formattedValue(defaultValue) : i18n.editFieldAction.empty)}
    {isEditable ?
      <>
        <button style={{backgroundColor: "transparent", border: "none"}} onClick={handleOpenPopup}>
          <i className="fa fa-pencil" aria-hidden="true" style={{color: clientColor}}></i>
        </button>
        <Modal
          className="modal-announcement-wrapper"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={showPopup}
          onClose={handleClosePopup}
        >
          <ModalStyled>
            <ModalHeader Icon={<ModalIcon/>}
                         title={i18n.editFieldAction.title}
                         closeClick={handleClosePopup}/>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <Grid container alignItems="center">
                  <div style={{width: '100%', display: 'flex', flexDirection: "column", alignItems: "flex-start"}}>
                    <p className={'title-field'} dangerouslySetInnerHTML={{ __html: fieldLabel }}></p>
                    {options.length > 0 ?
                      <div style={{width: '100%'}}>
                        <Select
                          key={remoteName}
                          defaultValue={_.find(options, {value: value})}
                          isMulti={false}
                          options={options}
                          isClearable={false}
                          isDisabled={isSubmitting}
                          placeholder={i18n.modalFilter.select}
                          onChange={val=> {updateFormData(remoteName, val.value)}}
                        />
                        </div>
                      : <TextField
                          type={getInputType(remoteName)}
                          key={remoteName}
                          style={{width: '100%'}}
                          defaultValue={value}
                          disabled={isSubmitting}
                          onChange={target=> {updateFormData(remoteName, target.target.value)}}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleSubmit();
                            }
                          }}
                        />
                    }
                  </div>
                  <div>
                    <RegularButton
                        title={i18n.boxEditableInfo.cancel}
                        color={'#b73838'}
                        disabled={isSubmitting}
                        type="button"
                        style={{display: 'inline-block', padding: '5px 18px', margin: '0 8px', color: '#fff'}}
                        onClick={handleClosePopup}
                    />
                    <RegularButton
                      title={i18n.boxEditableInfo.accept}
                      color={'#56803b'}
                      disabled={isSubmitting}
                      type="button"
                      style={{display: 'inline-block', padding: '5px 18px', margin: 16, marginLeft: 0, color: '#fff'}}
                      onClick={handleSubmit}
                    />
                  </div>
                </Grid>
              </form>
            </div>
          </ModalStyled>
        </Modal>
      </>
      : null}
  </>)
}

EditFieldAction.propTypes = {
  remoteName: PropTypes.string,
  defaultValue: PropTypes.any,
}

export default EditFieldAction
