import React from 'react'
import PropTypes from 'prop-types'
import ArrowsDropDown from '@material-ui/icons/UnfoldMore'
import Button from '@material-ui/core/Button'
import colors from '../../../assets/colors'
import { MTEvaluationButtonStyled } from './styled'

const MTEvaluationButton = ({ evaluation, personal_data }) => {
  return (
    <MTEvaluationButtonStyled>
      <Button className="evaluation" onClick={() => alert('Pulsado Cambiar evaluacion ' + personal_data.name)}>
        <span className="value">{evaluation}</span>
        <ArrowsDropDown htmlColor={colors.darkGrey2} />
      </Button>
    </MTEvaluationButtonStyled>
  )
}

MTEvaluationButton.propTypes = {
  name: PropTypes.string,
  evaluation: PropTypes.string,
  personal_data: PropTypes.object
}
export default MTEvaluationButton
