import styled from 'styled-components'

const BoxPDAHeaderStyled = styled.div`
  
  && {
    padding: 30px 0 10px 0;
  }

  .title-line {
  
    .pda-logo{
      height: 20px
    }
  }
  
  
`

export default BoxPDAHeaderStyled
