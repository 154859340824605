import styled from 'styled-components'
import colors from '../../../assets/colors'

const BoxInfoHeaderStyled = styled.div`
  #boxInfoHeaderData {
    flex-basis: auto;
    padding-left: 32px;
    margin-top: 52px;;
  }
  
  #boxInfoHeaderData.talent_acquisition_profile {
    margin-top: 0;
  }

  .boxInfoHeaderDivider{
    width: 100% !important;
  }
  
  .profileContainer{
    margin-left: ${props => props.profileContainer};
    .title-profile{
      margin-left: ${props => props.profileTitle};
    }
  }
  
  @media print {
    #boxInfoHeaderData {
      padding-left: 35px!important;
    }
    .header-table {
      background: ${props => props.theme.primary}!important;
      -webkit-print-color-adjust: exact;

      p {
        color: ${colors.white}!important;
      }
    }
    #wrap-table {
      background-color: ${colors.white}!important;
    }
    .matchContainer{
      margin-left: 29px!important;
      .title-profile{
        margin-bottom: 10px!important;
        margin-left:-6px !important;
      }
    }
    .profileContainer {
      margin-left: 40px!important;
      .title-profile {
        margin-bottom: 10px!important;
        margin-left: -25px !important;
      }
    }
    }
  }

  && {
    border-radius: 8px;

    p {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.03em;
      position: relative;
    }

    .header-table {
      background: ${props => props.theme.primary};
      -webkit-print-color-adjust: exact;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 15px 30px;

      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.03em;
        color: ${props => props.theme.textPrimary};
        margin-bottom: 0;
        padding-left: 45px;

        svg {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          margin-right: 10px;

          path {
            fill: ${props => props.theme.textPrimary};
          }
        }
      }
    }

    .wrap-table {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .row-table {
      
      :first-child {
        border-top: 0;
        
      }

      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
      }

      .title {
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
      }

      #title-info-header{
        margin-top:2px;
      }
    }
  }
`

export default BoxInfoHeaderStyled
