export const SET_DATA = 'processes/SET_DATA'
export const SET_INITIAL_DATA = 'processes/SET_INITIAL_DATA'
export const SET_PAGE = 'processes/SET_PAGE'
export const SET_PER_PAGE = 'processes/SET_PER_PAGE'
export const SET_SORT = 'processes/SET_SORT'
export const SET_MODULE = 'processes/SET_MODULE'
export const RESET_DATA = 'processes/RESET_DATA'
export const SET_TOTAL = 'processes/SET_TOTAL'
export const SET_IS_FETCHING = 'processes/SET_IS_FETCHING'
export const SET_DATA_FIELDS = 'processes/SET_DATA_FIELDS'
export const SET_STATUS_TYPES = 'processes/SET_STATUS_TYPES'
export const SET_MANUAL_STATUS_TYPES = 'processes/SET_MANUAL_STATUS_TYPES'
export const SET_TAGS_TYPES = 'processes/SET_TAGS_TYPES'
export const SET_REPORT_FIELDS = 'processes/SET_REPORT_FIELDS'
export const SET_READY = 'processes/SET_READY'

