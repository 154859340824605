import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import routes from '../../../config/routes'
import { AvatarStyled } from './styled'

const ProfileAvatar = ({ styledType, profileRedirect, hideSurname, history, data, client, size }) => {

  function redirectToProfile() {
    if (profileRedirect) {
      history.push(routes.myProfile)
    }
  }

  const name = _.get(data, 'name', '')
  const surname = _.get(data, 'surname', '')

  const getInitials = () => {
    if (client) {
      return (client.name[0] + client.surname[0]).toUpperCase();
    } else if(name) {
      return (name[0] + surname[0]).toUpperCase();
    }
    return '';
  }

  return (
    <AvatarStyled size={size} styledType={styledType} onClick={redirectToProfile}>
      <p>
        {name} {hideSurname ? '' : surname}
      </p>

      <div style={{ backgroundImage: `url(${ client ? client.avatar : '' })` }} >
        {client && client.avatar ? '' : getInitials()}
      </div>
    </AvatarStyled>
  )
}

ProfileAvatar.defaultProps = {
  profileRedirect: true
}

ProfileAvatar.propTypes = {
  profileRedirect: PropTypes.bool,
  hideSurname: PropTypes.bool,
  styledType: PropTypes.string,
  i18n: PropTypes.object,
  onClick: PropTypes.func,
  history: PropTypes.object.isRequired,
  data: PropTypes.object,
  url: PropTypes.string,
  setUrl: PropTypes.func,
  client: PropTypes.object,
}

export default ProfileAvatar
