import {PushOffersActions} from './'
import 'core-js'
import {getPushOffers} from "../../api";

export const initPushOffers = (module, range, filters, showMyPushOffers= {}) => (dispatch) => {
    dispatch(PushOffersActions.setIsFetching(true))
    getPushOffers(module, range, filters, showMyPushOffers)
        .then(response => {
            dispatch(PushOffersActions.setData(response.data))
            dispatch(PushOffersActions.setTotalItems(response.total))
        }).finally(() => {
        dispatch(PushOffersActions.setIsFetching(false))
    })
}

export const resetPushOffers = () => (dispatch) => {
    dispatch(PushOffersActions.setData([]))
}

export const fetchPushOffers = (module, range, filters, showMyPushOffers = {}) => (dispatch, getState, {handleError}) => {
    dispatch(PushOffersActions.setIsFetching(true))

    return getPushOffers(module, range, filters, showMyPushOffers)
        .then(response => {
            dispatch(PushOffersActions.setData(response.data))
            dispatch(PushOffersActions.setTotalItems(response.total))
        })
        .catch(error => {
            dispatch(handleError(error))
            throw error
        })
        .finally(() => dispatch(PushOffersActions.setIsFetching(false)))
}
