import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withI18n } from '../../hocs'
import { StatisticsOperations } from '../../../redux/statistics'

const mapStateToProps = state => {
  return {
    globalData: state.statistics.globalData,
    positionData: state.statistics.positionData,
    isFetching: state.statistics.isFetching,
    pushCounters: state.statistics.pushCounters,
    pullCounters: state.statistics.pullCounters,
    citationCounters: state.statistics.citationCounters,
    client: state.auth.client,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchGlobalStatistics: (data, module) => dispatch(StatisticsOperations.fetchGlobalStatistics(data, module)),
    initStats: (data, module) => dispatch(StatisticsOperations.initStats(data, module))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n
)

export default enharce(View)
