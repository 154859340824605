import styled from 'styled-components'
import colors from '../../../assets/colors'

const ValuesPercentagesTable = styled.div`
  && {
    padding: 12px 24px 24px;
    min-height: 448px;
    background-color: ${colors.white};
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(98, 102, 113, 0.06), 0px 2px 2px rgba(98, 102, 113, 0.06);
  }
  .table_header {
    font-weight: 700;
    font-size: 18px;
    margin: 24px 0;
  }
  .values_percentages_table {
    .row_element {
      padding: 12px !important;
        
      .factor_number {
        border-radius: 100%;
        display: inline-block;
        background-color: #F2F2F4;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin: 0 8px 0 0;
        font-weight: 500;
        color: #7A808D;
      }
    }
    
    .last_row_cell {
      border-bottom: 0;
    }

    .factor_name {
      background-color: transparent;
      text-align: left !important;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      width: 45%;
    }
    
    .factor_values {
      text-align: right !important;
      display: table-cell;
      width: 55%;
      
      .factor_values__container {
        .factor_percentage{
          width: 45px;
        }
        .factor_value {
          width: 45px;
        }
        .factor_fixed{
          width: 0;        
        }
      }
      
      .big_container {
        display: flex;
        gap: 16px;
        justify-content: end;
        align-items: center;
        font-weight: 600;
        line-height: 30px;
        min-height: 100%;
        height: 100%;
        
        .big_value {
            display: inline-block;
            font-size: 24px;
        }
        .MuiLinearProgress-root {
          height: 24px;
          border-radius: 24px;
        .MuiLinearProgress-bar {
             border-radius: 24px;
          }
        }
        .big_percentage_container {
          min-width: 52px;
          width: 50%;
        }
        .big_percentage {
            display: inline-block;
            font-size: 28px;
            width: 80px;
        }
        
      }
      
      .card-container {
        background-color: unset;
        box-shadow: unset;
        min-height: unset !important;
        display: flex;
        padding: 5px 0;
        
        .value-stat {
        }
      }
      
      &__container {
        display: flex;
        gap: 24px;
        justify-content: end;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        min-height: 100%;
        height: 100%;
      }
      
      div .percentage_container {
        min-width: 52px;
        width: 30%;
      }
    }
  }

`

export default ValuesPercentagesTable
