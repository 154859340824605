import * as types from './types'

export const setIsFetching = isFetching => ({
  type: types.SET_IS_FETCHING,
  payload: isFetching
})

export const setData = data => ({
  type: types.SET_DATA,
  payload: data
})

export const setAuxFilters = auxFilters => ({
  type: types.SET_AUX_FILTERS,
  payload: auxFilters
})

export const setFilters = (filters, title) => ({
  type: types.SET_FILTERS,
  payload: {
    filters,
    title
  }
})

export const setFilterGroup = value => ({
  type: types.SET_SEARCH_FILTER_GROUP,
  payload: value
})

export const setSearchFilters = (filters, title) => ({
  type: types.SET_SEARCH_FILTERS,
  payload: {
    filters,
    title
  }
})

export const setPage = page => ({
  type: types.SET_PAGE,
  payload: page
})

export const setSort = sort => ({
  type: types.SET_SORT,
  payload: sort
})

export const setPageSize = pageSize => ({
  type: types.SET_PAGE_SIZE,
  payload: pageSize
})

//Add filters to the state, it's not a permanent functionality

export const addFiltersToState = ( filters ) => ({
  type: types.ADD_FILTERS_TO_STATE,
  payload: filters
});
