import React, {useState} from 'react'
import {AppBarInboxQuery, ProcessTableQuery, ProfileInfoQuery} from '../../organism'
import {useAppContext} from "../../../context/appContext";

const InboxPeopleQuery = ({
                            classes,
                            i18n,
                          }) => {

  const [rowsSelected, setRowsSelected] = useState([]);
  const [isOpenModalSuggestChangeStatus, setIsOpenModalSuggestChangeStatus] = useState(false);
  const [tableRef, setTableRef] = useState(null);

  const filters = useAppContext();
    return (
      <div className={classes.root}>
        <AppBarInboxQuery
          showActions
          filterName={i18n.appBar.filterPeople}
          filters={filters}
          rowsSelected={rowsSelected}
          setRowsSelected={setRowsSelected}
          tableRef={tableRef}
          section={"inboxPeople"}
        />

        <ProcessTableQuery
          title={i18n.inboxPeople.title}
          filters={filters}
          updateParentRowsSelected={setRowsSelected}
          showChangeStatus
          suggestChangeStatusModal={{ isOpenModalSuggestChangeStatus, setIsOpenModalSuggestChangeStatus}}
          setTableRef={setTableRef}
          rowViewRender={id => <ProfileInfoQuery id={id} />}
        />
      </div>
    )
}

export default InboxPeopleQuery
