import styled from 'styled-components'
import { MTLinearPercent, MTText } from '../../atoms'
import { ExportInboxButton } from '../../molecules'
import React from "react";

export const FavoritesTableStyled = styled.div`
  && {
    padding: 15px 0;
  }
  
  .was_read {
     font-weight: 400 !important;
     color: ${({ theme }) => theme.colors.black} !important;
     div:not([alt="avatar"]) {
       font-weight: 400 !important;
       color: ${({ theme }) => theme.colors.black} !important;
      }
    .value {
      font-weight: 400 !important;
    }
  }
  
  .not_read {
     font-weight: 800 !important;
     color: ${({ theme }) => theme.colors.black} !important;     
     div:not([alt="avatar"]) {
       color: ${({ theme }) => theme.colors.black} !important;   
     }
  }
  
  .wrapImageName {
      flex-wrap: nowrap;
      position:relative;
  }
  
  .isPullAcceptedIcon {
    font-size: 20px;
    border-radius: 100%;
    position: absolute;
    top: 36px;
    left: 32px;
    background-color: white;
    width: 20px;
    height: 20px;
  }
  
  .MuiTableBody-root tr td:nth-child(2){
    padding-left: 0;
    padding-right: 0;
  }
  .MuiTableBody-root tr td:nth-child(3){
    padding-left: 0;
  }
  .MuiTableRow-root.MuiTableRow-head th:nth-child(2){
    padding-left: 0;
  }

  .MuiTableRow-head > th:not(:first-child) span{
    display: flex;
  }

  #svgFavorite{
    width:24px!important;
    height:24px!important;
  }

  .tagsColumn{
      padding-left: 5px;
  }
  
}
`

export const SubtextStyled = styled(props => <MTText {...props} />)`
  font-weight: normal;
  white-space:nowrap;
  color: ${({ theme }) => theme.colors.grey}
`

export const OfferStateImageStyled = styled.span`
   svg {
      width:  28px;
      height: 28px;
   }
`

export const ExportButton = styled(props => <ExportInboxButton {...props}/>)`
  margin-right: 10px !important;
`

export const Grow = styled.div`
   flex-grow: 1;
`

export const SearchCandidatureName = styled(props => <MTText {...props} />)`
   font-size: 14px;
   font-weight: 700;
   line-height: 18px;
   text-align: start;
`

export const CandidatureText = styled(props => <MTText {...props} />)`
   margin-top: 5px;
   font-size: 12px;
   font-weight: 500;
   line-height: 18px;
   text-align: start;
`

export const LimitedDefaultCol = styled(props => <MTText {...props} title={ props.value } />)`
   max-width: 200px;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   
`

export const MTLinearPercentStyled = styled(props => <MTLinearPercent {...props} />)`
  justify-content: flex-start !important;
`