import React from 'react'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import logo from '../../../assets/images/logo.svg'
import styles from './styles'

class DrawerMenuLogo extends React.Component {
  render() {
    const { onClick, classes, client } = this.props
    return (
      <Button onClick={onClick} className={classes.root}>
        <img src={_.get(client, 'logo', logo)} className={classes.image} alt="hrbot logo" />
      </Button>
    )
  }
}

DrawerMenuLogo.propTypes = {
  active: PropTypes.bool,
  Icon: PropTypes.func,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  client: PropTypes.object,
}

export default withStyles(styles)(DrawerMenuLogo)
