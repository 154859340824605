import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Panel, List } from '../../molecules'
import { SpinnerIcon } from '../../atoms'
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg'
import { ReactComponent as TelIcon } from '../../../assets/images/tel.svg'
import * as S from './styled'
class ProfileInfoSimple extends Component {
  componentDidMount() {
    //const candidatureId = _.get(this.props, 'match.params.slug')
    //this.props.fetchUser(candidatureId)
  }

  render() {
    //const { profileData } = this.props
    const profileData = {
      nombre: 'Nombre',
      apellidos: 'Apellido1 Apellido2',
      email:'user@gmail.com',
      departamento:'Operaciones, Madrid',
      telefono:'53534535',
      categoria:'Teleoperadores',
      dni:'2342423D',
      servicio:'423423',
    }

    if(!profileData) return <SpinnerIcon />
    return (
      <S.Container>
        <Panel>
          <>
            <S.UserName >{`${profileData.nombre} ${profileData.apellidos}`}</S.UserName>
            <Grid container alignItems="center" justifyContent='flex-start'>
              <Grid item sm={6}>
                <List value={[
                  {id: 1, icon: <MailIcon />, text:profileData.email},
                  {id: 2, icon: <TelIcon />, text:profileData.telefono},
                  {id: 3, icon: <MailIcon />, text:profileData.dni},
                ]} />
              </Grid>
              <Grid item sm={6}>
                <List underlined value={[
                  {id: 1, label:'categoría', text:profileData.categoria},
                  {id: 2, label:'departamento', text:profileData.departamento},
                  {id: 3, label:'servicio', text:profileData.servicio},
                ]} />
              </Grid>
            </Grid>
          </>
        </Panel>
      </S.Container>
    )
  }
}

ProfileInfoSimple.defaultProps = {}

ProfileInfoSimple.propTypes = {
  i18n: PropTypes.object.isRequired,
  fetchUser: PropTypes.func,
  profileData: PropTypes.object,
}

export default ProfileInfoSimple
