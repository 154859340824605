import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, StatisticsItems } from '../../organism'

const Statistics = ({ classes }) => {

  return (
    <div className={classes.root}>
    <AppBar />
    <StatisticsItems />
  </div>
  )
}

Statistics.propTypes = {
  classes: PropTypes.object.isRequired
}

export default Statistics
