import React from 'react';
import { Grid } from '@material-ui/core';
import { RegularButtonSecondary } from '../../atoms';
import TableToggleStyled from './styled';

const TableToggle = ({ showOpen, handleButtonClick, buttonLabels }) => {
    const [activeLabel, inactiveLabel] = buttonLabels;

    return (
        <TableToggleStyled>
            <Grid item className="containerToggle">
                <div className="containerButtons">
                    <RegularButtonSecondary title={activeLabel} onClick={() => handleButtonClick(true)} isActive={showOpen} />
                    <RegularButtonSecondary title={inactiveLabel} onClick={() => handleButtonClick(false)} isActive={!showOpen} />
                </div>
            </Grid>
        </TableToggleStyled>
    );
}

export default TableToggle;
