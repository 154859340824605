import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const icon = props => (
  <SvgIcon {...props}>
    <circle cx="12" cy="4.5" r="3.5" strokeWidth="2" opacity="0.7" />
    <path d="M19 1.46777C20.1854 2.05685 21 3.28008 21 4.69357C21 6.10705 20.1854 7.33028 19 7.91936" strokeWidth="2" strokeLinecap="round" opacity="0.7" />
    <path d="M18.5 17V15C18.5 13.3431 17.1569 12 15.5 12H8.5C6.84315 12 5.5 13.3431 5.5 15V17" strokeWidth="2" strokeLinecap="round" opacity="0.7" />
    <path d="M23.5 17V14.5C23.5 13.1193 22.3807 12 21 12V12" strokeWidth="2" strokeLinecap="round" opacity="0.7" />
    <path d="M5.5 1.46777C4.31463 2.05685 3.5 3.28008 3.5 4.69357C3.5 6.10705 4.31463 7.33028 5.5 7.91936" strokeWidth="2" strokeLinecap="round" opacity="0.7" />
    <path d="M1 17V14.5C1 13.1193 2.11929 12 3.5 12V12" strokeWidth="2" strokeLinecap="round" opacity="0.7" />
  </SvgIcon>
)

export default icon
