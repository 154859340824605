import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { FavoritesOperations } from '../../../redux/favorites'

const mapStateToProps = state => ({
  favorites: state.favorites.data
})

const mapDispatchToProps = dispatch => {
  return {
    postFavorites: ids => dispatch(FavoritesOperations.postFavorites(ids)),
    deleteFavorites: ids => dispatch(FavoritesOperations.deleteFavorites(ids))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enharce(View)
