import { ReadsActions } from './'
import 'core-js'

export const fetchReads = () => (dispatch, getState, { api, handleError }) => {
  dispatch(ReadsActions.setIsFetching(true))

  return api
    .getReads()
    .then(reponse => dispatch(ReadsActions.setData(reponse)))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(ReadsActions.setIsFetching(false)))
}

export const postReads = ids => (dispatch, getState, { api, handleError }) => {
  dispatch(ReadsActions.setIsFetching(true))

  return api
    .postReads(ids)
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(ReadsActions.setIsFetching(false)))
}

export const deleteReads = ids => (dispatch, getState, { api, handleError }) => {
  dispatch(ReadsActions.setIsFetching(true))

  return api
    .deleteReads(ids)
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(ReadsActions.setIsFetching(false)))
}
