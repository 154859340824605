import styled from 'styled-components'
import {colors} from "../../../assets";

const ProfileInfoStyled = styled.div`
  && {
    padding: 20px 0;
  }

  .tabpanel_box {
    margin: 35px 0 12px -16px;
  }

  .tab-item {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid rgba(148,163,178,0.25);
    padding: 15px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: ${colors.grey4};
    margin-bottom: 0;
    background-color: rgba(148,163,178,0.18);
    margin-right: 3px;
  }
  
  .MuiTabs-indicator {
    display: none!important;
  }
  
  .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel {
    background-color: transparent;
    padding: 0;
    margin-bottom: 24px;
  }

  @media print {
    .profileStatsInfo-print{
      justify-content:space-between;
    }
    .otherInfoContainer {
      width: 50%;
      //padding: 35px 24px 35px 55px;
      margin-top: 20px;
      p.value{
        font-size: 11px !important;
      }
    }

    #boxProfileCiteGridContainer {
      padding-right: 0 !important;
      padding-left: 24px !important;
      margin-top: -4px;
    }

    .interview-otes-title-print{
      display: block !important;
      font-size: 18px !important;
    }
  }

  //InterviewNotes
  #interviewer_notes_container{
    background-color: ${colors.white};
    box-shadow: 0 4px 6px rgb(98 102 113 / 6%), 0 2px 2px rgb(98 102 113 / 6%);
    min-height:70px;
    padding: 20px 30px;
  }

  .interviewerNote {
    font-size: 15px;
  }

  .interviewerNote_metadata{
    color: gray;
    margin-top: 10px;
  }

  //Tabs
  .tab-item {
    text-transform: none;
    padding: 0 15px;
  }
  
  .tab-item.Mui-selected{
    background-color: #fff;
  }

  .tab-item:not(.Mui-selected) {
    font-weight: 400;
  }

  .interview-otes-title-print{
    display:none;

  }
`

export default ProfileInfoStyled
