import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import {ProfileOperations} from '../../../redux/profile'

const mapStateToProps = state => {
  return {
    user: state.auth.data,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUserList: candidatureIds => dispatch(ProfileOperations.fetchUserList(candidatureIds))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n
)

export default enharce(View)
