import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {AppBarInbox, ProcessTable, ProfileFeedbackInfo, SaveSearchesTable} from '../../organism'
import {PageTitleSubtitle} from '../../atoms'
import {MODULES} from '../../../config/constants'

const SavedSearches = ({ classes, i18n, searchFilters, searchFilterTitle, resetFilters, filterGroupSelected }) =>{

  const [total, setTotal] = useState(0);

  useEffect(() => {
    resetFilters()
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.root}>
      <AppBarInbox
        showActions
        module={MODULES.ID_FEEDBACK}
        saveSearchOption={false}
        canEditFilters={false}
        canCloneSearch
        forceTotal={total}
        section={"savedSearches"}
      />
      {_.isEmpty(searchFilters) ? (
        <SaveSearchesTable module={MODULES.ID_FEEDBACK} setTotal={setTotal}/>
      ) : (
        <>
          <PageTitleSubtitle title={i18n.inboxPeople.filtersSearch} subtitle={searchFilterTitle} module={MODULES.ID_FEEDBACK} />
          <ProcessTable 
            showFilterGroup
            showChangeStatus
            filters={searchFilters}
            filterGroupSelected={filterGroupSelected}
            module={MODULES.ID_FEEDBACK}
            rowViewRender={(id) => <ProfileFeedbackInfo id={id} />}
            resetFiltersOnLoad={false}
          />
        </>
      )}
    </div>
  )
}

SavedSearches.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  searchFilters: PropTypes.object,
  searchFilterTitle: PropTypes.string,
  resetFilters: PropTypes.func,
  filterGroupSelected: PropTypes.number,
}

export default SavedSearches
