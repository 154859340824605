import View from './view'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router'
import { withTheme } from '@material-ui/core/styles'
import { withI18n } from '../../hocs'

const mapStateToProps = state => {
  return {
    filters: state.inbox.inboxFilters,
    filtersCitation: state.citations.filters,
    filtersValues: state.saveSearches.auxFilters
  }
}

const mapDispatchToProps = () => {
  return {}
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withTheme
)

export default enharce(View)
