import styled from 'styled-components'

export const DashboardEditStyled = styled.div`
  padding: 30px 60px;
  
  .MuiButtonBase-root.MuiButton-root {
    height: initial;
  }
  
  .title-container {
    margin-bottom: 30px;    
    display: flex;
    flex-wrap: wrap;
  }
  
  .main-wrapper {
    box-shadow: 0 3px 8px -4px black;
    border-radius: 8px;
  }
  
  .title {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 8px;
    button {
      border-radius: 10px;
    }
  }
  
  .widgets-sidebar-wrapper {
    background: #3a4d5f;
    color: #f0f0f0;   
    border-radius: 8px 0 0 8px;
    border: 1px solid #777;
  }
  
  .widgets-list-wrapper {
    background: #ebebeb;
    border-radius: 0 8px 8px 0;
    overflow: hidden;
  }
  
  .widgets-list {
    max-height: 65vh;
    overflow-y: auto;
    overflow-x: clip;
  }
  
  .MuiTypography-body1 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .widgets-sidebar-item {
    cursor: pointer;
  }
  
  .widgets-list-divider {
    background: rgba(255, 255, 255, 0.15);
  }
  
  .widgets-sidebar-item.Mui-selected, .widgets-sidebar-item:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .MuiSnackbar-root {
    background: #2196f3;
    border-radius: 4px;
    &.success {
      background: #4caf50;
    }
    &.warning {
      background: #ff9800;
    }
    &.error {
      background: #f44336;
    }
    
    .MuiSnackbarContent-root {
      font-size: 1.2em;
      background: inherit;
      cursor: default;
    }
  }
  
`
