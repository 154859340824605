import 'core-js'
import _ from 'lodash'
import {ReportsActions} from './'
import fileDownload from 'js-file-download'
import eventTracker from "../../utils/eventTracker";

export const postReport = (data, module) => (dispatch, getState, { api, handleError, toast, i18n }) => {
  dispatch(ReportsActions.setIsFetching(true))

  return api
    .postReport(data, module)
    .then(() => toast(i18n.reports.reportCreated, { type: toast.TYPE.SUCCESS }))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(ReportsActions.setIsFetching(false)))
}

export const deleteReports = (ids, module) => (dispatch, getState, { api, handleError, toast, i18n }) => {
  dispatch(ReportsActions.setIsFetching(true))

  return Promise.all(_.map(ids, (id)=>api.deleteReport(id, module)))
    .then(() => toast(i18n.reports.reportDeleted, { type: toast.TYPE.SUCCESS }))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(ReportsActions.setIsFetching(false)))
}

export const exportReports = (ids, module) => (dispatch, getState, { api }) => {
  _.forEach(ids, ({ id, title }, keyId) => {
    setTimeout(() => {
      api.getReportCsv(id, module).then(response => {
          fileDownload(new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${title}.xlsx`)
          eventTracker.track('ReportExported', {
              id: id,
              status: 'Success'
          });
      }).catch( error => {
          eventTracker.track('ReportExported', {
              id: id,
              status: 'Failed',
              reason: error
          });
      });
    }, keyId * 200)
  })

  return Promise.resolve()
}

export const fetchReportFields = candidatureId => (dispatch, getState, { api, handleError }) => {
  dispatch(ReportsActions.setIsFetching(true))

  return api
    .getReportFields(candidatureId)
    .then(dataRes => dispatch(ReportsActions.setFields(dataRes)))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(ReportsActions.setIsFetching(false)))
}
