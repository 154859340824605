import styled from 'styled-components'

const IntegrationsWrapper = styled.div`
  .integration_item {
    padding: 20px;
    border-radius: 10px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin: 20px;
    align-items: center;
  }

  .integration_item img {
    width: 250px;
  }
  
  .integration_name {
    flex: 2;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin: auto;
    }

  .integration_button {
    border-radius: 12px;
    background-color: ${props => (props.theme.primary)};
    padding: 10px 20px;
    color: #fff;
    float: right;
  }
`

export default IntegrationsWrapper
