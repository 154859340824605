import { css } from 'styled-components'
import colors from '../../../assets/colors'

const borderRadius = '10px';
// const boxShadow = '0px 4px 6px rgb(98 102 113 / 6%), 0px 2px 2px rgb(98 102 113 / 6%)';
const boxShadow = '0 2px 10px -6px';

export { borderRadius, boxShadow };


export const headerStatStyles = css`
  && {
    padding: 0;
    min-width: 200px;
    background-color: ${colors.white};
    border-radius: ${borderRadius};
    box-shadow: ${boxShadow};
  }
  
  .header-stat {
    border-bottom: 2px solid ${props => props.theme.primary};
    background-color: ${colors.white};
    text-align: left;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
    
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      color: #222;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`

export const noDataStyles = css`
  .no-data-available {
    background: ${colors.white};
    padding: 30px 0;
    text-align: center;
  }
`
export const chartWrapperStyles = css`
  .chart-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
    padding: 30px;
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};    
  }
`
