import React from 'react'
import PropTypes from 'prop-types'
import TimeInputStyled from './styled'
import TextField from '@material-ui/core/TextField'
import Grid from "@material-ui/core/Grid";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {Autocomplete} from "@material-ui/lab";

const TimeInput = ({ fontSize, name, defaultValue, onChange, value, range, hourStepInMinutes = 5 }) => {
  const [selectedTime, setSelectedTime] = React.useState(range ? {from: null, to: null} : null)

  function handleTimeChange(time, inputName) {
    if (range) {
      const newSelectedTime = {...selectedTime}
      newSelectedTime[inputName] = time
      setSelectedTime(newSelectedTime)
      const formatedTime = {}
      if(newSelectedTime.from) {
        formatedTime.from = newSelectedTime.from
      } else if (value.from) {
        formatedTime.from = value.from
      }
      if(newSelectedTime.to) {
        formatedTime.to = newSelectedTime.to
      } else if (value.to) {
        formatedTime.to = value.to
      }
      onChange(formatedTime, name)
    } else {
      setSelectedTime(time)
      onChange(time, name)
    }
  }

  function handleAutocompleteKeyDown(e) {
    if (e.key === "Tab") {
      const inputValue = e.target.value.trim();

      if (/^[0-2]?[0-9]$/.test(inputValue) && parseInt(inputValue) <= 23) {
        e.preventDefault();
        const formattedValue = `${inputValue.padStart(2, '0')}:00`;
        onChange(formattedValue, name);
      }

      else if (/^([0-1]?[0-9]|2[0-3])[0-5][0-9]$/.test(inputValue)) {
        e.preventDefault();
        const hour = inputValue.slice(0, -2).padStart(2, '0');
        const minutes = inputValue.slice(-2);
        const formattedValue = `${hour}:${minutes}`;
        onChange(formattedValue, name);
      }
    }
  }

  if (range) {
    const timeFrom = value.from || selectedTime.from
    const timeTo = value.to || selectedTime.to
    return (
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6}>
          <TimeInputStyled fontSize={fontSize}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <TextField
                name={"from"}
                type="time"
                style={{width: "100%"}}
                defaultValue={defaultValue}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  className:"time_input",
                  step: 300 // 5 min
                }}
                onChange={(e) => handleTimeChange(e.target.value, 'from')}
                value={timeFrom}
              />
            </MuiPickersUtilsProvider>
          </TimeInputStyled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TimeInputStyled fontSize={fontSize}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <TextField
                name={"to"}
                style={{width: "100%"}}
                type="time"
                defaultValue={defaultValue}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  className: "time_input",
                  step: 300 // 5 min
                }}
                onChange={(e) => handleTimeChange(e.target.value, 'to')}
                value={timeTo}
              />
            </MuiPickersUtilsProvider>
          </TimeInputStyled>
        </Grid>
      </Grid>
    )
  }

  const hours = generateHours();
  function generateHours() {
    const hours = [];

    for (let hour = 8; hour < 32; hour++) {
      const formattedHour = `${(hour % 24).toString().padStart(2, '0')}:00`;
      hours.push(formattedHour);

      for (let minute = hourStepInMinutes; minute < 60; minute += hourStepInMinutes) {
        const formattedMinute = `${minute.toString().padStart(2, '0')}`;
        const formattedHour = `${(hour % 24).toString().padStart(2, '0')}:${formattedMinute}`;
        hours.push(formattedHour);
      }
    }

    return hours;
  }

  return (
      <TimeInputStyled>
        <Autocomplete
            getOptionLabel={(option) => typeof option === 'string'
            || option instanceof String ? option : ""}
            value={selectedTime || value}
            name={name}
            id={name}
            onChange={(event, newValue) => {
              setSelectedTime(newValue);
              onChange(newValue, name);
            }}
            options={hours}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={"HH:MM"}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: handleAutocompleteKeyDown
                    }}
                />
            )}
        />
      </TimeInputStyled>
  );

}

TimeInput.defaultProps = {
  name: 'time',
  fontSize: 'normal'
}

TimeInput.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  fontSize: PropTypes.string
}

export default TimeInput
