import React, { useState, useEffect } from 'react';
import { SwatchesPicker } from 'react-color';
import Popover from '@material-ui/core/Popover';
import ColorPickerStyled from './styled';

const ColorPicker = ({ defaultColor, onChange, color }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSwatchColor, setSelectedSwatchColor] = useState(color || defaultColor);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (newColor) => {
    setSelectedSwatchColor(newColor.hex);
    onChange(newColor);
    handleClose();
  };

  useEffect(() => {
    setSelectedSwatchColor(color || defaultColor);
  }, [defaultColor, color]);

  const open = Boolean(anchorEl);

  return (
    <ColorPickerStyled>
      <div className="containerColorPicker color-picker-button" onClick={handleClick}>
        <div
          className="color-picker-box"
          style={{ backgroundColor: selectedSwatchColor }}
        />
        <span className="color-code-text">{selectedSwatchColor}</span>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            overflow: 'hidden',
          },
        }}
      >
        <SwatchesPicker
          color={{ hex: selectedSwatchColor }}
          onChange={handleColorChange}
        />
      </Popover>
    </ColorPickerStyled>
  );
};

export default ColorPicker;

