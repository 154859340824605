import styled from 'styled-components'
import colors from '../../../assets/colors'
import { headerStatStyles, noDataStyles, chartWrapperStyles } from '../dynamic-widget/styled'

const PieStatisticStyled = styled.div`
  && {
    padding: 0;
  }
  
  ${headerStatStyles}
  
  ${noDataStyles}
  
  ${chartWrapperStyles}
`

export default PieStatisticStyled
