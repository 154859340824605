import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import MaterialTable from 'material-table'
import colors from '../../../assets/colors'
import {MTMultipleFields, MTText, MTToolbar, PageTitle} from '../../atoms'
import {ModalCreateReport, ModalReportConfirmation} from '../../molecules'
import {ReportsTableStyled} from './styled'
import i18n from '../../../assets/i18n'
import * as api from '../../../api'
import {getReportOnBackground} from "../../../api";
import eventTracker from "../../../utils/eventTracker";
import {toast} from "react-toastify";

const tableOptions = {
  emptyRowsWhenPaging: false,
  loadingType: 'linear',
  selection: true,
  headerStyle: {backgroundColor: colors.grey, textAlign: 'center', color: colors.white, fontSize: 12},
  cellStyle: {textAlign: 'center'},
  pageSize: 20,
  pageSizeOptions: [20, 50, 100, 200],
}

class ReportsTable extends Component {

  state = {
    inboxData: [],
    selectedRows: '',
    showModalConfirmation: false,
    ilters: null
  }

  constructor(props) {
    super(props)

    this.tableRef = React.createRef()
    this.props.fetchReportFields(this.props.module)
  }

  checkRow = event => {
    this.setState({selectedRows: event})
  }

  onHandleItem = index => {
    switch (index) {
      case 0:
        this.removeReports()
        this.clearSelectedRows()
        break
      case 1:
        this.exportAllCV()
        this.clearSelectedRows()
        break
      default:
        return null
    }
  }

  toolbarOptions = [
    {
      id: 'delete',
      label: i18n.reportsTable.delete,
      onClick: () => {
        this.removeReports()
        this.clearSelectedRows()
      }
    },
    {
      id: 'export',
      label: i18n.peopleTable.exportCsv,
      onClick: () => {
        this.exportAllCV();
      }
    }
  ]

  clearSelectedRows = () => {
    this.setState({selectedRows: []})
    this.tableRef.current && this.tableRef.current.onAllSelected(false)
  }

  updateDataQuery = () => this.tableRef.current && this.tableRef.current.onQueryChange()

  resetDataQuery = () => this.tableRef.current && this.tableRef.current.onQueryChange({page: 0})

  handleSubmitReport = data => {
    this.props.postReport(data, this.props.module).then(() => this.updateDataQuery())
  }

  removeReports = () => {
    const {selectedRows} = this.state
    if (_.size(selectedRows) > 0) {
      const ids = _.map(selectedRows, 'id')
      this.props.deleteReports(ids, module).then(() => this.updateDataQuery())
    }
  }

  openModalConfirmation = () => this.setState({showModalConfirmation: true})

  closeModalConfirmation = ( receivers ) => {
    this.setState({showModalConfirmation: false})

    if(receivers !== 'Close' && !_.isEmpty(receivers)){

      const { selectedRows, module } = this.state;
      _.forEach(selectedRows, ({ id }) => {

        getReportOnBackground(id, module, receivers).then((res) => {

          if(res.status === 200){
            eventTracker.track('ReportExportedBackground', {
              id: id,
              status: 'Success'
            });

            toast.success(i18n.reports.reportExportedSuccess, {
              position: toast.POSITION.BOTTOM_CENTER
            })
          } else {
            eventTracker.track('ReportExportedBackground', {
              id: id,
              status: 'Failed'
            });

            toast.error(i18n.reports.reportExportedFailed, {
              position: toast.POSITION.BOTTOM_CENTER
            })
          }

        });

      })

      this.clearSelectedRows();

    }

  }

  exportAllCV = () => {
    this.openModalConfirmation();
  }

  render() {
    const {reportFields} = this.props
    const {selectedRows} = this.state

    const MTTitle = rowData => <MTText value={rowData.title}/>
    const MTDate = rowData => <MTText value={moment(rowData.date_from).format('DD/MM/YYYY HH:mm')}/>
    const MTFields = rowData => <MTMultipleFields values={JSON.parse(rowData.fields)} mapValues={reportFields}/>

    const columns = [
      {title: i18n.reportsTable.title, field: 'title', render: MTTitle},
      {title: i18n.reportsTable.date, field: 'date_from', render: MTDate},
      {title: i18n.reportsTable.fields, field: 'fields', render: MTFields}
    ]

    const tableComponents = {
      Container: props => <div {...props} />,
      Toolbar: () => (
        <MTToolbar module={this.props.module} options={this.toolbarOptions} disabled={_.isEmpty(selectedRows)}>
          <PageTitle title={i18n.reports.title}/>
          <ModalCreateReport reportFields={reportFields} onSubmit={this.handleSubmitReport}/>
        </MTToolbar>
      )
    }

    const data = async query => {
      try {
        const {range, sort, page} = api.getQueryData(query)
        const response = await api.getReportsData(null, range, sort, this.props.module)
        const data = _.get(response, 'data')
        const totalCount = _.get(response, 'total')
        return {data, page, totalCount}
      } catch (e) {
        return {data: [], page: 0, totalCount: 0}
      }
    }

    return (
      <>
        <ReportsTableStyled>
          <MaterialTable
            tableRef={this.tableRef}
            columns={columns}
            data={data}
            options={tableOptions}
            onSelectionChange={this.checkRow}
            components={tableComponents}
            localization={i18n.tableLocalization}
            draggable={false}
          />
        </ReportsTableStyled>
        <ModalReportConfirmation
            showModal={this.state.showModalConfirmation}
            handleClose={this.closeModalConfirmation}
        />
      </>
    )
  }
}

ReportsTable.propTypes = {
  i18n: PropTypes.object.isRequired
}

export default ReportsTable
