import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { AuthUtils } from '../../../redux/auth'
import routes from '../../../config/routes'
import { TOKEN_KEY } from '../../../config/constants'

const PrivateRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!localStorage.getItem(TOKEN_KEY)) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          )
        } else if (!AuthUtils.isLogedUser({ auth })) {
          return (
            <Redirect
              to={{
                pathname: routes.login,
                state: { from: props.location }
              }}
            />
          )
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}

PrivateRoute.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  component: PropTypes.any
}

export default PrivateRoute
