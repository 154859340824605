import { Tooltip } from '@material-ui/core'
import styled from 'styled-components'
import React from "react";

export const MTTextStyled = styled.div`
  
  div.MuiChip-root{
    border-radius: 5px;
    height: auto;
    padding: 3px 5px;
    margin-bottom: 3px;
    justify-content: left;
    text-overflow: ellipsis;
    background-color: transparent;
  }
  
  span {
    /* color: #fff; */
    font-weight: bold;
    font-size: ${({fontSize}) => fontSize};
  }
  
  svg {
    color: #fff;
    top: inherit !important;
  }  
  
  .short-version{
    border-radius: 5px;
    min-height: 8px;
    margin: 3px 0;
    height: 8px;
    width: 8px;
  }
`

export const TooltipStyled = styled(props => <Tooltip {...props} />)`
  display: inline-block;
  margin-right: 4px;
`