import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

export const AvatarStyled = styled.div`
  && {
    // margin-left: 80px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    position: relative;
    cursor: pointer;

    :hover {
      opacity: 0.75;
    }

    div {
      width:${props => props.size === 'small' ? '48px' : '58px'} ;
      height: ${props => props.size === 'small' ? '48px' : '58px'};
      min-width: 45px;
      margin-bottom: 10px;
      min-height: 45px;
      max-width: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.3);
      line-height: ${props => props.size === 'small' ? '48px' : '58px'};
      text-align: center;
      color:${props => props.theme.textPrimary};
      font-size:20px ;

    }

    p {
      color: ${colors.grey};
      font-weight: 600;
      font-size: 14px;
      // margin-right: 10px;
      margin-bottom: 0;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid ${props => props.theme.primary};
      background: ${colors.white};
      object-fit: 'cover';
    }

    ${props =>
    props.styledType === 'observation-table' &&
    css`
        margin-left: 0;
        flex-direction: row-reverse;

        :hover {
          opacity: 1;
        }

        div {
          margin-right: 15px;
        }

        p {
          font-size: 17px;
          line-height: 25px;
          letter-spacing: 0.03em;
          color: ${colors.black};
          margin-right: 0;
        }
      `}
  }
`
