import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AuthUtils } from '../../../redux/auth'
import { PrivateRoute } from '../../templates'
import { Drawer } from '../../organism'
import routes from '../../../config/routes'
import * as pages from '../../pages'
import GlobalStyles from './styled'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { clientMaterialTheme } from '../../../config/material'
import { ThemeProvider } from 'styled-components'
import styledTheme from '../../../config/styled'
import {ROLES, USER_AUTHORIZED_SECTION_KEYS, ALLOWED_EMAILS} from '../../../config/constants'
import { FeatureGuard } from '../../../utils/guard'
import {isArray} from "lodash";

class Routes extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    auth: PropTypes.object,
    history: PropTypes.object,
    client: PropTypes.object,
    user_authorized_sections: PropTypes.array,
  }

  previousLocation = this.props.location

  UNSAFE_componentWillUpdate(nextProps) {
    const { location } = this.props

    // React Router Modal Fix
    if (nextProps.history.action !== 'POP' && (!location.state || !location.state.modal)) {
      this.previousLocation = location
    }
  }

  render() {
    const { location, auth, user_authorized_sections } = this.props

    let userAuthorizedSections = user_authorized_sections && isArray(user_authorized_sections) ? user_authorized_sections.map(item => item.section) : [];

    const isModal = !!(location.state && location.state.modal) && this.previousLocation !== location

    const clientMaterialUITheme = clientMaterialTheme(auth.client)
    const clientStyledComponentsTheme = styledTheme(auth.client)

    if (!auth.sesionLoaded && AuthUtils.isLogedUser({ auth })) return null
    if (!AuthUtils.isLogedUser({ auth })) {
      return (
        <ThemeProvider theme={clientStyledComponentsTheme}>
          <MuiThemeProvider theme={clientMaterialUITheme}>
            <Switch location={location}>
              <Route path={routes.landingSmsText} component={pages.LandingSmsText} />
              <Route path={routes.landingEmail} component={pages.LandingEmail} />
              <Route path={routes.reschedule} component={pages.Reschedule} />
              <Route path={routes.notInterested} component={pages.NotInterested} />
              <Route path={routes.landingOffer} component={pages.LandingOffer} />
              <Route path={routes.landingPull} component={pages.LandingPull} />
              <Route path={routes.landingDocRequest} component={pages.landingDocRequest} />
              <Route path="/" component={pages.Login} />
            </Switch>
          </MuiThemeProvider>
        </ThemeProvider>
      )
    }

    const forceResetPassword = auth.data ? auth.data.force_reset_password : false;
    if( AuthUtils.isLogedUser({ auth }) && forceResetPassword && location.pathname !== '/my-profile' ){
      window.location.href = '/my-profile';
    }

    const isTalentReadOnly = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_talent_acquisition)
    const isProcessSupervisor = AuthUtils.hasRole(ROLES.ROLE_SUPERVISOR_employee_line)
    const isRRHHUser = AuthUtils.hasRole(ROLES.ROLE_RRHH_USER)
    const isTechnicalSelection = AuthUtils.hasRole(ROLES.ROLE_TECHNICAL_SELECTION)
    const isTalentAtsAdmin = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_talent_acquisition)
    const isAdminUser = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_ROOT) || AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_talent_acquisition);
    const isOnboard = AuthUtils.hasRole(ROLES.ROLE_ON_BOARDING)
    const isOnboardSupervisor = AuthUtils.hasRole(ROLES.ROLE_SUPERVISOR_on_board)
    const isFeedback = AuthUtils.hasRole(ROLES.ROLE_FEEDBACK_USER)
    const isFeedbackSupervisor = AuthUtils.hasRole(ROLES.ROLE_SUPERVISOR_feedback)
    const isExitInterview = AuthUtils.hasRole(ROLES.ROLE_EXIT_INTERVIEW)
    const isExitInterviewSupervisor = AuthUtils.hasRole(ROLES.ROLE_SUPERVISOR_exit_interview)

    const isAdminRoot = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_ROOT)
    const isOnboardingAtsAdmin = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_on_board)
    const isExitAtsAdmin = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_exit_interview)
    const isFeedbackAtsAdmin = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_feedback)
    const isEmployeeLineAtsAdmin = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_employee_line)

    const isAdminModule = isAdminRoot || isTalentAtsAdmin || isOnboardingAtsAdmin || isExitAtsAdmin || isEmployeeLineAtsAdmin || isFeedbackAtsAdmin;

    const getInitialPathName = () => {
      if (isTechnicalSelection) return localStorage.getItem('drawer_first_route') || routes.userManual
      if (isRRHHUser) return routes.processes
      if (isOnboard) return routes.onboarding
      if (isFeedback) return routes.feedbackInbox
      if (isExitInterview) return routes.exitInterviewInbox
      return pages.NotFound
    }

    const allowedUserHrbf = ['@hrbotfactory.com'];
    const currentUserEmail = auth?.data?.email;
    const isUserAllowed = allowedUserHrbf.some(domain => currentUserEmail.endsWith(domain));

    const canAccessToTalentInbox = (isAdminUser || isTalentReadOnly || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.INBOX)));

    return (
      <ThemeProvider theme={clientStyledComponentsTheme}>
        <MuiThemeProvider theme={clientMaterialUITheme}>
          <GlobalStyles>
            <CssBaseline />

            <div style={{ position: 'fixed' }} className="no-print">
              <Drawer />
            </div>
            <div id="print-content" />
            <div id="main-content" style={location && location.pathname.includes(routes.myProfile) ? { marginLeft: 250 } : { marginLeft: 80 }} className="no-margin-print">
              <Switch location={isModal ? this.previousLocation : location}>
                <Redirect
                  from="/"
                  exact
                  to={{
                    pathname: getInitialPathName()
                  }}
                />
                <Redirect
                  from="/inbox"
                  exact
                  to={{
                    pathname: getInitialPathName()
                  }}
                />
                <Redirect
                  from="/login"
                  exact
                  to={{
                    pathname: getInitialPathName()
                  }}
                />
                <PrivateRoute exact path={routes.myProfile} component={pages.MyProfile} />
                <PrivateRoute exact path={routes.integrations} component={pages.MyProfileIntegrations} />
                <PrivateRoute exact path={routes.userManagement} component={pages.MyProfileUserManagement} />
                <PrivateRoute exact path={routes.tagManagement} component={pages.MyProfileTagManagement} />
                <PrivateRoute exact path={routes.keywordsRpa} component={pages.MyProfileKeywordsRpa} />
                <PrivateRoute exact path={routes.keywordsRpaTalent} component={pages.MyProfileKeywordsRpa} />
                <PrivateRoute exact path={routes.partners} component={pages.MyProfilePartners} />
                {isAdminUser && <PrivateRoute exact path={routes.iaEvaluation} component={pages.MyProfileIaEvaluation} />}
                {isAdminUser && <PrivateRoute exact path={routes.iaEvaluationCreate} component={pages.MyProfileIaEvaluationWizard} />}
                {isAdminUser && <PrivateRoute exact path={routes.iaEvaluationEdit} component={pages.MyProfileIaEvaluationWizard} />}
                {isAdminModule && <PrivateRoute exact path={routes.superAdministratorManual} component={pages.SuperAdministratorManual} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.STATISTICS_DASHBOARD))) &&  <PrivateRoute exact path={routes.dashboardWizard} component={pages.DashboardWizard} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.STATISTICS_DASHBOARD))) &&  <PrivateRoute exact path={routes.dashboardHome} component={pages.DashboardHome} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.STATISTICS_DASHBOARD))) &&  <PrivateRoute exact path={routes.dashboardEdit} component={pages.DashboardEdit} />}
                <Route path={routes.landingSmsText} component={pages.LandingSmsText} />
                <Route path={routes.landingEmail} component={pages.LandingEmail} />
                <Route path={routes.reschedule} component={pages.Reschedule} />
                <Route path={routes.notInterested} component={pages.NotInterested} />
                <Route path={routes.landingOffer} component={pages.LandingOffer} />
                <Route path={routes.landingPull} component={pages.LandingPull} />
                <Route path={routes.landingDocRequest} component={pages.landingDocRequest} />
                <Route path={routes.logout} component={pages.Logout} />
                <PrivateRoute exact path={routes.savedSearchesItemQuery} component={pages.SavedSearchesItemQuery} />
                {/*<PrivateRoute exact path={routes.savedSearchesItemEmployeeLineQuery} component={pages.SavedSearchesItemQuery} />*/}
                <PrivateRoute exact path={routes.savedSearchesItem} component={pages.SavedSearchesItem} />
                {(FeatureGuard.canAccessToJobOffers() || ALLOWED_EMAILS.indexOf(currentUserEmail)>-1) && canAccessToTalentInbox && <PrivateRoute exact path={routes.job_offers} component={pages.JobOffers} />}
                {(FeatureGuard.canAccessToJobOffers() || ALLOWED_EMAILS.indexOf(currentUserEmail)>-1) && canAccessToTalentInbox && <PrivateRoute exact path={routes.job_offer_create} component={pages.JobOfferWizard} />}
                {(FeatureGuard.canAccessToJobOffers() || ALLOWED_EMAILS.indexOf(currentUserEmail)>-1) && canAccessToTalentInbox && <PrivateRoute exact path={routes.job_offer_edit} component={pages.JobOfferWizard} />}
                {(FeatureGuard.canAccessToJobOffers() || ALLOWED_EMAILS.indexOf(currentUserEmail)>-1) && canAccessToTalentInbox && <PrivateRoute exact path={routes.job_offer_applications} component={pages.JobOfferApplications} />}
                {(FeatureGuard.canAccessToJobOffers() || ALLOWED_EMAILS.indexOf(currentUserEmail)>-1) && canAccessToTalentInbox && <PrivateRoute exact path={routes.profilePerson} component={pages.ProfilePerson} />}
                {canAccessToTalentInbox && <PrivateRoute exact path={routes.inbox} component={pages.InboxPeopleQuery} />}
                {isTechnicalSelection && <PrivateRoute exact path={routes.profile} component={pages.Profile} />}
                {isTechnicalSelection && <PrivateRoute exact path={routes.profileQuery} component={pages.ProfileQuery} />}
                {isTechnicalSelection && <PrivateRoute exact path={routes.userManual} component={pages.UserManual} />}
                {isTechnicalSelection && <PrivateRoute exact path={routes.profileFavoritesQuery} component={pages.ProfileQuery} />}
                {isTechnicalSelection && <PrivateRoute exact path={routes.profileSaveSearchQuery} component={pages.ProfileQuery} />}
                {isTechnicalSelection && <PrivateRoute exact path={routes.profiles} component={pages.Profiles} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.PLANNING))) &&  <PrivateRoute exact path={routes.planning} component={pages.Planning} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.PLANNING))) &&  <PrivateRoute exact path={routes.profilePlanning} component={pages.ProfileQuery} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.SAVED_SEARCHES))) &&  <PrivateRoute exact path={routes.savedSearches} component={pages.SavedSearchesQuery} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.FAVORITES))) &&  <PrivateRoute exact path={routes.favorites} component={pages.FavoritesQuery} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.REPORTS))) &&  <PrivateRoute exact path={routes.reports} component={pages.Reports} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.PUSH_OFFER))) &&  FeatureGuard.canAccessToPushOffers() && <PrivateRoute exact path={routes.pushOffer} component={pages.PushOffer} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.PULL_CONFIG))) &&  FeatureGuard.canAccessToPullOffers() && <PrivateRoute exact path={routes.pullConfig} component={pages.PullConfig} />}
                {isTechnicalSelection && <PrivateRoute exact path={routes.offerCandidatures} component={pages.OfferCandidatures} />}
                {isTalentAtsAdmin && FeatureGuard.canAccessToFunnelStatus() && <PrivateRoute exact path={routes.phasesStates} component={pages.PhasesStates} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.EVALUATIVE_TESTS))) && <PrivateRoute exact path={routes.evaluativesTests} component={pages.EvaluativeTests} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.EVALUATIVE_TESTS))) && <PrivateRoute exact path={routes.createEvaluativeTests} component={pages.EvaluativeTestsCreate} />}
                {(isAdminUser || (isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.EVALUATIVE_TESTS))) && <PrivateRoute exact path={routes.editEvaluativeTests} component={(props) => <pages.EvaluativeTestsEdit {...props} isEditMode />} />}
                {FeatureGuard.canAccessToVacancyRequest() && (isAdminUser || isTalentAtsAdmin || isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.TEMPLATE_REQUESTOR)) && <PrivateRoute exact path={routes.templateRequestManager} component={pages.TemplateRequestManager} />}
                {FeatureGuard.canAccessToVacancyRequest() && (isAdminUser || isTalentAtsAdmin || isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.TEMPLATE_REQUESTOR)) && <PrivateRoute exact path={routes.createProcess} component={pages.ProcessCreateTemplateRequest} />}
                {FeatureGuard.canAccessToVacancyRequest() && (isAdminUser || isTalentAtsAdmin || isTechnicalSelection && (userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.TEMPLATE_REQUESTOR) || userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.TEMPLATE_VALIDATOR))) && <PrivateRoute exact path={routes.showProcess} component={pages.ProcessShowTemplateRequest} />}
                {FeatureGuard.canAccessToVacancyRequest() && (isAdminUser || isTalentAtsAdmin || isTechnicalSelection && userAuthorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.TEMPLATE_VALIDATOR)) && <PrivateRoute exact path={routes.validatorTemplateRequest} component={pages.ValidatorTemplateRequest} />}
                {isRRHHUser && <PrivateRoute exact path={routes.processes} component={pages.Processes} />}
                {isRRHHUser && <PrivateRoute exact path={routes.profileProcesses} component={pages.ProfileProcesses} />}
                {isRRHHUser && <PrivateRoute exact path={routes.processesSavedSearches} component={pages.ProcessesSavedSearches} />}
                {/*{isRRHHUser && <PrivateRoute exact path={routes.processesSavedSearches} component={pages.SavedSearchesQuery} />}*/}
                {isRRHHUser && <PrivateRoute exact path={routes.processesFavorites} component={pages.ProcessesFavorites} />}
                {isProcessSupervisor && <PrivateRoute exact path={routes.processesReports} component={pages.ProcessesReports} />}
                {isProcessSupervisor && <PrivateRoute exact path={routes.processesStatistics} component={pages.ProcessesStatistics} />}
                {<PrivateRoute exact path={routes.processesUserManual} component={pages.ProcessesUserManual} />}
                {/*
                ONBOARDING
              */}
                {isOnboard && <PrivateRoute exact path={routes.onboarding} component={pages.Onboarding} />}
                {isOnboard && <PrivateRoute exact path={routes.profileOnboarding} component={pages.OnboardingProfile} />}
                {isOnboard && <PrivateRoute exact path={routes.onboardingFavorites} component={pages.OnboardingFavorites} />}
                {isOnboard && <PrivateRoute exact path={routes.onboardingSavedSearches} component={pages.OnboardingSavedSearches} />}
                {isOnboardSupervisor && <PrivateRoute exact path={routes.onboardingReports} component={pages.OnboardingReports} />}
                {isOnboardSupervisor && <PrivateRoute exact path={routes.onboardingStatistics} component={pages.OnboardingStatistics} />}
                {<PrivateRoute exact path={routes.onboardingUserManual} component={pages.OnboardingUserManual} />}
                {/*
                Feedback
              */}
                {isFeedback && <PrivateRoute exact path={routes.feedbackInbox} component={pages.FeedbackInbox} />}
                {isFeedback && <PrivateRoute exact path={routes.profileFeedback} component={pages.ProfileFeedback} />}
                {isFeedback && <PrivateRoute exact path={routes.feedbackSavedSearches} component={pages.FeedbackSavedSearches} />}
                {isFeedback && <PrivateRoute exact path={routes.feedbackFavorites} component={pages.FeedbackFavorites} />}
                {isFeedbackSupervisor && <PrivateRoute exact path={routes.feedbackReports} component={pages.FeedbackReports} />}
                {isFeedbackSupervisor && <PrivateRoute exact path={routes.feedbackStatistics} component={pages.FeedbackStatistics} />}

                {/*
                EXIT_INTERVIEW
              */}
                {/*TODO: Qué roles tienen acceso a cada ruta*/}
                {isExitInterview && <PrivateRoute exact path={routes.exitInterviewInbox} component={pages.ExitInterviewInbox} />}
                {isExitInterview && <PrivateRoute exact path={routes.exitInterviewProfile} component={pages.ExitInterviewProfile} />}
                {isExitInterview && <PrivateRoute exact path={routes.exitInterviewFavorites} component={pages.ExitInterviewFavorites} />}
                {isExitInterviewSupervisor && <PrivateRoute exact path={routes.exitInterviewStatistics} component={pages.ExitInterviewStatistics} />}
                  {/*
                ERROR
              */}
                <Route component={pages.NotFound} />
              </Switch>
            </div>

            {/* {isModal && <PrivateRoute exact path="/my-profile/my-data" component={MyData} />} */}
          </GlobalStyles>
        </MuiThemeProvider>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  user_authorized_sections: state.auth && state.auth.data
  && state.auth.data.user_authorized_sections ? state.auth.data.user_authorized_sections : [],
})

export default withRouter(connect(mapStateToProps)(Routes))
