import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {AppBarInboxQuery, ProcessTableQuery, ProfileInfoQuery} from '../../organism'
import {SECTION_KEYS} from '../../../config/constants'

const FavoritesQuery = ({ classes, i18n }) => {
  let queryFilters = {isFavorite: true}
  const [rowsSelected, setRowsSelected] = useState([]);

  return (
    <div className={classes.root}>
        <AppBarInboxQuery
            showActions
            no_filterName={i18n.processesTable.filterProcesses}
            // module={MODULES.ID_TALENT_ACQUISITION}
            rowsSelected={rowsSelected}
            // section={"favorites"}
            section={SECTION_KEYS.FAVORITES}
        />
      <ProcessTableQuery
        title={i18n.favorites.title}
        // module={MODULES.ID_TALENT_ACQUISITION}
        updateParentRowsSelected={setRowsSelected}
        defaultFilters={queryFilters}
        rowViewRender={(id) => <ProfileInfoQuery id={id} />}
        showChangeStatus
      />
    </div>
  )
}

FavoritesQuery.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default FavoritesQuery
