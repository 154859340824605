import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import _, {isObject} from 'lodash'
import Select from 'react-select'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { InputStyled, ContainerStyled, InputHelperStyled } from './styled'
import { withI18n } from '../../hocs'
import { DateInput, InputField } from '../../atoms'
import colors from '../../../assets/colors'
import useInboxFilters from '../../../api/queries/useInboxFilters'
import Switch from '@material-ui/core/Switch'
import { Fragment } from 'react'
import { Chip } from '@material-ui/core'
import { getCitationStatusTypesTranslated } from '../../../models/citations'
import { FeatureGuard } from '../../../utils/guard'
import {getCurrentSectionKey, SECTION_KEYS} from "../../../config/constants";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.blue : state.isFocused ? colors.blueAlpha15 : 'white'
  })
}

let refs = {}
const FilterForm = ({ updateFiltersValues, filtersValues, filters, filtersCitationExtend, i18n, auth, module, location, createFilterObjectSelected, overrideFilters = false, overrideSwitchActive = false, targetModule = null }) => {

  const weAreInPlanningSection = () => {
    return location.pathname.search('talent_acquisition/planning') !== -1
  }

  const weAreInUserManagementSection = () => {
    return location.pathname.search('my-profile/user-management') !== -1
  }

  const { data: inboxFilters } = useInboxFilters(module, false, [], weAreInUserManagementSection())

  const [filterBlocks, setFilterBlocks] = useState({})
  const [isSwitchActive, setIsSwitchActive] = React.useState(overrideSwitchActive)

  function groupByFilterName(filters) {
    if (filters === null) {
      return {}
    }

    if (!filters || filters.length === 0) {
      return {}
    }

    const result = {}
    if (weAreInPlanningSection()) {
      result[i18n.modalAnnouncement.filterBlockCitation] = []
    }

    filters.forEach(item => {
      if (isFilterEnable(item, filtersValues)) {
        const filterBlock = item.filter_block || { name: i18n.modalAnnouncement.filterBlockGeneral, order: 0 }
        const filterName = filterBlock.name

        if (!result[filterName]) {
          result[filterName] = { order: filterBlock.order, items: [] }
        }
        result[filterName].items = result[filterName].items || []
        result[filterName].items.push(item)
      }
    })

    const sortedFilterBlocks = Object.values(result)
      .sort((a, b) => a.order - b.order)
      .reduce((finalResult, block) => {
        const blockName = block.items[0].filter_block ? block.items[0].filter_block.name : i18n.modalAnnouncement.filterBlockGeneral
        finalResult[blockName] = block.items
        return finalResult
      }, {})

    return sortedFilterBlocks
  }

  useEffect(() => {
    setFilterBlocks(groupByFilterName(filters))
  }, [filters, filtersValues])

  if (weAreInPlanningSection()) {
    let clonedInboxFilters = _.cloneDeep(inboxFilters)

    clonedInboxFilters.map(filterInbox => (filterInbox.remote_name = `candidature.${filterInbox.remote_name}`))
    filters = filtersCitationExtend.concat(inboxFilters)
    filters.unshift({
      answers: [],
      description: null,
      id: -7,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.modalAnnouncement.online_meeting,
      remote_name: 'method',
      type: 'boolean',
      filter_block: { name: i18n.modalAnnouncement.filterBlockCitation }
    })
    filters.unshift({
      answers: [],
      description: null,
      id: -5,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.modalAnnouncement.location,
      remote_name: 'location',
      type: 'text',
      filter_block: { name: i18n.modalAnnouncement.filterBlockCitation }
    })
    filters.unshift({
      answers: [],
      description: null,
      id: -4,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.modalAnnouncement.interviewer,
      remote_name: 'interviewer',
      type: 'text',
      filter_block: { name: i18n.modalAnnouncement.filterBlockCitation }
    })
    filters.unshift({
      answers: [],
      description: null,
      id: -3,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.modalAnnouncement.date,
      remote_name: 'date',
      type: 'range_date',
      filter_block: { name: i18n.modalAnnouncement.filterBlockCitation }
    })
    filters.unshift({
      answers: [],
      description: null,
      id: -2,
      module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
      name: i18n.modalAnnouncement.citationDate,
      remote_name: 'citationDate',
      type: 'range_date',
      filter_block: { name: i18n.modalAnnouncement.filterBlockCitation }
    })
    if (FeatureGuard.canAccessToFunnelStatus()) {
      filters.unshift({
        answers: getCitationStatusTypesTranslated(i18n),
        description: null,
        id: -1,
        module_type: { id: 'talent_acquisition', name: 'talent_acquisition' },
        name: i18n.modalAnnouncement.citationStatus,
        remote_name: 'status',
        type: 'select',
        filter_block: { name: i18n.modalAnnouncement.filterBlockCitation }
      })
    }
  } else if (!weAreInUserManagementSection() && !overrideFilters) {
    filters = inboxFilters
  }

  const handleOnChangeNumberRange = (value, name, subname, item, oldValue) => {
    let filterOldObj = _.get(oldValue, '[0]', null)
    let valueFormatted = { from: null, to: null }
    if (filterOldObj) {
      valueFormatted = filterOldObj.value
    }

    value = parseInt(value)

    if (!isNaN(value)) {
      valueFormatted[subname] = value
    } else {
      valueFormatted[subname] = null
    }

    filtersOnChangeHandle([createFilterObjectSelected(valueFormatted, item.remote_name, module, item)], name)
  }

  const filtersOnChangeHandle = (arrObjSelected, name) => {
    if (targetModule) {
      updateFiltersValues(arrObjSelected, name, targetModule);
    } else {
      updateFiltersValues(arrObjSelected, name);
    }
  }

  const renderInputField = ({ remoteValue, handleOnChange, ...rest }) => {
    let value = _.get(remoteValue, '[0].value', '')
    return (
      <>
        <InputField
          value={value}
          handleOnChange={handleOnChange}
          handleRef={input => {
            refs[rest.name] = input
          }}
          key={rest.name}
          {...rest}
        />
        {refs[rest.name] === document.activeElement && (
          <InputHelperStyled>
            <b>
              <>&quot;;&quot;</>
            </b>{' '}
            {i18n.modalFilter.inputTextHelper}
          </InputHelperStyled>
        )}
      </>
    )
  }

  const getSelectOptions = item => {
    if (item.name === 'Estado' && !_.includes(auth.email, 'hrbotfactory')) {
      let itemAnswers = item.answers
      let position = _.indexOf(itemAnswers, 15)

      if (position !== -1) {
        _.pullAt(itemAnswers, position)
      }

      item.answers = itemAnswers
    }

    let createdFilters = [];
    _.map(_.get(item, 'answers'), answer => {
      if (isObject(answer) && answer.hasOwnProperty("value") && answer.hasOwnProperty("text")
        && answer.value === "" && answer.text === "") {
        return;
      }
      createdFilters.push(createFilterObjectSelected(answer, item.remote_name, module, item));
    })
    return createdFilters;
  }

  const renderInput = item => {
    const { type } = item
    const remoteValue = _.get(filtersValues, item.remote_name)

    switch (type) {
      case 'boolean':
        return <>
          <Switch
            checked={isSwitchActive}
            onClick={() => {
              setIsSwitchActive(!isSwitchActive)
              filtersOnChangeHandle([createFilterObjectSelected(!isSwitchActive, item.remote_name, module, item)], item.remote_name)
            }}
            name={item.name}
            value="isSwitchActive"
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {getCurrentSectionKey() === SECTION_KEYS.JOB_OFFERS && <label style={{fontWeight: 600}}>{isSwitchActive ? i18n.job_offers.active_label : i18n.job_offers.inactive_label}</label>}
        </>
      case 'text':
        return renderInputField({
          remoteValue,
          handleOnChange: value => filtersOnChangeHandle([createFilterObjectSelected(value, item.remote_name, module, item)], item.remote_name),
          name: item.remote_name,
          placeholder: i18n.filterFields[item.name] || item.name
        })
      case 'select':
        return (
          <InputStyled>
            <Select
              defaultValue={remoteValue}
              value={filtersValues ? filtersValues[item.remote_name] : null}
              isMulti={true}
              options={getSelectOptions(item)}
              isSearchable={true}
              isClearable={true}
              placeholder={i18n.modalFilter.select}
              onChange={e => filtersOnChangeHandle(e, item.remote_name)}
              styles={customStyles}
            />
          </InputStyled>
        )
      case 'range_date':
        return (
          <DateInput
            value={_.get(remoteValue, '[0].value', '')}
            format="DD/MM/YYYY"
            onChange={e => {
              const regex = /^(0[1-9]|[12][0-9]|3[01])[-/](0[1-9]|1[0-2])[-/]\d{4}$/
              if (e.hasOwnProperty('from') && !regex.test(e.from)) {
                delete e.from
              }
              if (e.hasOwnProperty('to') && !regex.test(e.to)) {
                delete e.to
              }
              if (Object.keys(e).length == 0) {
                e = null
              }
              return filtersOnChangeHandle([createFilterObjectSelected(e, item.remote_name, module, item)], item.remote_name)
            }}
            placeholder={i18n.modalFilter.fromDate}
            name={item.remote_name}
            range
          />
        )
      case 'range_number':
        return (
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <InputField
                value={_.get(remoteValue, '[0].value.from', '')}
                handleOnChange={(value, name) => handleOnChangeNumberRange(value, name, 'from', item, remoteValue)}
                name={item.remote_name}
                placeholder={i18n.modalFilter.from}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                value={_.get(remoteValue, '[0].value.to', '')}
                handleOnChange={(value, name) => handleOnChangeNumberRange(value, name, 'to', item, remoteValue)}
                name={item.remote_name}
                placeholder={i18n.modalFilter.to}
              />
            </Grid>
          </Grid>
        )
      default:
        return null
    }
  }

  const isFilterEnable = (item, filtersValues) => {
    if (filtersValues && filtersValues.candidatura_seleccionada && item.candidature) {
      const found = filtersValues.candidatura_seleccionada.filter(e => e.value == item.candidature.remote_name)
      if (found.length == 0) {
        return false
      }
    }
    return true
  }

  return (
    <>
      <ContainerStyled>
        {_.map(filterBlocks, (filters, blockName, key) => {
          return (
            <div key={key}>
              <Grid container className="customBlock">
                {blockName ? (
                  <Chip label={i18n.filterBlocks[blockName] || blockName} variant="outlined"></Chip>
                ) : (
                  <Chip label={i18n.modalAnnouncement.filterBlockGeneral} variant="outlined"></Chip>
                )}
                {_.map(filters, (item, key) => {
                  if (!isFilterEnable(item, filtersValues)) return null
                  return (
                    <Fragment key={key}>
                      <Grid container className="customFilterItem" alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <label>{i18n.filterFields[item.name] || item.name}</label>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          {renderInput(item)}
                        </Grid>
                      </Grid>
                    </Fragment>
                  )
                })}
              </Grid>
            </div>
          )
        })}
      </ContainerStyled>
    </>
  )
}

FilterForm.propTypes = {
  i18n: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func,
  filters: PropTypes.array,
  filtersValues: PropTypes.object,
  module: PropTypes.string
}

FilterForm.defaultProps = {
  filtersValues: {}
}

const enharce = compose(withI18n)

export default enharce(FilterForm)
