import React from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal'
import { ModalStyled } from './styled'
import { withI18n } from '../../hocs'
import { ModalHeader, RegularButton } from '../../atoms'

const ModalFilter = ({ open, handleClose, i18n, handleSearchClick, title, children }) => {
  function handeOnSearch(e) {
    e.preventDefault()
    handleSearchClick()
    handleClose()
  }

  return (
    <div>
      <Modal
        style={{ overflow: 'scroll' }}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeader title={title} closeClick={handleClose} />
          <div className="modal-body">
            <form>
              {children}
              <div className="button-wrapper">
                <RegularButton handleOnClick={handeOnSearch} type="submit" title={i18n.modalFilter.search} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </div>
  )
}

ModalFilter.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  handleSearchClick: PropTypes.func,
  filtersCitation: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.node,
  isCitation: PropTypes.bool,
}

ModalFilter.defaultProps = {
  title: '',
  children: <></>
}

const enharce = compose(withI18n)

export default enharce(ModalFilter)
