import React, { Component } from 'react'
import i18n from '../../assets/i18n'

function withI18n(WrappedComponent) {
  return class WithI18n extends Component {
    render() {
      return <WrappedComponent {...this.props} i18n={i18n} />
    }
  }
}

export default withI18n
