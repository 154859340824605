import React from 'react'

const icon = props => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity={0.6} d="M11 19V21" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path opacity={0.6} d="M7 19V21" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path opacity={0.6} d="M15 21V20C15 19.071 15 18.6065 14.9384 18.2178C14.5996 16.0784 12.9216 14.4004 10.7822 14.0616C10.3935 14 9.92901 14 9 14V14C8.07099 14 7.60649 14 7.21783 14.0616C5.07837 14.4004 3.40042 16.0784 3.06156 18.2178C3 18.6065 3 19.071 3 20V21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path opacity={0.6} d="M21 21V20C21 19.071 21 18.6065 20.9384 18.2178C20.5996 16.0784 18.9216 14.4004 16.7822 14.0616C16.3935 14 15.929 14 15 14C14.071 14 13.6065 14 13.2178 14.0616C12.7912 14.1291 12.383 14.2499 12 14.417" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path opacity={0.6} d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path opacity={0.6} d="M12.0239 9.63023C12.7249 10.5112 13.8136 11 15.0001 11C17.2001 11 19.0001 9.2 19.0001 7C19.0001 4.8 17.2001 3 15.0001 3C13.811 3 12.7201 3.49095 12.0193 4.37555" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default icon
