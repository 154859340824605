import styled from 'styled-components'
import colors from '../../../assets/colors'

export const AppBarStyled = styled.div`
  .appbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .selectContainer {
    flex: 1;
    margin-right: 10px;
    margin-top: 10px;
  }

  .filterButton {
    height: 50px;
    margin-right: 10px;
    background-color: ${colors.grey};
    box-shadow: none;
    text-transform: none;
    color: ${colors.white};
  }

  .dropdownIndicator {
    padding: 10px;
  }

  .saveButton {
    height: 50px;
    background-color: ${props => props.theme.primary};
    margin-right: 10px;
    text-transform: none;
    color: ${colors.white};
  }

  .user-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .clue-wrapper {
      margin-left: 15px;
    }
  }
`

export const InputStyled = styled.div`
  > div > div {
    min-height: 50px;
    font-size: 14px;
    padding: 0 10px;
    border: 1px solid ${colors.grey};
    box-shadow: none;
    :hover,
    :active,
    :focus {
      border: 1px solid ${colors.grey};
    }
  }

  [class*='-menu'] {
    min-height: 100px;
    overflow-y: scroll;
    * {
      background-color: white;
    }
  }

  > div > div span {
    background-color: transparent;
  }

  .dropdownIndicator {
    color: ${colors.greyAlpha15};
  }
`

export const ModalStyled = styled.div`
  && {
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    width: 685px;
    outline: none;

    .modal-body {
      background-color: ${colors.white};
      padding: 30px 50px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      outline: none;
      .grid-decoration {
        position: relative;
        ::before {
          content: '-';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -4px;
        }
      }
      label {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }

      .button-wrapper {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 50px;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: right;
          cursor: pointer;
        }

        button {
          margin-left: 20px;
        }
      }
    }
  }
`
