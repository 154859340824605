import styled from 'styled-components'
import colors from '../../../assets/colors'

export const FunnelStatisticLegacyStyled = styled.div`
  .card-container {
    text-align: left;
    background-color: ${colors.white};
    padding: 30px 20px;
    min-width: 200px;
    min-height: 448px;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(98, 102, 113, 0.06), 0 2px 2px rgba(98, 102, 113, 0.06);
  }
  
  .full{
    width: 100%;
  }
  
  .label-stat {
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${colors.blackAlpha80};
    margin: 0;
    margin-bottom: 20px;
  }
    
  .funnel-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .extra-data {
    font-size: 14px;
  }

  .container-info{
    position: relative;
  }

`

const funnel_polygon = ({top, bottom}) => {
  return `polygon(${50 - top/2}% 0, ${top/2  + 50}% 0, ${bottom/2 + 50}% 100%, ${50 - bottom/2}% 100%)`;
}

export const FunnelItem = styled.div`
  max-width: 100%;
  width: 30vmin;
  height: 8vmin;
  background-color: ${props => props.theme.primary+props.opacity};
  clip-path: ${props => funnel_polygon(props)};
  margin-bottom: 1px;
  padding-top: 12%;
  text-align: center;
`

export const FunnelInfo = styled.div`
  position: absolute;
  bottom: 0;
  right: 0; 
  text-align: right;   
  .title{
    color: ${colors.blackAlpha50};
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 3px;
  }
 .value{
    color: ${colors.blackAlpha80};
    font-size: 28px;
    font-weight: 800;
    padding-bottom: 9px;
  }
`
