import styled from 'styled-components'
import colors from '../../../assets/colors'

const BackButtonStyled = styled.div`
  .select-wrapper {
    position: relative;
    background: transparent;
    height: 36px !important;
    margin-left: 12px !important;
    width: 196px;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      opacity: ${props => (props.disabled ? 0.3 : 0.4)};
    }
  }

  select {
    height: 36px !important;
    box-sizing: border-box;
    padding: 0 10px !important;
    letter-spacing: 0.03em;
    outline: none;
    width: 100%;
    appearance: none;

    text-align: left !important;
    background: rgba(0, 0, 0, 0);
    border: 1.5px solid ${colors.grey3} !important;
    border-radius: 6px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: ${colors.grey3} !important;

    ::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.03em;
      color: ${colors.blackAlpha40};
    }
  }
`

export default BackButtonStyled
