import axios from 'axios'
import _ from 'lodash'
import * as ApiConfig from '../config/api'
import { TOKEN_KEY } from '../config/constants'

const instance = axios.create({
  baseURL: ApiConfig.BASE_URL,
  headers: { 'Content-type': 'application/json'}
})

export const configure = errorAuthCallback => addAxiosInterceptors(errorAuthCallback)

export function configureAxiosDefaults(header, value) {
  instance.defaults.headers.common[header] = value
}

let isAlreadyFetchingAccessToken = false
let subscribers = []
let responseInterceptors = null

function onAccessTokenFetched(token) {
  subscribers = subscribers.filter(callback => callback(token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export const refreshToken = () => {
  localStorage.removeItem(TOKEN_KEY)
  // if (firebase.auth().currentUser) {
  //   return firebase.auth().currentUser.getIdToken(true)
  // } else {
  // return Promise.reject(new Error('refreshToken not implemented'))
  // }
}

const addAxiosInterceptors = errorAuthCallback => {
  const successCallBack = response => response
  const errorCallback = error => {
    const {
      config,
      response: { status }
    } = error

    if (status === 401 || status === 403) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        return refreshToken()
          .then(token => {
            instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
            isAlreadyFetchingAccessToken = false
            onAccessTokenFetched(token)
          })
          .catch(error => {
            isAlreadyFetchingAccessToken = false
            errorAuthCallback(error)
          })
      }

      localStorage.removeItem(TOKEN_KEY)

      const originalRequest = config

      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(token => {
          originalRequest.headers.Authorization = 'Bearer ' + token
          resolve(instance(originalRequest))
        })
      })
      return retryOriginalRequest
    } else {
      return Promise.reject(error)
    }
  }

  if (!responseInterceptors) {
    instance.interceptors.response.eject(responseInterceptors)
  }
  responseInterceptors = instance.interceptors.response.use(successCallBack, errorCallback)
}

export function get(url, config) {
  return instance
    .get(url, config)
    .then(response => {
      return response && response.data ? response.data : null
    })
    .catch(error => {
      let res
      if (error && error.response) {
        res = { code: error.response.status, msg: error.response.data, error: error }
        throw res
      } else {
        res = { code: 500, msg: error.message, error: error }
        throw res
      }
    })
}

export function getList(url) {
  return instance
    .get(url)
    .then(response => {
      const total = getTotal(_.get(response, 'headers')) || 0
      const data = _.get(response, 'data', [])
      return { total, data }
    })
    .catch(error => {
      if (error && error.response) {
        const res = { code: error.response.status, msg: error.response.data, error: error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error: error }
        throw res
      }
    })
}

export function post(url, data, headers = {}) {
  return instance
    .post(url, data, { headers })
    .then(response => {
      return response && response.data ? response.data : null
    })
    .catch(error => {
      if (error && error.response) {
        const res = { code: error.response.status, msg: error.response.data, error: error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error: error }
        throw res
      }
    })
}

export function put(url, data) {
  return instance
    .put(url, data)
    .then(response => {
      return response && response.data ? response.data : null
    })
    .catch(error => {
      if (error && error.response) {
        const res = { code: error.response.status, msg: error.response.data, error: error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error: error }
        throw res
      }
    })
}

export function patch(url, data) {
  return instance
      .patch(url, data)
      .then(response => {
        return {
          data: response && response.data ? response.data : null,
          fullResponse: response
        };
      })
      .catch(error => {
        if (error && error.response) {
          throw { code: error.response.status, msg: error.response.data, error: error };
        } else {
          throw { code: 500, msg: error.message, error: error };
        }
      });
}


export function remove(url, data) {
  return instance
    .delete(url, { data })
    .then(response => {
      return response && response.data ? response.data : null
    })
    .catch(error => {
      if (error && error.response) {
        const res = { code: error.response.status, msg: error.response.data, error: error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error: error }
        throw res
      }
    })
}

const getTotal = headers => {
  if (headers && _.get(headers, 'content-range')) {
    return parseInt(
      _.get(headers, 'content-range')
        .split('/')
        .pop(),
      10
    )
  } else return 0
}

export const refetchQueryOnCacheDataLost = (data, dataUpdatedAt, refetch) => {
  if (data === undefined && dataUpdatedAt) {
      refetch();
  }
}