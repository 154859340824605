import styled from 'styled-components'

const BoxVideoCandidateStyled = styled.div`
  .boxInfo {
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(98, 102, 113, 0.06), 0px 2px 2px rgba(98, 102, 113, 0.06);
    border-radius: 6px;
    padding: 20px 30px;
    margin-bottom: 30px;
  }

  .wrapper-video {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 20px;
  }
   
  .custom-video-player {
    margin: auto;
    width: auto !important;
    border-radius: 18px !important ;

    video {
      border-radius: 18px !important ;
    }
  }
  
`
export default BoxVideoCandidateStyled
