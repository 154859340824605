import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const TimeInputStyled = styled.div`
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }

  .MuiInputBase-root {
    display: grid;
    min-width: 140px;
    line-height: 25px;
    min-height: 40px;

    
  }

  label{
    line-height:0px !important;
    
    :active{
      transform: translate(25px, 0px) scale(0.75) !important;
    }
  }
  .MuiInputLabel-outline.MuiInputLabel-shrink {
    transform: translate(25px, 0px) scale(0.75) !important;
  }
  
  input {
    width: 100% !important;
    background: ${colors.white};
    //border: 1px solid ${colors.greyAlpha50};
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 5px;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.03em;
    color: ${colors.black};
    outline: none;
    border: none;
    appearance: none;

    ::placeholder {
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.03em;
      text-align: center;
      color: ${colors.blackAlpha40};
    }

    ${props =>
      props.fontSize === 'small' &&
      css`
        font-size: 14px;
        line-height: 21px;
        ::placeholder {
          font-size: 14px;
          line-height: 25px;
        }
      `}
  }

  input[type="time"].time_input::-webkit-calendar-picker-indicator {
    transform: scale(1.2);
    position: relative;
    right: 10px;
  }
`

export default TimeInputStyled
