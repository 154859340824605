import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'
import { MTLinkStyled } from './styled'

const MTText = ({ value, color, href }) => {
  let text = value

  if (_.isString(value) && moment(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid()) {
    text = moment(value).format('DD/MM/YYYY')
  }

  return <MTLinkStyled color={color} href={href}>{text}</MTLinkStyled>
}

MTText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  href: PropTypes.string
}

MTText.defaultProps = {
  color: undefined,
  href: '',
}

export default MTText
