import React, { useState, useEffect } from 'react'
import { PhasesStyled } from './styled'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import { InputField, RegularButtonOutline } from '../../atoms'
import { DndContext, closestCenter } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { ListItem } from '../../molecules'
import { CustomAlert } from '../../atoms'
import { useMutation } from '@tanstack/react-query'
import usePhases from '../../../api/queries/usePhasesProcesses'
import { postPhase, patchPhase, patchPhaseOrder, deletePhase } from '../../../api'
import { LinearProgress } from '@material-ui/core'
import { useAppContext } from '../../../context/appContext'
import { onMutatePhaseOrState } from '../../../api/mutations/mutationCallbacks'
import { PHASES_STATUS_TYPES } from '../../../config/constants'

const Phases = ({ i18n, setAllPhasesData }) => {
  const { module } = useAppContext()
  const [phaseName, setPhaseName] = useState('')
  const [userValues, setUserValues] = useState([])
  const [alert, setAlert] = useState({ message: '', severity: '' })
  const [saveChangesAlert, setSaveChangesAlert] = useState({ message: '', severity: '' });
  const [hasChanges, setHasChanges] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showChangesAlert, setShowChangesAlert] = useState(false)

  const { data: phasesData, isLoading, isError, refetch: refetchPhases } = usePhases(module, data => data)

  useEffect(() => {
    if (!isLoading && !isError && phasesData) {
      setAllPhasesData(phasesData.data)
      const transformedPhases = phasesData.data.map(phase => ({
        id: phase.id,
        name: phase.name,
        type: phase.type,
        order: phase.order,
      }))

      setUserValues(existingValues => {
        const existingPhaseNames = existingValues.map(value => value.name)
        const newPhases = transformedPhases.filter(phase => !existingPhaseNames.includes(phase.name))
        return [...existingValues, ...newPhases]
      })
    }

  }, [phasesData])

  const createPhase = async order => {
    if (phaseName) {
      const data = {
        name: phaseName,
        type: PHASES_STATUS_TYPES.CUSTOM,
        order: order,
      }

      const response = await postPhase(data, module)
      return response.data
    }
  }

  const editPhase = async (id, updatedData) => {
    const response = await patchPhase(id, updatedData, module)
    return response.data
  }

  const onSubmitDeletePhase = async itemToDelete => {
    const { id } = itemToDelete
    try {
      await mutationDeletePhase.mutateAsync(id, {
        onSuccess: () => {
          onMutatePhaseOrState(module, [], 'mutationDeletePhase');
        }
      });

      const updatedUserValues = userValues.filter(phase => phase.id !== id)
      setUserValues(updatedUserValues)

      setAllPhasesData(prevAllPhasesData => prevAllPhasesData.filter(phase => phase.id !== id))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error al eliminar la fase:', error)
    }
  }

  const onSubmitEditPhase = async updatedPhase => {
    try {
      await mutationEditPhase.mutateAsync({ id: updatedPhase.id, name: updatedPhase.name }, {
        onSuccess: () => {
          onMutatePhaseOrState(module, [], 'mutationEditPhase');
        }
      });
      const updatedUserValues = userValues.map(phase => (phase.id === updatedPhase.id ? { ...phase, name: updatedPhase.name } : phase))
      setUserValues(updatedUserValues)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error al editar la fase:', error)
    }
  }

  const updatePhaseOrder = async updatedOrder => {
    const { data, fullResponse } = await patchPhaseOrder(updatedOrder)
    if (fullResponse.status !== 200) {
      setSaveChangesAlert({ message: i18n.phases_states.errorOrderPhasesUpdate, severity: 'error' })
      throw new Error('Network response was not ok')
    } else {
      setSaveChangesAlert({ message: i18n.phases_states.changesSavedSuccessfully, severity: 'success' })
    }

    return data
  }

  const handleSaveChanges = () => {
    if (hasChanges) {
      const updatedOrder = userValues.map(({ id, order }) => ({ id, order }));
      mutationPatchOrder.mutate(updatedOrder, {
        onSuccess: () => {
          onMutatePhaseOrState(module, [], 'mutationPatchOrder');
        },
      });
    }
  };

  const mutationSubmitPhase = useMutation(createPhase)
  const mutationEditPhase = useMutation(updatedPhase => editPhase(updatedPhase.id, { name: updatedPhase.name }))
  const mutationPatchOrder = useMutation(updatePhaseOrder)
  const mutationDeletePhase = useMutation(deletePhase)

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);

      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [alert]);


  useEffect(() => {
    if (saveChangesAlert.message) {
      setShowChangesAlert(true);

      const timer = setTimeout(() => {
        setShowChangesAlert(false);
        setSaveChangesAlert({ message: '', severity: '' });
        setHasChanges(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [saveChangesAlert]);

  const handleAddPhase = async () => {
    if (userValues.length > 12) {
      setAlert({ message: i18n.phases_states.alertCannotAddMoreEightPhases, severity: 'info' });
    } else if (phaseName.trim() !== '') {
      const existingPhase = userValues.find(value => value.name === phaseName);
      if (!existingPhase) {
        try {
          const newPhaseData = await mutationSubmitPhase.mutateAsync(userValues.length + 1, {
            onSuccess: () => {
              onMutatePhaseOrState(module, [], 'mutationSubmitPhase');
            }
          });

          setUserValues(existingValues => [
            existingValues.find(value => value.type === PHASES_STATUS_TYPES.RECEIVED),
            ...existingValues.filter(value => value.type !== PHASES_STATUS_TYPES.RECEIVED && value.type !== PHASES_STATUS_TYPES.HIRED),
            newPhaseData,
            existingValues.find(value => value.type === PHASES_STATUS_TYPES.HIRED)
          ]);

          setAlert({ message: i18n.phases_states.alertCreatedPhase, severity: 'success' });
          setPhaseName('');
        } catch (error) {
          setAlert({ message: i18n.phases_states.alertErrorCreatePhase, severity: 'error' });
        }
      } else {
        setAlert({ message: i18n.phases_states.alertPhaseExists, severity: 'info' });
      }
    } else {
      setAlert({ message: i18n.phases_states.alertAddNamePhase, severity: 'info' });
    }
  };


  const handleDragEndPhase = event => {
    const { active, over } = event

    setUserValues(prevUserValues => {
      const oldIndex = prevUserValues.findIndex(phase => phase.id === active.id)
      const newIndex = prevUserValues.findIndex(phase => phase.id === over.id)

      if (oldIndex !== newIndex) {
        setHasChanges(true)
      }

      const updatedUserValues = arrayMove(userValues, oldIndex, newIndex)

      updatedUserValues.forEach((phase, index) => {
        phase.order = index + 2
      })

      const receivedPhase = updatedUserValues.find(phase => phase.type === PHASES_STATUS_TYPES.RECEIVED)
      const hiredPhase = updatedUserValues.find(phase => phase.type === PHASES_STATUS_TYPES.HIRED)

      if (receivedPhase) receivedPhase.order = 1
      if (hiredPhase) hiredPhase.order = 99

      return [...updatedUserValues]
    })
  }

  if (isLoading) {
    return <LinearProgress />
  }

  if (isError) {
    return showAlert && <CustomAlert severity="error" message={i18n.phases_states.alertErrorGetPhases} />
  }

  const handlePhaseNameChange = e => {
    setPhaseName(e.target.value)
  }

  return (
    <PhasesStyled>
      <Grid item className="sectionSecond" xs={12} sm={12}>
        <h2>{i18n.phases_states.titlePhase}</h2>
        <p>{i18n.phases_states.descriptionPhase}</p>
        <p>{i18n.phases_states.descriptionTwoPhase}</p>

        <Grid item xs={12} sm={12}>
          <label>{i18n.phases_states.labelPhase}</label>
          <InputField placeholder={i18n.phases_states.placeholderPhase} value={phaseName} onChange={handlePhaseNameChange} />
        </Grid>
        <div className="containerButtonForm">
          {showAlert && !showChangesAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
          {mutationSubmitPhase.isLoading && <CircularProgress />}
          <RegularButtonOutline type="submit" title={i18n.phases_states.titleButtonPhase} onClick={handleAddPhase} />

        </div>

        <div className="containerDragAndDrop">
          <div className="containerDragAndDropList">
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEndPhase}>
              <SortableContext items={[...userValues]} strategy={verticalListSortingStrategy}>
                {userValues &&
                  userValues.length > 0 &&
                  userValues.map(item => {
                    return (
                      <ListItem
                        i18n={i18n}
                        item={item}
                        key={item.id}
                        onSubmitEditPhase={onSubmitEditPhase}
                        onSubmitDelete={onSubmitDeletePhase}
                        elementType="phase"
                        phasesData={phasesData}
                      />
                    )
                  })}
              </SortableContext>
            </DndContext>
          </div>
        </div>

        <div className="containerButtonForm">
          {showChangesAlert && <CustomAlert severity={saveChangesAlert.severity} message={saveChangesAlert.message} />}
          {mutationPatchOrder.isLoading && <CircularProgress />}
          {hasChanges && <RegularButtonOutline type="submit" title={i18n.phases_states.buttonAllSave} onClick={handleSaveChanges} />}

        </div>
      </Grid>
    </PhasesStyled>
  )
}

export default Phases
