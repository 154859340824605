/* eslint-disable react/display-name */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import {
  BoxInfoHeader,
  BoxOtherInfo,
  BoxOtherInfoHeader,
  BoxProfileCite,
  BoxVideoSentiments,
  DocumentsList,
  DocumentsListViewer,
  HeaderProfileButtons,
  ObservationsTable,
  OfferStateImage,
  ProfileActionsButtons,
  ProfileStats,
} from '../../molecules'
import ProfileInfoStyled from './styled'
import {MTDate, MTText, SpinnerIcon} from '../../atoms'
import BoxPDAMVMInfo from "../../molecules/box-pda-mvm-info";
import BoxPDAEmergiaInfo from "../../molecules/box-pda-emergia-info";
import Divider from '@material-ui/core/Divider';
import JSZip from "jszip";
import _map from "lodash/map";
import * as S from "../profile-processes-info/styled";
import _get from "lodash/get";
import {Box, Tab, Tabs, Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DraggableModal from "../../molecules/draggable-modal/view";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialTable from "material-table";
import routes from "../../../config/routes";
import OfferCandidatureStatusType from "../../../models/offer-candidature-status-type";
import {getCurrentSectionKey, MODULES, SECTION_KEYS} from "../../../config/constants";
import {useAppContext} from "../../../context/appContext";
import useTotalCount from "../../../api/queries/useTotalCount";
import * as api from "../../../api";
import { FeatureGuard } from '../../../utils/guard'

const ProfileInfo = ({
                       i18n, fetchUser, fetchUserList, profileData, profileMultipleData, history, fetchPushOffers,
                       match, isFetching, isFetchingObservations, isFetchingPushOffers, isFetchingUserList, fetchObservations,
                       observations, pushOffers, postObservations, deleteFavorites, postFavorites, postReads, showActions,
                       showFav, showObsInput, id, onGoBack, tagsTypes, changeTags, fetchTagsTypes, user, client, totalPlanning,
                       totalProcesses, fetchStatusTypes, citationsData
                     }) => {

  const {filters, module, clearContextIfNeeded} = useAppContext();

  useEffect(() => {
    clearContextIfNeeded(MODULES.currentModule());
  }, [module]);

  const {data: totalRQuery, isFetched: isTotalRQueryFetched} = useTotalCount(module, getCurrentSectionKey(), filters[getCurrentSectionKey()]);

  // const [observationInput, setObservationInput] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [ tabValue, setTabValue ] = useState( 0 );
  const [ interviewerNote, setInterviewerNote ] = useState( '' );
  const [ printNotes, setPrintNotes ] = useState( false );
  const [showCvPreview, setShowCvPreview] = useState(false);
  const [showAttachmentsPreview, setShowAttachmentsPreview] = useState(false);
  const [pushOffersData, setPushOffersData] = useState([]);
  const candidatureId = id || match.params.slug;

  const [dniValue, setDniValue] = useState(null);
  const [candidatureTabsData, setCandidatureTabsData] = useState([]);
  const [candidatureTabValue, setCandidatureTabValue] = useState(0);

  const pushOfferTableColumns = [
    { title: i18n.pushOfferTable.name, field: 'name', render: row => <MTText value={row.name}/>},
    { title: i18n.pushOfferTable.description, field: 'description', render: row =>
        <Tooltip title={<p dangerouslySetInnerHTML={{__html: row.description}}></p>}>
          <p className="offer_description"
             dangerouslySetInnerHTML={{__html: row.description}}>
          </p>
        </Tooltip>
    },
    { title: i18n.pushOfferTable.userName, field: 'userName', render: row => <MTText className="offer_user_name" value={row.userName}/>  },
    { title: i18n.pushOfferTable.createdAt, field: 'createdAt', render: row => row.createdAt ? <MTDate date={row.createdAt}/> : '-' },
    { title: i18n.pushOfferTable.state, field: 'state', render: row => {
        let icon = null;
        switch (row.state) {
          case OfferCandidatureStatusType.INTERESTED:
            icon = <OfferStateImage state={OfferCandidatureStatusType.INTERESTED} bigSize={true}></OfferStateImage>
            break;
          case OfferCandidatureStatusType.NOT_INTERESTED:
            icon = <OfferStateImage state={OfferCandidatureStatusType.NOT_INTERESTED} bigSize={true}></OfferStateImage>
            break;
          case OfferCandidatureStatusType.UNANSWERED:
            icon = <OfferStateImage state={OfferCandidatureStatusType.UNANSWERED} bigSize={true}></OfferStateImage>
            break;
          default:
            break;
        }
        return icon;
      }
    },
  ];
  const tableOptions = {
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    cellStyle: { textAlign: 'center' },
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
    toolbar: false,
    draggable: false,
  }

  //Tab Panel
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  /* TODO: refactorizar, valores hardcodeados para poder inicializar initBox
   que tiene tagsTypes y el paginado al entrar desde otro lugar que no sea inbox*/
  // const queryParams = {
  //   filters: {},
  //   range: [
  //     0,
  //     19
  //   ],
  //   sort: [
  //     "",
  //     ""
  //   ]};

  const reloadById = (candidatureId) => {
    if (candidatureId && typeof candidatureId === 'string' && candidatureId.split('-').length > 1) {
      const ids = _.filter(candidatureId.split('-'), id => !_.isEmpty(id))
      fetchUserList(ids).then(() => setTimeout(window.print, 300))
    } else {
      fetchUser(candidatureId)
      postReads(candidatureId)
      fetchObservations(candidatureId)
      fetchPushOffers(candidatureId)
    }
  };

  useEffect(() => {
    if (candidatureId) {
      reloadById(candidatureId)
      fetchStatusTypes();
      fetchTagsTypes();
    }
  }, [candidatureId]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (client && dniValue) {
      api.getCandidaturesByClientAndDni(client.id, dniValue).then(res => {
        let data = res ? res.data : null;
        if (data) {
          setCandidatureTabsData(res.data);
        }

        if (citationsData && citationsData.data) {
          let inboxData = citationsData.data;
          data.map(item => {
            if (_.find(inboxData, {candidature:{id: item.id}})) {
              let key = client && client.id ? `profileIdMapper-${client.id}` : 'profileIdMapper';
              localStorage.setItem(key, item.id)
            }
          })
        }
      })
    }
  }, [client, dniValue])

  useEffect(() => {
    setPushOffersData(pushOffers)
  }, [pushOffers]);

  useEffect(() => {
    if (profileData) {
      setIsFavorite(profileData.is_favorite);
      setInterviewerNote(profileData.nota_entrevistador)

      let cv = _get(profileData, 'cv');
      if (cv && cv.length > 0) {
        let cvMainUrl = cv.split('?').shift();
        // eslint-disable-next-line
        let extension = cvMainUrl.split('.').pop();
        //setShowAttachmentsPreview(extension.includes('doc'));
        setShowCvPreview(true);
      }

      let attachments = _get(profileData, 'attachments');
      if (attachments && !Array.isArray(attachments)) attachments = [attachments];
      if (attachments && attachments.length > 0) {
        setShowAttachmentsPreview(true);
      } else {
        setShowAttachmentsPreview(false);
      }
    }

  }, [profileData])

  const handleAddObservation = (value) => {
    postObservations(candidatureId, value)
  }

  /* TODO: revisar porque no esta siendo llamada esta funcion */
  /*const handleOnChange = (value) => {
    setObservationInput(observationInput, value);
  }*/

  const handleOnClickFav = () => {
    // const isFavorite = profileData.is_favorite

    if (isFavorite) {
      // deleteFavorites([candidatureId]).then(() => reloadById())
      deleteFavorites([candidatureId]).then(() => setIsFavorite(false))
    } else {
      postFavorites([candidatureId]).then(() => setIsFavorite(true))
    }
  }

  const formatCV = (cvFile) => {
    if (cvFile) {
      let cvMainUrl = cvFile.split('?').shift();
      let cvName = cvMainUrl.split('/').pop();
      let originalUrl = cvMainUrl.split('.com/').pop();

      let cvObj = {
        id: originalUrl,
        name: decodeURI(cvName),
        file: cvFile,
      }
      return [cvObj];
    }
    return [];
  }

  const formatAttachments = data => {
    if (!data) return []
    if (!Array.isArray(data)) data = [data];
    if (data.length <= 1 && "id" in data) data = [data]

    let files = _map(data, ({id, original_name, url}) => {
      return {id, name: original_name, file: url}
    });
    //let cv = formatCV(_get(profileData, 'cv'), true);
    //if (!_.isEmpty(cv)) files.push(cv)

    return files;
  }

  const downloadBlob = (blob, name = 'myFile') => {
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.href = blobUrl
    link.download = name
    document.body.appendChild(link)
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    )
    document.body.removeChild(link)
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }} style={{padding: "0px"}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps( index ) {
    return {
      id: `simple-tab-${ index }`,
      'aria-controls': `simple-tabpanel-${ index }`,
    };
  }

  const handleTabChange = ( event, newValue ) => {
    setTabValue( newValue );
  };
  const handleCandidatureTabChange = (event, newValue) => {
    setCandidatureTabValue(newValue)
  }
  const getInterviewerNote = () => {
    return {__html: _.split( interviewerNote, '-*{}*-')[0]};
  }

  const getInterviewerNoteAuthor = () => {
    if(_.isEmpty(interviewerNote))
    {
      return '';
    }
    //Pasamos la traducción
    return i18n.interviewerNotes.updatedAt
      .replace('{author}',_.split(interviewerNote, '-*{}*-')[1])
      .replace('{date}', _.split(interviewerNote, '-*{}*-')[3])
      .replace('{hour}', _.split(interviewerNote, '-*{}*-')[2]);

    //i18n.modalAnnouncement.confirmation[!isEdit ? 'description_create' : 'description_update'].replaceAll('{num}'
  }

  const handleDownload = () => {
    const newDocuments = selectedDocuments.map(item => {
      return fetch(item.file, {cache: 'no-cache'})
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          return Promise.resolve({
            data: Buffer.from(arrayBuffer, 'binary').toString('base64'),
            name: item.name,
            file: item.file
          })
        });
    })

    var zip = new JSZip()
    Promise.all(newDocuments).then(response => {
      response.map(item => zip.file(item.name, item.data, {base64: true}))
      zip.generateAsync({type: 'blob'}).then(function (content) {
        downloadBlob(content, 'files_' + moment().format('YYYYMMDD_Hms') + '.zip')
      })
    })
  }

  const handleOfferRowClick = (event, rowData) => {
    event.preventDefault();
    if (rowData && rowData.offerId) {
      history.push(routes.offerCandidatures.replace(':slug', rowData.offerId))
    }
  }

  const renderTabs = ( profileData ) => {

    if (!profileData || isFetching) {
      return <SpinnerIcon/>
    } else {
      return <>
        <Grid container xs={12} spacing={ 2 } style={{marginTop: "50px", marginBottom:"0px"}}>

          <Grid item xs={ 12 }>

            <Box sx={{ m:-2 }}>

              {/* Tabs */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={"no-print"} >
                <Tabs value={ tabValue } onChange={ handleTabChange } aria-label="basic tabs example" >
                  <Tab  label={i18n.interviewerNotes.titleTab} className={ `documentsInfoTitle tab-item ${ printNotes ? 'print' : 'no-print' }` } {...a11yProps(0)} />
                  <Tab label={i18n.observationsTable.title} className={"documentsInfoTitle tab-item"} {...a11yProps(1)} />
                  {FeatureGuard.canAccessToPushOffers() && <Tab label={i18n.pushOfferTable.title} className={"documentsInfoTitle tab-item"} {...a11yProps(2)} />}
                </Tabs>
              </Box>

              {/*Notas entrevista*/}
              <TabPanel value={ tabValue } index={0} >
                <Grid id="interviewer_notes_container" spacing={10} item style={{padding: "30px"}}
                      className={printNotes ? 'print' : 'no-print'}>
                  <p className={`interview-otes-title-print ${ printNotes ? 'print' : 'no-print' }` }>{ i18n.interviewerNotes.titleTab }</p>
                  <p className={"interviewerNote"} dangerouslySetInnerHTML={getInterviewerNote()}></p>
                  <Grid className={"no-print"}>
                    <DraggableModal user={user} id={candidatureId} titleTab={i18n.interviewerNotes.titleTab}
                                    interviewerNote={interviewerNote} setInterviewerNote={setInterviewerNote}/>
                    <FormControlLabel control={<Checkbox checked={ printNotes } style={{ marginLeft: "15px" }} onClick={ () => setPrintNotes( !printNotes ) }/>} label={ i18n.interviewerNotes.showOnPrint } />
                    <span className={"interviewerNote_metadata pull-right"}>{getInterviewerNoteAuthor()}</span>
                  </Grid>
                </Grid>
              </TabPanel>

              {/*Observaciones*/}
              <TabPanel value={ tabValue } index={1} >
                <Grid style={{padding: "0px"}} spacing={2} container  className={!observations || observations.length === 0 ? 'no-print' : 'print'}>
                  <Grid id="observations-wrapper" item xs={12} style={{marginTop:"-10px"}} >
                    {!observations || (isFetchingObservations && <SpinnerIcon styledType={'overlay-spinner'}/>)}
                    <ObservationsTable id='boxObsevationsContainer'
                                       showObsInput={showObsInput}
                                       handleAddObservation={handleAddObservation}
                                       observations={observations}
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              {/*Ofertas Push*/}
              {FeatureGuard.canAccessToPushOffers() && <TabPanel value={ tabValue } index={2} >
                <Grid style={{padding: 0, marginLeft: 3}} spacing={2} container  className={!observations || observations.length === 0 ? 'no-print' : 'print'}>
                  <Grid id="observations-wrapper"  className="offer_table_container" item xs={12} style={{marginTop:"8px"}} >
                    {!pushOffers || (isFetchingPushOffers && <SpinnerIcon styledType={'overlay-spinner'}/>)}
                    <MaterialTable
                      data={pushOffersData}
                      onRowClick={handleOfferRowClick}
                      columns={pushOfferTableColumns}
                      options={tableOptions}
                      localization={i18n.tableLocalization}
                    />
                  </Grid>
                </Grid>
              </TabPanel>}

            </Box>
          </Grid>
        </Grid>
      </>
    }
  }

  const renderProfile = (candidatureId, profileData, isFetchingObservations, observations, showActions, showFav) => {
    const candidature = _.get(profileData, 'candidatura', '')
    const workingDay = _.get(profileData, 'jornada', '')
    const userName = `${_.get(profileData, 'nombre', '')} ${_.get(profileData, 'apellidos', '')}`
    const responsibleCitation = _.get(profileData, 'citation.user.name', '')
    const statusId = _.get(profileData, 'state.id', -1)
    const currentStatus = i18n.status[statusId]
    const currentStatusColor = profileData.state.color;
    const photo = _.get(profileData, 'foto', null)
    const profileValue = Math.round(_.get(profileData, 'scoring_profile', ''))
    const matchValue = Math.round(_.get(profileData, 'matching_total', ''))
    const apto = _.get(profileData, 'apto', undefined)
    const skill1 = _.get(profileData, 'skill_score1', '')
    const skill2 = _.get(profileData, 'skill_score2', '')
    const skill3 = _.get(profileData, 'skill_score3', '')
    const skill4 = _.get(profileData, 'skill_score4', '')
    const skillsValue = Math.round(_.get(profileData, 'scoring_skills', ''))
    const skills = [skill1, skill2, skill3, skill4]

    const hasSkills = () => {
      return !(skill1.type && skill1.type.name === 'Vacio'
        && skill2.type && skill2.type.name === 'Vacio'
        && skill3.type && skill3.type.name === 'Vacio'
        && skill4.type && skill4.type.name === 'Vacio');
    }

    let origin = _.get(profileData, 'fuente_recrutamiento', '')
    let formation = _.get(profileData, 'formacion', '')
    let experience = _.get(profileData, 'anos_experiencia', '')
    let email = _.get(profileData, 'email', '')
    let phone = _.get(profileData, 'telefono', '')
    let tags = _.get(profileData, 'tags', 'os_experiencia', '')
    let lastJob = _.get(profileData, 'ultimo_puesto', '')
    let address = _.get(profileData, 'direccion', '')
    let location = _.get(profileData, 'cp', '')
    let zone = _.get(profileData, 'zona_deseada', '')
    let variableFields = _.get(profileData, 'variable_fields', '')

    let availability = _.get(profileData, 'disponibilidad', '')
    availability = moment(availability).isValid() ? moment(availability).format('DD/MM/YYYY') : availability
    let citationDate = _.get(profileData, 'citation.citation_date', '')
    citationDate = citationDate ? moment(citationDate).format('DD/MM/YYYY') : ''
    let hiringDate = _.get(profileData, 'hiring_date', '')
    hiringDate = hiringDate ? moment(hiringDate).format('DD/MM/YYYY') : ''
    let dateOfBirth = _.get(profileData, 'fecha_nacimiento', '')
    const dateOfBirthFormat = moment(dateOfBirth, _.includes(dateOfBirth, '-') ? 'YYYY-MM-DD' : 'DD/MM/YYYY')
    const formatYears = dateOfBirth ? `${moment().diff(dateOfBirthFormat.format('YYYY-MM-DD'), 'years')} ${i18n.profileInfo.years}` : ''
    dateOfBirth = dateOfBirth ? `${formatYears} - ${dateOfBirthFormat.format('DD/MM/YYYY')}` : ''
    const yearsOld = _.get(profileData, 'edad') ? `${_.get(profileData, 'edad')} ${i18n.profileInfo.years} ` : ''
    const years = dateOfBirth ? dateOfBirth : yearsOld
    let createdAt = _.get(profileData, 'created_at', '');
    createdAt = moment(createdAt).isValid() ? moment(createdAt).format('DD/MM/YYYY') : createdAt;
    let updatedAt = _.get(profileData, 'updated_at', '');
    updatedAt = moment(updatedAt).isValid() ? moment(updatedAt).format('DD/MM/YYYY') : updatedAt;
    let pullSentAt = _.get(profileData, 'pull_sent_at', '');
    pullSentAt = moment(pullSentAt).isValid() ? moment(pullSentAt).format('DD/MM/YYYY') : pullSentAt;
    let isPullAccepted = _.get(profileData, 'is_pull_accepted', null);

    const dni = _.get(profileData, 'dni', '')
    setDniValue(dni);

    const files = []
    const cvFile = _.get(profileData, 'cv', '')
    const videoAnalysis = _.get(profileData, 'video_analysis', '');
    const videoUrl = _.get(profileData, 'video', '')
    const attached1 = _.get(profileData, 'adjunto1', '')
    const attached2 = _.get(profileData, 'adjunto2', '')
    const attached3 = _.get(profileData, 'adjunto3', '')
    const attached4 = _.get(profileData, 'adjunto4', '')
    const attached5 = _.get(profileData, 'adjunto5', '')
    const attached6 = _.get(profileData, 'adjunto6', '')
    const attached7 = _.get(profileData, 'adjunto7', '')
    const attached8 = _.get(profileData, 'adjunto8', '')
    const attached9 = _.get(profileData, 'adjunto9', '')
    const attached10 = _.get(profileData, 'adjunto10', '')

    cvFile && files.push({label: i18n.profileInfo.cv, url: cvFile})
    videoUrl && files.push({label: i18n.profileInfo.video, url: videoUrl})
    attached1 && files.push({label: `${i18n.profileInfo.attached} 1`, url: attached1})
    attached2 && files.push({label: `${i18n.profileInfo.attached} 2`, url: attached2})
    attached3 && files.push({label: `${i18n.profileInfo.attached} 3`, url: attached3})
    attached4 && files.push({label: `${i18n.profileInfo.attached} 4`, url: attached4})
    attached5 && files.push({label: `${i18n.profileInfo.attached} 5`, url: attached5})
    attached6 && files.push({label: `${i18n.profileInfo.attached} 6`, url: attached6})
    attached7 && files.push({label: `${i18n.profileInfo.attached} 7`, url: attached7})
    attached8 && files.push({label: `${i18n.profileInfo.attached} 8`, url: attached8})
    attached9 && files.push({label: `${i18n.profileInfo.attached} 9`, url: attached9})
    attached10 && files.push({label: `${i18n.profileInfo.attached} 10`, url: attached10})

    const createCandidatureTabs = () => {
      return <Tabs value={ candidatureTabValue } onChange={ handleCandidatureTabChange }
                 className="candidature_tabs_container" aria-label="basic tabs example" >
        {candidatureTabsData.map((item) => {
          return <Tab tabIndex={item.candidatura.id} key={item.id}
                      onClick={() => history.push(window.location.pathname.replace(candidatureId, item.id))}
                      label={item.candidatura.name}
                      className={ `candidatureTabs tab-item ${item.candidatura.name === candidature ? 'candidature_tab_selected': 'candidature_tab_not_selected'}` }
                      {...a11yProps(item.id)}/>
        })}
      </Tabs>
    }

    return (
      <ProfileInfoStyled id='profilePrint'>
        {showActions && (
          <div className="no-print">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <HeaderProfileButtons
                  onGoBack={onGoBack}
                  userName={userName}
                  userId={profileData.id}
                  totalRQuery={
                    getCurrentSectionKey() === SECTION_KEYS.PLANNING
                    && totalPlanning && totalPlanning > 0
                      ? totalPlanning
                      : (
                        module === MODULES.ID_TALENT_ACQUISITION
                          ? (totalProcesses && totalProcesses > 0 ? totalProcesses : 0)
                          : (isTotalRQueryFetched && totalRQuery ? totalRQuery.total : 0)
                      )
                  }
                  onChangeProfile={reloadById}
                />
              </Grid>
            </Grid>
          </div>
        )}

        <Grid container>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={"tabpanel_box no-print"} >
            {candidatureTabsData && createCandidatureTabs()}
          </Box>
        </Grid>

        <Grid id='firstSectionStyle' container spacing={4}>
          <Grid className="profileActionButtonsWrapper" justifyContent='space-between' container item lg={12}>
            <Grid id="ProfileActionsButtons" container item lg={12}>
              <ProfileActionsButtons currentStatusColor={currentStatusColor} files={files} currentStatus={currentStatus}
                                     userName={userName} onGoBack={onGoBack}/>
            </Grid>
            <Divider className='divider-horizontal' variant="fullWidth"/>
          </Grid>


          <Grid className={"profileStatsInfo-print"} direction="row" container item lg={12}>
            <Grid spacing={4} item lg={6}>
              {/* TODO: maybe verify tagsType not procced */}
              {tagsTypes &&
                <ProfileStats
                  showFav={showFav}
                  isFav={isFavorite}
                  handleOnClickFav={handleOnClickFav}
                  avatarImage={photo}
                  job={candidature}
                  info2={years}
                  titleInfo3={i18n.profileInfo.availableFrom}
                  titleInfo4={''}
                  info4={dni}
                  mail={email}
                  tel={phone}
                  tags={tags}
                  tagsTypes={tagsTypes}
                  userName={userName}
                  candidatureId={candidatureId}
                  changeTags={changeTags}
                  fetchObservations={fetchObservations}
                  fetchUser={fetchUser}
                  createdAt={createdAt}
                  updatedAt={updatedAt}
                  pullSentAt={pullSentAt}
                  isPullAccepted={isPullAccepted}
                />}
            </Grid>
            <Grid className="otherInfoContainer" spacing={4} item lg={6}>
              <BoxOtherInfoHeader
                titleInfo1={i18n.profileInfo.city}
                titleInfo2={i18n.profileInfo.desiredLocation}
                titleInfo3={i18n.profileInfo.desiredZone}
                value1={address}
                value2={location}
                value3={zone}
                actualStatus={currentStatus}
                origin={origin}
                workingDay={workingDay}
                availability={availability}
              />

            </Grid>
          </Grid>

          <Grid id='analiticSection' direction="row" container item lg={12} spacing={4}>
            <Grid id="boxInfoHeaderGridContainer" item lg={hasSkills() ? 6 : 12}>
              <BoxInfoHeader
                totalMatch={matchValue}
                valid={apto}
                profileValue={profileValue}
                title={i18n.profileInfo.profile}
                titleInfo1={i18n.profileInfo.formation}
                titleInfo2={i18n.profileInfo.exp}
                titleInfo3={i18n.profileInfo.lastJob}
                value1={formation}
                value2={experience}
                value3={lastJob}
                noSkillsRender={!hasSkills()}
              />
            </Grid>
            {hasSkills() && <Grid id='boxProfileCiteGridContainer' item lg={6}>
              <BoxProfileCite
                date={citationDate}
                hiringDate={hiringDate}
                responsible={responsibleCitation}
                skillValue={skillsValue}
                skills={skills}
              />
            </Grid>}
          </Grid>
        </Grid>

        <Grid spacing={4} container>
          {profileData.integrations_pda_emergia &&
            <Grid container className="pagebreakavoid">
              <Grid item xs={12}>
                <BoxPDAEmergiaInfo pdaFields={profileData.integrations_pda_emergia}/>
              </Grid>
            </Grid>
          }

          {profileData.integrations_pda &&
            <Grid container className="pagebreakavoid">
              <Grid item xs={12}>
                <BoxPDAMVMInfo pdaFields={profileData.integrations_pda}/>
              </Grid>
            </Grid>
          }
          <Grid id='boxOtherInfoContainer' container className="pagebreakavoid">
            <Grid item xs={12}>
              <BoxOtherInfo variableFields={variableFields} profile={profileData}/>
            </Grid>
          </Grid>

          { client && client.name && client.name.includes("Demo") &&
            <Grid id="videoSentiments" container className="pagebreakavoid">
              <Grid item xs={12}>
                <BoxVideoSentiments videoUrl={videoUrl} videoAnalysis={ videoAnalysis }/>
              </Grid>
            </Grid>
          }

        </Grid>

        {showCvPreview && _get(profileData, 'cv') !== null &&
          <Grid spacing={4} id='documentsInfoContainer' container className="pagebreakavoid no-print">
            <p className="documentsInfoTitle">{i18n.process.previewCV}</p>
            <Grid className="documentsInfoBody" item xs={12}>
              <DocumentsListViewer options={formatCV(_get(profileData, 'cv'))} embedded={true}/>
            </Grid>
          </Grid>}

        {showAttachmentsPreview &&
          <Grid spacing={4} id='documentsInfoContainer' container className="pagebreakavoid no-print">
            <p className="documentsInfoTitle">{i18n.process.documentation}</p>
            <Grid className="documentsInfoBody" item xs={12}>
              <S.Toolbar className="no-print">
                <S.ActionButton
                  disabled={!selectedDocuments || selectedDocuments.length <= 0}
                  handleOnClick={handleDownload}
                  title={i18n.process.downloadDocs}
                  styledType="invert"
                />
              </S.Toolbar>
              <DocumentsList
                check
                value={selectedDocuments}
                options={formatAttachments(_get(profileData, 'attachments'))}
                onChange={value => setSelectedDocuments(value)}
              />
            </Grid>
          </Grid>}

        {renderTabs( profileData )}

      </ProfileInfoStyled>
    )
  }

  const LogicOfRender = () => {
    //IF MULTIPLE USER SLUG (PRINT)
    if (candidatureId && candidatureId.split('-').length > 1) {
      if (!profileMultipleData || isFetchingUserList) {
        return <SpinnerIcon/>
      }
      const profileMultipleDataFiltered = _.filter(profileMultipleData, data => _.has(data, 'id'))
      return _.map(profileMultipleDataFiltered, (profile, key) => (
        <>
          {key > 0 &&
            <div className="pagebreak"></div>
          }
          <div
            key={key}>{renderProfile(profile.id, profile, isFetchingObservations, profile.observations, showActions, showFav, showObsInput)}</div>
        </>
      ))
    } else {
      if (!profileData || isFetching) {
        return <SpinnerIcon/>
      }
      return renderProfile(candidatureId, profileData, isFetchingObservations, observations, showActions, showFav, showObsInput)
    }
  }

  return (
    <LogicOfRender/>
  )
}

ProfileInfo.defaultProps = {
  showActions: true,
  showFav: true,
  showObsInput: true
}

ProfileInfo.propTypes = {
  i18n: PropTypes.object.isRequired,
  favorites: PropTypes.array,
  fetchUser: PropTypes.func,
  fetchUserList: PropTypes.func,
  profileData: PropTypes.object,
  profileMultipleData: PropTypes.array,
  match: PropTypes.object,
  isFetching: PropTypes.bool,
  isFetchingObservations: PropTypes.bool,
  isFetchingPushOffers: PropTypes.bool,
  isFetchingUserList: PropTypes.bool,
  fetchObservations: PropTypes.func,
  observations: PropTypes.array,
  pushOffers: PropTypes.array,
  postObservations: PropTypes.func,
  deleteFavorites: PropTypes.func,
  postFavorites: PropTypes.func,
  postReads: PropTypes.func,
  showActions: PropTypes.bool,
  showFav: PropTypes.bool,
  showObsInput: PropTypes.bool,
  tagsTypes: PropTypes.array,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onGoBack: PropTypes.func,
}

export default ProfileInfo
