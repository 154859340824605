import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { AppBarInboxQuery, ProcessTableQuery } from '../../organism'
import { SECTION_KEYS, getCurrentSectionKey } from '../../../config/constants'
import _ from "lodash";
import {searchCurrentOfferHasCandidature} from "../../../utils/offer";
import {getPushOfferById} from "../../../api";
import {useParams} from "react-router";
import { useAppContext } from '../../../context/appContext';

const OfferCandidates = ({ classes, i18n, data, postReads }) => {
  const { slug } = useParams();
  const context = useAppContext();
  const filterGroupSelected = context.filterGroup[getCurrentSectionKey()];

  const [offerData, setOfferData] = useState(null);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [forceTotalOffers, setForceTotalOffers] = useState(0);
  const [isOpenModalSuggestChangeStatus, setIsOpenModalSuggestChangeStatus] = useState(false);

  const setSectionFiltersForAppContext = (section, filters) => {
    let filtersOfContext = context.filters;
    filtersOfContext[section] = filters;
    
    context.setFilters(filtersOfContext);
  };

  const getOfferFilters = (offer) => {
    let state = null;
    if (offer.interested > 0) state = 'interested';
    if (!state && offer.not_interested > 0) state = 'not_interested';
    if (!state && offer.unanswered > 0) state = 'unanswered';

    if (filterGroupSelected && Object.keys(filterGroupSelected).length !== 0 && filterGroupSelected !== state && localStorage.getItem('currentOfferId') === slug) {
      state = filterGroupSelected;
      context.setFilterGroupSelected(getCurrentSectionKey(), slug, state);
    } else {
      context.setFilterGroupSelected(getCurrentSectionKey(), slug, state);
    }
    localStorage.setItem('currentOfferId', slug);
    setSectionFiltersForAppContext(SECTION_KEYS.PUSH_OFFER_CANDIDATES, { 'offerHasCandidatures.offer': slug, 'offerHasCandidatures.state': state })
  }

  useEffect(() => {
    let offerHasCandidature = searchCurrentOfferHasCandidature(_.get(data.data, '[0]', []), slug);
    if (offerHasCandidature) {
      let offer = _.get(offerHasCandidature, 'offer');
      setOfferData(offer);
      getOfferFilters(offer);
    } else {
      getPushOfferById(slug).then((res) => {
        setOfferData(res);
        getOfferFilters(res);
      });
    }
  }, [slug]);

  useEffect(() => {    
    setSectionFiltersForAppContext(SECTION_KEYS.PUSH_OFFER_CANDIDATES, { 'offerHasCandidatures.offer': slug, 'offerHasCandidatures.state': filterGroupSelected })
  }, [context.filterGroup]);

  useEffect(() => {
    postReads(slug)
  }, [slug, postReads])

  return (
    <div className={classes.root}>
      <AppBarInboxQuery
        showActions
        filterName={i18n.offerCandidates.title}
        filters={context}
        rowsSelected={rowsSelected}
        section={"inboxPeople"}
        forceTotal={forceTotalOffers}
      />
      <ProcessTableQuery
        title={i18n.offerCandidates.title}
        filters={context}
        updateParentRowsSelected={setRowsSelected}
        showChangeStatus
        suggestChangeStatusModal={{ isOpenModalSuggestChangeStatus, setIsOpenModalSuggestChangeStatus}}
        showFilterGroup
        offer={offerData}
        appBarForceTotal={{forceTotalOffers, setForceTotalOffers}}
      />
    </div>
  )
}

OfferCandidates.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default OfferCandidates