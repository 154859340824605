import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import {ModalStyled} from './styled'
import Modal from '@material-ui/core/Modal'
import {CustomAlert, ModalHeaderProcess, RegularButton} from '../../atoms'
import {patchEvaluationProfile} from '../../../api/index';
import {useMutation} from "@tanstack/react-query";
import i18n from '../../../assets/i18n'
import {manageQueriesWithPrefix} from "../../../api/mutations/mutationCallbacks";
import {QUERY_ACTIONS, QUERY_KEYS} from "../../../config/constants";

const customStyles = {
  overflow: 'scroll'
}

const ModalDisableEvaluationProfile = ({ open, handleClose, evaluationData, showAlert, alert, setAlert }) => {
  const [evaluationDataState, setEvaluationDataState] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalConfirmButtonTitle, setModalConfirmButtonTitle] = useState("");

  useEffect(() => {
    if (open) {
      setEvaluationDataState(evaluationData);

      if (evaluationData) {
        setModalTitle(i18n.ia_evaluation[evaluationData.is_active ? 'confirmDeactivation' : 'confirmActivation'].replace('{name}', `${evaluationData.name || ''}`));
        if (evaluationData.id) {
          setModalConfirmButtonTitle(i18n.ia_evaluation[evaluationData.is_active ? 'buttonConfirmDisable' : 'buttonConfirmEnable']);
        }
      }
    }
  }, [open, evaluationData]);

  const changeEvaluationProfile = async ({id, data}) => {
    try {
      return await patchEvaluationProfile(id, data).then(() => {
        setAlert({
          message: evaluationDataState.is_active
            ? i18n.ia_evaluation.evaluationDisabledSuccessfully
            : i18n.ia_evaluation.evaluationEnabledSuccessfully,
          severity: 'success',
        });

        manageQueriesWithPrefix([QUERY_KEYS.IA_EVALUATION], QUERY_ACTIONS.REFETCH);
        setTimeout(() => {
          handleClose();
        }, 3000);
      }).catch(error => {
        setAlert({
          message: evaluationDataState.is_active
            ? i18n.ia_evaluation.evaluationDisabledError
            : i18n.ia_evaluation.evaluationEnabledError,
          severity: 'error',
        });
        console.error("Error updating data:", error);
      });
    } catch (error) {
      setAlert({
        message: evaluationDataState.is_active
          ? i18n.ia_evaluation.evaluationDisabledError
          : i18n.ia_evaluation.evaluationEnabledError,
        severity: 'error',
      });
      console.error("Error updating data:", error);
    }
  };

  const mutationChangeEvaluationProfile = useMutation(changeEvaluationProfile);

  const handleClickDisable = async (e) => {
    e.preventDefault();

    try {
      if (evaluationDataState && evaluationDataState.id) {
        await mutationChangeEvaluationProfile.mutateAsync(
          {
            id: evaluationData.id,
            data: {is_active: !evaluationDataState.is_active},
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => {
    setEvaluationDataState(null);
    handleClose();
  };

  return (
    <Modal
      style={customStyles}
      className="modal-wrapper"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleModalClose}
    >
      <ModalStyled>
        <ModalHeaderProcess closeClick={handleModalClose} hasCloseIcon={true} />
        <div className="modal-body">
          <form>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={12}>
                <h4>{modalTitle}</h4>
              </Grid>
            </Grid>
            {showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
            <div className="button-wrapper">
              <RegularButton
                className="button-custom"
                title={modalConfirmButtonTitle}
                onClick={handleClickDisable}
              /></div>
          </form>
        </div>
      </ModalStyled>
    </Modal>
  )
}

export default ModalDisableEvaluationProfile