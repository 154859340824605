import styled from 'styled-components'
import colors from '../../../assets/colors'

export const ModalStyled = styled.div`
  && {
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    width: 600px;
    outline: none;

    .modal-body {
      background-color: ${colors.white};
      padding: 30px 50px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      outline: none;
      .grid-decoration {
        position: relative;
        ::before {
          content: '-';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -4px;
        }
      }

      .file-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 0;
      }

      .file-label {
        margin-right: 15px;
      }

      .button-wrapper {
        text-align: center;

        button {
          margin-top: 25px;
        }
      }
    }
  }
`
