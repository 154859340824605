import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import CheckButtonStyled from './styled'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const CheckButton = ({ title, name, onChange, checked, ...rest }) => (
  <CheckButtonStyled {...rest}>
    <FormControlLabel control={<Checkbox name={name} onChange={onChange} checked={checked} />} label={title} />
  </CheckButtonStyled>
)

CheckButton.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

const enharce = compose()

export default enharce(CheckButton)
