import styled from 'styled-components'

const UserManualStyled = styled.div`
  .title-container {
    padding: 30px 60px;
  }

  .iframe-container {
    width: 97vw;
    height: 93%;
    border: none;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    position: fixed;  

  @media (max-width: 1440px) {
    width: 95vw;
  }

  @media (max-width: 1024px) {
    width: 90vw;
  }
 
  @media (max-width: 475px) {
    width: 82vw;
    height: 90%;
  }
}
`
export default UserManualStyled
