import React, { useEffect, useState } from 'react'
import PhasesStatesStyled from './styled'
import Grid from '@material-ui/core/Grid'
import Phases from '../../organism/phases/view'
import Status from '../../organism/status/view'
import BoxSelectionFunnel from './../../molecules/box-selection-funnel/index'

const PhasesStates = ({ classes, i18n }) => {
  const [allPhasesData, setAllPhasesData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (allPhasesData) {
      setIsLoading(false)
    }
  }, [allPhasesData])

  return (
    <>
      <div className={classes.root}>
        <PhasesStatesStyled>
          <div className="containerHeader">
            <Grid container>
              <Grid item className="sectionFirstText" xs={12} sm={12}>
                <h2>{i18n.drawer.phases_states}</h2>
                <h5>{i18n.phases_states.descriptionPhasesStates}</h5>
              </Grid>
              <div className="containerSection">
                <div className="phasesContainer">
                  <Phases i18n={i18n} setAllPhasesData={setAllPhasesData} />
                </div>
                <div className="statesContainer">{!isLoading && <Status i18n={i18n} allPhasesData={allPhasesData} />}</div>
              </div>
              <Grid item lg={12} style={{ width: 'inherit' }} justifyContent='center'>
                {!isLoading && <BoxSelectionFunnel i18n={i18n} allPhasesData={allPhasesData} />}
              </Grid>
            </Grid>
          </div>
        </PhasesStatesStyled>
      </div>
    </>
  )
}

export default PhasesStates
