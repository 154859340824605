import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import compose from 'recompose/compose'
import DateInputStyled from './styled'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import esLocale from 'date-fns/locale/es'
import moment from 'moment'
import { withI18n } from '../../hocs'

const DateInput = ({ fontSize, name, value, onChange, format, range, i18n, placeholder}) => {
  const [selectedDate, setSelectedDate] = React.useState(range?{from: null, to: null}:null)

  function formatDate(date) {
    return format
    ? moment(date)
        .startOf('day').format(format)
    : moment(date)
        .startOf('day')
        .toDate()
  }

  function handleDateChange(date, inputName) {
    if(range){
      const newSelectedDate = {...selectedDate}
      newSelectedDate[inputName] = date
      setSelectedDate(newSelectedDate)
      const formatedDate = {}
      if(newSelectedDate.from) formatedDate.from = formatDate(newSelectedDate.from)
      if(newSelectedDate.to) formatedDate.to = formatDate(newSelectedDate.to)
      onChange(formatedDate, name)
    }else{
      setSelectedDate(date)
      date = formatDate(date)
      onChange(date, name)
    }
  }

  if(range){
    const dateFrom = value.from || selectedDate.from
    const dateTo = value.to || selectedDate.to
    const valueDateFrom = (dateFrom && format)?moment(dateFrom, format):dateFrom
    const valueDateTo = (dateTo && format)?moment(dateTo, format):dateTo
    return (
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6}>
          <DateInputStyled fontSize={fontSize}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                autoOk
                clearable
                disableToolbar
                variant="inline"
                placeholder={i18n.modalFilter.fromDate}
                format={"dd-MM-yyyy"}
                name={'from'}
                value={valueDateFrom}
                onChange={(value) =>handleDateChange(value, 'from')}
                shouldDisableDate={(date) => {
                  return dateTo && moment(date).isAfter(valueDateTo)
                }}
              />
            </MuiPickersUtilsProvider>
          </DateInputStyled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateInputStyled fontSize={fontSize}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                autoOk
                clearable
                disableToolbar
                variant="inline"
                placeholder={i18n.modalFilter.toDate}
                format={"dd-MM-yyyy"}
                name={'to'}
                value={valueDateTo}
                onChange={(value) =>handleDateChange(value, 'to')}
                shouldDisableDate={(date) => {
                  return dateFrom && moment(date).isBefore(valueDateFrom)
                }}
              />
            </MuiPickersUtilsProvider>
          </DateInputStyled>
        </Grid>
      </Grid>
    )
  }

  return (
    <DateInputStyled fontSize={fontSize}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          placeholder={placeholder}
          format={"dd-MM-yyyy"}
          name={name}
          value={value || selectedDate}
          onChange={(value) =>handleDateChange(value, name)}
        />
      </MuiPickersUtilsProvider>
    </DateInputStyled>
  )
}

DateInput.defaultProps = {
  name: 'date',
  fontSize: 'normal'
}

DateInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
  format: PropTypes.string,
  fontSize: PropTypes.string,
  module: PropTypes.string,
  i18n: PropTypes.object,
  range: PropTypes.bool,
}

const enharce = compose(withI18n)

export default enharce(DateInput)