import styled from 'styled-components'
import colors from '../../../assets/colors'

const DashboardHomeStyled = styled.div`
  padding: 30px 60px;
  
  .MuiButtonBase-root.MuiButton-root {
    height: initial;
  }
  
  .RGL-widgetDeleteButton {
    color: gray;
    &:hover {
      color: orangered;
    }
  }
  
  .title {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .filter-n-edit {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    
    .container-filters {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      
      &.is-fetching div{
        background-color: #f2f2f2;
      }
      
      & > div {
        font-size: 1.3em;
      }
    }
  }

  //Tabs
  .tabpanel-tabs div[aria-label='client dashboard tabs'] {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }
  .tab-item {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid rgba(148,163,178,0.25);
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: ${colors.grey4};
    background-color: rgba(148,163,178,0.18);
    margin: 3px 3px 0 0;
    text-transform: none;
    padding: 0 15px;
  }
  .tab-item.Mui-selected{
    background-color: #fff;
    border-bottom: solid 3px ${colors.primary};
  }
  .tab-item:not(.Mui-selected) {
    font-weight: 400;
  }
  .MuiTabs-indicator {
    background-color: transparent;
  }
  
  // Tabs content
  .panel-content-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 12px;
      letter-spacing: 0.03em;
    }
  }
  
  // React grid layout
  .layout {
    width: 100%;
    height: 100%;
    .react-grid-item {
      //padding: 10px 0!important;
    } 
    .react-grid-placeholder {
      background-color: ${props => props.theme.primary};
      border-radius: 8px;
    }
  }
  
  .no-dashboards-created {
    background: white;
    max-width: 640px;
    margin: 8rem auto 0;
    padding: 2rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 14px;
    box-shadow: 0 2px 10px -6px;
    
    svg {
      color: #91989d;
    }
    
    p {
      margin-top: 1rem;
    }
  }

  .alertCustom {
    position: fixed;
    width: fit-content;
    right: 36px;
    bottom: 36px;
  }
  
  .MuiSnackbar-root {
    background: #2196f3;
    border-radius: 4px;
    &.success {
      background: #4caf50;
    }
    &.warning {
      background: #ff9800;
    }
    &.error {
      background: #f44336;
    }
    
    .MuiSnackbarContent-root {
      font-size: 1.2em;
      background: inherit;
      cursor: default;
    }
  }
`
export default DashboardHomeStyled
