import React from 'react'
import PropTypes from 'prop-types'
import SelectTagsStyled from './styled'
import { PlusIcon } from '../../atoms'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import i18n from '../../../assets/i18n'

const SelectTags = ({ onChange, options, labelSelectorStatus }) => {
  const tagSelectorId = 'tag-selector'
  const delayAutoShowOptions = async (delay = 500) => {
    await new Promise(resolve => setTimeout(() => {
      if (!document.getElementById(tagSelectorId)) {
        return
      }
      document.getElementById(tagSelectorId).focus()
      return resolve()
    }, delay))
  }

  const renderAutocompleteOptions = () => {
    return (
      <Autocomplete
        id={tagSelectorId}
        size="small"
        options={options}
        openOnFocus={true}
        getOptionLabel={(option) => option.name}
        style={{ width: 200 }}
        renderInput={(params) => {
          delayAutoShowOptions()
          return (<TextField {...params} label={i18n.headerProfileButtons.changeTags} variant="outlined"/>)
        }}
        onBlur={() => {
          labelSelectorStatus && labelSelectorStatus.setIsLabelSelectorOpened(false)
        }}
        blurOnSelect={true}
        onKeyDown={(e) => {e.stopPropagation()}}
        onChange={(e, newValue) => {
          e.preventDefault()
          e.stopPropagation()
          onChange(newValue)
          labelSelectorStatus && labelSelectorStatus.setIsLabelSelectorOpened(false)
        }}
      />
    )
  }

  return (
    <>
      <SelectTagsStyled
        open={labelSelectorStatus ? labelSelectorStatus.isLabelSelectorOpened : null}
        IconComponent={() => (<PlusIcon/>)}
        onClick={() => labelSelectorStatus && labelSelectorStatus.setIsLabelSelectorOpened(true)}
      >
        {renderAutocompleteOptions()}
      </SelectTagsStyled>
    </>
  )
}

SelectTags.propTypes = {
  onClick: PropTypes.func,
  options: PropTypes.object,
}

export default SelectTags
