import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Icon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" >
    <path d="M6 7C6 6.44772 5.55228 6 5 6C4.44772 6 4 6.44772 4 7H6ZM20 7C20 6.44772 19.5523 6 19 6C18.4477 6 18 6.44772 18 7H20ZM18 7V16H20V7H18ZM14 20H10V22H14V20ZM6 16V7H4V16H6ZM10 20C7.79086 20 6 18.2091 6 16H4C4 19.3137 6.68629 22 10 22V20ZM18 16C18 18.2091 16.2091 20 14 20V22C17.3137 22 20 19.3137 20 16H18Z" fill="#F4304A" />
    <path d="M3 7H21" stroke="#F4304A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16 7H8V5.5C8 4.1 9.1 3 10.5 3H13.5C14.9 3 16 4.1 16 5.5V7Z" stroke="#F4304A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 12V16" stroke="#F4304A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14 12V16" stroke="#F4304A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </SvgIcon>
)

export default Icon
