import React from 'react'
import { withRouter } from 'react-router'
import { MTPersonalDataStyled } from './styled'

const MTPersonalData = withRouter(({ full_name='', nombre='', apellidos='', was_read }) => {
  return (
    <MTPersonalDataStyled>
      <span className={was_read ? 'was_read name' : 'not_read name'}>
        {full_name !== '' ? full_name : `${nombre} ${apellidos}`}
      </span>

    </MTPersonalDataStyled>
  )
})

export default MTPersonalData
