import {MODULES, SECTION_KEYS} from './constants'

export default {
  login: '/login',
  logout: '/logout',
  job_offers: `/${MODULES.ID_TALENT_ACQUISITION}/job-offers`,
  job_offer_applications: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.JOB_OFFERS}/:job_offer_id/${SECTION_KEYS.JOB_OFFER_APPLICATIONS}`,
  profilePerson: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.JOB_OFFERS}/:job_offer_id/${SECTION_KEYS.JOB_OFFER_APPLICATIONS}/:job_offer_application_id/person/:slug`,
  job_offer_create: `/${MODULES.ID_TALENT_ACQUISITION}/job-offer/create`,
  job_offer_edit: `/${MODULES.ID_TALENT_ACQUISITION}/job-offer/:id/edit`,
  inbox: `/${MODULES.ID_TALENT_ACQUISITION}/inbox`,
  favorites: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.FAVORITES}`,
  savedSearches: `/${MODULES.ID_TALENT_ACQUISITION}/saved-searches`,
  savedSearchesItemQuery: `/${MODULES.ID_TALENT_ACQUISITION}/saved-searches/:savedSearchItemId`,
  savedSearchesItem: `/:moduleId/saved-searches/:slug`,
  planning: `/${MODULES.ID_TALENT_ACQUISITION}/planning`,
  profileQuery: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.INBOX}/profile/:slug`,
  profileFavoritesQuery: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.FAVORITES}/profile/:slug`,
  profileSaveSearchQuery: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.SAVED_SEARCHES}/:savedSearchItemId/profile/:slug`,
  profilePlanning: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.PLANNING}/profile/:slug`,
  inbox_query: `/${MODULES.ID_TALENT_ACQUISITION}/inbox-query`,
  profile: `/profile/${MODULES.ID_TALENT_ACQUISITION}/:slug`,
  profiles: `/profiles/${MODULES.ID_TALENT_ACQUISITION}/:slug`,
  modules: `/${MODULES.ID_TALENT_ACQUISITION}/modules`,
  favorites_query: `/${MODULES.ID_TALENT_ACQUISITION}/favorites-query`,
  reports: `/${MODULES.ID_TALENT_ACQUISITION}/reports`,
  pushOffer: `/${MODULES.ID_TALENT_ACQUISITION}/push-offer`,
  pullConfig: `/${MODULES.ID_TALENT_ACQUISITION}/pull-config`,
  offerCandidatures: `/${MODULES.ID_TALENT_ACQUISITION}/offer-candidatures/:slug`,
  myProfile: '/my-profile',
  integrations: '/my-profile/integrations',
  phasesStates: '/my-profile/phases-states',
  userManagement: '/my-profile/user-management',
  tagManagement: '/my-profile/tag-management',
  keywordsRpa: '/my-profile/recruitment-sources-integrations',
  keywordsRpaTalent: `/${MODULES.ID_TALENT_ACQUISITION}/recruitment-sources-integrations`,
  partners: '/my-profile/partners',
  iaEvaluation: '/my-profile/ia-evaluation',
  iaEvaluationCreate: '/my-profile/ia-evaluation/create',
  iaEvaluationEdit: '/my-profile/ia-evaluation/:id/edit',
  landingSmsText: '/landing-sms-text',
  landingEmail: '/landing-email',
  reschedule: '/reschedule',
  notInterested: '/not-interested',
  landingOffer: '/landing-offer',
  landingPull: '/landing-pull',
  landingDocRequest: '/landing-documentation-request',
  userManual: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.USER_MANUAL}`,
  superAdministratorManual: `/my-profile/superadministrator-manual`,
  evaluativesTests: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.EVALUATIVE_TESTS}`,
  createEvaluativeTests: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.EVALUATIVE_TESTS}/test`,
  editEvaluativeTests: `/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.EVALUATIVE_TESTS}/test/:testId`,

  //Template Request Manager
  templateRequestManager: `/${MODULES.ID_TALENT_ACQUISITION}/template-request-manager`,
  validatorTemplateRequest: `/${MODULES.ID_TALENT_ACQUISITION}/validation-template-request`, 
  createProcess: `/${MODULES.ID_TALENT_ACQUISITION}/template-request-manager/create`,
  showProcess: `/${MODULES.ID_TALENT_ACQUISITION}/template-request-manager/:vacancyRequestId`,

  //Dashboard Statistics
  dashboardWizard: `/:module_id/dashboard/wizard`,
  dashboardHome: `/:module_id/dashboard/:id?`,
  dashboardEdit: `/:module_id/dashboard/:id/edit`,
  adminDashboardWizard: '/my-profile/dashboard/wizard',
  adminDashboardEdit: `/my-profile/dashboard/:id/edit`,

  //FEEDBACK
  feedbackInbox: `/${MODULES.ID_FEEDBACK}/inbox`,
  profileFeedback: `/profile/${MODULES.ID_FEEDBACK}/:slug`,
  feedbackSavedSearches: `/${MODULES.ID_FEEDBACK}/saved-searches`,
  feedbackReports: `/${MODULES.ID_FEEDBACK}/reports`,
  feedbackFavorites: `/${MODULES.ID_FEEDBACK}/favorites`,
  feedbackStatistics: `/${MODULES.ID_FEEDBACK}/statistics`,
  //PROCESOS
  processes: `/${MODULES.ID_EMPLOYEE_LINE}/inbox`,
  profileProcesses: `/profile/${MODULES.ID_EMPLOYEE_LINE}/:slug`,
  processesStatistics: `/${MODULES.ID_EMPLOYEE_LINE}/statistics`,
  processesReports: `/${MODULES.ID_EMPLOYEE_LINE}/reports`,
  processesFavorites: `/${MODULES.ID_EMPLOYEE_LINE}/favorites`,
  processesSavedSearches: `/${MODULES.ID_EMPLOYEE_LINE}/saved-searches`,
  processesUserManual: `/${MODULES.ID_EMPLOYEE_LINE}/${SECTION_KEYS.USER_MANUAL}`,
  // savedSearchesItemEmployeeLineQuery: `/${MODULES.ID_EMPLOYEE_LINE}/saved-searches/:savedSearchItemId`,
  //ONBOARDING
  onboarding: `/${MODULES.ID_ON_BOARDING}/inbox`,
  onboardingReports: `/${MODULES.ID_ON_BOARDING}/reports`,
  profileOnboarding: `/profile/${MODULES.ID_ON_BOARDING}/:slug`,
  onboardingFavorites: `/${MODULES.ID_ON_BOARDING}/favorites`,
  onboardingSavedSearches: `/${MODULES.ID_ON_BOARDING}/saved-searches`,
  onboardingStatistics: `/${MODULES.ID_ON_BOARDING}/statistics`,
  onboardingUserManual: `/${MODULES.ID_ON_BOARDING}/${SECTION_KEYS.USER_MANUAL}`,
  //EXIT INTERVIEW
  exitInterviewInbox: `/${MODULES.ID_EXIT_INTERVIEW}/inbox`,
  exitInterviewProfile: `/profile/${MODULES.ID_EXIT_INTERVIEW}/:slug`,
  exitInterviewFavorites: `/${MODULES.ID_EXIT_INTERVIEW}/favorites`,
  exitInterviewStatistics: `/${MODULES.ID_EXIT_INTERVIEW}/statistics`,
}
