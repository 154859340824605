import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import colors from '../../../assets/colors'
import React from "react";

export const ButtonStyled = styled(props => <Button {...props} />)`
    opacity: ${props => props.disabled ? 0.4 : 1};
    cursor: ${ props => props.disabled ? 'not-allowed' : 'pointer'} !important;
    pointer-events: ${ props => props.disabled ? 'none' : 'auto'};
    padding: 9px 12px 9px 10px !important;
    margin-left: 12px !important;
    // background-color: #FFFFFF !important;
    border: 1.5px solid ${colors.grey3} !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    width: 196px;
    height: 36px !important;
    justify-content: space-between !important;

    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: ${colors.grey3} !important;

    span{
        margin-right: 8px;
    }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
    
    svg#svgCircle{
        width: 14px !important;
        height: 14px !important;
        margin-left: 5px;
        margin-right: 2px;
        padding-top: 2px;
    }
`