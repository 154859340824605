import styled from 'styled-components'
import colors from '../../../assets/colors'
import { headerStatStyles, chartWrapperStyles, noDataStyles } from '../dynamic-widget/styled'

export default styled.div`
  ${headerStatStyles}
  
  ${noDataStyles}

  ${chartWrapperStyles}

  .value-percent {
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    color: ${props => props.theme.primary};
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bottom-circle {
    position: absolute;
  }
  
  .label-stat {
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${colors.blackAlpha80};
    padding: 30px 20px 0;
  }
`
