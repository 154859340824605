import styled from 'styled-components'
import colors from '../../../assets/colors'
import { headerStatStyles } from '../dynamic-widget/styled'

const HeatmapStatisticStyled = styled.div`
  && {
    padding: 30px 0;
  }
  
  ${headerStatStyles}

  .heatmap-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
    padding: 0;
    height: auto;
    svg circle {
      stroke-width: 1;
    }
  }
`

export default HeatmapStatisticStyled
