import styled from 'styled-components'
import colors from '../../../assets/colors'

const BoxInfoHeaderStyled = styled.div`

  #boxOtherInfo2, #boxOtherInfo1 {
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(98, 102, 113, 0.06), 0px 2px 2px rgba(98, 102, 113, 0.06);
    border-radius: 6px;
    padding: 20px 30px;
    width: 49%;
  }

  @media print {
    .header-table {
      background: ${colors.white} !important;
      -webkit-print-color-adjust: exact;

      p {
        color: ${colors.white} !important;
      }
    }

    .wrap-table {
      background-color: ${colors.white} !important;
    }

    && {
      padding: 30px 24px !important;
    }
  }

  && {
    border-radius: 8px;
    padding-top: 24px;

    p {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.03em;
      position: relative;
      display: inline-block;
    }

    .header-table {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 15px 0;

      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: ${colors.grey4};
        margin-bottom: 0;

        svg {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          margin-right: 10px;
        }

        path {
          fill: ${props => props.theme.textPrimary};
        }
      }
    }

    .wrap-table {
      padding: 15px 30px;
      background-color: ${colors.white};
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      position: relative;

      ::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2px;
        height: 70%;
        background-color: ${colors.greyAlpha25};
      }
    }

    .row-table {
      border-top: 1px solid ${colors.greyAlpha25};
      padding: 10px 0;

      :first-child {
        border-top: 0;
      }

      p {
        margin-bottom: 0;
      }

      .title {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
      }

      .value {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        /* identical to box height, or 138% */

        text-align: right;
        color: #494D55;

        &.long {
          text-align: left !important;
        }
      }
    }
  }
`

export default BoxInfoHeaderStyled
