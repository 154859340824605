import styled from 'styled-components'
import colors from '../../../assets/colors'

const SkillProfileStyled = styled.div`
  @media print {
    .circle-wrapper {
      span {
        color: ${props => props.theme.primary}!important;
        font-size: 26px !important;
      }
    }
    p {
      font-size: 16px !important;
    }
    .MuiCircularProgress-root {
      width: 90px !important;
      height: 90px !important;
    }
  }
  && {
    display: flex;
    align-items: center;
  }

  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: ${colors.black};
    max-width: 130px;
    margin-left: 15px;
  }

  .circle-wrapper {
    position: relative;
    display: inline-block;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      font-size: 40px;
      line-height: 60px;
      color: ${props => props.theme.primary};
    }

    svg circle {
      stroke-width: 1.5;
    }
  }

  .bottom-circle {
    position: absolute;
    left: 0;
  }
`

export default SkillProfileStyled
