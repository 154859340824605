import React from 'react'
import compose from 'recompose/compose'
import {withI18n} from '../../hocs'
import Grid from '@material-ui/core/Grid'
import {ModalStyled} from './styled'
import {ReactComponent as SaveIcon} from '../../../assets/images/save-search-icon.svg'
import Modal from '@material-ui/core/Modal'
import {InputField, ModalHeader, RegularButton} from '../../atoms'

const ModalSaveSearch = withI18n(({ i18n, open, handleClose, handleSubmitSave, handleOnChangeTitle, errorMsg }) => {
  return (
    <div>
      <Modal className="modal-wrapper" aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
        <ModalStyled>
          <ModalHeader Icon={<SaveIcon />} title={i18n.modalSaveSearch.title} closeClick={handleClose} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                  <InputField name="searchName" handleOnChange={handleOnChangeTitle} placeholder={i18n.modalSaveSearch.enterName} />
                  <p className="error-msg">{errorMsg}</p>
                </Grid>
              </Grid>
              <div className="button-wrapper">
                <span onClick={handleClose}>{i18n.modalSaveSearch.back}</span>
                <RegularButton type="submit" handleOnClick={handleSubmitSave} title={i18n.modalSaveSearch.saveSearch} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </div>
  )
})

const enharce = compose(withI18n)

export default enharce(ModalSaveSearch)
