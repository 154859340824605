import React, { memo, useEffect, useMemo } from 'react'
import HereMapsStatisticStyled from './styled'
import { ReactComponent as UserIcon } from '../../../assets/images/stats.svg'
import i18n from '../../../assets/i18n'
import HereMaps from '../here-maps'

const HereMapsStatistic = ({ title = null, overrideTitle = null, mapHeight = 300, originPosition = null, data = { markers: [] }}) => {
  const hereApiKey = process.env.REACT_APP_HERE_MAPS_API_KEY;

  const markers = data.markers;
  const HereMapsMemo = memo(({ markers = [], mapHeight }) => {
    return (<HereMaps
      apiKey={hereApiKey}
      mapHeight={mapHeight}
      originPosition={originPosition}
      markers={markers}
    />)
  })
  HereMapsMemo.displayName = 'HereMapsMemo';

  return (
    <HereMapsStatisticStyled>
      {overrideTitle}
      {!overrideTitle && title &&
        <div className="header-stat">
          {<UserIcon />}
          <p>{title}</p>
        </div>
      }

      <div className="chart-wrapper" style={{height: mapHeight}}>
        {markers.length > 0 ?
          <HereMapsMemo markers={markers} mapHeight={mapHeight} />
          :
          <span>{i18n.dashboard.no_data_available}</span>
        }
      </div>
    </HereMapsStatisticStyled>
  )
}

export default HereMapsStatistic
