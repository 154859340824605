import * as types from './types'

export const setIsFetching = isFetching => ({
  type: types.SET_HEADERS_IS_FETCHING,
  payload: isFetching
})

export const setHeaders = (headers, total) => ({
  type: types.SET_HEADERS,
  payload: { headers, total }
})

export const setData = (inboxData, total, totalInbox) => ({
  type: types.SET_DATA,
  payload: { inboxData, total, totalInbox }
})

export const setInboxFilters = inboxFilters => ({
  type: types.SET_INBOX_FILTERS,
  payload: inboxFilters
})

export const setPage = page => ({
  type: types.SET_PAGE,
  payload: page
})

export const setRowsSelected = rowsSelected => ({
  type: types.SET_ROWS_SELECTED,
  payload: rowsSelected
})

export const setSort = sort => ({
  type: types.SET_SORT,
  payload: sort
})

export const setPageSize = pageSize => ({
  type: types.SET_PAGE_SIZE,
  payload: pageSize
})

export const setStatusTypes = data => ({
  type: types.SET_STATUS_TYPES,
  payload: data
})

export const setTags = data => ({
  type: types.SET_TAGS_TYPES,
  payload: data
})

export const setReportFields = data => ({
  type: types.SET_REPORT_FIELDS,
  payload: data
})

export const RESET_FILTERS = () => ({
  type: types.RESET_FILTERS
})
