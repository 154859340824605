import React from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid'
import { MTCircularPercent } from '../../atoms'
import BoxInfoHeaderStyled from './styled'
import EditFieldAction from "../../organism/edit-field-action";
import { isJSON } from '../../../utils/validation'

const BoxInfoHeader = ({ actualStatus, titleInfo1, titleInfo2, titleInfo3, valid, value1, value2, value3, i18n, totalMatch, profileValue, section, noSkillsRender, showAdditionalInfo = true }) => {
  if (isJSON(value1) && (value1 = JSON.parse(value1))) {
    value1 = value1.join(', ');
  }
  if (isJSON(value2) && (value2 = JSON.parse(value2))) {
    value2 = value2.join(', ');
  }
  if (isJSON(value3) && (value3 = JSON.parse(value3))) {
    value3 = value3.join(', ');
  }
  return (
    <BoxInfoHeaderStyled className={section} profileContainer={!noSkillsRender ? '4%' : '0'} profileTitle={!noSkillsRender ? '-16px !important' : '0'}>
        <Grid id="wrap-table" container spacing={4}>
          {section !== 'onboarding' ? (<>
            <Grid className="matchContainer" item xs={2}>
              {_.isNumber(totalMatch) && <MTCircularPercent value={totalMatch} valid={valid} fontSize="18px" title={i18n.avatarMatch.match} thickness={4.8} />}
            </Grid>
            <Divider className='divider-box-infoheader divider-vertical MuiDivider-vertical' orientation='vertical' flexItem='true' />
            <Grid className="profileContainer" item xs={noSkillsRender ? 2 : 1}>
              {_.isNumber(profileValue) && <MTCircularPercent theme={true} value={profileValue} fontSize="18px" title={i18n.profileStats.profile}/>}
            </Grid></>) : <Grid item xs={1}/> }
          <Grid id='boxInfoHeaderData' className="talent_acquisition_profile" container item xs={8}>
            {showAdditionalInfo && <>
              <Grid container justifyContent="space-between" className={'row-table'}>
                <p id="title-info-header" className={'title'}>{titleInfo1}</p>
                <p><EditFieldAction remoteName={'formacion'} defaultValue={value1 || '-'}/></p>
              </Grid>
              <Divider className='boxInfoHeaderDivider' />
              <Grid container justifyContent="space-between" className={'row-table'}>
                <p id="title-info-header" className={'title'}>{titleInfo2}</p>
                <p><EditFieldAction remoteName={'anos_experiencia'} defaultValue={value2 || '-'}/></p>
              </Grid>
              <Divider className='boxInfoHeaderDivider' />
              <Grid container justifyContent="space-between" className={'row-table'}>
                <p id="title-info-header" className={'title'}>{titleInfo3}</p>
                <p><EditFieldAction remoteName={'ultimo_puesto'} defaultValue={value3 || '-'}/></p>
              </Grid>
              <Divider className='boxInfoHeaderDivider' />
            </>}
            {actualStatus &&
              <Grid container justifyContent="space-between" className={'row-table'}>
                <p className={'title'}>{i18n.boxProfileCite.currentState}</p>
                <p>{actualStatus || '-'}</p>
              </Grid>
            }
          </Grid>
        </Grid>

    </BoxInfoHeaderStyled>
  )
}

BoxInfoHeader.defaultProps = {
  titleInfo1: '',
  titleInfo2: '',
  titleInfo3: ''
}

BoxInfoHeader.propTypes = {
  totalMatch: PropTypes.number,
  profileValue: PropTypes.number,
  skillValue: PropTypes.number,
  actualStatus: PropTypes.string,
  titleInfo1: PropTypes.string,
  titleInfo2: PropTypes.string,
  titleInfo3: PropTypes.string,
  value1: PropTypes.string,
  value2: PropTypes.string,
  value3: PropTypes.string
}

const enharce = compose(withI18n)

export default enharce(BoxInfoHeader)
