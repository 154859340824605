import styled from 'styled-components'
import colors from '../../../assets/colors'
import { Select } from '@material-ui/core'
import React from "react";


const SelectTagsStyled = styled(props => <Select {...props}/>)`

    top: 4px;
    background: ${colors.greyAlpha26} !important;
    border-radius: 60px !important;
    height: 30px !important;
    width: 30px !important;
    justify-content: center !important;
    margin-left: 10px;
    line-height: 39px !important;
    
    &&::before, &&::after{
        content: none !important;
    }

    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input{
        position: absolute !important;
        padding-bottom: 7px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 6px;
    }

`

export default SelectTagsStyled
