import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { withI18n } from '../../hocs';
import { FormGridContainer, FormGridItem, ModalStyled } from './styled';
import Modal from '@material-ui/core/Modal';
import { ModalHeaderProcess, RegularButton, RegularButtonOutline } from '../../atoms';
import SelectChangeStatusFunnel from '../select-change-status-funnel';
import {
  getApplicationsByCandidate,
  updateApplicationStatusFunnelByCandidature,
  updateNewStatesSelectionFunnel
} from '../../../api/index';
import {manageQueriesWithPrefix, refetchQueriesByKeys} from '../../../api/mutations/mutationCallbacks';
import {
  ALERT_LEVEL, ALLOWED_EMAILS,
  getCurrentSectionKey,
  QUERY_ACTIONS,
  QUERY_KEYS,
  SECTION_KEYS
} from '../../../config/constants';
import {useLocation, useParams} from "react-router";
import {useAppContext} from "../../../context/appContext";
import { matchPath } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import routes from "../../../config/routes";
import {FeatureGuard} from "../../../utils/guard";

const customStyles = {
  overflow: 'scroll'
};

const ModalSuggestChangeStatusAnnouncement = ({ open, handleClose, i18n, profileData, statusFunnelTypes, module, data, auth, getApplicationsCallback = null }) => {
  const currentUserEmail = auth?.data?.email;

  const isCandidateProfileRoutes = [
    routes.profileQuery,
    routes.profileFavoritesQuery,
    routes.profileSaveSearchQuery,
    routes.profilePlanning,
  ];

  const location = useLocation();
  const {createAlert} = useAppContext();

  const [isCandidateProfile, setIsCandidateProfile] = useState(false);
  const [candidateApplicationsOptions, setCandidateApplicationsOptions] = useState([]);
  const [candidateApplicationValue, setCandidateApplicationValue] = useState(null);
  const [candidaturesToChangeStatus, setCandidaturesToChangeStatus] = useState([]);
  const [isNewStatusSelected, setIsNewStatusSelected] = useState(false);
  const { slug, job_offer_id, job_offer_application_id } = useParams();

  let filteredStatusFunnelTypes = [];

  filteredStatusFunnelTypes = (statusFunnelTypes?.data ?? []).filter((status) => {
    return status.name;
  });

  useEffect(() => {
    let is_candidate_profile = isCandidateProfileRoutes.some(route => matchPath(location.pathname, {path: route}));
    if (is_candidate_profile) {
      setIsCandidateProfile(true);
      getApplicationsByCandidate(slug).then(options => {
        setCandidateApplicationsOptions(options);
      });
    } else {
      setIsCandidateProfile(false);
      setCandidateApplicationsOptions([]);
    }
    if (data && data.length > 0) {
      const candidateObjects = data.map(candidate => ({
        id: candidate.id,
      }));
      setCandidaturesToChangeStatus(candidateObjects);
    } else if (job_offer_application_id) {
      setCandidaturesToChangeStatus([{id: job_offer_application_id}]);
    } else if (profileData?.id) {
      setCandidaturesToChangeStatus([{ id: profileData.id }]);
    }
  }, [data, profileData]);

  useEffect(() => {
    getApplicationsCallback && getApplicationsCallback(candidateApplicationsOptions.length)
  }, [getApplicationsCallback, candidateApplicationsOptions])


  const handleChangeStatus = async (e) => {
    e.preventDefault();

    try {
      if (isCandidateProfile && (ALLOWED_EMAILS.indexOf(currentUserEmail)>-1 || FeatureGuard.canAccessToJobOffers())) {

        if (!isNewStatusSelected || !isNewStatusSelected.id || !candidateApplicationValue || !candidateApplicationValue.id) {
          createAlert(i18n.modalChangeStatus.incomplete_form, ALERT_LEVEL.WARNING);
          return;
        }

        let data = {
          selection_status_id: isNewStatusSelected.id,
          candidate_id: slug,
          job_offer_application_id: candidateApplicationValue.id,
        };
        await updateApplicationStatusFunnelByCandidature(data).then((res) => {
          manageQueriesWithPrefix([
            `${module}:${QUERY_KEYS.INBOX}`,
            `${module}:${QUERY_KEYS.PROFILE}:${res.candidate_id}`,
            `${module}:${QUERY_KEYS.OBSERVATIONS}:${res.candidate_id}`
          ], QUERY_ACTIONS.REFETCH);
          manageQueriesWithPrefix([
            `${QUERY_KEYS.JOB_OFFER_APPLICATIONS}`,
            `${QUERY_KEYS.PERSON}`
          ], QUERY_ACTIONS.REMOVE);
          createAlert(i18n.phases_states.updatedStatus, ALERT_LEVEL.SUCCESS);
        }).catch(() => {
          createAlert(i18n.phases_states.errorUpdatedStatus, ALERT_LEVEL.ERROR);
        });
      } else {
        let type = null;
        if (job_offer_id || job_offer_application_id) {
          type = SECTION_KEYS.JOB_OFFER_APPLICATIONS;
        }
        const requestData = candidaturesToChangeStatus.map(candidate => ({
          id: candidate.id,
          status_id: isNewStatusSelected.id,
          type
        }));

        await updateNewStatesSelectionFunnel(requestData).then(() => {
          createAlert(i18n.phases_states.updatedStatus, ALERT_LEVEL.SUCCESS);
        }).catch(() => {
          createAlert(i18n.phases_states.errorUpdatedStatus, ALERT_LEVEL.ERROR);
        });

        let queriesToRefetch = [
          `${module}:${QUERY_KEYS.INBOX}`,
          `${module}:${QUERY_KEYS.PROFILE}:${slug}`,
          `${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`,
          `${QUERY_KEYS.PERSON}:${slug}:${job_offer_application_id}`
        ];
        refetchQueriesByKeys(queriesToRefetch);
      }

      setCandidaturesToChangeStatus([]);
      handleClose();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // eslint-disable-next-line no-console
        console.error('Error 400:', error.response.data);
      } else {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      }
    }
  };

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeaderProcess title={isCandidateProfile && (ALLOWED_EMAILS.indexOf(currentUserEmail)>-1 || FeatureGuard.canAccessToJobOffers()) ? i18n.phases_states.changeStatusCheckApplication : i18n.phases_states.questionChangeStatus} hasCloseIcon={false} />
          <div className="modal-body">
            <form>
              <FormGridContainer container spacing={2}>
                <FormGridItem item justifyContent='center' >
                  <SelectChangeStatusFunnel
                    options={filteredStatusFunnelTypes}
                    label={false}
                    placeholder={i18n.modalChangeStatus.selectStatus}
                    onSelectedItemChange={(newStatus) => setIsNewStatusSelected(newStatus)}
                  />
                </FormGridItem>
                {isCandidateProfile && (ALLOWED_EMAILS.indexOf(currentUserEmail)>-1 || FeatureGuard.canAccessToJobOffers()) && <FormGridItem item justifyContent='center' >
                  <Autocomplete
                    id="change-status-funnel"
                    size="small"
                    value={candidateApplicationValue}
                    options={candidateApplicationsOptions}
                    getOptionLabel={(option) => option.name}
                    style={{width: 220}}
                    renderInput={(params) => <TextField {...params} placeholder={i18n.modalChangeStatus.selectOffer} variant="outlined" />}
                    disableClearable={true}
                    onChange={(event, newValue) => {
                      setCandidateApplicationValue(newValue);
                    }}
                  />
                </FormGridItem>}
              </FormGridContainer>
              <div className="button-wrapper">
                <RegularButtonOutline title={i18n.phases_states.buttonCancel} handleOnClick={handleClose} />
                <RegularButton className="button-custom" title={i18n.phases_states.changeStatus} handleOnClick={handleChangeStatus} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

const enhance = compose(withI18n);

export default enhance(ModalSuggestChangeStatusAnnouncement);
