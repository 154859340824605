import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../assets/colors'
import { FileIcon } from '../'
import { MTExporterStyled } from './styled'

const MTExporter = ({ cv }) => {
  if (!cv) {
    return null
  }

  const handleClick = e => {
    e.stopPropagation()
  }

  return (
    <MTExporterStyled href={cv} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
      <FileIcon htmlColor={colors.darkGrey3} fontSize="large" />
    </MTExporterStyled>
  )
}

MTExporter.propTypes = {
  cv: PropTypes.string
}

export default MTExporter
