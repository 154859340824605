import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import * as FiltersUtils from "../../utils/filters";
import {MODULES, QUERY_KEYS} from "../../config/constants";
import {addItemCount} from "../index";
import eventTracker from "../../utils/eventTracker";


async function getInboxData({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  let [_key, {module, filters, rangeArr, sort}] = queryKey;
  if (!module || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;

  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  const range = rangeArr ? encodeURI(JSON.stringify(rangeArr)) : ''
  sort = sort ? encodeURI(JSON.stringify(sort)) : ''

  eventTracker.track('InboxRequest', {
    module: module,
    filters: filters,
    sort: sort
  });
  const response = await get(`/api/module/${module}/inbox?filter=${filters}&range=${range}${sort ? `&sort=${sort}` : ''}`);

  response.data = addItemCount(response.data, rangeArr);
  return response;
}

const queryOptions = {
  staleTime: 120000, // 2 minutes
  cacheTime: 180000, // 3 minutes
}

export default function useInbox(module, filters, rangeArr, sort) {
  filters = JSON.parse(JSON.stringify(filters)); // Clone filter object on query to protect for reference unexpected changes
  return useQuery([`${module}:${QUERY_KEYS.INBOX}`, {module, filters, rangeArr, sort}], getInboxData, queryOptions);
}