import {SaveSearchesActions} from './'
import 'core-js'
import _ from 'lodash'
import {CitationsActions} from "../citations";
import {ProcessesActions} from "../processes";
import eventTracker from "../../utils/eventTracker";

export const fetchSaveSearches = (module) => (dispatch, getState, { api, handleError }) => {
  dispatch(SaveSearchesActions.setIsFetching(true))

  return api
    .getSaveSearches(module)
    .then(reponse => dispatch(SaveSearchesActions.setData(reponse)))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(SaveSearchesActions.setIsFetching(false)))
}

export const fetchSaveSearch = (id, module) => (dispatch, getState, { api, handleError }) => {
  dispatch(SaveSearchesActions.setIsFetching(true))

  return api
    .getSaveSearchData(id, module)
    .then(response => dispatch(SaveSearchesActions.setData(response)))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(SaveSearchesActions.setIsFetching(false)))
}

export const postSaveSearches = (ids, module) => (dispatch, getState, { api, handleError, toast, i18n }) => {
  dispatch(SaveSearchesActions.setIsFetching(true))

  return api
    .postSaveSearches(ids, module)
    .then(() => {
      toast(i18n.appBar.successSave, { type: toast.TYPE.SUCCESS })
      dispatch(fetchSaveSearches())
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(SaveSearchesActions.setIsFetching(false)))
}

export const deleteSaveSearches = (ids, module) => (dispatch, getState, { api, handleError }) => {
  dispatch(SaveSearchesActions.setIsFetching(true))

  return Promise.all(_.map(ids, id => api.deleteSaveSearches(id, module)))
    .then(() => dispatch(fetchSaveSearches()))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(SaveSearchesActions.setIsFetching(false)))
}

export const setFilters = (filters, title) => dispatch => {
  dispatch(SaveSearchesActions.setFilters(filters, title))
}

export const setAuxFilters = filters => dispatch => {
  dispatch(SaveSearchesActions.setAuxFilters(filters))
}

export const setSearchFilters = (filters, title) => dispatch => {
  dispatch(SaveSearchesActions.setFilterGroup(-1))
  dispatch(SaveSearchesActions.setFilters(filters, title))
}

export const filtersOnChangeHandle = (arrObjSelected, name) => (dispatch, getState) => {
  const state = getState()
  const auxFilters = { ...state.saveSearches.auxFilters }

  if ( !arrObjSelected || _.isEmpty(arrObjSelected)
    || (arrObjSelected.length === 1 && _.get(arrObjSelected, '[0].value', null) === null)) {
    _.unset(auxFilters, name)
  } else {
    _.assign(auxFilters, { [name]: arrObjSelected })
  }

  dispatch(SaveSearchesActions.setAuxFilters(auxFilters))
}

export const filtersOnSelectHandle = (list, event) => (dispatch, getState) => {
  const state = getState()

  const auxFilters = { ...state.saveSearches.auxFilters }

  if (event.action === 'remove-value') {
    let filterAssoc = auxFilters[event.removedValue.name]
    let filterToRemove = _.find(filterAssoc, {id: event.removedValue.id})
    _.pull(filterAssoc, filterToRemove)

    if ( _.isEmpty(filterAssoc)) {
      _.unset(auxFilters, event.removedValue.name)
    }

    dispatch(SaveSearchesActions.setAuxFilters(auxFilters))
    dispatch(SaveSearchesActions.setFilters(auxFilters))
    dispatch(SaveSearchesActions.addFiltersToState( auxFilters ));

  } else if (event.action === 'clear') {
    dispatch(filtersClear())
  }
  dispatch(CitationsActions.setPage(0))
  dispatch(ProcessesActions.setPage(0))
}

export const filtersOnSubmitHandle = () => (dispatch, getState) => {
  const state = getState()

  const auxFilters = { ...state.saveSearches.auxFilters }

  eventTracker.track('SearchAction', {
    url: document.location.href,
    filters: auxFilters
  })
  // TODO: unificar el filtro de whatsapp counter y filtro de pagina de citaciones
  // if (auxFilters && Object.keys(auxFilters).length <= 1 && auxFilters.hasOwnProperty('date')) {
  //   try {
  //     getCitationWhatsappCounter({"date": auxFilters['date'][0].value}).then((res) => CitationsActions.setWhatsappCounter(res));
  //   } catch (e) {
  //   }
  // }

  dispatch(SaveSearchesActions.setFilters(auxFilters))
  dispatch(CitationsActions.setPage(0))
  dispatch(ProcessesActions.setPage(0))
  dispatch(SaveSearchesActions.addFiltersToState( auxFilters ));


}

export const filtersClear = () => (dispatch) => {
  dispatch(SaveSearchesActions.setFilters({}))
  dispatch(SaveSearchesActions.setAuxFilters({}))
  // todo unify all pages in one var...
  dispatch(CitationsActions.setPage(0))
  dispatch(ProcessesActions.setPage(0))
  dispatch(SaveSearchesActions.setFilterGroup(-1))
  dispatch(SaveSearchesActions.addFiltersToState({} ));

}
