import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@material-ui/core'
import { postEvaluativeTests, editEvaluativeTest, checkCsvCandidates } from '../../../api/index'
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from '../../../context/appContext'
import { useParams } from 'react-router'
import EvaluativeTestsCreateStyled from './styled'
import RegularButton from '../../atoms/regular-button'
import Divider from '@material-ui/core/Divider'
import BackButton from '../../atoms/back-button'
import DateRangePiker from '../../molecules/date-range-picker'
import moment from 'moment'
import InputField from '../../atoms/input-field'
import RegularButtonOutline from '../../atoms/regular-button-outline'
import IconXRemove from '../../atoms/icon-x-remove'
import useEvaluativeTestsTemplates from '../../../api/queries/useTemplates';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { CustomAlert } from '../../atoms';
import routes from '../../../config/routes';
import { CircularProgress } from '@material-ui/core';
import CsvUploader from '../../molecules/csv-uploader'
import { ListAlt } from '@material-ui/icons'
import { ModalImportEvaluativeTestsCandidates } from '../../molecules'

const EvaluativeTestsCreate = ({ i18n, initialData, isEditMode }) => {
  const { module } = useAppContext();
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(+7, 'days'));
  const [focused, setFocused] = useState(null);
  const [addQuestion, setAddQuestion] = useState([1, 2, 3, 4, 5, 6]);
  const [formData, setFormData] = useState({
    name: '',
    init_date: '',
    end_date: '',
    bot_url: '',
    questions: [],
    evaluative_config_template: [],
  });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isCreateSuccess, setIsCreateSuccess] = useState(false);
  const [isEditSuccess, setIsEditSuccess] = useState(false);
  const isButtonDisabled = addQuestion.length >= 6;
  const { testId } = useParams();
  const [errors, setErrors] = useState({});
  const [isDisableButton, setIsDisableButton] = useState(false);

  const { data: evaluativeTemplates } = useEvaluativeTestsTemplates(module);

  const [uploadCsvInProgress, setUploadCsvInProgress] = useState(false);
  const [showSendNotificationModal, setShowSendNotificationModal] = useState(false);
  const [uploadResult, setUploadResult] = useState([]);
  const [currentForm, setCurrentForm] = useState(null);

  const csvUploaderTriggerRef = useRef(null);

  const allowCsvUpload = true;

  const onAddCandidatesCsv = function(e) {
    e.preventDefault();

    if(csvUploaderTriggerRef.current) {
      csvUploaderTriggerRef.current();
    }
  }
  const setCsvUploaderTrigger = (trigger) => {
    csvUploaderTriggerRef.current = trigger;
  };
  const uploadCsv = function(form) {
    setCurrentForm(form);
    setUploadCsvInProgress(true);
    return checkCsvCandidates(testId, module, form);
  }

  const uploadCsvFinished = function(result) {
    setUploadCsvInProgress(false);
    setShowSendNotificationModal(true);
    setUploadResult(result);
  }

  useEffect(() => {
    if (isEditMode && initialData) {
      setStartDate(moment(initialData.init_date));
      setEndDate(moment(initialData.end_date));
      setFormData({
        name: initialData.name,
        init_date: initialData.init_date,
        end_date: initialData.end_date,
        bot_url: initialData.bot_url,
        questions: initialData.questions,
        evaluative_config_template: initialData.evaluative_config_template,
      });
      setSelectedTemplate(initialData.evaluative_config_template);
      setAddQuestion(Array.from({ length: initialData.questions.length }, (_, index) => index + 1));
    }
  }, [isEditMode, initialData]);

  const handleChangeTemplate = (event, newValue) => {
    setSelectedTemplate(newValue);
  };

  const createEvaluativeTest = async (data) => {
    try {
      const response = await postEvaluativeTests(data);
      return response.data;
    } catch (error) {
      setIsDisableButton(false);
      console.error('error', error);
      throw error;
    }
  }

  const editionEvaluativeTest = async (data) => {
    try {
      const response = await editEvaluativeTest(data.id, data);
      return response.data;
    } catch (error) {
      setIsDisableButton(false);
      console.error('error', error);
      throw error;
    }
  }

  const mutationCreateEvaluativeTest = useMutation(createEvaluativeTest);
  const mutationEditEvaluativeTest = useMutation(editionEvaluativeTest);

  const handleInputChange = (e, fieldName, index) => {
    const { value } = e.target;

    setFormData((prevFormData) => {
      const updatedQuestions = [...prevFormData.questions];

      if (!updatedQuestions[index]) {
        updatedQuestions[index] = { question: '', time_limit: null };
      }

      if (fieldName === "questions") {
        updatedQuestions[index] = {
          ...updatedQuestions[index],
          question: value,
        };
      } else if (fieldName === "time_limit") {
        updatedQuestions[index] = {
          ...updatedQuestions[index],
          time_limit: value.value || value,
        };
      } else {
        prevFormData[fieldName] = value;
      }

      return {
        ...prevFormData,
        questions: updatedQuestions,
      };
    });
  };

  const handleAddQuestion = (e) => {
    e.preventDefault();
    if (addQuestion.length < 6) {
      setAddQuestion([...addQuestion, addQuestion.length + 1]);
    }
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions.splice(index, 1);
    setFormData({
      ...formData,
      questions: updatedQuestions,
    });
    setAddQuestion(addQuestion.slice(0, -1));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formattedStartDate = startDate.format('YYYY-MM-DDTHH:mm:ssZ');
    const formattedEndDate = endDate.format('YYYY-MM-DDTHH:mm:ssZ');
    const evaluativeTemplate = selectedTemplate ? selectedTemplate.id : null;

    formData.init_date = formattedStartDate;
    formData.end_date = formattedEndDate;
    formData.evaluative_config_template = evaluativeTemplate

    const response_type = "video";

    const formattedData = {
      name: formData.name,
      init_date: formattedStartDate,
      end_date: formattedEndDate,
      bot_url: formData.bot_url,
      questions: formData.questions.map((question, index) => {
        const data = {
          question: decodeURIComponent(question.question),
          response_type: response_type,
          question_order: index + 1,
          time_limit: question.time_limit?.value || question.time_limit || timeLimit[0].value,
        }
        if (question.id) {
          data.id = question.id;
        }
        return data;
      }),
      evaluative_config_template: evaluativeTemplate,
    };

    const requiredFields = ['name', 'init_date', 'end_date', 'questions', 'evaluative_config_template'];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = i18n.evaluativeTests.errorFieldRequired;
      }
    });

    if (!formData.questions || formData.questions.length === 0) {
      newErrors['questions'] = i18n.evaluativeTests.errorFieldQuestion;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    setIsDisableButton(true);

    try {
      if (testId) {
        await mutationEditEvaluativeTest.mutateAsync({ id: testId, ...formattedData });
        setIsEditSuccess(true);
      } else {
        await mutationCreateEvaluativeTest.mutateAsync(formattedData);
        setIsCreateSuccess(true);
      }
      setTimeout(() => {
        setIsCreateSuccess(false);
        setIsEditSuccess(false);
        window.location.href = routes.evaluativesTests;
      }, 2000);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    }
  };

  const placeholderQuestions = [
    i18n.evaluativeTests.placeholderQuestionOne,
    i18n.evaluativeTests.placeholderQuestionTwo,
    i18n.evaluativeTests.placeholderQuestionThree,
    i18n.evaluativeTests.placeholderQuestionFour,
    i18n.evaluativeTests.placeholderQuestionFive,
    i18n.evaluativeTests.placeholderQuestionSix,
  ];

  const timeLimit = [
    { value: 90, name: '1:30 min' },
    { value: 120, name: '2:00 min' },
    { value: 180, name: '3:00 min' },
  ];

  return (
    <>
      <EvaluativeTestsCreateStyled>
        <Grid container className="title-container" justifyContent="space-between">
          <Grid item sm={3}>
            <BackButton
              title={i18n.headerProfileButtons.backToEvaluativeTests}
              onClick={() => {
                window.location.href = routes.evaluativesTests
                return;
              }}
            />
          </Grid>
        </Grid>
        <Divider variant="inset" />
        <form >
          <Grid container className="create-container" justifyContent="center" alignItems="center">
            <Grid item className="wrapper-create-test">
              <span className="title-create-test"> {isEditMode ? i18n.evaluativeTests.titleEditTest : i18n.evaluativeTests.titleNewTest}</span>

              {isEditMode && allowCsvUpload &&
                <div style={{ marginTop: "-72px", marginBottom: "16px" }}>
                  <RegularButtonOutline
                    style={{ fontSize: "12px" }}
                    title={uploadCsvInProgress ? <CircularProgress /> : <><ListAlt /> {i18n.evaluativeTests.csvUpload.editCta}</>}
                    onClick={onAddCandidatesCsv}
                  />

                  <CsvUploader
                    uploadFn={uploadCsv}
                    callbackFn={uploadCsvFinished}
                    triggerClick={setCsvUploaderTrigger}
                    showAlert={false}
                  />
                </div>
              }

              <Grid item className="wrapper-content">
                <span className="subtitle-create-test">{i18n.evaluativeTests.subtitleNewTest}</span>
                <span className="description-create-test">{i18n.evaluativeTests.descriptionNewTest}</span>
              </Grid>
              <Grid item className="wrapper-content">
                <Grid container className='container-title-date' >
                  <Grid item className='container-item-text' >
                    <span className="label-picker">{i18n.evaluativeTests.labelPickerStartDate} </span>
                  </Grid>
                  <Grid item className='container-item-text' >
                    <span className="label-picker">{i18n.evaluativeTests.labelPickerEndDate} </span>
                  </Grid>
                </Grid>
                <DateRangePiker
                  startDate={startDate}
                  onStartDateChange={date => setStartDate(date)}
                  endDate={endDate}
                  onEndDateChange={date => setEndDate(date)}
                  onFocusChange={({ focused }) => setFocused(focused)}
                  focused={focused}
                  showButton={false}
                />
                {errors.init_date && <CustomAlert severity="error" message={errors.init_date} />}
                {errors.end_date && <CustomAlert severity="error" message={errors.end_date} />}
              </Grid>
              <Grid item className="wrapper-content">
                <span className="title-test">{i18n.evaluativeTests.nameTest} </span>
                <InputField
                  className="name-test"
                  placeholder={i18n.evaluativeTests.placeholderNameTest}
                  name="name"
                  value={formData.name}
                  onChange={(e) => handleInputChange(e, "name")} />
                {errors.name && <CustomAlert severity="error" message={errors.name} />}
              </Grid>
              <Grid item className="wrapper-content" >
                <span className="title-test">{i18n.evaluativeTests.titleTemplate}</span>
                <div className="wrapper-select-template">
                  <Autocomplete
                    size="small"
                    value={selectedTemplate || null}
                    options={evaluativeTemplates || []}
                    getOptionLabel={(option) => option?.name || ''}
                    style={{ width: 400 }}
                    renderInput={(params) => <TextField {...params}
                      placeholder={i18n.evaluativeTests.placeholderSelectTemplate}
                      variant="outlined" />}
                    disableClearable={true}
                    onChange={handleChangeTemplate}
                  />
                </div>
                {errors.evaluative_config_template && <CustomAlert severity="error" message={errors.evaluative_config_template} />}
              </Grid>
              <Grid item className="wrapper-content">
                <span className="title-test">{i18n.evaluativeTests.selectionQuestions} </span>
                <span className="subtitle-test">{i18n.evaluativeTests.subtitleTest} </span>
                <span className="subtitle-test">{i18n.evaluativeTests.subtitleTest2} </span>
                <Grid className='container-add-question'>
                  <RegularButton title={i18n.evaluativeTests.buttonAddQuestion} className="button-custom" onClick={handleAddQuestion} disabled={isButtonDisabled} />
                </Grid>
                {addQuestion.map((_, index) => (
                  <Grid item key={index} className='container-questions'>
                    <textarea
                      className="textarea-question"
                      rows={2}
                      name="questions"
                      onChange={(e) => handleInputChange(e, "questions", index)}
                      placeholder={`Pregunta ${index + 1} ${placeholderQuestions[index]} `}
                      value={formData.questions[index]?.question || ''}
                    >
                    </textarea>
                    <div className='wrapper-icons-question'>
                      <Grid item className='wrapper-actions-question'>
                        <div className='wrapper-time-question'>
                          <Autocomplete
                            size="small"
                            value={timeLimit.find((option) => option.value === formData.questions[index]?.time_limit) || timeLimit[0]}
                            options={timeLimit}
                            getOptionLabel={(option) => option?.name || ''}
                            style={{ width: 135 }}
                            renderInput={(params) => <TextField {...params} placeholder={i18n.evaluativeTests.placeholderTimeLimit} variant="outlined" />}
                            disableClearable={true}
                            onChange={(e, value) => handleInputChange({ target: { value } }, "time_limit", index)}
                          />
                        </div>
                      </Grid>
                      <div className='container-icon-remove' onClick={() => handleRemoveQuestion(index)} >
                        <IconXRemove />
                      </div>
                    </div>
                  </Grid>
                ))}
                {errors.questions && <CustomAlert severity="error" message={errors.questions} />}
                <Grid container justifyContent='center'>
                  <div className="alert-container">
                    {mutationCreateEvaluativeTest.isLoading && <CircularProgress />}
                    {mutationEditEvaluativeTest.isLoading && <CircularProgress />}
                    {isCreateSuccess && (
                      <CustomAlert severity="success" message={i18n.evaluativeTests.successCreateTest} />
                    )}
                    {isEditSuccess && (
                      <CustomAlert severity="success" message={i18n.evaluativeTests.successUpdateTest} />
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid item className="wrapper-content-buttons">
                <RegularButtonOutline title={i18n.evaluativeTests.buttonCancel} onClick={(e) => {
                  e.preventDefault();
                  window.location.href = routes.evaluativesTests
                  return;
                }} />
                <RegularButton title={i18n.evaluativeTests.buttonSave} className="button-custom" onClick={handleFormSubmit} disabled={isDisableButton} />
              </Grid>
            </Grid>
          </Grid>
        </form>

        {showSendNotificationModal && <ModalImportEvaluativeTestsCandidates
          evaluativeConfigId={testId}
          form={currentForm}
          isModalOpen={showSendNotificationModal}
          handleClose={() => setShowSendNotificationModal(false)}
          uploadResult={uploadResult}/>}

      </EvaluativeTestsCreateStyled>
    </>
  )
}

export default EvaluativeTestsCreate
