import React from 'react'
import _ from 'lodash'
import { FavouriteIcon } from '../'

const MTFavoriteButton = ({ value, postFavorites, deleteFavorites }) => {
  const id = _.get(value, 'id')
  if (!id) return null
  const isFavorite = _.get(value, 'is_favorite')
  const onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const ep = !isFavorite ? postFavorites : deleteFavorites
    ep([value.id])
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <FavouriteIcon {...value} favorite={isFavorite} onClick={onClick} />
    </div>
  )
}

MTFavoriteButton.defaultPorps = {}

export default MTFavoriteButton
