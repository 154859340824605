import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {AppBarInbox, ProcessTable, ProfileProcessesInfo, SaveSearchesTable} from '../../organism'
import {PageTitleSubtitle} from '../../atoms'
import {MODULES} from '../../../config/constants'

const SavedSearches = ({ classes, searchFilters, searchFilterTitle, resetFilters, filterGroupSelected }) =>{
    const [total, setTotal] = useState(0);

  useEffect(() => {
    resetFilters()
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.root}>
      <AppBarInbox
        showActions
        module={MODULES.ID_EMPLOYEE_LINE}
        saveSearchOption={false}
        canEditFilters={false}
        canCloneSearch
        forceTotal={total}
        section={"savedSearches"}
      />
      {_.isEmpty(searchFilters) ? (
          <SaveSearchesTable module={MODULES.ID_EMPLOYEE_LINE} setTotal={setTotal}/>
      ) : (
        <>
          <PageTitleSubtitle subtitle={searchFilterTitle} module={MODULES.ID_EMPLOYEE_LINE} />
          <ProcessTable 
            showFilterGroup
            showChangeStatus
            filters={searchFilters}
            filterGroupSelected={filterGroupSelected}
            module={MODULES.ID_EMPLOYEE_LINE}
            rowViewRender={(id) => <ProfileProcessesInfo id={id} />}
            resetFiltersOnLoad={false}
          />
        </>
      )}
    </div>
  )
}

export default SavedSearches
