import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import IntegrationsWrapper from './styled';
import { RegularButton } from '../../atoms';
import { INTEGRATIONS } from '../../../config/constants';

const MyProfileIntegrations = ({
                                   classes,
                                   i18n,
                                   self_data,
                                   disconnectIntegration,
                               }) => {
    const [connectedIntegrations, setConnectedIntegrations] = useState({});

    useEffect(() => {
        if (self_data && self_data.event_type) {
            setConnectedIntegrations((prevState) => ({
                ...prevState,
                [self_data.event_type]: true,
            }));
        }
    }, [self_data]);

    const toggleIntegration = (integration) => {
        // self_data && self_data.event_type !== integration.event_type
        if (isDisabled(integration))
        {
            return ;
        }

        if (!connectedIntegrations[integration.event_type]) {
            window.location.href = integration.url + '?user=' + self_data.id;
        } else {
            disconnectIntegration(integration, self_data.id)
                .then(() => {
                    setConnectedIntegrations((prevState) => ({
                        ...prevState,
                        [integration.event_type]: false,
                    }));
                setConnectedIntegrations({});
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error('Failed to disconnect integration:', error);
                });
        }
    };

    const isDisabled = (integration) => {
        if (self_data && self_data.event_type === null) {
            return false;
        }
        return self_data && self_data.event_type !== integration.event_type;
    }

    return (
        <div className={classes.root}>
            {self_data && INTEGRATIONS && INTEGRATIONS.length ? (
                <IntegrationsWrapper>
                    {INTEGRATIONS.map((integration) => {
                        let isConnected = connectedIntegrations[integration.event_type];
                        return (
                            <Paper key={integration.name}
                                   className='integration_item'
                                   // style={{ opacity: isConnected ? '0.4' : '1' }}>
                                   style={{ opacity: isDisabled(integration) ? '0.4' : '1' }}>
                                <img
                                    // src={require(`../../../assets/images/${integration.calendar_logo}`).default}
                                    src={`https://hrbf-public.s3.eu-west-1.amazonaws.com/assets/${integration.calendar_logo}`}
                                    height={50}
                                    alt={integration.name}
                                />
                                <p className='integration_name'>{i18n.integrations[integration.name]}</p>
                                <RegularButton
                                    type='button'
                                    // disabled={() => isDisabled(integration)}
                                    color={isConnected ? '#f4304a' : null}
                                    title={isConnected ? i18n.integrations.disconnect : i18n.integrations.connect}
                                    onClick={() => toggleIntegration(integration)}
                                />
                            </Paper>
                        );
                    })}
                </IntegrationsWrapper>
            ) : (
                <h1>There is no integration available.</h1>
            )}
        </div>
    );
};

export default MyProfileIntegrations;
