import React from 'react'
import { ModalStyled } from './styled'
import { ReactComponent as ModalIcon } from '../../../assets/images/planning.svg'
import Modal from '@material-ui/core/Modal'
import { ModalHeader, RegularButtonOutline } from '../../atoms'
import { Box } from '@material-ui/core'
import i18n from '../../../assets/i18n'

const ModalSearch = ({ children, isOpen, setIsOpen, disabled = false }) => {
  return (
    <Box component="div">
      <RegularButtonOutline className="button-custom" title={i18n.templateRequestManager.filterProcess} onClick={() => setIsOpen(true)} disabled={disabled} />

      <Modal
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={i18n.modalSearch.title} closeClick={() => setIsOpen(false)} />
          <Box className="modal-body">{children}</Box>
        </ModalStyled>
      </Modal>
    </Box>
  )
}

export default ModalSearch
