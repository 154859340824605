import styled from 'styled-components'

const ProfileStyled = styled.div`
  && {
    padding: 30px 60px;
    position: relative;
  }
`

export default ProfileStyled
