import React from 'react'
import compose from 'recompose/compose'
import {withI18n} from '../../hocs'
import PropTypes from 'prop-types'
import SatisfactionSurveyTable from './styled'
import i18n from '../../../assets/i18n'
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import superAgreePng from '../../../assets/images/satisfaction_super_agree.png'
import agreePng from '../../../assets/images/satisfaction_agree.png'
import littleDisagreePng from '../../../assets/images/satisfaction_little_disagree.png'
import disagreePng from '../../../assets/images/satisfaction_disagree.png'

const TableStatistic = ({i18n, data}) => {

  const tableHeadData = {
    exitFactors: i18n.exitInterviewStatisticsItems.satisfactionSurvey.exitFactors,
    superAgree: i18n.exitInterviewStatisticsItems.satisfactionSurvey.superAgree,
    agree: i18n.exitInterviewStatisticsItems.satisfactionSurvey.agree,
    littleDisagree: i18n.exitInterviewStatisticsItems.satisfactionSurvey.littleDisagree,
    disagree: i18n.exitInterviewStatisticsItems.satisfactionSurvey.disagree,
  }

  const colors = {
    superAgree: '#37bc6a',
    agree: '#FAB830',
    littleDisagree: '#F87A26',
    disagree: '#F4304A',
  }

  return (
    <SatisfactionSurveyTable colors={colors}>
      <Table className="satisfaction_table">
        <TableHead className="table_head">
          <TableRow className="row">
            {tableHeadData && Object.keys(tableHeadData).map((key) => {
              let isExitFactors = key === 'exitFactors';
              let rowClass = isExitFactors ? 'row_element factor_name_header' : 'row_element';
              let imgSrc = '';
              switch (key) {
                case 'superAgree':
                  imgSrc = superAgreePng
                  break;
                case 'agree':
                  imgSrc = agreePng
                  break;
                case 'littleDisagree':
                  imgSrc = littleDisagreePng
                  break;
                case 'disagree':
                  imgSrc = disagreePng
                  break;
                default:
                  break;
              }

              return <TableCell key={'header_' + key} className={rowClass}>{!isExitFactors &&
                <div className="header_icon_container">{<img className="satisfaction_icon" src={imgSrc} alt={key}/>}</div>} {tableHeadData[key]}</TableCell>
            })}
              </TableRow>
              </TableHead>
              <TableBody className="table_body">
            {data.map((item, index) => {
              let stats = item.stats;
              let maxStat = Math.max(...Object.values(stats));
              let lastRowClass = data.length - 1 === index ? 'last_row_cell' : '';

              return <TableRow key={'tr_' + index} className="row">
              <TableCell className={"row_element factor_name " + lastRowClass}>
              <p
              className="factor_number">{index + 1}</p>{i18n.exitInterviewStatisticsItems.satisfactionSurvey[item.label]}
              </TableCell>
            {Object.keys(stats).map((key) => {
              let rowElementClasses = 'row_element ' + lastRowClass;
              let statContainerClasses = `stat_container  ${key} ` + (stats[key] === maxStat ? 'max_stat_container' : '');
              let valueClasses = `value value_${key} ` + (stats[key] === maxStat ? 'max_stat_value' : '');

              return <TableCell key={'cell_' + key} className={rowElementClasses}>
              <div className={statContainerClasses} style={{}}>
              <p className={valueClasses}>{stats[key]}</p>
              </div>
              </TableCell>;
            })}
              </TableRow>
            })}
              </TableBody>
          </Table>
      </SatisfactionSurveyTable>
      )
}

TableStatistic.propTypes = {
title: PropTypes.string,
i18n: PropTypes.object.isRequired,
data: PropTypes.array,
isFetching: PropTypes.bool
}

TableStatistic.defaultProps = {
title: i18n.processesStatisticsItems.tableTitle,
data: [],
isFetching: false
}

const enharce = compose(withI18n)

export default enharce(TableStatistic)
