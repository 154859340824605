import styled from 'styled-components'
import { headerStatStyles, chartWrapperStyles } from '../dynamic-widget/styled'

const GaugeStatisticStyled = styled.div`
  && {
    padding: 0;
    height: inherit;
  }
  
  ${headerStatStyles}
  
  ${chartWrapperStyles}

  .chart-wrapper {
    .gauge-chart {
      position: relative;
      bottom: 20px;
    }
    .value-percent {
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      color: ${props => props.theme.primary};
      text-align: center;
      position: absolute;
      bottom: 5px;
    }
    .left {
      left: 25%;
    }
    .right {
      right: 15%;
    }
  }
`

export default GaugeStatisticStyled
