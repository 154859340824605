import React from 'react'

const icon = props => (
  <svg {...props} opacity={0.6} width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.875 3.625V5.875H6.125V3.625M12.875 3.625C12.875 2.38236 11.8676 1.375 10.625 1.375H8.375C7.13236 1.375 6.125 2.38236 6.125 3.625M12.875 3.625H15.125C16.3676 3.625 17.375 4.63236 17.375 5.875V11.5M6.125 3.625H3.875C2.63236 3.625 1.625 4.63236 1.625 5.875V19.375C1.625 20.6176 2.63236 21.625 3.875 21.625H7.25M10.625 19.375L12.875 21.625L18.5 16"
      stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default icon