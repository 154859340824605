import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, ReportsTable } from '../../organism'
import { MODULES } from '../../../config/constants'

const Reports = ({ classes }) => (
  <div className={classes.root}>
    <AppBar />
    <ReportsTable module={MODULES.ID_EMPLOYEE_LINE} />
  </div>
)

Reports.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default Reports
