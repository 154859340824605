import View from './view'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router'
import { withTheme } from '@material-ui/core/styles'
import { withI18n } from '../../hocs'
import { AuthActions } from '../../../redux/auth'

const mapStateToProps = state => {
  return {
    data: state.auth.data,
    url: state.auth.url
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUrl: url => dispatch(AuthActions.setUrl(url))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withTheme
)

export default enharce(View)
