import React from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'
import {withI18n} from '../../hocs'
import PropTypes from 'prop-types'
import {FilterButton} from '../../atoms'
import TableFiltersStyled from './styled'
import {PeopleTableFilters} from '../../molecules'
import {MODULES} from '../../../config/constants'
import {withRouter} from "react-router";
import routes from "../../../config/routes";

const TableFilters = ({ total, values, selected, onFilterSelected, i18n, module, match }) => {
  if(module === MODULES.ID_TALENT_ACQUISITION && match.path !== routes.offerCandidatures) {
    return <PeopleTableFilters i18n={i18n} total={total} values={values} selected={selected} onFilterSelected={onFilterSelected} />
  }
  return (
    <TableFiltersStyled>
      {_.map(values, (item) => {
        return (
          <FilterButton
            label={item.name}
            total={total}
            value={item.total}
            isSelected={selected === item.id}
            onClick={() => onFilterSelected(item.id)}
            disabled={!item.total}
          />
          )
      } )}
    </TableFiltersStyled>
  )
}

TableFilters.propTypes = {
  i18n: PropTypes.object.isRequired,
  total: PropTypes.number,
  values: PropTypes.array,
  selected: PropTypes.number,
  onFilterSelected: PropTypes.func,
  disabledGroups: PropTypes.array,
  module: PropTypes.string
}

TableFilters.defaultProps = {
  total: 0,
  values: null,
  selected: -1,
  onFilterSelected: () => {}
}

const enharce = compose(withI18n, withRouter)

export default enharce(TableFilters)
