import * as types from './types'

export const setIsFetching = isFetching => ({
  type: types.SET_IS_FETCHING,
  payload: isFetching
})

export const setData = data => ({
  type: types.SET_DATA,
  payload: data
})

export const setInitialData = data => ({
  type: types.SET_INITIAL_DATA,
  payload: data
})

export const setPage = data => ({
  type: types.SET_PAGE,
  payload: data
})

export const setPerPage = data => ({
  type: types.SET_PER_PAGE,
  payload: data
})

export const setSort = data => ({
  type: types.SET_SORT,
  payload: data
})

export const setReady = data => ({
  type: types.SET_READY,
  payload: data
})

export const setModule = data => ({
  type: types.SET_MODULE,
  payload: data
})

export const resetData = data => ({
  type: types.RESET_DATA,
  payload: data
})

export const setDataFields = data => ({
  type: types.SET_DATA_FIELDS,
  payload: data
})

export const setTotal = data => ({
  type: types.SET_TOTAL,
  payload: data
})

export const setStatusTypes = data => ({
  type: types.SET_STATUS_TYPES,
  payload: data
})

export const setManualStatusTypes = data => ({
  type: types.SET_MANUAL_STATUS_TYPES,
  payload: data
})

export const setTagsTypes = data => ({
  type: types.SET_TAGS_TYPES,
  payload: data
})

export const setReportFields = data => ({
  type: types.SET_REPORT_FIELDS,
  payload: data
})