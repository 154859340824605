import {QUERY_ACTIONS, QUERY_KEYS} from "../../config/constants";
import queryClient from "../queryClient";
import _ from "lodash";

const getCallbackStructure = (onSuccessFn, onErrorFn = null, onSettledFn = null) => {
  return {
    onSettled: onSettledFn ? onSettledFn : function () {
    },
    onSuccess: onSuccessFn,
    onError: onErrorFn ? onErrorFn : function (error) {
      // eslint-disable-next-line no-console
      console.log('logg error', error);
    }
  }
}

export const invalidateQueriesByKeys = (module, queryKeys) => {
  queryKeys.map(queryKey => queryClient.invalidateQueries([`${module}:${queryKey}`]));
}

export const invalidateQueriesByKeysWithNoModule = (queryKeys) => {
  queryKeys.map(queryKey => queryClient.invalidateQueries([`${queryKey}`]));
}

export const manageQueriesWithPrefix = (queryKeys, action = QUERY_ACTIONS.INVALIDATE) => {
  queryKeys.map(queryKey => {
    let query = {
      predicate: query => {
       return query.queryKey[0] ? query.queryKey[0].startsWith(queryKey) : null;
      }
    };
    switch (action) {
      case QUERY_ACTIONS.REMOVE:
        return queryClient.removeQueries(query);
      case QUERY_ACTIONS.REFETCH:
        return queryClient.refetchQueries(query);
      case QUERY_ACTIONS.RESET:
        return queryClient.resetQueries(query);
      case QUERY_ACTIONS.CANCEL:
        return queryClient.cancelQueries(query);
      default:
        return queryClient.invalidateQueries(query);
    }
  });
}

export const removeQueriesByKeys = (queryKeys, exact = false) => {
  queryKeys.map(queryKey => queryClient.removeQueries([queryKey], {exact: exact}));
}

export const refetchQueriesByKeys = (queryKeys, exact = false) => {
  queryKeys.map(queryKey => queryClient.refetchQueries([queryKey], {exact: exact}));
}

const updateInboxDataField = (inboxData, ids, module, field, value) => {
  return getCallbackStructure(function () {
    if (inboxData) {
      queryClient.setQueryData([`${module}:${QUERY_KEYS.INBOX}`], () => {
        _.map(ids, (id) => {
          if (_.find(inboxData.data, { id })) { 
            inboxData.data[_.findIndex(inboxData.data, { id })][field] = value;
          }
        });
        return inboxData;
      });
    }
  });
}

export const updateProfileData = (data, module, id, field, value) =>{
  return getCallbackStructure(function () {
    if (data && id) {
      queryClient.setQueryData([`${module}:${QUERY_KEYS.PROFILE}:${id}`], () => {
        data[field] = value
        return data;
      });
    }
  });
}

export const updateDataFieldByQueryKey = (queryData, queryKey, ids, module, field, value) => {
  queryClient.setQueryData([`${module}:${queryKey}`], () => {
    _.map(ids, (id) => {
      queryData.data[_.findIndex(queryData.data, {id})][field] = value
    });

    return queryData;
  });
}

export const removeRowsByQueryKey = (queryData, queryKey, ids, module) => {
  queryClient.setQueryData([`${module}:${queryKey}`], () => {
    _.map(ids, (id) => {
      queryData.pop([_.findIndex(queryData, {id})])
    });

    return queryData;
  });
}

export const mutationFavoritesCallback = (ids, module, sectionKey, isFavorite, inboxData, isProfile = false) => {
  if (isProfile) {
    return updateProfileData(inboxData, module, ids && ids.length > 0 ? ids.shift() : null, 'is_favorite', isFavorite);
  }
  return updateInboxDataField(inboxData, ids, module, 'is_favorite', isFavorite);
};

export const mutationMarkAsReadCallback = (ids, module, inboxData, isProfile = false) => {
  if (isProfile) {
    return updateProfileData(inboxData, module, ids && ids.length > 0 ? ids.shift() : null, 'was_read', true);
  }
  return updateInboxDataField(inboxData, ids, module, 'was_read', true);
};

export const mutationMarkAsUnreadCallback = (ids, module, inboxData) => {
  return updateInboxDataField(inboxData, ids, module, 'was_read', false);
};

export const mutationChangeStatusCallback = (ids, module, status, inboxData, isProfile = false, statusObj = null) => {
  if (isProfile) {
    return updateProfileData(inboxData, module, ids && ids.length > 0 ? ids.shift() : null, 'state', statusObj);
  }
  return updateInboxDataField(inboxData, ids, module, 'estado', status);
};

export const mutationChangeTagsCallback = (ids, module, tags, tagsTypes, inboxData, isProfile = false) => {
  let newTags = [];
  tags.map(tagId => {
    tagId = typeof tagId === 'string' ? parseInt(tagId) : tagId;
    let tag = _.find(tagsTypes, {id: tagId});
    if (tag) {
      newTags.push({id: tag.id, name: tag.name});
    }
  });
  if (isProfile) {
    return updateProfileData(inboxData, module, ids && ids.length > 0 ? ids.shift() : null, 'tags', newTags);
  }

  return updateInboxDataField(inboxData, ids, module, 'tags', newTags);
};

export const mutationPostCitationCallback = (ids, module) => {
  return getCallbackStructure(() => {
    invalidateQueriesByKeys(module, [QUERY_KEYS.CITATIONS]);
  });
};

export const mutationDocRequiredCallback = (ids, module, status, inboxData, isProfile = false) => {
  if (isProfile) {
    return updateProfileData(inboxData, module, ids && ids.length > 0 ? ids.shift() : null, 'estado', status);
  }
  return updateInboxDataField(inboxData, ids, module, 'estado', status);
};

export const mutationPostObservationCallback = (candidatureId, value, module, observationsData) => {
  return getCallbackStructure(function (response) {
    queryClient.setQueryData([`${module}:${QUERY_KEYS.OBSERVATIONS}:${candidatureId}`], () => {

      observationsData.push(response);
      return observationsData;
    });
  });
};

export const mutationUpdateBlacklistCallback = (profileData, id, module, onBlacklist = null) => {
  return updateProfileData(profileData, module, id, 'on_blacklist', onBlacklist);
};

export const mutationStatesCandidatesCallback = (module, candidatureId) => {
  const refetchQueryRequests = [
    `${module}:${QUERY_KEYS.INBOX}`,
    `${module}:${QUERY_KEYS.OBSERVATIONS}:${candidatureId}`,
    `${module}:${QUERY_KEYS.PROFILE}:${candidatureId}`, { module, slug: candidatureId }
  ];
  queryClient.invalidateQueries(refetchQueryRequests);
  refetchQueriesByKeys(refetchQueryRequests);
}

export const onMutatePhaseOrState = (module, candidatures = []) => {
  const refetchQueryRequests = [
    `${module}:${QUERY_KEYS.PHASES}`,
    `${module}:${QUERY_KEYS.FUNNEL_STATUS_TYPES}`,
    `${module}:${QUERY_KEYS.INBOX}`,
  ];
  if (Array.isArray(candidatures)) {
    candidatures.map((candidature) => {
      refetchQueryRequests.push(`${module}:${QUERY_KEYS.PROFILE}:${candidature}`);
    })
  }
  queryClient.invalidateQueries(refetchQueryRequests);
  refetchQueriesByKeys(refetchQueryRequests, false);
}