import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import PageSubtitleTitleStyled from './styled'

const PageSubtitleTitle = ({ title, subtitle }) => (
  <PageSubtitleTitleStyled>
    <Typography variant="h1" style={{ fontWeight: 'normal' }}>
      {title}
    </Typography>
    {subtitle && <Typography variant="h1">{`"${subtitle}"`}</Typography>}
  </PageSubtitleTitleStyled>
)

PageSubtitleTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

export default PageSubtitleTitle
