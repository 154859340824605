import React from 'react'
import { AppBar, ProfileInfo } from '../../organism'
import ProfileStyled from '../profile/styled'

const Profiles = () => {
  return (
    <ProfileStyled>
      <div className="no-print">
        <AppBar />
      </div>
      <ProfileInfo showActions={false} showFav={false} showObsInput={false} />
    </ProfileStyled>
  )
}

export default Profiles
