import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withI18n} from '../../hocs'
import { ProcessesOperations } from '../../../redux/processes'

const mapStateToProps = state => ({
  filtersValues: state.saveSearches.auxFilters,
})

const mapDispatchToProps = dispatch => {
  return {
    postOffer: (obj) => dispatch(ProcessesOperations.postOffer(obj)),
  }
}

const enharce = compose(connect(mapStateToProps, mapDispatchToProps), withI18n)

export default enharce(View)
