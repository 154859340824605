import React, { useMemo, useState} from 'react'
import PieStatisticStyled from './styled'
import { ReactComponent as UserIcon } from '../../../assets/images/stats.svg'
import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'
import {isArray} from "lodash";
import i18n from '../../../assets/i18n'
import { useColorPalette, assignColorByValueAndData } from '../use-color-palette'
import {breakSvgText, ellipsisFromXCharacter} from "../../../utils/svg";

const translate = (keyword) => {
  return i18n.dashboard.pie[keyword] || keyword;
}

const PieStatistic = ({title = null, overrideTitle = null, data = null, size = 300, unit = '%', fixedLabels = false}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [stateFixedLabels,] = useState(fixedLabels);

  const palette = useColorPalette(isArray(data) ? data.length : 8);

  data = isArray(data) && data.length > 0 ? assignColorByValueAndData(data, "value", palette) : [];

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  }

  data.forEach((item) => (item.name = translate(item.name)));

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    // <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>

    return (
      <g>
        {breakSvgText(payload.name, cx, cy, 0, -5)}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        {!stateFixedLabels && (<>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
            {ellipsisFromXCharacter(payload.name, 25)}
          </text>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
            {`${value} (${(percent * 100).toFixed(0)}${unit})`}
          </text>
        </>)}
      </g>
    );
  };

  const renderLabel = ({ name, value, percent }) => {
    return `${name}: ${value} (${(percent * 100).toFixed(0)}${unit})`;
  };

  const chart = useMemo(
    () => {
      return (<div className="chart-wrapper" style={{width: '100%', height: size, padding: 0}}>
          <ResponsiveContainer width="100%" height="100%">
            {data && data.length > 0 ?
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  innerRadius={55}
                  outerRadius={90}
                  fill="#8884d8"
                  dataKey="value"
                  label={stateFixedLabels ? renderLabel : undefined}
                  labelLine={!stateFixedLabels}
                  onMouseEnter={onPieEnter}
                />
{/*                <Legend
                  layout="vetical" verticalAlign="middle" align="right"
                />*/}
              </PieChart>
              :
              <div className="no-data-available">{i18n.dashboard.no_data_available}</div>
            }
          </ResponsiveContainer>
        </div>
      )
    }, [activeIndex, stateFixedLabels]);

  return (
    <PieStatisticStyled>
      {overrideTitle}
      {!overrideTitle && title &&
      <div className="header-stat">
          {<UserIcon />}
          <p>{title}</p>
        </div>
      }
      {chart}
    </PieStatisticStyled>
  )
}

export default PieStatistic
