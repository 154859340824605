import React from 'react'
import PropTypes from 'prop-types'
import {MTImageStyled, Ia, Idiv} from './styled'

const verifyURL = foto => {
    return (foto && foto.split('')[0] === 'h') ? true : false;
}

const MTImage = ({foto, avoidLink = false, inicials, size, pullIcon = null}) => {
  let style, Type, typeProps, text;
  if (verifyURL(foto)) {
      style = {backgroundImage: 'url(' + foto + ')', cursor: 'pointer', position: 'relative'};
      if(avoidLink) {
        style.cursor = 'default';
        Type = Idiv
      } else {
        Type = Ia
        typeProps = {href: foto, target: '_blank'}
      }

      text = ''
    }else{
      Type = Idiv
      typeProps = {}
      style = {cursor: 'default', filter: `brightness(1.2)`, position: 'relative'};
      text = inicials
    }
    return (
        <Type {...typeProps}>
        <MTImageStyled size={size} className={size} style={style} inicials={inicials} alt="avatar">
            {text}
            {pullIcon != null && pullIcon}
        </MTImageStyled>
        </Type>)
}

MTImage.propTypes = {
    foto: PropTypes.string,
    initials: PropTypes.string,
    size: PropTypes.string
}

export default MTImage

