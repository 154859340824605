import React from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const InputEditor = ({ fontSize, modules, placeholder, name, onChange, value, rest }) => (
  <ComponentStyled fontSize={fontSize}>
    <ReactQuill className="input-editor" modules={modules} placeholder={placeholder} name={name} value={value} onChange={onChange} {...rest}/>
  </ComponentStyled>
)

InputEditor.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  modules: PropTypes.object,
  fontSize: PropTypes.string
}

InputEditor.defaultProps = {
  modules: {
    toolbar: null
  },
  fontSize: 'normal'
}

export default InputEditor
