import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'

const mapStateToProps = state => {
  return {
    client: state.auth.client,
    self: state.auth.data,
  }
}

const mapDispatchToProps = () => {
  return {
  }
}

const enharce = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withI18n)

export default enharce(View)
