import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'
import InputField from '../../atoms/input-field'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import RegularButton from '../../atoms/regular-button';
import {
  CustomAlert,
  IconAlertInfo,
} from '../../atoms'
import {
  EVALUATION_CRITERIA, QUERY_ACTIONS, QUERY_KEYS,
} from '../../../config/constants'
import _, {find, trim} from 'lodash';
import MyProfileIaEvaluationWizardStyled from "./styled";
import i18n from "../../../assets/i18n";
import Radio from "@material-ui/core/Radio";
import {useEvaluationCriteria} from "../../../api/queries/useIaEvaluationCriteria";
import {ModalSimpleSmall} from "../../molecules";
import {useLocation, useParams} from "react-router";
import {useMutation} from "@tanstack/react-query";
import {patchEvaluationProfile, postEvaluationProfile} from "../../../api";
import {useJobsByFamily} from "../../../api/queries/useJobFamilies";
import {useIaEvaluationProfile} from "../../../api/queries/useIaEvaluationProfile";
import {useHistory} from "react-router-dom";
import routes from "../../../config/routes";
import {manageQueriesWithPrefix} from "../../../api/mutations/mutationCallbacks";
import {useRpaCredentialsOptions} from "../../../api/queries/useRpaIntegration";

const MyProfileIaEvaluationWizard = () => {
  const location = useLocation();
  const history = useHistory();
  const {id} = useParams();
  const mode = location.pathname.split('/').pop();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });

  const [familyOptions, setFamilyOptions] = useState([]);
  const [jobsOptions, setJobsOptions] = useState([]);
  const [rpaCredentialsOptions, setRpaCredentialsOptions] = useState([]);

  const [family, setFamily] = useState("");
  const [job, setJob] = useState("");
  const [name, setName] = useState("");
  const [rpaCredentials, setRpaCredentials] = useState("");
  const [criteriaValues, setCriteriaValues] = useState({});

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const { data: evaluationCriteria, isLoading: isLoadingCriteria} = useEvaluationCriteria();
  const { data: rpaCredentialsData, isLoading: isLoadingRpaCredentialsData} = useRpaCredentialsOptions();
  const { data: jobsByFamily, isLoading: isLoadingJobsByFamily} = useJobsByFamily();
  const { data: evaluationProfile, isLoading: isLoadingEvaluationProfile} = useIaEvaluationProfile(id);

  useEffect(() => {
    if (!isLoadingRpaCredentialsData && rpaCredentialsData) {
      let options = rpaCredentialsData.map(row => {
        let formattedName = row.name.split(" - ");
        formattedName[0] = i18n.job_offers[formattedName[0]];
        return {...row, name: formattedName.join(" - ")};
      })
      setRpaCredentialsOptions(options);
    }
  }, [rpaCredentialsData]);

  useEffect(() => {
    if (mode === 'edit') {
      if (!isLoadingEvaluationProfile && !evaluationProfile) {
        history.push(routes.iaEvaluation);
      } else if (evaluationProfile) {
        setFamily({name: evaluationProfile.job.job_family.name, id: evaluationProfile.job.job_family.id});
        setJob({name: evaluationProfile.job.name, id: evaluationProfile.job.id});
        setName(evaluationProfile.name);

        let currentCriteriaValues = {};
        evaluationProfile.evaluation_profile_criterias.map(criteria => {
          currentCriteriaValues[criteria.evaluation_criteria.keyword] = criteria.relevance_level;
        });
        setCriteriaValues(currentCriteriaValues);
      }
    }
  }, [mode, id, isLoadingEvaluationProfile, evaluationProfile]);

  useEffect(() => {
    if (!isLoadingCriteria && evaluationCriteria && evaluationCriteria.length > 0 && mode !== 'edit') {
      let resultCriteriaValues = {};

      evaluationCriteria.map(criteria => {
        resultCriteriaValues[criteria.keyword] = EVALUATION_CRITERIA.DESIRED;
      });
      setCriteriaValues(resultCriteriaValues);
    }
  }, [evaluationCriteria]);

  useEffect(() => {
    if (!isLoadingJobsByFamily && jobsByFamily && jobsByFamily.length > 0) {
      let families = jobsByFamily.map(family => ({name: family.name, id: family.id}));
      setFamilyOptions(families);
    }
  }, [jobsByFamily]);

  useEffect(() => {
    if (family) {
      let selectedFamily = find(jobsByFamily, {id: family.id});
      if (selectedFamily && selectedFamily.jobs && selectedFamily.jobs.length > 0) {
        let jobs = [];
        selectedFamily.jobs.map(job => {
          jobs.push({name: job.name, id: job.id});
        });
        setJobsOptions(jobs);
      }
    }
  }, [family]);

  const handleRadioChange = (e) => {
    setCriteriaValues({...criteriaValues, [e.target.name]: e.target.value});
  }

  const handleOnClickSave = () => {
    setIsModalOpen(true);
  }

  const handleCreateEvaluation = (data) => {
    postEvaluationProfile(data).then(() => {
      manageQueriesWithPrefix([QUERY_KEYS.IA_EVALUATION], QUERY_ACTIONS.REFETCH);
      setAlert({ message: i18n.ia_evaluation.wizard.successCreateEvaluation, severity: 'success' });
    });
  }

  const handleUpdateEvaluation = ({id, data}) => {
    patchEvaluationProfile(id, data).then(() => {
      manageQueriesWithPrefix([QUERY_KEYS.IA_EVALUATION, `${QUERY_KEYS.IA_EVALUATION_ROW}:${id}`], QUERY_ACTIONS.REFETCH);
      setAlert({ message: i18n.ia_evaluation.wizard.successUpdateEvaluation, severity: 'success' });
    });
  }

  const mutationCreateEvaluation = useMutation(handleCreateEvaluation);
  const mutationUpdateEvaluation = useMutation(handleUpdateEvaluation);

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const data = {
        name: trim(name),
        job: job.id,
        family: family.id,
        rpa_credentials: rpaCredentials.id,
        criteria: criteriaValues
      };

      if (mode === 'create') {
        await mutationCreateEvaluation.mutateAsync(data);
      } else {
        await mutationUpdateEvaluation.mutateAsync({id, data});
      }
      setTimeout(() => {
        setIsModalOpen(false);
        history.push(routes.iaEvaluation);
      }, 3000);
    } catch (error) {
      if (mode === 'create') {
        setAlert({ message: i18n.ia_evaluation.wizard.errorCreateEvaluation, severity: 'error' });
      } else {
        setAlert({ message: i18n.ia_evaluation.wizard.errorUpdateEvaluation, severity: 'error' });
        console.error('Error durante la mutación:', error);
      }
    }
  }

  return (
    <MyProfileIaEvaluationWizardStyled>
      <div className="containerHeader">
        <Grid container spacing={4}>
          <Grid item className="sectionSecond" xs={12} sm={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start", gridGap: '15px' }}>
              <h2>{mode === 'create' ? i18n.ia_evaluation.wizard.title_create : i18n.ia_evaluation.wizard.title_edit}</h2>
              <h5 style={{color: '#000'}}>{i18n.ia_evaluation.wizard.subtitle}</h5>
              <h5>{i18n.ia_evaluation.wizard.description}</h5>
            </Box>
          </Grid>
          <Grid item className="sectionSecond" xs={12} sm={12}>
              <Box item sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: "flex-start", gap: '12px' }}>
                <label>{i18n.ia_evaluation.wizard.family}</label>
                <Autocomplete
                  className='autocomplete-custom'
                  options={familyOptions}
                  getOptionLabel={(option) => option.name ? option.name : ''}
                  value={family}
                  onChange={(_, selected) => {
                    if (selected !== family) {
                      setJob(null);
                      setJobsOptions([]);
                    }
                    setFamily(selected);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={i18n.ia_evaluation.wizard.family_placeholder} variant="outlined" />
                  )}
                />
              </Box>
              <Box item sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: "flex-start", gap: '12px' }}>
                <label>{i18n.ia_evaluation.wizard.job}</label>
                <Autocomplete
                  className='autocomplete-custom'
                  options={jobsOptions}
                  getOptionLabel={(option) => option.name ? option.name : ''}
                  value={job}
                  onChange={(_, selected) => {
                    setJob(selected);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={i18n.ia_evaluation.wizard.job_placeholder} variant="outlined" />
                  )}
                />
              </Box>
            </Grid>
            <Grid item className="sectionSecond" xs={12} sm={12}>
              <Box item sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: "flex-start", gap: '12px' }}>
                <label>{i18n.ia_evaluation.wizard.name}</label>
                <InputField
                  value={name}
                  onChange={(e) => {setName(e.target.value)}}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={i18n.ia_evaluation.wizard.name_placeholder} variant="outlined" />
                  )}
                />
              </Box>
            <Box item sx={{display: 'inline-flex', flexDirection: 'column', alignItems: "flex-start", gap: '8px'}}>
              <label>{i18n.ia_evaluation.wizard.rpa_credentials}</label>
              <Autocomplete
                className='autocomplete-custom'
                options={rpaCredentialsOptions ? rpaCredentialsOptions : []}
                getOptionLabel={(option) => option.name ? option.name : ''}
                value={rpaCredentials}
                onChange={(_, selected) => {
                  setRpaCredentials(selected);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder={i18n.ia_evaluation.wizard.rpa_credentials_placeholder}
                             variant="outlined"/>
                )}
              />
            </Box>
          </Grid>

          {family && job && trim(name) && <>
            <Grid item className="alertInfoValuesSection" xs={12} sm={12}>
            <IconAlertInfo className="iconAlertInfo"/>
            <h5 className="alertInfo1">{i18n.ia_evaluation.wizard.info_text}</h5>
            <h5 dangerouslySetInnerHTML={{__html: i18n.ia_evaluation.wizard.info_text_values.replace('{family}', family ? family.name : '').replace('{job}', job ? job.name : '')}}></h5>
          </Grid>

            {evaluationCriteria && <Grid item className="configureCriteriaSection" xs={12} sm={12}>
              <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
                <h5 style={{color: "#7a808d"}}>{i18n.ia_evaluation.wizard.tableHeader}</h5>
                <div className="headerGroup" style={{display: "flex", gap: 12}}>
                  {Object.keys(EVALUATION_CRITERIA).map(relevance => {
                    return <div key={relevance} style={{width: 100, textAlign: "center"}}><h5>{i18n.ia_evaluation.relevance_level[EVALUATION_CRITERIA[relevance]]}</h5></div>
                  })}
                </div>
              </div>
              {evaluationCriteria.map(criteria => {
                return <div key={criteria.id} style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                  <h5>{i18n.ia_evaluation.criteria[criteria.keyword]}</h5>
                  <div className="radioGroup" style={{display: "inline-flex", gap: 12}}>
                    {Object.keys(EVALUATION_CRITERIA).map(relevance => {
                      return <div key={relevance} style={{width: 100, textAlign: "center"}}><Radio
                        className={`${EVALUATION_CRITERIA[relevance]}_radio`}
                        checked={criteriaValues[criteria.keyword] === EVALUATION_CRITERIA[relevance]}
                        onChange={handleRadioChange}
                        value={EVALUATION_CRITERIA[relevance]}
                        name={`${criteria.keyword}`}
                        inputProps={{'aria-label': 'A'}}
                      /></div>
                    })}
                  </div>
                </div>
              })}
              <div style={{float: "right", marginTop: 24}}>
                <RegularButton title={i18n.ia_evaluation.wizard.save} onClick={handleOnClickSave}/>
              </div>
            </Grid>}
          </>}
        </Grid>
      </div>
      <ModalSimpleSmall
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        buttonTitle={i18n.ia_evaluation.wizard.save_confirmation_button.replace('{mode}', i18n.ia_evaluation.wizard[`${mode}_mode`])}
        handleButtonClick={handleSave}
        textContent={<>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} style={{display: "flex", flexDirection: "column", gap: 15}}>
              <h4 style={{fontWeight: "bold"}}>{i18n.ia_evaluation.wizard.save_confirmation_title.replace('{mode}', i18n.ia_evaluation.wizard[`${mode}_mode`])}</h4>
              <h4 dangerouslySetInnerHTML={{__html: i18n.ia_evaluation.wizard.info_text_values
                  .replace('{family}', family ? family.name : '').replace('{job}', job ? job.name : '')}}></h4>

              {showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}

            </Grid>
          </Grid>
        </>
        }
      />
    </MyProfileIaEvaluationWizardStyled>
  );
};

export default MyProfileIaEvaluationWizard;
