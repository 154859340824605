import * as types from './types'

const initialState = {
  headers: null,
  inboxFilters: null,
  inboxData: null,
  inboxUser: null,
  total: 0,
  rowsSelected: [],
  totalInbox: 0,
  offset: 0,
  isFetching: false,
  page: 0,
  sort: null,
  pageSize: null,
  reportFields: [],
  statusTypes: [],
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_HEADERS:
      return { ...state, headers: action.payload.headers, total: action.payload.total }
    case types.SET_HEADERS_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_DATA:
      return { ...state, inboxData: action.payload.inboxData, total: action.payload.total, totalInbox: action.payload.totalInbox }
    case types.SET_DATA_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_INBOX_FILTERS:
      return { ...state, inboxFilters: action.payload }
    case types.SET_PAGE:
      return { ...state, page: action.payload }
    case types.SET_ROWS_SELECTED:
      return { ...state, rowsSelected: action.payload }
    case types.SET_SORT:
      return { ...state, sort: action.payload }
    case types.SET_PAGE_SIZE:
      return { ...state, pageSize: action.payload }
    case types.SET_STATUS_TYPES:
      return { ...state, statusTypes: action.payload }
    case types.SET_REPORT_FIELDS:
      return { ...state, reportFields: action.payload }
    case types.RESET_FILTERS:
      return { ...initialState }
    default:
      return state
  }
}
