import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ModuleStyled = styled.div`
  && {
    cursor: pointer;
  }

  .wrap-image {
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 20%;
    border-radius: 10px;

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 33px;
      position: relative;
      color: ${colors.white};
      text-align: center;
    }

    :hover:before {
      background-color: ${colors.blue2};
    }

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transition: 0.5s ease all;
      height: 100%;
      border-radius: 10px;
      background-color: ${colors.black};
      opacity: 0.5;
    }

    ${props =>
      props.isActive === true &&
      css`
        :before {
          background-color: ${colors.blue2};
        }
      `}
  }
`

export default ModuleStyled
