import React from 'react'
import compose from 'recompose/compose'
import Grid from '@material-ui/core/Grid'
import BoxKeyValueStyled from './styled'

const BoxKeyValue = ({ title, data = [], dataKey, dataValue, showList = false, numCols = 2 }) => {
  let i = 0;
  const renderItem = (item) => {
    i++;
    return (<Grid xs={12} container justifyContent='space-between' className={'row-table'}>
      {item[dataKey] &&
      <Grid item>
        <p className={'title'}>{showList && <b>{i}. </b>}{item[dataKey]}</p>
      </Grid>}

      <Grid item>
        {item[dataValue] ? <p className={item[dataValue].length < 120 ? 'value' : 'value long'}>
          {item[dataValue]}
        </p> : '-' }
      </Grid>
    </Grid>)
  }

  let chunkSize = Math.ceil(data.length / numCols);
  let style = {
    "max-width": `${Math.ceil(100 / numCols)-(numCols-1)}%`
  };


  const RenderItems = () => {
    const chunks = data.reduce((result, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!result[chunkIndex]) {
        result[chunkIndex] = [];
      }
      result[chunkIndex].push(item);
      return result;
    }, []);

    return chunks.map((chunk, idxGroup) => {
      return (
        <Grid key={idxGroup} xs={12} lg={12/numCols} container item className={'grid-container'} style={style}>
          {chunk.map(renderItem)}
        </Grid>)
    });
  }

  return <>
      <BoxKeyValueStyled hasTitle={!!title}>
        {title && <div className="header-table"><p>{title}</p></div>}

        <Grid container justifyContent='space-between' alignItems={'baseline'}>
            <RenderItems />
        </Grid>

      </BoxKeyValueStyled>
    </>
}

const enharce = compose()
export default enharce(BoxKeyValue)
