import React  from 'react'
import {Card, CardHeader} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import DashboardCardStyled from "./styled";

const DashboardPresetItem = ({ id , preview, name, description, handleOnClick, className = [] }) => {
  if(className && !Array.isArray(className)) {
    className = [className];
  }
  className.push('dashboard_preconfigured_card');
  return (
    <DashboardCardStyled onClick={() => {handleOnClick(id)}}>
      <Card key={id} className={className.join(' ')}>
        <CardHeader
          avatar={<Avatar variant="square" src={preview} aria-label="preview_url"/>}
          title={name}
          subheader={description}
        />
      </Card>
    </DashboardCardStyled>
  )
}

export default DashboardPresetItem
