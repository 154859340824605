import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const RegularButtonSecondaryStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  && {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: ${props => (props.isActive ? colors.white : colors.black)};
    border: 0;
    border-radius: 50px;
    box-shadow: none;
    padding: 2px 20px;
    outline: none;
    background: ${props => (props.isActive ? props.color : 'transparent')};
    transition: 0.5s ease all;
    opacity: ${props => (props.disabled ? 0.6 : 1)};
  }
`
export default RegularButtonSecondaryStyled
