import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import i18n from '../../../assets/i18n'
import { SelectChangeStatusTemplateRequestStyle } from './styled'

const SelectChangeStatusTemplateRequest = ({ options, currentStatus, onSelectedItemChange, disabled = false, label = true, placeholder }) => {
  {
    return (
      <SelectChangeStatusTemplateRequestStyle>
        <Autocomplete
          id="change-status-funnel"
          size="small"
          value={currentStatus}
          options={options}
          groupBy={option => option.group.name}
          getOptionLabel={option => option.name}
          style={{ width: 260 }}
          renderInput={params => <TextField {...params} label={label ? i18n.templateRequestManager.statusProcessValidator : null} variant="outlined" placeholder={placeholder} />}
          disableClearable={true}
          onChange={(event, newValue) => {
            onSelectedItemChange(newValue)
          }}
          disabled={disabled === true}
        />
      </SelectChangeStatusTemplateRequestStyle>
    )
  }
}

export default SelectChangeStatusTemplateRequest
