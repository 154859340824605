import React from 'react'
import { SingleDatePicker } from 'react-dates'
import i18n from '../../../assets/i18n'
import SingleDatePickerStyled from './styled'

const SingleDate = ({ date, onDateChange, focused, onFocusChange, isFetching, readOnly = false }) => {
  const commons = {
    disabled: isFetching || readOnly,
    showClearDate: true,
    showDefaultInputIcon: true,
    small: true,
    numberOfMonths: 1,
    reopenPickerOnClearDate: true,
    hideKeyboardShortcutsPanel: true
  }
  return (
    <SingleDatePickerStyled>
      <SingleDatePicker
        {...commons}
        date={date}
        onDateChange={onDateChange}
        focused={focused === 'startDate'}
        onFocusChange={({ focused }) => onFocusChange({ focused: focused ? 'startDate' : '' })}
        id="startDate_id"
        placeholder={i18n.statisticsItems.startDate}
      />
    </SingleDatePickerStyled>
  )
}

export default SingleDate
