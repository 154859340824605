import styled  from 'styled-components'

export const MTToolbarStyled = styled.div`
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 15px;
  align-items: center;
  font-weight: 700;
  display: flex;
  justify-content: space-between;

  #totalText{
    margin: 15px 0;
    font-size: 14px;
    color: #7A808D;
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
  }

`
