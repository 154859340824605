import styled from 'styled-components'
import colors from '../../../assets/colors'

const DateRangeStyled = styled.div`

  .SingleDatePicker{
     background-color: ${colors.white};
  }
  .SingleDatePickerInput__withBorder {
     box-shadow: 2px 6px 8px rgba(98, 102, 113, 0.06), 0px 2px 2px rgba(98, 102, 113, 0.06);
     border-radius: 6px; 
  }
  
  .DateInput {
    width: 120px;
    padding: 5px;
  }
  
  .DateInput_input__focused {
    border-bottom: 2px solid ${props => props.theme.textPrimary};
  }
  
  .DayPicker__withBorder{
     box-shadow: 0 4px 6px rgb(0 0 0 / 5%), 0 0 2px 2px rgb(0 0 0 / 7%);
     border-radius: 6px; 
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${props => props.theme.primary};
    color: ${props => props.theme.textPrimary};
    border: 1px double ${props => props.theme.textPrimary};
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  display: inline-flex;
  align-items: center;

  .separator {
    margin: 5px;
  }

  .submit {
    color: black;
    background: transparent;
    margin-left: 5px;
    padding: 5px;
    border-radius: 39px;
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    :hover {
      opacity: 0.8;
    }

    :focus {
      outline: 0;
    }
  }

  .rotate {
    animation: spinner 0.6s linear infinite;
  }
`

export default DateRangeStyled
