import styled from 'styled-components'

import colors from '../../../assets/colors'

const EvaluativeTestsCreateStyled = styled.div`
  .title-container {
    padding: 30px 60px;
  }

  .button-custom {
    border-radius: 10px;
    color: #ffffff;
  }

  .buttons-wrapper {
    text-align: right;
  }

  .create-container {
    padding: 30px 60px;
  }

  .description-section {
    color: #7a808d;
    font-size: 16px;
    font-weight: 400;
  }

  .wrapper-create-test {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 47px 70px;
    width: auto;
    height: auto;
    border-radius: 25px;
    background: #fbfbfb;
    width: 820px;
    box-shadow: 0px 0px 1px 0px #e3e4e5, 0px 3px 12px 0px rgba(9, 30, 66, 0.14);
  }

  .title-create-test {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: #000;
    font-size: 28px;
    font-weight: 600;
  }

  .subtitle-create-test {
    color: #1c1c1c;
    font-size: 20px;
    font-weight: 600;
  }

  .description-create-test {
    color: #7a808d;
    font-size: 14px;
    font-weight: 500;
  }

  .wrapper-text {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
  }

  .container-title-date {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .container-item-text {
    min-width: 230px;
  }

  .label-picker {
    color: #262e36;
    font-size: 14px;
    font-weight: 400;

    &::after {
      content: '*';
      color: #d95117;
      padding-left: 2px;
    }
  }

  .wrapper-content {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .title-test {
    color: #262e36;
    font-size: 14px;
    font-weight: 600;
    &::after {
      content: '*';
      color: #d95117;
      padding-left: 2px;
    }
  }

  .wrapper-url {
    display: inline-flex;
    width: 781px;
    align-items: center;
    justify-content: space-between;
    gap: 28px;
  }

  .name-test {
    width: 217%;
  }

  .url-test {
    width: 257%;
  }

  .subtitle-test {
    color: #262e36;
    font-size: 14px;
    font-weight: 300;
  }

  .textarea-question {
    min-height: 40px;
    background: ${colors.white};
    border: 1px solid ${colors.greyAlpha50};
    box-sizing: border-box;
    border-radius: 6px;
    padding: 5px 10px;
    font-weight: normal;
    font-size: 12px;
    color: ${colors.black};
    outline: none;
    resize: none;
    height: auto;
    overflow-y: auto;

    ::placeholder {
      font-weight: normal;
      font-size: 12px;
      color: #9999b3;
    }

    width: 212% !important;
  }

  .container-add-question {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .wrapper-content-buttons {
    display: inline-flex;
    justify-content: center;
    gap: 12px;
    margin-top: 20px;
  }

  .container-questions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .wrapper-icons-question {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
  }

  .container-icon-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .container-icon-check {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .wrapper-select-template,
  .wrapper-time-question {
    .MuiInputBase-input::-webkit-input-placeholder {
      color: #9999b3;
      opacity: 1;
    }
  }

  .wrapper-time-question {
    .MuiInputBase-root {
      height: 46px;
    }
    .MuiInputBase-input {
      font-size: 12px;
    }
  }

  .MuiAutocomplete-popupIndicator {
    top: 3px;
  }

  .MuiInputBase-input {
    font-size: 14px;
    font-family: Poppins;
  }

  .alert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
  }

  .MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;
  }
`
export default EvaluativeTestsCreateStyled
