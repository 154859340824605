  import React, { useEffect, useState } from 'react'
  import compose from 'recompose/compose'
  import { withI18n } from '../../hocs'
  import Grid from '@material-ui/core/Grid'
  import { ModalStyled } from './styled'
  import Modal from '@material-ui/core/Modal'
  import { ModalHeaderProcess, RegularButton, CustomAlert } from '../../atoms'
  import InputField from '../../atoms/input-field'

  const customStyles = {
    overflow: 'scroll'
  }

  const ModalConfirmLogin = ({ open, handleClose, i18n, auth, isDisabledButton, onSubmit, onVerificationSuccess, setIsPasswordValidated }) => {

    const [showAlert, setShowAlert] = useState(false)
    const [alert, setAlert] = useState({ message: '', severity: '' });
    const [password, setPassword] = useState('');


    useEffect(() => {
      if (alert.message) {
        setShowAlert(true);
        const timer = setTimeout(() => {
          setShowAlert(false);
          setAlert({ message: '', severity: '' });
        }, 3000);
        return () => clearTimeout(timer);
      }
    }, [alert]);

    const handleModalClose = () => {
      handleClose();    
    };

    const handleVerifiedPassword = async (e) => {
      e.preventDefault();

      try {
        const response = await onSubmit({ email: auth.email, password });
        setAlert({ message: i18n.keywords_rpa.successPasswordVerified, severity: 'success' });
        setTimeout(() => {
          setIsPasswordValidated(prevValue => {
            onVerificationSuccess(!prevValue);        
            return !prevValue;
          });
        }, 2000);
      } catch (error) {
        setAlert({ message: i18n.keywords_rpa.errorPasswordIncorrect, severity: 'error' });
        setShowAlert(true);
        console.error('Error al validar contraseña:', error);    
      }
    };

    return (
      <>
        <Modal
          style={customStyles}
          className="modal-wrapper"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleModalClose}
        >
          <ModalStyled>
            <ModalHeaderProcess closeClick={handleModalClose} hasCloseIcon={true} />
            <div className="modal-body">
              <form>
                <Grid container alignItems="center" justifyContent='center'>
                  <h4>{i18n.keywords_rpa.verifyPasswordUserPlatformToEditeIntegration}</h4>

                  <form autocomplete="off">
                    <input autocomplete="false" name="hidden" type="text" style={{'display': 'none'}}></input>
                    <label>{i18n.keywords_rpa.emailLabel}</label>
                    <InputField
                      name='email'
                      className='disabled-input'
                      placeholder={auth.email}
                      value={auth.email}
                      type='text' 
                      autocomplete="false"
                    />
                    <label>{i18n.keywords_rpa.passwordLabel}</label>
                    <InputField
                      name='password'
                      placeholder={i18n.keywords_rpa.placeholderPassword}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type='password'  
                      autocomplete="false"                        
                    />
                  </form>
                </Grid>
                <div className="button-wrapper">
                  <RegularButton
                    className="button-custom"
                    title={i18n.keywords_rpa.verifyButton}
                    onClick={handleVerifiedPassword}
                    disabled={isDisabledButton}
                  />
                </div>
              </form>
              <div className="loading-container">
                {showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
              </div>
            </div>
          </ModalStyled>
        </Modal>
      </>
    )
  }

  const enharce = compose(withI18n)

  export default enharce(ModalConfirmLogin)