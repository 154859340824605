import React from 'react'
import PropTypes from 'prop-types'
import {AppBarInbox, OnboardingProfileInfo, ProcessTable} from '../../organism'
import {MODULES} from '../../../config/constants'

const Favorites = ({ classes, i18n }) => {
  return (
    <div className={classes.root}>
        <AppBarInbox
          showActions
            no_filterName={i18n.processesTable.filterProcesses}
            module={MODULES.ID_ON_BOARDING}
            section={"favorites"}
        />
      <ProcessTable
        title={i18n.favoritesOnboardingTable.title}
        module={MODULES.ID_ON_BOARDING}
        defaultFilters={{ isFavorite: true }}
        rowViewRender={id => <OnboardingProfileInfo id={id} />}
      />
    </div>
  )
}

Favorites.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default Favorites
