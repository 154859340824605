import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import { QUERY_KEYS } from '../../config/constants'
import { useRef } from 'react';
import {isObject} from "lodash";

async function getRpaClient(id) {
  return await get(`/api/rpa/${id}`)
}

export function useRpaIntegrations(id) {
  return useQuery([QUERY_KEYS.RPA_INTEGRATIONS, id], () => getRpaClient(id));
}

async function getRpaCandidatures(id) {
  return await get(`/api/rpa/${id}/candidatures`)
}

export function useRpaCandidatures(id) {
  return useQuery([QUERY_KEYS.RPA_CANDIDATURES, id], () =>( id ? getRpaCandidatures(id): null));
}

async function getRpaKeyword(clientId, candidatureId, provider) {
  return await get(`/api/rpa/${clientId}/${candidatureId}/${provider}/keyword`);
}

export function useRpaKeyword(clientId, candidatureId, provider, keywordRequested) {
  provider = isObject(provider) ? provider.default : provider;
  return useQuery(
    [QUERY_KEYS.RPA_KEYWORD, clientId, candidatureId, provider],
    () => (clientId && candidatureId && provider ? getRpaKeyword(clientId, candidatureId, provider) : null),
    {
      enabled: keywordRequested,
    }
  );
}

async function getRpaCredentialsOptions() {
  return await get('/api/rpa_credentials_options');
}

export function useRpaCredentialsOptions() {
  return useQuery([`options:${QUERY_KEYS.RPA_KEYWORD}`], getRpaCredentialsOptions);
}