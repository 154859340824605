import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const RichTextEditorStyled = styled.div`
  
    .ql-container.ql-snow, .ql-toolbar.ql-snow  {
      border: 0 !important;      
    }

    .ql-editor {
      min-height: 90px;
      background: ${colors.white};
      border: 1.5px solid ${colors.blackAlpha41}!important;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px 15px;
      font-size: 14px;
      color:  ${colors.black};
      outline: none;
      width: 100%;
      resize: none;
      font-family: Poppins, sans-serif;

      & p {
      font-weight: 400;
      color: #000 !important;
    }
      
      ${props =>
        props.fontSize === 'small' &&
        css`
          font-size: 14px;
          line-height: 21px;

          ::before {
            font-size: 14px;
            line-height: 21px;
          }
        `}
    }
    
    .ql-editor.ql-blank::before {    
        font-style: normal !important;
        color: ${colors.blackAlpha40};
      }

`

export default RichTextEditorStyled
