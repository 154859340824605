import React, { Component } from 'react'
import compose from 'recompose/compose'
import ProfileInfoStyled from './styled'
import { Module } from '../../molecules'
import Grid from '@material-ui/core/Grid'
import talent from '../../../assets/images/talent.png'
import boarding from '../../../assets/images/boarding.png'
import welcome from '../../../assets/images/welcome.png'
import employee from '../../../assets/images/employee.png'
import office from '../../../assets/images/office.png'
import surveys from '../../../assets/images/surveys.png'
import tutor from '../../../assets/images/tutor.png'
import interview from '../../../assets/images/interview.png'
import i18n from '../../../assets/i18n'

class Modules extends Component {
  render() {
    return (
      <ProfileInfoStyled>
        <Grid container spacing={4} className="modules-wrapper">
          <Grid item xs={12} sm={3}>
            <Module title={i18n.modules.module1} imageUrl={talent} isActive={true} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Module title={i18n.modules.module2} imageUrl={boarding} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Module title={i18n.modules.module3} imageUrl={welcome} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Module title={i18n.modules.module4} imageUrl={employee} isActive={true} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Module title={i18n.modules.module5} imageUrl={office} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Module title={i18n.modules.module6} imageUrl={surveys} isActive={true} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Module title={i18n.modules.module7} imageUrl={tutor} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Module title={i18n.modules.module8} imageUrl={interview} />
          </Grid>
        </Grid>
      </ProfileInfoStyled>
    )
  }
}

const enharce = compose()

export default enharce(Modules)
