import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import ModalHeaderProcessStyled from './styled'
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg'

const ModalHeaderProcess = ({ Icon, closeClick, title, hasCloseIcon, ...rest }) => {
  return (
    <ModalHeaderProcessStyled {...rest}>
      <div className="header-modal">
        {Icon}
        <p>{title}</p>
        <div className="close-icon">
          {hasCloseIcon ? <CloseIcon onClick={closeClick} /> : null}
        </div>
      </div>

    </ModalHeaderProcessStyled>
  )
}

ModalHeaderProcess.propTypes = {
  Icon: PropTypes.element,
  title: PropTypes.string,
  closeClick: PropTypes.func
}

const enharce = compose()

export default enharce(ModalHeaderProcess)
