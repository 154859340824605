import View from './view'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { withI18n } from '../../hocs'
import styles from './styles'
import {PushOffersOperations} from "../../../redux/offers";
import * as FiltersUtils from "../../../utils/filters";

const mapStateToProps = state => {
  return {
    data: state.offers.data,
    totalItems: state.offers.totalItems,
    pageSizeOptions: state.offers.pageSizeOptions,
    filters: state.inbox.inboxFilters,
    client: state.auth.client,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPushOffers: (module, range, filters, showMyPushOffers) => dispatch(PushOffersOperations.fetchPushOffers(module, range, filters, showMyPushOffers)),
    resetPushOffers: (module) => dispatch(PushOffersOperations.resetPushOffers(module)),
    parseFiltersFromApiToFrontFormat: (filtersInApiFormat) => dispatch(FiltersUtils.parseFiltersFromApiToFrontFormat(filtersInApiFormat)),
    dispatch
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withStyles(styles)
)

export default enharce(View)
