import React from 'react'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import ProfileSkillsStyled from './styled'
import {LinearPercentWithTitle } from '../../atoms'
import PropTypes from 'prop-types'

const ProfileSkills = skills => {
  let skill = _.map(skills.skills, (item, key) => {
    const value = _.get(item, 'value', '')
    const name = _.get(item, 'type.name', '')
    return (
      <Grid item key={key}>
        <LinearPercentWithTitle key={key} title={name} value={Math.round(value)} />
      </Grid>

    )
  })

  if (skills && skills.skills) {
    return (
      <ProfileSkillsStyled>
        <Grid id='profileSkillsGridContainer' container direction="column" justifyContent="space-between" spancing={2}>
          {skill}
        </Grid>

      </ProfileSkillsStyled>
    )
  } else {
    return null
  }
}

ProfileSkills.propTypes = {
  skills: PropTypes.array
}

export default ProfileSkills
