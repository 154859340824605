import _ from 'lodash'
import moment from 'moment'
import {
  resetData,
  setData,
  setDataFields,
  setInitialData,
  setIsFetching,
  setModule,
  setReady,
  setReportFields,
  setTagsTypes
} from './actions'
import fileDownload from 'js-file-download'
import {SaveSearchesActions, SaveSearchesOperations} from '../savesearches'

import 'core-js'
import {fetchInboxFilters} from "../inbox/operations";

export const initInbox = (filters, range, sort, module, filtersGroups) => (dispatch, getState, { api }) => {
    let newFilters = {...filters}
    setReady(false)
    if(getState().exitInterview.module && getState().exitInterview.module !== module){
      dispatch(SaveSearchesOperations.setFilters({}))
      dispatch(SaveSearchesOperations.setAuxFilters({}))
      dispatch(SaveSearchesOperations.setSearchFilters({}))
      dispatch(SaveSearchesActions.setFilterGroup(-1))
      newFilters = {}
      dispatch(resetData())

    }else{
      dispatch(setData({data:[], total: 0}))
    }
    dispatch(setIsFetching(true))
    if(filtersGroups) dispatch(fetchFilterGroupInbox(filtersGroups, module))
    Promise.all([
      api.getInboxHeaders(module), 
      _.get(newFilters, 'isFavorite')?api.getFavorites(newFilters, range, sort, module):api.getInboxData(newFilters, range, sort, module),
      api.getReportFields(module),
      api.getTagsTypes(module),
    ],
    )
    // .then(([fields, status, data, reportFields, tags]) => {
    .then(([fields, data, reportFields, tags]) => {
      dispatch(setModule(module))
      dispatch(setDataFields(fields))
      // dispatch(setStatusTypes(status))
      dispatch(setTagsTypes(tags))
      dispatch(setData(data))
      dispatch(setReportFields(reportFields))
      dispatch(setReady(true))
    })
    .finally(() => {
      dispatch(setIsFetching(false))
    })
}

export const fetchItemsInbox = (filters, range, sort, module, filtersGroups) => (dispatch, getState, { api }) => {
  dispatch(setData({data:[], total: 0}))
  dispatch(setIsFetching(true))
  const fetchData = _.get(filters, 'isFavorite')?api.getFavorites:api.getInboxData
  if(filtersGroups) dispatch(fetchFilterGroupInbox(filtersGroups, module))
  return fetchData(filters, range, sort, module)
    .then(response => {
      dispatch(setData(response))
    })
    .finally(() => dispatch(setIsFetching(false)))
}

export const fetchFilterGroupInbox = (filters, module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  const fetchData = _.get(filters, 'isFavorite')?api.getFavorites:api.getInboxData
  return fetchData(filters, [0,4], ["",""], module)
    .then(response => {
      dispatch(setInitialData(response))
    })
    .finally(() => dispatch(setIsFetching(false)))
}

export const fetchProcessesFields = (module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  api
    .getInboxHeaders(module)
    .then(response => {
      dispatch(setDataFields(response))
    })
    .finally(() => dispatch(setIsFetching(false)))
}

export const postFavorite = (ids, isFavorite, queryParams, module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  const { exitInterview } = getState()
    const newDataInbox = { ...exitInterview.dataInbox }
    _.map(ids, (id) => {
      newDataInbox.data[_.findIndex(newDataInbox.data, {id})].is_favorite	= isFavorite
    })
    dispatch(setData(newDataInbox))
    const fetchFav = isFavorite?api.postFavorites:api.deleteFavorites
    fetchFav(ids, module)
    .then(() => {
      const { filters, range, sort } = queryParams
      const fetchData = _.get(filters, 'isFavorite')?api.getFavorites:api.getInboxData
      fetchData(filters, range, sort, module)
      .then(response => {
        dispatch(setData(response))
      })
      .finally(() => dispatch(setIsFetching(false)))
    })
}

export const markAsRead = (ids, queryParams, module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  const { exitInterview } = getState()
    const newDataInbox = { ...exitInterview.dataInbox }
    _.map(ids, (id) => {
      newDataInbox.data[_.findIndex(newDataInbox.data, {id})].was_read	= true
    })
    dispatch(setData(newDataInbox))
  api.postReads(ids, module)
  .then(() => {
    const { filters, range, sort } = queryParams
    dispatch(fetchItemsInbox(filters, range, sort, module))
  })
  .finally(() => {
    dispatch(setIsFetching(false))
  })
}

export const unmarkAsRead = (ids, queryParams, module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  const { exitInterview } = getState()
    const newDataInbox = { ...exitInterview.dataInbox }
    _.map(ids, (id) => {
      newDataInbox.data[_.findIndex(newDataInbox.data, {id})].was_read	= false
    })
    dispatch(setData(newDataInbox))
  api.deleteReads(ids, module)
  .then(() => {
    const { filters, range, sort } = queryParams
    dispatch(fetchItemsInbox(filters, range, sort, module))
  })
  .finally(() => {
    dispatch(setIsFetching(false))
  })
}

export const changeTags = (ids, tags, queryParams, module, filterGroups) => (dispatch, getState, { api, handleError }) => {

  dispatch(setIsFetching(true))
  return api
    .changeTags(ids, tags, module)
    .then(() => {
      const { filters, range, sort } = queryParams
      dispatch(fetchInboxFilters(module))
      dispatch(fetchItemsInbox(filters, range, sort, module, filterGroups))
    }).then()
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(dispatch(setIsFetching(false))))
}

export const exportCsv = (fields, filters, sort, module) => (dispatch, getState, { api }) => {
  return api.getInboxCsv(fields, filters, sort, module).then(response => {
    const date = moment().format('DD-MM-YYYY')
    fileDownload(new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${module}_${date}.xlsx`)
  })
}
