import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withI18n } from '../../hocs'
import { ReportsOperations } from '../../../redux/reports'

const mapStateToProps = state => ({
  reportFields: state.reports.fields,
  total: state.reports.total
})

const mapDispatchToProps = dispatch => ({
  fetchReportFields: (module) => dispatch(ReportsOperations.fetchReportFields(module)),
  postReport: (data, module) => dispatch(ReportsOperations.postReport(data, module)),
  deleteReports: (ids, module) => dispatch(ReportsOperations.deleteReports(ids, module)),
  exportReports: (ids, module) => dispatch(ReportsOperations.exportReports(ids, module))
})

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n
)

export default enharce(View)
