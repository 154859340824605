import React from 'react'

const icon = props => (
  <svg {...props} width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.2083 22.2762H7.79223C6.12512 22.2762 4.78418 20.9522 4.78418 19.3061V7.71163C4.78418 6.06551 6.12512 4.74146 7.79223 4.74146H19.2265C20.8936 4.74146 22.2345 6.06551 22.2345 7.71163V19.3061C22.2345 20.9522 20.8755 22.2762 19.2083 22.2762Z" stroke={props.color} stroke-width="2" stroke-miterlimit="10"/>
      <path d="M18.085 1.30615V4.72365" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.5908 1.30615V4.72365" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.09668 1.30615V4.72365" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.085 22.2764V25.676" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.5908 22.2764V25.676" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.09668 22.2764V25.676" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.2344 9.05371H25.6773" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.2344 13.491H25.6773" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.2344 17.9106H25.6773" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.32324 9.05371H4.78432" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.32324 13.491H4.78432" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.32324 17.9106H4.78432" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.1377 16.3359L15.8196 10.646L18.4833 16.3359" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.27832 10.646V16.3359" stroke={props.color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )

export default icon
