import { createStyles } from '@material-ui/core/styles'

export default theme =>
  createStyles({
    '@global': {
      body: {
        backgroundColor: theme.colors.pageBackground,
        minWidth: 320
      }
    },

    root: {
      width: '100%',
      padding: '30px 60px'
    },

    logOut: {
      display: 'flex',
      justifyContent: 'center'
    },

    paper: {
      padding: 20,
      borderRadius: 10,
      flexDirection: 'row',
      display: 'flex',
      margin: 20,

      '& form': {
        '& button': {
          // marginTop: 20,
          margin: '0 auto',
          // display: 'block',
        }
      }
    },

    avatarContainer: {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },

    avatar: {
      margin: 40,
      display: 'flex',
      alignSelf: 'center',
      position: 'relative',
      cursor: 'pointer',

      '& img': {
        width: 200,
        height: 200,
        borderRadius: '50%',
        objectFit: 'cover',
        border: `4px solid ${theme.colors.primary}`,
        padding: 6,
        background: 'white'
      },

      '& div': {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        background: 'transparent',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0,

        '&:hover': {
          opacity: 1,
          background: theme.colors.blueAlpha
        }
      }
    },

    avatarEdit: {
      color: 'white'
    },

    avatarPlaceholder: {
      backgroundColor: 'red',
      width: 200,
      height: 200
    },

    autocomplete: {
      width: '100%',
      marginTop: '16px',
      marginBottom: '8px',     
    },

    button: {
      display: 'flex',
      marginTop: '10px',
    },
  })
