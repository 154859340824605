import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import { QUERY_KEYS } from '../../config/constants'

async function getRequirements(id) {
  if (!id) return
  return await get(`/api/vacancy/candidature-settings/${id}/requirements`)
}

export function useRequirements(id) {
  const query = useQuery([QUERY_KEYS.SELFUSER, id], () => getRequirements(id))
  return {
    ...query,
    refetch: query.refetch,
    isLoading: query.isLoading,
    isFetching: query.isFetching
  }
}
