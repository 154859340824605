import * as types from './types'

export const setIsFetching = isFetching => ({
  type: types.SET_IS_FETCHING,
  payload: isFetching
})

export const setData = data => ({
  type: types.SET_DATA,
  payload: data
})

export const setResponsible = value => ({
  type: types.SET_RESPONSIBLE,
  payload: value
})

export const setPage = page => ({
  type: types.SET_PAGE,
  payload: page
})

export const setSort = sort => ({
  type: types.SET_SORT,
  payload: sort
})

export const setPageSize = pageSize => ({
  type: types.SET_PAGE_SIZE,
  payload: pageSize
})

export const setReportFields = data => ({
  type: types.SET_REPORT_FIELDS,
  payload: data
})

export const setRowsSelected = rowsSelected => ({
  type: types.SET_ROWS_SELECTED,
  payload: rowsSelected
})

export const setEditCounter = counter => ({
  type: types.SET_EDIT_COUNTER,
  payload: counter
})

export const setWhatsappCounter = ( counter ) => ({
  type: types.SET_WHATSAPP_COUNTER,
  payload: counter
});

export const setMeetingDetails = ( data ) => ({
    type: types.SET_MEETING_DETAILS,
    payload: data
});
