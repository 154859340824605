import React, {useState} from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import BoxOtherInfoStyled from './styled'
import _ from 'lodash'
import {toast} from 'react-toastify'
import {RegularButton} from "../../atoms";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import {updateCandidature} from "../../../api";
import CachedIcon from "@material-ui/icons/Cached";

const BoxEditableInfo = ({ editableFields, candidatureId, client, dni, candidatureConfig, i18n }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    dni: dni,
    candidature_id: candidatureId,
    candidatura_seleccionada: candidatureConfig
  });

  if (!editableFields) {
    return null
  }
  if (typeof editableFields == 'object' && !Array.isArray(editableFields)) {
    editableFields = [editableFields];
  }

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsSubmitting = () => setIsSubmitting(!isSubmitting);

  const updateFormData = (remoteName, value) => {
    setFormData({...formData, [remoteName]: value});
  };

  const rendeItem = (key) => {
    const item = editableFields[key]

    return (
      <div className="editable_item_container">
          <p className={'title'}>{item.name}</p>
          {item.options.length > 0 ?
            <div style={{width: 180}}>
              <Select
                key={item.id}
                defaultValue={_.find(item.options, {value: item.value})}
                isMulti={false}
                options={item.options}
                isSearchable={false}
                isClearable={false}
                placeholder={i18n.modalFilter.select}
                isDisabled={!isEditing}
                onChange={val=> {updateFormData(item.remote_name, val.value)}}
              />
            </div>
            : <TextField key={item.id} disabled={!isEditing} defaultValue={item.value} onChange={target=> {updateFormData(item.remote_name, target.target.value)}}/>
          }
      </div>
    )
  }

  const editableKeys = [];
  for(const key in editableFields){
    if(editableFields[key]){
      editableKeys.push(key);
    }
  }

  const handleSubmit = () => {
    toggleIsEditing();
    toggleIsSubmitting();

    updateCandidature(formData, client).then(() => {

      toggleIsSubmitting();
      toast.success(i18n.boxEditableInfo.success);
      window.location.href = window.location.pathname;

    }).catch(() => {
      toggleIsSubmitting();
      toast.error(i18n.boxEditableInfo.error);
    });
  }

  return (
    <BoxOtherInfoStyled>
      <form onSubmit={handleSubmit}>
        <div className="header-table">
          <p style={{display: "inline-block"}}>
            {i18n.boxEditableInfo.title}
          </p>
          <RegularButton
            title={i18n.boxEditableInfo.edit}
            disabled={isEditing}
            type="button"
            style={{display: (!isEditing ? "inline-block" : "none"), padding: '5px 18px', margin: '0 8px'}}
            onClick={toggleIsEditing}/>
          <div style={{display: (isEditing ? "inline-block" : "none")}}>
            <RegularButton
              title={i18n.boxEditableInfo.cancel}
              color={'#b73838'}
              disabled={!isEditing}
              type="button"
              style={{display: 'inline-block', padding: '5px 18px', margin: '0 8px'}}
              onClick={toggleIsEditing}/>
            <RegularButton
              title={i18n.boxEditableInfo.accept}
              color={'#56803b'}
              disabled={!isEditing}
              type="button"
              style={{display: 'inline-block', padding: '5px 18px', margin: '0 8px'}}
              onClick={handleSubmit}
            />
          </div>
          <CachedIcon className={isSubmitting ? 'rotate' : 'hide'}/>
        </div>

        <Grid container rowSpacing={8} id='boxEditableContainer' lg={12} direction='row'>
            {editableKeys.map(rendeItem)}
        </Grid>
      </form>
    </BoxOtherInfoStyled>
  )
}

BoxEditableInfo.propTypes = {
  i18n: PropTypes.object.isRequired,
  editableFields: PropTypes.object
}

const enharce = compose(withI18n)

export default enharce(BoxEditableInfo)
