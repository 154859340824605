import styled from 'styled-components'

export const ContentBox = styled.div`
  .dzu-dropzone {
    overflow: hidden;
  }
  .dzu-input {
    display: none;
  }
  .dzu-previewContainer {
    padding: 20px 3%;
  }
  .dzu-previewImage {
    max-width: 240px;
    max-height: 80px;
  }
  .dzu-previewFileName {
    font-weight: 700;
  }
  .sizeInfo {
    font-weight: 700;
    margin-right: 10px;
  }
  .statusInfo {
    margin: 0 0 2px 10px;
    font-weight: 700;
  }
`
export const InfoContainer = styled.div``

export const Text = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  text-align: left;
  color: rgb(102, 102, 102);
  margin-bottom: 20px;
  &.bold {
    color: black;
  }
  .bold {
    color: black;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.align-center {
    text-align: center;
  }
`

export const Warning = styled.div`
  color: #000000;
  font-size: 18px;
  margin-top: 1em;
  font-family: Poppins, sans-serif;
  font-weight: 400;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 20px 0;
`

export const ButtonContainer = styled.div`
  margin: 10px 0;
  width: 100%;
  button {
    width: 100%;
  }
`
