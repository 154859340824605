import React from 'react';
import EvaluativeTestsCreate from '../evaluative-tests-create/view';
import { useEvaluativeTest } from '../../../api/queries/useEvaluativeTests';
import { useAppContext } from '../../../context/appContext'
import { useParams } from 'react-router-dom'

const EvaluativeTestsEdit= ({ i18n, history, isEditMode }) => {
  const { testId } = useParams();

  const { module } = useAppContext();
  const { data: evaluativeTest } = useEvaluativeTest(module, testId);

  return <EvaluativeTestsCreate
  i18n={i18n}
  history={history}
  initialData={evaluativeTest}
  isEditMode={isEditMode} />;
};

export default EvaluativeTestsEdit;
