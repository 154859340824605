import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import {QUERY_KEYS} from '../../config/constants'

async function getIaEvaluationCriteria() {
  const response = await get(`/api/evaluation_criteria`);

  return response.data;
}

export function useEvaluationCriteria() {
  return useQuery([`${QUERY_KEYS.IA_EVALUATION_CRITERIA}`], getIaEvaluationCriteria);
}
