import { ROLES } from '../config/constants'
import { AuthUtils } from '../redux/auth'

const Feature = {
  PushOffers: ROLES.ROLE_FEATURE_push_offer,
  PullOffers: ROLES.ROLE_FEATURE_pull_config,
  FunnelStates: ROLES.ROLE_FEATURE_phases_status,
  DocumentRequest: ROLES.ROLE_FEATURE_doc_request,
  EditableFields: ROLES.ROLE_FEATURE_edit_fields,
  VideoInterview: ROLES.ROLE_FEATURE_videointerview,
  VacancyRequest: ROLES.ROLE_FEATURE_vacancy_request,
  JobOffer: ROLES.ROLE_FEATURE_job_offer,
}

const clientHasFeature = feature => {
  return AuthUtils.hasRole(feature)
}

export const FeatureGuard = {
  canAccessToFunnelStatus: () => clientHasFeature(Feature.FunnelStates),
  canAccessToPushOffers: () => clientHasFeature(Feature.PushOffers),
  canAccessToPullOffers: () => clientHasFeature(Feature.PullOffers),
  canAccessToDocumentRequest: () => clientHasFeature(Feature.DocumentRequest),
  canAccessToEditableFields: () => clientHasFeature(Feature.EditableFields),
  canAccessToVideoInterview: () => clientHasFeature(Feature.VideoInterview),
  canAccessToVacancyRequest: () => clientHasFeature(Feature.VacancyRequest),
  canAccessToJobOffers: () => clientHasFeature(Feature.JobOffer),
}
