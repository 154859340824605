import {actions} from '.'
import 'core-js'
import {toast} from 'react-toastify'
import {MODULES} from "../../config/constants";

export const initOnboarding = id => (dispatch) => {
  dispatch(actions.resetData())
  dispatch(fetchOnboarding(id))
}

export const fetchOnboarding = id => (dispatch, getState, { api }) => {
  dispatch(actions.setIsFetching(true))
  api.getInboxItem(id, MODULES.ID_ON_BOARDING).then(response => {
    dispatch(actions.setData(response))
  })
  dispatch(actions.setIsFetching(false))
}

export const addFav = (id, isFav, module) => (dispatch, getState, { api }) => {
  if (isFav) {
    const newData = { ...getState().profileOnboarding.data, is_favorite: true }
    dispatch(actions.setData(newData))
    api
      .postFavorites([id], module)
      .then(() => {
        dispatch(fetchOnboarding(id))
        toast('Modificado onboarding', { type: toast.TYPE.SUCCESS })
      })
      .finally(() => {
        dispatch(actions.setIsFetching(false))
      })
  } else {
    const newData = { ...getState().profileOnboarding.data, is_favorite: false }
    dispatch(actions.setData(newData))
    api
      .deleteFavorites([id], module)
      .then(() => {
        dispatch(fetchOnboarding(id))
        toast('Modificado proceso', { type: toast.TYPE.SUCCESS })
      })
      .finally(() => {
        dispatch(actions.setIsFetching(false))
      })
  }
}

export const markAsRead = id => (dispatch, getState, { api }) => {
  api.postReads([id], MODULES.ID_EMPLOYEE_LINE)
}

export const deleteOnboarding = (id, module) => (dispatch, getState, { api, handleError }) => {
  dispatch(actions.setIsFetching(true))
  return api
    .deleteInboxCandidature(id, module)
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(actions.setIsFetching(false)))
}

