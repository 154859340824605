import React from 'react'
import PropTypes from 'prop-types'
import {AppBarInbox, ProcessTable, ProfileFeedbackInfo} from '../../organism'
import {MODULES} from "../../../config/constants";

const Favorites = ({ classes, i18n }) => {
  let queryFilters = {isFavorite: true}

  return (
    <div className={classes.root}>
        <AppBarInbox
            showActions
            module={MODULES.ID_FEEDBACK}
            section={"favorites"}
        />
      <ProcessTable  
        title={i18n.favorites.title}
        module={MODULES.ID_FEEDBACK}
        defaultFilters={queryFilters}
        rowViewRender={(id) => <ProfileFeedbackInfo id={id} />}
        showChangeStatus
      />
    </div>
  )
}

Favorites.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
}

export default Favorites
