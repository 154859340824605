import React from 'react'

const icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M2.66492 12.5932C2.51794 12.2115 2.51794 11.7885 2.66492 11.4068C4.10837 7.65919 7.74362 5 12 5C16.2563 5 19.8916 7.6592 21.335 11.4069C21.482 11.7885 21.482 12.2116 21.335 12.5932C19.8916 16.3408 16.2563 19 12 19C7.74362 19 4.10837 16.3408 2.66492 12.5932Z" stroke="#141C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#141C24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default icon
