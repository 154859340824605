import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withStyles, withTheme} from '@material-ui/core/styles'
import {withI18n} from '../../hocs'
import styles from './styles'

const mapStateToProps = state => ({
  client: state.auth.client,
  token: state.auth.token,
  auth: state.auth,
  user_authorized_sections: state.auth && state.auth.hasOwnProperty('data')
    && state.auth.data.hasOwnProperty('user_authorized_sections') ? state.auth.data.user_authorized_sections : [],
})

const mapDispatchToProps = () => {
  return {}
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withTheme,
  withStyles(styles)
)

export default enharce(View)
