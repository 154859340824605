import React from 'react'
import compose from 'recompose/compose'
import * as S from './styled'
import PropTypes from 'prop-types'

const Panel = ({ icon, title, children, color, headerRight }) => {


  return (
    <S.Container>
      {(icon || title) && 
        <S.Header color={color}>
          {icon}
          <S.HeaderContent>
            <S.Title>{title}</S.Title>
            {headerRight && <S.ContentRight>{headerRight}</S.ContentRight>}
          </S.HeaderContent>
        </S.Header> }
      <S.Content>
        {children}
      </S.Content>
    </S.Container>
  )
}

Panel.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
  color: PropTypes.string,
  headerRight: PropTypes.node,
}

Panel.defaultValues = {
  title: '',
  icon: undefined,
  children: undefined,
  color: undefined,
  headerRight: undefined,
}

const enharce = compose()

export default enharce(Panel)
