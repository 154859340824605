import styled from 'styled-components'
import colors from '../../../assets/colors'

const ColorPickerStyled = styled.div`
  .containerColorPicker {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 10px !important;
    width: 100% !important;
    border: 1.5px solid ${colors.blackAlpha41} !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    padding: 6px 10px;
    position: relative;
  }

  .color-picker-container {
    position: relative !important;
  }

  .color-picker-button {
    cursor: pointer;
  }
  .color-picker-box {
    border: none;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    padding: 10px;
    transition: background-color 0.3s ease;
  }
  .swatches-picker {
    width: auto !important;
  }

  .swatches-picker-container {
    position: absolute !important;
    top: 40px !important;
    z-index: 1;
  }

  .color-code-text {
    font-size: 14px;
    font-weight: 400;
    color: #7a808d;
  }
`

export default ColorPickerStyled
