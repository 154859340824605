import styled from 'styled-components'
import { chartWrapperStyles, headerStatStyles } from '../dynamic-widget/styled'

const HereMapsStatisticStyled = styled.div`
  && {
    padding: 30px 0;
    min-width: 200px;
    height: auto;
  }
  
  ${headerStatStyles}
  
  ${chartWrapperStyles}
  
  .chart-wrapper {
    padding: 0;
    overflow: hidden;
  }
`

export default HereMapsStatisticStyled
