import React from 'react'
import PropTypes from 'prop-types'
import { MTVideoStyled } from './styled'
import { ReactComponent as VideoIcon } from '../../../assets/images/video.svg'

const MTVideo = ({ videoUrl }) => {
  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
    window.open(videoUrl, '_blank')
  }

  return (
    <MTVideoStyled>
      {videoUrl && (
        <a href={videoUrl} onClick={handleClick} download>
          <VideoIcon />
        </a>
      )}
    </MTVideoStyled>
  )
}

MTVideo.propTypes = {
  videoUrl: PropTypes.string
}

export default MTVideo
