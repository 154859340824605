import React from 'react'
import { PageTitle } from '../../atoms'
import { Grid } from '@material-ui/core'
import UserManualStyled from './styled'

const OnboardingUserManual = ({ i18n }) => {

  return (
    <UserManualStyled>
      <Grid container className="title-container">
        <Grid item xs={12} sm={6} className="title">
          <PageTitle title={i18n.userManual.title} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>  
          <iframe
            className="iframe-container"
            title="Manual de uso Onboarding"
            src="https://scribehow.com/page/Manual_de_usuario_Onboarding__ynIEJ7dDRFqBaxz8EU7RZA"
          ></iframe>      
      </Grid>
    </UserManualStyled>
  )
}

export default OnboardingUserManual
