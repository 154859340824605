import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import {ProfileOperations} from '../../../redux/profile'
import {FavoritesOperations} from '../../../redux/favorites'
import {ReadsOperations} from '../../../redux/reads'
import { ProcessesOperations } from '../../../redux/processes'
import { InboxOperations } from '../../../redux/inbox'

const mapStateToProps = state => {
  return {
    profileData: state.profile.data,
    profileMultipleData: state.profile.multipleData,
    isFetching: state.profile.isFetching,
    isFetchingObservations: state.profile.isFetchingObservations,
    isFetchingPushOffers: state.profile.isFetchingPushOffers,
    isFetchingUserList: state.profile.isFetchingUserList,
    favorites: state.favorites.data,
    observations: state.profile.observations,
    pushOffers: state.profile.pushOffers,
    tagsTypes: state.processes.tagsTypes,
    user: state.auth.data,
    client: state.auth ? state.auth.client : null,
    citationsData: state.citations.data,
    totalProcesses: state.processes && state.processes.total && state.processes.total.total ? state.processes.total.total : 0,
    totalPlanning: state.citations && state.citations.data && state.citations.data.total ? state.citations.data.total : 0
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initInbox: (filters, range, sort, module, filtersGroups) => dispatch(ProcessesOperations.initInbox(filters, range, sort, module, filtersGroups)),
    postReads: candidatureId => dispatch(ReadsOperations.postReads([candidatureId])),
    fetchUser: candidatureId => dispatch(ProfileOperations.fetchUser(candidatureId)),
    fetchUserList: candidatureIds => dispatch(ProfileOperations.fetchUserList(candidatureIds)),
    postFavorites: candidatureId => dispatch(FavoritesOperations.postFavorites(candidatureId)),
    deleteFavorites: candidatureId => dispatch(FavoritesOperations.deleteFavorites(candidatureId)),
    fetchObservations: candidatureId => dispatch(ProfileOperations.fetchObservations(candidatureId)),
    fetchPushOffers: candidatureId => dispatch(ProfileOperations.fetchPushOffers(candidatureId)),
    postObservations: (candidatureId, message) => dispatch(ProfileOperations.postObservations(candidatureId, message)),
    changeTags: (ids, status, date) => dispatch(InboxOperations.changeTags(ids, status, date)),
    fetchTagsTypes: ( module ) => dispatch(ProcessesOperations.refetchTagsTypes( module )),
    fetchStatusTypes: ( module ) => dispatch(ProcessesOperations.fetchStatusTypes( module ))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n
)

export default enharce(View)
