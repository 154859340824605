import React from 'react'
import { Provider } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { store, persistor } from '../../../config/redux'
import { BrowserRouter } from 'react-router-dom'
import Routes from '../routes'
import { PersistGate } from 'redux-persist/integration/react'

import {QueryClientProvider} from "@tanstack/react-query";
import queryClient from "../../../api/queryClient";
import {AppContextProvider} from "../../../context/appContext";

const App = () => (
  <AppContextProvider>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <>
              <Routes />
              <ToastContainer bodyClassName="grow-font-size" hideProgressBar={true} draggable={false} position={toast.POSITION.BOTTOM_CENTER} />
            </>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </AppContextProvider>
)

export default App
