import React, { Component } from 'react'
import compose from 'recompose/compose'
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider';
import { withI18n } from '../../hocs'
import { AppBarStyled, InputStyled } from './styled'
import { PageTitle } from '../../atoms'
import { FilterForm, ModalFilter, ModalSaveSearch, OfferInboxButton } from '../../molecules'
import moment from 'moment'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { ExportButton } from '../process-table/styled';
import routes from "../../../config/routes";
import { matchPath } from "react-router";
import { FeatureGuard } from '../../../utils/guard';

class AppBarInbox extends Component {
  constructor(props) {
    super(props)
    this.hasFeaturePush = FeatureGuard.canAccessToPushOffers()

    this.state = {
      openSaveModal: false,
      openFilterModal: false,
      titleSaveSearch: '',
      errorMsg: '',
      options: []
    }
  }

  componentDidMount() {
    this.props.fetchInboxFilters(this.props.module)
    this.props.fetchReportFields(this.props.module)
  }

  handleOpenSaveModal = () => this.setState({ openSaveModal: true })

  handleCloseSaveModal = () => this.setState({ openSaveModal: false })

  handleOpenFilterModal = () => this.setState({ openFilterModal: true })

  handleCloseFilterModal = () => this.setState({ openFilterModal: false })

  noOptionsMessage = () => this.props.i18n.appBar.noResults

  handleOnChangeTitle = value => {
    this.setState({ titleSaveSearch: value })
  }

  handleSubmitSave = event => {
    event.preventDefault()
    const date = moment().format('YYYY-MM-DD HH:mm:ss')
    const { titleSaveSearch } = this.state
    const { filters } = this.props
    const data = {
      title: titleSaveSearch,
      date: date,
      fields: filters
    }

    if (titleSaveSearch) {
      this.handleCloseSaveModal()
      this.props.postSaveSearches(data, this.props.module)
      this.props.history.push("/"+window.location.pathname.split("/")[1]+ "/saved-searches" + '?reload=1');
    } else {
      const bounced = _.debounce(() => this.setState({ errorMsg: '' }), 2000)
      this.setState(
        {
          errorMsg: this.props.i18n.appBar.errorNoTitle
        },
        bounced
      )
    }
  }

  handleOnChange = (value, index) => {
    this.props.handleOnChange(value, index)
  }

  renderModalSaveSearch = () => (
    <ModalSaveSearch
      handleOnChangeTitle={this.handleOnChangeTitle}
      handleSubmitSave={this.handleSubmitSave}
      open={this.state.openSaveModal}
      handleClose={this.handleCloseSaveModal}
      errorMsg={this.state.errorMsg}
    />
  )

  renderModalFilter = () => {
    if (this.props.inboxFilters) {
      return (
        <ModalFilter
          handleSearchClick={this.props.filtersOnSubmitHandle}
          open={this.state.openFilterModal}
          handleClose={this.handleCloseFilterModal}
          filterName={this.props.filterName}
        >
          <FilterForm module={this.props.module} />
        </ModalFilter>
      )
    }
  }

  renderSerachOptions = options => {

    let selectOptions = {}

    //Modifica los estilos del select de los filtros. EL primero quita las x de cada elemento, y el segundo la x general
    if (!this.props.canEditFilters) {
      selectOptions.styles = { multiValueRemove: () => ({ display: 'none' }), indicatorsContainer: () => ({ display: 'none'}) }
    }

    return (
      <>
        <InputStyled className="selectContainer">
          <Select
            value={options}
            isMulti
            isSearchable={false}
            isClearable={true}
            placeholder={this.props.i18n.appBar.filters}
            onChange={this.props.filtersOnSelectHandle}
            components={{ DropdownIndicator }}
            menuIsOpen={false}
            filterOption={(options, filter) => options.keywords.indexOf(filter.toLowerCase()) >= 0}
            {...selectOptions}
          />
        </InputStyled>
        {this.props.saveSearchOption && !this.props.isExitInterview && <Button variant="contained" className="saveButton" onClick={this.handleOpenSaveModal}>
          {this.props.i18n.appBar.saveSearchs}
        </Button>}
        {this.props.canCloneSearch && <Button variant="contained" className="saveButton" onClick={this.onClickCloneSearch}>
          {this.props.i18n.appBar.cloneSearch}
        </Button>}
      </>
    )
  }

  onClickCloneSearch = () => {
    this.props.history.push(`/${this.props.module}/inbox?keepFilters=1`)
  }

  renderSearch = () => {
    const { canEditFilters } = this.props;
    const isSaveSearchItemPage = matchPath(this.props.match.path, {path: routes.savedSearchesItem});

    let filtersCopy;

    let options = [];

    if( !isSaveSearchItemPage ){
      filtersCopy = _.cloneDeep( this.props.filtersInState );
    } else {
      filtersCopy = _.cloneDeep( this.props.filters );
    }

    _.map(filtersCopy, (label) => {
      _.map(label, (obj) => {
        obj.label = (obj.filter && obj.filter.name ? obj.filter.name : obj.name) + ': ' + obj.label
      })
      options = _.concat(options, label);
    })

    return (
        this.props.section !== 'offerCard' &&
        <>
          {canEditFilters && <Button variant="contained" className="filterButton" onClick={this.handleOpenFilterModal}>
            {this.props.filterName}
          </Button>}

          {options.length > 0 ? this.renderSerachOptions(options) : null}
        </>
    )
  }

  numberWithDots = x => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") :  '0';
  };


  render() {
    const { showActions, title, total, forceTotal, module, reportFields, filters, defaultFilters, queryOptions, rowsSelected, tableSort, location, isSaveSearchItem } = this.props
    const newFilters = { ...defaultFilters, ...filters }
    const sort = queryOptions ? queryOptions.sort : tableSort

    return (
      <AppBarStyled className="no-print">
        <Grid container justifyContent="space-between">

          <Grid item xs={12} sm={6}>
            <PageTitle title={title} />
            {!matchPath(location.pathname, {path: routes.savedSearchesItem}) && <span id={'total'}>({this.numberWithDots("forceTotal" in this.props? forceTotal : total)})</span>}
          </Grid>

          {showActions ? (<Grid className={'filterButtonDiv'} item xs={12} sm={6}>
            { ['favorites'].includes(this.props.section) === false && this.renderSearch() }
            {this.hasFeaturePush && (['savedSearches', 'planning', 'favorites', 'pullConfig'].includes(this.props.section) === false || (this.props.section === 'savedSearches' && rowsSelected.length > 0 )) && !matchPath(location.pathname, {path: routes.offerCandidatures}) && module === 'talent_acquisition' && <OfferInboxButton
                filters={newFilters}
                sort={sort}
                ids={_.map(rowsSelected, (item) => item.id)}
                sms={this.props.client ? this.props.client.sms_available : false}
            />}
              {((this.props.section === 'savedSearches' && isSaveSearchItem === 1) || ['savedSearches', 'pullConfig'].includes(this.props.section) === false) && <ExportButton
              module={module}
              fields={reportFields}
              filters={newFilters}
              sort={sort}
              ids={_.map(rowsSelected, (item) => item.id)}
              totalRows={total}
              offerFilters={this.props.offerFilters}
            />}
          </Grid>) : null}

        </Grid>
        {this.renderModalFilter()}
        {this.renderModalSaveSearch()}
        <Divider variant="middle" />
      </AppBarStyled>
    )
  }
}

AppBarInbox.defaultProps = {
  showActions: false,
  saveSearchOption: true,
  canEditFilters: true,
  canCloneSearch: false,
  title: null
}

const DropdownIndicator = () => null

const enharce = compose(withI18n)

export default enharce(AppBarInbox)
