import styled from 'styled-components'
import colors from '../../../assets/colors'

const LinearPercentStyled = styled.div`
  @media print {
    .MuiLinearProgress-root {
      background: ${colors.greyAlpha25}!important;
      -webkit-print-color-adjust: exact;
    }

    .linear-percent {
      color: ${props => props.theme.primary}!important;
      -webkit-print-color-adjust: exact;
    }
  }
  .MuiLinearProgress-root {
    height: 5px;
    border-radius: 8px;
    background: ${colors.greyAlpha25};
  }

  .linear-percent {
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 0;
    margin-top: 10px;
    color: ${props => props.theme.primary};
    text-align: center;
    display: block;
  }

  .icon-wrapper {
    text-align: center;

    svg {
      margin-bottom: 10px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    color: ${colors.black};
  }
`

export default LinearPercentStyled
