import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import BackButtonStyled from './styled'
import { ReactComponent as Arrow } from '../../../assets/images/back_arrow.svg'
import i18n from '../../../assets/i18n'

const BackButton = ({ title, history, onClick, color }) => (
  <BackButtonStyled color={color}>
      <p style={{ cursor: "pointer"}}
        onClick={e => {
          e.preventDefault()
          onClick?onClick():history.goBack()
        }}
      >
        <Arrow />
        {title}
      </p>
  </BackButtonStyled>
)

BackButton.propTypes = {
  title: PropTypes.string,
  history: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

BackButton.defaultProps = {
  title: i18n.headerProfileButtons.backToApplications,
}

const enharce = compose(withRouter)

export default enharce(BackButton)
