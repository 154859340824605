import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import RegularSelectStyled from './styled'
import { ReactComponent as Icon } from '../../../assets/images/black-arrow.svg'
import i18n from '../../../assets/i18n'
import _ from 'lodash'
import ArrowDropDownIcon from "@material-ui/icons/KeyboardArrowDown";

const RegularSelect = ({ styledType, name, keyLabel, keyValue, defaultOption, options, onChange,disabled,  placeholder }) => {

  const optionList = _.map(options, (option, i) => {
    if (option.label !== defaultOption && option.label !== placeholder) {
      return (
        <option key={i} value={_.get(option, keyValue)}>
          {_.get(option, keyLabel)}
        </option>
      )
    }
  })

  return (
    <RegularSelectStyled disabled={ disabled } styledType={styledType}>
      <div className="select-wrapper">
        <select style={ disabled ? {opacity: 0.4, cursor: "not-allowed"} : {opacity: 1, cursor: "pointer"} } disabled={ disabled } name={name} onChange={onChange ? e => onChange(e.target.value, e.target.name) : null}>
          <option defaultChecked value="">
            {defaultOption || placeholder || i18n.regularSelect.select}
          </option>
          {optionList}
        </select>
        <ArrowDropDownIcon />
      </div>
    </RegularSelectStyled>
  )
}

RegularSelect.defaultProps = {
  styledType: '',
  Icon: <Icon />,
  options: [],
  keyLabel: 'label',
  keyValue: 'value'
}

RegularSelect.propTypes = {
  styledType: PropTypes.string,
  defaultOption: PropTypes.string,
  name: PropTypes.string,
  Icon: PropTypes.element,
  options: PropTypes.array,
  onChange: PropTypes.func,
  keyLabel: PropTypes.string,
  keyValue: PropTypes.string
}

const enharce = compose()

export default enharce(RegularSelect)
