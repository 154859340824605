import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { withI18n } from '../../hocs'
import styles from './styles'
import { AuthOperations, AuthActions } from '../../../redux/auth'

const mapStateToProps = state => ({
  isFetching: state.auth.isFetching,
  data: state.auth.data,
  url: state.auth.url
})

const mapDispatchToProps = (dispatch, props) => {
  return {
    signOut: () => dispatch(AuthOperations.signOut(props.history)),
    fetchMe: () => dispatch(AuthOperations.fetchMe(props.history)),
    updateAvatar: imageBase64 => dispatch(AuthOperations.updateAvatar(imageBase64)),
    updateProfile: data => dispatch(AuthOperations.updateProfile(data)),
    setUrl: url => dispatch(AuthActions.setUrl(url)),
    changePassword: data => dispatch(AuthOperations.changePassword(data)),
    integrateWithTeams: () => dispatch(AuthOperations.integrateWithTeams())
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withStyles(styles)
)

export default enharce(View)
