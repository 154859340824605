import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import {MODULES, QUERY_KEYS} from "../../config/constants";

async function getStatusTypes({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  const [_key, {module}] = queryKey;
  // if (!module || ![MODULES.ID_TALENT_ACQUISITION, MODULES.ID_EMPLOYEE_LINE].includes(module)) return;
  if (!module || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;
  return await get(`api/module/${module}/status_types`);
}

export default function useStatusTypes(module) {
  return useQuery([`${module}:${QUERY_KEYS.STATUS_TYPES}`, {module}], getStatusTypes)
}