import React from 'react'
import compose from 'recompose/compose'
import { Container, ItemWrapper, Item, Label, Text, Icon} from './styled'
import PropTypes from 'prop-types'

const Panel = ({ value, columns, underlined }) => {
  return (
    <Container columns={columns}>
      {value.map((item) =>{
        const { id, label, text, icon } = item
        if (!text) return (<></>)
        return (
          <ItemWrapper key={id} underlined={underlined}>
            {icon && <Icon>{icon}</Icon>}
            <Item>
              {label && <Label value={label} />}
              {text && <Text value={text} />}
            </Item>
          </ItemWrapper>
        )
      })}
    </Container>
  )
}

Panel.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  underlined: PropTypes.bool,
  columns: PropTypes.number
}

Panel.defaultValues = {
  value: [],
  underlined: false,
  columns: 1,
}

const enharce = compose()

export default enharce(Panel)
