import styled from 'styled-components'

export const DashboardWizardStyled = styled.div`
  padding: 30px 60px;
  
  .MuiButtonBase-root.MuiButton-root {
    height: initial;
  }
  
  .title {
    margin-bottom: 32px;
  }
 .wizard-grid-content {
    background: #ebebeb;
    border-radius: 8px;
    box-shadow: 0 3px 8px -4px black;
  }
  
  .custom-dashboard-wrapper {
    border-top: 1px solid #ccc;
    background: #e0e0e0;
    border-radius: 0 0 8px 8px;
  }
  
  .paragraph {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }
  
 /* .card-grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }*/
`

export const DashboardCardStyled = styled.div`
  //width: 340px;
  //height: 120px;
  .dashboard_from_scratch_card {
    border: 2px solid transparent;
    cursor: pointer;
    min-width: 340px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &.active, &:hover {
      border-color: ${({ theme }) => theme.primary};
      box-shadow: 0 2px 4px -2px gray;
    }
    
    .alertCustom {
      margin-bottom: 12px;
    }
    .MuiCardHeader-root {
      position: relative;
      .MuiCardHeader-avatar {
        .MuiAvatar-square {
          width: 50px;
          height: 50px;
          border-radius: 8px;
        }
      }
      .MuiCardHeader-title {
        font-size: 16px;
      }
      .MuiCardHeader-subheader {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .MuiCardHeader-action {
        position: absolute;
        right: 24px;
      }
    }
    .MuiCardContent-root {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 16px;
    }
  }
`
