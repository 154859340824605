import React, { useCallback, useMemo, useState } from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import { useDropzone } from 'react-dropzone'
import { withI18n } from '../../hocs'
import { ModalHeader, RegularButton } from '../../atoms'
import { ReactComponent as ModalIcon } from '../../../assets/images/user-white.svg'
import { ModalStyled } from './styled'

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const ModalChangeAvatar = ({ open, handleClose, i18n, onSubmit }) => {
  const [imageBase64, setImageBase64] = useState(null)

  const onClickSubmit = e => {
    e.preventDefault()
    onSubmit && imageBase64 && onSubmit(imageBase64)
  }

  const onDrop = useCallback(acceptedFiles => {
    const file = _.first(acceptedFiles)
    toBase64(file)
      .then(base64 => setImageBase64(base64))
      .catch(() => setImageBase64(null))
  }, [])
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <div>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={i18n.modalChangeAvatar.title} closeClick={handleClose} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center" spacing={3}>
                <Grid item sm={12}>
                  <label>{i18n.modalChangeAvatar.info}</label>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>{isDragActive ? i18n.modalChangeAvatar.dropFilesActive : i18n.modalChangeAvatar.dropFilesHere}</p>
                  </div>
                </Grid>
                {imageBase64 && <img className="preview" src={imageBase64} alt="preview" />}
              </Grid>
              <div className="button-wrapper">
                <RegularButton title={i18n.modalChangeAvatar.submit} handleOnClick={onClickSubmit} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </div>
  )
}

const customStyles = {
  overflow: 'scroll'
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const activeStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

ModalChangeAvatar.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
}

const enharce = compose(withI18n)

export default enharce(ModalChangeAvatar)
