import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { withI18n } from '../../hocs'
import styles from './styles'
import { SaveSearchesOperations } from '../../../redux/savesearches'

const mapStateToProps = state => {
  return {
    filters: state.saveSearches.filters,
    auxFilters: state.saveSearches.auxFilters,
    saveSearchData: state.saveSearches.data,
    isFetching: state.saveSearches.isFetching
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFilters: filters => dispatch(SaveSearchesOperations.setFilters(filters)),
    setAuxFilters: filters => dispatch(SaveSearchesOperations.setAuxFilters(filters)),
    fetchSaveSearch: id => dispatch(SaveSearchesOperations.fetchSaveSearch(id))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withStyles(styles)
)

export default enharce(View)
