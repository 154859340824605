import React from 'react'

const icon = props => (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="7.5" stroke="#7A808D" stroke-width="1.5" stroke-linejoin="round" />
        <path d="M12 15.75V15C12 14.6517 12 14.4776 11.9835 14.3311C11.8464 13.1141 10.8859 12.1536 9.66891 12.0165C9.52242 12 9.34828 12 9 12V12C8.65172 12 8.47758 12 8.33109 12.0165C7.11409 12.1536 6.15363 13.1141 6.01651 14.3311C6 14.4776 6 14.6517 6 15V15.75" stroke="#7A808D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z" stroke="#7A808D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default icon