export default {
  black: '#000000',
  blackAlpha80: 'rgba(0, 0, 0, 0.8)',
  blackAlpha50: 'rgba(0, 0, 0, 0.5)',
  blackAlpha40: 'rgba(0, 0, 0, 0.4)',
  blackAlpha41: '#B0B3BB',
  blackAlpha30: 'rgba(0, 0, 0, 0.3)',
  blackAlpha20: 'rgba(0, 0, 0, 0.2)',
  blackAlpha10: 'rgba(0, 0, 0, 0.1)',
  white: '#FFFFFF;',
  whiteAlpha50: 'rgba(255, 255, 255, 0.5)',
  whiteAlpha24: 'rgba(255, 255, 255, 0.24)',
  whiteAlpha75: 'rgba(255, 255, 255, 0.75)',
  blue: '#00A0E1',
  blue2: '#0076a6',
  darkBlue: 'rgb(0, 112, 157)',
  blueAlpha: '#00A0E1aa',
  blueAlpha15: '#00A0E122',
  grey: '#666',
  grey2: '#FAFAFA',
  grey3: '#7A808D',
  grey4:'#181A1C',
  grey5:'#494D55',
  grey6:'#7A808D',
  grey7:'#F2F2F4',
  grey8: '#818181',
  greyAlpha50: 'rgba(148, 163, 178, 0.5)',
  greyAlpha25: 'rgba(148, 163, 178, 0.25)',
  greyAlpha26:'#E4E6E8',
  greyAlpha15: 'rgba(148, 163, 178, 0.15)',
  darkGrey: '#C6CED6',
  darkGrey2: '#637B92',
  darkGrey3: '#94A3B2',
  yellow: '#DCCD47',
  red: '#FB344C',
  green: '#5DCA62',
  orange: '#E0A93F',
  gold: '#ead1a2',

  pageBackground: '#FBFBFB'
}
