import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import Slide from '@material-ui/core/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AlertDialog = ({ text, title, onSuccess, onClose, open, i18n }) => {
  return (
    <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          {i18n.dialog.decline}
        </Button>
        <Button onClick={onSuccess} variant="contained" color="primary">
          {i18n.dialog.success}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlertDialog.propTypes = {
  i18n: PropTypes.object.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool
}

AlertDialog.defaultProps = {
  open: false
}

const enharce = compose(withI18n)

export default enharce(AlertDialog)