import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import {operations as ProfileProcessesOperations} from '../../../redux/profileProcesses'
import { MODULES } from '../../../config/constants'

const mapStateToProps = state => ({
  data: state.profileProcesses.data,
  user: state.profileProcesses.user,
  comments: state.profileProcesses.comments,
  documents: state.profileProcesses.documents,
  isFetching: state.profileProcesses.isFetching
})

const mapDispatchToProps = dispatch => {
  return {
    fetchProcess: id => dispatch(ProfileProcessesOperations.fetchProcess(id, MODULES.ID_FEEDBACK)),
    initProcess: id => dispatch(ProfileProcessesOperations.initProcess(id, MODULES.ID_FEEDBACK)),
    fetchComments: id => dispatch(ProfileProcessesOperations.fetchComments(id, MODULES.ID_FEEDBACK)),
    fetchDocuments: id => dispatch(ProfileProcessesOperations.fetchDocuments(id)),
    addComment: (id, comment) => dispatch(ProfileProcessesOperations.addComment(id, comment, MODULES.ID_FEEDBACK)),
    markAsRead: id => dispatch(ProfileProcessesOperations.markAsRead(id, MODULES.ID_FEEDBACK)),
    addFav: (id, isFav) => dispatch(ProfileProcessesOperations.addFav(id, isFav, MODULES.ID_FEEDBACK)),
  }
}

const enharce = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withI18n)

export default enharce(View)
