import { colors } from "../assets"

export const CITATION_STATUS_TYPES = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  RESCHEDULE: 'replan',
  TENTATIVE: 'tentative',
}

export const getCitationStatusTypesTranslated = (i18n) => {
  return [
    { value: CITATION_STATUS_TYPES.PENDING, text: i18n.citationStatus[CITATION_STATUS_TYPES.PENDING] },
    { value: CITATION_STATUS_TYPES.ACCEPTED, text: i18n.citationStatus[CITATION_STATUS_TYPES.ACCEPTED] },
    { value: CITATION_STATUS_TYPES.REJECTED, text: i18n.citationStatus[CITATION_STATUS_TYPES.REJECTED] },
    { value: CITATION_STATUS_TYPES.TENTATIVE, text: i18n.citationStatus[CITATION_STATUS_TYPES.TENTATIVE] },
    { value: CITATION_STATUS_TYPES.RESCHEDULE, text: i18n.citationStatus[CITATION_STATUS_TYPES.RESCHEDULE] },
  ]
}

export const getCitationStatusTypeColor = (status) => {
  if (status == CITATION_STATUS_TYPES.REJECTED) {
    return colors.red
  } else if (status == CITATION_STATUS_TYPES.ACCEPTED) {
    return colors.green
  } else if (status == CITATION_STATUS_TYPES.RESCHEDULE) {
    return colors.orange
  } else if (status == CITATION_STATUS_TYPES.TENTATIVE) {
    return colors.blue2
  }
  return null;
}