import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import { MODULES, QUERY_KEYS } from '../../config/constants'

async function getPhases(module = MODULES.ID_TALENT_ACQUISITION) {
  return await get(`/api/module/${module}/phases`)
}

export default function usePhases(module, transformDataToState) {
  return useQuery([`${module}:${QUERY_KEYS.PHASES}`, { module }], () => getPhases(module), {
    select: (data) => transformDataToState(data),
  });
}
