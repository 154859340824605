import * as types from './types'

export const setIsFetching = isFetching => ({
  type: types.SET_IS_FETCHING,
  payload: isFetching
})

export const setDocuments = data => ({
  type: types.SET_DOCUMENTS,
  payload: data
})

export const setData = data => ({
  type: types.SET_DATA,
  payload: data
})

export const setComments = data => ({
  type: types.SET_COMMENTS,
  payload: data
})

export const setUser = data => ({
  type: types.SET_USER,
  payload: data
})

export const resetData = data => ({
  type: types.RESET_DATA,
  payload: data
})