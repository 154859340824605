import styled from 'styled-components'
import {FavouriteIcon, MTText, RegularButton} from '../../atoms'
import React from "react";

export const Container = styled.div``

export const Title = styled.p`
  font-size: 40px;
  font-weight: bold;
`

export const InfoList = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`
export const InfoItem = styled.div`
  display: flex;
`
export const InfoItemUnderlined = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Label = styled(props => <MTText {...props} />)``
export const Text = styled(props => <MTText {...props} />)`
  text-align: left;
`
export const CommentsTable = styled.div`
  && {
    padding: 30px 0;
  }
  & .MuiTableSortLabel-root,
  th {
    color: ${({theme}) => theme.primary};
    &:hover {
      color: ${({theme}) => theme.primary};
    }
    &:focus {
      color: ${({theme}) => theme.primary};
    }
    &.MuiTableSortLabel-active {
      color: ${({theme}) => theme.primary};
    }
    & .MuiTableSortLabel-icon {
      color: ${({theme}) => theme.primary} !important;
    }
  }
`

export const ProcessTitle = styled.span``

export const Toolbar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
`

export const ActionButton = styled(props => <RegularButton {...props}/>)`
  margin: 10px;
`
export const UserName = styled.p`
  font-size: 36px;
  font-weight: bold;
`

export const ProcessIsFavourite = styled(props => <FavouriteIcon {...props}/>)`
  margin-left: 10px;
  font-size: 32px !important;
`

export const ExitReason = styled.div`
  .reason_title {
    font-size: 17px;
    font-weight: 600;
    padding: 10px;
  }
  .reason_content {
    font-size: 17px;
    font-weight: normal;
    padding: 0 10px 10px;
    border-bottom: solid 1px rgba(148,163,178,0.25);
  }
`
