import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { DialogStyled } from './styled'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { RegularButton } from '../../atoms'
import i18n from '../../../assets/i18n'

function SimpleDialog(props) {
  const { onClose, open, defaultMessages } = props

  function handleClose() {
    onClose()
  }

  function handleListItemClick(value) {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogStyled>
        <DialogTitle>{i18n.simpleDialog.defaultMessages}</DialogTitle>
        <List className="list">
          {defaultMessages && defaultMessages.length > 0
            ? defaultMessages.map(message => (
                <ListItem className="item" button onClick={() => handleListItemClick(message)} key={message.id}>
                  <p className="title">{message.title}</p>
                  {/* <div className="body" dangerouslySetInnerHTML={{ __html: message.body }} /> */}
                </ListItem>
              ))
            : ''}
        </List>
        <div className="button-wrapper">
          <RegularButton handleOnClick={handleClose} title={i18n.simpleDialog.close} />
        </div>
      </DialogStyled>
    </Dialog>
  )
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  defaultMessages: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
}

const enharce = compose()

export default enharce(SimpleDialog)
