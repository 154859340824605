import styled from 'styled-components'

const PullConfigTableStyled = styled.div`
  && {
    padding: 30px 0;
  }
  .MuiTableHead-root {
    th.MuiTableCell-alignLeft {
      text-align: left !important;
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .MuiTableBody-root {
    td {
      padding-top: 20px;
      padding-bottom: 20px;
      * {
        text-align: left;
      }
    }
  }

  .SingleDatePicker_picker {
    z-index: 11;
  }

  .counter_icon {
    width: 36px;
    height: 36px;
    color: #47C057;
  }
  
  .toolbar {
    flex-direction: row-reverse;
  }
`

export default PullConfigTableStyled
