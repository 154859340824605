import i18n from '../assets/i18n'
import _, { find, isObject } from 'lodash'
import {MODULES} from "../config/constants";
import {prettyEmptyValues} from "./others";
import StatusGroupType from "../models/status-group-type";

const SEPARATOR_TO_SEARCH_MULTIPLE = ';';

export const completePeopleFilters = (filters) => {
    const newFilters = [
        {remote_name: 'search_candidature_name', name: i18n.modalFilter.name },
        {remote_name: 'fecha_desde', name: i18n.modalFilter.date },
        {remote_name: 'fecha_hasta', name: i18n.modalFilter.date},
        {remote_name: 'disponibilidad_desde', name: i18n.modalFilter.availability},
        {remote_name: 'disponibilidad_hasta', name: i18n.modalFilter.availability},
        {remote_name: 'perfil_de', name: i18n.modalFilter.profile},
        {remote_name: 'perfil_a', name: i18n.modalFilter.profile},
        {remote_name: 'skills_de', name: i18n.modalFilter.skills},
        {remote_name: 'skills_a', name: i18n.modalFilter.skills},
        {remote_name: 'matching_de', name: i18n.modalFilter.matching},
        {remote_name: 'matching_a', name: i18n.modalFilter.matching},
        {remote_name: 'estado', name: i18n.modalFilter.status},
    ]
    if(!filters) return newFilters
    return [
        ...newFilters,
        ...filters
    ]
}

export const parseFiltersFromFrontToApiFormat = (filtersOriginal, separateTextFields = true) => {
    if (!filtersOriginal) {
        return null
    }

    let filtersCopy = _.cloneDeep(filtersOriginal)
    let filtersToSend = {}
    _.map(filtersCopy, (obj, key) => {
        if (!filtersToSend[key]) {
            filtersToSend[key] = []
        }
        if (_.isArray(obj)) {
            _.map(obj, (item) => {
                if (typeof item === 'object') {
                    let value = item.value

                    if (separateTextFields && item.filter && item.filter.type === 'text') {
                        value = value.split(SEPARATOR_TO_SEARCH_MULTIPLE).map(function(item) {
                            return item.trim();
                        }).filter(function(item) {
                            return item !== '';
                        });

                        if(value.length === 1) {
                            filtersToSend[key] = value[0];
                        } else {
                            filtersToSend[key] = value;
                        }
                    } else {
                        filtersToSend[key].push(value)
                    }
                } else {
                    // add primitive support
                    filtersToSend[key].push(item)
                }
            })
        } else if (typeof obj === 'string' && obj.includes(';')) {
            let splitObj = obj.split(';');
            filtersToSend[key].push(splitObj);
        } else {
            // for exceptional queries like isCitation
            filtersToSend[key].push(obj)
        }

        // remove array instead of primitive value to improve mysql queries
        if (filtersToSend[key].length === 1 && _.isObject(filtersToSend[key][0])) {
            filtersToSend[key] = filtersToSend[key][0];
        }
    })

    return filtersToSend
}

export const parseFiltersFromApiToFrontFormat = (filtersInApiFormat, module = MODULES.ID_TALENT_ACQUISITION) => (dispatch) => {
    let filtersInFrontFormat = _.cloneDeep(filtersInApiFormat)
    let res = {}

    _.map(filtersInFrontFormat, (filter, key) => {
        if (!_.isArray(filter)) {
            filter = [filter]
        }

        res[key] = _.map(filter, (filterValue) => {
            return dispatch(createFilterObjectSelected(filterValue, key, module))
        })
    })

    return res
}

export const createFilterObjectSelected = (inputValue, filterRemoteName, module, filter) => (dispatch, getState) => {
    const state = getState()
    const inboxFilters = { ...state.inbox.inboxFilters }

    if (!filter) {
        filter =_.findLast(inboxFilters, inboxFilter =>
          inboxFilter.remote_name === filterRemoteName
        )
    }

    let label = inputValue
    if (isObject(inputValue) && ('from' in inputValue || 'to' in inputValue)) {
        label = prettyEmptyValues(inputValue.from) + ' - ' + prettyEmptyValues(inputValue.to)
    }

    if (filter) {
        if (module === MODULES.ID_TALENT_ACQUISITION) {
            if (isObject(inputValue) && inputValue.value && inputValue.text) {
                let originalInputValue = inputValue;
                inputValue = originalInputValue.value;
                label = originalInputValue.text;
            } else {
                switch (filterRemoteName) {
                    case 'estado':
                    case 'candidature.estado':
                        inputValue = parseInt(inputValue)
                        label = i18n.status[inputValue]
                        break;
                    case 'evaluativeConfig.status':
                        label = i18n.evaluativeConfig[inputValue]
                        break;
                    case 'user.id': {
                        const responsibles = state.citations.responsible

                        let responsible = responsibles.find(responsible =>
                        responsible.id === inputValue
                        )
                        if (responsible) {
                            label = responsible.name + ' ' + responsible.surname
                        }
                        break;
                    }
                    default:
                }
            }
        }
    }

    return { value: inputValue, label: label, name: filterRemoteName, id: filterRemoteName+'-'+label, filter }
}
export const parseFiltersFromApiToFrontFormatNoRedux = (filtersInApiFormat, inboxFilters, options, module = MODULES.ID_TALENT_ACQUISITION) => {
    let filtersInFrontFormat = _.cloneDeep(filtersInApiFormat)
    let res = {}

    _.map(filtersInFrontFormat, (filter, key) => {
        if (!_.isArray(filter)) {
            filter = [filter]
        }

        res[key] = _.map(filter, (filterValue) => {
            return createFilterObjectSelectedNoRedux(filterValue, key, module, inboxFilters, options);
        })
    })

    return res
}

export const createFilterObjectSelectedNoRedux = (inputValue, filterRemoteName, module, filter, options) => {
    let label = inputValue
    if (isObject(inputValue) && ('from' in inputValue || 'to' in inputValue)) {
        label = prettyEmptyValues(inputValue.from) + ' - ' + prettyEmptyValues(inputValue.to)
    }

    if (filter) {
        if (module === MODULES.ID_TALENT_ACQUISITION) {
            let phasesAndStatusRemoteNames = ['selection_phase','selection_status.phase', 'selection_status'];
            if (isObject(inputValue) && inputValue.value && inputValue.text && !phasesAndStatusRemoteNames.includes(filterRemoteName)) {
                let originalInputValue = inputValue;
                inputValue = originalInputValue.value;
                label = originalInputValue.text;
            } else {
                switch (filterRemoteName) {
                    case 'estado':
                    case 'candidature.estado':
                        inputValue = parseInt(inputValue)
                        label = i18n.status[inputValue]
                        break;
                    case 'evaluativeConfig.status':
                        label = i18n.evaluativeConfig[inputValue]
                        break;
                    case 'selection_status':
                        label = find(options['status'], {id: inputValue}).name;
                        break;
                    case 'selection_phase':
                    case 'selection_status.phase':
                        label = find(options['phases'], {id: inputValue}).name;
                        break;
                  // TODO: Dar soporte para obtener el state desde aquí.
                  // case 'user.id': {
                    //     const responsibles = state.citations.responsible
                    //
                    //     let responsible = responsibles.find(responsible =>
                    //       responsible.id === inputValue
                    //     )
                    //     if (responsible) {
                    //         label = responsible.name + ' ' + responsible.surname
                    //     }
                    //     break;
                    // }
                    default:
                }
            }
        }
    }

    return { value: inputValue, label: label, name: filterRemoteName, id: filterRemoteName+'-'+label, filter }
}

export const parseFilterFromIdToLabel = (id, data, dataAccessKeys, labelKey) => {
    let accessedData = data;
    if (dataAccessKeys && dataAccessKeys.length > 0) {
        dataAccessKeys.map(dataAccessKeys => {
            if (data.hasOwnProperty(dataAccessKeys)) {
                accessedData = data[dataAccessKeys];
            }
        });
    }
    let row = find(accessedData, {id: id});
    return row ? row[labelKey] : id;
}

export const refactorSavedSearchFiltersByGroupSelected = (filters, filterGroup) => {
    let statusInFilters = filters.hasOwnProperty('estado') ? JSON.stringify(filters['estado']) : null;
    let statusByGroup = _.find(StatusGroupType, {id: filterGroup});
    if(statusByGroup && statusByGroup.status) {
        statusByGroup = statusByGroup.status;
        if (statusInFilters) {
            filters['estado'] = statusByGroup.filter(status => statusInFilters.includes(status));
        } else {
            filters['estado'] = statusByGroup;
        }
    }

    return filters;
}
