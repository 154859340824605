import styled from 'styled-components'

export default styled.div`
  margin-bottom: 20px;
  margin: 30px 0 30px 0;

  h1 {
    display: inline;
    margin-right: 7px;
  }
`
