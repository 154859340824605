import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import {MODULES, QUERY_KEYS} from "../../config/constants";


async function getPushOffersByCandidature({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  let [_key, {module, slug}] = queryKey;
  if (!module || !slug || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;

  return get(`/api/module/${module}/identifier/${slug}/push_offers`);
}

export default function usePushOfferByCandidature(module, slug) {
  return useQuery([`${module}:${QUERY_KEYS.PUSH_OFFER_CANDIDATURE}:${slug}`, {module, slug}], getPushOffersByCandidature);
}