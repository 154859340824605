import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import { QUERY_KEYS } from '../../config/constants'
import * as FiltersUtils from '../../utils/filters'

async function getAvailableDashboards({queryKey}) {
  const [_key, {module}] = queryKey;
  if (!module) return;
  return await get(`/api/module/${module}/dashboard/available`);
}

export function useAvailableDashboards(module) {
  return useQuery([`${module}:${QUERY_KEYS.AVAILABLE_DASHBOARDS}`, {module}], getAvailableDashboards);
}

async function getClientDashboards({queryKey}) {
  const [_key, {module}] = queryKey;
  if (!module) return;
  return await get(`/api/module/${module}/dashboard`);
}

export function useClientDashboards(module) {
  return useQuery([`${module}:${QUERY_KEYS.DASHBOARDS}`, {module}], getClientDashboards);
}

async function getDashboardInfo({queryKey}) {
  const [_key, {module, id}] = queryKey;
  if (!module || !id) return;
  return await get(`/api/module/${module}/dashboard/${id}`);
}

export function useDashboardInfo(module, id) {
  return useQuery([`${module}:${QUERY_KEYS.DASHBOARDS}:info:${id}`, {module, id}], getDashboardInfo);
}

async function getDashboardData({queryKey}) {
  let [_key, {module, id, from, to, filters}] = queryKey;
  if (!module || !id || !from || !to) return;

  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : '{}'

  const params = window.disable_cache ? "&refresh=true" : "";
  return await get(`/api/module/${module}/dashboard/${id}/data?from=${from}&to=${to}&filters=${filters}`+params);
}

async function getDashboardFilters({queryKey}) {
  const [_key, {module, id, from, to}] = queryKey;
  if (!module || !id || !from || !to) return;
  return await get(`/api/module/${module}/dashboard/${id}/filters?from=${from}&to=${to}`);
}

export function useDashboardData(module, id, from, to, filters = null) {
  return useQuery([`${module}:${QUERY_KEYS.DASHBOARDS}:data:${id}:${from}:${to}:${filters}`, {module, id, from, to, filters}], getDashboardData);
}

export function useDashboardFilters(module, id, from, to) {
  return useQuery([`${module}:${QUERY_KEYS.DASHBOARDS}:filters:${id}:${from}:${to}`, {module, id, from, to}], getDashboardFilters);
}

async function getDashboardWidgets({queryKey}) {
  const [_key, {module, id}] = queryKey;
  if (!module || !id) return;

  return await get(`/api/module/${module}/dashboard/${id}/widget`);
}

export function useDashboardWidgets(module, id) {
  return useQuery([`${module}:${QUERY_KEYS.DASHBOARDS}:widgets:${id}`, {module, id}], getDashboardWidgets);
}

async function getAvailableWidgets({queryKey}) {
  const [_key, {module}] = queryKey;
  if (!module) return;

  return await get(`/api/module/${module}/widget/available`);
}

export function useAvailableWidgets(module) {
  return useQuery([`${module}:${QUERY_KEYS.DASHBOARDS}:available`, {module}], getAvailableWidgets);
}

async function getStatisticsExtraFilters({queryKey}) {
  const [_key, {module}] = queryKey;
  if (!module) return;
  return await get(`/api/module/${module}/statistics/filters`);
}

export function useStatisticsExtraFilters(module) {
  return useQuery([`${module}:${QUERY_KEYS.DASHBOARDS}:extra_filters`, {module}], getStatisticsExtraFilters);
}
