import styled from 'styled-components'
import colors from '../../../assets/colors'
import Grid from '@material-ui/core/Grid'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export const ModalStyled = styled.div`
  && {
    border-radius: 25px;
    margin: 0 auto;
    max-width: 100%;
    margin-top: 20vh;
    width: 685px;
    outline: none;

    .modal-body {
      background-color: ${colors.white};
      padding: 0 50px 50px 50px;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
      outline: none;
      font-family: Poppins;

      .grid-decoration {
        position: relative;
        ::before {
          content: '-';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -4px;
        }
      }
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;

        margin-top: 20px;
        ::after {
          content: '*';
          color: #d95117;
          padding-left: 2px;
        }       
      }
      input {
          font-weight: 500;
          border-color: #d8dde3;
          color: #646464;
        }

      .button-wrapper {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;

        button {
          margin-top: 25px;
        }      
      }
      
      .save-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-top: 39px;
        border-radius: 10px;

      }
      
      .button-custom{
          border-radius: 10px;
          color: #ffffff;
        }

      .wrapper-tags {
        background: #ffffff;
        border-radius: 8px;
        border-style: solid;
        border-color: #d8dde3;
        border-width: 1px;
        padding: 12px 16px 12px 16px;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-start;
        box-shadow: 0px 0px 4px 0px rgba(23, 25, 28, 0.05);
      }

      .chip-custom {
        color: #4b79d3;
        background-color: #b8d9ff;
        border-radius: 10px;

        span {
          font-weight: 600;
          font-size: 12px;
        }
      }
      
    }
  }
`
export const FormGridContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormGridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const CenteredIconGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowIcon = styled(ArrowForwardIcon)`
  margin-top: 35px;
  font-size: 5.5em;
`;

