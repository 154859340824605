import * as types from './types'

const initialState = {
  dataInbox: {
    data: [],
    total: 0,
  },
  initialDataInbox: {
    data: [],
    total: 0,
  },
  module: null,
  dataFields: null,
  reportFields: [],
  statusTypes: [],
  isFetching: false,
  sort: ["",""],
  page: 0,
  perPage: 20,
  ready: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_DATA:
      return { ...state, dataInbox: action.payload }
    case types.SET_INITIAL_DATA:
      return { ...state, initialDataInbox: action.payload }
    case types.SET_PAGE:
      return { ...state, page: action.payload }
    case types.SET_PER_PAGE:
      return { ...state, perPage: action.payload }
    case types.SET_SORT:
      return { ...state, sort: action.payload }
    case types.SET_MODULE:
      return { ...state, module: action.payload }
    case types.SET_TOTAL:
      return { ...state, total: action.payload }
    case types.RESET_DATA:
      return { ...initialState }
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_DATA_FIELDS:
      return { ...state, dataFields: action.payload }
    case types.SET_STATUS_TYPES:
      return { ...state, statusTypes: action.payload }
    case types.SET_TAGS_TYPES:
      return { ...state, tagsTypes: action.payload }
    case types.SET_REPORT_FIELDS:
      return { ...state, reportFields: action.payload }
    case types.SET_READY:
      return { ...state, ready: action.payload }
    default:
      return state
  }
}
