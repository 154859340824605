export const someArgumentIs = (targetValue, ...valuesToCheck) => {
  return valuesToCheck.some(val => val === targetValue);
}

export const isJSON = (jsonString) => {
  try {
    const objeto = JSON.parse(jsonString);
    return typeof objeto === 'object' && objeto !== null;
  } catch (e) {
    return false;
  }
}