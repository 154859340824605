import React, {useState} from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import {InputField, ModalHeader, RegularButton} from '../../atoms'
import { ReactComponent as ModalIcon } from '../../../assets/images/user-white.svg'

const ModalReportConfirmation = ({ showModal, handleClose, i18n }) => {

  const [ receiver, setReceiver ] = useState('');

  return (
    <div>
      <Modal
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={showModal}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon/>} title={i18n.reports.modalConfirmationTitle} closeClick={() => handleClose('Close')}/>
            <div className="modal-body">
              <Grid container alignItems="center" spacing={1}>
                <p style={{fontSize: '11px'}} dangerouslySetInnerHTML={{ __html: i18n.reports.reportReceiversTitle }}></p>
              </Grid>

              <InputField value={ receiver } handleOnChange={ (text) => setReceiver(text) }/>

              <div style={{marginTop: '30px'}}>
                <div className="button-wrapper" style={{display: 'flex'}}>
                  <RegularButton onClick={() => handleClose(receiver)} title={i18n.reports.modalConfirmationButton} style={{marginRight: '30px'}}/>
                  <RegularButton style={{backgroundColor: '#c4c6c999'}} onClick={() => handleClose('Close')} title={i18n.reports.modalConfirmationButtonCancel}/>
                </div>
              </div>
            </div>
        </ModalStyled>
      </Modal>
    </div>
  )
}

ModalReportConfirmation.propTypes = {
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
}

const enharce = compose(withI18n)

export default enharce(ModalReportConfirmation)
