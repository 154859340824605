import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import _ from 'lodash'
import { MTText } from '../'
import colors from '../../../assets/colors'

const MTStatusTextColor = ({ value, i18n, options, onClick }) => {
  let color = 'black'

  switch (_.get(value, 'id')) {
    case 2:
    case 5:
    case 9:
    case 12:
    case 13:
    case 14:
    case 'declined':
      color = colors.red
      break
    case 1:
    case 4:
    case 7:
    case 8:
    case 10:
    case 'accepted':
      color = colors.green
      break
    case 3:
    case 6:
      color = colors.orange
      break
    case 'none':
      color = colors.grey8
      break
    default:
      color = colors.black
      break
  }

  return <MTText value={value ? i18n.status[_.get(value, 'id')] : ''} color={color} {...options} onClick={onClick} />
  
}

MTStatusTextColor.propTypes = {
  value: PropTypes.object,
  i18n: PropTypes.object
}

const enharce = compose(withI18n)

export default enharce(MTStatusTextColor)
