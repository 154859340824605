import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import { MODULES, QUERY_KEYS } from '../../config/constants'

async function getEvaluativeTests (module = MODULES.ID_TALENT_ACQUISITION, params = {}) {
  let querystring = Object.entries(params)
    .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
    .join('&');

  if(querystring.length > 0) {
    querystring = `?${querystring}`;
  }

  return await get(`/api/module/${module}/evaluative-features${querystring}`)
}

async function getEvaluativeTest (module, id) {
  return await get(`/api/module/${module}/evaluative-feature/${id}`)
}

export function useEvaluativeTests (module, params) {
  return useQuery([`${module}:${QUERY_KEYS.EVALUATIVE_TESTS}`, { module, params }], () => getEvaluativeTests(module, params))
}

export function useEvaluativeTest (module, id) {
  return useQuery([`${module}:${QUERY_KEYS.EVALUATIVE_TEST}`, { module, id }], () => getEvaluativeTest(module, id))
}
