export const SET_HEADERS = 'inbox/SET_HEADERS'
export const SET_HEADERS_IS_FETCHING = 'inbox/SET_HEADERS_IS_FETCHING'

export const SET_DATA = 'inbox/SET_DATA'
export const SET_DATA_IS_FETCHING = 'inbox/SET_DATA_IS_FETCHING'

export const SET_INBOX_FILTERS = 'inbox/SET_INBOX_FILTERS'

export const SET_PAGE = 'inbox/SET_PAGE'
export const SET_ROWS_SELECTED = 'inbox/SET_ROWS_SELECTED'
export const SET_SORT = 'inbox/SET_SORT'
export const SET_PAGE_SIZE = 'inbox/SET_PAGE_SIZE'

export const SET_STATUS_TYPES = 'inbox/SET_STATUS_TYPES'
export const SET_REPORT_FIELDS = 'inbox/SET_REPORT_FIELDS'
export const SET_TAGS_TYPES = 'tags/SET_TAGS_TYPES'

export const RESET_FILTERS = 'inbox/RESET_FILTERS'
