import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {AppBarInbox, ProcessTable, ProfileProcessesInfo} from '../../organism'
import {MODULES} from '../../../config/constants'

class InboxPeople extends Component {
  render() {
    const { classes, filters, i18n } = this.props
    return (
      <div className={classes.root}>
        <AppBarInbox
          showActions
          filterName={i18n.processesTable.filterProcesses}
          module={MODULES.ID_EMPLOYEE_LINE}
          section={"processesTable"}
        />
        <ProcessTable 
          title={i18n.processesTable.title}
          filters={filters} 
          module={MODULES.ID_EMPLOYEE_LINE} 
          showChangeStatus
          rowViewRender={(id) => <ProfileProcessesInfo id={id} />}
        />
      </div>
    )
  }
}

InboxPeople.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  auxFilters: PropTypes.object,
  setAuxFilters: PropTypes.func,
  fetchSaveSearch: PropTypes.func,
  saveSearchData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default InboxPeople
