import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import styles from './styles'

const DrawableListItem = ({ Icon, text, selected, classes, iconClasName, onClick, className, contentRight }) => {
  const theme = useTheme()
  const htmlColor = selected ? theme.textPrimary : theme.textSecondary
  return (
    <ListItem button selected={selected} className={classnames(classes.root, className)} onClick={onClick}>
      {Icon && <ListItemIcon className={classnames(classes.icon, iconClasName)}>{Icon && <Icon htmlColor={htmlColor} fontSize="large" />}</ListItemIcon>}
      <ListItemText>{text && <span className={classnames(selected ? [classes.text, classes.selected] : classes.text)}>{text}</span>}</ListItemText>
      {contentRight}
    </ListItem>
  )
}

DrawableListItem.propTypes = {
  selected: PropTypes.bool,
  Icon: PropTypes.func,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  text: PropTypes.string,
  iconClasName: PropTypes.string,
  className: PropTypes.string,
  contentRight: PropTypes.node
}

export default withStyles(styles)(DrawableListItem)
