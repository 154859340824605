import React, {useEffect, useState} from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import PullConfigTableStyled from './styled'
import {MTMultipleFields, MTText, MTToolbar} from '../../atoms'
import i18n from '../../../assets/i18n'
import * as API from '../../../api'
import moment from 'moment'
import {withRouter} from 'react-router'
import {AppBarInbox} from "../../organism";
import {MODULES} from "../../../config/constants";
import Grid from "@material-ui/core/Grid";
import {DateRangePiker} from "../../molecules";
import {Badge} from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const PullConfig = ({classes, user_id, client}) => {

  const tableRef = React.createRef();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(moment().add(-30, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [focused, setFocused] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [whatsappCounter, setWhatsappCounter] = useState(null);

  const newData = async () => {
    try {
      let start = startDate.format('DD/MM/YYYY');
      let end = endDate.format('DD/MM/YYYY');
      API.getPullConfigsWhatsappCounter({"created_at": {"from": start, "to": end}}).then((res) => setWhatsappCounter(res));
      const result = user_id ? await Promise.all([
        API.getPullConfigs(user_id)]) : [];

      const pullConfigData = result.length > 0 ? result[0].data : [];
      const totalData = pullConfigData.length;
      setTotal(totalData);
      setData(pullConfigData);
    } catch (e) {
      setData([]);
    }
  }

  useEffect(() => {
    newData();
  }, []);

  const fetchPullConfigs = () => {
    let start = startDate.format('DD/MM/YYYY');
    let end = endDate.format('DD/MM/YYYY');
    setIsFetching(true);
    API.getPullConfigsWhatsappCounter({"created_at": {"from": start, "to": end}}).then((res) => setWhatsappCounter(res));
    setIsFetching(false);
  }

  const parseRelativeAge = (filter) => {
    let formattedFilter = '';
    if (!filter) {
      return '';
    }
    let i = 0;
    for(let field in filter){

      if (i > 0){
        formattedFilter += ' y '
      }


      let split = filter[field].split(' ');
      let value = Number.parseInt(split[0]);
      let time = split[1];
      let absValue = Math.abs(value);
      formattedFilter += (value < 0 ? 'Menor de ' : 'Mayor de ') + absValue;

      switch (time) {
        case 'days':
          formattedFilter += absValue > 1 ? ' días' : ' día';
          break;
        case 'months':
          formattedFilter += absValue > 1 ? ' meses' : ' mes';
          break;
        case 'years':
          formattedFilter += absValue > 1 ? ' años' : ' año';
          break;
        default:
          break;
      }
      i++;
    }
    return formattedFilter;
  }

  const parseRelativeDate = (date, isFrom = true) => {

    if (!date) {
      if (isFrom) return 'el comienzo';
      if (!isFrom) return 'ahora';
    }
    let split = date.split(' ');
    let value = Number.parseInt(split[0]);
    let time = split[1];
    let absValue = Math.abs(value);

    date = (value < 0 ? 'hace ' : '') + absValue;

    switch (time) {
      case 'days':
        date += absValue > 1 ? ' días' : ' día';
        break;
      case 'months':
        date += absValue > 1 ? ' meses' : ' mes';
        break;
      case 'years':
        date += absValue > 1 ? ' años' : ' año';
        break;
      default:
        break;
    }

    return date;
  }

  const boolTrueIcon = <MTText value={<i className="fa fa-2x fa-check" style={{color: 'green'}} aria-hidden="true"></i>}/>;
  const boolFalseIcon = <MTText value={<i className="fa fa-2x fa-times" style={{color: 'red'}} aria-hidden="true"></i>}/>;

  const columns = [
    {
      title: i18n.pullConfigTable.title,
      field: 'name', render: rowData => <MTText value={rowData.name}/> // eslint-disable-line react/display-name
    }, {
      title: i18n.pullConfigTable.is_active,
      field: 'is_active', render: rowData => rowData.is_active === '1' ? boolTrueIcon : boolFalseIcon // eslint-disable-line react/display-name
    }, {
      title: i18n.pullConfigTable.renotify_answered,
      field: 'renotify_answered', render: rowData => rowData.renotify_answered === '1' ? boolTrueIcon : boolFalseIcon // eslint-disable-line react/display-name
    }, {
      title: i18n.pullConfigTable.interval_in_days,
      field: 'interval_in_days', render: rowData => <MTText value={`${rowData.interval_in_days} ${i18n.pullConfigTable.days}`}/> // eslint-disable-line react/display-name
    }, {
      title: i18n.pullConfigTable.last_time_execution,
      field: 'executed_at', render: rowData => rowData.executed_at ? <MTText value={moment(rowData.executed_at).format('DD/MM/YYYY HH:mm')}/> : '-' // eslint-disable-line react/display-name
    }, {
      title: i18n.pullConfigTable.filters,
      field: 'filters', render: rowData => { // eslint-disable-line react/display-name
          let filters = JSON.parse(rowData.filters);
          let updateAt = filters.update_at;

        filters.fecha_nacimiento = filters.fecha_nacimiento ? parseRelativeAge(filters.fecha_nacimiento) : '';
        filters.update_at = `${i18n.pullConfigTable.from} ${parseRelativeDate(updateAt.from)} ${i18n.pullConfigTable.to} ${parseRelativeDate(updateAt.to, false)}`

          return <MTMultipleFields values={filters} useCapitalize={false}/>;
        }
    }, {
      title: <i className="fa fa-arrow-circle-up" aria-hidden="true" style={{color: 'green', fontSize: 20}}></i>,
      field: 'accepted', render: rowData => <MTText value={rowData.accepted}/> // eslint-disable-line react/display-name
    }, {
      title: <i className="fa fa-2x fa-times-circle" aria-hidden="true" style={{color: 'red', fontSize: 20}}></i>,
      field: 'refused', render: rowData => <MTText value={rowData.refused}/> // eslint-disable-line react/display-name
    }, {
      title: <i className="fa fa-clock-o" aria-hidden="true" style={{color: 'grey', fontSize: 20}}></i>,
      field: 'not_answered', render: rowData => <MTText value={rowData.not_answered}/> // eslint-disable-line react/display-name
    }
  ];

  const tableOptions = {
    selection: false,
    cellStyle: {textAlign: 'left !important'},
    paging: false,
    loadingType: 'linear',
    pageSize: 20,
    pageSizeOptions: [20, 50, 100, 200],
  }

  const tableComponents = {
    Container: props => <div {...props} />, // eslint-disable-line react/display-name
    Toolbar: () => <MTToolbar/> // eslint-disable-line react/display-name
  }

  return (
    <div className={classes.root}>
    <PullConfigTableStyled>
      <AppBarInbox
        showActions
        module={MODULES.ID_TALENT_ACQUISITION}
        saveSearchOption={false}
        canEditFilters={false}
        canCloneSearch={false}
        forceTotal={total}
        section={"pullConfig"}
      />
      {client && client.whatsapp_available &&
        <Grid container className="title-container">
          <Grid item xs={12} sm={12} className="buttons-wrapper"
                style={{display: "flex", justifyContent: "space-between", marginTop: 16}}
          >
            <DateRangePiker
              startDate={startDate}
              onStartDateChange={date => setStartDate(date)}
              endDate={endDate}
              onEndDateChange={date => setEndDate(date)}
              onFocusChange={({focused}) => setFocused(focused)}
              focused={focused}
              isFetching={isFetching}
              onSubmit={fetchPullConfigs}
            />
            {whatsappCounter &&
                <Badge badgeContent={whatsappCounter} color="error" max={1000000}>
                  <WhatsAppIcon className="counter_icon"/>
                </Badge>
            }
          </Grid>
        </Grid>
      }
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={data}
          options={tableOptions}
          components={tableComponents}
          localization={i18n.tableLocalization}
        />
      </PullConfigTableStyled>
    </div>
  );
}

PullConfig.propTypes = {
  i18n: PropTypes.object.isRequired,
  searchFilters: PropTypes.object
}

const enharce = compose(withRouter)

export default enharce(PullConfig)