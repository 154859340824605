import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid'
import MyProfileUserManagementStyled from './styled'
import { Box, Tooltip, Typography } from '@material-ui/core'
import InputField from '../../atoms/input-field'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import RegularButton from '../../atoms/regular-button';
import MaterialTable from 'material-table';
import { MTText, IconEdit, IconBlockUser, IconActiveUser, CustomAlert } from '../../atoms'
import { ModalCreateUser } from '../../molecules'
import RegularButtonOutline from '../../atoms/regular-button-outline';
import ModalDisableUser from '../../molecules/modal-disable-user';
import { useUsers } from '../../../api/queries/useUsers';
import {
    USERS_ROLES,
    MODULE_BY_USER_TYPE,
    ROLES,
    MODULES,
    CUSTOM_USERS_DEFAULT_FILTERS
} from '../../../config/constants'
import _ from 'lodash';
import { LinearProgress } from '@material-ui/core';
import moment from 'moment';
import {getClientSections} from "../../../api";
import { AuthUtils } from '../../../redux/auth'
import useInboxFilters from '../../../api/queries/useInboxFilters'
import { useFunnelStatusTypes, useGetPhasesOfStatusTypesQuery } from '../../../api/queries/useFunnelStatusTypes'
import { Skeleton } from '@material-ui/lab';

const MyProfileUserManagement = ({ i18n, classes, self_data, self_client }) => {
    const [searchTerm, setSearchTerm] = useState({ term: '' });
    const [profileFilter, setProfileFilter] = useState('');
    const [moduleFilter, setModuleFilter] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDisableOpen, setIsModalDisableOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [editingUserData, setEditingUserData] = useState(null);
    const [isDisable, setIsDisable] = useState(false);
    const [isEnable, setIsEnable] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({ message: '', severity: '' })
    const [sortedUsers, setSortedUsers] = useState([]);
    const [clientSections, setClientSections] = useState([]);
    const [clientHasModules, setClientHasModules] = useState([]);
    const [moduleOptions, setModuleOptions] = useState([]);
    const [customInboxFilters, setCustomInboxFilters] = useState([]);
    const [isLoadingInboxFilters, setIsLoadingInboxFilters] = useState(false);

    useEffect(() => {
        getClientSections().then((sections) => {
            setClientSections(sections);
        }).catch((e) => {
            console.error(e);
        })
    }, []);

    useEffect(() => {
        if (alert.message) {
            setShowAlert(true);
            const timer = setTimeout(() => {
                setShowAlert(false);
                setAlert({ message: '', severity: '' });
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [alert]);

    const options = [
        { id: USERS_ROLES.ATS_ADMIN_USER, name: i18n.user_management.atsAdminUser },
        { id: USERS_ROLES.BASIC_USER, name: i18n.user_management.customUser },
        { id: USERS_ROLES.READ_ONLY_USER, name: i18n.user_management.readOnlyUser },
    ];

    const { data: usersData, isLoading, refetch } = useUsers();
    const {data: inboxFiltersTalent, isLoading: isLoadingInboxFiltersTalent} = useInboxFilters(MODULES.ID_TALENT_ACQUISITION, true, clientHasModules);
    const {data: inboxFiltersOnBoarding, isLoading: isLoadingInboxFiltersOnBoarding} = useInboxFilters(MODULES.ID_ON_BOARDING, true, clientHasModules);
    const {data: inboxFiltersEmployeeLine, isLoading: isLoadingInboxFiltersEmployeeLine} = useInboxFilters(MODULES.ID_EMPLOYEE_LINE, true, clientHasModules);
    const {data: inboxFiltersExitInterview, isLoading: isLoadingInboxFiltersExitInterview} = useInboxFilters(MODULES.ID_EXIT_INTERVIEW, true, clientHasModules);
    const {data: inboxFiltersFeedback, isLoading: isLoadingInboxFiltersFeedback} = useInboxFilters(MODULES.ID_FEEDBACK, true, clientHasModules);
    const {data: inboxFiltersAbsenteeism, isLoading: isLoadingInboxFiltersAbsenteeism} = useInboxFilters(MODULES.ID_ABSENTEEISM, true, clientHasModules);
    const {data: inboxFiltersSurveys, isLoading: isLoadingInboxFiltersSurveys} = useInboxFilters(MODULES.ID_SURVEYS, true, clientHasModules);
    const {data: inboxFiltersWelcome, isLoading: isLoadingInboxFiltersWelcome} = useInboxFilters(MODULES.ID_WELCOME_PACK, true, clientHasModules);
    const {data: inboxFiltersVisual, isLoading: isLoadingInboxFiltersVisual} = useInboxFilters(MODULES.ID_VISUAL_TUTOR, true, clientHasModules);

    const {data: funnelStatusTypes, isLoading: isLoadingFunnelStatusTypes} = useFunnelStatusTypes(MODULES.ID_TALENT_ACQUISITION);
    const phasesOfStatusTypes = useGetPhasesOfStatusTypesQuery(MODULES.ID_TALENT_ACQUISITION);

    useEffect(() => {
        setIsLoadingInboxFilters(
          isLoadingInboxFiltersTalent || isLoadingInboxFiltersOnBoarding || isLoadingInboxFiltersEmployeeLine
          || isLoadingInboxFiltersExitInterview || isLoadingInboxFiltersFeedback || isLoadingInboxFiltersAbsenteeism
          || isLoadingInboxFiltersWelcome || isLoadingInboxFiltersSurveys || isLoadingInboxFiltersVisual
        )
    }, [
        isLoadingInboxFiltersTalent,
        isLoadingInboxFiltersOnBoarding,
        isLoadingInboxFiltersEmployeeLine,
        isLoadingInboxFiltersExitInterview,
        isLoadingInboxFiltersFeedback,
        isLoadingInboxFiltersAbsenteeism,
        isLoadingInboxFiltersWelcome,
        isLoadingInboxFiltersSurveys,
        isLoadingInboxFiltersVisual,
    ]);

    useEffect(() => {
        if (!isLoadingInboxFiltersTalent && inboxFiltersTalent) {
            let filtered = inboxFiltersTalent.filter(filter => CUSTOM_USERS_DEFAULT_FILTERS[MODULES.ID_TALENT_ACQUISITION].includes(filter.remote_name));
            setCustomInboxFilters(prevState => {return {...prevState, [MODULES.ID_TALENT_ACQUISITION]: filtered}});
        }
    }, [inboxFiltersTalent]);

    useEffect(() => {
        if (!isLoadingInboxFiltersOnBoarding && inboxFiltersOnBoarding) {
            let filtered = inboxFiltersOnBoarding.filter(filter => CUSTOM_USERS_DEFAULT_FILTERS[MODULES.ID_ON_BOARDING].includes(filter.remote_name));
            setCustomInboxFilters(prevState => {return {...prevState, [MODULES.ID_ON_BOARDING]: filtered}});
        }
    }, [inboxFiltersOnBoarding]);

    useEffect(() => {
        if (!isLoadingInboxFiltersEmployeeLine && inboxFiltersEmployeeLine) {
            let filtered = inboxFiltersEmployeeLine.filter(filter => CUSTOM_USERS_DEFAULT_FILTERS[MODULES.ID_EMPLOYEE_LINE].includes(filter.remote_name));
            setCustomInboxFilters(prevState => {return {...prevState, [MODULES.ID_EMPLOYEE_LINE]: filtered}});
        }
    }, [inboxFiltersEmployeeLine]);

    useEffect(() => {
        if (!isLoadingInboxFiltersExitInterview && inboxFiltersExitInterview) {
            let filtered = inboxFiltersExitInterview.filter(filter => CUSTOM_USERS_DEFAULT_FILTERS[MODULES.ID_EXIT_INTERVIEW].includes(filter.remote_name));
            setCustomInboxFilters(prevState => {return {...prevState, [MODULES.ID_EXIT_INTERVIEW]: filtered}});
        }
    }, [inboxFiltersExitInterview]);

    useEffect(() => {
        if (!isLoadingInboxFiltersFeedback && inboxFiltersFeedback) {
            let filtered = inboxFiltersFeedback.filter(filter => CUSTOM_USERS_DEFAULT_FILTERS[MODULES.ID_FEEDBACK].includes(filter.remote_name));
            setCustomInboxFilters(prevState => {return {...prevState, [MODULES.ID_FEEDBACK]: filtered}});
        }
    }, [inboxFiltersFeedback]);

    useEffect(() => {
        if (!isLoadingInboxFiltersAbsenteeism && inboxFiltersAbsenteeism) {
            let filtered = inboxFiltersAbsenteeism.filter(filter => CUSTOM_USERS_DEFAULT_FILTERS[MODULES.ID_ABSENTEEISM].includes(filter.remote_name));
            setCustomInboxFilters(prevState => {return {...prevState, [MODULES.ID_ABSENTEEISM]: filtered}});
        }
    }, [inboxFiltersAbsenteeism]);

    useEffect(() => {
        if (!isLoadingInboxFiltersWelcome && inboxFiltersWelcome) {
            let filtered = inboxFiltersWelcome.filter(filter => CUSTOM_USERS_DEFAULT_FILTERS[MODULES.ID_WELCOME_PACK].includes(filter.remote_name));
            setCustomInboxFilters(prevState => {return {...prevState, [MODULES.ID_WELCOME_PACK]: filtered}});
        }
    }, [inboxFiltersWelcome]);

    useEffect(() => {
        if (!isLoadingInboxFiltersSurveys && inboxFiltersSurveys) {
            let filtered = inboxFiltersSurveys.filter(filter => CUSTOM_USERS_DEFAULT_FILTERS[MODULES.ID_SURVEYS].includes(filter.remote_name));
            setCustomInboxFilters(prevState => {return {...prevState, [MODULES.ID_SURVEYS]: filtered}});
        }
    }, [inboxFiltersSurveys]);

    useEffect(() => {
        if (!isLoadingInboxFiltersVisual && inboxFiltersVisual) {
            let filtered = inboxFiltersVisual.filter(filter => CUSTOM_USERS_DEFAULT_FILTERS[MODULES.ID_VISUAL_TUTOR].includes(filter.remote_name));
            setCustomInboxFilters(prevState => {return {...prevState, [MODULES.ID_VISUAL_TUTOR]: filtered}});
        }
    }, [inboxFiltersVisual]);

    useEffect(() => {
        if (usersData && usersData.data && usersData.data.length > 0) {
            let users = usersData.data.map((user) => {
                let userModules = [];
                let userRole = ""
                MODULE_BY_USER_TYPE.map((row, index) => {
                    if (user[row.type]) {
                        userModules.push(row.module);
                        userRole = userRole ? userRole : user[row.type].role;
                    }
                    if (index === MODULE_BY_USER_TYPE.length - 1) {
                        user['module'] = userModules;
                        user['role'] = userRole;
                    }
                });
                return user;
            });
            const sortedData = [...users].sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            setSortedUsers(sortedData);
        }
        // eslint-disable-next-line
    }, [usersData]);

    useEffect(() => {
        if (_.isObject(self_data) && _.isObject(self_client) && self_client.client_has_modules) {
            let hasModules = [];
            let adminRoot = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_ROOT);
            self_client.client_has_modules.map(module => {
                let module_id = module.module_type.id;
                if (adminRoot || self_data.roles.includes(`ROLE_ATS_ADMIN_${module_id}`)) {
                    hasModules.push(module_id);
                }
            });
            if (hasModules.length > 0) setClientHasModules(hasModules);
        }
    }, [self_data, self_client]);

    useEffect(() => {
        if (clientHasModules && clientHasModules.length >= 1) {
            setModuleOptions(clientHasModules.map(module => ({id: module, name: i18n.module_names[module]})));
        }
    }, [clientHasModules]);

    if (isLoading) {
        return <LinearProgress />
    }

    const handleDataUpdate = async () => {
        try {
            await refetch();
            setSelectedRows([]);
            setIsModalDisableOpen(false);

            if (isEnable || isDisable) {
                setAlert({
                    message: isEnable
                        ? i18n.user_management.userDisabledSuccessfully
                        : i18n.user_management.userEnabledSuccessfully,
                    severity: 'success',
                    section: isEnable ? 'inactive' : 'active',
                });

                setIsEnable(false);
                setIsDisable(false);
            }
            if (selectedRows.length > 0) {
                setAlert({
                    message: isEnable
                        ? i18n.user_management.usersDisabledSuccessfully
                        : i18n.user_management.usersEnabledSuccessfully,
                    severity: 'success',
                    section: isEnable ? 'inactive' : 'active',
                });
                setIsDisable(false);
                setIsEnable(false);
            }
        } catch (error) {
            console.error("Error updating data:", error);
            setAlert({
                message: isEnable
                    ? i18n.user_management.userDisabledError
                    : i18n.user_management.userEnabledError,
                severity: 'error',
                section: isEnable ? 'inactive' : 'active',
            });
        }
    };

    const filterData = (searchTermValue, profileFilterValue, moduleFilterValue, isActive, usersList) => {
        if (!usersList || usersList.length === 0) {
            return [];
        }

        const filteredData = usersList.filter((user) => {
            const isUserActive = user.is_active === isActive;

            if (!isUserActive) {
                return false;
            }

            const nameMatch = user.name && user.name.toLowerCase().includes(searchTermValue.toLowerCase());
            const surnameMatch = user.surname && user.surname.toLowerCase().includes(searchTermValue.toLowerCase());
            const emailMatch = user.email && user.email.toLowerCase().includes(searchTermValue.toLowerCase());

            return nameMatch || surnameMatch || emailMatch;
        });

        const profileFilteredData = profileFilterValue
            ? filteredData.filter((user) => {
              if (profileFilterValue === USERS_ROLES.BASIC_USER) {
                  return [USERS_ROLES.BASIC_USER, USERS_ROLES.SUPERVISOR_USER].includes(user.role);
              }
              return user.role === profileFilterValue;
            })
            : filteredData;

        const moduleFilteredData = moduleFilterValue
          ? profileFilteredData.filter((user) => user.module.includes(moduleFilterValue))
          : profileFilteredData;

        return moduleFilteredData;
    };

    const activeUsers = filterData(searchTerm.term, profileFilter, moduleFilter, true, sortedUsers);
    const inactiveUsers = filterData(searchTerm.term, profileFilter, moduleFilter, false, sortedUsers);

    const handleSearchTerm = _.debounce((value) => {
        setSearchTerm({ term: value });
    }, 250);

    const handleProfileFilter = (_, value) => {
        setProfileFilter(value ? value.id : '');
    };

    const handleModuleFilter = (_, value) => {
        setModuleFilter(value ? value.id : '');
    };

    const checkRow = (selectedRows) => {
        setSelectedRows(selectedRows);
    };

    const tableOptions = {
        emptyRowsWhenPaging: false,
        loadingType: 'linear',
        cellStyle: { textAlign: 'center' },
        pageSize: 10,
        pageSizeOptions: [10, 25, 50, 100],
        toolbar: false,
        draggable: false,
        selection: true,
    }

    let tableColumns = {
        created_at: {
            title: i18n.user_management.dateCreated,
            render: function UserNameColumn(row) {
                return <MTText value={formattedValue(row.created_at)} style={{ fontWeight: '400' }} />;
            },
            field: 'created_at'
        },
        name: {
            title: i18n.user_management.username,
            render: function UserNameColumn(row) {
                return <MTText value={row.name} style={{ fontWeight: '400' }} />;
            },
            field: 'name'
        },
        surname: {
            title: i18n.user_management.lastname,
            render: function LastnameColumn(row) {
                return <MTText value={row.surname} style={{ fontWeight: '400' }} />;
            },
            field: 'surname'
        },
        email: {
            title: i18n.user_management.email,
            render: function EmailColumn(row) {
                return <MTText value={row.email} style={{ fontWeight: '400' }} />;
            },
            field: 'email'
        },
        profile: {
            title: i18n.user_management.profile,
            render: function ProfileColumn(row) {
                const selectedProfile = options.find(option => {
                    if ([USERS_ROLES.BASIC_USER, USERS_ROLES.SUPERVISOR_USER].includes(row.role)) {
                        return [USERS_ROLES.BASIC_USER, USERS_ROLES.SUPERVISOR_USER].includes(option.id);
                    }
                    return option.id === row.role
                });
                return <MTText value={selectedProfile ? selectedProfile.name : ''} style={{ fontWeight: '400' }} />;
            },
        },
    };

    const moduleColumn = {
        module: {
            title: i18n.user_management.module,
            render: function ModuleColumn(row) {
                let selectedModules = [];
                row.module.map(module => {
                    selectedModules.push(i18n.module_names[module]);
                })
                return <MTText value={selectedModules.join(', ')} style={{ fontWeight: '400' }} />;
            },
        },
    };

    const actionColumns = {
        editUser: {
            title: '',
            render: function EditUserColumn(row) {
                return <div className='custom-edit'
                            onClick={() => handleEditIconClick(row)} ><IconEdit /></div>
            },
            field: 'editUser',
            hidden: false,
        },
        disableAndEnableUser: {
            title: '',
            render: function DisableUserColumn(row) {
                const isUserActive = row.is_active;
                const icon = isUserActive ? <IconBlockUser /> : <IconActiveUser />;

                return (
                  <div className='custom-delete' onClick={() => handleDisableAndEnableIconClick(row)}>
                      {icon}
                  </div>
                );
            },
            field: 'disableAndEnableUser',
            hidden: false,
        }
    };

    if (moduleOptions && moduleOptions.length > 0 && moduleColumn) {
        tableColumns = {...tableColumns, ...moduleColumn};
    }
    tableColumns = {...tableColumns, ...actionColumns};

    const formattedValue = (value) => {
        if (_.isString(value) && (
          moment(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid() ||
          moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ||
          moment(value, 'YYYY-MM-DD', true).isValid()
        )) {
          return moment(value).format('DD/MM/YYYY');
        } else if (!_.isArray(value) && !_.isObject(value)) {
          return value;
        }
      }

    const handleEditIconClick = (row) => {
        setEditingUserData(row);
        setIsEdit(true);
        setIsModalOpen(true);
    };

    const handleCreateUser = () => {
        setEditingUserData(null);
        setIsEdit(false);
        setIsModalOpen(true);
    };

    const handleDisableAndEnableIconClick = (row) => {
        const currentStatus = row.is_active;
        setEditingUserData(row);
        setIsEdit(false);
        setIsModalDisableOpen(true);
        setIsDisable(!currentStatus);
        setIsEnable(currentStatus);
    };

    const columnsArray = Object.values(tableColumns);

    columnsArray.forEach(column => {
        if (column.render) {
            column.render.displayName = `${column.field}_render`;
        }
    });

    const handleDisableUsers = (row) => {
        const currentStatus = row.is_active;
        setEditingUserData(null);
        setIsModalDisableOpen(true);
        setIsDisable(currentStatus);
        setIsEnable(!currentStatus);
    };

    const handleEnableUsers = (row) => {
        const currentStatus = row.is_active;
        setEditingUserData(null);
        setIsModalDisableOpen(true);
        setIsDisable(!currentStatus);
        setIsEnable(currentStatus);
    };

    return (
        <div className={classes.root}>
            <MyProfileUserManagementStyled>
                <div className="containerHeader">
                    <Grid container spacing={4}>
                        <Grid item className="sectionSecond" xs={12} sm={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start", gridGap: '15px', maxWidth: "800px", }}>
                                <h2>{i18n.user_management.titleSection}</h2>
                                <h5>{i18n.user_management.descriptionSection}</h5>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-end", gridGap: '15px', justifyContent: 'flex-end' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", placeSelf: 'flex-end', gridGap: '5px' }}>
                                    {self_client?.max_users ?
                                        usersData?.data?.length === self_client?.max_users ?
                                        (
                                            <Tooltip title={i18n.user_management.errors.max_users_reached} aria-label="message">
                                                <Typography variant="h6">{usersData?.data?.length}/{self_client?.max_users}</Typography>
                                            </Tooltip>
                                        ) : (
                                            <Typography variant="h6">{activeUsers?.length}/{self_client?.max_users}</Typography>
                                        )
                                    :
                                        <><Skeleton width={35} height={20}/>{ "/" }<Skeleton width={35} height={20}/></>
                                    }
                                    <Box ml={2}>
                                        <RegularButton className="button-custom" title={i18n.user_management.createUser}
                                            onClick={handleCreateUser}
                                            disabled={usersData?.data?.length === self_client?.max_users}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'inline-flex', maxWidth: "367px", alignItems: "center", }}>
                                    <label>{i18n.user_management.searchLabel}</label>
                                    <InputField
                                        placeholder={i18n.evaluativeTests.placeholderInputTerm}
                                        onChange={(e) => {
                                            e.persist();
                                            handleSearchTerm(e.target.value);
                                        }}
                                    />
                                </Box>
                                <Box item sx={{ display: 'inline-flex', alignItems: "center" }}>
                                    <label>{i18n.user_management.filterProfile}</label>
                                    <Autocomplete
                                        className='autocomplete-custom'
                                        options={options}
                                        getOptionLabel={(option) => option.name}
                                        onChange={handleProfileFilter}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder={i18n.user_management.placeholderFilter} variant="outlined" />
                                        )}
                                    />
                                </Box>
                                {moduleOptions && moduleOptions.length > 1
                                  && <Box item sx={{display: 'inline-flex', alignItems: "center"}}>
                                      <label>{i18n.tag_management.filterModule}</label>
                                      <Autocomplete
                                        className='autocomplete-custom'
                                        options={moduleOptions}
                                        getOptionLabel={(option) => option.name}
                                        onChange={handleModuleFilter}
                                        renderInput={(params) => (
                                          <TextField {...params} placeholder={i18n.tag_management.placeholderFilter} variant="outlined"/>
                                        )}
                                      />
                                  </Box>}
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} >
                            <Grid item className="headerTable">
                                <h3>{i18n.user_management.activeUsers}</h3>
                                <div className="containerButton">
                                    {showAlert && !isDisable && alert.section === 'inactive' && <CustomAlert severity={alert.severity} message={alert.message} />}
                                    <RegularButtonOutline
                                        title={i18n.user_management.disableUsers}
                                        onClick={handleDisableUsers}
                                        disabled={selectedRows.filter(row => row !== undefined).length === 0 || selectedRows.every(row => !row.is_active)}
                                    />
                                </div>
                            </Grid>
                            <MaterialTable
                                data={activeUsers}
                                columns={columnsArray}
                                options={tableOptions}
                                onSelectionChange={checkRow}
                            />
                        </Grid>

                        {inactiveUsers && inactiveUsers.length > 0 &&
                        <Grid item xs={12} sm={12}>
                            <Grid item className="headerTable">
                                <h3>{i18n.user_management.inactiveUsers}</h3>
                                <div className="containerButton">
                                    {showAlert && !isEnable && alert.section === 'active' &&
                                    <CustomAlert severity={alert.severity} message={alert.message}/>}
                                    <RegularButtonOutline
                                      title={i18n.user_management.enableUsers}
                                      onClick={handleEnableUsers}
                                      disabled={selectedRows.filter(row => row !== undefined).length === 0 || selectedRows.every(row => row.is_active)}
                                    />
                                </div>
                            </Grid>
                            <MaterialTable
                              data={inactiveUsers}
                              columns={columnsArray.map(column => {
                                  if (column.field === 'editUser') {
                                      return {
                                          ...column,
                                          hidden: true,
                                      };
                                  }
                                  return column;
                              })}
                              options={tableOptions}
                              onSelectionChange={checkRow}
                            />
                        </Grid>
                        }

                        <ModalCreateUser
                            open={isModalOpen}
                            handleClose={() => setIsModalOpen(false)}
                            i18n={i18n}
                            isEdit={isEdit}
                            editingUser={editingUserData}
                            moduleOptions={moduleOptions}
                            onDataUpdate={handleDataUpdate}
                            clientSections={clientSections}
                            customInboxFilters={customInboxFilters}
                            isLoadingInboxFilters={isLoadingInboxFilters}
                            phases={phasesOfStatusTypes}
                            status={funnelStatusTypes && funnelStatusTypes.data ? funnelStatusTypes.data : []}
                        />

                        <ModalDisableUser
                            open={isModalDisableOpen}
                            handleClose={() => setIsModalDisableOpen(false)}
                            i18n={i18n}
                            selectedRows={selectedRows}
                            userData={editingUserData}
                            onDataUpdate={handleDataUpdate}
                        />
                    </Grid>
                </div>
            </MyProfileUserManagementStyled>
        </div>
    );

};

export default MyProfileUserManagement;
