import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { CircleStatistic, TableStatistic, DateRangePiker, BarChartStatistic } from '../../molecules'
import { PageTitle } from '../../atoms'
import i18n from '../../../assets/i18n'
import ProfileInfoStyled from './styled'
import { FeatureGuard } from '../../../utils/guard'

const parseFloatCandidatures = item => ({ ...item, candidatures: parseFloat(item.candidatures) })

const parseLiteralToDay = day => {
  switch (day) {
    case '1':
      return i18n.statistic.sunday
    case '2':
      return i18n.statistic.monday
    case '3':
      return i18n.statistic.tuesday
    case '4':
      return i18n.statistic.wednesday
    case '5':
      return i18n.statistic.thursday
    case '6':
      return i18n.statistic.friday
    case '7':
      return i18n.statistic.saturday
    default:
      return day
  }
}

class StatisticsItems extends Component {
  static propTypes = {
    fetchGlobalStatistics: PropTypes.func,
    globalData: PropTypes.object,
    positionData: PropTypes.array,
    isFetching: PropTypes.bool,
    pushCounters: PropTypes.array,
    pullCounters: PropTypes.array,
    citationCounters: PropTypes.array,
    client: PropTypes.object,
  }

  hasFeaturePush = FeatureGuard.canAccessToPushOffers();
  hasFeaturePull = FeatureGuard.canAccessToPullOffers();

  state = {
    focused: '',
    startDate: moment().add(-7, 'days'),
    endDate: moment(),
  }

  componentDidMount() {
    this.props.initStats(this.state, this.props.module)
  }

  fetchGlobalStatistics = () => this.props.fetchGlobalStatistics(this.state, this.props.module)

  render() {
    const {globalData, positionData, isFetching, client} = this.props

    const hasFeaturePush = this.hasFeaturePush
    const hasFeaturePull = this.hasFeaturePull

    const interviewed = _.get(globalData, 'interviewed', 0)
    const suitables = parseInt(_.get(globalData, 'suitables', 0))

    const offer_interested = _.get(globalData, 'offer_interested', 0)
    const recovered = parseInt(_.get(globalData, 'recovered', 0))

    const recruitmentSource = _.take(
      _.map(_.map(_.get(globalData, 'by_recruitment_source', []), parseFloatCandidatures), item => ({
        ...item,
        recruitment_source: _.truncate(item.recruitment_source, { length: 14 })
      })),
      10
    )

    let activity = _.map(_.get(globalData, 'activity', []), item => {
      return { candidatures: parseFloat(item.candidatures), day: parseLiteralToDay(item.day), literal: item.day }
    })

    //Activity start on sunday (1), pass to the end of array
    const firstActivity = _.first(activity)
    const firstActivityDay = _.get(firstActivity, 'literal')
    let formatActivity = activity

    //If is sunday
    if (firstActivityDay === '1') {
      activity = _.drop(activity, 1)
      formatActivity = _.concat(activity, firstActivity)
    }

    const whatsappCounterData = () => {
      let data = [];
      let whatsappCounter = _.get(globalData, 'whatsapp_counter', []);
      if (whatsappCounter.hasOwnProperty('bot_starter')) {
        data.push({
          "section": i18n.statisticsItems.whatsappCounter.bot_starter,
          "whatsapp": whatsappCounter.bot_starter,
        })
      }

      data.push({
        "section": i18n.statisticsItems.whatsappCounter.citations,
        "whatsapp": whatsappCounter.citations,
      })

      if (hasFeaturePush) {
        data.push({
          "section": i18n.statisticsItems.whatsappCounter.offers,
          "whatsapp": whatsappCounter.offers,
        })
      }

      if (hasFeaturePull) {
        data.push({
          "section": i18n.statisticsItems.whatsappCounter.pulls,
          "whatsapp": whatsappCounter.pulls,
        })
      }

      return data;
    }

    const whatsappTotalCount = () => {
      let whatsappTotalCount = 0;
      let whatsappCounter = _.get(globalData, 'whatsapp_counter', []);

      if (whatsappCounter.hasOwnProperty('bot_starter')) {
        whatsappTotalCount += Number.parseInt(whatsappCounter.bot_starter)
      }
      whatsappTotalCount += Number.parseInt(whatsappCounter.citations)

      if (hasFeaturePush) {
        whatsappTotalCount += Number.parseInt(whatsappCounter.offers)
      }
      if (hasFeaturePull) {
        whatsappTotalCount += Number.parseInt(whatsappCounter.pulls)
      }

      return whatsappTotalCount ? whatsappTotalCount : 0;
    }

    return (
      <ProfileInfoStyled>
        <Grid container className="title-container">
          <Grid item xs={12} sm={6} className="title">
            <PageTitle title={i18n.statistic.title} />
          </Grid>
          <Grid item xs={12} sm={6} className="buttons-wrapper">
            <DateRangePiker
              startDate={this.state.startDate}
              onStartDateChange={startDate => this.setState({ startDate })}
              endDate={this.state.endDate}
              onEndDateChange={endDate => this.setState({ endDate })}
              onFocusChange={({ focused }) => this.setState({ focused })}
              focused={this.state.focused}
              isFetching={isFetching}
              onSubmit={this.fetchGlobalStatistics}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} style={{marginTop: '32px'}}>
          <Grid item xs={12} sm={this.hasFeaturePush?3:6}>
            <CircleStatistic title={i18n.statisticsItems.interviewed} value={interviewed} />
          </Grid>
          {this.hasFeaturePush && (<><Grid item xs={12} sm={3}>
            <CircleStatistic title={i18n.statisticsItems.offer_interested} value={offer_interested} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CircleStatistic title={i18n.statisticsItems.recovered} value={recovered} />
          </Grid></>)}
          <Grid item xs={12} sm={this.hasFeaturePush?3:6}>
            <CircleStatistic title={i18n.statisticsItems.suitables} value={suitables} percentText="%" />
          </Grid>
        </Grid>

        <Grid container spacing={4} style={{marginTop: '16px'}}>
          <Grid item xs={12} sm={12}>
            <BarChartStatistic
              title={i18n.statisticsItems.recruitmentSource}
              data={recruitmentSource}
              xDataKey="recruitment_source"
              yDataKey="candidatures"
              label={i18n.statisticsItems.candidatures}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <BarChartStatistic
              title={i18n.statisticsItems.activity}
              data={formatActivity}
              xDataKey="day"
              yDataKey="candidatures"
              label={i18n.statisticsItems.candidatures}
            />
          </Grid>
        </Grid>

        {client && client.whatsapp_available &&
          <Grid container spacing={4} style={{marginTop: '16px'}}>
            <Grid item xs={8}>
              <TableStatistic
                title={i18n.statisticsItems.whatsappCounter.title}
                data={whatsappCounterData()}
                isFetching={isFetching}
                whatsappCounter={true}
                whatsappTotalCount={whatsappTotalCount()}
              />
            </Grid>
            {client.whatsapp_available && client.whatsapp_voucher &&
              <Grid item xs={4}>
                <CircleStatistic
                  title={`${i18n.statisticsItems.whatsappCounter.voucher}: ${whatsappTotalCount()}/${client.whatsapp_voucher}`}
                  whatsappCounter={true}
                  value={whatsappTotalCount() ? (whatsappTotalCount()/client.whatsapp_voucher*100).toFixed(0) : 0} percentText="%"
                />
              </Grid>
            }
          </Grid>
        }

        <Grid container style={{marginTop: '32px'}}>
          <Grid item xs={12}>
            <TableStatistic title={i18n.statisticsItems.positions} data={positionData} isFetching={isFetching} />
          </Grid>
        </Grid>
      </ProfileInfoStyled>
    )
  }
}

StatisticsItems.propTypes = {
  module: PropTypes.string,
  initStats: PropTypes.func,
}

StatisticsItems.defaultProps = {
}

export default StatisticsItems
