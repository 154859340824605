import { createStyles } from '@material-ui/core/styles'

export default theme =>
  createStyles({
    '@global': {
      body: {
        backgroundColor: theme.colors.pageBackground,
        minWidth: 320
      }
    },
    root: {
      width: '100%',
      padding: '30px 60px',
      textAlign: 'center'
    },
    title: {
      color: theme.colors.black,
      fontSize: 18,
      marginBottom: '15px'
    },
    message: {
      color: theme.colors.black,
      fontSize: 18,
      marginTop: '1em'
    },
    contentWrapper: {
      maxWidth: '600px',
      margin: '0 auto'
    },
    selectWrapper: {
      marginBottom: '15px'
    },
    btnWrapper: {
      textAlign: 'center'
    },
    spinnerWrapper: {
      textAlign: 'center'
    }
  })
