import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {AuthOperations} from "../../../redux/auth";

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    signOut: dispatch(AuthOperations.signOut(props.history))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
)

export default enharce(View)
