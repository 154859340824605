import React, { useState } from 'react'
import compose from 'recompose/compose'
import moment from 'moment'
import _map from 'lodash/map'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeader, RegularButton, RegularSelect, DateInput } from '../../atoms'
import { ReactComponent as ModalIcon } from '../../../assets/images/user-white.svg'
import avaiOptions from '../../../utils/status-options'
import statusType from '../../../models/status-type'

const customStyles = {
  overflow: 'scroll'
}

const ModalChangeStatus = ({ open, handleClose, i18n, onSubmit, options }) => {
  const [status, setStatus] = useState(-1)
  const [date, setDate] = React.useState(new Date())

  const onChangeStatus = label => {
    label && setStatus(label)
  }
  const onClickSubmit = e => {
    e.preventDefault()

    onSubmit(status, moment(date).format('YYYY-MM-DD HH:mm:ss'))
    setStatus(-1)
  }

  const onChangeDate = e => setDate(e)
  const newOptions = options?_map(options, ({ id }) => {return {value: id, id, label: i18n.status[id]}}):avaiOptions()
  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={i18n.modalChangeStatus.changeStatus} closeClick={handleClose} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalChangeStatus.selectStatus}</label>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <RegularSelect placeholder={i18n.modalChangeStatus.select} name="availability" options={newOptions} onChange={onChangeStatus} />
                </Grid>
              </Grid>
              {status === statusType.HIRED ? (
                <Grid container alignItems="center" spacing={1} className="hired-date">
                  <Grid item xs={12} sm={12}>
                    <label>{i18n.modalChangeStatus.hiredDate}</label>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <DateInput name="date" onChange={onChangeDate} value={date} />
                  </Grid>
                </Grid>
              ) : null}

              <div className="button-wrapper">
                <RegularButton title={i18n.modalChangeStatus.changeStatus} handleOnClick={onClickSubmit} disabled={status === -1} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

ModalChangeStatus.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
}

const enharce = compose(withI18n)

export default enharce(ModalChangeStatus)
