import React, {useState} from 'react'
import Modal from '@material-ui/core/Modal'
import compose from 'recompose/compose'
import * as S from './styled'
import {withI18n} from '../../hocs'
import {ReactComponent as ModalIcon} from '../../../assets/images/notebook.svg'
import {
  // CheckButton,
  DateInput, InputEditor, InputField, ModalHeader, RegularButton} from '../../atoms'
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import {AlertDialog} from "../index";
import {useAppContext} from "../../../context/appContext";
import {getCurrentSectionKey, SECTION_KEYS} from "../../../config/constants";
import {useParams} from "react-router";
import * as FiltersUtils from "../../../utils/filters"

const customStyles = {
    overflow: 'scroll'
  }

const OfferInboxButton = ({ ids, i18n, postOffer, filtersValues, totalRows,
                            // , sms
}) => {

  const {savedSearchItemId} = useParams();
  const {filters, savedSearchItems, filterGroup} = useAppContext();

  if (ids && ids.length > 0) {
    filtersValues = {'candidature_id' : ids}
  }

  const [open, setOpen ] = useState(false)
  const [areUSure, setAreUSure ] = useState(false)
  const [formData, setFormData] = React.useState({name:'', description:'', date: {from: null, to: null}, smsNotification: false})

  const handleSubmit = e => {
    e.preventDefault()
    if (!isValid) {
      return
    }
    if (!areUSure) {
      setAreUSure(true)
      return
    }

    setAreUSure(false)

    let filtersForOffer = {}
    let terminateEval = false;
    if (ids && ids.length > 0) {
      terminateEval = true;
      filtersForOffer = {'candidature_id': ids};
    }
    if (!terminateEval && Object.keys(filtersValues) > 0) {
      terminateEval = true;
      filtersForOffer = filtersValues;
    }
    if (!terminateEval && getCurrentSectionKey() !== SECTION_KEYS.SAVED_SEARCHES_ITEM) {
      terminateEval = true;
      filtersForOffer = filters[getCurrentSectionKey()];
    }

    if (!terminateEval) {
      let strFilters = savedSearchItems && savedSearchItems[savedSearchItemId] ? savedSearchItems[savedSearchItemId].fields : null;
      let filters = strFilters ? JSON.parse(strFilters) : {};
      let filterGroupValue = filterGroup[getCurrentSectionKey()] && filterGroup[getCurrentSectionKey()][savedSearchItemId] ? filterGroup[getCurrentSectionKey()][savedSearchItemId] : null;

        filtersForOffer = FiltersUtils.refactorSavedSearchFiltersByGroupSelected(filters, filterGroupValue);
    }

    /* eslint-disable no-useless-computed-key */
    postOffer({...formData, ['filters']: filtersForOffer} ).then(() => {
      setOpen(false)
      setFormData({name:'', description:'', date: {from: null, to: null}, smsNotification: false})
    })
  }

  const isValid = () => {
    return !_.isEmpty(formData.name)
      && !_.isEmpty(formData.description)
      && !_.isEmpty(formData.dateFrom)
      && !_.isEmpty(formData.dateTo)
  }

  return (
    <>
          <Modal
              style={customStyles}
              className="modal-wrapper"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={open}
              onClose={()=>setOpen(false)}
          >
              <S.ModalStyled>
                <ModalHeader Icon={<ModalIcon />} title={i18n.offerModal.title} closeClick={()=>setOpen(false)} />
                <div className="modal-body">
                  <form onSubmit={handleSubmit}>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <label>{i18n.offerModal.name}</label>
                        <InputField
                          fontSize="small"
                          placeholder={i18n.offerModal.name_placeholder}
                          name="name"
                          value={formData.name}
                          required="true"
                          handleOnChange={value => {
                            /* eslint-disable no-useless-computed-key */
                            setFormData({...formData, ['name']: value })
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <label>{i18n.offerModal.description}</label>
                        <InputEditor
                          fontSize="small"
                          placeholder={i18n.offerModal.description_placeholder}
                          name="message"
                          value={formData.description}
                          required="true"
                          onChange={value => {
                            /* eslint-disable no-useless-computed-key */
                            setFormData({...formData, ['description']: value })
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <label>{i18n.offerModal.date_range}</label>
                        <DateInput
                          value={formData.date}
                          format="DD/MM/YYYY"
                          placeholder={i18n.modalFilter.fromDate}
                          required="true"
                          onChange={(value) =>
                            /* eslint-disable no-useless-computed-key */
                            setFormData({...formData, ['dateFrom']: value.from, ['dateTo']: value.to, })}
                          range
                        />
                      </Grid>
                    </Grid>

                  {/*
                    {sms && (
                      <Grid container spacing={3} alignItems="center" className="sms">
                        <Grid item>
                          <TelIcon />
                        </Grid>
                        <Grid>
                          <label>Sms </label>
                        </Grid>
                        <Grid className="box-check">
                          <CheckButton name="sms" title="" checked={formData.smsNotification} onChange={() =>
                            // eslint-disable no-useless-computed-key
                            setFormData({...formData, ['smsNotification']: !formData.smsNotification })} />
                        </Grid>
                      </Grid>
                    )}*/}

                    <AlertDialog
                      open={areUSure}
                      onClose={() => setAreUSure(false)}
                      onSuccess={(e) => handleSubmit(e)}
                      title={i18n.process.deleteModalTitle}
                      text={i18n.offerModal.areUSure.description.replaceAll('{num}', (ids && ids.length > 0) ? ids.length : totalRows)}
                    />
                    <div className="button-wrapper">
                      <RegularButton type="submit" title={i18n.modalMailCustom.send} disabled={!isValid()} />
                    </div>
                  </form>
                </div>
              </S.ModalStyled>
          </Modal>
      <S.ButtonExport
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        {i18n.processesTable.offer} ({(ids && ids.length > 0) ? ids.length : i18n.processesTable.all})
      </S.ButtonExport>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(OfferInboxButton)