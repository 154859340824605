import styled from 'styled-components'

const ProfileSkillsStyled = styled.div`
  height: 100%;
  padding-left: 16px;
  #profileSkillsGridContainer{
    height: 100%;
  }

`

export default ProfileSkillsStyled
