import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import * as FiltersUtils from "../../utils/filters";
import {MODULES, QUERY_KEYS} from "../../config/constants";
import {addItemCount} from "../index";


async function getFavoritesData({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  let [_key, {module, filters, rangeArr, sort}] = queryKey;
  if (!module || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;

  filters = { isFavorite: true, ...filters };

  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  const range = rangeArr ? encodeURI(JSON.stringify(rangeArr)) : ''
  sort = sort ? encodeURI(JSON.stringify(sort)) : ''

  const response = await get(`/api/module/${module}/inbox?filter=${filters}&range=${range}${sort ? `&sort=${sort}` : ''}`);

  response.data = addItemCount(response.data, rangeArr);
  return response;
}

export default function useFavorites(module, filters, rangeArr, sort) {
  return useQuery([`${module}:${QUERY_KEYS.FAVORITES}`, {module, filters, rangeArr, sort}], getFavoritesData);
}