import React from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import {withI18n} from '../../hocs'
import Grid from '@material-ui/core/Grid'
import BoxPDAInfoStyled from './styled'
import {SkillProfile, TitleLine} from "../../atoms"

const BoxPDAInfo = ({ pdaFields, i18n }) => {
  if (!pdaFields) {
    return null
  }

  return (
    <BoxPDAInfoStyled>
      <div className="title-line">
        <TitleLine title={<img alt="PDA Integration" className="pda-logo" src={require('../../../assets/images/pda.svg')} />}/>
      </div>
      <Grid container justifyContent="space-around">
        <SkillProfile key={'score_1'} title={i18n.profileStats.profile} value={Math.round(pdaFields.score_1)} />
        <SkillProfile key={'score_2'} title={i18n.profileStats.profile} value={Math.round(pdaFields.score_2)} />
        <SkillProfile key={'score_3'} title={i18n.profileStats.profile} value={Math.round(pdaFields.score_3)} />
        <SkillProfile key={'score_2'} title={i18n.profileStats.profile} value={Math.round(pdaFields.score_2)} />
        <SkillProfile key={'score_2'} title={i18n.profileStats.profile} value={Math.round(pdaFields.score_2)} />
      </Grid>
    </BoxPDAInfoStyled>
  )
}

BoxPDAInfo.propTypes = {
  i18n: PropTypes.object.isRequired,
  fields: PropTypes.object,
  pdaFields: PropTypes.object,
}

const enharce = compose(withI18n)

export default enharce(BoxPDAInfo)
