import styled from 'styled-components'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { RegularButton } from '../../atoms'
import React from "react";
import {colors} from "../../../assets";

const ProfileButtonsStyled = styled.div`
  .buttons-wrapper {
    text-align: right;
  }

  .header-pagination-count {
    display: inline-block;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color:#666;
    opacity: 0.75 !important;
  }

  .header-profile-arrows {
    margin-left: 15px;
    button {
      padding: 7px;
      margin-left: 10px;
    }
    svg {
      height: 25px;
      width: 30px;
    }
  }

  .currentItemNumber{
    color: ${colors.grey4};
  }
`

export const HeaderButton = styled(props => <RegularButton {...props}/>)`
  margin-left: 10px;
`

export const ButtonContainer = styled(props => <ButtonGroup {...props}/>)`
  margin: 35px 0;
`

export default ProfileButtonsStyled
