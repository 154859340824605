import styled from 'styled-components'

const GlobalStyles = styled.div`
  @media print {
    .no-margin-print {
      margin-left: 0 !important;

      > div {
        padding: 10px !important;
      }
    }
  }
`

export default GlobalStyles
