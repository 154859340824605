import React, {useEffect} from "react";

const Logout = ({signOut}) => {
  useEffect(() => {
    signOut();
  }, []);

  return <></>;
}

export default Logout;