import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import {QUERY_KEYS} from '../../config/constants'

async function getJobsByFamily() {
  return await get(`/api/jobs_by_family`);
}

export function useJobsByFamily() {
  return useQuery([`${QUERY_KEYS.JOBS_BY_FAMILY}`], getJobsByFamily);
}