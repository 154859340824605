import React from 'react'

const icon = props => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path d="M9 4V4C8.07003 4 7.60504 4 7.22354 4.10222C6.18827 4.37962 5.37962 5.18827 5.10222 6.22354C5 6.60504 5 7.07003 5 8V17.2002C5 18.8804 5 19.7205 5.32698 20.3622C5.6146 20.9267 6.07354 21.3856 6.63803 21.6732C7.27976 22.0002 8.11984 22.0002 9.8 22.0002H14.2C15.8802 22.0002 16.7202 22.0002 17.362 21.6732C17.9265 21.3856 18.3854 20.9267 18.673 20.3622C19 19.7205 19 18.8804 19 17.2002V8C19 7.07003 19 6.60504 18.8978 6.22354C18.6204 5.18827 17.8117 4.37962 16.7765 4.10222C16.395 4 15.93 4 15 4V4" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="9" y="2" width="6" height="4" rx="2" stroke="white" strokeWidth="2" strokeLinejoin="round" />
        <path d="M15 18H9" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 15H9" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11 11H9" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
)

export default icon
