import React  from 'react'
import _ from 'lodash'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { ButtonDropdown, CircleStatusIcon } from '../../atoms'
import i18n from '../../../assets/i18n'
import avaiOptions from '../../../utils/status-options'
import moment from 'moment'
import { SelectChangeStatusStyle } from './style'


const SelectChangeStatus = ({ options, currentStatus, onSubmit, disabled}) => {
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    //const [status, setStatus] = React.useState(-1)
    const newOptions = options ? _.map(options, ({ id }) => { return { value: id, id, label: i18n.status[id] } }) : avaiOptions()

    const onChangeStatus = label => {
        //label && setStatus(label)
        onSubmit(label, moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
        //setStatus(-1)
    }

    const handleMenuItemClick = currentTarget => {
        /* TODO: retrive label from currentTarget */
        onChangeStatus(currentTarget.value)
        setOpen(false)
        return true;
    }

    const handleToggle = () => setOpen(prevOpen => !prevOpen)

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        } else {
            setOpen(false)
        }
    }

    const renderMenuItem = () => {
        return (

            newOptions.map((theNewOption) => {
                if (theNewOption.label !== currentStatus) {
                    return (
                        <MenuItem key={theNewOption.id} value={theNewOption.id} onClick={e => handleMenuItemClick(e.currentTarget)}>
                            {theNewOption.label}
                        </MenuItem>
                    )

                }
                return null
            })


        )
    }

    return (
        <SelectChangeStatusStyle>
            <ButtonGroup ref={anchorRef} aria-label="Split button">
                <ButtonDropdown
                    size="small"
                    open={open}
                    MenuList='menu-list-grow'
                    onClick={handleToggle}
                    title={<><CircleStatusIcon id='svgCircle' fill={'transparent'} /> {currentStatus}</>}

                >
                </ButtonDropdown>
            </ButtonGroup>

            <Popper className='popperMtToolbar' open={open} anchorEl={anchorRef.current} transition disablePortal style={{ zIndex: 9999 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            boxShadow: '-4px 0px 4px -4px rgba(0,0,0,0.1)'
                        }}
                    >
                        <Paper name="menu-list-grow">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuList>
                                        {!disabled && renderMenuItem()}
                                    </MenuList>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </ SelectChangeStatusStyle>

    )
}

export default SelectChangeStatus
