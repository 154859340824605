import React, { memo } from 'react'
import {
  CircleStatistic,
  CircleMultipleStatistic,
  TableStatistic,
  BarChartStatistic,
  FunnelStatistic,
  HereMapsStatistic as MapsStatistic,
  GaugeStatistic,
  PieStatistic,
  HeatmapStatistic
} from '../../molecules'

import i18n from '../../../assets/i18n'
import { DeleteForever, Help } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import { useTheme } from '@material-ui/core'

const mapFunctions = {
  convertNumberToWeekday: (item) => {
    if(item.day) {
      item.day = i18n.dashboard.weekdays[item.day] || item.day;
    }

    return item;
  },
  whatsappTranslateSections: (item) => {
    if(item.section) {
      item.section = i18n.dashboard.whatsapp[item.section] || item.section;
    }

    return item;
  },
  featuresEnabledTranslate: (item) => {
    if(item.feature) {
      item.feature = i18n.dashboard.features[item.feature] || item.feature;
    }

    return item;
  },
};

const DynamicWidget = memo(({ widget, name, isLoading = false, params = {}, handleDelete, widgetId, isDraggable = false }) => {
  const {primary} = useTheme();

  const Widget = {
    CircleStatistic,
    CircleMultipleStatistic,
    TableStatistic,
    BarChartStatistic,
    FunnelStatistic,
    MapsStatistic,
    GaugeStatistic,
    PieStatistic,
    HeatmapStatistic
  }[widget] || null;

  if(Widget === null) {
    console.error(`Widget ${widget} not defined.`);
    return <></>;
  }

  let title = '';
  if(name) {
    title = i18n.dashboard.widgets[name] || name;
  }

  // Soporte para campo label con traducción
  if(params.label_i18n) {
    params.label = i18n.dashboard.widgets[params.label_i18n] || params.label_i18n;
  }

  if(params.title) {
    title += ": " + replaceStringVars(params.title, params.data);
    delete params.title;
  }

  // Soporte para quedarnos con un dato simple del objeto de la respuesta. Por ej, dataKey: "total" nos quedamos con params.data.total
  if(params.dataKey && params.data.hasOwnProperty(params.dataKey)) {
    params.data = params.data[params.dataKey];
  }

  // Soporte para ejecutar una función sobre la respuesta: ej. traducir de la semana a partir del nº
  if(params.mapData && typeof mapFunctions[params.mapData]) {
    params.data = params.data.map((item) => mapFunctions[params.mapData](item));
  }

  const otherParams = { ...params };
  delete otherParams.data;

  const overrideTitle = () => {
    let tooltipText = params.tooltip ? i18n.dashboard.tooltip[params.tooltip] : '';
    if (tooltipText && params.tooltip_replace) {
      params.tooltip_replace.map(key => {
        if (params.data && params.data.hasOwnProperty(key)) {
          tooltipText = tooltipText.replace(`:${key}:`, params.data[key]);
        }
      });
    }
    return (
      <div className="header-stat" style={{position: "relative", cursor: isDraggable ? "move" : "auto", color: primary}}>
        <p className="hearder-text">
          {title}
          {tooltipText && <Tooltip title={tooltipText} arrow>
            <Help color='inherit'/>
          </Tooltip>}
        </p>
        {isDraggable && <div
          style={{ display: "flex", cursor: "pointer"}}
          className="RGL-widgetDeleteButton"
          onClick={(e) => {
            e.preventDefault();
            handleDelete(widgetId);
          }}
        >
          <DeleteForever/>
        </div>}
      </div>
    )
  }

  return (<>
    <Widget overrideTitle={overrideTitle()} title={title} isLoading={isLoading} data={params.data} {...otherParams} isDashboard={true} />
  </>
  );
});

const replaceStringVars = (string, object) => (
  string.replace(/{{(.*?)}}/g, function(match, p1) {
    const propiedad = p1.trim();

    return object[propiedad] !== undefined ? object[propiedad] : match;
  })
)
DynamicWidget.displayName = 'DynamicWidget';

export default DynamicWidget;
