import React  from 'react'
import DynamicWidget from "../dynamic-widget";
import Grid from "@material-ui/core/Grid";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import DashboardHomeItemStyled from "./styled";

const DashboardHomeItem = ({id, widget}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    // <DashboardHomeItemStyled>
      <Grid
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className="widget-container"
        item
        xs={12}
        md={widget.params.colSizeMd || 6}
        lg={widget.params.colSizeLg || 4}
        xl={widget.params.colSizeXl || 3}
      >
        <DynamicWidget
          name={widget.name} widget={widget.front_component.name} params={widget.params} />
      </Grid>
    // </DashboardHomeItemStyled>
  )
}

export default DashboardHomeItem
