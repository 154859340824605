import styled from 'styled-components'
import colors from '../../../assets/colors'

export const MTPersonalDataStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  text-align: center;

  .name {
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    text-align: center;
    align-self: center;
  }

  .job {
    font-size: 14px;
    color: ${colors.darkGrey3};
    font-weight: 700;
    margin: 0;
    text-align: center;
    align-self: center;
  }
`
