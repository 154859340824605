import styled from 'styled-components'

const SaveSearchesTableStyled = styled.div`
  && {
    padding: 30px 0;
  }
  .MuiTableHead-root {
    th.MuiTableCell-alignLeft {
      text-align: left !important;
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .MuiTableBody-root {
    td {
      padding-top: 20px;
      padding-bottom: 20px;
      * {
        text-align: left;
      }
    }
  }
  
  .toolbar {
    flex-direction: row-reverse;
  }
`

export default SaveSearchesTableStyled
