import styled from 'styled-components'

const BoxVideoInterviewStyled = styled.div`
  .boxInfo {
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(98, 102, 113, 0.06), 0px 2px 2px rgba(98, 102, 113, 0.06);
    border-radius: 6px;
    padding: 20px 30px;
    margin-bottom: 30px;
  }

  .header-box {
    display: flex;
    padding-bottom: 24px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    
    .evaluative-config-disabled {
      color: orangered;
    }
  }

  .header-box::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(209, 210, 211, 0), #d1d2d3, rgba(209, 210, 211, 0));
  }

  .header-text,
  .header-text-button {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-content: flex-start;

    p {
      font-size: 20px;
      font-weight: 600;
      color: #181a1c;
      margin-bottom: 0;
    }
    span {
      font-size: 14px;
      color: #181a1c;
      margin-bottom: 0;
    }
  }

  .header-text-button {
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 10px;
  }

  .header-calendar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  .header-dropdown {
    display: flex;
    padding: 15px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    span {
      font-size: 14px;
      color: #181a1c;
      margin-bottom: 0;
    }
  }

  .wrapper-video {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    /* gap: 50px; */
    margin-top: 50px;
  }
  .container-videos {
    display: flex;
    width: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    max-height: 420px;
    overflow-y: auto;
    overflow-x: none;
    overflow-block: none;
    padding: 10px;
    scrollbar-width: thin;
    scrollbar-color: rgba(63, 67, 80, 0.24) transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(63, 67, 80, 0.24);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  .container-item-video {
    display: flex;
    width: 100%;
    gap: 16px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .container-item-video.active {
    background-color: #f5f5f5;
    color: #333;
    border-radius: 18px;
  }

  .wrapper-video-question {
    display: flex;
    width: auto;
    height: 145px;
    align-items: center;
    gap: 16px;
    position: relative;
  }

  .wrapper-video-question::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(209, 210, 211, 0), #d1d2d3, rgba(209, 210, 211, 0));
  }

  .content-video {
    display: flex;
    width: 380px;
    align-items: flex-start;
    gap: 8px;
    padding: 1rem;
  }

  .content-text {
    display: flex;
    width: 290px;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;

    p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      white-space: normal;
      max-height: 110px;
    }
  }

  .container-thumbnail {
    width: auto;
  }

  .custom-video-player-thumbnail {
    width: auto !important;
    video {
      height: 117px;
      border-radius: 30px !important;
      width: auto !important;
    }
  }

  .chip-custom {
    color: ${props => props.color};
    border: 1px solid ${props => props.theme.primary};
    background-color: #fff !important;
    border-radius: 50px;
    padding: 5px 0;

    span {
      font-weight: 600;
      font-size: 12px;
    }
  }

  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .custom-video-player {
    margin: auto;
    width: auto !important;
    border-radius: 18px !important ;

    video {
      border-radius: 18px !important ;
    }
  }

  .container-video-player {
    max-width: 815px;
    height: 420px;
    border-radius: 18px !important;
  }

  .wrapper-questions {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .button-custom {
    border-radius: 10px;
    color: #ffffff;
  }

  .MuiInputBase-input {
    font-size: 14px;
    line-height: 18px;
    color: #181a1c;
    font-family: Poppins;
  }
  .MuiAutocomplete-endAdornment {
    top: 8px;
  }

  .custom-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .alert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
  }
`
export default BoxVideoInterviewStyled
