import styled from 'styled-components'
import colors from '../../../assets/colors'

export const Container = styled.div`
  border-radius: 6px;
  padding: 12px 0;
`

export const Content = styled.div`
  padding: 24px;
  background-color: ${colors.white};
`

export const Header = styled.div`
  color: ${colors.white};
  @media print {
    .header-table {
      background: ${props => props.theme.primary}!important;
      -webkit-print-color-adjust: exact;

      p {
        color: ${colors.white}!important;
      }
    }
    .wrap-table {
      background-color: ${colors.white}!important;
    }
  }
  display: flex;
  background: ${props => props.theme.primary};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 12px 24px;
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
`

export const ContentRight = styled.div``

export const Icon = styled.p`
  path {
    fill: ${props => props.theme.textPrimary};
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: ${props => props.theme.textPrimary};
  margin-bottom: 0;
`
