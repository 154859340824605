import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Icon = (isNegative = false) => {
  let stroke = isNegative === true ? "#fff" : "#7A808D";
  let strokeWidth = isNegative === true ? "2" : "1.5";
  let opacity = isNegative === true ? "0.6" : "1";

  return <SvgIcon width="17" height="17" viewBox="0 0 17 17">
    <path d="M7.47187 2.16354C7.88536 2.20948 8.0921 2.23246 8.28535 2.29501C8.45679 2.35051 8.61995 2.42893 8.77039 2.52814C8.93995 2.63995 9.08705 2.78705 9.38123 3.08123L14.9873 8.68726C15.7793 9.47929 16.1753 9.87531 16.3237 10.332C16.4542 10.7337 16.4542 11.1663 16.3237 11.568C16.1753 12.0247 15.7793 12.4207 14.9873 13.2127L13.2127 14.9873C12.4207 15.7793 12.0247 16.1753 11.568 16.3237C11.1663 16.4542 10.7337 16.4542 10.332 16.3237C9.87531 16.1753 9.47929 15.7793 8.68726 14.9873L3.08123 9.38123C2.78705 9.08705 2.63995 8.93995 2.52814 8.77039C2.42893 8.61995 2.35051 8.45679 2.29501 8.28535C2.23246 8.0921 2.20948 7.88536 2.16354 7.47187L1.94173 5.47553C1.79073 4.11658 1.71523 3.43711 1.9434 2.93561C2.14345 2.4959 2.4959 2.14345 2.93561 1.9434C3.43711 1.71523 4.11658 1.79073 5.47553 1.94173L7.47187 2.16354Z" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" fill="none" opacity={opacity} />
    <path d="M5.4375 6.1875C5.85171 6.1875 6.1875 5.85171 6.1875 5.4375C6.1875 5.02329 5.85171 4.6875 5.4375 4.6875C5.02329 4.6875 4.6875 5.02329 4.6875 5.4375C4.6875 5.85171 5.02329 6.1875 5.4375 6.1875Z" stroke={stroke} stroke-width={strokeWidth} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none" opacity={opacity} />
  </SvgIcon>
}

export default Icon
