import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const RegularButtonOutlineStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  && {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: ${props => (props.disabled ? '#e0e0e0' : props.color)};
    background: none;
    border-radius: 10px;
    box-shadow: none;
    padding: 8px 14px;
    outline: none;
    border: 2px solid ${props => (props.disabled ? '#e0e0e0' : props.color)};
    transition: 0.5s ease all;
    opacity: ${props => (props.disabled ? 0.6 : 1)};

    :hover {
      background: ${props => props.color};
      color: ${colors.white};
    }

    ${props =>
      props.styledType === 'reset' &&
      css`
        background-color: transparent;
        color: ${colors.black};
        padding: 0;
        font-weight: 500;
        font-size: 14px;

        :hover {
          background-color: transparent;
        }
      `};
    ${props =>
      props.disabled === true &&
      css`
        background: none;
        color: ${colors.blackAlpha41};
        :hover {
          background: none;
          color: ${colors.blackAlpha41};
        }
      `};

    ${props =>
      props.styledType === 'invert' &&
      css`
        background-color: transparent;
        color: ${props.color};
        border: solid 2px ${props.color};
        :hover {
          background-color: transparent;
        }
      `};
    ${props =>
      props.disabled === true &&
      css`
        background: none;

        color: ${colors.blackAlpha41};
        :hover {
          background: none;
          color: ${colors.blackAlpha41};
        }
      `};
  }
`

export default RegularButtonOutlineStyled
