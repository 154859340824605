import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import {InboxOperations} from '../../../redux/inbox'
import {FavoritesOperations} from '../../../redux/favorites'
import {ProfileOperations} from '../../../redux/profile'
import {CitationsActions, CitationsOperations} from '../../../redux/citations'
import {ProcessesActions, ProcessesOperations} from "../../../redux/processes";

const mapStateToProps = state => {
  return ({
    favorites: state.favorites.data,
    client: state.auth.client,
    tagsTypes: state.processes.tagsTypes,
    dataInboxRows: state.processes.dataInbox,
    tablePage: state.processes.page,
    tablePerPage: state.processes.perPage,
    tableProcessFeching: state.processes.isFetching,
    total: state.processes.total,
    dataCitationRows: state.citations.data,
    citationPage: state.citations.page,
    citationSort: state.citations.sort,
    citationPageSize: state.citations.pageSize,
    citationIsFetching: state.citations.isFetching,
  })
}

const mapDispatchToProps = dispatch => {
  return {
    postFavorites: ids => dispatch(FavoritesOperations.postFavorites(ids)),
    deleteFavorites: ids => dispatch(FavoritesOperations.deleteFavorites(ids)),
    changeStatus: (ids, status, date) => dispatch(InboxOperations.changeStatus(ids, status, date)),
    changeTags: (ids, status, date) => dispatch(InboxOperations.changeTags(ids, status, date)),
    fetchUser: candidatureId => dispatch(ProfileOperations.fetchUser(candidatureId)),
    fetchObservations: candidatureId => dispatch(ProfileOperations.fetchObservations(candidatureId)),
    postCitations: (ids, data) => dispatch(CitationsOperations.postCitations(ids, data)),
    deleteCandidature: (id, module) => dispatch(InboxOperations.deleteCandidature(id, module)),
    tableSetPage: (page) => dispatch(ProcessesActions.setPage(page)),
    citationSetPage: (page) => dispatch(CitationsActions.setPage(page)),
    initPlanning: (filters = {}, range = [0, 19], sort = ["", ""]) => dispatch(CitationsOperations.initPlanning(filters, range, sort)),
    fetchItemsInbox: (filters, range, sort, module, filtersGroups = null) => dispatch(ProcessesOperations.fetchItemsInbox(filters, range, sort, module, filtersGroups)),
    fetchCitationsData: (filters, range, sort) => dispatch(CitationsOperations.fetchCitationsData(filters, range, sort))
    }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n
)

export default enharce(View)
