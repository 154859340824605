import styled from 'styled-components'
import colors from '../../../assets/colors'

export const ModalStyled = styled.div`
  && {
    border-radius: 25px;
    margin: 0 auto;
    max-width: 100%;
    margin-top: 15vh;
    width: 685px;
    outline: none;

    .modal-body {
      background-color: ${colors.white};
      padding: 0 50px 50px 50px;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
      outline: none;
      font-family: 'Poppins';

      .grid-decoration {
        position: relative;
        ::before {
          content: '-';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -4px;
        }
      }
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;

        margin-top: 20px;
        ::after {
          content: '*';
          color: #d95117;
          padding-left: 2px;
        }
      }
      input {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins';
      }

      .button-wrapper {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        margin-top: 25px;
      }

      .button-custom {
        border-radius: 10px;
        color: #ffffff;
      }

      .wrapper-tags {
        background: #ffffff;
        border-radius: 8px;
        border: 1.5px solid ${colors.blackAlpha41};
        padding: 12px 16px 12px 16px;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-start;
        box-shadow: 0px 0px 4px 0px rgba(23, 25, 28, 0.05);
      }

      .chip-custom {
        border-radius: 10px;

        span {
          font-weight: 600;
          font-size: 12px;
        }
      }
    }

    .autocomplete-custom {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      gap: 10px !important;
      width: 100% !important;
    }

    .MuiOutlinedInput-root {
      border-radius: 6px !important;
    }

    .MuiAutocomplete-inputRoot,
    .MuiAutocomplete-input:first-child {
      padding-left: 12px !important;
    }

    .MuiAutocomplete-inputRoot {
      padding: 4px !important;
    }

    .MuiAutocomplete-endAdornment {
      top: calc(50% - 12px) !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #b0b3bb !important;
    }

    .MuiInputBase-input::-webkit-input-placeholder {
      color: #9999b3 !important;
      font-size: 14px !important;
      font-family: 'Poppins' !important;
      opacity: 1 !important;
      font-weight: 400 !important;
    }

    .MuiCircularProgress-root {
      width: 25px !important;
      height: 25px !important;
    }

    .container-password {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .container-keyword {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 25px;
    }

    .alert-custom {
      margin-top: 25px;
    }

    .input-custom-keyword {
      width: 150px !important;
      margin-left: 20px;
    }

    .disabled-input {
      background-color: #f0f0f0;
      color: #888888;
    }

    .disabled-input-keyword {
      width: 150px !important;
      margin-left: 20px;
      background-color: #f0f0f0;
      color: #888888;
    }

    .button-outline-custom {
      margin-right: 15px;
    }

    .MuiCircularProgress-root {
      margin-left: 10px;
      width: 25px !important;
      height: 25px !important;
    }

    .container-password {
      display: flex;
      margin-top: 25px;
      flex-direction: row;
      align-items: center;
    }

    .container-url {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      span {
        font-size: 14px;
      }

      input {
        width: 170px !important;
      }
    }
  }
`
