import React, { Component } from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import moment from 'moment'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import compose from 'recompose/compose'
import JSZip from 'jszip'
import { DocumentsList, HeaderProfileProcesses, List, ObservationsTable, Panel } from '../../molecules'
import { withI18n } from '../../hocs'
import { SpinnerIcon } from '../../atoms'
import * as S from './styled'

class ProfileInfoFeedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selecedDocuments: []
    }
  }

  componentDidMount() {
    const id = this.props.id || _get(this.props, 'match.params.slug')
    this.props.initProcess(id)
    this.props.markAsRead(id)
  }

  handleAddObservation = value => {
    const { addComment } = this.props
    addComment(this.props.id || _get(this.props, 'match.params.slug'), value)
  }

  getNotificationSubjectDefault = data => {
    let subCategory = ''
    if (data['Mejora experiencia']){
      subCategory = +", "+data['Mejora experiencia']
    }
    return data.suggestion_id+" - "+ (data.categoria_idea || '') + subCategory +" - "+ this.props.i18n.status[data.state.id]
}

  render() {
    const { data, comments, i18n } = this.props

    const downloadBlob = (blob, name = 'myFile') => {
      const blobUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = blobUrl
      link.download = name
      document.body.appendChild(link)
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      )
      document.body.removeChild(link)
    }

    const formatAttachments = data => {
      if (!data) return []
      return _map(data, ({ id, original_name, url }) => {
        return { id, name: original_name, file: url }
      })
    }

    const formatVariables = oldData => {
      if (!oldData) return []
      return _map(Object.keys(_get(data, 'variable_fields')), item => {
        return { id: item, label: item, text: oldData[item] }
      })
    }

    const handleDownload = () => {
      const newDocuments = this.state.selecedDocuments.map(item => {

        return fetch(item.file, {cache: 'no-cache'})
          .then(response => response.arrayBuffer())
          .then(arrayBuffer => {
            return Promise.resolve({ data: Buffer.from(arrayBuffer, 'binary').toString('base64'), name: item.name, file: item.file })
          });

      })
      var zip = new JSZip()
      Promise.all(newDocuments).then(response => {
        response.map(item => zip.file(item.name, item.data, { base64: true }))
        zip.generateAsync({ type: 'blob' }).then(function(content) {
          downloadBlob(content, 'files_'+ moment().format('YYYYMMDD_Hms')+'.zip')
        })
      })
    }

    const reloadById = (feedbackId) => {
      this.props.fetchProcess(feedbackId);
      this.props.fetchComments(feedbackId);
    };

    if (!data || !comments) return <SpinnerIcon />
    return (
      <S.Container id="profilePrint">
        <HeaderProfileProcesses
          onGoBack={this.props.onGoBack}
          subjectDefault={this.getNotificationSubjectDefault(data)}
          className="no-print"
          userId={_get(data, 'id', '')}
          userName={`${_get(data, 'mongo_temp.nombre', '')} ${_get(data, 'mongo_temp.apellidos', '')}`}
          onChangeProfile={reloadById}
        />
        <S.Title>{`${_get(data, 'suggestion_id')}`}</S.Title>

        <Panel>
          <>
            <S.UserName>
              <S.ProcessIsFavourite favorite={data.is_favorite} onClick={() => this.props.addFav(data.id, !data.is_favorite)} />
              {`${_get(data, 'employee.name')}`} {_get(data, 'employee.surname', '')}
            </S.UserName>
            <Grid container alignItems="flex-start" justifyContent="space-between">
              <Grid item sm={5}>
                <List
                  underlined
                  value={[
                    { id: 1, label: i18n.feedback.category, text: _get(data, 'employee.category') },
                    { id: 2, label: i18n.feedback.email, text: _get(data, 'employee.email') },
                    { id: 3, label: i18n.feedback.dni, text: _get(data, 'employee.dni') },
                    { id: 4, label: i18n.feedback.phone, text: _get(data, 'employee.phone') },
                  ]}
                />
              </Grid>
              <Grid item sm={6}>
                <List
                  underlined
                  value={[
                    { id: 1, label: i18n.feedback.date, text: moment(_get(data, 'created_at')).format('DD/MM/YYYY hh:mm') },
                    { id: 2, label: i18n.process.area, text: _get(data, 'employee.department') },
                    { id: 3, label: i18n.process.department, text: _get(data, 'employee.center') },
                    { id: 4, label: i18n.process.centerProvince, text: _get(data, 'employee.center_province') },
                    { id: 5, label: i18n.process.centerType, text: _get(data, 'employee.center_type') },
                    { id: 6, label: i18n.process.registerData, text: _get(data, 'employee.hiring_date') !== null ? moment(_get(data, 'employee.hiring_date')).format('DD/MM/YYYY') : '' },
                    { id: 7, label: i18n.process.registerData, text: _get(data, 'employee.hiring_date_text') },
                  ]}
                />
              </Grid>
            </Grid>
          </>
        </Panel>
        <Panel title={i18n.feedback.proposal}>
          <S.Proposal>
              {data.proposal}
          </S.Proposal>
        </Panel>

        <Panel title={i18n.feedback.questions}>
          {_get(data, 'variable_fields') && <List underlined columns={2} value={formatVariables(_get(data, 'variable_fields'))} />}
        </Panel>
        {(_get(data, 'attachments') && _get(data, 'attachments').length > 0) && <Panel title={i18n.process.documentation}>
          <S.Toolbar className="no-print">
            <S.ActionButton
              disabled={!this.state.selecedDocuments || this.state.selecedDocuments.length <= 0}
              handleOnClick={handleDownload}
              title={i18n.process.downloadDocs}
              styledType="invert"
            />
          </S.Toolbar>
          <DocumentsList
            check
            value={this.state.selecedDocuments}
            options={formatAttachments(_get(data, 'attachments'))}
            onChange={value => this.setState({ selecedDocuments: value })}
          />
        </Panel>}
        <ObservationsTable showObsInput={true} handleAddObservation={this.handleAddObservation} observations={comments} isFetching={this.props.isFetching} />
      </S.Container>
    )
  }
}

ProfileInfoFeedback.defaultProps = {
  data: {
    process: [],
    documents: []
  }
}

ProfileInfoFeedback.propTypes = {
  i18n: PropTypes.object.isRequired,
  fetchUser: PropTypes.func,
  profileData: PropTypes.object,
  data: PropTypes.array,
  comments: PropTypes.array,
  addFav: PropTypes.func,
  fetchProcess: PropTypes.func,
  fetchComments: PropTypes.func,
  fetchDocuments: PropTypes.func,
  addComment: PropTypes.func,
  markAsRead: PropTypes.func,
  isFetching: PropTypes.bool,
  initProcess: PropTypes.func,
  id: PropTypes.number,
  onGoBack: PropTypes.func
}

const enharce = compose(withI18n)

export default enharce(ProfileInfoFeedback)
