import React, {useCallback, useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import {RegularButton} from '../../atoms'
import qs from 'qs'
import _ from 'lodash'
import * as api from '../../../api'
import * as S from './styled'
import Typography from "@material-ui/core/Typography";

const LandingPull = withRouter(({ classes, location, i18n }) => {

  const search = decodeURIComponent(_.get(location, 'search'))
  const params = qs.parse(search, { ignoreQueryPrefix: true })
  const [response, setResponse] = useState({})
  const [isEnded, setIsEnded] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isError, setIsError] = useState(false)

  const data = useCallback(async () => {
    try {
      setIsFetching(true)
      const response = await api.getPullByNotification(params.notificationId, params.pullId, params.candidatureId, params.pin)
      setResponse(response.data)
      checkForAutoUpdate();

    } catch (e) {
      setIsFetching(false)
      setIsError(true)
    }
  }, [params.notificationId, params.pullId, params.candidatureId, params.pin])

  const checkForAutoUpdate = () => {
    if (params.interested) {
      changeState(params.interested).finally(() => setIsFetching(false));
    } else {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    data()
    // eslint-disable-next-line
  }, [])

  let changeState = (isInterested) => api.patchPullByNotification(params.notificationId, params.pullId, params.candidatureId, params.pin, isInterested).then(() => {
    setIsEnded(true)
  });

  return (
    <Container component="main" className={classes.root}>
      {isEnded === false && response.candidature_name && !isFetching ? (
        <>
          <S.InfoContainer>
            <S.Text>
              {i18n.landingPull.title} <span className="bold">{response.candidature_name}</span>,
            </S.Text>
            <S.Text>Qué tal te va todo, queríamos preguntarte si querías seguir recibiendo ofertas de trabajo que podrían interesarte</S.Text>
            <S.Text>Por favor, si sigues interesado en recibir dichas ofertas de empleo, haz click en el botón de Sigo interesado, en caso contrario haz click en No me interesa</S.Text>
          </S.InfoContainer>
          <S.ButtonsContainer>
            <S.ButtonContainer>
              <RegularButton
                title={i18n.landingPull.interested}
                color="#1BB247"
                onClick={() => {
                  changeState(true)
                }}
              />
            </S.ButtonContainer>
            <S.ButtonContainer>
              <RegularButton
                title={i18n.landingPull.notInterested}
                color="#D3241F"
                onClick={() => {
                  changeState(false)
                }}
              />
            </S.ButtonContainer>
          </S.ButtonsContainer>
        </>
      ) : (
        ''
      )}
      {isError ? (
        <S.InfoContainer>
          <S.Warning className="align-center">{i18n.landingPull.notExist}</S.Warning>
        </S.InfoContainer>
      ) : (
        ''
      )}
      {isEnded ? (
          <S.InfoContainer>
            <Typography className={classes.message} dangerouslySetInnerHTML={{ __html: i18n.landingPull.endDescription }} />
          </S.InfoContainer>
      ) : ''}
    </Container>
  )
})

LandingPull.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object
}

export default LandingPull
