import styled from 'styled-components'
import colors from '../../../assets/colors'

const CheckButtonStyled = styled.div`
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.grey};
  }
`

export default CheckButtonStyled
