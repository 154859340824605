import styled from 'styled-components'

const HereMapsStyled = styled.div`
  && {
    min-width: 200px;
    width: 100%;
    height: auto;
  }
`

export default HereMapsStyled
