import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import {
  CardStatistic,
  DateRangePiker,
  TableExitInterviewSatisfactionSurvey,
  TableFactorsValuesPercentages
} from '../../molecules'
import i18n from '../../../assets/i18n'
import ProfileInfoStyled from './styled'
import _map from "lodash/map";
import Select from "react-select";
import MaterialTable from "material-table";
import {MTDate, MTText} from "../../atoms";

class ExitInterviewStatisticsItems extends Component {
  static propTypes = {
    fetchGlobalStatistics: PropTypes.func,
    globalData: PropTypes.object,
    positionData: PropTypes.array,
    isFetching: PropTypes.bool
  }

  state = {
    focused: '',
    startDate: moment().add(-7, 'days'),
    endDate: moment(),
    filtersActive: {}
  }

  componentDidMount() {
    this.props.initStats(this.state, this.props.module)
  }

  fetchGlobalStatistics = () => this.props.fetchGlobalStatistics(this.state, this.props.module, this.state.filtersActive)

  addValueFilterActive(remoteName, elementsSelected) {
    let filtersActive = this.state.filtersActive
    filtersActive[remoteName] = _map(elementsSelected, element => element.value)
    if (filtersActive[remoteName].length === 0)
      delete filtersActive[remoteName]
    this.setState({ 'filtersActive': filtersActive})
  }


  render() {
    let { globalData, isFetching } = this.props

    const exits = _.get(globalData, 'all_exits', 0)
    const exit_completed = Math.round(_.get(globalData, 'exit_completed', 0))
    const recommends = _.get(globalData, 'recommends', [])
    const factors = _.get(globalData, 'factors', [])
    const reasons = _.get(globalData, 'reasons', [])
    const employees_have_not_done_exit = _.get(globalData, 'employees_have_not_done_exit', []);

    const columns = [
      { title: i18n.statisticsItems.name, field: 'name', render: row => <MTText value={row.name}/> },
      { title: i18n.statisticsItems.surname, field: 'surname', render: row => <MTText value={row.surname}/>  },
      { title: i18n.statisticsItems.dni, field: 'dni', render: row => <MTText value={row.dni}/>  },
      { title: i18n.statisticsItems.zone, field: 'zone', render: row => <MTText value={row.zone}/>  },
      { title: i18n.statisticsItems.hiringDate, field: 'hiringDate', render: row => row.hiringDate ? <MTDate date={row.hiringDate}/> : '-' },
      { title: i18n.statisticsItems.leavingDate, field: 'leavingDate', render: row => row.leavingDate ? <MTDate date={row.leavingDate}/> : '-'},
    ];

    const tableOptions = {
      emptyRowsWhenPaging: false,
      loadingType: 'linear',
      cellStyle: { textAlign: 'center' },
      pageSize: 20,
      pageSizeOptions: [20, 50, 100, 200],
    }

    return (
      <ProfileInfoStyled>
        <Grid container className="title-container">
          <Grid item xs={12} className="buttons-wrapper">
            <div className={"container-filters"+(this.props.isFetching ? ' is-fetching' : '') }>
              {_map(this.props.filtersAvailable, filter => (
                  <Select
                      isMulti={true}
                      isSearchable={true}
                      isClearable={true}
                      key={filter.name}
                      placeholder={filter.name}
                      options={_map(filter.options, option => {
                        if(option instanceof String){
                          return {value: option, label:option}
                        }
                        return {value: option.id, label:option.label};
                      })}
                      onChange={e => this.addValueFilterActive(filter.remoteName, e)}
                      styles={{
                        control: base => ({
                          ...base,
                          minWidth: 160,
                          maxWidth: 480,
                          textAlign: 'left'
                        }),
                        menuList: base => ({
                          textAlign: 'left',
                          ...base,
                        })
                      }}
                  />
              ))}

              <DateRangePiker
                  startDate={this.state.startDate}
                  onStartDateChange={startDate => this.setState({ startDate })}
                  endDate={this.state.endDate}
                  onEndDateChange={endDate => this.setState({ endDate })}
                  onFocusChange={({ focused }) => this.setState({ focused })}
                  focused={this.state.focused}
                  isFetching={isFetching}
                  onSubmit={this.fetchGlobalStatistics}
              />
            </div>
          </Grid>
        </Grid>


        <Grid container spacing={6}>
          <Grid item xs={12} sm={4}>
            <CardStatistic title={i18n.exitInterviewStatisticsItems.exits} value={exits} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardStatistic title={i18n.exitInterviewStatisticsItems.exit_completed} value={exit_completed}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardStatistic title={i18n.exitInterviewStatisticsItems.recommends} value={recommends} progressBar={true}/>
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TableFactorsValuesPercentages data={reasons} tableTitle={i18n.exitInterviewStatisticsItems.reason} tableSize={12} isBigSizeStats={true}/>
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TableExitInterviewSatisfactionSurvey data={factors} isFetching={isFetching} />
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={12}>
            <MaterialTable
              title={i18n.statisticsItems.employeesHaveNotDoneExit}
              data={employees_have_not_done_exit}
              columns={columns}
              options={tableOptions}
              localization={i18n.tableLocalization}
            />
          </Grid>
        </Grid>

      </ProfileInfoStyled>
    )
  }
}

ExitInterviewStatisticsItems.propTypes = {
  module: PropTypes.string,
  initStats: PropTypes.func
}

ExitInterviewStatisticsItems.defaultProps = {
  initStats: () => null
}

export default ExitInterviewStatisticsItems
