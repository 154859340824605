import React from 'react'
import PropTypes from 'prop-types'
import { MTCircularPercentContainerStyled, MTCircularPercentStyled, CircularProgressStyled } from './styled'

const ColorCircularProgress = ({ width, height, thickness, theme, fontSize, ...props }) => {

    if(!thickness){
        thickness = 4;
    }
  const handleValid = (theme) => {
        if(theme) return "theme";
        if (typeof(props.valid) != 'undefined'){
            return props.valid ? 'valid' : 'invalid';
        }
    return props.value && props.value >= 50 ? 'valid' : 'invalid';
  }

  return (
    <div className="circular-progress">
      <CircularProgressStyled fontSize={fontSize} thickness={thickness} width={width} height={height} variant="static" className="top" {...props} value={100} />

      <CircularProgressStyled fontSize={fontSize} thickness={thickness} width={width} height={height} variant="static" className={'bottom ' + handleValid(theme)} {...props} />
    </div>
  )
}

const MTCircularPercent = ({ fontSize,width, height, value, theme, thickness, title, className="", valid, left=false }) => (
  <MTCircularPercentContainerStyled fontSize={fontSize} left={left} >
    {title ? <p className='title-profile'>{title}</p> : ''}
    <MTCircularPercentStyled fontSize={fontSize}>
      <span className={"value "+className}>{value > 0 ? value.toFixed() : 0}%</span>
      <ColorCircularProgress width={width} height={height} theme={theme} thickness={thickness} value={Math.max(0, Math.min(100, value))} valid={valid} size={50} />
    </MTCircularPercentStyled>
  </MTCircularPercentContainerStyled>
)

MTCircularPercent.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}

export default MTCircularPercent
