import styled from 'styled-components'
import colors from '../../../assets/colors'

const CardStatisticStyled = styled.div`

  .card-container {
    background-color: ${colors.white};
    padding: 20px 20px;
    min-width: 200px;
    min-height: 90px;
    box-shadow: 0px 4px 6px rgba(98, 102, 113, 0.06), 0px 2px 2px rgba(98, 102, 113, 0.06);
    border-radius: 6px; 
  }
  @media (max-width: 1216px) {
    .card-container {
        min-height: 114px !important;
    }
  }
  
  .label-stat {
      text-align: left;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: ${colors.blackAlpha50};
      margin: 0;
      display: inline-block;
      align-self: center;
  }

  .value-stat {
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    text-align: right;
    color: ${colors.black};
    display: inline-block;
    align-self: center;
  }

`

export default CardStatisticStyled
