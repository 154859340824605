import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import {MODULES, QUERY_KEYS} from "../../config/constants";

async function getTagsTypes({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  const [_key, {module}] = queryKey;
  if (!module || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;
  return await get(`api/module/${module}/tags_types`);
}

export default function useTagsTypes(module) {
  return useQuery([`${module}:${QUERY_KEYS.TAGS_TYPES}`, {module}], getTagsTypes, {enabled: module !== null && module !== undefined})
}