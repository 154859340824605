import React, { useState } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeader, RegularButton, InputField } from '../../atoms'
import { ReactComponent as ModalIcon } from '../../../assets/images/user-white.svg'

const ModalUpdateBlacklist = ({ open, handleClose, i18n, name, isOnBlacklist, handleOnClickUpdateBlacklist }) => {
  const [disableButton, setDisableButton] = useState(true)

  function handleOnChange(value) {
    if (value === name) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }

  function handleUpdateBlacklistButton() {
    handleOnClickUpdateBlacklist()
    setDisableButton(true)
  }

  return (
    <div>
      <Modal className="modal-wrapper" aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={`${isOnBlacklist ? i18n.modalUpdateBlacklist.removeFromBlacklist : i18n.modalUpdateBlacklist.addToBlacklist} ${name}`} closeClick={handleClose} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <label>{i18n.modalUpdateBlacklist.enterCandidateName}</label>
                </Grid>
                <Grid item xs={12}>
                  <InputField handleOnChange={handleOnChange} placeholder={i18n.modalUpdateBlacklist.writeCandidateName} name="message" />
                </Grid>
              </Grid>
              <div className="button-wrapper">
                <RegularButton handleOnClick={handleUpdateBlacklistButton} type="reset" title={isOnBlacklist ? i18n.modalUpdateBlacklist.remove : i18n.modalUpdateBlacklist.add} disabled={disableButton} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </div>
  )
}

ModalUpdateBlacklist.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  name: PropTypes.string,
  handleOnClickUpdateBlacklist: PropTypes.func
}

const enharce = compose(withI18n)

export default enharce(ModalUpdateBlacklist)
