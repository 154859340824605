import styled from 'styled-components'
import colors from '../../../assets/colors'

const SatisfactionSurveyTable = styled.div`
  && {
    padding: 12px 24px 24px;
    background-color: ${colors.white};
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(98, 102, 113, 0.06), 0px 2px 2px rgba(98, 102, 113, 0.06);
  }
  .satisfaction_table {
    
    .table_head {
      border: unset;
      .row {
        .row_element {
          text-align: center;
          padding: 16px !important;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          color: black;
          background-color: white !important;
          border-bottom: 0;
        }
        .row_element:first-child {
          text-align: left !important;
        }
        .header_icon_container {
          position: relative;
          display: inline-block;
          width: 30px;
          height: 30px;
          
          .satisfaction_icon {
            width: 30px;
            height: 30px;
          }
        }
        .factor_name_header {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }
    .table_body {
      .row {
        .row_element {
          padding: 6px !important;
            
          .factor_number {
            border-radius: 100%;
            display: inline-block;
            background-color: #F2F2F4;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin: 0 8px 0 0;
            font-weight: 500;
            color: #7A808D;
          }
          
          .stat_container {
            border-radius: 12px;
          }
          .value {
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 100%;
            display: inline-block;
            margin: 4px 0;
            color: white;
          }
          .value_superAgree {
            background-color: ${props => props.colors.superAgree + 45};
          }
          .value_superAgree.max_stat_value {
            background-color: ${props => props.colors.superAgree};
          }
          .value_agree {
            background-color: ${props => props.colors.agree + 45};
          }
          .value_agree.max_stat_value {
            background-color: ${props => props.colors.agree};
          }
          .value_littleDisagree {
            background-color: ${props => props.colors.littleDisagree + 45};
          }
          .value_littleDisagree.max_stat_value {
            background-color: ${props => props.colors.littleDisagree};
          }
          .value_disagree {
            background-color: ${props => props.colors.disagree + 45};
          }
          .value_disagree.max_stat_value {
            background-color: ${props => props.colors.disagree};
          }
         
        }
        
        .last_row_cell {
          border-bottom: 0;
        }
    
        .factor_name {
          background-color: transparent;
          text-align: left !important;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
        }
        
        .superAgree {
          background-color: ${props => props.colors.superAgree + 12};
          color: #37bc6a;
        }
        .superAgree.max_stat_container {
          background-color: ${props => props.colors.superAgree + 50};
        }

        .agree {
          background-color: ${props => props.colors.agree + 12};
          color: #FAB830;
        }
        .agree.max_stat_container {
          background-color: ${props => props.colors.agree + 50}!important;
        }

        .littleDisagree {
          background-color: ${props => props.colors.littleDisagree + 12};
          color: #F87A26;
        }
        .littleDisagree.max_stat_container {
          background-color: ${props => props.colors.littleDisagree + 50};
        }

        .disagree {
          background-color: ${props => props.colors.disagree + 12};
          color: #F4304A;
        }
        .disagree.max_stat_container {
          background-color: ${props => props.colors.disagree + 50};
        }
      }
    }
  }

`

export default SatisfactionSurveyTable
