import React from 'react'
import { PageTitle } from '../../atoms'
import { Grid } from '@material-ui/core'
import UserManualStyled from './styled'
import i18n from '../../../assets/i18n'

const ProcessesUserManual = () => {

  return (
    <UserManualStyled>
      <Grid container className="title-container">
        <Grid item xs={12} sm={6} className="title">
          <PageTitle title={i18n.userManual.title} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <iframe
          className="iframe-container"
          title="Manual de uso Línea de Empleado"
          src="https://scribehow.com/shared/Linea_de_empleado__Manual_de_uso__4Z6bDklcQmmYB3Q2UNJSUw"
        ></iframe>
      </Grid>
    </UserManualStyled>
  )
}

export default ProcessesUserManual
