import styled from 'styled-components'
import colors from '../../../assets/colors'

const TextAreaFieldStyled = styled.div`
  textarea {
    min-height: 40px;
    background: ${colors.white};
    border: 1px solid ${colors.greyAlpha50};
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 15px;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.03em;
    color: ${colors.black};
    outline: none;
    width: 100%;
    resize: none;

    ::placeholder {
      font-weight: normal;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.03em;
      color: ${colors.blackAlpha40};
    }
  }
`

export default TextAreaFieldStyled
