import React, {useEffect, useState} from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import colors from '../../../assets/colors'
import TableStatisticStyled from './styled'
import { ReactComponent as StatsIcon } from '../../../assets/images/stats.svg'
import i18n from '../../../assets/i18n'
import { MTCircularPercent } from '../../atoms'
import {isArray} from "lodash";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const MTPercent = value => <MTCircularPercent value={value} thickness={4} width={50} height={50} />

const TableStatistic = ({title, overrideTitle = null, i18n, data, whatsappCounter = false, propsColumns = [], isDashboard = false, customHeight, inheritHeight = false, maxHeight = null}) => {
  const tableOptions = {
    emptyRowsWhenPaging: false,
    paginationType: 'stepped',
    loadingType: 'linear',
    headerStyle: { backgroundColor: colors.grey, textAlign: 'center' },
    pageSize: 20,
    pageSizeOptions: [20, 50, 100, 200],
  }

  const tableComponents = {
    Container: MTContainer,
    Toolbar: () => ''
  }

  const [overrideColumns, setOverrideColumns] = useState([]);

  useEffect(() => {
    if (isArray(propsColumns) && propsColumns.length > 0) {
      let resultColumns = [];
      propsColumns.map(column => {
        return resultColumns.push({
          title: i18n.dashboard.widgets.tableColumns[column.field],
          field: column.field,
          render: (row) => MTCustomColumn(row, column),
          cellStyle: {
            textAlign: column.textAlign || 'center'
          }
        })
      });
      setOverrideColumns(resultColumns);
    }
  }, [propsColumns]);

  const columns = [
    { title: i18n.statisticsItems.name, field: 'position_name', render: MTTitle, cellStyle: { textAlign: 'center' } },
    { title: i18n.statisticsItems.interviewed, field: 'candidatures', render: MTStat1, cellStyle: { textAlign: 'center' } },
    {
      title: i18n.statisticsItems.candidaturesSuitables,
      field: 'candidatures_suitables_percent',
      render: rowData => MTPercent(Math.round(rowData.candidatures_suitables_percent)),
      cellStyle: { textAlign: 'center' }
    },
    {
      title: i18n.statisticsItems.hiredVsSuitables,
      field: 'hired_vs_suitables_percent',
      render: rowData => MTPercent(Math.round(rowData.hired_vs_suitables_percent)),
      cellStyle: { textAlign: 'center' }
    },
  ]

  const whatsappCounterColumns = [
    {title: i18n.statisticsItems.whatsappCounter.section, field: 'section', cellStyle: { textAlign: 'center', fontSize: 12, fontWeight: 500 }},
    {title: i18n.statisticsItems.whatsappCounter.sent, field: 'whatsapp', cellStyle: { textAlign: 'center', fontSize: 12, fontWeight: 500 }},
  ]

  return (
    <>
      <TableStatisticStyled isDashboard={isDashboard} customHeight={customHeight} inheritHeight={inheritHeight} maxHeight={maxHeight}>
        {overrideTitle ? overrideTitle : <div className="header-stat">
          <StatsIcon />
          <p>{title}</p>
        </div>}
        <div className="chart-wrapper">
          <MaterialTable
            search={false}
            columns={overrideColumns && overrideColumns.length > 0 ? overrideColumns : (whatsappCounter ? whatsappCounterColumns : columns)}
            data={data ? data : []}
            options={whatsappCounter ? {...tableOptions, paging: false} : {...tableOptions, paging: true}}
            components={tableComponents}
            localization={i18n.tableLocalization}
            draggable={false}
          />
        </div>
      </TableStatisticStyled>
    </>
  )
}

const MTContainer = props => <div {...props} />

const MTTitle = rowData => (
  <span>
    <strong>{rowData.position_name}</strong>
  </span>
)
const MTCustomColumn = (rowData, column) => {
  switch (column.type) {
    case "checkbox":
      return (<span><strong>{rowData[column.field] ? <CheckCircleIcon style={{color: "#198754"}}/> : <CancelIcon color="error"/>}</strong></span>);
    case "percentage":
      return MTPercent(parseInt(rowData[column.field] || 0));
    default: // Default será tipo text.
      return (<span><strong>{rowData[column.field]}</strong></span>);
  }
}
const MTStat1 = rowData => (
  <span>
    <strong>{rowData.candidatures}</strong>
  </span>
)

TableStatistic.propTypes = {
  title: PropTypes.string,
  i18n: PropTypes.object.isRequired,
  data: PropTypes.array,
  isFetching: PropTypes.bool
}

TableStatistic.defaultProps = {
  title: i18n.statisticsItems.tableTitle,
  data: [],
  isFetching: false
}

const enharce = compose(withI18n)

export default enharce(TableStatistic)
