import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import colors from '../../../assets/colors'
import styles from './styles'

const DrawerCloseButton = ({ classes, ...rest }) => (
  <Button {...rest} className={classes.root}>
    <ClearIcon fontSize="large" htmlColor={colors.white} />
  </Button>
)

DrawerCloseButton.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DrawerCloseButton)
