import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import {AppBarInbox} from '../../organism'
import {MODULES} from '../../../config/constants'
import OfferCard from "../../molecules/offer-card";
import {Paginator} from 'primereact/paginator';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import * as api from "../../../api";
import PushOffersStyled from "./styled";
import Grid from "@material-ui/core/Grid";
import {DateRangePiker} from "../../molecules";
import moment from "moment";
import {getPushOfferWhatsappCounter} from "../../../api";
import {Badge} from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Checkbox from '@material-ui/core/Checkbox'

const PushOffers = ({
                      classes, i18n, data, totalItems, pageSizeOptions,
                      fetchPushOffers, parseFiltersFromApiToFrontFormat, resetPushOffers, client
                    }) => {

  const defaultPageInfo = {first: 1, rows: pageSizeOptions[0]}
  const [paginationInfo, setPaginationInfo] = useState(defaultPageInfo);
  const [startDate, setStartDate] = useState(moment().add(-7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [focused, setFocused] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [whatsappCounter, setWhatsappCounter] = useState(null);
  const [showMyPushOffers, setShowMyPushOffers] = useState(true); 

  const setFetchToFalse = () => setTimeout(() => setIsFetching(false), 300);

   useEffect(() => {
    resetPushOffers()
    const defaultRange = [0, pageSizeOptions[0] - 1];
    const defaultFilters = {
      "created_at": {
        "from": startDate.format('DD/MM/YYYY'),
        "to": endDate.format('DD/MM/YYYY')
      }
    }
    setIsFetching(true);
    if (client && client.whatsapp_available) {
      getPushOfferWhatsappCounter(MODULES.currentModule(), defaultFilters, showMyPushOffers).then((res) => setWhatsappCounter(res));
    }
  }, [pageSizeOptions, resetPushOffers]);

  useEffect(() => {
    refetchPushOffers();    
  }, [!showMyPushOffers])

  const changePage = (pageInfo) => {
    setPaginationInfo(pageInfo);
    let start = startDate.format('DD/MM/YYYY');
    let end = endDate.format('DD/MM/YYYY');
    let filters = {"created_at": {"from": start, "to": end}};  
    fetchPushOffers(MODULES.ID_TALENT_ACQUISITION, api.getRange(pageInfo.page, pageInfo.rows), filters, showMyPushOffers)     
  }

  const refetchPushOffers = () => {
    let start = startDate.format('DD/MM/YYYY');
    let end = endDate.format('DD/MM/YYYY');
    let filters = {"created_at": {"from": start, "to": end}};
    setIsFetching(true);
    if (client && client.whatsapp_available) {
      getPushOfferWhatsappCounter(MODULES.currentModule(), filters, showMyPushOffers).then((res) => setWhatsappCounter(res));
    }        
    fetchPushOffers(MODULES.ID_TALENT_ACQUISITION, [0, pageSizeOptions[0] - 1], filters, showMyPushOffers).then(setFetchToFalse);
  }
  
  const handleCheckboxChange = () => {
    setShowMyPushOffers(!showMyPushOffers);   
  };
 
  return (
    <PushOffersStyled>
      <div className={classes.root}>
          <AppBarInbox
              module={MODULES.ID_TALENT_ACQUISITION}
              section={"offerCard"}
              forceTotal={data.length}
          />
        <Grid container className="title-container">
          <Grid item xs={12} sm={12} className="buttons-wrapper"
                style={{display: "flex", justifyContent: "flex-start", marginTop: 16}}
          >
            <DateRangePiker
              startDate={startDate}
              onStartDateChange={date => setStartDate(date)}
              endDate={endDate}
              onEndDateChange={date => setEndDate(date)}
              onFocusChange={({focused}) => setFocused(focused)}
              focused={focused}
              isFetching={isFetching}
              onSubmit={refetchPushOffers}
            /> 

            <Checkbox checked={showMyPushOffers} onChange={handleCheckboxChange} disabled={isFetching} />
            <h5 className="title-checkbox">{i18n.offerCard.showOffers}</h5>       
            
            {client && client.whatsapp_available && whatsappCounter &&
              <Badge badgeContent={whatsappCounter} color="error" max={1000000} className="container_icon">
                <WhatsAppIcon className="counter_icon"/>
              </Badge>
            }                     
          </Grid>         
        </Grid>
        <div className="row"> 
        {
          data.length > 0
            ? data.map((offer) => (<OfferCard key={offer.id} item={offer}
                                              filters={parseFiltersFromApiToFrontFormat(offer.filters)}/>))
            : <h5 className="push_offer__empty_content">{i18n.offerCard.empty}</h5>
        }
        </div>
        {
          totalItems > pageSizeOptions[0] &&
          <Paginator
            className="primereact-paginator"
            first={paginationInfo.first}
            rows={paginationInfo.rows}
            totalRecords={totalItems}
            rowsPerPageOptions={pageSizeOptions}
            onPageChange={changePage}
          />
        }
      </div>
    </PushOffersStyled>
  )
}

PushOffers.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default PushOffers
