import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    AppBarInbox,
    ExitInterviewProfileInfo,
    ProcessTable,
} from '../../organism'
import {MODULES} from '../../../config/constants'

class Inbox extends Component {
  render() {
    const { classes, filters, i18n } = this.props
    return (
      <div className={classes.root}>
        <AppBarInbox
          showActions
          filterName={i18n.processesTable.filterProcesses}
          module={MODULES.ID_EXIT_INTERVIEW}
          section={"exitInterview"}
        />
          <ProcessTable
              title={i18n.exitInterview.title}
              filters={filters}
              module={MODULES.ID_EXIT_INTERVIEW}
              showChangeStatus
              rowViewRender={(id) => <ExitInterviewProfileInfo id={id} />}
              notificationMultipleEnabled={true}
          />
      </div>
    )
  }
}

Inbox.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  auxFilters: PropTypes.object,
  setAuxFilters: PropTypes.func,
  fetchSaveSearch: PropTypes.func,
  saveSearchData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default Inbox
