import styled from 'styled-components'

const ModulesStyled = styled.div`
  .title-container {
    margin-top: 38px;

    .title {
      align-items: flex-end;
      display: flex;
    }
  }

  .modules-wrapper {
    padding: 50px 0;
  }

  .buttons-wrapper {
    text-align: right;
  }
  
  .container-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    
    &.is-fetching div{
      background-color: #f2f2f2;
    }
    
    & > div {
      font-size: 1.3em;
    }
  }
`

export default ModulesStyled
