import {AuthUtils} from "../redux/auth";

var mixPanel = require('mixpanel-browser');

mixPanel.init('0be6f0caca09a70bee9c7a624a4c0599', {debug: true});
//TODO: Create alias del usuario logado


mixPanel._track = mixPanel.track;

mixPanel.track = function (event_name, properties, options, callback) {

    const username = AuthUtils.getUserName();
    if(username && window.location.hostname !== 'localhost'){
        const client_id = localStorage.getItem('client');

        properties = {
            ...properties,
            client: client_id
        }
        mixPanel._flags.identify_called || mixPanel.identify(username);
        mixPanel._track(event_name, properties, options, callback);
    }else{
        // eslint-disable-next-line no-console
        console.warn('EventTracker: Skip '+event_name +' event. Token not found');
    }

}


export default mixPanel;
