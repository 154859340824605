import styled from 'styled-components'

const TableToggleStyled = styled.div`
  .containerButtons {
    display: inline-flex;
    align-items: center;
    align-self: end;
    justify-content: flex-end;
    gap: 10px;
    background-color: #f0f1f2;
    padding: 8px 10px;
    border-radius: 50px;
  }

  .containerToggle {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 20px;

    h5 {
      margin-bottom: 0px;
    }
  }
`
export default TableToggleStyled
