import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, CircularProgress, Typography, TextField } from '@material-ui/core'
import { RegularSelect, RegularButton } from '../../atoms'
import { toast } from 'react-toastify'
import qs from 'qs'
import _ from 'lodash'
import * as api from '../../../api'
import i18n from '../../../assets/i18n'

class Reschedule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: true,
      success: false,
      citationOptions: [],
      availabilityId: null,
      reason: ''
    }
  }

  async componentDidMount() {
    const { location } = this.props
    const search = decodeURIComponent(_.get(location, 'search'))
    const params = qs.parse(search, { ignoreQueryPrefix: true })

    try {
      const results = await api.getRescheduleAvailability(params)
      this.setState({ citationOptions: results.data })
    } catch (error) {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    } finally {
      this.setState({ isFetching: false })
    }
  }

  onChangeCitation = availabilityId => {
    this.setState({ availabilityId })
  }

  onSendReschedule = async () => {
    const { location } = this.props
    const { availabilityId } = this.state
    const { reason } = this.state
    this.setState({ isFetching: true })

    const search = decodeURIComponent(_.get(location, 'search'))
    let params = qs.parse(search, { ignoreQueryPrefix: true })
    _.assign(params, {
      availabilityId: availabilityId,
      reason: reason
    });
    try {
      await api.addRescheduleAvailability(params)
      this.setState({ success: true })
    } catch (error) {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    } finally {
      this.setState({ isFetching: false })
    }
  }

  renderContent = () => {
    const { classes } = this.props
    const { success, citationOptions, availabilityId } = this.state

    if (_.size(citationOptions) === 0) {
      return <Typography className={classes.message}>{i18n.landingReschedule.noDates}</Typography>
    }

    if (success) {
      return <Typography className={classes.message}>{i18n.landingReschedule.successSend}</Typography>
    }

    return (
      <Grid container>
        <Grid item xs={12} className={classes.selectWrapper}>
          <Typography className={classes.title}>{i18n.landingReschedule.title}</Typography>
          <RegularSelect
            keyLabel="label"
            keyValue="id"
            onChange={this.onChangeCitation}
            options={citationOptions}
            defaultOption={i18n.landingReschedule.putAvailability}
          />
          <TextField
              fullWidth
              variant="outlined"
              value={this.state.reason}
              onChange={(e) => this.setState({ reason: e.target.value })}
              placeholder={i18n.landingReschedule.reasonPlaceholder}
              margin="normal"
          />
        </Grid>
        {availabilityId && this.state.reason && (
            <Grid item xs={12} className={classes.btnWrapper}>
              <RegularButton handleOnClick={this.onSendReschedule} title={i18n.landingReschedule.send} />
            </Grid>
        )}
      </Grid>
    )
  }

  render() {
    const { classes } = this.props
    const { isFetching } = this.state

    return (
      <Container component="main" className={classes.root}>
        <div className={classes.contentWrapper}>{isFetching ? <CircularProgress size={40} /> : this.renderContent()}</div>
      </Container>
    )
  }
}

Reschedule.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object
}

export default Reschedule
