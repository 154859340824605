import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import TitleLineStyledStyled from './styled'

import { ReactComponent as SkillIcon } from '../../../assets/images/skills.svg'

const TitleLine = ({ title, icon=<SkillIcon /> }) => (
  <TitleLineStyledStyled>
    <p className="title">
      <span>
        {icon}
        {title}
      </span>
    </p>
  </TitleLineStyledStyled>
)

TitleLine.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element
}

const enharce = compose()

export default enharce(TitleLine)
