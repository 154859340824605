import React from 'react'
import PropTypes from 'prop-types'
import {AppBarInbox, ProcessTable, ProfileInfo} from '../../organism'
import {MODULES} from '../../../config/constants'

const Favorites = ({ classes, i18n }) => {
  let queryFilters = {isFavorite: true}

  return (
    <div className={classes.root}>
        <AppBarInbox
            showActions
            no_filterName={i18n.processesTable.filterProcesses}
            module={MODULES.ID_TALENT_ACQUISITION}
            section={"favorites"}
        />
      <ProcessTable
        title={i18n.favorites.title} 
        module={MODULES.ID_TALENT_ACQUISITION} 
        defaultFilters={queryFilters}
        rowViewRender={(id) => <ProfileInfo id={id} />}
        showChangeStatus
      />
    </div>
  )
}

Favorites.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default Favorites
