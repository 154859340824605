import React, {useEffect, useState} from 'react'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _map from 'lodash/map'
import ArrowDropDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Grid from '@material-ui/core/Grid'
import {toast} from 'react-toastify'
import PropTypes from 'prop-types'
import ProfileButtonsStyled, * as S from './styled'
import {BackButton, MTText, SimpleMenu} from '../../atoms'
import {AlertDialog} from '../../molecules'
import ModalMailCustom from '../modal-send-mail-custom'
import {MODULES} from '../../../config/constants'
import {print} from '../../../utils/print'
import routes from "../../../config/routes";
import _ from "lodash";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/KeyboardArrowLeft";
import ArrowForward from "@material-ui/icons/KeyboardArrowRight";
import {matchPath} from "react-router";

const HeaderProfileProcesses = ({
                               history,
                               i18n,
                               data,
                               changeStatus,
                               addFav,
                               sendEmailCustom,
                               fetchStatusTypes,
                               statusTypes,
                               deleteProcess,
                               match,
                               enableStatusBackgroundColor = false,
                               location,
                               onChangeProfile,
                               userId,
                               dataInboxRows,
                               tablePage,
                               tableSetPage,
                               tableProcessFeching,
                               tablePerPage,
                               ...props
                             }) => {

  const anchorRef = React.useRef(null)
  const [isOpenMailCustomModal, setOpenMailCustomModal] = React.useState(false)
  const [removeModal, setRemoveModal] = React.useState(false)
  const currentModuleId = match.params.moduleId || MODULES.currentModule() || MODULES.ID_EMPLOYEE_LINE
  useEffect(() => {
    fetchStatusTypes(currentModuleId) // not used getCurrentModuleId
  }, [fetchStatusTypes, currentModuleId])

  const actionsOptions = [
    {
      value: 'markFavorite',
      text: data && data.is_favorite ? i18n.headerProfileButtons.removeFavorites : i18n.headerProfileButtons.markFavorite
    },
    {value: 'print', text: i18n.headerProfileButtons.print},
    {value: 'mailCustom', text: i18n.headerProfileButtons.mailCustom},
    {value: 'removeProcess', text: i18n.headerProfileButtons.remove}
  ]

  const handleChangeStatus = status => {
    changeStatus(data.id, status, currentModuleId)
  }

  const handleClickActionsButton = item => {
    switch (item) {
      case 'markFavorite':
        addFav(data.id, !data.is_favorite, currentModuleId)
        break
      case 'print':
        print('profilePrint')
        break
      case 'mailCustom':
        setOpenMailCustomModal(true)
        break
      case 'removeProcess':
        setRemoveModal(true)
        break
      default:
        break
    }
  }

  const handleSubmitMailCustom = mailCustom => {
    sendEmailCustom(data.id, mailCustom.subject, mailCustom.message, currentModuleId)
  }

  const handleDeleteProcess = () => {
    if (data.id) {
      deleteProcess(data.id, currentModuleId).then(() => {
        toast.success(i18n.process.deleteSuccess, {
          position: toast.POSITION.BOTTOM_CENTER
        })
        let redirectTo = routes.processes;
        if (currentModuleId == MODULES.ID_FEEDBACK) {
          redirectTo = routes.feedbackInbox;
        }
        history.push(redirectTo + '?keepFilters=1');
      })
    }
  }

  const status = _get(data, 'state')

  const ButtonTitle = ({title}) => (
    <>
      <span>{title}</span> <ArrowDropDownIcon/>
    </>
  )

  const [id, setId] = useState(userId || _.get(match, 'params.slug'))
  const [loadingNextPage, setLoadingNextPage] = React.useState(false)
  const [loadingPreviousPage, setLoadingPreviousPage] = React.useState(false)

  const getModule = () => {
    if (matchPath(location.pathname, {path: routes.profileProcesses})) {
      return MODULES.ID_EMPLOYEE_LINE;
    } else if (matchPath(location.pathname, {path: routes.profileFeedback})) {
      return MODULES.ID_FEEDBACK;
    }
  }

  let currentTableRows
  let currentObjectResponse
  let currentItemNum = 1

  const handleOnChangePage = (newId) => {
    setId(newId)
    onChangeProfile(newId)
  }

  const pageGetCurrentIndexFromTable = () => {
    return _.findIndex(currentTableRows, {id})
  }

  const hasNextPage = () => {
    return currentItemNum + 1 <= currentObjectResponse.total
  }

  const hasPreviousPage = () => {
    return currentItemNum - 1 >= 1
  }

  const getNextPageId = () => {
    const currentIndex = pageGetCurrentIndexFromTable()
    if (currentTableRows[currentIndex + 1]) {

      history.push(`/profile/${getModule()}/${currentTableRows[currentIndex + 1].id}`)

      return handleOnChangePage(currentTableRows[currentIndex + 1].id)
    } else {
      changePageInTable(+1)
    }
    return false
  }

  const getPreviousPageId = () => {
    const currentIndex = pageGetCurrentIndexFromTable()
    if (currentTableRows[currentIndex - 1]) {

      history.push(`/profile/${getModule()}/${currentTableRows[currentIndex - 1].id}`)
      return handleOnChangePage(currentTableRows[currentIndex - 1].id)
    } else {
      changePageInTable(-1)
    }
    return false
  }

  const changePageInTable = (sumOrRest) => {
    let newTablePage
    newTablePage = tablePage + sumOrRest
    tableSetPage(newTablePage)

    if (sumOrRest > 0) {
      setLoadingNextPage(newTablePage)
    } else {
      setLoadingPreviousPage(newTablePage)
    }
  }

  const onInit = () => {

    let currentPage = tablePage
    let currentPerPage = tablePerPage
    let currentIsProcessing = tableProcessFeching

    // wait until data is loaded
    if (
      (loadingNextPage !== false && loadingNextPage !== currentPage) || currentIsProcessing
      || (loadingPreviousPage !== false && loadingPreviousPage !== currentPage) || currentIsProcessing
    ) {
      return false
    }

    currentObjectResponse = dataInboxRows
    currentTableRows = dataInboxRows.data

    if (!id || !currentTableRows) {
      return false
    }

    if (loadingNextPage !== false && currentTableRows[0].itemCount === (loadingNextPage * currentPerPage) + 1) {
      handleOnChangePage(currentTableRows[0].id)
      setLoadingNextPage(false)
      return false
    }

    if (loadingPreviousPage !== false && currentTableRows[0].itemCount === ((loadingPreviousPage * currentPerPage) + 1)) {
      handleOnChangePage(currentTableRows[currentPerPage - 1].id)
      setLoadingPreviousPage(false)
      return false
    }

    if (!currentTableRows[pageGetCurrentIndexFromTable()]) {
      return false
    }

    currentItemNum = currentTableRows[pageGetCurrentIndexFromTable()].itemCount

    return true
  }

  if (!onInit()) {
    return null;
  }


  const statusOptions = _map(statusTypes, item => {
    return {
      text: <MTText style={{paddingLeft: item.level * 15, fontWeight: item.level === 0 ? 'bold' : 'normal'}}
                    value={i18n.status[item.id]} color={enableStatusBackgroundColor ? '' : item.color}/>,
      value: item.id,
      backgroundColor: enableStatusBackgroundColor ? item.color : false
    }
  })

  return (
    <ProfileButtonsStyled className="no-print">
      <Grid container alignItems="center">
        <Grid item sm={3}>
          <BackButton title={i18n.headerProfileButtons.back} onClick={() => {
            localStorage.getItem('backLocation') ? history.push(localStorage.getItem('backLocation')) : history.goBack();
          }}/>
        </Grid>
        <Grid item sm={9} className={'buttons-wrapper'}>
          <S.ButtonContainer variant="contained" color="primary" ref={anchorRef} aria-label="Split button">
            {status && (
              <SimpleMenu options={statusOptions} value={status.id}
                          onChange={id => handleChangeStatus(_find(statusTypes, {id}))}>
                <S.HeaderButton color={status.color} styledType="invert"
                                title={<ButtonTitle title={i18n.status[status.id]}/>}/>
              </SimpleMenu>
            )}
            <SimpleMenu options={actionsOptions} onChange={handleClickActionsButton}>
              <S.HeaderButton title={<ButtonTitle title={i18n.headerProfileButtons.actions}/>}/>
            </SimpleMenu>
          </S.ButtonContainer>
          <p className={"header-pagination-count"}>
            <span>
              <span className="currentItemNumber">{currentItemNum}</span> / {currentObjectResponse.total}
            </span>
          </p>
          <ButtonGroup color="primary" aria-label="Split button" className={"header-profile-arrows"}>

            <Button variant="contained" onClick={() => getPreviousPageId()} disabled={hasPreviousPage() === false}>
              <span>&#8205;</span>
              <ArrowBack/>
            </Button>

            <Button variant="contained" onClick={() => getNextPageId()} disabled={hasNextPage() === false}>
              <span>&#8205;</span>
              <ArrowForward/>
            </Button>

          </ButtonGroup>
        </Grid>
      </Grid>
      <ModalMailCustom open={isOpenMailCustomModal} handleClose={() => setOpenMailCustomModal(false)}
                       onSubmit={handleSubmitMailCustom} {...props} />
      <AlertDialog open={removeModal} onClose={() => setRemoveModal(false)} onSuccess={handleDeleteProcess}
                   title={i18n.process.deleteModalTitle} text={i18n.process.deleteModalText}/>
    </ProfileButtonsStyled>
  )
}

HeaderProfileProcesses.propTypes = {
  i18n: PropTypes.object.isRequired,
  data: PropTypes.object,
  match: PropTypes.object,
  setData: PropTypes.func,
  changeStatus: PropTypes.func,
  addFav: PropTypes.func,
  sendEmailCustom: PropTypes.func,
  fetchStatusTypes: PropTypes.func,
  statusTypes: PropTypes.array,
  onGoBack: PropTypes.func,
  deleteProcess: PropTypes.func
}

export default HeaderProfileProcesses
