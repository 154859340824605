import React from 'react'
import { SingleDatePicker } from 'react-dates'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CachedIcon from '@material-ui/icons/Cached'
import moment from 'moment'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import DateRangePikerStyled from './styled'

const DateRangePiker = ({
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  focused,
  onFocusChange,
  onSubmit,
  isFetching,
  showButton = true,
  disabled = false,
}) => {
  const commons = {
    disabled: isFetching,
    showClearDate: true,
    showDefaultInputIcon: true,
    small: true,
    numberOfMonths: 1,
    reopenPickerOnClearDate: true,
    hideKeyboardShortcutsPanel: true
  }
  return (
    <DateRangePikerStyled>
      <SingleDatePicker
        {...commons}
        date={startDate}
        onDateChange={onStartDateChange}
        focused={focused === 'startDate'}
        onFocusChange={({ focused }) => onFocusChange({ focused: focused ? 'startDate' : '' })}
        id="startDate_id"
        placeholder={i18n.statisticsItems.startDate}
        isOutsideRange={date => {
          return endDate && moment(date).isAfter(endDate)
        }}
        disabled={disabled}
      />
      <ArrowForwardIcon className="separator" />
      <SingleDatePicker
        {...commons}
        date={endDate}
        onDateChange={onEndDateChange}
        focused={focused === 'endDate'}
        onFocusChange={({ focused }) => onFocusChange({ focused: focused ? 'endDate' : '' })}
        id="endDate_id"
        placeholder={i18n.statisticsItems.endDate}
        isOutsideRange={date => {
          return startDate && moment(date).isBefore(startDate)
        }}
        disabled={disabled}
      />
      {showButton && (
        <button variant="contained" className={`submit ${isFetching ? 'rotate' : ''}`} onClick={onSubmit}>
          <CachedIcon />
        </button>
      )}
    </DateRangePikerStyled>
  )
}

DateRangePiker.propTypes = {
  Icon: PropTypes.element,
  title: PropTypes.string,
  titleInfo1: PropTypes.string,
  titleInfo2: PropTypes.string,
  titleInfo3: PropTypes.string,
  value1: PropTypes.string,
  value2: PropTypes.string,
  value3: PropTypes.string
}

export default DateRangePiker
