import styled from 'styled-components'
import colors from '../../../assets/colors'

const LinearPercentWithTitleStyled = styled.div`
   &&>p{
        margin-bottom: 8px;
    }
    .title-name{
        margin-bottom: 2px;
    }
  @media print {
    .MuiLinearProgress-root {
      background: ${colors.greyAlpha25}!important;
      -webkit-print-color-adjust: exact;
    }

    .linear-percent {
      color: ${props => props.theme.primary}!important;
      -webkit-print-color-adjust: exact;
    }
  }
  .MuiLinearProgress-root {
    height: 5px;
    border-radius: 8px;
    background: ${colors.greyAlpha25};
  }

  .linear-percent {
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 0;
    margin-top: 10px;
    color: ${props => props.theme.primary};
    text-align: center;
    display: block;
  }

  .title-name{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
  }

  p {
    font-weight: 700px!important;
    font-size: 14px !important;
    line-height: 18px !important;
    // letter-spacing: 0.03em;
    color: ${colors.black};
  }
`

export default LinearPercentWithTitleStyled