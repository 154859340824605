import { createGlobalStyle } from 'styled-components/macro'
import _ from 'lodash'
import colors from '../assets/colors'

export const GlobalStyle = createGlobalStyle``

export const theme = {
  colors
}

const themeClient = client => {
  return {
    colors,
    primary: _.get(client, 'background_color', colors.blue),
    textPrimary: _.get(client, 'text_color_principal', colors.white),
    textSecondary: _.get(client, 'text_color_secondary', colors.whiteAlpha50)
  }
}

export default themeClient
