import styled from 'styled-components'

export const StatesStyled = styled.div`
  .sectionThird {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;

    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 12px;
      &:not(.optional)::after {
        content: '*';
        color: #d95117;
        padding-left: 2px;
      }
    }
    input {
      font-size: 14px;
      border-color: #b0b3bb;
    }
    & h2 {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0;
    }

    & p {
      font-weight: 400;
      color: #7a808d;
    }
  }

  .containerButtonForm {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .containerDragAndDrop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0px;
    background: #ffffff;
    border-radius: 12px;
    height: auto;
  }
  .containerDragAndDropList {
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }

  .selectPhase {
    font-size: 14px;
  }

  .alertCustom {
    border-radius: 10px;
    padding: 4px 14px;
    font-family: Poppins;
  }

  .MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;
  }

  .ColorPicker-MuiFormControl-root {
    flex-direction: row !important;
  }

  .ColorPicker-MuiInput-underline:before {
    content: none !important;
    border-bottom: none !important;
    margin-bottom: 8px;
  }
  .ColorPicker-MuiInput-underline:after {
    transform: none !important;
    border-bottom: none !important;
  }
  .ColorPicker-MuiInputBase-root {
    font-family: 'Poppins' !important;
  }

  .makeStyles-root-81 {
    align-items: center !important;
  }

  .color-picker-container {
    position: relative;
  }

  .MuiTypography-body1 {
    font-size: 13px;
    font-weight: 400;
    font-family: 'Poppins';
  }
`
