import styled from 'styled-components'
import colors from '../../../assets/colors'

const BoxStyled = styled.div`
  .grid-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(98, 102, 113, 0.06), 0px 2px 2px rgba(98, 102, 113, 0.06);
    border-radius: 6px;
    padding: 20px 30px;
  }

  && {
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-top: 1rem;

    .ia-score {
      color: white;
      border-radius: 6px;
      padding: 0 4px;
      font-size: 12px;
      min-width: 20px;
      display: inline-block;
      text-align: center;
      background-color: ${colors.gray};
    
      &.ok {
        background-color: ${colors.green};
      }
      &.discard {
        background-color: ${colors.red};
      }
    }
    
    .ia-criteria {
      border: 1px solid #d3d3d3;
      border-radius: 8px;
      margin: 2rem;
      padding: 1rem;
      box-shadow: 0 2px 4px -2px #ddd;
      color: #333;
      font-size: 14px;
      
      h5 {
        color: inherit;
        font-weight: 600;
        font-size: 16px;
        background: #eee;
        padding: 0.6rem;
        margin-left: -1rem;
        border-radius: 8px 8px 0 0;
        margin-right: -1rem;
        margin-top: -1rem;
      }
      
      &.passed h5 {
        color: green;
      }
      &.failed h5 {
        color: orangered;
      }
    }
    
    
    .raw-wrapper {
      font-size: 14px;
      margin-left: 2rem;
      .raw-field {
        white-space: pre-line;
        background: #f0f0f0;
        border: 1px solid #999;
        max-height: 200px;
        overflow-y: auto;
        padding: 8px;
        border-radius: 4px;
        margin-bottom: 1rem;
        
        pre {
          white-space: break-spaces;
        }
      }
    }
  }
`

export default BoxStyled
