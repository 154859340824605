import React, {useEffect} from 'react'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import i18n from '../../../assets/i18n'
import { MTToolbarStyled } from './styled'
import ButtonWithIcon from '../button-with-icon'
import { ButtonDropdown, FileIcon, IconTag, DeleteIcon, ScheduleIcon } from '..'
import { SelectChangeStatusInbox } from '../../molecules'
import IconFile from "../icon-file";
import {AuthUtils} from "../../../redux/auth";
import {ALLOWED_EMAILS, ROLES} from "../../../config/constants";
import SelectChangeStatusFunnel from '../../molecules/select-change-status-funnel'
import { FeatureGuard } from '../../../utils/guard'
import routes from "../../../config/routes";
import {matchPath, useLocation} from "react-router";

const MTToolbar = ({options, module, disabled, onItemClick, totalRowsSelected, totalRows, onSubmitChangeStatus, statusTypes, funnelStatusTypes, onSubmitChangeFunnelStatus, isPushPage, auth, ...props }) => {
  const currentUserEmail = auth?.data?.email;

  const talentInboxRoutes = [
    routes.inbox,
    routes.favorites,
    routes.savedSearchesItemQuery,
    routes.savedSearchesItem,
    routes.planning,
  ];

  const location = useLocation();

  const [isTalentInbox, setIsTalentInbox] = React.useState(false);
  const [open, setOpen] = React.useState(false)
  const [optionsReduced, setOptionsReduced] = React.useState({});
  const anchorRef = React.useRef(null)
  const isTalentSupervisor = AuthUtils.hasRole(ROLES.ROLE_SUPERVISOR_talent_acquisition)

  useEffect(() => {
    let is_talent_inbox = talentInboxRoutes.some(route => matchPath(location.pathname, {path: route}));
    if (is_talent_inbox) {
      setIsTalentInbox(true);
    } else {
      setIsTalentInbox(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    options && optionsReduce()
    if(options && options.length === 0){
      disabled = true; // eslint-disable-line react-hooks/exhaustive-deps
    }
  }, [options])// eslint-disable-line react-hooks/exhaustive-deps

  const handleMenuItemClick = option => {
    onItemClick ? onItemClick(option.id) : option.onClick()
    setOpen(false)
  }

  const handleToggle = () => setOpen(prevOpen => !prevOpen)

  const optionsReduce = () => {
    let optionsById = options.reduce((accu, item) => {
      if (item) {
        accu[item.id] = item;
      }
      return accu;
    }, {})

    setOptionsReduced(optionsById);
  }

  const handleClose = event => {
    if (anchorRef.current && !anchorRef.current.contains(event.target)) {
      setOpen(false)
    }
  }

  return (
    <MTToolbarStyled className="toolbar">
      {module && totalRows? <p id='totalText'>{totalRowsSelected}/{totalRows} {i18n.processesTable[module]}</p>:null}
      {props.children}
      <div className="actions">
        {/* Si no hay para marcar fav no hay acciones que hacer */}
        {optionsReduced.markFavorite ? <>
          {(ALLOWED_EMAILS.indexOf(currentUserEmail)<0 && !FeatureGuard.canAccessToJobOffers()) && module !== 'on_board' && !FeatureGuard.canAccessToFunnelStatus() && <SelectChangeStatusInbox options={statusTypes} onSubmit={onSubmitChangeStatus} isDisabled={disabled} />}
          {(((ALLOWED_EMAILS.indexOf(currentUserEmail)>-1 || FeatureGuard.canAccessToJobOffers()) && !isTalentInbox) || ((ALLOWED_EMAILS.indexOf(currentUserEmail)<0 && !FeatureGuard.canAccessToJobOffers()) && isTalentInbox)) && module !== 'on_board' && FeatureGuard.canAccessToFunnelStatus() && <SelectChangeStatusFunnel options={funnelStatusTypes} onSelectedItemChange={onSubmitChangeFunnelStatus} disabled={disabled} />}
          <ButtonGroup ref={anchorRef} aria-label="Split button" disabled={disabled}>
            <ButtonDropdown
              size="small"
              open={open}
              MenuList='menu-list-grow'
              onClick={handleToggle}
              title={i18n.markAs}
            >
            </ButtonDropdown>
          </ButtonGroup></> : null }

        {optionsReduced.announcement && <ButtonWithIcon disabled={ disabled } title={optionsReduced.announcement.label} onClick={() => handleMenuItemClick(optionsReduced.announcement)} Icon={<ScheduleIcon />} />}
        {isTalentSupervisor && FeatureGuard.canAccessToDocumentRequest() && optionsReduced.requireDoc && <ButtonWithIcon disabled={ disabled } title={optionsReduced.requireDoc.label} onClick={() => handleMenuItemClick(optionsReduced.requireDoc)} Icon={<IconFile />} />}
        {optionsReduced.export && <ButtonWithIcon disabled={ disabled } title={optionsReduced.export.label} onClick={optionsReduced.export.onClick} Icon={<FileIcon />} />}
        {optionsReduced.changeTags && <ButtonWithIcon disabled={ disabled } title={optionsReduced.changeTags.label} onClick={() => handleMenuItemClick(optionsReduced.changeTags)} Icon={<IconTag />} />}
        {optionsReduced.delete && <ButtonWithIcon disabled={ !isPushPage && disabled } title={optionsReduced.delete.label} onClick={() => handleMenuItemClick(optionsReduced.delete)} Icon={<DeleteIcon />} />}

        <Popper className='popperMtToolbar' open={open} anchorEl={anchorRef.current} transition disablePortal style={{ zIndex: 9999 }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                boxShadow: '-4px 0px 4px -4px rgba(0,0,0,0.1)'
              }}
            >
              <Paper name="menu-list-grow">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    <MenuList>
                      {optionsReduced.unmarkAsRead ? <MenuItem onClick={() => handleMenuItemClick(optionsReduced.unmarkAsRead)}>
                        {optionsReduced.unmarkAsRead.label}
                      </MenuItem> : null}
                      {optionsReduced.markAsRead ? <MenuItem onClick={() => handleMenuItemClick(optionsReduced.markAsRead)}>
                        {optionsReduced.markAsRead.label}
                      </MenuItem> : null }
                      {optionsReduced.markFavorite ? <MenuItem onClick={() => handleMenuItemClick(optionsReduced.markFavorite)}>
                        {optionsReduced.markFavorite.label}
                      </MenuItem> : null}
                      {optionsReduced.removeFavorites ? <MenuItem onClick={() => handleMenuItemClick(optionsReduced.removeFavorites)}>
                        {optionsReduced.removeFavorites.label}
                      </MenuItem> : null }
                    </MenuList>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper></div>

    </MTToolbarStyled>
  )
}

export default MTToolbar
