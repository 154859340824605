import React, {Fragment, useEffect, useState} from "react"
import _ from 'lodash'
import SelectChangeStatus from "../select-change-status"
import i18n from "../../../assets/i18n"
import {print} from '../../../utils/print'
import {ButtonWithIcon, DeleteIcon, FileIcon, PrintIcon, ScheduleIcon} from "../../atoms"
import ModalAnnouncement from "../modal-announcement"
import ModalDeleteUser from "../modal-delete-user"
import ModalDownloadFiles from '../modal-download-files'
import {INTEGRATIONS, MODULES, ROLES} from '../../../config/constants'
import {toast} from 'react-toastify'
import routes from "../../../config/routes"
import {AuthUtils} from "../../../redux/auth";
import IconFile from "../../atoms/icon-file";
import ModalRequireDoc from "../modal-require-doc";
import {matchPath} from "react-router";
import {StatusType} from "../../../models";
import autoStatusDomains from "../../../utils/auto-status-domains";
import {postOrPatchCitations} from "../../../api";
import moment from "moment/moment";
import { FeatureGuard } from "../../../utils/guard"

const ProfileActionsButtons = (
  {
    changeStatus,
    fetchUser,
    fetchObservations,
    match,
    currentStatus,
    client,
    auth,
    files,
    deleteCandidature,
    userName,
    history,
    currentStatusColor,
    hasModalAnnouncement = true,
    requireDocumentation,
    location,
    dataCitationRows,
    dataInboxRows,
    statusTypes,
    user_email,
  }) => {

  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
  const [isOpenAnnouncementModal, setOpenAnnouncementModal] = useState(false)
  const [requireDocModal, setRequireDocModal] = useState(false)
  const [isOpenModalFiles, setOpenModalFiles] = useState(false)
  const [showCitationAction, setShowCitationAction] = useState(false)
  const [statusTypesByUser, setStatusTypesByUser] = useState([])
  const id = _.get(match, 'params.slug');

  useEffect(() => {
    if (dataInboxRows && dataInboxRows.data) {
      let row = _.find(dataInboxRows.data, {id: id});
      if (row) {
      setShowCitationAction(![StatusType.HIRED, StatusType.DISCARDED, StatusType.DISCARDED_PHASE_2].includes(row.estado));
      }
    }
    if (dataCitationRows && dataCitationRows.data) {
      let row = _.find(dataCitationRows.data, {candidature: {id: id}});
      if (row) {
        setShowCitationAction(![StatusType.HIRED, StatusType.DISCARDED, StatusType.DISCARDED_PHASE_2].includes(row.candidature.state.id));
      }
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (statusTypes) {
      let showOnlyManualStatus = true;
      if (user_email) {
        autoStatusDomains().map(item => {
          if (user_email.includes(item)) {
            showOnlyManualStatus = false;
          }
        });
      }
      let resultStatus;
      if (showOnlyManualStatus) {
        resultStatus = statusTypes.filter(item => item.automatic === false);
      } else {
        resultStatus = statusTypes;
      }
      setStatusTypesByUser(resultStatus);
    }
  }, [statusTypes]);

  const handleCloseDeleteUserModal = () => setOpenDeleteUserModal(false)
  const handleOpenDeleteUserModal = () => setOpenDeleteUserModal(true)
  const handleClickDocRequired = () => setRequireDocModal(true)
  const handleClickFiles = () => setOpenModalFiles(true)
  const isTalentReadOnly = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_talent_acquisition)
  const isPlanningProfile = () => matchPath(location.pathname, {path: routes.profilePlanning}) !== null;

  const onSubmitRequireDoc = (data) => {
    requireDocumentation([id], data).then(() => {
      fetchUser(id)
      fetchObservations(id)
    })
  }

  const handleSubmitChangeStatus = (status, date) => {
    changeStatus([id], status, date).then(() => {
      fetchUser(id)
      fetchObservations(id)
    })
  }
    const getCurrentIntegration = () => {
        if (auth && INTEGRATIONS) {
            let currentIntegration = _.find(INTEGRATIONS, {event_type: auth.event_type})
            return currentIntegration ? currentIntegration : null;
        }
        return null
    }
  const handleSubmitAnnouncement = data => {
    data.create = false
    postOrPatchCitations({data, ids: [id]}).then(() => {
        let status_id = getCurrentIntegration() ? StatusType.CITED : StatusType.TO_BE_CONFIRMED;
        changeStatus([id], status_id, moment(new Date()).format('YYYY-MM-DD HH:mm:ss')).then(() => {
            fetchUser(id)
            fetchObservations(id)
        });
    });
  }

  const deleteInboxPeople = () => {
    setOpenDeleteUserModal(false)
    if (id) {
      deleteCandidature(id, MODULES.currentModule()).then(() => {
        toast.success(i18n.headerProfileButtons.userDeleted, {
          position: toast.POSITION.BOTTOM_CENTER
        })
        history.push(routes.inbox)
      })
    }
  }

  return (
    <Fragment>
      {currentStatus && <SelectChangeStatus disabled={isTalentReadOnly} currentStatusColor={currentStatusColor}
                                            currentStatus={currentStatus}
                                            options={statusTypesByUser}
                                            onSubmit={handleSubmitChangeStatus}/>}
      {!isTalentReadOnly && hasModalAnnouncement && showCitationAction &&
        <ButtonWithIcon
          title={isPlanningProfile() ? i18n.headerProfileButtons.editQuoteCall : i18n.headerProfileButtons.quoteCall}
          onClick={() => setOpenAnnouncementModal(true)}
          Icon={<ScheduleIcon/>}/>}
      {MODULES.ID_TALENT_ACQUISITION === MODULES.currentModule() && !isTalentReadOnly && FeatureGuard.canAccessToDocumentRequest() &&
        <ButtonWithIcon title={i18n.peopleTable.requireDoc} onClick={handleClickDocRequired} Icon={<IconFile/>}/>}

      {!isTalentReadOnly && files && files.length > 0 &&
        <ButtonWithIcon title={i18n.headerProfileButtons.docs} onClick={handleClickFiles}
                        Icon={<FileIcon/>}/>}

      <ButtonWithIcon title={i18n.headerProfileButtons.print} onClick={() => print('profilePrint')}
                      Icon={<PrintIcon/>}/>
      {!isTalentReadOnly && <ButtonWithIcon remove='true' title={i18n.headerProfileButtons.remove}
                                            onClick={() => handleOpenDeleteUserModal()}
                                            Icon={<DeleteIcon/>}/>}

      {/* Modals */}
      < ModalAnnouncement
        open={isOpenAnnouncementModal}
        handleClose={() => setOpenAnnouncementModal(false)}
        onSubmit={handleSubmitAnnouncement}
        sms={client ? client.sms_available : false}
        self={auth ? auth : null}
        isEdit={isPlanningProfile}
        data={dataCitationRows && dataCitationRows.data && _.find(dataCitationRows.data, {candidature: {id: id}})}
      />

      <ModalDeleteUser handleOnClickDelete={deleteInboxPeople} name={userName} open={openDeleteUserModal}
                       handleClose={handleCloseDeleteUserModal}/>
      <ModalRequireDoc
        open={requireDocModal}
        handleClose={() => setRequireDocModal(false)}
        onSubmit={onSubmitRequireDoc}
      />

      <ModalDownloadFiles files={files} open={isOpenModalFiles} handleClose={() => setOpenModalFiles(false)}
                          onSubmit={() => {
                          }}/>
    </Fragment>
  )
}

export default ProfileActionsButtons;
