import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTheme } from '@material-ui/core/styles'
import { withI18n } from '../../hocs'
import {ProfileOperations} from "../../../redux/profile";

const mapStateToProps = state => {
  return {
    client: state.auth.client ? state.auth.client.id : null,
    clientColor: state.auth.client ? state.auth.client.background_color : '#000'
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfileField: (profile, field, value, phase) => dispatch(ProfileOperations.updateProfileField(profile, field, value, phase)),
    fetchObservations: candidatureId => dispatch(ProfileOperations.fetchObservations(candidatureId)),
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withTheme,
)

export default enharce(View)
