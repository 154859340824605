import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Grid from '@material-ui/core/Grid'
import ProfileButtonsStyled from './styled'
import {BackButton} from '../../atoms'
import ArrowBack from '@material-ui/icons/KeyboardArrowLeft'
import ArrowForward from '@material-ui/icons/KeyboardArrowRight'
import {getCurrentSectionKey, MODULES, SECTION_KEYS} from '../../../config/constants'
import {useAppContext} from "../../../context/appContext";
import {useParams} from "react-router";
import routes from "../../../config/routes";

const HeaderProfileButtonsQuery = ({
                                i18n,
                                totalRQuery,
                                history,
                                candidatureId,
                                module,
                                targetInboxData,
                                isFetchedTargetInboxData,
                                setIsFetchedTargetInboxData,
                                client,
                                isPerson = false
                              }) => {

  const {range, setRange, page, setPage, perPage, goBackLocation, setGoBackLocation, navigationNextId, setNavigationNextId} = useAppContext();
  
  const {savedSearchItemId, job_offer_id, job_offer_application_id} = useParams();

  const pageGetCurrentIndexFromTable = (rows = targetInboxData) => {
    if (isPerson) {
      return _.findIndex(rows, row => row.id == job_offer_application_id);
    } else {
      return _.findIndex(rows, {id: candidatureId});
    }
  }

  const pageGetNextIndexFromTable = (rows = targetInboxData, id = job_offer_application_id ?? candidatureId) => {
    let current = null
    if (isPerson) {
      current = _.findIndex(rows, row => row.id == id)
    } else {
      current = _.findIndex(rows, { id: id })
    }
    return (current >= 0 && rows[current + 1]) ? current + 1 : -1
  }

  const [currentItemNum, setCurrentItemNum] = useState(0);

  const goBackHandler = () => {
    if (job_offer_id) {
      history.push(routes.job_offer_applications.replace(':job_offer_id', job_offer_id));
    } else {
      history.goBack();
    }
    /*
    if (goBackLocation) {
      history.push(goBackLocation);
      setGoBackLocation(null);
    } else if (job_offer_id) {
      history.push(routes.job_offer_applications.replace(':job_offer_id', job_offer_id));
    } else if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM) {
      history.push(`/${module}/${SECTION_KEYS.SAVED_SEARCHES}/${savedSearchItemId}`);
    } else {
      history.push(`/${module}/${getCurrentSectionKey()}`);
    }
    */
  }

  useEffect(() => {
    if (targetInboxData && targetInboxData.length > 0) {

      let rowIndex = pageGetCurrentIndexFromTable(targetInboxData);
      if (rowIndex < 0) {
        setCurrentItemNum(null)
      } else if (rowIndex >= 0) {
        setCurrentItemNum(targetInboxData[rowIndex].itemCount ?? rowIndex + 1);
      } else {
        let key = client && client.id ? `profileIdMapper-${client.id}` : 'profileIdMapper';
        let idMapper = localStorage.getItem(key);
        let rowIndex = _.findIndex(targetInboxData, {id: idMapper});
        if (targetInboxData[rowIndex]) {
          setCurrentItemNum(targetInboxData[rowIndex].itemCount ?? rowIndex + 1)
        }
      }

      let isPreviousPage = localStorage.getItem('isPreviousPage');
      let isNextPage = localStorage.getItem('isNextPage');

      if (isPreviousPage || isNextPage) {
        let id = null;
        let data = null;
        if (isPreviousPage) {
          localStorage.removeItem('isPreviousPage');
          data = targetInboxData[targetInboxData.length -1];
          id = targetInboxData[targetInboxData.length -1].id;
        }
        if (isNextPage) {
          localStorage.removeItem('isNextPage');
          data = targetInboxData[0];
          id = targetInboxData[0].id;
        }

        if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM) {
          history.push(`/${MODULES.ID_TALENT_ACQUISITION}/${SECTION_KEYS.SAVED_SEARCHES}/${savedSearchItemId}/profile/${id}`);
        } else if (isPerson && data && data.person) {
          history.push(routes.profilePerson.replace(':job_offer_id', job_offer_id).replace(':job_offer_application_id', id).replace(':slug', data.person.id));
        } else {
          history.push(`/${MODULES.ID_TALENT_ACQUISITION}/${getCurrentSectionKey()}/profile/${id}`);
        }
      }
    }
  }, [targetInboxData]);

  const hasNextPage = () => {
    if (currentItemNum === null && targetInboxData.length > 0) {
      return true
    }
    let nextIndex = pageGetNextIndexFromTable();
    if (nextIndex >= 0) {
      setNavigationNextId(targetInboxData[nextIndex].id ?? null)
    }

    return currentItemNum !== 0 && targetInboxData && currentItemNum + 1 <= (totalRQuery ? totalRQuery : 0);
  }

  const hasPreviousPage = () => {
    return currentItemNum - 1 >= 1;
  }

  const getNextPageId = () => {
    let currentIndex = pageGetCurrentIndexFromTable();
    if (currentIndex < 0) {
      let key = client && client.id ? `profileIdMapper-${client.id}` : 'profileIdMapper';
      let idMapper = localStorage.getItem(key);
      currentIndex = _.findIndex(targetInboxData, {id: idMapper});
    }
    if (currentIndex < 0) {
      currentIndex = pageGetNextIndexFromTable(targetInboxData, navigationNextId);
    }
    if (targetInboxData[currentIndex + 1]) {
      if (isPerson) {
        let nextData = targetInboxData[currentIndex + 1];
        if (nextData) {
          history.push(routes.profilePerson.replace(':job_offer_id', job_offer_id).replace(':job_offer_application_id', nextData.id).replace(':slug', nextData.person.id));
        }
      } else {
        let targetSection = getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM ? `${SECTION_KEYS.SAVED_SEARCHES}/${savedSearchItemId}` : getCurrentSectionKey();
        history.push(`/${MODULES.ID_TALENT_ACQUISITION}/${targetSection}/profile/${targetInboxData[currentIndex + 1].id}`);
      }
    } else {
      changePageInTable(+1);
    }
    return false;
  }

  const getPreviousPageId = () => {
    let currentIndex = pageGetCurrentIndexFromTable();
    if (currentIndex < 0) {
      let key = client && client.id ? `profileIdMapper-${client.id}` : 'profileIdMapper';
      let idMapper = localStorage.getItem(key);
      currentIndex = _.findIndex(targetInboxData, {id: idMapper});
    }
    if (targetInboxData[currentIndex - 1]) {
      if (isPerson) {
        let previousData = targetInboxData[currentIndex - 1];
        if (previousData && previousData.person) {
          history.push(routes.profilePerson.replace(':job_offer_id', job_offer_id).replace(':job_offer_application_id', previousData.id).replace(':slug', previousData.person.id));
        }
      } else {
        let targetSection = getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM ? `${SECTION_KEYS.SAVED_SEARCHES}/${savedSearchItemId}` : getCurrentSectionKey();
        history.push(`/${MODULES.ID_TALENT_ACQUISITION}/${targetSection}/profile/${targetInboxData[currentIndex - 1].id}`);
      }
    } else {
      changePageInTable(-1);
    }
    return false;
  }

  const changePageInTable = (sumOrRest) => {
    let newTablePage = page[getCurrentSectionKey()] + sumOrRest;
    const initialRange = (newTablePage === 0 ? 0 : newTablePage) * perPage[getCurrentSectionKey()];
    const finishRange = initialRange + perPage[getCurrentSectionKey()] - 1;

    if (sumOrRest > 0) {
      localStorage.setItem('isNextPage', '1');
    } else {
      localStorage.setItem('isPreviousPage', '1');
    }

    if (!isPerson) {
      setIsFetchedTargetInboxData(false);
    }
    setPage({...page, [getCurrentSectionKey()]: newTablePage});
    setRange({...range, [getCurrentSectionKey()]: [initialRange, finishRange]});
  }

  return <ProfileButtonsStyled>
    {isPerson || (targetInboxData && isFetchedTargetInboxData && (totalRQuery ? totalRQuery : 0)) ? (
      <Grid container alignItems="center">
        <Grid item sm={3}>
          <BackButton title={i18n.headerProfileButtons.backToApplications}
                      onClick={goBackHandler}/>
        </Grid>
        <Grid item sm={9} className={'buttons-wrapper'}>
          <p className={"header-pagination-count"}>
            <span>
              <span className="currentItemNumber">{currentItemNum === null ? '-' : (currentItemNum === 0 ? 1 : currentItemNum)}</span> / {(targetInboxData.length)}
            </span>
          </p>

          <ButtonGroup color="primary" aria-label="Split button" className={"header-profile-arrows"}>

            <Button variant="contained"
              onClick={() => getPreviousPageId()}
              disabled={hasPreviousPage() === false}
            >
              <span>&#8205;</span>
              <ArrowBack />
            </Button>

            <Button variant="contained"
              onClick={() => getNextPageId()}
              disabled={hasNextPage() === false}
            >
              <span>&#8205;</span>
              <ArrowForward />
            </Button>

          </ButtonGroup>
        </Grid>
      </Grid>
    ) : null}
    </ProfileButtonsStyled>

}

export default HeaderProfileButtonsQuery
