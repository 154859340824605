import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Icon = () => (
  <SvgIcon width="17" height="17" viewBox="0 0 17 17">
    <rect x="2.25" y="3" width="13.5" height="12.75" rx="3" stroke="#7A808D" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M6 1.875V4.125" stroke="#7A808D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 1.875V4.125" stroke="#7A808D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.625 6.75L15.375 6.75" stroke="#7A808D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9 9.5625V12.9375" stroke="#7A808D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.3125 11.25H10.6875" stroke="#7A808D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </SvgIcon>
)

export default Icon
