import React, { useEffect } from 'react';
import MyProfilePartnersStyled from './styled';
import { Grid, Box } from '@material-ui/core';
import { loadFacebookSDK } from '../../../utils/facebookSDK';

const MyProfilePartners = ({
    classes,
    i18n,
}) => {
    useEffect(() => {
        loadFacebookSDK(true);
    }, []);

    const launchWhatsAppSignup = () => {
        if (window.FB && window.FB.initCalled) {
            window.FB.login(function (response) {
                if (response.authResponse) {
                    const accessToken = response.authResponse.accessToken;
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            }, {
                config_id: '2050804728646504',
                response_type: 'code',
                override_default_response_type: true,
                extras: {
                    setup: {
                    }
                }
            });
        } else {
            console.log('El SDK de Facebook no se ha inicializado todavía.');
        }
    };

    return (
        <MyProfilePartnersStyled>
            <div className="containerHeader">
                <Grid container spacing={4}>
                    <Grid item className="sectionSecond" xs={12} sm={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start", gridGap: '15px', maxWidth: "700px", }}>
                            <h2>Partners</h2>
                            <h5>WhatsApp Business</h5>
                            <button onClick={launchWhatsAppSignup} style={{ backgroundColor: '#1877f2', border: 0, borderRadius: '4px', color: '#fff', cursor: 'pointer', fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '16px', fontWeight: 'bold', height: '40px', padding: '0 24px' }}>
                                Login with Facebook
                            </button>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </MyProfilePartnersStyled>
    );
};

export default MyProfilePartners;
