import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import { FavouriteIcon } from '../../atoms'
import UserNameTitleStyled from './styled'

const UserNameTitle = ({ userName, showFav, isFav, handleOnClickFav }) => {
  return (
    <UserNameTitleStyled>
      <p className="user-name-title">
        <div>
          {showFav && <FavouriteIcon className="no-print" size={60} favorite={isFav} onClick={handleOnClickFav} />}
        </div>
        <span>
          {userName}
        </span>
        {/* {status === 1 ? <Suitable /> : ''}
          {status === 2 ? <NoSuitable /> : ''}
          {status === 3 ? <ToBeConfirmed /> : ''}
          {status === 4 ? <Confirmed /> : ''}
          {status === 5 ? <NotInterested /> : ''}
          {status === 6 ? <Reschedule /> : ''}
          {status === 7 ? <Hired /> : ''}
          {status === 8 ? <Reserve /> : ''}
          {status === 9 ? <NotAttend /> : ''}
          {status === 10 ? <Confirmed /> : ''}
          {status === 11 ? <Discarded /> : ''}
          {status === 12 ? <Discarded /> : ''}
          {status === 13 ? <NotAttend /> : ''}
          {status === 14 ? <NotInterested /> : ''} */}
      </p>
    </UserNameTitleStyled>
  )
}

UserNameTitle.propTypes = {
  userName: PropTypes.string,
  status: PropTypes.number
}

const enharce = compose(withI18n)

export default enharce(UserNameTitle)
