import React, {Component} from 'react'
import compose from 'recompose/compose'
import {withI18n} from '../../hocs'
import {AppBarStyled} from './styled'
import {PageTitleSubtitle, ProfileAvatar} from '../../atoms'
import Grid from '@material-ui/core/Grid'
import {MODULES} from "../../../config/constants";

class AppBar extends Component {

  render() {
    const { title, subtitle } = this.props
    return (
      <AppBarStyled>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={12} sm={6}>
            {title ? <PageTitleSubtitle title={title} subtitle={subtitle} module={MODULES.ID_ON_BOARDING} /> : ''}
          </Grid>
          <Grid item xs={12} sm={6} className="user-wrapper">
            <ProfileAvatar />
          </Grid>
        </Grid>
      </AppBarStyled>
    )
  }
}

AppBar.propTypes = {
}

AppBar.defaultProps = {
  title: null,
  subtitle: null
}

const enharce = compose(withI18n)

export default enharce(AppBar)
