import React from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import BoxVideoCandidateStyled from './styled'
import ReactPlayer from 'react-player'

const BoxVideoCandidate = ({ profileData, lg }) => {

  const videos = [];

  if (profileData.video) {
    videos.push(profileData.video);
  }

  for (const key in profileData) {
    if (key.startsWith('adjunto') && profileData[key]) {
      videos.push(profileData[key]);
    }
  }

  return (
    <>
      <BoxVideoCandidateStyled>
        <Grid style={{ height: lg === 12 ? 'auto' : 800 }}  className="boxInfo" container justifyContent="space-between">
          <Grid item className="wrapper-video" container>
            {videos.map((videoUrl, index) => (
              <ReactPlayer key={index} className="custom-video-player" controls url={videoUrl} config={{ file: { forceVideo: true } }} />
            ))}
          </Grid>
        </Grid>
      </BoxVideoCandidateStyled>
    </>
  )
}

export default BoxVideoCandidate
