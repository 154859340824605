import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import LinearPercentStyled from './styled'
import LinearProgress from '@material-ui/core/LinearProgress'

const LinearPercent = ({ title, value, Icon }) => {
  if (value < 0) value = 0

  return (
    <LinearPercentStyled>
      <div>
        <div className="icon-wrapper">{Icon}</div>
        <LinearProgress variant="determinate" value={value} />
        <p className="linear-percent">{value > 0 ? value.toFixed() : 0}%</p>
      </div>
      <p className="title">{title}</p>
    </LinearPercentStyled>
  )
}

LinearPercent.defaultProps = {
  value: 0
}

LinearPercent.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  Icon: PropTypes.element
}

const enharce = compose()

export default enharce(LinearPercent)
