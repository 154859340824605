import styled from 'styled-components'

export default styled.div`
  display: inline;
  opacity: ${props => (props.disabled ? 0.4 : 1)};

  .ft-button {
    margin-right: 10px;
    text-transform: none;
    font-weight: normal;
  }

  .ft-label {
    color: ${props => (props.isSelected ? props.theme.textPrimary : props.theme.colors.blackAlpha50)};
    font-weight: bold;
    margin-right: 5px;
  }

  .ft-value {
    color: ${props => (props.isSelected ? props.theme.textPrimary : props.theme.colors.blackAlpha50)};
  }
`
