export const LOG_OUT = 'auth/LOG_OUT'
export const SET_IS_FETCHING = 'auth/SET_IS_FETCHING'
export const SET_AUTH_TOKEN = 'auth/SET_AUTH_TOKEN'
export const SET_UID = 'auth/SET_UID'
export const SET_EMAIL = 'auth/SET_EMAIL'
export const SET_ID = 'auth/SET_ID'
export const SET_LANGUAJE = 'auth/SET_LANGUAJE'
export const SET_SESION_LOADED = 'auth/SET_SESION_LOADED'
export const SET_DATA = 'auth/SET_DATA'
export const SET_URL = 'auth/SET_URL'
export const SET_CLIENT = 'auth/SET_CLIENT'
