import View from './view'
import {connect} from 'react-redux'
import compose from 'recompose/compose'
import {withRouter} from 'react-router'
import {withTheme} from '@material-ui/core/styles'
import {withI18n} from '../../hocs'
import * as FilterUtils from "../../../utils/filters";

const mapStateToProps = state => {
  return {
    // filtersInbox: state.inbox.inboxFilters,
    filtersCitationExtend: state.citations.filtersExtend,
    responsibles: state.citations.responsible,
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // filtersOnChangeHandle: (arrObjSelected, name) => dispatch(SaveSearchesOperations.filtersOnChangeHandle(arrObjSelected, name)),
    createFilterObjectSelected: (inputValue, filterRemoteName, module, filter) => dispatch(FilterUtils.createFilterObjectSelected(inputValue, filterRemoteName, module, filter))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withTheme
)

export default enharce(View)
