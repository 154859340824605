import React from 'react'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeaderProcess, RegularButton } from '../../atoms'
import RegularButtonOutline from '../../atoms/regular-button-outline'

const customStyles = {
  overflow: 'scroll'
}

const ModalSimpleSmall = ({ open, handleClose, textContent, buttonTitle, cancelButton = null, handleButtonClick }) => {
  const handleModalClose = () => {
    handleClose();
  };

  return (
    <Modal
      style={customStyles}
      className="modal-wrapper"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleModalClose}
    >
      <ModalStyled>
        <ModalHeaderProcess closeClick={handleModalClose} hasCloseIcon={true} />
        <div className="modal-body">
          <form>
            {textContent}
            <div className="button-wrapper">
              {cancelButton &&
                <RegularButtonOutline
                  title={cancelButton}
                  onClick={(e) => {e.preventDefault(); handleModalClose()}}
                />}
              <RegularButton
                className="button-custom"
                title={buttonTitle}
                onClick={handleButtonClick}
              />
            </div>
          </form>
        </div>
      </ModalStyled>
    </Modal>
  )
}

export default ModalSimpleSmall
