import styled from 'styled-components'

import colors from '../../../assets/colors'

export const TemplateRequestManagerStyled = styled.div`
  .title-container {
    padding: 30px 60px;
  }

  .create-process-container {
    padding: 30px 60px;
  }

  .wrapper-general {
    gap: 5px;
  }

  .wrapper-short-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 35px;
    height: auto;
    border-radius: 25px;
    background: #fbfbfb;
    box-shadow: 0px 0px 1px 0px #e3e4e5, 0px 3px 12px 0px rgba(9, 30, 66, 0.14);
    margin-top: 30px;
  }

  .wrapper-large-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 35px;
    width: 100%;
    height: auto;
    border-radius: 25px;
    background: #fbfbfb;
    box-shadow: 0px 0px 1px 0px #e3e4e5, 0px 3px 12px 0px rgba(9, 30, 66, 0.14);
  }

  .title-create-process {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: #000;
    font-size: 28px;
    font-weight: 600;
  }

  .title-process {
    color: #262e36;
    font-size: 14px;
    font-weight: 500;
    &::after {
      content: '*';
      color: #d95117;
      padding-left: 2px;
    }
  }
  /* 
  .label-picker {
    color: #262e36;
    font-size: 14px;
    font-weight: 400;

    &::after {
      content: '*';
      color: #d95117;
      padding-left: 2px;
    }
  } */

  .container-item-text {
    /*min-width: 320px;*/
    display: flex;
    flex-direction: column;
  }

  .container-item-vacancies {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }

  .container-select {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 650px;
  }

  .divider-center {
    width: 75%;
    margin: 0 auto;
  }

  .sectionSecond {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 25px 20px;
    border-radius: 25px;
    background: #ffffff;
    gap: 15px;
    margin: 20px 0px;

    label {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 6px;
      margin-right: 20px;

      &.required {
        ::after {
          content: '*';
          color: #d95117;
          padding-left: 2px;
        }
      }
    }
    input {
      font-size: 14px;
      font-family: 'Poppins';
    }

    & h2 {
      font-size: 28px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0;
    }
    & h5 {
      color: #7a808d;
      font-weight: 400;
      margin-bottom: 0;
    }

    & p {
      font-weight: 400;
      color: #7a808d;
    }
  }

  .headerTable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 20px;

    h5 {
      margin-bottom: 0px;
    }
  }

  .containerButtons {
    display: inline-flex;
    align-items: center;
    align-self: end;
    justify-content: flex-end;
    gap: 10px;
    background-color: #f0f1f2;
    padding: 8px 10px;
    border-radius: 50px;
  }

  .MuiAutocomplete-popupIndicator {
    top: 3px;
  }

  .MuiInputBase-input {
    font-size: 14px;
    font-family: Poppins;
  }

  .autocomplete-custom {
    input {
      height: 20px;
    }
  }

  .autocomplete-custom-without-label {
    margin-top: 20px;
    input {
      height: 20px;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 6px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #b0b3bb !important;
  }

  .textarea-question {
    min-height: 40px;
    background: ${colors.white};
    border: 1px solid ${colors.blackAlpha41};
    box-sizing: border-box;
    border-radius: 6px;
    padding: 5px 10px;
    font-weight: normal;
    font-size: 12px;
    color: ${colors.black};
    outline: none;
    resize: none;
    min-height: 100px;
    overflow-y: auto;

    ::placeholder {
      font-weight: normal;
      font-size: 14px;
      color: #9999b3;
    }

    width: 100% !important;
  }

  .container-questions {
    display: flex;
    flex-direction: column;
  }

  .wrapper-button {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  .button-custom {
    border-radius: 10px;
    color: #ffffff;
  }
`
