import { createStyles } from '@material-ui/core/styles'

export default theme =>
  createStyles({
    '@global': {
      body: {
        backgroundColor: theme.colors.pageBackground,
        minWidth: 320
      }
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 40
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 20
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      padding: 40
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      position: 'relative'
    },
    progress: {
      color: theme.colors.greyAlpha25,
      position: 'absolute',
      right: 20
    }
  })
