import React from 'react'

const icon = props => (
  <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5001 14C21.5001 14.5523 21.0524 15 20.5001 15H17.5001V3H20.5001C21.0524 3 21.5001 3.44772 21.5001 4V14Z"
      stroke="#D86262" strokeWidth="1.5"/>
    <path
      d="M15.2559 3.50385L17.5001 5V13H16.6087C16.2349 13 15.8922 13.2085 15.7205 13.5406L12.7882 19.2096C12.0053 20.7234 9.70549 19.9663 9.97474 18.2835L10.3148 16.158C10.412 15.5504 9.9427 15 9.32739 15H5.95971C4.69109 15 3.74301 13.8341 4.00175 12.5921L5.50258 5.38814C5.7924 3.99698 7.01849 3 8.43952 3H13.5918C14.1841 3 14.7631 3.17531 15.2559 3.50385Z"
      stroke="#D86262" strokeWidth="1.5"/>
  </svg>
)

export default icon