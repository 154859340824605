import styled from 'styled-components'

const MyProfileIaEvaluationStyled = styled.div`
  .containerHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    padding: 36px 49px;
    border-radius: 25px;
    background: #ffffff;
    gap: 50px;
  }

  .sectionSecond {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;

    label {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0px;
      margin-right: 20px;
    }
    input {
      font-size: 14px;
      font-family: 'Poppins';
    }

    & h2 {
      font-size: 28px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0;
    }
    & h5 {
      color: #7a808d;
      font-weight: 400;
      line-height: inherit;
    }

    & p {
      font-weight: 400;
      color: #7a808d;
    }
  }

  .autocomplete-custom {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 10px !important;
    width: 240px !important;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    font-size: 14px !important;
    font-family: 'Poppins' !important;
    color: #9999b3;
    opacity: 1;
  }
    
  .MuiOutlinedInput-root {
    border-radius: 6px !important;
  }
    
  .MuiAutocomplete-inputRoot {
    padding: 4px !important;
  }
    
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 12px) !important;
  }

  .MuiTableHead-root th {
    font-weight: bold;
    color: black;
  }
    
  .MuiTableHead-root th:nth-child(n+3) {
    text-align: center !important;
    padding-right: 12px!important;
  }

  .MuiTableHead-root th:not(:nth-child(n+3)) {
    text-align: left !important;
  }

  .headerTable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h3 {
      margin-bottom: 0px;
    }
      
    .relevance_level_legend {
        display: flex;
        gap: 20px;
        div {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
      
    .containerToggle {
        margin: 0!important;
    }
  }

  .containerButton {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    height: 36px;
  }

  .MuiTableCell-root {
    font-family: 'Poppins';
  }
`

export default MyProfileIaEvaluationStyled
