export const SET_DATA = 'savesearches/SET_DATA'
export const SET_IS_FETCHING = 'savesearches/SET_IS_FETCHING'
export const SET_FILTERS = 'savesearches/SET_FILTERS'
export const SET_AUX_FILTERS = 'savesearches/SET_AUX_FILTERS'
export const SET_SEARCH_FILTER_GROUP = 'savesearches/SET_SEARCH_FILTER_GROUP'
export const SET_SEARCH_FILTERS = 'savesearches/SET_SEARCH_FILTERS'
export const SET_PAGE = 'savesearches/SET_PAGE'
export const SET_SORT = 'savesearches/SET_SORT'
export const SET_PAGE_SIZE = 'savesearches/SET_PAGE_SIZE'

//Add filters to the state for the searches
export const ADD_FILTERS_TO_STATE = 'savesearches/ADD_FILTERS_TO_STATE';
