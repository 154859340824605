import styled from 'styled-components'
import colors from '../../../assets/colors'

export const ReportsTableStyled = styled.div`
  .planning-btn {
    margin-right: 15px;
    color: ${colors.white};
    background-color: ${colors.orange};
    text-transform: capitalize;
  }
  .toolbar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;

    .actions {
      text-transform: none;
      font-size: 14px;
      padding-left: 20px;
      padding-right: 13px;
    }
  }
  .toolbar {
    text-align: left;
    margin-bottom: 30px;
    margin-top: 30px;

    .MuiButton-root {
      font-size: 14px;
      padding-left: 20px;
      padding-right: 15px;
      text-transform: capitalize;
    }
  }
  .MuiTableHead-root {
    th {
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .MuiTableBody-root {
    td {
      padding-top: 20px;
      padding-bottom: 20px;
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .tags {
      background: ${colors.greyAlpha15};
      border-radius: 4px;
      display: inline-block;
      margin-right: 15px;
      padding: 10px;
      text-transform: capitalize;
    }
  }
`
