import React from 'react'
import PropTypes from 'prop-types'
import {AppBarInbox, ProcessTable, ProfileProcessesInfo} from '../../organism'
import {MODULES} from '../../../config/constants'

const Favorites = ({ classes, i18n }) =>{
  return (
    <div className={classes.root}>
        <AppBarInbox
          showActions
            no_filterName={i18n.processesTable.filterProcesses}
            module={MODULES.ID_EMPLOYEE_LINE}
            section={"favorites"}
        />
      <ProcessTable 
        title={i18n.favoritesProcessTable.title}
        module={MODULES.ID_EMPLOYEE_LINE} 
        defaultFilters={{isFavorite: true}}
        rowViewRender={(id) => <ProfileProcessesInfo id={id} />}
      />
    </div>
  )
}

Favorites.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default Favorites
