import React, { useRef, useEffect } from 'react'
import HereMapsStyled from './styled'
import H from '@here/maps-api-for-javascript'

const HereMaps = ({ apiKey, mapHeight = 500, originPosition = null, initialZoom = 6, markers = [] }) => {
  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const ui = useRef(null);

  const onClusterClick = (e) => {
    let position = e.target.getGeometry();

    // Get the data associated with that marker
    let data = e.target.getData();
    let zoom = map.current.getZoom();
    let clickOnCluster = typeof data.c === 'function';

    map.current.setCenter(position, !clickOnCluster);
    if(clickOnCluster && zoom < 12) {
      map.current.setZoom(zoom+1, true);
    }
  }

  const setClusters = () => {
    // Remove all the previous map objects, if any
    map.current.removeObjects(map.current.getObjects());

    const dataPoints = markers.map(function (item) {
      return new H.clustering.DataPoint(item.lat, item.lng);
    });

    const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 32,
        minWeight: 1
      }
    });
    clusteredDataProvider.addEventListener('tap', onClusterClick);

    const clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
    if(!originPosition) {
      clusteredDataProvider.addEventListener('update', function(evt) {
        if (evt.target === this) {
          map.current.getViewModel().setLookAtData({
            bounds: clusteredDataProvider.getRootGroup().getBoundingBox()
          });
        }
      });
    }

    map.current.addLayer(clusteringLayer);
  }

  useEffect(() => {
      // Check if the map object has already been created
      if (!map.current) {
        // Create a platform object with the API key
        platform.current = new H.service.Platform({ apikey: apiKey });
        // Create a new Raster Tile service instance
        const rasterTileService = platform.current.getRasterTileService({
          queryParams: {
            style: "lite.day",
            features: 'vehicle_restrictions:disabled,pois:disabled,environmental_zones:disabled,congestion_zones:disabled',
            size: 256
          },
        });

        // Creates a new instance of the H.service.rasterTile.Provider class
        // The class provides raster tiles for a given tile layer ID and pixel format
        const rasterTileProvider = new H.service.rasterTile.Provider(
          rasterTileService
        );
        // Create a new Tile layer with the Raster Tile provider
        const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);
        // Create a new map instance with the Tile layer, center and zoom level
        const newMap = new H.Map(
          mapRef.current,
          rasterTileLayer,
          {
            pixelRatio: window.devicePixelRatio,
            center: originPosition,
            zoom: initialZoom,
          }
        );

        // Add panning and zooming behavior to the map
        const behavior = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(newMap)
        );

        // Set the map object to the reference
        map.current = newMap;
        ui.current = H.ui.UI.createDefault(map.current, platform.current.createDefaultLayers());
        if(markers) {
          setClusters();
        }
      }
    }, [apiKey, markers]);


  return (
    <HereMapsStyled>
      <div style={{ width: "100%", height: mapHeight }} ref={mapRef} />
    </HereMapsStyled>
  )
}

export default HereMaps
