import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { AuthOperations } from '../../../redux/auth'
import { withI18n } from '../../hocs'
// import styles from './styles'

const mapStateToProps = state => {
  return {
    isFetching: state.auth.isFetching,
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: data => dispatch(AuthOperations.signIn(data, props.history)),
    updateLocale: code => dispatch(AuthOperations.updateLocale(code))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  // withStyles(styles)
)

export default enharce(View)
