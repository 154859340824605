import React from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import BoxOtherInfoHeaderStyled from './styled'
import EditFieldAction from "../../organism/edit-field-action";

const BoxOtherInfoHeader = ({ titleInfo1, titleInfo2, titleInfo3, value1, value2, value3, i18n, origin, workingDay, availability }) => {
    return (
        <BoxOtherInfoHeaderStyled>
            <Grid id='wrap-table' container justifyContent="space-around" >
                <Grid container justifyContent="space-between" >
                    <p className={'title'}>{titleInfo1}:</p>
                    <p className="value">
                        <EditFieldAction remoteName={'direccion'} defaultValue={value1 || '-'}/>
                    </p>
                </Grid>
                <Grid container justifyContent="space-between" >
                    <p className={'title'}>{titleInfo2}:</p>
                    <p className="value">
                        <EditFieldAction remoteName={'cp'} defaultValue={value2 || '-'}/>
                    </p>
                </Grid>
                <Grid container justifyContent="space-between" >
                    <p className={'title'}>{titleInfo3}:</p>
                    <p className="value">
                        <EditFieldAction remoteName={'zona_deseada'} defaultValue={value3 || '-'}/>
                    </p>
                </Grid>
                {workingDay &&
                  <Grid container justifyContent="space-between">
                      <p className={'title'}>{i18n.boxProfileCite.disponibilidad}</p>
                      <p className="value">
                            <EditFieldAction remoteName={'jornada'} defaultValue={workingDay || ''}/>
                      </p>
                  </Grid>
                }
                {availability &&
                  <Grid container justifyContent="space-between">
                      <p className={'title'}>{i18n.profileInfo.availableFrom}</p>
                      <p className="value">
                          <EditFieldAction remoteName={'disponibilidad'} defaultValue={availability || ''}/>
                      </p>
                  </Grid>
                }

                {origin &&
                    <Grid container justifyContent="space-between" className={'row-table'}>
                        <p className={'title'}>{i18n.boxProfileCite.recruitmentSource}</p>
                        <p className="value">
                            <EditFieldAction remoteName={'fuente_recrutamiento'} defaultValue={origin || ''}/>
                        </p>
                    </Grid>
                }
                {/* {actualStatus &&
                        <Grid container justifyContent="space-between" >
                            <p className={'title'}>{i18n.boxProfileCite.currentState}:</p>
                            <p className="value">{actualStatus || '-'}</p>
                        </Grid>
                    } */}
            </Grid>
        </BoxOtherInfoHeaderStyled>
    )
}

BoxOtherInfoHeader.defaultProps = {
    title: '',
    titleInfo1: '',
    titleInfo2: '',
    titleInfo3: ''
}

BoxOtherInfoHeader.propTypes = {
    actualStatus: PropTypes.string,
    title: PropTypes.string,
    titleInfo1: PropTypes.string,
    titleInfo2: PropTypes.string,
    titleInfo3: PropTypes.string,
    value1: PropTypes.string,
    value2: PropTypes.string,
    value3: PropTypes.string
}

const enharce = compose(withI18n)

export default enharce(BoxOtherInfoHeader)
