import styled from 'styled-components'

const ComponentStyled = styled.div`
  && {
    .talent-clue-logo {
      width: 45px;
      height: 45px;
    }
  }
`

export default ComponentStyled
