/* eslint-disable react/display-name */
import React, {useEffect, useState} from 'react'
import moment from 'moment'
import _, {isArray} from 'lodash'
import Grid from '@material-ui/core/Grid'
import {
  BoxInfoHeader,
  BoxKeyValue,
  BoxIAGen,
  BoxOtherInfo,
  BoxOtherInfoHeader,
  BoxProfileCite,
  BoxSelectionFunnel,
  BoxVideoCandidate,
  BoxVideoSentiments,
  DocumentsList,
  DocumentsListViewer,
  HeaderProfileButtonsQuery,
  ObservationsTable,
  ProfileActionsButtonsQuery,
  ProfileStatsQuery,
} from '../../molecules'
import ProfileInfoStyled from './styled'
import { ButtonWithIcon, IconEdit, MTCitationStatusTextColor, MTDate, MTText, SpinnerIcon, IconIA } from '../../atoms'
import {
  ALERT_LEVEL,
  BACKEND_CLASSES,
  getCurrentSectionKey,
  MODULES,
  QUERY_ACTIONS,
  QUERY_KEYS,
  SECTION_KEYS
} from "../../../config/constants";
import {useAppContext} from "../../../context/appContext";
import {useParams} from "react-router";
import useObservations from "../../../api/queries/useObservations";
import i18n from '../../../assets/i18n'
import Divider from "@material-ui/core/Divider";
import BoxVideoInterview from "../../molecules/box-videointerview";
import BoxPDAEmergiaInfo from "../../molecules/box-pda-emergia-info";
import BoxPDAMVMInfo from "../../molecules/box-pda-mvm-info";
import _get from "lodash/get";
import {
  manageQueriesWithPrefix,
  mutationPostObservationCallback,
  refetchQueriesByKeys
} from "../../../api/mutations/mutationCallbacks";
import _map from "lodash/map";
import * as S from "../profile-processes-info/styled";
import JSZip from "jszip";
import {Box, Tab, Tabs} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {FeatureGuard} from "../../../utils/guard";
import DraggableModal from "../../molecules/draggable-modal/view";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MaterialTable from "material-table";
import {useMutation} from "@tanstack/react-query";
import * as api from "../../../api";
import useCitations from "../../../api/queries/useCitations";
import eventTracker from "../../../utils/eventTracker";
import usePhases from "../../../api/queries/usePhasesProcesses";
import {usePersonWithCandidature} from "../../../api/queries/usePerson";
import useTagsTypes from "../../../api/queries/useTagsTypes";
import {useJobOfferApplications} from "../../../api/queries/useJobOffers";
import { AuthUtils } from '../../../redux/auth'

const ProfileInfoPerson = ({user, showActions = true, showFav = true}) => {

  const {filters, range, sort, filterGroup, module, clearContextIfNeeded, createAlert} = useAppContext();
  const {savedSearchItemId, slug, job_offer_id, job_offer_application_id} = useParams();

  const { data: jobOfferApplications, isLoading} = useJobOfferApplications(job_offer_id, filters[SECTION_KEYS.JOB_OFFER_APPLICATIONS], range[SECTION_KEYS.JOB_OFFER_APPLICATIONS], sort[SECTION_KEYS.JOB_OFFER_APPLICATIONS]);
  const {data: personData, isFetched} = usePersonWithCandidature(slug, job_offer_application_id);
  const {data: observations, isFetched: isObservationsFetched} = useObservations(module, slug);
  const {data: lastCitations, isFetched: isCitationsDataFetched} = useCitations(
    module,
    slug ? {"jobOfferApplication.id": job_offer_application_id} : null,
    range[SECTION_KEYS.PLANNING],
    sort[SECTION_KEYS.PLANNING]);
  const {data: tagsPerson, isFetched: isTagsPersonFetched} = useTagsTypes(module);

  const { data: phasesData } = usePhases(module, data => data)

  useEffect(() => {
    eventTracker.track('AccessToPersonProfile', {
      personId: personId,
      module: module
    });
  }, []);

  const mutationPostObservation = useMutation(api.postUserObservationsQuery);
  const mutationChangeTags = useMutation(api.changeTags_query);
  const mutationPostFavorites = useMutation(api.addJobOfferApplicationFav);
  const mutationDeleteFavorites = useMutation(api.removeJobOfferApplicationFav);

  // const [forceApplicantEvaluation, setForceApplicantEvaluation] = useState(false);

  const [candidatureId, setCandidatureId] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [currentStatusLabel, setCurrentStatusLabel] = useState('');

  const [personId, setPersonId] = useState(null);
  const [tabValue, setTabValue] = useState( 'notes' );
  const [interviewerNote, setInterviewerNote] = useState( '' );
  const [printNotes, setPrintNotes] = useState( false );
  const [showCvPreview, setShowCvPreview] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  // START - Child Modal States
  const [isCitationOpened, setIsCitationOpened] = useState(false);
  const [citationData, setCitationData] = useState(false);
  const [isRequestDocumentationOpened, setIsRequestDocumentationOpened] = useState(false);
  const [isAttachmentFilesOpened, setIsAttachmentFilesOpened] = useState(false);
  const [isBlacklistOpened, setIsBlacklistOpened] = useState(false);
  const [isDeleteCandidatureOpened, setIsDeleteCandidatureOpened] = useState(false);
  const [isOpenModalSuggestChangeStatus, setIsOpenModalSuggestChangeStatus] = useState(false);
  // END - Child Modal States

  const [isLabelSelectorOpened, setIsLabelSelectorOpened] = useState(false);

  useEffect(() => {
    if (slug) {
      setPersonId(slug);
    }
  }, [slug]);

  useEffect(() => {
    clearContextIfNeeded(MODULES.currentModule());
  }, [module]);

  /*useEffect(() => {
    if(forceApplicantEvaluation) {
      // TODO: send request to backend
    }
  }, [forceApplicantEvaluation])*/

  const mutationPostReads = useMutation(api.addJobOfferApplicationRead);

  const isRead = rowData => {
    let applications = rowData.job_offer_applications;
    for (let i = 0; i < applications.length; i++) {
      if (applications[i].id == job_offer_application_id) {
        return isArray(applications[i].job_offer_application_reads);
      }
    }
  }

  const MTAttendees = ( rowData ) => {
    let attendees = '';
    rowData.attendees && rowData.attendees.map( ( attendee ) => {
      attendees += attendee.emailAddress && ', ' + attendee.emailAddress.address ;
    });

    let attendeesText = rowData.names + "" + attendees;

    if (attendeesText.length > 20) {
      attendeesText = attendeesText.substring(0, 20) + ' ...';
    }
    return <MTText title={rowData.names + "" + attendees} style={{'text-overflow': 'ellipsis'}} value={ attendeesText }/>
  };

  const lastCitationsTableColumns = [
    { title: i18n.lastCitationsTable.date, field: 'name', render: row => row.date.date ? <MTDate date={row.date.date}/> : '-'},
    { title: i18n.lastCitationsTable.interviewDate, field: 'interviewDate', render: row => row.citationDate.date ? <MTDate date={row.citationDate.date}/> : '-'},
    { title: i18n.lastCitationsTable.location, field: 'location', render: row => <MTText value={row.location}/>},
    { title: i18n.lastCitationsTable.userName, field: 'userName', render: row => <MTText value={row.username}/>},
    { title: i18n.lastCitationsTable.attendees, field: 'attendees', render: MTAttendees},
    { title: i18n.lastCitationsTable.statusAnswer, field: 'statusAnswer', render: row => <MTCitationStatusTextColor value={row.status} />},
    { title: i18n.lastCitationsTable.editCitation, field: 'editCitation', render: row => <div className='custom-edit' onClick={() => handleEditIconClick(row)} ><IconEdit /></div>  },
  ];

  const handleEditIconClick = (row) => {
    setCitationData(row);
    setIsCitationOpened(true);
  };

  const tableOptions = {
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    cellStyle: { textAlign: 'center' },
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
    toolbar: false,
    draggable: false,
  }

  //Tab Panel
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  useEffect(() => {
    if (personData) {
      setInterviewerNote(personData.nota_entrevistador);

      let jobOfferApplication = _.find(personData.job_offer_applications, item => item.id == job_offer_application_id);
      setIsFavorite(jobOfferApplication.job_offer_application_favs);

      let cv = _.get(personData, 'cv');
      if (cv && cv.length > 0) {
        let cvMainUrl = cv.split('?').shift();
        // eslint-disable-next-line
        let extension = cvMainUrl.split('.').pop();
        setShowCvPreview(true);
      }

      let atts = _.get(personData, 'attachments_people');
      if(atts && !Array.isArray(atts) && Object.keys(atts).length > 0){
        atts = [atts];
      }

      if (Array.isArray(atts)) {
        setAttachments(atts);
      }

      if (!isRead(personData)) {
        let queriesToRefetch = [`${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`, `${QUERY_KEYS.JOB_OFFERS}`];
        mutationPostReads.mutateAsync({ids: [job_offer_application_id]})
          .then(() => {
            manageQueriesWithPrefix(queriesToRefetch, QUERY_ACTIONS.REFETCH);
          });
      }
    }

  }, [personData]);

  const handleAddObservation = (value) => {
    mutationPostObservation.mutateAsync({id: personId, change_msg: value, module, type: 'person'},
      mutationPostObservationCallback(personId, value,  module, observations)
    ).then(() => createAlert(i18n.toast_msg.observation.success, ALERT_LEVEL.SUCCESS)
    ).catch(() => createAlert(i18n.toast_msg.observation.error, ALERT_LEVEL.ERROR));
  }

  const onSubmitProfileChangeTags = (ids, tags, module) => {
    mutationChangeTags.mutateAsync({ids, tags, module}).then(() => {
      let queriesToRefetch = [
        `${module}:${QUERY_KEYS.PROFILE}:${slug}`,
        `${module}:${QUERY_KEYS.INBOX}`,
        `${module}:${QUERY_KEYS.FAVORITES}`,
        `${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`,
        `${QUERY_KEYS.PERSON}:${slug}:${job_offer_application_id}`,
        `${module}:${QUERY_KEYS.OBSERVATIONS}:${slug}`
      ];

      if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM && savedSearchItemId && filterGroup && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM] && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId]) {
        queriesToRefetch.push(`${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:${filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId]}`);
      }
      refetchQueriesByKeys(queriesToRefetch);
    });
  }

  const handleOnClickFav = () => {
    if (isFavorite) {
      mutationDeleteFavorites.mutateAsync({ids: [job_offer_application_id], module}).then(() => {
        setIsFavorite(false);
        manageQueriesWithPrefix([
          `${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`,
          `${QUERY_KEYS.PERSON}:${personId}:${job_offer_application_id}`
        ], QUERY_ACTIONS.REFETCH);
      });
    } else {
      mutationPostFavorites.mutateAsync({ids: [job_offer_application_id], module}).then(() => {
        setIsFavorite(true);
        manageQueriesWithPrefix([
          `${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`,
          `${QUERY_KEYS.PERSON}:${personId}:${job_offer_application_id}`
        ], QUERY_ACTIONS.REFETCH);
      });
    }
  }

  const formatCV = (cvFile) => {
    if (cvFile) {
      let cvMainUrl = cvFile.split('?').shift();
      let cvName = cvMainUrl.split('/').pop();
      let originalUrl = cvMainUrl.split('.com/').pop();

      let cvObj = {
        id: originalUrl,
        name: decodeURI(cvName),
        file: cvFile,
      }
      return [cvObj];
    }
    return [];
  }

  const formatAttachments = data => {
    if (!data) return []
    if (data.length <= 1 && "id" in data) data = [data]

    let files = _map(data, ({id, original_name, url}) => {
      return {id, name: original_name, file: url}
    });
    //let cv = formatCV(_get(profileData, 'cv'), true);
    //if (!_.isEmpty(cv)) files.push(cv)

    return files;
  }

  const downloadBlob = (blob, name = 'myFile') => {
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.href = blobUrl
    link.download = name
    document.body.appendChild(link)
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    )
    document.body.removeChild(link)
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }} style={{padding: "0px"}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps( index ) {
    return {
      id: `simple-tab-${ index }`,
      value: index,
      'aria-controls': `simple-tabpanel-${ index }`,
    };
  }

  const handleTabChange = ( event, newValue ) => {
    setTabValue( newValue );
  };

  const getInterviewerNote = () => {
    return {__html: _.split( interviewerNote, '-*{}*-')[0]};
  }

  const getInterviewerNoteAuthor = () => {
    if(_.isEmpty(interviewerNote))
    {
      return '';
    }
    //Pasamos la traducción
    return i18n.interviewerNotes.updatedAt
      .replace('{author}',_.split(interviewerNote, '-*{}*-')[1])
      .replace('{date}', _.split(interviewerNote, '-*{}*-')[3])
      .replace('{hour}', _.split(interviewerNote, '-*{}*-')[2]);

    //i18n.modalAnnouncement.confirmation[!isEdit ? 'description_create' : 'description_update'].replaceAll('{num}'
  }

  const handleDownload = () => {
    const newDocuments = selectedDocuments.map(item => {
      return fetch(item.file, {cache: 'no-cache'})
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          return Promise.resolve({
            data: Buffer.from(arrayBuffer, 'binary').toString('base64'),
            name: item.name,
            file: item.file
          })
        });
    })

    var zip = new JSZip()
    Promise.all(newDocuments).then(response => {
      response.map(item => zip.file(item.name, item.data, {base64: true}))
      zip.generateAsync({type: 'blob'}).then(function (content) {
        downloadBlob(content, 'files_' + moment().format('YYYYMMDD_Hms') + '.zip')
      })
    })
  }

  const renderTabs = ( profileData ) => {
    if (!profileData || !isFetched || !isObservationsFetched) {
      return <SpinnerIcon/>
    } else {
      return <>
        <Grid container xs={12} spacing={ 2 } style={{marginTop: "50px", marginBottom:"0px"}}>

          <Grid item xs={ 12 }>

            <Box sx={{ m:-2 }}>

              {/* Tabs */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={"no-print"} >
                <Tabs value={ tabValue } onChange={ handleTabChange } aria-label="basic tabs example" >
                  <Tab label={i18n.interviewerNotes.titleTab} className={ `documentsInfoTitle tab-item ${ printNotes ? 'print' : 'no-print' }` } {...a11yProps('notes')} />
                  <Tab label={i18n.observationsTable.title} className={"documentsInfoTitle tab-item"} {...a11yProps('observations')} />
                  {FeatureGuard.canAccessToFunnelStatus() && <Tab label={i18n.lastCitationsTable.title} className={"documentsInfoTitle tab-item"} {...a11yProps('last-citations')} />}
                </Tabs>
              </Box>

              {/*Notas entrevista*/}
              <TabPanel value={ tabValue } index={'notes'} >
                <Grid id="interviewer_notes_container" spacing={10} item style={{padding: "30px"}}
                      className={printNotes ? 'print' : 'no-print'}>
                  <p className={`interview-otes-title-print ${ printNotes ? 'print' : 'no-print' }` }>{ i18n.interviewerNotes.titleTab }</p>
                  <p className={"interviewerNote"} dangerouslySetInnerHTML={getInterviewerNote()}></p>
                  <Grid className={"no-print"}>
                    <DraggableModal user={user} id={personId} titleTab={i18n.interviewerNotes.titleTab} module={module}
                                    className={BACKEND_CLASSES.PERSON}
                                    slug={slug} interviewerNote={interviewerNote} setInterviewerNote={setInterviewerNote}/>
                    <FormControlLabel control={<Checkbox checked={ printNotes } style={{ marginLeft: "15px" }} onClick={ () => setPrintNotes( !printNotes ) }/>} label={ i18n.interviewerNotes.showOnPrint } />
                    <span className={"interviewerNote_metadata pull-right"}>{getInterviewerNoteAuthor()}</span>
                  </Grid>
                </Grid>
              </TabPanel>

              {/*Observaciones*/}
              <TabPanel value={ tabValue } index={'observations'} >
                <Grid style={{padding: "0px"}} spacing={2} container  className={!observations || observations.length === 0 ? 'no-print' : 'print'}>
                  <Grid id="observations-wrapper" item xs={12} style={{marginTop:"-10px"}} >
                    {!observations || (!isObservationsFetched && <SpinnerIcon styledType={'overlay-spinner'}/>)}
                    <ObservationsTable id='boxObsevationsContainer'
                      // showObsInput={showObsInput}
                                       showObsInput={true}
                                       handleAddObservation={handleAddObservation}
                                       observations={observations}
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              {/*Últimas Citaciones*/}
              {FeatureGuard.canAccessToFunnelStatus() && <TabPanel value={ tabValue } index={'last-citations'} >
                <Grid style={{padding: 0, marginLeft: 3}} spacing={2} container  className={!observations || observations.length === 0 ? 'no-print' : 'print'}>
                  <Grid id="last-citations-wrapper"  className="last_citations_table_container" item xs={12} style={{marginTop:"8px"}} >
                    {!lastCitations || (!isCitationsDataFetched && <SpinnerIcon styledType={'overlay-spinner'}/>)}
                    <MaterialTable
                      data={lastCitations && lastCitations.data ? lastCitations.data : []}
                      columns={lastCitationsTableColumns}
                      options={tableOptions}
                      localization={i18n.tableLocalization}
                    />
                  </Grid>
                </Grid>
              </TabPanel>}
            </Box>
          </Grid>
        </Grid>
      </>
    }
  }

  const renderProfile = (personId, personData, showActions, showFav) => {
    let jobOfferApplication = _.find(personData.job_offer_applications, item => item.id == job_offer_application_id);
    let candidatureData = personData.candidature ?? null;
    const candidature = _.get(candidatureData, 'candidatura', '')
    const workingDay = _.get(candidatureData, 'jornada', '')
    let userName = `${_.get(personData, 'nombre', '')} ${_.get(personData, 'apellidos', '')}`
    if(!_.get(personData, 'nombre') && !_.get(personData, 'apellidos')) {
      userName = _.get(personData, 'email');
    }
    const photo = _.get(personData, 'foto', null)

    const profileValue = Math.round(_.get(candidatureData, 'scoring_profile', ''))
    const matchValue = Math.round(_.get(candidatureData, 'matching_total', ''))
    const apto = _.get(candidatureData, 'apto', undefined)
    const skill1 = _.get(candidatureData, 'skill_score1', '')
    const skill2 = _.get(candidatureData, 'skill_score2', '')
    const skill3 = _.get(candidatureData, 'skill_score3', '')
    const skill4 = _.get(candidatureData, 'skill_score4', '')
    const skillsValue = Math.round(_.get(candidatureData, 'scoring_skills', ''))
    const skills = [skill1, skill2, skill3, skill4]

    const hasSkills = () => {
      return !(skill1.type && skill1.type.name === 'Vacio'
        && skill2.type && skill2.type.name === 'Vacio'
        && skill3.type && skill3.type.name === 'Vacio'
        && skill4.type && skill4.type.name === 'Vacio');
    }

    const isIAGenEnabled = () => {
      if(!AuthUtils.isDebugUser()) {
        return false;
      }

      return personData?.iagen_enabled || false;
    }

    const hasIAGenData = () => {
      return isIAGenEnabled() && jobOfferApplication.job_offer_application_cv?.ia_criteria;
    }

    let origin = _.get(candidatureData, 'fuente_recrutamiento', '')
    let experience = _.get(candidatureData, 'anos_experiencia', '')

    let formation = _.get(personData, 'formacion', '')
    let lastJob = _.get(personData, 'ultimo_puesto', '')

    const hasFormationExperienceOrLastJob = () => formation || experience || lastJob

    let email = _.get(personData, 'email', '')
    let phone = _.get(personData, 'telefono', '')
    let tags = _.get(candidatureData, 'tags', null)
    let address = _.get(personData, 'direccion', '')
    let location = _.get(personData, 'cp', '')
    let zone = _.get(personData, 'zona_deseada', '')
    let variableFields = _.get(candidatureData, 'variable_fields', '')

    let availability = _.get(candidatureData, 'disponibilidad', '')
    availability = moment(availability).isValid() ? moment(availability).format('DD/MM/YYYY') : availability
    let citationDate = _.get(candidatureData, 'citation.citation_date', '')
    citationDate = citationDate ? moment(citationDate).format('DD/MM/YYYY') : ''
    let hiringDate = _.get(personData, 'hiring_date', '')
    hiringDate = hiringDate ? moment(hiringDate).format('DD/MM/YYYY') : ''
    let dateOfBirth = _.get(personData, 'fecha_nacimiento', '')
    const dateOfBirthFormat = moment(dateOfBirth, _.includes(dateOfBirth, '-') ? 'YYYY-MM-DD' : 'DD/MM/YYYY')
    const formatYears = dateOfBirth ? `${moment().diff(dateOfBirthFormat.format('YYYY-MM-DD'), 'years')} ${i18n.profileInfo.years}` : ''
    dateOfBirth = dateOfBirth ? `${formatYears} - ${dateOfBirthFormat.format('DD/MM/YYYY')}` : ''
    const yearsOld = _.get(personData, 'edad') ? `${_.get(personData, 'edad')} ${i18n.profileInfo.years} ` : ''
    const years = dateOfBirth ? dateOfBirth : yearsOld

    let createdAt = _.get(jobOfferApplication, 'created_at', '');
    createdAt = moment(createdAt).isValid() ? moment(createdAt).format('DD/MM/YYYY') : createdAt;
    let updatedAt = _.get(jobOfferApplication, 'updated_at', '');
    updatedAt = moment(updatedAt).isValid() ? moment(updatedAt).format('DD/MM/YYYY') : updatedAt;

    let pullSentAt = _.get(candidatureData, 'pull_sent_at', '');
    pullSentAt = _.isObject(pullSentAt) ? pullSentAt.date : pullSentAt;
    pullSentAt = moment(pullSentAt).isValid() ? moment(pullSentAt).format('DD/MM/YYYY') : pullSentAt;
    let isPullAccepted = _.get(candidatureData, 'is_pull_accepted', null);
    let evaluative_config = _.get(candidatureData, 'evaluative_config', []);
    if(evaluative_config.id){
      evaluative_config = [evaluative_config];
    }

    const dni = _.get(personData, 'dni', '')

    const files = []
    const cvFile = _.get(personData, 'cv', '')
    const videoAnalysis = _.get(candidatureData, 'video_analysis', '');
    const videoUrl = _.get(candidatureData, 'video', '')
    const attached1 = _.get(candidatureData, 'adjunto1', '')
    const attached2 = _.get(candidatureData, 'adjunto2', '')
    const attached3 = _.get(candidatureData, 'adjunto3', '')
    const attached4 = _.get(candidatureData, 'adjunto4', '')
    const attached5 = _.get(candidatureData, 'adjunto5', '')
    const attached6 = _.get(candidatureData, 'adjunto6', '')
    const attached7 = _.get(candidatureData, 'adjunto7', '')
    const attached8 = _.get(candidatureData, 'adjunto8', '')
    const attached9 = _.get(candidatureData, 'adjunto9', '')
    const attached10 = _.get(candidatureData, 'adjunto10', '')

    cvFile && files.push({label: i18n.profileInfo.cv, url: cvFile})
    videoUrl && files.push({label: i18n.profileInfo.video, url: videoUrl})
    attached1 && files.push({label: `${i18n.profileInfo.attached} 1`, url: attached1})
    attached2 && files.push({label: `${i18n.profileInfo.attached} 2`, url: attached2})
    attached3 && files.push({label: `${i18n.profileInfo.attached} 3`, url: attached3})
    attached4 && files.push({label: `${i18n.profileInfo.attached} 4`, url: attached4})
    attached5 && files.push({label: `${i18n.profileInfo.attached} 5`, url: attached5})
    attached6 && files.push({label: `${i18n.profileInfo.attached} 6`, url: attached6})
    attached7 && files.push({label: `${i18n.profileInfo.attached} 7`, url: attached7})
    attached8 && files.push({label: `${i18n.profileInfo.attached} 8`, url: attached8})
    attached9 && files.push({label: `${i18n.profileInfo.attached} 9`, url: attached9})
    attached10 && files.push({label: `${i18n.profileInfo.attached} 10`, url: attached10})

    return (
      <ProfileInfoStyled id='profilePrint'>
        <div className="no-print">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <HeaderProfileButtonsQuery
                userName={userName}
                candidatureId={personId}
                setCandidatureId={setPersonId}
                targetInboxData={!isLoading && jobOfferApplications && jobOfferApplications.data ? jobOfferApplications.data : null}
                isFetchedTargetInboxData={!isLoading}
                totalRQuery={!isLoading && jobOfferApplications && jobOfferApplications.total ? jobOfferApplications.total : 0}
                isPerson={true}
                module={module}
              />
            </Grid>
          </Grid>
        </div>
        <Grid id='firstSectionStyle' container spacing={4}>
          <Grid className='profileActionButtonsWrapper' justifyContent='space-between' container item lg={12}>
            <Grid id="ProfileActionsButtons" container item lg={12}>
              <ProfileActionsButtonsQuery
                files={files}
                currentStatus={currentStatusLabel}
                setCurrentStatus={setCurrentStatusLabel}
                userName={userName}
                candidatureId={candidatureId}
                setCandidatureId={setCandidatureId}
                personId={personId}
                module={module}
                statusData={jobOfferApplication.selection_status}
                citationModalStatus={{ isCitationOpened, setIsCitationOpened }}
                requestDocumentationModalStatus={{ isRequestDocumentationOpened, setIsRequestDocumentationOpened }}
                attachmentFilesModalStatus={{ isAttachmentFilesOpened, setIsAttachmentFilesOpened }}
                blacklistModalStatus={{ isBlacklistOpened, setIsBlacklistOpened }}
                deleteCandidatureModalStatus={{ isDeleteCandidatureOpened, setIsDeleteCandidatureOpened }}
                suggestChangeStatusModal={{ isOpenModalSuggestChangeStatus, setIsOpenModalSuggestChangeStatus}}
                citationData={{citationData, setCitationData}}
              />
            </Grid>
            <Divider className='divider-horizontal' variant="fullWidth"/>
          </Grid>

          {jobOfferApplication && jobOfferApplication.selection_status && phasesData &&
            <Grid item lg={12} style={ {width : 'inherit'} } justifyContent='center'>
              <BoxSelectionFunnel profileData={jobOfferApplication} i18n={i18n} phasesData={phasesData} selectionStatusValue={jobOfferApplication.selection_status}/>
            </Grid>
          }

          <Grid className={"profileStatsInfo-print"} direction="row" container item lg={12}>
            <Grid spacing={4} item lg={6}>
              {tagsPerson &&
                <ProfileStatsQuery
                  personId={personId}
                  showFav={showFav}
                  isFav={isFavorite}
                  handleOnClickFav={handleOnClickFav}
                  avatarImage={photo}
                  info2={years}
                  titleInfo3={i18n.profileInfo.availableFrom}
                  titleInfo4={''}
                  info4={dni}
                  mail={email}
                  tel={phone}
                  tags={tags}
                  personData={personData}
                  tagsTypes={tagsPerson}
                  userName={userName}
                  candidatureId={candidatureData ? candidatureData.id : null}
                  changeTags={onSubmitProfileChangeTags}
                  module={module}
                  createdAt={createdAt}
                  updatedAt={updatedAt}
                  pullSentAt={pullSentAt}
                  isPullAccepted={isPullAccepted}
                  valid={apto}
                  labelSelectorStatus={{isLabelSelectorOpened, setIsLabelSelectorOpened}}
                />}
            </Grid>
            <Grid className="otherInfoContainer" spacing={4} item lg={6}>
              <BoxOtherInfoHeader
                titleInfo1={i18n.profileInfo.city}
                titleInfo2={i18n.profileInfo.desiredLocation}
                titleInfo3={i18n.profileInfo.desiredZone}
                value1={address}
                value2={location}
                value3={zone}
                origin={origin}
                workingDay={workingDay}
                availability={availability}
              />
            </Grid>
          </Grid>

          {(matchValue || profileValue || skillsValue) ? (
            <Grid id='analiticSection' direction="row" container item lg={12} spacing={4}>
              <Grid id="boxInfoHeaderGridContainer" item lg={hasSkills() ? 6 : 12}>
                <BoxInfoHeader
                  totalMatch={matchValue}
                  profileValue={profileValue}
                  valid={apto}
                  title={i18n.profileInfo.profile}
                  titleInfo1={i18n.profileInfo.formation}
                  titleInfo2={i18n.profileInfo.exp}
                  titleInfo3={i18n.profileInfo.lastJob}
                  value1={formation}
                  value2={experience}
                  value3={lastJob}
                  noSkillsRender={!hasSkills()}
                  showAdditionalInfo={hasFormationExperienceOrLastJob()}
                />
              </Grid>
              {hasSkills() && <Grid id='boxProfileCiteGridContainer' item lg={6}>
                <BoxProfileCite
                  date={citationDate}
                  hiringDate={hiringDate}
                  skillValue={skillsValue}
                  skills={skills}
                />
              </Grid>}
            </Grid>) : null}

        </Grid>

        {/*{isIAGenEnabled() && AuthUtils.isDebugUser() &&
          <Grid container className="pagebreakavoid" style={{ margin: '4rem auto 2rem' }}>
            <Grid item xs={12}>
              <ButtonWithIcon
                Icon={<IconIA color={"green"} />}
                title={"Forzar Evaluación IA"}
                onClick={() => setForceApplicantEvaluation(true)}
              />
            </Grid>
          </Grid>
        }*/}

        {hasIAGenData() && (
          <Grid id='iagen-section' container className="pagebreakavoid">
            <Grid item xs={12}>
              <BoxIAGen
                data={jobOfferApplication.job_offer_application_cv}
                debug={AuthUtils.isDebugUser()}
              />
            </Grid>
          </Grid>
        )}

        <Grid spacing={4} container>

          {evaluative_config.length > 0 &&
            <Grid container className="pagebreakavoid">
              <div className="header-table">
                <p>{i18n.evaluativeTests.titleBoxProfileInbox}</p>
              </div>
              {evaluative_config.length ?
                evaluative_config.map((config, index) => (
                  <Grid item xs={12} key={index}>
                    <BoxVideoInterview evaluativeConfig={config} profileData={candidatureData} i18n={i18n} />
                  </Grid>
                )) : null
              }
            </Grid>
          }
          {candidatureData && candidatureData.integrations_pda_emergia &&
            <Grid container className="pagebreakavoid">
              <Grid item xs={12}>
                <BoxPDAEmergiaInfo pdaFields={candidatureData.integrations_pda_emergia}/>
              </Grid>
            </Grid>
          }

          {candidatureData && candidatureData.integrations_pda &&
            <Grid container className="pagebreakavoid">
              <Grid item xs={12}>
                <BoxPDAMVMInfo pdaFields={candidatureData.integrations_pda}/>
              </Grid>
            </Grid>
          }

          {candidatureData && candidatureData.nearby_places && candidatureData.nearby_places.length > 0 &&
            <Grid container className="nearbyPlacesWrapper pagebreakavoid">
              <Grid item xs={12}>
                <BoxKeyValue
                  title={i18n.boxOtherInfo.nearbyPlaces}
                  data={candidatureData.nearby_places}
                  dataKey={'place_name'}
                  dataValue={'distance_human'}
                  showList={true}
                  numCols={2} />
              </Grid>
            </Grid>
          }

          <Grid id='boxOtherInfoContainer' container className="pagebreakavoid">
            <Grid item xs={12}>
              <BoxOtherInfo variableFields={variableFields} profile={candidatureData}/>
            </Grid>
          </Grid>

          { videoAnalysis && Object.keys(videoAnalysis).length > 0 &&
            <Grid id="videoSentiments" container className="pagebreakavoid">
              <Grid item xs={12}>
                <BoxVideoSentiments videoUrl={videoUrl} videoAnalysis={ videoAnalysis }/>
              </Grid>
            </Grid>
          }

          <Grid container spacing={2} className="pagebreakavoid">
            {candidatureData && candidatureData.video && (
              <Grid item lg={personData.cv ? 5 : 12} md={12} xs={12}>
                <div className="header-table">
                  <p>{i18n.process.previewVideo}</p>
                </div>
                <BoxVideoCandidate profileData={candidatureData} lg={personData.cv ? 5 : 12} />
              </Grid>
            )}

            {personData && showCvPreview && _.get(personData, 'cv') !== null && (
              <Grid item lg={candidatureData && candidatureData.video ? 7 : 12} md={12} xs={12} className="pagebreakavoid no-print">
                <p className="documentsInfoTitle">{i18n.process.previewCV}</p>
                <DocumentsListViewer key={slug} options={formatCV(_get(personData, 'cv'))} embedded={true} singleDoc={true}/>
              </Grid>
            )}
          </Grid>
        </Grid>

        {personData && attachments.length ?
          <Grid spacing={4} id='documentsInfoContainer' container className="pagebreakavoid no-print">
            <p className="documentsInfoTitle">{i18n.process.documentation}</p>
            <Grid className="documentsInfoBody" item xs={12}>
              <S.Toolbar className="no-print">
                <S.ActionButton
                  disabled={!selectedDocuments || selectedDocuments.length <= 0}
                  handleOnClick={handleDownload}
                  title={i18n.process.downloadDocs}
                  styledType="invert"
                />
              </S.Toolbar>
              <DocumentsList
                check
                value={selectedDocuments}
                options={formatAttachments(attachments)}
                onChange={value => setSelectedDocuments(value)}
              />
            </Grid>
          </Grid>
          : null
        }

        {renderTabs(personData)}

      </ProfileInfoStyled>
    )
  }

  const LogicOfRender = () => {
    if (!personData || !isFetched) {
      return <SpinnerIcon/>
    }
    return renderProfile(personId, personData, showActions, showFav)
  }

  return (
    <LogicOfRender/>
  )
}

export default ProfileInfoPerson
