import styled from 'styled-components'

export const Idiv = styled.div``
export const Ia = styled.a``

export const MTImageStyled = styled.div`
  letter-spacing: 1px;
  font-weight: 400 !important;
  width: ${props => props.size === 'big' ? '84px' : '50px'};
  height: ${props => props.size === 'big' ? '84px' : '50px'};
  border-radius: 50%;
  background-color: ${props => props.theme.primary};
  background-position: center center;
  background-size: cover;
  line-height:${props => props.size === 'big' ? '6vw' : '50px'};
  color:${props => props.theme.textPrimary};
  font-size: 20px;
  margin-right:  ${props => props.size === 'big' ? 'auto' : '12px'};
  margin-left: ${props => props.size === 'big' ? 'auto' : '0px'};
  text-align:center;
  
  &.big {
    font-size: 3vw;
    letter-spacing: 3px;
    width: 6vw;
    height: 6vw;
  }
`
