import styled from 'styled-components'
import colors from '../../../assets/colors'

export const ModalStyled = styled.div`
  && {
    border-radius: 8px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    width: 685px;
    outline: none;

    .modal-body {
      background-color: ${colors.white};
      padding: 30px 50px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      outline: none;
      label {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
      .sms {
        margin-top: 15px;
      
        .box-check {
          margin-left: 10px;
        }
      }

      .button-wrapper {
        text-align: right;
        margin-top: 25px;

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: right;
          cursor: pointer;
        }

        button {
          margin-top: 25px;
        }
      }

      .MuiSwitch-thumb {
        border: ${props => `solid 1px ${props.theme.primary}`};
      }
    }
  }
`
