import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Icon = () => (
  <SvgIcon width="21" height="22" viewBox="0 0 21 22" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33976 14.3895L7.94463 14.1524L12.8446 9.24799L10.4856 6.88811L5.57076 11.803L5.33976 14.3895ZM11.6581 5.71649L14.0163 8.07461L15.7199 6.36836L13.3626 4.01111L11.6581 5.71649ZM3.75602 15.975C3.57227 15.7912 3.4804 15.5357 3.50315 15.2767L3.83477 11.628C3.87152 11.2281 4.04827 10.851 4.33352 10.5657L12.205 2.69424C12.8193 2.07736 13.9331 2.10799 14.5815 2.75461L16.9773 5.15036L16.9781 5.15124C17.6475 5.82149 17.6746 6.88724 17.0376 7.52599L9.16527 15.3984C8.8809 15.6827 8.50377 15.8595 8.10302 15.8962L4.45427 16.2279C4.42802 16.2296 4.40177 16.2305 4.37465 16.2305C4.14452 16.2305 3.9214 16.1395 3.75602 15.975V15.975ZM17.4996 18.8555C17.4996 19.3367 17.1059 19.7305 16.6246 19.7305H4.37463C3.89426 19.7305 3.49963 19.3367 3.49963 18.8555C3.49963 18.3751 3.89426 17.9805 4.37463 17.9805H16.6246C17.1059 17.9805 17.4996 18.3751 17.4996 18.8555V18.8555Z"
      fill="black"
    />
  </SvgIcon>
)

export default Icon
