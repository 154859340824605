import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import {withI18n} from '../../hocs'
import PropTypes from 'prop-types'
import ProfileStatsStyled from './styled'
import {ChipCustom, FavouriteIcon, MTImage, UserIcon} from '../../atoms'
import {Grid} from '@material-ui/core'
import Divider from '@material-ui/core/Divider';
import {ReactComponent as MailIcon} from '../../../assets/images/mail.svg'
import {ReactComponent as TelIcon} from '../../../assets/images/tel.svg'
import SelectTags from '../select-tags'
import {AuthUtils} from "../../../redux/auth";
import {MODULES, ROLES} from '../../../config/constants'
import { FeatureGuard } from '../../../utils/guard'

const ProfileStats = withI18n(
  ({
     i18n, tags, mail, tel, avatarImage, info4,
     userName, tagsTypes, candidatureId, changeTags, section,
     fetchUser, fetchOnboarding, fetchObservations, createdAt, updatedAt, pullSentAt, isPullAccepted,
     showFav, handleOnClickFav, isFav,
   }) => {

    const [tagsReduced, setTagsReduced] = useState(null);

    const module = MODULES.currentModule();
    const hasPullFeature = MODULES.currentModule() === MODULES.ID_TALENT_ACQUISITION && FeatureGuard.canAccessToPullOffers();

    let isOnlyReader;
    if (module === MODULES.ID_FEEDBACK) {
      isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_feedback)
    } else if (module === MODULES.ID_EMPLOYEE_LINE) {
      isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_employee_line)
    } else if (module === MODULES.ID_ON_BOARDING) {
      isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_on_board)
    } else if (module === MODULES.ID_EXIT_INTERVIEW) {
      isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_exit_interview)
    } else {
      isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_talent_acquisition)
    }

      const tagsReducer = () => {
          let tagsReduce = tags.reduce((accu, theTag) => {
              _.map(tagsTypes, (theTagType) => {
                  if (theTag.name === theTagType.name) {
                      accu[theTag.id] = theTag;
                      accu[theTag.id].color = theTagType.color;
                  }
              })
              return accu;
          }, {})

          setTagsReduced(tagsReduce)
      }

    useEffect(() => {
      tags && tagsReducer()
    }, [tags])// eslint-disable-line react-hooks/exhaustive-deps

      const getInitials = fullName => {
          function getInitial(name){
              return name && name.length ? name[0].toUpperCase() : '';
          }
          const fullNameSplit = fullName ? fullName.split(' ') : false;
          if (fullNameSplit && fullNameSplit.length > 1) {
              return getInitial(fullNameSplit[0]) + getInitial(fullNameSplit[1]);
          }
          return '';
      }

    const renderTags = () => {
      return (
        _.map(tagsReduced, (theTagsReduced) => {
          if (!isOnlyReader) {
            return <ChipCustom targetId={theTagsReduced.id} onDelete={removeTags} label={theTagsReduced.name} color={theTagsReduced.color} />
          } else {
            return <ChipCustom targetId={theTagsReduced.id} label={theTagsReduced.name} color={theTagsReduced.color} />
          }
        }))
    }

    const removeTags = (e) => {
      const idTagToRemove = parseInt(e.currentTarget.getAttribute('targetid'));
      let newTags = tags.filter(item => item.id !== idTagToRemove).map(tag => tag.id);

      const date = new Date();
      changeTags([candidatureId], newTags, date, module).then(() => {
        switch (module) {
          case MODULES.ID_ON_BOARDING:
            fetchOnboarding(candidatureId)
            break;
          default:
            fetchUser(candidatureId)
            fetchObservations(candidatureId)
            break;
        }
      })
    }

    const handleAddTag = (e) => {
      const idTagToAdd = parseInt(e.currentTarget.getAttribute('data-value'));

      let newTags = tags.map(tag => tag.id);
      newTags.push(idTagToAdd)
      const date = new Date();
      changeTags([candidatureId], newTags, date, module).then(() => {
        switch (module) {
          case MODULES.ID_ON_BOARDING:
            fetchOnboarding(candidatureId)
            break;
          default:
            fetchUser(candidatureId)
            fetchObservations(candidatureId)
            break;
        }
      })
    }

    /* Return only the tags for add */
    const tagsForSelect = () => {
      const idsNotAvaible = tags ? tags.map(tags => tags.id) : false;
      if (idsNotAvaible) {
        return tagsTypes.filter(theTagType => !idsNotAvaible.includes(theTagType.id));
      }
      return [];
    }

    const getPullIcon = (isPullAccepted) => {
      let pullIcon;

      switch (isPullAccepted) {
        case 1:
          pullIcon = <i className="fa fa-arrow-circle-up isPullAcceptedIcon" aria-hidden="true" style={{color: 'green'}}></i>;
          break;
        case 0:
          pullIcon = <i className="fa fa-clock-o isPullAcceptedIcon" aria-hidden="true" style={{color: 'grey'}}></i>;
          break;
        case -1:
          pullIcon = <i className="fa fa-2x fa-times-circle isPullAcceptedIcon" aria-hidden="true" style={{color: 'red'}}></i>;
          break;
        default:
          break;
      }

      return pullIcon;
    }

    return (
      <ProfileStatsStyled>
        <Grid className="profileStatsContainer" container direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
          {!['onboarding'].includes(section) &&
            <>
              <Grid id='imageContainer' className="no-print" item lg={2}>
                <MTImage inicials={getInitials(userName)} size='big' foto={avatarImage} />
              </Grid>
              <Divider className='divider-profile-stats divider-vertical MuiDivider-vertical' orientation='vertical'
                       flexItem='true'/>
            </>
          }
          <Grid id='profileStatsDataContainer' item lg={10} direction="column" justifyContent="space-between"
                alignItems="stretch">
            <Grid item xs={12}>
              <div>
                <div>
                  <p className="user-name-title" style={{display: "inline-block"}}>
                    {showFav && <FavouriteIcon className="no-print" size={60} favorite={isFav} onClick={handleOnClickFav} />}
                    <span>{userName}</span>
                  </p>
                  {hasPullFeature && isPullAccepted !== null && getPullIcon(isPullAccepted)}
                </div>
                <div className="profile_dates">
                    {createdAt && <p><span>{i18n.profileInfo.createdAt}: </span><span className="date_value">{createdAt}</span></p>}
                    {updatedAt && <p><span>{i18n.profileInfo.updatedAt}: </span><span className="date_value">
                      {updatedAt}
                    </span></p>}
                  {hasPullFeature && pullSentAt &&  <p><span>{i18n.profileInfo.pullSentAt}: </span><span className="date_value">
                      {pullSentAt}
                    </span></p>}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center">
              <Grid id='tagsContainer' item xs={12}>
                <p id='tagTextStyle'>{i18n.boxProfileCite.tags}:</p>
                {!isOnlyReader && <SelectTags options={tagsForSelect()} onChange={(e) => handleAddTag(e)}/>}
                {tags && tags.length > 0 &&
                  renderTags()
                }
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
            <Grid id="info-contact-container" item lg={12} direction="row" justifyContent="space-between" alignItems="center" >
              {info4 && info4 !== mail && info4 !== tel ?
                <div>
                  <p className="user-contact">
                    <UserIcon/>
                    <span>{info4.toUpperCase()}</span>
                  </p>
                </div> : ''}
              <div>
                <p className="user-contact">
                  <MailIcon/>
                  <span>{mail || '-'}</span>
                </p>
              </div>
              <div>
                <p className="user-contact">
                  <TelIcon/>
                  <span>{tel || '-'}</span>
                </p>
              </div>
            </Grid>
          </Grid>
          <Divider className='divider-profile-stats divider-vertical MuiDivider-vertical' orientation='vertical' flexItem='true' />
        </Grid>
      </ProfileStatsStyled>
    )
  }
)

ProfileStats.defaultProps = {
  avatarImage: '-',
  job: '-',
  info2: '-',
  titleInfo4: '',
  info4: ''
}

ProfileStats.propTypes = {
  avatarImage: PropTypes.string,
  mail: PropTypes.string,
  tel: PropTypes.string,
  tags: PropTypes.array,
  job: PropTypes.string,
  info2: PropTypes.string,
  titleInfo4: PropTypes.string,
  tagsTypes: PropTypes.array,
  candidatureId: PropTypes.number
}

const enharce = compose(withI18n)

export default enharce(ProfileStats)
