import styled from 'styled-components'

export const TemplateRequestManagerStyled = styled.div`
  .title-container {
    padding: 30px 60px;
  }

  .sectionSecond {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;

    label {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0px;
      margin-right: 20px;
      min-width: 200px;
    }
    input {
      font-size: 14px;
      font-family: 'Poppins';
    }

    & h2 {
      font-size: 28px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0;
    }
    & h5 {
      color: #7a808d;
      font-weight: 400;
      margin-bottom: 0;
    }

    & p {
      font-weight: 400;
      color: #7a808d;
    }
  }

  .headerTable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 20px;

    h5 {
      margin-bottom: 0px;
    }
  }

  .containerButtons {
    display: inline-flex;
    align-items: center;
    align-self: end;
    justify-content: flex-end;
    gap: 10px;
    background-color: #f0f1f2;
    padding: 8px 10px;
    border-radius: 50px;
  }
`
