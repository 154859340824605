import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import {CitationsOperations} from "../../../redux/citations";

const mapStateToProps = state => {
  return ({
    client: state.auth.client,
    auth: state.auth.data,
    user_email: state.auth.email
  })
}

const mapDispatchToProps = dispatch => {
    return {
      fetchMeetingDetails: (id) => dispatch(CitationsOperations.fetchMeetingDetails(id)),
    }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n
)

export default enharce(View)
