import React, {useCallback, useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import qs from 'qs'
import _ from 'lodash'
import * as api from '../../../api'
import * as S from './styled'
import Typography from "@material-ui/core/Typography";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import * as ApiConfig from "../../../config/api";
import {MODULES} from "../../../config/constants";
import Button from "@material-ui/core/Button";

const landingDocRequest = withRouter(({classes, location, i18n}) => {

  const search = decodeURIComponent(_.get(location, 'search'))
  const params = qs.parse(search, {ignoreQueryPrefix: true})
  const [response, setResponse] = useState({})
  const [limitDate, setLimitDate] = useState('');
  const [isEnded, setIsEnded] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isExpired, setIsExpired] = useState(false)
  const [isError, setIsError] = useState(false)
  const checkFiles = {ready: 0, done: 0}
  const maxFileSizeInBytes = 50 * 1024 * 1024;

  const data = useCallback(async () => {
    try {
      setIsFetching(true)
      let res;
      if (params.personId) {
        res = await api.getRequireDocPersonByNotification(params.notificationId, params.personId)
      } else {
        res = await api.getRequireDocByNotification(params.notificationId, params.candidatureId)
      }
      setResponse(res.data)
      if (new Date(res.data.notification.extra_data.notification_data.limit_date) < new Date()) {
        setIsExpired(true)
      } else {
        setIsFetching(false)
      }

      let datetime = res.data.notification.extra_data.notification_data.limit_date.split(' ');
      let date = datetime[0].split('-');
      let time = datetime[1].split(':');
      setLimitDate(date.reverse().join('/') + ' ' + time[0] + ':' + time[1]);

    } catch (e) {
      setIsFetching(false)
      setIsError(true)
    }
  }, [params.notificationId, params.candidatureId, params.personId])

  useEffect(() => {
    data()
    // eslint-disable-next-line
  }, [])

  const getUploadParams = () => {
    let url = `${ApiConfig.BASE_URL}/api/public/module/${MODULES.ID_TALENT_ACQUISITION}/doc-required${params.personId ? "-person" : ""}?notification=${params.notificationId}`
    url += `&${params.personId ? "person="+params.personId : "candidature="+params.candidatureId}`;
    return {method: 'POST', url: url, headers: {Accept: 'application/json, text/plain, */*'}};
  };

  const handleChangeStatus = (file, status) => {
    if (status === 'ready' || status === 'done') {
      checkFiles[status]++;
    }
    if (checkFiles.done > 0 && checkFiles.done === checkFiles.ready) {
      setIsEnded(true)
    }
  };

  const previewComponent = (f) => {
    const formatBytes = (b) => {
      const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      let l = 0
      let n = b

      while (n >= 1024) {
        n /= 1024
        l += 1
      }

      return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`
    }

    const {name = '', percent = 0, size = 0, previewUrl, status} = f.meta
    const {remove} = f.fileWithMeta
    const isUpload = f.isUpload

    let title = `${name || '?'}`

    return <div className="dzu-previewContainer">
      {previewUrl && <img className="dzu-previewImage" src={previewUrl} alt={title} title={title}/>}
      {!previewUrl && <span className="dzu-previewFileName">{title}</span>}

      <div className="dzu-previewStatusContainer">
        <span className="sizeInfo">{formatBytes(size)}</span>
        {isUpload && (
          <progress max={100} value={status === 'done' || status === 'headers_received' ? 100 : percent}/>
        )}
        {['error_upload_params', 'exception_upload', 'error_upload'].includes(status)
          && <span className="statusInfo" style={{color: "red"}}>{i18n.dropzone.error}</span>}
        {size > maxFileSizeInBytes
          && <>
            <span className="statusInfo" style={{color: "red"}}>{i18n.dropzone.error} {i18n.dropzone.file_too_large}</span>
            <Button onClick={remove}>X</Button>
          </>}
        {status === 'done' && <span className="statusInfo" style={{color: "green"}}>{i18n.dropzone.done}</span>}
      </div>
    </div>
  }

  const SubmitButton = (props) => {
    const {files, onSubmit} = props;

    const handleClick = React.useCallback(() => {
      if (typeof onSubmit !== "function") {
        return;
      }

      onSubmit(files.filter((f) => ['ready', 'error_upload_params', 'exception_upload', 'error_upload'].includes(f.meta.status)));
    }, [files, onSubmit]);

    return (
      <Button
        type="button"
        color='primary'
        variant='contained'
        tabIndex={0}
        style={{height: "auto", marginBottom: 24}}
        disabled={props.disabled}
        onClick={handleClick}
      >
        {i18n.dropzone.send}
      </Button>
    );
  };

  const handleUpload = async (files) => {
    files.forEach((file) => file.restart())
  };

  return (
    <Container component="main" className={classes.root}>
      <S.ContentBox>
        {
          isExpired === false && response.notification && !isFetching
            ? (
              <>
                <S.InfoContainer>
                  <S.Text>
                    <span style={{fontSize: 24}} dangerouslySetInnerHTML={{__html: i18n.landingDocRequest.title}}/>
                    <p>{i18n.landingDocRequest.limit_date} {limitDate}</p>
                  </S.Text>
                  <S.Text className="bold"
                          dangerouslySetInnerHTML={{__html: response.notification.extra_data.notification_data.message}}>
                  </S.Text>
                </S.InfoContainer>
                {!isEnded ?
                  <><Dropzone
                    accept={'*'}
                    autoUpload={false}
                    canRestart={false}
                    maxSizeBytes={50 * 1204 * 1024}
                    submitButtonDisabled={false}
                    inputContent={i18n.dropzone.dragOrBrowse}
                    inputWithFilesContent={i18n.dropzone.addFile}
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    PreviewComponent={previewComponent}
                    SubmitButtonComponent={SubmitButton}
                    onSubmit={handleUpload}
                  />
                  <p className="statusInfo" style={{color: "grey"}}>{i18n.dropzone.file_max_size}: {maxFileSizeInBytes/1024/1024} MB</p></>
                  : <S.InfoContainer>
                    <Typography className={classes.message}
                                dangerouslySetInnerHTML={{__html: i18n.landingDocRequest.endDescription}}/>
                  </S.InfoContainer>
                }
              </>
            ) : (
              ''
            )}
        {isError ? (
          <S.InfoContainer>
            <S.Warning className="align-center">{i18n.landingDocRequest.notExist}</S.Warning>
          </S.InfoContainer>
        ) : (
          ''
        )}
        {isExpired ? (
          <S.InfoContainer>
            <Typography className={classes.message}
                        dangerouslySetInnerHTML={{__html: i18n.landingDocRequest.expiredDescription}}/>
          </S.InfoContainer>
        ) : ''}
      </S.ContentBox>
    </Container>

  )
})

landingDocRequest.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object
}

export default landingDocRequest
