import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'
import { MTTextStyled } from './styled'

const MTText = ({ value, color, options, ...rest }) => {
  let text = value

  if (_.isString(value) && moment(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid()) {
    text = moment(value).format('DD/MM/YYYY')
  }

  return (
    <MTTextStyled {...rest} color={color} {...options}>
      {text}
    </MTTextStyled>
  )
}

MTText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

MTText.defaultProps = {
  color: 'black',
  options: {}
}

export default MTText
