import React from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import { withI18n } from '../../hocs'
import { ModalHeader, RegularButton, InputEditor } from '../../atoms'
import { ReactComponent as ModalIcon } from '../../../assets/images/notebook.svg'

const customStyles = {
  overflow: 'scroll'
}

const ModalCreateObservation = ({ i18n, onSubmit }) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    reset()
    setOpen(false)
  }

  const [text, setText] = React.useState('')
  const handleChangeText = text => {
    setText(text)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const data = {
      change_msg: text
    }

    onSubmit(data)

    handleClose()
  }

  const reset = () => {
    setText('')
  }

  const isSubmitReady = !_.isEmpty(text)

  return (
    <div>
      <Button variant="contained" color="default" onClick={handleOpen} className="planning-btn">
        {i18n.process.createComment}
      </Button>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={i18n.process.createComment} closeClick={handleClose} />
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={4}>
                  <label>{i18n.process.text}*</label>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <InputEditor name="title" placeholder={i18n.process.commentName} onChange={handleChangeText} value={text} />
                </Grid>
              </Grid>
              <div className="button-wrapper">
                <RegularButton type="submit" title={i18n.process.createComment} disabled={!isSubmitReady} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </div>
  )
}

ModalCreateObservation.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  inboxFilters: PropTypes.array,
  onSubmit: PropTypes.func.isRequired
}

const enharce = compose(withI18n)

export default enharce(ModalCreateObservation)
