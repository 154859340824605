import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

const PageTitle = ({ classes, title }) => (
  <div className={classes.root}>
    <Typography variant="h1">{title}</Typography>
  </div>
)

PageTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

const enharce = compose(withStyles(styles))

export default enharce(PageTitle)
