import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'
import colors from '../../../assets/colors'

const Icon = ({ favorite, onClick, ...rest }) => (
  <SvgIcon id="svgFavorite"  {...rest} htmlColor={favorite ? colors.orange : colors.grey6} style={{ cursor: 'pointer' }} onClick={onClick}>
    {
      favorite ?
        < path d="M21.9524 8.54167C21.8272 8.20833 21.5351 7.95833 21.1596 7.95833H14.7752L12.814 1.58333C12.6889 1.25 12.355 1 11.9795 1C11.6039 1 11.2701 1.25 11.1449 1.58333L9.18375 7.95833H2.84116C2.46561 7.95833 2.13179 8.16667 2.04834 8.54167C1.92315 8.875 2.04834 9.25 2.34043 9.5L7.51465 13.5L5.51172 19.875C5.38654 20.25 5.51172 20.625 5.84554 20.8333C6.13764 21.0417 6.55491 21.0417 6.84701 20.7917L11.9795 16.9167L17.0703 20.8333C17.2372 20.9583 17.4041 21 17.571 21C17.7379 21 17.9048 20.9583 18.0717 20.8333C18.3638 20.625 18.5307 20.25 18.4055 19.875L16.4443 13.4583L21.6186 9.5C21.9524 9.25 22.0776 8.875 21.9524 8.54167Z" fill="#E0A93F" stroke="#E0A93F" stroke-width="2" stroke-linejoin="round" />
        :
        <path d="M21.9524 9.54167C21.8272 9.20833 21.5351 8.95833 21.1596 8.95833H14.7752L12.814 2.58333C12.6889 2.25 12.355 2 11.9795 2C11.6039 2 11.2701 2.25 11.1449 2.58333L9.18375 8.95833H2.84116C2.46561 8.95833 2.13179 9.16667 2.04834 9.54167C1.92315 9.875 2.04834 10.25 2.34043 10.5L7.51465 14.5L5.51172 20.875C5.38654 21.25 5.51172 21.625 5.84554 21.8333C6.13764 22.0417 6.55491 22.0417 6.84701 21.7917L11.9795 17.9167L17.0703 21.8333C17.2372 21.9583 17.4041 22 17.571 22C17.7379 22 17.9048 21.9583 18.0717 21.8333C18.3638 21.625 18.5307 21.25 18.4055 20.875L16.4443 14.4583L21.6186 10.5C21.9524 10.25 22.0776 9.875 21.9524 9.54167Z" fill="#FFF" stroke="#7A808D" stroke-width="2" stroke-linejoin="round" />
    }
  </SvgIcon>
)

Icon.defaultPorps = {
  favorite: false
}

Icon.propTypes = {
  favorite: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  onClick: PropTypes.func
}

export default Icon
