import React from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import { withI18n } from '../../hocs'
import { ModalHeader, RegularButton, DateInput, InputField, CheckButton } from '../../atoms'
import { ReactComponent as ModalIcon } from '../../../assets/images/notebook.svg'

const customStyles = {
  overflow: 'scroll'
}

const ModalCreateReport = ({ i18n, reportFields, onSubmit }) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    reset()
    setOpen(false)
  }

  const [values, setValues] = React.useState({})
  /*const handleChangeValue = e => {
    if (e.target.checked) {
      setValues({ ...values, [e.target.name]: e.target.checked })
    } else {
      setValues(_.omit(values, e.target.name))
    }
    if (selectAll) {
      setSelectAll(false);
    }
  }*/

  const [title, setTitle] = React.useState('')
  const handleChangeTitle = title => setTitle(title)

  const [dateFrom, setDateFrom] = React.useState(null)
  const handleChangeDateFrom = date => setDateFrom(date)

  const [dateTo, setDateTo] = React.useState(null)
  const handleChangeDateTo = date => setDateTo(date)

  const handleSubmit = e => {
    e.preventDefault()

    const data = {
      title: title,
      date_from: dateFrom ? moment(dateFrom).format('YYYY-MM-DD HH:mm:ss') : null,
      date_to: dateTo ? moment(dateTo).format('YYYY-MM-DD HH:mm:ss') : null,
      fields: JSON.stringify(_.keys(values))
    }

    onSubmit(_.pickBy(data, _.identity))

    handleClose()
  }

  const [selectAll, setSelectAll] = React.useState(false);
  //const [checkedCount, setCheckedCount] = React.useState(0);

  /*React.useEffect(() => {
    if (reportFields) {
      setCheckedCount(reportFields.length);
    }
  }, [reportFields]);*/

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      //setCheckedCount(reportFields.length);

      let newValues = {};
      reportFields.forEach(field => {
        newValues[field.remote_name] = true;
      });
      setValues(newValues);
    } else {
      //setCheckedCount(0);
      setValues({});
    }
  };

  const handleChangeCheck = (event) => {

    setValues({ ...values, [event.target.name]: event.target.checked });

    /*if(event.target.checked){
      setCheckedCount(prevCount => {
        const newCount = prevCount + 1;
        if (newCount === reportFields.length) {
          setSelectAll(true);
        }
        return newCount;
      });
    } else {
      setCheckedCount(prevCount => {
        if (prevCount - 1 === 0){
          setSelectAll(false);
        }
        return prevCount - 1;
      });
    }*/
  };

  const reset = () => {
    setValues({})
    setTitle('')
    setDateFrom(null)
    setDateTo(null)
  }

  const isSubmitReady = !_.isEmpty(title) && !_.isNull(dateFrom) && !_.isNull(dateTo)
  return (
    <div>
      <Button variant="contained" color="default" onClick={handleOpen} className="planning-btn">
        {i18n.modalCreateReport.createReport}
      </Button>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={i18n.modalCreateReport.createReport} closeClick={handleClose} />
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={4}>
                  <label>{i18n.modalCreateReport.name}*</label>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputField
                      name="title"
                      placeholder={i18n.modalCreateReport.reportName}
                      handleOnChange={handleChangeTitle}
                      value={title}
                    />
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={4}>
                  <label>{i18n.modalCreateReport.date}*</label>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <DateInput
                      placeholder={i18n.modalCreateReport.fromDate}
                      name="date_from"
                      value={dateFrom}
                      onChange={handleChangeDateFrom}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <DateInput
                      placeholder={i18n.modalCreateReport.toDate}
                      name="date_to"
                      value={dateTo}
                      onChange={handleChangeDateTo}
                    />
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={4}>
                  <label>{i18n.modalCreateReport.selectAll}</label>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <CheckButton
                      checked={selectAll}
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                      color="primary"
                  />
                </Grid>
              </Grid>
              {_.map(reportFields, ({ name, remote_name }) => {
                return (
                  <Grid container alignItems="center" spacing={3} key={`mcr_${remote_name}`}>
                    <Grid item xs={12} sm={4}>
                      <label>{name}</label>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <CheckButton
                        name={remote_name}
                        title={i18n.modalCreateReport.include}
                        checked={_.has(values, remote_name) ? _.get(values, remote_name) : (selectAll)}
                        onChange={handleChangeCheck}
                      />
                    </Grid>
                  </Grid>
                );
              })}

              <div className="button-wrapper">
                <RegularButton type="submit" title={i18n.modalCreateReport.createReport} disabled={!isSubmitReady} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </div>
  )
}

ModalCreateReport.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  reportFields: PropTypes.array,
  onSubmit: PropTypes.func.isRequired
}

const enharce = compose(withI18n)

export default enharce(ModalCreateReport)
