import * as types from './types'

const initialState = {
  isFetching: false,
  token: null,
  email: null,
  id: null,
  languaje: 'es',
  sesionLoaded: false,
  data: null,
  url: null,
  client: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_AUTH_TOKEN:
      return { ...state, token: action.payload }
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_EMAIL:
      return { ...state, email: action.payload }
    case types.SET_ID:
      return { ...state, id: action.payload }
    case types.SET_SESION_LOADED:
      return { ...state, sesionLoaded: action.payload }
    case types.SET_LANGUAJE:
      return { ...state, languaje: action.payload }
    case types.SET_DATA:
      return { ...state, data: action.payload }
    case types.SET_URL:
      return { ...state, url: action.payload }
    case types.SET_CLIENT:
      return { ...state, client: action.payload }
    case types.LOG_OUT:
      return { ...initialState, sesionLoaded: true }
    default:
      return state
  }
}
