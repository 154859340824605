import _ from 'lodash'
import jwtDecode from 'jwt-decode'
import { TOKEN_KEY } from '../../config/constants'

export const isLogedUser = () => localStorage.getItem(TOKEN_KEY);

export const isUnauthorizedError = error => _.get(error, 'code') === 401

export const isValidEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const hasRole = (role) => {
  const token = !areWeTestingWithJest() ? localStorage.getItem(TOKEN_KEY) : "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2NDc0NDY4NzAsImV4cCI6MTY0NzUzMzI3MCwicm9sZXMiOlsiUk9MRV9VU0VSIiwiUk9MRV9URUNITklDQUxfU0VMRUNUSU9OIiwiUk9MRV9SRUFEX09OTFlfdGFsZW50X2FjcXVpc2l0aW9uIiwiUk9MRV9BRE1JTiIsIlJPTEVfUlJISF9VU0VSIiwiUk9MRV9PTl9CT0FSRElORyIsIlJPTEVfRkVFREJBQ0tfVVNFUiJdLCJ1c2VybmFtZSI6InNlbGVjY2lvbjFAY2xvdWRkaXN0cmljdC5jb20ifQ.rfVjl0_gqSBkZN4AYdIbnAmAnb2pSWaj8dzqRM7YvNR9xoSoIv8TI0ywUN2N5-Z-GZRM7IbOhT_funIcTnb4mB9tPYqsqHedxxETnxxkX0hyqBeSuT8EPG0GKDt_XV2DrI621LRRbwKjk-memvCRgDWqCGl0ABVZ_rEitJB7wDhAnU1a0TyQtKPkaYPWmDugOgjztbBaO1D0YIx7TZuHOGBSyOoGzgEdxiq818pkQ4i6ci9HZrF2oGgSBndtANCqPavoTtotiE0jirFLZGg_BCy11xBgSHR1r2XN9a-cEPm6ybIYr1qC3whPERnES_PB8-qN-PcSFsw-tj5KgYwSEPd_527pKPd3osFB4RSKU3v6AzvUploeXSP_UYCdwJG8rq4av-FhXa76gGpoCmPOf93THbT8ooZN2KvRn7Y1iAxrjBhCvTFCB63XpLt-M2QtIOb8uD71-UTVplWYHrGC48Ucz225xBQUvA7f8WF7nyUp4Pu1NqX5xq0dGF18EAvvJ8vDttLVggusiqRcWpYd3714xqbHRmhys7EJ64BgHvlGwE9fjVeReSqrU4FjDPLnrqsxo2Qi_0vh1Ni0ySkLdLG3IhozMzu6agNkUWUCzUAv7esmw2lBHl6eHsEKgeekTg5EmGQf3QvJlsVLWLJ_f7kPkoGXINJdDvnafTQCWHg";
  const permissions = _.get(jwtDecode(token), 'roles', [])
  return permissions.includes(role)
}

export const getUserName = () => {
  const token = !areWeTestingWithJest() ? localStorage.getItem(TOKEN_KEY) : "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2NDc0NDY4NzAsImV4cCI6MTY0NzUzMzI3MCwicm9sZXMiOlsiUk9MRV9VU0VSIiwiUk9MRV9URUNITklDQUxfU0VMRUNUSU9OIiwiUk9MRV9SRUFEX09OTFlfdGFsZW50X2FjcXVpc2l0aW9uIiwiUk9MRV9BRE1JTiIsIlJPTEVfUlJISF9VU0VSIiwiUk9MRV9PTl9CT0FSRElORyIsIlJPTEVfRkVFREJBQ0tfVVNFUiJdLCJ1c2VybmFtZSI6InNlbGVjY2lvbjFAY2xvdWRkaXN0cmljdC5jb20ifQ.rfVjl0_gqSBkZN4AYdIbnAmAnb2pSWaj8dzqRM7YvNR9xoSoIv8TI0ywUN2N5-Z-GZRM7IbOhT_funIcTnb4mB9tPYqsqHedxxETnxxkX0hyqBeSuT8EPG0GKDt_XV2DrI621LRRbwKjk-memvCRgDWqCGl0ABVZ_rEitJB7wDhAnU1a0TyQtKPkaYPWmDugOgjztbBaO1D0YIx7TZuHOGBSyOoGzgEdxiq818pkQ4i6ci9HZrF2oGgSBndtANCqPavoTtotiE0jirFLZGg_BCy11xBgSHR1r2XN9a-cEPm6ybIYr1qC3whPERnES_PB8-qN-PcSFsw-tj5KgYwSEPd_527pKPd3osFB4RSKU3v6AzvUploeXSP_UYCdwJG8rq4av-FhXa76gGpoCmPOf93THbT8ooZN2KvRn7Y1iAxrjBhCvTFCB63XpLt-M2QtIOb8uD71-UTVplWYHrGC48Ucz225xBQUvA7f8WF7nyUp4Pu1NqX5xq0dGF18EAvvJ8vDttLVggusiqRcWpYd3714xqbHRmhys7EJ64BgHvlGwE9fjVeReSqrU4FjDPLnrqsxo2Qi_0vh1Ni0ySkLdLG3IhozMzu6agNkUWUCzUAv7esmw2lBHl6eHsEKgeekTg5EmGQf3QvJlsVLWLJ_f7kPkoGXINJdDvnafTQCWHg";
  try {
    return _.get(jwtDecode(token), 'username', 'Unknown');
  }catch (e) {
    return null;
  }
}

export const isDebugUser = () => {
  const email = getUserName();

  return email.indexOf('@hrbotfactory.com') > -1;
}

function areWeTestingWithJest() {
  return process.env.JEST_WORKER_ID !== undefined;
}
