import React from 'react'

const icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 23 24" fill="none">
    <path opacity={0.6} d="M14.7896 0.00871124C15.3865 0.0171127 15.7086 0.739392 15.461 1.27774C15.3307 1.56112 15.0521 1.76592 14.7379 1.76169C14.1407 1.75366 13.4619 1.75351 12.6795 1.75351H10.3205C8.65448 1.75351 7.45816 1.75419 6.51912 1.83023C5.59042 1.90543 4.99155 2.04995 4.50628 2.29502C3.56306 2.77134 2.79619 3.53139 2.3156 4.46623C2.06834 4.94719 1.92252 5.54073 1.84664 6.46118C1.76992 7.39187 1.76923 8.57756 1.76923 10.2288V15.1717C1.76923 17.1226 3.36489 18.7041 5.33324 18.7041H6.043C7.58777 18.7041 8.64407 20.2504 8.07036 21.6719C7.91668 22.0527 8.35913 22.3969 8.69583 22.1586L11.7752 19.9786L11.8423 19.9312C12.9736 19.1411 14.3216 18.713 15.7055 18.7042L15.8056 18.7041C16.185 18.7041 16.3421 18.7038 16.455 18.6985C19.0372 18.5774 21.1029 16.53 21.2252 13.9707C21.2305 13.8586 21.2308 13.7207 21.2308 13.3837V10.2288C21.2308 9.45334 21.2306 8.78056 21.2225 8.18865C21.2182 7.87726 21.4249 7.60111 21.7108 7.47196C22.254 7.22662 22.9827 7.54584 22.9912 8.1374C23 8.75105 23 9.43162 23 10.1895V13.4122C23 13.712 23 13.8946 22.9924 14.0537C22.827 17.5161 20.0322 20.2861 16.5387 20.4501C16.3781 20.4576 16.1753 20.4576 15.8391 20.4576H15.8056L15.7168 20.4577C14.6939 20.4642 13.6976 20.7806 12.8614 21.3646L12.8035 21.4055L9.72417 23.5854C7.94963 24.8417 5.61776 23.0275 6.42767 21.0207C6.53652 20.751 6.3361 20.4576 6.043 20.4576H5.33324C2.38777 20.4576 6.45445e-08 18.091 6.45445e-08 15.1717V10.1895C-4.36895e-06 8.58597 -7.8915e-06 7.3288 0.083286 6.31839C0.168104 5.2895 0.343677 4.43951 0.7392 3.67015C1.38942 2.40537 2.42694 1.37707 3.70306 0.73263C4.47932 0.340623 5.33694 0.166609 6.37505 0.0825458C7.39454 -9.44138e-06 8.663 -5.1947e-06 10.2809 8.42271e-08H12.7191C13.4838 -2.36538e-06 14.1704 -4.46539e-06 14.7896 0.00871124Z" fill="white"/>
    <path opacity={0.6} fillRule="evenodd" clipRule="evenodd" d="M16.5128 3.21476C16.5128 1.4393 17.965 8.42271e-08 19.7564 8.42271e-08C21.5478 8.42271e-08 23 1.4393 23 3.21476C23 4.99023 21.5478 6.42952 19.7564 6.42952C17.965 6.42952 16.5128 4.99023 16.5128 3.21476ZM19.7564 1.75351C18.9421 1.75351 18.2821 2.40773 18.2821 3.21476C18.2821 4.02179 18.9421 4.67602 19.7564 4.67602C20.5707 4.67602 21.2308 4.02179 21.2308 3.21476C21.2308 2.40773 20.5707 1.75351 19.7564 1.75351Z" fill="white"/>
    <path opacity={0.6} d="M6.78205 11.3978C7.43346 11.3978 7.96154 10.8744 7.96154 10.2288C7.96154 9.58316 7.43346 9.05978 6.78205 9.05978C6.13064 9.05978 5.60256 9.58316 5.60256 10.2288C5.60256 10.8744 6.13064 11.3978 6.78205 11.3978Z" fill="white"/>
    <path opacity={0.6} d="M11.5 11.3978C12.1514 11.3978 12.6795 10.8744 12.6795 10.2288C12.6795 9.58316 12.1514 9.05978 11.5 9.05978C10.8486 9.05978 10.3205 9.58316 10.3205 10.2288C10.3205 10.8744 10.8486 11.3978 11.5 11.3978Z" fill="white" />
    <path opacity={0.6} d="M17.3974 10.2288C17.3974 10.8744 16.8694 11.3978 16.2179 11.3978C15.5665 11.3978 15.0385 10.8744 15.0385 10.2288C15.0385 9.58316 15.5665 9.05978 16.2179 9.05978C16.8694 9.05978 17.3974 9.58316 17.3974 10.2288Z" fill="white"/>
  </svg>
)

export default icon
