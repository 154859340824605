import React, {useState} from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import i18n from '../../../assets/i18n'
import {Card, CardHeader, CardContent, Tooltip, Badge} from "@material-ui/core";
import {DraftsRounded, MailRounded} from "@material-ui/icons";
import {generatePath} from "react-router";
import routes from "../../../config/routes";
import {Link} from "react-router-dom";
import {OfferStateImage} from "../index";
import OfferCandidatureStatusType from "../../../models/offer-candidature-status-type";
import OfferCardStyled from "./styled";
import {ButtonWithIcon, DeleteIcon, ModalHeader, RegularButton} from "../../atoms";
import Modal from "@material-ui/core/Modal";
import {ModalStyled} from "../modal-delete-user/styled";
import {ReactComponent as ModalIcon} from "../../../assets/images/user-white.svg";
import Grid from "@material-ui/core/Grid";
import {deletePushOffer} from "../../../api";
import {toast} from "react-toastify";

function OfferCard({item, filters}) {
  const [showOfferDeleteModal, setShowOfferDeleteModal] = useState(false);

  const date_from = item.date_from.split('T')[0].split("-").reverse().join("/");
  const date_to = item.date_to.split('T')[0].split("-").reverse().join("/");

  const formattedDateTo = date_to.split("/");
  const isExpired = new Date(formattedDateTo[2] + '/' + formattedDateTo[1] + '/' + formattedDateTo[0]) <= new Date();
  const interested_count_color = item.interested > 0 ? '#34ab11' : '#da0000';
  const wasRead = item.was_read;

  const filterTags = Object.keys(filters).map((filter, index) => {
    let filterData = filters[filter][0];
    return <button key={index} id={index} className="btn card_container__card-content-button">
      {'filter' in filterData && filterData.filter !== undefined && 'name' in filterData.filter && filterData.filter.name}: {filterData.label}
    </button>

  }).reverse();

  const handleCloseShowOfferDeleteModal = () => setShowOfferDeleteModal(false);
  const handleOpenShowOfferDeleteModal = (e) => {
    e.preventDefault();
    setShowOfferDeleteModal(true);
  }

  const handleOnClickDelete = () => {
    handleCloseShowOfferDeleteModal();
    deletePushOffer(item.id).then(() => {
      toast.success(i18n.offerCard.delete.success);
      window.location.reload();
    }).catch(() => {
      toast.error(i18n.offerCard.delete.error);
    });
  }

  function handleDeleteButton() {
    handleOnClickDelete();
  }

  return (
    <div>
    <Link to={{
      pathname: generatePath(routes.offerCandidatures, {slug: item.id}),
    }}>
      <OfferCardStyled>
        <div className="col-lg-3 col-md-6 col-xs-12 card_container">
          <Card className={"card_container__card " + (isExpired ? 'expired_push_offer' : '')}>
            <CardHeader
              className="card_container__card-header"
              title={
                <>
                  {wasRead
                    ? <DraftsRounded color={"action"} fontSize="large"/>
                    : <MailRounded color={"primary"} fontSize="large"/>
                  }
                  <span>{item.name}</span>
                  <ButtonWithIcon remove='true'
                                  onClick={handleOpenShowOfferDeleteModal}
                                  isDeleteOfferBtn={true}
                                  Icon={<DeleteIcon/>}/>
                </>
              }
              subheader={
                <>
                            <span className="card_container__card-header-date_range">
                                {date_from + ' - ' + date_to}
                            </span>
                  <span className="card_container__card-header-interested">
                                <span
                                  style={{color: interested_count_color}}>{item.interested} {i18n.offerCard.interested_candidatures}</span>
                                <span className="state-icons">
                                    <Badge badgeContent={String(item.interested)} color="primary"><OfferStateImage
                                      state={OfferCandidatureStatusType.INTERESTED}></OfferStateImage></Badge>
                                    <Badge badgeContent={String(item.not_interested)} color="primary"><OfferStateImage
                                      state={OfferCandidatureStatusType.NOT_INTERESTED}></OfferStateImage></Badge>
                                    <Badge badgeContent={String(item.unanswered)} color="primary"><OfferStateImage
                                      state={OfferCandidatureStatusType.UNANSWERED}></OfferStateImage></Badge>
                                </span>
                            </span>
                </>
              }
            />
            <CardContent className="card_container__card-content">
              <div className="card_container__card-content-description">
                <Tooltip title={item.description_without_html}>
                  <p className="card_container__card-content-description-text"
                     dangerouslySetInnerHTML={{__html: item.description}}>
                  </p>
                </Tooltip>
              </div>
              <div className="card_container__card-content-filters">{filterTags}</div>
            </CardContent>
          </Card>
        </div>
      </OfferCardStyled>
    </Link>
    <Modal className="modal-wrapper" aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description"
           open={showOfferDeleteModal} onClose={handleCloseShowOfferDeleteModal}>
      <ModalStyled>
        <ModalHeader Icon={<ModalIcon />} title={`${i18n.modalDeleteUser.removeUser} "${item.name}"`} closeClick={handleCloseShowOfferDeleteModal} />
        <div className="modal-body">
          <form>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <label>{i18n.process.deleteModalText}</label>
              </Grid>
            </Grid>

            <div style={{display: "flex", gap: 24, marginTop: 16}}>
              <RegularButton handleOnClick={handleDeleteButton} type="reset" title={i18n.modalDeleteUser.remove} />
              <RegularButton style={{backgroundColor: "#F42F4A"}} handleOnClick={handleCloseShowOfferDeleteModal} type="reset" title={i18n.dialog.decline} />
            </div>
          </form>
        </div>
      </ModalStyled>
    </Modal>
  </div>
  )
}

OfferCard.propTypes = {
  item: PropTypes.object.isRequired,
}

const enharce = compose()

export default enharce(OfferCard)
