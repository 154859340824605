import React, { useState, useMemo } from 'react'
import { useRef } from 'react'
import useComponentSize from '@rehooks/component-size'
import PropTypes from 'prop-types'
import _, { isArray } from 'lodash'
import { withTheme } from '@material-ui/core/styles'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer } from 'recharts'
import BarChartStatisticStyled from './styled'
import {ReactComponent as UserIcon} from '../../../assets/images/stats.svg'
import Grid from "@material-ui/core/Grid";
import i18n from '../../../assets/i18n'
import { assignColorByValueAndData, useColorPalette } from '../use-color-palette'

const translate = (key, value) => {
  if(!i18n.dashboard.barchart[key]) {
    return value;
  }
  return `${value}${i18n.dashboard.barchart[key]}`;
}

const BarChartStatistic = ({
    title,
    data,
    theme,
    xDataKey = 'day',
    yDataKey = 'candidatures',
    unit = '%',
    totalToCalcPercent,
    isOnboarding = false,
    height = 300,
    overrideTitle = null,
    hideXAxis = false
  }) => {
  const ref = useRef(null)
  const size = useComponentSize(ref)
  const { width } = size
  const palette = useColorPalette(isArray(data) ? data.length : 8);

  const chart = useMemo(() => {
    const tooltipFormatter = (value, name, entry) => {
      const dataX = _.get(entry, `payload.${xDataKey}`)
      const labelX = xDataKey === 'day' ? `Día ${dataX}` : translate(xDataKey, dataX)
      let valueX
      if (totalToCalcPercent)
        valueX = value + ' (' + Math.round(value * 100 / totalToCalcPercent, 0) + unit + ')'
      else
        valueX = xDataKey === 'day' || xDataKey === 'phase' ? value : `${value}${unit}`

      valueX = translate(yDataKey, valueX);

      return [valueX, labelX]
    }


    if(data && data.length > 0) {
      data.map((item) => {
        item[yDataKey] = !isNaN(item[yDataKey]) && item[yDataKey] > 1 ? Math.round(item[yDataKey]) : item[yDataKey];

        return item;
      });
      data = assignColorByValueAndData(data, yDataKey, palette);
    }

    return (
      <ResponsiveContainer width="100%" height="100%">
        {data && data.length > 0 ?
          <BarChart width={width} data={data} margin={{top: 20, right: 40}} maxBarSize={50}>
            <CartesianGrid strokeDasharray="5 5"/>
            <XAxis dataKey={xDataKey} hide={hideXAxis}/>
            <YAxis/>
            <Tooltip labelFormatter={() => ''} formatter={tooltipFormatter}/>
            <Bar dataKey={yDataKey} label={{ position: 'top' }}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.fill} />
              ))}
            </Bar>
          </BarChart>
          :
          <div className="no-data-available">{i18n.dashboard.no_data_available}</div>
        }
      </ResponsiveContainer>
    )
  }, [width, data, xDataKey, yDataKey, unit, totalToCalcPercent])

  return (
    <BarChartStatisticStyled isOnboarding={isOnboarding}>
      {
        !isOnboarding ?
          (overrideTitle ? overrideTitle :
          <div className="header-stat">
            <UserIcon/>
            <p>{title}</p>
          </div>)
          : <Grid item xs={12} className="label-stat">
            {title}
          </Grid>
      }
      <div className="chart-wrapper" ref={ref} style={{width: '100%', height: height}}>
        {chart}
      </div>
    </BarChartStatisticStyled>
  )
}

BarChartStatistic.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  xDataKey: PropTypes.string.isRequired,
  yDataKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  unit: PropTypes.string
}

export default withTheme(BarChartStatistic)
