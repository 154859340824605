import React from 'react'
import compose from 'recompose/compose'
import ModuleStyled from './styled'
import PropTypes from 'prop-types'

const Module = ({ title, imageUrl, isActive }) => {
  var style = {
    backgroundImage: 'url(' + imageUrl + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }

  return (
    <ModuleStyled isActive={isActive}>
      <div className="wrap-image" style={style}>
        <p>{title}</p>
      </div>
    </ModuleStyled>
  )
}

Module.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  isActive: PropTypes.bool
}

const enharce = compose()

export default enharce(Module)
