import styled from 'styled-components'

const ModalHeaderStyled = styled.div`
  .header-modal {
    background-color: ${props => props.theme.primary};
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    position: relative;

    path {
      fill: ${props => props.theme.textPrimary};
    }

    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: ${props => props.theme.textPrimary};
      margin: 0;
      margin-left: 15px;
    }
  }

  .close-icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`

export default ModalHeaderStyled
