import styled from 'styled-components'
import colors from '../../../assets/colors'

const BackButtonStyled = styled.div`
  p,
  a {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.color ? props.color : colors.grey};
    margin-bottom: 0;

    svg {
      margin-right: 10px;
      position: relative;
      top: 2px;
      transform: translateY(4px);
    }
      
    }
  }
`

export default BackButtonStyled
