import React from 'react'
import compose from 'recompose/compose'
import {withI18n} from '../../hocs'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import ValuesPercentagesTable from "./styled";
import {withStyles} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from '@material-ui/core/Tooltip';
import routes from "../../../config/routes";
import {useAppContext} from "../../../context/appContext";
import {getCurrentSectionKey} from "../../../config/constants";

const TableStatistic = ({data, tableTitle, translation, isBigSizeStats, hasExtraValue, history = null, isOnboarding = false}) => {
  const {filters} = useAppContext();

  const colors = {
    positive: '#37bc6a',
    negative: '#F4304A',
  }

  const CardLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.primary,
    },
  }))(LinearProgress);

  const redirectToInboxWithFilters = (label, options, isin= "$in") => {
    if (isOnboarding) {
      let currentFilters = filters[getCurrentSectionKey()];
      let storageFilter;
      if(Array.isArray(options)){
        storageFilter = {[label]: {[isin]: options}};
      }else{
        storageFilter = {[label]: {"$regex": options, "$options":"i"}}
      }
      if (currentFilters.hasOwnProperty('startDate')) {
        if (!storageFilter['update_at']) storageFilter['update_at'] = {};
        let date = new Date(currentFilters.startDate);
        let dateString = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        let month = date.getMonth() + 1 < 10 ? `0${date.getMonth()+1}` : date.getMonth() + 1;
        storageFilter['update_at']['from'] = `${dateString}/${month}/${date.getFullYear()}`
      }
      if (currentFilters.hasOwnProperty('endDate')) {
        if (!storageFilter['update_at']) storageFilter['update_at'] = {};
        let date = new Date(currentFilters.endDate);
        let dateString = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        let month = date.getMonth() + 1 < 10 ? `0${date.getMonth()+1}` : date.getMonth() + 1;
        storageFilter['update_at']['to'] = `${dateString}/${month}/${date.getFullYear()}`
      }
      if (currentFilters.hasOwnProperty('responsable')) {
        storageFilter['responsable'] = currentFilters['responsable'];
      }
      if (currentFilters.hasOwnProperty('tecnico')) {
        storageFilter['tecnico'] = currentFilters['tecnico'];
      }
      if (currentFilters.hasOwnProperty('tienda_contrato')) {
        storageFilter['tienda_contrato'] = currentFilters['tienda_contrato'];
      }
      if (currentFilters.hasOwnProperty('especialidad')) {
        storageFilter['especialidad'] = currentFilters['especialidad'];
      }
      localStorage.setItem('inboxFilter', JSON.stringify(storageFilter));
      history.push(routes.onboarding);
    }
  }

  return (
        <ValuesPercentagesTable colors={colors}>
          <h4 className="table_header">{tableTitle}</h4>
          <Table className="values_percentages_table">
            <TableBody className="table_body">
              {data.map((item, index) => {
                let lastRowClass = data.length - 1 === index ? 'last_row_cell' : '';
                return <TableRow key={'tr_' + index} className="row">
                  <TableCell className={"row_element factor_name " + lastRowClass}>
                    <p className="factor_number">{index + 1}</p>{translation && item.label in translation ? translation[item.label] : item.label}
                  </TableCell>
                  <TableCell className={"row_element factor_values " + lastRowClass}>
                    {
                      isBigSizeStats
                        ? <div className="big_container">
                          <span className="big_value">{item.stats.value}</span>
                          <div className="big_percentage_container">
                            <CardLinearProgress variant="determinate" value={item.stats.ration}/>
                          </div>
                          <span className="big_percentage">{item.stats.ration + '%'}</span>
                        </div>
                        : <div className="factor_values__container">
                          <Tooltip title="Número de alertas por item" placement="left" arrow>
                            <div>
                              <span className="factor_value"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if(item.alarm_values) {
                                        redirectToInboxWithFilters(item.label, item.alarm_values);
                                      }else{
                                        redirectToInboxWithFilters(item.label, "no|pendiente|falta|mejorable|mala");
                                      }
                                    }}
                                    style={{color: hasExtraValue ? colors.negative : ''}}>{item.stats.value}</span>
                            </div>
                          </Tooltip>
                         <Tooltip title="Porcentaje frente al total de personas que han realizado la fase" placement="top" arrow>
                           <div style={{width: "50%", display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                            <span className="factor_percentage">{item.stats.ration + '%'}</span>
                            <div className="percentage_container" style={{width: "50%"}}>
                              <CardLinearProgress variant="determinate" value={item.stats.ration}/>
                            </div>
                           </div>
                         </Tooltip>
                         <Tooltip title="El número de personas que se han subsanado" placement="right" arrow>
                            <div style={{width: 0}}>
                              {hasExtraValue &&
                                <span className="factor_fixed"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if(item.fixed_label) {
                                      redirectToInboxWithFilters(item.fixed_label, item.fixed_values, "$nin");
                                    }else{
                                      redirectToInboxWithFilters(item.label, "ahora");
                                    }
                                  }}
                                  style={{color: item.stats.fixed > 0 ? colors.positive : colors.negative}}>{item.stats.fixed}</span>}
                            </div>
                         </Tooltip>
                        </div>
                    }
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </ValuesPercentagesTable>
  )
}

TableStatistic.propTypes = {
  title: PropTypes.string,
  i18n: PropTypes.object.isRequired,
  data: PropTypes.array,
  isFetching: PropTypes.bool
}

TableStatistic.defaultProps = {
  title: i18n.processesStatisticsItems.tableTitle,
  data: [],
  isFetching: false
}

const enharce = compose(withI18n)

export default enharce(TableStatistic)
