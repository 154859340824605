import React from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import ProfileSkills from '../profile-skills'
import { MTCircularPercent } from '../../atoms'
// import { ReactComponent as SkillIcon } from '../../../assets/images/skills.svg'
import BoxProfileCiteStyled from './styled'
//import {ReactComponent as MailIcon} from '../../../assets/images/mail.svg'
//import {ReactComponent as TelIcon} from '../../../assets/images/tel.svg'
//import Tag from "../../atoms/tag";
import Chip from "@material-ui/core/Chip";
import routes from '../../../config/routes'
import {Link} from "react-router-dom";
import { generatePath } from 'react-router';
import {OfferStateImage} from "../index";
import Divider from "@material-ui/core/Divider";


const BoxProfileCite = ({section, i18n, skillValue, skills, offerHasCandidatures }) => {
  return (
    <BoxProfileCiteStyled>
      <div className="box-wrapper">
        {/*<p className="main-info">
          <MailIcon />
          {mail || '-'}
        </p>
        <p className="main-info">
          <TelIcon />
          {tel || '-'}
        </p>*/}
        <div className="wrap-table">
          {section !== 'onboarding' ? <Divider className='divider-box-citeheader divider-vertical MuiDivider-vertical' orientation='vertical' flexItem='true' /> : null }
          {section !== 'onboarding' ? (<Grid container>
            <Grid className="skillContainer" justifyContent="space-between" item lg={2}>
              {_.isNumber(skillValue) &&
                <MTCircularPercent theme={true} value={skillValue} fontSize="18px" title={i18n.profileStats.skills} thickness={4}/>
              }
            </Grid>
            <Grid justifyContent="space-between" item lg={10} >
              <ProfileSkills skills={skills} />
            </Grid>
          </Grid>) : null }
          {/* {date &&
            <Grid container justifyContent="space-between" className={'row-table'}>
              <p className={'title'}>{i18n.boxProfileCite.citationDate}</p>
              <p>{date || '-'}</p>
            </Grid>
          }
          {responsible &&
            <Grid container justifyContent="space-between" className={'row-table'}>
              <p className={'title'}>{i18n.boxProfileCite.citationManager}</p>
              <p>{responsible || '-'}</p>
            </Grid>
          }
          {hiringDate &&
            <Grid container justifyContent="space-between" className={'row-table'}>
              <p className={'title'}>{i18n.boxProfileCite.hiringDate}</p>
              <p>{hiringDate || '-'}</p>
            </Grid>
          }
          {origin &&
            <Grid container justifyContent="space-between" className={'row-table'}>
              <p className={'title'}>{i18n.boxProfileCite.recruitmentSource}</p>
              <p>{origin || '-'}</p>
            </Grid>
          }
          {tags && tags.length > 0 &&
            <Grid container justifyContent="space-between" className={'row-table'}>
              <p className={'title'}>{i18n.boxProfileCite.tags}</p>
              <div>
                {_.map(tags, (tag) => {
                  return (
                    <Tag tag={tag} fontSize={'1.4em'}/>
                  )
                })
                }
              </div>
            </Grid>
          }
          */}
          {offerHasCandidatures && offerHasCandidatures.length > 0 &&
            <Grid container justifyContent="space-between" className={'row-table'}>
              <p className={'title'}>{i18n.boxProfileCite.offers}</p>
              <p className="offers">
                {_.map(offerHasCandidatures, (offerHasCandidature) => {
                  return <Link to={{
                    pathname: generatePath(routes.offerCandidatures, {slug: offerHasCandidature.offer.id}),
                  }}>
                      <Chip icon={ <OfferStateImage state={_.get(offerHasCandidature, 'state') } /> } label={offerHasCandidature.offer.name}/>
                    </Link>
                })}
              </p>
            </Grid>
          }
        </div>
      </div>
    </BoxProfileCiteStyled >
  )
}

BoxProfileCite.propTypes = {
  // mail: PropTypes.string,
  // tel: PropTypes.string,
  // tags: PropTypes.array,
  // actualStatus: PropTypes.string,
  skillValue: PropTypes.number,
  skills: PropTypes.array,
  date: PropTypes.string,
  hiringDate: PropTypes.string,
  responsible: PropTypes.string,
  origin: PropTypes.string,
  i18n: PropTypes.object.isRequired
}

const enharce = compose(withI18n)

export default enharce(BoxProfileCite)
