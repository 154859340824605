import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import { MTText, CheckButton } from '../../atoms'
import React from "react";


export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: stretch;
  column-gap: 30px;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({underlined, theme})=>underlined?`solid 1px ${theme.colors.greyAlpha25}`:'none'};
`
export const Item = styled.div`
  position: relative;
  display: inline-block;
  margin: 10px;
  width: 200px;
`
export const Label = styled(props => <MTText {...props} />)`
  padding: 0px 10px;
  margin-right: 0px;
  font-size: 17px;
`
export const Text = styled(props => <MTText {...props} />)`
  font-weight: normal;
  font-size: 17px;
`
export const Icon = styled.div`
  margin-right: 10px;
`
export const Check = styled(props => <CheckButton {...props}/>)`
  position: absolute;
  top: 0px;
  left: 10px;
  z-index: 2; 
`

export const Link = styled.a`
  color: ${({theme})=>theme.primary};
  z-index: 1;
`

export const ModalContainer = styled(props => <Modal {...props}/>)`
  margin: 10px;
`