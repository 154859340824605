import styled from 'styled-components'
import colors from '../../../assets/colors'

const BoxProfileCiteStyled = styled.div`

  .title-name p{
    margin: 0;
    align-self: end;
    line-height: 18px;
    font-size: 14px;
    font-weight: 700;
  }
  
  .wrap-table{
    position: relative;
  }
  .divider-box-citeheader{
    height: 110px !important;
    position: absolute;
    top: 0;
    left: 16px;
  }
  
  .skillContainer {
    padding-left: 28px;
  }

  @media print {
    p {
      font-size: 13px !important;
      line-height: 22px !important;
    }
    .skillContainer {
      padding-left: 12px;
    }
  }
  && {

    .box-wrapper {
      border-radius: 8px;
    }

    p {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.03em;

      &.main-info svg {
        position: relative;
        top: 10px;
        margin-right: 10px;
      }
      
      &.offers {
        padding: 20px 0 0;
        
        .MuiChip-root {
            margin: 5px 5px;
            font-size: 13px;
            text-align: right;
            padding: 0 8px 0;
            cursor: pointer;
        }
      }
    }

    .row-table {
      border-top: 1px solid ${colors.greyAlpha25};
      padding: 10px 0;

      p {
        margin-bottom: 0;
      }

      .title {
        font-weight: 600;
      }
    }
  }
`

export default BoxProfileCiteStyled
