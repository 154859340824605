import React from 'react'
import compose from 'recompose/compose'
import moment from 'moment'
import _ from 'lodash'
import {withI18n} from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import {ModalStyled} from './styled'
import Modal from '@material-ui/core/Modal'
import {CheckButton, ModalHeader, RegularButton} from '../../atoms'
import {ReactComponent as ModalIcon} from '../../../assets/images/user-white.svg'
import Tag from "../../atoms/tag";

const customStyles = {
  overflow: 'scroll'
}

const ModalChangeTags = ({ open, handleClose, i18n, tagsTypes, currentTags, onSubmit }) => {
  const tagsTypesCopy = _.cloneDeep(tagsTypes)
  tagsTypesCopy.forEach((tag) => {
    if (_.find(currentTags, {id: tag.id})) {
      tag.enabled=true
    } else {
      tag.enabled=false
    }
  })
  const [tagsTypesState, setTagsTypesState] = React.useState(tagsTypesCopy)
  const [date] = React.useState(new Date())

  const reset = () => {
    tagsTypesState.forEach((tag) => {
      tag.enabled=false
    })
  }

  const onClickSubmit = (e) => {
    e.preventDefault()
    const tagsRes = tagsTypesState.filter(tag => tag.enabled === true).map(tag => tag.id)

    onSubmit(tagsRes, moment(date).format('YYYY-MM-DD HH:mm:ss'))
    reset()
  }

  const toggle = (tag) => {
    tag.enabled = !tag.enabled
    setTagsTypesState(_.cloneDeep(tagsTypesState))
  }

  return (
    <Modal
      style={customStyles}
      className="modal-wrapper"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <ModalStyled>
        <ModalHeader Icon={<ModalIcon />} title={i18n.modalChangeTags.title} closeClick={handleClose} />
        <div className="modal-body">
          <form onSubmit={onClickSubmit}>
            <Grid container alignItems="center" spacing={1}>
              {
                tagsTypesState.map(tag => {
                  return (
                    <Grid container alignItems="center" spacing={2} key={`mcr_${tag.id}`}>
                      <Grid item xs={12} sm={8}>
                        <label><Tag shortVersion={ false } tag={tag} fontSize={'1.2em'}
                                    onClick={() => toggle(tag)}/></label>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CheckButton
                          name={"tag-"+tag.id}
                          title={i18n.modalCreateReport.include}
                          checked={tag.enabled}
                          onChange={() => toggle(tag)}
                        />
                      </Grid>
                    </Grid>
                  )
                })
              }
            </Grid>

            <div style={{marginTop: '45px'}}>
              <div className="button-wrapper" >
                <RegularButton title={i18n.update} />
              </div>
            </div>
          </form>
        </div>
      </ModalStyled>
    </Modal>
  )
}

ModalChangeTags.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  tagsTypes: PropTypes.array,
  currentTags: PropTypes.array,
  module: PropTypes.string,
}

const enharce = compose(withI18n)

export default enharce(ModalChangeTags)
