import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const SpinnerStyled = styled.div`
  && {
    position: relative;
    min-height: 300px;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 40px;
      width: 40px;
      transition: 0.4s ease all;
      animation: rotating 1s linear infinite;
      path {
        fill: ${props => props.theme.primary};
      }
    }

    ${props =>
      props.styledType === 'overlay-spinner' &&
      css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${colors.whiteAlpha75};
        z-index: 2;
      `}
  }

  @keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`

export default SpinnerStyled
