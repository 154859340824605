import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import _ from 'lodash'
import { RegularButton, CheckButton } from '../../atoms'
import {matchPath, useLocation, useParams} from "react-router";
import routes from "../../../config/routes";
import {useAppContext} from "../../../context/appContext";
import {getCurrentSectionKey, SECTION_KEYS} from "../../../config/constants";
import * as FiltersUtils from "../../../utils/filters";

const Form = ({ module, ids, fields, filters, sort, onSubmit, i18n, offerFilters }) => {

    const {savedSearchItems, filterGroup} = useAppContext();
    const location = useLocation();
    const {slug, savedSearchItemId} = useParams();
    const [ form, setForm ] = useState(null);
    const [ isSubmitting, setIsSubmitting ] = useState(false);

    useEffect(()=>{
        setForm(fields)
        // eslint-disable-next-line
    }, [])

    const handleClick = () => {
        let newFilter = {...filters}
        if (matchPath(location.pathname, {path: routes.offerCandidatures.replace(':slug', slug)})) {
            newFilter = {...offerFilters}
        }
        if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM) {
            let strFilters = savedSearchItems && savedSearchItems[savedSearchItemId] ? savedSearchItems[savedSearchItemId].fields : null;
            let filters = strFilters ? JSON.parse(strFilters) : {};
            let filterGroupValue = filterGroup[getCurrentSectionKey()] && filterGroup[getCurrentSectionKey()][savedSearchItemId] ? filterGroup[getCurrentSectionKey()][savedSearchItemId] : null;

            newFilter = FiltersUtils.refactorSavedSearchFiltersByGroupSelected(filters, filterGroupValue);
        }
        if(ids && ids.length > 0) newFilter = {id: ids}
        const submitForm = _.map(form, (item) => item.remote_name)
        setIsSubmitting(true)
        onSubmit( submitForm, {...newFilter}, sort, module )
    }

    const handleChangeValue = e => {
        const newForm = [...form]
        if (e.target.checked) {
            newForm.push(_.find(fields, {remote_name: e.target.name}))
        } else {
            _.remove(newForm, (item) => { 
                return item.remote_name === e.target.name; 
            }); 
        }
        setForm(newForm)
    }
    if(!form) return null
    return (
        <>
            {_.map(fields, ({ name, remote_name }) => {
                return (
                <Grid container alignItems="center" spacing={3} key={`mcr_${remote_name}`}>
                    <Grid item xs={12} sm={4}>
                    <label>{name}</label>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                    <CheckButton
                        name={remote_name}
                        title={i18n.modalCreateReport.include}
                        checked={_.find(form, {remote_name})}
                        onChange={handleChangeValue}
                    />
                    </Grid>
                </Grid>
                )
            })}
            <div className="button-wrapper">
                <RegularButton disabled={_.isEmpty(form) || isSubmitting} title={i18n.modalCreateReport.createReport} onClick={handleClick} />
            </div>
        </>
    )
}

const enharce = compose(withI18n)

export default enharce(Form)