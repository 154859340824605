import styled from 'styled-components'
import { headerStatStyles, chartWrapperStyles } from '../dynamic-widget/styled'

const textColor = '#333333';

const CircleStatisticStyled = styled.div`
  && {
    padding: 30px 0;
    min-width: 200px;
    height: ${props => props.whatsappCounter ? "100%" : "inherit"};
  }
  
  ${headerStatStyles}

  ${chartWrapperStyles}

  .chart-wrapper {
    top: 5%;
    svg circle {
      stroke-width: 1;
    }
  }

  .value-percent {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: ${textColor};
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bottom-circle {
    position: absolute;
  }
`

export default CircleStatisticStyled
