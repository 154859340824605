import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import {operations as ProfileExitInterviewsOperations} from '../../../redux/profileExitInterview'

const mapStateToProps = state => ({
  data: state.profileExitInterview.data,
  user: state.profileExitInterview.user,
  comments: state.profileExitInterview.comments,
  documents: state.profileExitInterview.documents,
  isFetching: state.profileExitInterview.isFetching
})

const mapDispatchToProps = dispatch => {
  return {
    fetchProcess: id => dispatch(ProfileExitInterviewsOperations.fetchProcess(id)),
    initProcess: id => dispatch(ProfileExitInterviewsOperations.initProcess(id)),
    fetchComments: id => dispatch(ProfileExitInterviewsOperations.fetchComments(id)),
    fetchDocuments: id => dispatch(ProfileExitInterviewsOperations.fetchDocuments(id)),
    addComment: (id, comment) => dispatch(ProfileExitInterviewsOperations.addComment(id, comment)),
    markAsRead: id => dispatch(ProfileExitInterviewsOperations.markAsRead(id)),
    addFav: (id, isFav) => dispatch(ProfileExitInterviewsOperations.addFav(id, isFav)),
  }
}

const enharce = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withI18n)

export default enharce(View)
