import styled from 'styled-components'
import colors from '../../../assets/colors'

export const ModalStyled = styled.div`
  && {
    border-radius: 8px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 100%;
    width: 800px;
    outline: none;

    .modal-body {
      background-color: ${colors.white};
      padding: 30px 50px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      outline: none;
      .grid-decoration {
        position: relative;
        ::before {
          content: '-';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -4px;
        }
      }
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }

      .button-wrapper {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 50px;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: right;
          cursor: pointer;
        }

        button {
          margin-left: 20px;
        }
      }
    }
    .multi-select {
      [class*='-indicatorSeparator'] {
        display: none;
      }
      [class*='-IndicatorsContainer'] {
        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 18px;
        }
      }
      [class*='-control'] {
        min-height: 40px;
        background: ${colors.white};
        border: 1px solid ${colors.greyAlpha50};
        box-sizing: border-box;
        border-radius: 5px;
        padding: 0 15px;
        outline: none;
        width: 100%;
        appearance: none;
        box-shadow: none;
        > div {
          padding: 0;
        }
      }
      [class*='-placeholder'] {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #9999b3;
      }
    }
  }
`

export const InputStyled = styled.div`
  > div > div {
    min-height: 40px;
    font-size: 14px;
    border: 1px solid ${colors.grey};
    box-shadow: none;
    :hover,
    :active,
    :focus {
      border: 1px solid ${colors.grey};
    }

    font-size: 14px;
  }

  [class*='-placeholder'] {
    color: #9999b3;
  }

  > div > div span {
    background-color: transparent;
  }

  .dropdownIndicator {
    color: ${colors.greyAlpha15};
  }
`
