import React, {Component} from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import moment from 'moment'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import compose from 'recompose/compose'
import JSZip from 'jszip'
import {DocumentsList, HeaderProfileExitInterview, List, ObservationsTable, Panel} from '../../molecules'
import {withI18n} from '../../hocs'
import {SpinnerIcon} from '../../atoms'
import * as S from './styled'

class ProfileInfoSimple extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selecedDocuments: []
    }
  }

  componentDidMount() {
    const id = this.props.id || _get(this.props, 'match.params.slug')
    this.props.initProcess(id)
    this.props.markAsRead(id)
  }

  handleAddObservation = value => {
    const {addComment} = this.props
    addComment(this.props.id || _get(this.props, 'match.params.slug'), value)
  }

  render() {
    const {data, comments, i18n} = this.props

    const downloadBlob = (blob, name = 'myFile') => {
      const blobUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = blobUrl
      link.download = name
      document.body.appendChild(link)
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      )
      document.body.removeChild(link)
    }

    const formatAttachments = data => {
      if (!data) return []
      return _map(data, ({id, original_name, url}) => {
        return {id, name: original_name, file: url}
      })
    }

    const formatVariables = (oldData) => {
      if (!oldData) return []
      return _map(Object.keys(_get(data, 'variable_fields')), item => {
        return {id: item, label: item in i18n.exit_interview ? i18n.exit_interview[item] : item, text: oldData[item]}
      })
    }

    const handleDownload = () => {
      const newDocuments = this.state.selecedDocuments.map(item => {
        return fetch(item.file, {cache: 'no-cache'})
          .then(response => response.arrayBuffer())
          .then(arrayBuffer => {
            return Promise.resolve({
              data: Buffer.from(arrayBuffer, 'binary').toString('base64'),
              name: item.name,
              file: item.file
            })
          });
      })
      var zip = new JSZip()
      Promise.all(newDocuments).then(response => {
        response.map(item => zip.file(item.name, item.data, {base64: true}))
        zip.generateAsync({type: 'blob'}).then(function (content) {
          downloadBlob(content, 'files_' + moment().format('YYYYMMDD_Hms') + '.zip')
        })
      })
    }

    const reloadById = (exitId) => {
      this.props.fetchProcess(exitId);
      this.props.fetchComments(exitId);
    };

    if (!data || !comments) return <SpinnerIcon/>
    return (
      <S.Container id="profilePrint">
        <HeaderProfileExitInterview
          onGoBack={this.props.onGoBack}
          className="no-print"
          userId={_get(data, 'id', '')}
          userName={`${_get(data, 'mongo_temp.nombre', '')} ${_get(data, 'mongo_temp.apellidos', '')}`}
          onChangeProfile={reloadById}
        />
        <S.Title>
          <S.ProcessIsFavourite className="no-print" favorite={data.is_favorite}
                                onClick={() => this.props.addFav(data.id, !data.is_favorite)}/>
          {`${_get(data, 'employee.name')} ${_get(data, 'employee.surname')} `}
        </S.Title>
        <Panel>
          <>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item sm={5}>
                <List
                  underlined
                  value={[
                    {id: 1, label: i18n.exit_interview.category, text: _get(data, 'employee.category')},
                    {id: 2, label: i18n.exit_interview.email, text: _get(data, 'employee.email')},
                    {id: 3, label: i18n.exit_interview.dni, text: _get(data, 'employee.dni')},
                    {id: 4, label: i18n.exit_interview.phone, text: _get(data, 'employee.phone')},
                    {
                      id: 5,
                      label: i18n.exit_interview.male_or_female,
                      text: (_get(data, 'employee.female') ? i18n.exit_interview.female : i18n.exit_interview.male)
                    },
                  ]}
                />
              </Grid>
              <Grid sm={1}></Grid>
              <Grid item sm={6}>
                <List
                  underlined
                  value={[
                    {
                      id: 1,
                      label: i18n.process.dayHourTransaction,
                      text: moment(_get(data, 'created_at')).format('DD/MM/YYYY hh:mm')
                    },
                    {id: 2, label: i18n.process.department, text: _get(data, 'employee.department')},
                    {id: 3, label: i18n.process.area, text: _get(data, 'employee.center')},
                    {id: 4, label: i18n.exit_interview.position, text: _get(data, 'employee.puesto')},
                    {
                      id: 5,
                      label: i18n.process.registerData,
                      text: _get(data, 'employee.hiring_date') !== null ? moment(_get(data, 'employee.hiring_date')).format('DD/MM/YYYY') : ''
                    },
                    {
                      id: 6,
                      label: i18n.exit_interview.leaving_date,
                      text: _get(data, 'employee.leaving_date') !== null ? moment(_get(data, 'employee.leaving_date')).format('DD/MM/YYYY') : ''
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </>
        </Panel>
        <Panel title={i18n.exit_interview.exit_reasons}>
          {_get(data, 'variable_fields_reason_exit')
            && Object.keys(_get(data, 'variable_fields_reason_exit')).map((key, index) => {
              return <S.ExitReason key={key + index}>
                <div className="reason_title">{key in i18n.exit_interview ? i18n.exit_interview[key] : key}</div>
                <div className="reason_content">{_get(data, 'variable_fields_reason_exit')[key]}</div>
              </S.ExitReason>
            })}
        </Panel>
        <Panel title={i18n.exit_interview.exit_factors}>
          {_get(data, 'variable_fields_factors')
            && <List underlined value={formatVariables(_get(data, 'variable_fields_factors'))}/>}
        </Panel>
        <Panel title={i18n.exit_interview.other_data}>
          {_get(data, 'variable_fields_others')
            && <List underlined value={formatVariables(_get(data, 'variable_fields_others'))}/>}
        </Panel>
        {(_get(data, 'attachments') && _get(data, 'attachments').length > 0)
          && <Panel title={i18n.process.documentation}>
            <S.Toolbar className="no-print">
              <S.ActionButton
                disabled={!this.state.selecedDocuments || this.state.selecedDocuments.length <= 0}
                handleOnClick={handleDownload}
                title={i18n.process.downloadDocs}
                styledType="invert"
              />
            </S.Toolbar>
            <DocumentsList
              check
              value={this.state.selecedDocuments}
              options={formatAttachments(_get(data, 'attachments'))}
              onChange={value => this.setState({selecedDocuments: value})}
            />
          </Panel>
        }
        <ObservationsTable showObsInput={true} handleAddObservation={this.handleAddObservation} observations={comments}
                           isFetching={this.props.isFetching}/>
      </S.Container>
    )
  }
}

ProfileInfoSimple.defaultProps = {
  data: {
    process: [],
    documents: []
  }
}

ProfileInfoSimple.propTypes = {
  i18n: PropTypes.object.isRequired,
  fetchUser: PropTypes.func,
  profileData: PropTypes.object,
  data: PropTypes.array,
  comments: PropTypes.array,
  fetchProcess: PropTypes.func,
  fetchComments: PropTypes.func,
  fetchDocuments: PropTypes.func,
  addComment: PropTypes.func,
  addFav: PropTypes.func,
  markAsRead: PropTypes.func,
  isFetching: PropTypes.bool,
  initProcess: PropTypes.func,
  id: PropTypes.number,
  onGoBack: PropTypes.func
}

const enharce = compose(withI18n)

export default enharce(ProfileInfoSimple)
