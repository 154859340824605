import React from 'react'
import compose from 'recompose/compose'
import ComponentStyled from './styled'
import { talentClueUrl } from '../../../config/constants'

const TalentClueLogo = () => {
  return (
    <ComponentStyled>
      <a href={talentClueUrl} rel="noopener noreferrer" target="_blank">
        <img alt="Talent Clue" src={require('../../../assets/images/talent-clue-logo.png')} className="talent-clue-logo" />
      </a>
    </ComponentStyled>
  )
}

const enharce = compose()

export default enharce(TalentClueLogo)
