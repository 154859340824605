import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import _map from 'lodash/map'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeaderProcess, RegularButton, RegularButtonOutline, InputField, CustomAlert } from '../../atoms'
import { Chip } from '@material-ui/core'
import { set } from 'lodash'

const customStyles = {
  overflow: 'scroll'
}

const ModalEditPhase = ({ open, handleClose, i18n, onSubmitEditPhase, initialValue, itemId, allPhasesData }) => {
  const [phase, setPhase] = useState(initialValue);
  const [phaseStates, setPhaseStates] = useState([]);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [showAlert, setShowAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [originalPhase, setOriginalPhase] = useState('');

  useEffect(() => {
    if (open && initialValue) {
      setIsOpen(true);
      setOriginalPhase(initialValue);
      setPhase(initialValue);
    }
  }, [open, initialValue]);

  useEffect(() => {
    if (isOpen && typeof phase === 'string' && phase.trim() !== '' && allPhasesData) {
      const selectedPhase = allPhasesData.find(fase => fase.name === phase);

      if (selectedPhase) {
        setPhaseStates(selectedPhase.status_candidates || []);
      }
    }

  }, [isOpen, phase, allPhasesData]);

  const resetModalValues = () => {
    if (isOpen) {
      setIsOpen(false);
      setPhase(originalPhase);
      setPhaseStates(originalPhase.status_candidates || []);
    }
  };

  const onChangePhase = e => {
    setPhase(e.target.value);
  }

  const onClickSubmit = async (e) => {
    e.preventDefault();


    const isPhaseDuplicate = allPhasesData.some(existingPhase => existingPhase.name === phase && existingPhase.id !== itemId);

    if (isPhaseDuplicate) {
      setAlert({ message: i18n.phases_states.alertPhaseExists, severity: 'error' });
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }
      , 2000);
      return;
    }

    const updatedPhase = { id: itemId, name: phase, status_candidates: phaseStates };
  
    try {
      await onSubmitEditPhase(updatedPhase);
      setAlert({ message: i18n.phases_states.updatedPhase, severity: 'success' });
      setShowAlert(true);
  
      setTimeout(() => {
        handleClose();
        setShowAlert(false);
      }, 2000);
    } catch (error) {
      setAlert({ message: i18n.phases_states.errorUpdatedPhase, severity: 'error' });
    }
  }; 
    
  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => {
          handleClose();
          resetModalValues();
        }}
      >
        <ModalStyled>
          <ModalHeaderProcess title={i18n.phases_states.modalEditPhaseTitle} closeClick={() => handleClose()} hasCloseIcon={true}  />
          <div className="modal-body">
            <form onSubmit={onClickSubmit}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12}>
                  <label>{i18n.phases_states.labelPhase}</label>
                  <InputField placeholder={i18n.phases_states.placeholderPhase} value={phase} onChange={onChangePhase} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.phases_states.titleState}</label>
                  <div className="wrapper-tags">
                    {phaseStates.length > 0 ? (
                      phaseStates.map((status) => (
                        <Chip
                          key={status.id}
                          label={status.name}
                          className='chip-custom'
                          style={{ backgroundColor: status ? status.color : '#d3d3d3' }}
                        />
                      ))
                    ) : (
                      <Chip label={i18n.phases_states.chipUnAssigned} className='chip-custom' />
                    )}
                  </div>

                </Grid>
              </Grid>
              <div className="button-wrapper">
                {showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
                <RegularButtonOutline title={i18n.phases_states.buttonCancel} handleOnClick={handleClose} />
                <RegularButton className="button-custom" title={i18n.phases_states.buttonSave} handleOnClick={onClickSubmit} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(ModalEditPhase)
