import styled from 'styled-components'

export const MTLinearPercentStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: start;
  

  .value {
    font-size: 14px;
    font-weight: 600;
    flex: 0,20%;
    margin-right: 10px;

  }

  .progress {
    width: 60px;
    border-radius: 5px;
    height: 7px;
    flex: 0,70%;
    margin-bottom: 0px;
  }
`
