import styled from 'styled-components'

const ModulesStyled = styled.div`
  .title-container {
    margin-top: 38px;

    .title {
      align-items: flex-end;
      display: flex;
    }
  }

  .modules-wrapper {
    padding: 50px 0;
  }

  .buttons-wrapper {
    text-align: right;
  }
  
  .header-stat {
    justify-content: center;
    
    > svg {
      display: none;
    }
  }
`

export default ModulesStyled
