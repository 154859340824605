import styled from 'styled-components'

export const MTEvaluationButtonStyled = styled.div`
  .evaluation {
    display: flex;
    justify-content: center;
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;

    .value {
      margin-right: 5px;
    }
  }
`
