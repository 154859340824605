import styled from 'styled-components'

const PlanningCountersStyled = styled.div`
  .SingleDatePicker_picker {
    z-index: 11;
  }

  .counter_icon {
    width: 36px;
    height: 36px;
    color: #47C057;
  }
`

export default PlanningCountersStyled
