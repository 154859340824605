import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid'
import MyProfileTagManagementStyled from './styled'
import {Box, LinearProgress} from '@material-ui/core'
import InputField from '../../atoms/input-field'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import RegularButton from '../../atoms/regular-button';
import MaterialTable from 'material-table';
import {IconDeleteTrash, IconEdit, MTText} from '../../atoms'
import {MODULES, QUERY_KEYS} from '../../../config/constants';
import _, {isArray, isObject} from 'lodash';
import moment from 'moment';
import {useTags} from "../../../api/queries/useTags";
import ModalCreateTag from "../../molecules/modal-create-tag";
import ModalDeleteTag from "../../molecules/modal-delete-tag";
import {isTagInUse} from "../../../api";
import {useAppContext} from "../../../context/appContext";
import {invalidateQueriesByKeysWithNoModule, removeQueriesByKeys} from "../../../api/mutations/mutationCallbacks";

const MyProfileTagManagement = ({ i18n, classes, self_data }) => {
    const {module} = useAppContext();

    const [searchTerm, setSearchTerm] = useState({ term: '' });
    const [moduleFilter, setModuleFilter] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [editingTagData, setEditingTagData] = useState(null);
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [moduleOptions, setModuleOptions] = useState([]);
    const {data: tags, isLoading, refetch} = useTags();
    const [clientHasModules, setClientHasModules] = useState([]);
    const [deleteParams, setDeleteParams] = useState(null);

    useEffect(() => {
        let modules = [];
        if (self_data) {
            let hasModules = [];
            if (isObject(self_data.technical_selection_user) && self_data.roles.includes(`ROLE_ATS_ADMIN_${MODULES.ID_TALENT_ACQUISITION}`)) {
                hasModules.push(MODULES.ID_TALENT_ACQUISITION);
            }
            if (isObject(self_data.user_on_boarding) && self_data.roles.includes(`ROLE_ATS_ADMIN_${MODULES.ID_ON_BOARDING}`)) {
                hasModules.push(MODULES.ID_ON_BOARDING);
            }
            if (isObject(self_data.user_feedback) && self_data.roles.includes(`ROLE_ATS_ADMIN_${MODULES.ID_FEEDBACK}`)) {
                hasModules.push(MODULES.ID_FEEDBACK);
            }
            if (isObject(self_data.user_exit_interview) && self_data.roles.includes(`ROLE_ATS_ADMIN_${MODULES.ID_EXIT_INTERVIEW}`)) {
                hasModules.push(MODULES.ID_EXIT_INTERVIEW);
            }
            if (hasModules.length > 0) setClientHasModules(hasModules);
        }
        // eslint-disable-next-line
    }, [tags]);

    useEffect(() => {
        if (clientHasModules && clientHasModules.length >= 1) {
            setModuleOptions(clientHasModules.map(module => ({id: module, name: i18n.module_names[module]})));
        }
    }, [clientHasModules]);

    if (isLoading) {
        return <LinearProgress />
    }

    const handleDataUpdate = async () => {
        try {
            await invalidateQueriesByKeysWithNoModule([`${QUERY_KEYS.TAGS}`]);
            await removeQueriesByKeys([`${module}:${QUERY_KEYS.TAGS_TYPES}`, `${module}:${QUERY_KEYS.INBOX}`]);

            if (isEdit || isDelete) {
                setIsEdit(false);
                setIsDelete(false);
            }
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };

    const filterData = (searchTermValue, moduleFilterValue, tagsList) => {
        if (!tagsList || tagsList.length === 0) {
            return [];
        }

        const filteredData = tagsList.filter((tag) => {
            const nameMatch = tag.name && tag.name.toLowerCase().includes(searchTermValue.toLowerCase());
            const descriptionMatch = tag.description && tag.description.toLowerCase().includes(searchTermValue.toLowerCase());
            const moduleMatch = tag.module && tag.module.toLowerCase().includes(searchTermValue.toLowerCase());

            return nameMatch || descriptionMatch || moduleMatch;
        });

        const moduleFilteredData = moduleFilterValue
            ? filteredData.filter((tag) => {
                const selectedModule = moduleOptions.find(option => option.id === tag.module);
                const moduleName = selectedModule ? selectedModule.id : tag.module;
                return moduleName === moduleFilterValue;
            })
            : filteredData;

        return moduleFilteredData;
    };

    const filteredTags = filterData(searchTerm.term, moduleFilter, tags);

    const handleSearchTerm = _.debounce((value) => {
        setSearchTerm({ term: value });
    }, 250);

    const handleModuleFilter = (_, value) => {
        setModuleFilter(value ? value.id : '');
    };

    const tableOptions = {
        emptyRowsWhenPaging: false,
        loadingType: 'linear',
        cellStyle: { textAlign: 'center' },
        pageSize: 10,
        pageSizeOptions: [10, 25, 50, 100],
        toolbar: false,
        draggable: false,
        selection: false,
    }

    let tableColumns = {
        name: {
            title: i18n.tag_management.name,
            render: function NameColumn(row) {
                return <MTText value={row.name} style={{ fontWeight: '400' }} />;
            },
            field: 'name'
        },
        description: {
            title: i18n.tag_management.description,
            render: function DescriptionColumn(row) {
                return <MTText value={row.description} style={{ fontWeight: '400' }} />;
            },
            field: 'description'
        },
        color: {
            title: i18n.tag_management.color,
            render: function DescriptionColumn(row) {
                return <div className="color-picker-button" style={{ backgroundColor: row.color }}/>
            },
            field: 'color'
        },
    };
    
    const moduleColumn = {
        module: {
            title: i18n.tag_management.module,
            render: function ModuleColumn(row) {
                return <MTText value={i18n.module_names[row.module]} style={{ fontWeight: '400' }} />;
            },
            field: 'module'
        }
    }
    
    const actionColumns = {
        editTag:{
            title: '',
            render: function EditTagColumn(row) {
                return <div className='custom-edit'
                    onClick={() => handleEditIconClick(row)} ><IconEdit /></div>
            },
            field: 'editTag',
            hidden: false,
        },
        deleteTag: {
            title: '',
            render: function DeleteTagColumn(row) {
                return <div className='custom-delete'
                            onClick={() => handleDeleteIconClick(row)}><IconDeleteTrash /></div>
            },
            field: 'deleteTag',
            hidden: false,
        }
    }

    if (moduleOptions && moduleOptions.length > 0 && moduleColumn) {
        tableColumns = {...tableColumns, ...moduleColumn};
    }
    tableColumns = {...tableColumns, ...actionColumns};

    const formattedValue = (value) => {
        if (_.isString(value) && (
          moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSSS', true).isValid() ||
          moment(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid() ||
          moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ||
          moment(value, 'YYYY-MM-DD', true).isValid()
        )) {
          return moment(value).format('DD/MM/YYYY');
        } else if (!_.isArray(value) && !_.isObject(value)) {
          return value;
        }
      }

    const handleEditIconClick = (row) => {
        setEditingTagData(row);
        setIsCreate(false);
        setIsModalOpen(true);
    };

    const handleCreateTag = () => {
        setEditingTagData(null);
        setIsCreate(true);
        setIsModalOpen(true);
    };

    const handleDeleteIconClick = async (row) => {
        setEditingTagData(row);
        setIsCreate(true);
        setIsDelete(true);
        if (row.id) {
            let res = await isTagInUse(row.id)
            let params = {currentTag: row};
            if (isArray(res) && res.length > 0) {
                params.ids = res;
                params.availableTags = _.filter(tags, (tag) => {
                    return tag.module === row.module && tag.id !== row.id;
                });
            }
            setDeleteParams(params);
        }
        setIsModalDeleteOpen(true);
    };

    const columnsArray = Object.values(tableColumns);

    columnsArray.forEach(column => {
        if (column.render) {
            column.render.displayName = `${column.field}_render`;
        }
    });

    return (
        <div className={classes.root}>
            <MyProfileTagManagementStyled>
                <div className="containerHeader">
                    <Grid container spacing={4}>
                        <Grid item className="sectionSecond" xs={12} sm={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start", gridGap: '15px', maxWidth: "800px", }}>
                                <h2>{i18n.tag_management.titleSection}</h2>
                                <h5>{i18n.tag_management.descriptionSection}</h5>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-end", gridGap: '15px', justifyContent: 'flex-end' }}>
                                <Box sx={{ display: 'flex', alignItems: "flex-end", placeSelf: 'flex-end' }}>
                                    <RegularButton className="button-custom" title={i18n.tag_management.createTag}
                                        onClick={handleCreateTag}
                                    />
                                </Box>
                                <Box sx={{ display: 'inline-flex', maxWidth: "367px", alignItems: "center", }}>
                                    <label>{i18n.tag_management.searchLabel}</label>
                                    <InputField
                                        placeholder={i18n.evaluativeTests.placeholderInputTerm}
                                        onChange={(e) => {
                                            e.persist();
                                            handleSearchTerm(e.target.value);
                                        }}
                                    />
                                </Box>
                                {moduleOptions && moduleOptions.length > 1
                                  && <Box item sx={{display: 'inline-flex', alignItems: "center"}}>
                                    <label>{i18n.tag_management.filterModule}</label>
                                    <Autocomplete
                                      className='autocomplete-custom'
                                      options={moduleOptions}
                                      getOptionLabel={(option) => option.name}
                                      onChange={handleModuleFilter}
                                      renderInput={(params) => (
                                        <TextField {...params} placeholder={i18n.tag_management.placeholderFilter} variant="outlined"/>
                                      )}
                                    />
                                </Box>}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <MaterialTable
                                data={filteredTags ?? tags}
                                columns={columnsArray}
                                options={tableOptions}
                            />
                        </Grid>
                        <ModalCreateTag
                            open={isModalOpen}
                            handleClose={() => setIsModalOpen(false)}
                            i18n={i18n}
                            isCreate={isCreate}
                            editingTag={editingTagData}
                            onDataUpdate={handleDataUpdate}
                            clientHasModules={clientHasModules}
                            moduleOptions={moduleOptions}
                        />
                        <ModalDeleteTag
                            open={isModalDeleteOpen}
                            handleClose={() => setIsModalDeleteOpen(false)}
                            i18n={i18n}
                            tagId={editingTagData ? editingTagData.id : ""}
                            tagName={editingTagData ? editingTagData.name : ""}
                            handleOnClickDelete={handleDataUpdate}
                            deleteParams={deleteParams}
                        />
                    </Grid>
                </div>
            </MyProfileTagManagementStyled>
        </div>
    );
};

export default MyProfileTagManagement;
