import * as types from './types'

const initialState = {
  data: null,
  multipleData: null,
  isFetching: false,
  isFetchingObservations: false,
  isFetchingUserList: false,
  observations: null,
  isFetchingPushOffers: false,
  pushOffers: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_DATA:
      return { ...state, data: action.payload }
    case types.SET_MULTIPLE_DATA:
      return { ...state, multipleData: action.payload }
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_IS_FETCHING_OBSERVATIONS:
      return { ...state, isFetchingObservations: action.payload }
    case types.SET_IS_FETCHING_PUSH_OFFERS:
      return { ...state, isFetchingPushOffers: action.payload }
    case types.SET_IS_FETCHING_USER_LIST:
      return { ...state, isFetchingUserList: action.payload }
    case types.SET_OBSERVATIONS:
      return { ...state, observations: action.payload }
    case types.SET_PUSH_OFFERS:
      return { ...state, pushOffers: action.payload }
    default:
      return state
  }
}
