import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'
import InputField from '../../atoms/input-field'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import RegularButton from '../../atoms/regular-button';
import {
  CustomAlert, RegularButtonOutline, TextAreaField,
} from '../../atoms'
import {
  QUERY_ACTIONS, QUERY_KEYS,
} from '../../../config/constants'
import _, {trim} from 'lodash';
import JobOfferWizardStyled from "./styled";
import i18n from "../../../assets/i18n";
import {ModalSimpleSmall} from "../../molecules";
import {useLocation, useParams} from "react-router";
import {useMutation} from "@tanstack/react-query";
import {patchJobOffer, postJobOffer} from "../../../api";
import {useHistory} from "react-router-dom";
import routes from "../../../config/routes";
import {manageQueriesWithPrefix} from "../../../api/mutations/mutationCallbacks";
import moment from "moment/moment";
import {useJobOffer} from "../../../api/queries/useJobOffers";
import {SingleDatePicker} from "react-dates";

const JobOfferWizard = () => {
  const location = useLocation();
  const history = useHistory();
  const {id} = useParams();
  const mode = location.pathname.split('/').pop();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [evaluationProfile, setEvaluationProfile] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [focused, setFocused] = useState(false);

  const [isOperationInProgress, setIsOperationInProgress] = useState(false);

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const { data: jobOffer, isLoading: isLoadingJobOffer} = useJobOffer(id);

  useEffect(() => {
    if (mode === 'edit') {
      if (!isLoadingJobOffer && !jobOffer) {
        history.push(routes.job_offers);
      } else if (jobOffer) {
        setName(jobOffer.name);
        setLocationValue(jobOffer.location);
        setDescription(jobOffer.description);
        setEvaluationProfile(jobOffer.evaluation_profile);
        setEndDate(moment(jobOffer.end_date));
      }
    } else {
      setName("");
      setLocationValue("");
      setDescription("");
      setEvaluationProfile("");
      setEndDate(moment().add(7, 'days'));
    }
  }, [mode, id, isLoadingJobOffer, jobOffer]);

  const handleOnClickSave = () => {
    setIsModalOpen(true);
  }

  const handleCreateJobOffer = (data) => {
    postJobOffer(data).then(() => {
      manageQueriesWithPrefix([QUERY_KEYS.JOB_OFFERS, `options:${QUERY_KEYS.JOB_OFFERS}`], QUERY_ACTIONS.REFETCH);
      setAlert({ message: i18n.job_offers.wizard.successCreateJobOffer, severity: 'success' });
      setTimeout(() => {
        setIsModalOpen(false);
        history.push(routes.job_offers);
      }, 3000);
    });
  }

  const handleUpdateJobOffer = ({id, data}) => {
    patchJobOffer(id, data).then(() => {
      manageQueriesWithPrefix([QUERY_KEYS.JOB_OFFERS, `options:${QUERY_KEYS.JOB_OFFERS}`, `${QUERY_KEYS.JOB_OFFER_ROW}:${id}`], QUERY_ACTIONS.REFETCH);
      setAlert({ message: i18n.job_offers.wizard.successUpdateJobOffer, severity: 'success' });
      setTimeout(() => {
        setIsModalOpen(false);
        history.push(routes.job_offers);
      }, 3000);
    });
  }

  const mutationCreateJobOffer = useMutation(handleCreateJobOffer);
  const mutationUpdateJobOffer = useMutation(handleUpdateJobOffer);

  const handleSave = async (e) => {
    e.preventDefault();

    if(isOperationInProgress) {
      return;
    }

    setIsOperationInProgress(true);

    try {
      const data = {
        name: trim(name),
        description: trim(description),
        location: trim(locationValue),
        recruitment_source: "manual",
        end_at: endDate.format('YYYY-MM-DD'),
      };

      if (mode === 'create') {
        await mutationCreateJobOffer.mutateAsync(data);
      } else {
        await mutationUpdateJobOffer.mutateAsync({id, data});
        setIsOperationInProgress(false);
      }
    } catch (error) {
      if (mode === 'create') {
        setAlert({ message: i18n.job_offers.wizard.errorCreateJobOffer, severity: 'error' });
      } else {
        setAlert({ message: i18n.job_offers.wizard.errorUpdateJobOffer, severity: 'error' });
        console.error('Error durante la mutación:', error);
      }
      setIsOperationInProgress(false);
    }
  }

  return (
    <JobOfferWizardStyled>
      <div className="containerHeader">
        <Grid container spacing={4}>
          <Grid item className="headerSection" xs={12} sm={12}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: "flex-start", gridGap: '15px'}}>
              <h2>{mode === 'create' ? i18n.job_offers.wizard.title_create : i18n.job_offers.wizard.title_edit}</h2>
              {/*<h5 style={{color: '#000'}}>{i18n.job_offers.wizard.subtitle}</h5>*/}
              <h5>{i18n.job_offers.wizard.description}</h5>
            </Box>
          </Grid>

          <Grid container className="fieldsSection" spacing={4}>
            <Grid item className="columnGroup" xs={12} sm={12}>
              <Box item sx={{display: 'inline-flex', flexDirection: 'column', alignItems: "flex-start", gap: '8px'}}>
                <label>{i18n.job_offers.wizard.name}</label>
                <InputField
                  value={name}
                  placeholder={i18n.job_offers.wizard.name_placeholder}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined"/>
                  )}
                />
              </Box>
              <Box item sx={{display: 'inline-flex', flexDirection: 'column', alignItems: "flex-start", gap: '8px'}}>
                <label>{i18n.job_offers.wizard.location}</label>
                <InputField
                  value={locationValue}
                  placeholder={i18n.job_offers.wizard.location_placeholder}
                  onChange={(e) => {
                    setLocationValue(e.target.value)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined"/>
                  )}
                />
              </Box>
            </Grid>
            <Grid item className="columnGroup" xs={12} sm={12}>
            <Box item sx={{display: 'inline-flex', flexDirection: 'column', alignItems: "flex-start", gap: '8px'}}>
              <label>{i18n.job_offers.wizard.end_at}</label>
              <SingleDatePicker
                showClearDate={true}
                showDefaultInputIcon={true}
                small={true}
                numberOfMonths={1}
                reopenPickerOnClearDate={true}
                hideKeyboardShortcutsPanel={true}
                date={endDate}
                onDateChange={date => setEndDate(date)}
                focused={focused}
                onFocusChange={() => {
                  setFocused(!focused)
                }}
                id="endDate_id"
                placeholder={i18n.job_offers.wizard.end_at_placeholder}
                isOutsideRange={(date) => {
                  return moment(date).isSameOrBefore(moment())
                }}
              />
            </Box>
            <Box item sx={{display: 'inline-flex', flexDirection: 'column', gap: '8px'}}>
              <label>{i18n.job_offers.wizard.description_value}</label>
              <TextAreaField
                value={description}
                placeholder={i18n.job_offers.wizard.description_value_placeholder}
                handleOnChange={(value) => {
                  setDescription(value)
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined"/>
                )}
              />
            </Box>
          </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div style={{position: "relative", bottom: 100, display: "flex", gap: 16}}>
              <RegularButton title={i18n.job_offers.wizard.save} onClick={handleOnClickSave}/>
              <RegularButtonOutline style={{borderRadius: 5}} title={i18n.ia_evaluation.wizard.cancel} onClick={() => {history.push(routes.job_offers)}}/>
            </div>
          </Grid>
        </Grid>
      </div>
      <ModalSimpleSmall
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        buttonTitle={i18n.job_offers.wizard.save_confirmation_button.replace('{mode}', i18n.job_offers.wizard[`${mode}_mode`])}
        handleButtonClick={handleSave}
        textContent={<>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} style={{display: "flex", flexDirection: "column", gap: 15}}>
              <h4
                style={{fontWeight: "bold"}}>{i18n.job_offers.wizard.save_confirmation_title.replace('{mode}', i18n.job_offers.wizard[`${mode}_mode`])}</h4>
              {showAlert && <CustomAlert severity={alert.severity} message={alert.message}/>}
            </Grid>
          </Grid>
        </>
        }
      />
    </JobOfferWizardStyled>
  );
};

export default JobOfferWizard;
