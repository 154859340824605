import { darken, lighten, useTheme } from '@material-ui/core'
import { useMemo } from 'react'
import { cloneDeep, find } from 'lodash'

const numberOfColors = 8;

const generatePalette = (baseColor, numberOfColors) => {
  const palette = [];
  let currentColor = baseColor;

  // Agregar el color base a la paleta
  palette.push(currentColor);

  // Calcular la diferencia de luminosidad entre cada paso
  const luminosityStep = 1 / (numberOfColors);

  // Generar los colores intermedios
  for (let i = 1; i < numberOfColors; i++) {
    currentColor = lighten(baseColor, i * luminosityStep) || darken(baseColor, i * luminosityStep);
    // Agregar el color a la paleta
    palette.push(currentColor);
  }

  return palette;
}

export const getPaletteColorByIndex = (idx, COLOR_PALETTE) => {
  return COLOR_PALETTE[idx >= COLOR_PALETTE.length ? (idx % COLOR_PALETTE.length) : idx];
}

export const useColorPalette = (overrideNumberOfColors = 8) => {
  const theme = useTheme();
  return useMemo(() => (
    generatePalette(theme.primary, overrideNumberOfColors ? overrideNumberOfColors : numberOfColors)
  ), [theme.primary]);
};

export const assignColorByValueAndData = (data, key, palette) => {
  let sortedData = cloneDeep(data);
  sortedData.sort((a, b) => {
    let aVal = a[key] && !isNaN(parseInt(a[key])) ? parseInt(a[key]) : a[key];
    let bVal = b[key] && !isNaN(parseInt(b[key])) ? parseInt(b[key]) : b[key];
    return bVal - aVal;
  });

  return data.map(item => {
    let index = sortedData.indexOf(find(sortedData, {[key]: item[key]}));
    let fill = palette[index];
    return { ...item, fill };
  });
}
