import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {
  AppBarInboxQuery,
  SaveSearchesTableQuery
} from '../../organism'
import {
  getCurrentSectionKey,
  MODULES,
  SECTION_KEYS,
} from '../../../config/constants'
import {useAppContext} from "../../../context/appContext";
import {parseFilterFromIdToLabel} from "../../../utils/filters";
import {useFunnelStatusTypes, useGetPhasesOfStatusTypesQuery} from "../../../api/queries/useFunnelStatusTypes";

const SavedSearchesQuery = ({classes, i18n, location}) =>{

  const {filters, setFilters, sort, module, setSavedSearchItems, clearContextIfNeeded} = useAppContext();
  const [total, setTotal] = useState(0);

  const {data: funnelStatusTypes} = useFunnelStatusTypes(MODULES.ID_TALENT_ACQUISITION);
  const phasesOfStatusTypes = useGetPhasesOfStatusTypesQuery(MODULES.ID_TALENT_ACQUISITION);

  useEffect(() => {
    clearContextIfNeeded(MODULES.currentModule());
  }, [module, location.pathname]);

  const setSavedSearchesListToContext = (savedSearchesList, moduleFilters) => {
      let contextItems = {};
      let contextFilters = {};
      savedSearchesList.map(item => {
        item.filterGroupSelected = 1;
        contextItems[item.id] = item;
        let itemFilters = JSON.parse(item.fields);
        let itemFiltersKeys = Object.keys(itemFilters);
        let resultFilter = {};
        itemFiltersKeys.map(key => {
          let targetFilter = _.find(moduleFilters, {remote_name: key})
          let filterValues = itemFilters[key];
          let processedFilter = [];
          if (!Array.isArray(filterValues)) {
            filterValues = [filterValues];
          }
          filterValues.map(value => {
            let valueString = value;
            if (value.hasOwnProperty('from') || value.hasOwnProperty('to')) {
              valueString = value.from ? value.from : '/';
              valueString = valueString + ' - ' + (value.to ? value.to : '/');
            }

            let filterItem = {};
            filterItem.filter = targetFilter;
            switch (key) {
              case 'estado':
                filterItem.id = `${key}-${i18n.status[value]}`;
                filterItem.label = i18n.status[value];
                break;
              // case 'state.name':
              //   filterItem.id =
              //   filterItem.label =
              //   break;
              case 'selection_status':
                filterItem.id = valueString;
                filterItem.label = parseFilterFromIdToLabel(valueString, funnelStatusTypes, ['data'], 'name');
                break;
              case 'selection_phase':
                filterItem.id = valueString;
                filterItem.label = parseFilterFromIdToLabel(valueString, phasesOfStatusTypes, null, 'name');
                break;
              default:
                filterItem.id = `${key}-${valueString}`
                filterItem.label = valueString;
                break;
            }
            filterItem.name = key;
            filterItem.value = value;
            processedFilter.push(filterItem);
          });
          resultFilter[key] = processedFilter;
        })
        contextFilters[item.id] = resultFilter;
      });
      setSavedSearchItems(contextItems);
      setFilters({...filters, [getCurrentSectionKey()]: contextFilters, [SECTION_KEYS.SAVED_SEARCHES_ITEM]: contextFilters});
  }

  return (
    <div className={classes.root}>
      <AppBarInboxQuery
        showActions
        module={module}
        saveSearchOption={false}
        canEditFilters={false}
        canCloneSearch
        forceTotal={total}
        section={getCurrentSectionKey()}
      />
          <SaveSearchesTableQuery module={module}
                                  setTotal={setTotal}
                                  setSavedSearchesListToContext={setSavedSearchesListToContext}
                                  sort={sort[getCurrentSectionKey()]}/>

    </div>
  )
}

export default SavedSearchesQuery
