import styled from 'styled-components'
import colors from '../../../assets/colors'
import { ExportInboxButton } from '../../molecules'
import React from "react";


export const PlanningTableStyled = styled.div`
  && {
    padding: 30px 0;
  }
  .planning-btn {
    margin-right: 15px;
    margin-left: 15px;
    color: ${colors.white};
    background-color: ${colors.orange};
    text-transform: capitalize;
  }
  
  .was_read {
    font-weight: 400 !important;
    color: ${({ theme }) => theme.colors.black} !important;
    div:not([alt="avatar"]) {
      font-weight: 400 !important;
      color: ${({ theme }) => theme.colors.black} !important;
    }
    .value {
      font-weight: 400 !important;
    }
  }

  .not_read {
    font-weight: 800 !important;
    color: ${({ theme }) => theme.colors.black} !important;
    div:not([alt="avatar"]) {
      color: ${({ theme }) => theme.colors.black} !important;
    }
  }
`

export const ModalStyled = styled.div`
  && {
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    width: 685px;
    outline: none;

    .modal-body {
      background-color: ${colors.white};
      padding: 30px 50px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      outline: none;
      .grid-decoration {
        position: relative;
        ::before {
          content: '-';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -4px;
        }
      }
      label {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }

      .button-wrapper {
        text-align: right;

        button {
          margin-top: 25px;
        }
      }
    }

    .button-clear-filters {
      background: red;
    }
  }
`
export const ExportButton = styled(props => <ExportInboxButton {...props}/>)`
  margin-right: 10px !important;
`
