import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import SkillProfileStyled from './styled'
import CircularProgress from '@material-ui/core/CircularProgress'

const SkillProfile = ({ title, value }) => {
  if (value < 0) value = 0
  return (
    <SkillProfileStyled>
      <div className="circle-wrapper">
        <span>{value}%</span>
        <ColorCircularProgress value={value} size={140} />
      </div>
      <p>{title}</p>
    </SkillProfileStyled>
  )
}

const ColorCircularProgress = ({ ...props }) => {
  return (
    <div>
      <CircularProgress variant="static" className={'top-circle'} {...props} value={100} />
      <CircularProgress variant="static" className={'bottom-circle'} {...props} />
    </div>
  )
}

SkillProfile.defaultProps = {
  value: 0
}

SkillProfile.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number
}

const enharce = compose()

export default enharce(SkillProfile)
