import React from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import CardStatisticStyled from './styled'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles';
import i18n from '../../../assets/i18n'


const CardStatistic = ({title, value, progressBar }) => {
  if (value < 0) value = 0

    const CardLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: theme.primary,
        },
    }))(LinearProgress);

  return (
      <CardStatisticStyled>
        <Grid container className="card-container">
          <Grid item xs={12} md={progressBar?6:8} className="label-stat">
            {title}
          </Grid>
          <Grid item xs={12} md={progressBar?6:4} className="value-stat">
            {value}{progressBar?"%":""}
          </Grid>
          {progressBar?(
            <Grid item xs={12} md={12} className="bar-stat">
                <CardLinearProgress variant="determinate" value={Math.min(value,100)} />
            </Grid>
          ):null}
        </Grid>
      </CardStatisticStyled>
  )
}

CardStatistic.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  percentText: PropTypes.string
}

CardStatistic.defaultProps = {
  title: i18n.statistics
}

const enharce = compose(withI18n)

export default enharce(CardStatistic)
