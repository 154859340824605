import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import styles from './styles'

const DrawerMenuItem = ({ selected, Icon, onClick, classes, className, ...rest }) => {
  const theme = useTheme()
  const htmlColor = selected ? theme.textPrimary : theme.textSecondary
  return (
    <MenuItem {...rest} onClick={onClick} className={`${className} ${classes.root}`} selected={selected}>
      {Icon && <Icon htmlColor={htmlColor} fontSize="large" />}
    </MenuItem>
  )
}

DrawerMenuItem.propTypes = {
  selected: PropTypes.bool,
  Icon: PropTypes.func,
  onClick: PropTypes.func,
  classes: PropTypes.object
}

export default withStyles(styles)(DrawerMenuItem)
