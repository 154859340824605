import * as types from './types'

export const setIsFetching = isFetching => ({
  type: types.SET_IS_FETCHING,
  payload: isFetching
})

export const setIsFetchingObservations = isFetchingObservations => ({
  type: types.SET_IS_FETCHING_OBSERVATIONS,
  payload: isFetchingObservations
})

export const setIsFetchingPushOffers = isFetchingPushOffers => ({
  type: types.SET_IS_FETCHING_PUSH_OFFERS,
  payload: isFetchingPushOffers
})

export const setIsFetchingUserList = isFetchingUserList => ({
  type: types.SET_IS_FETCHING_USER_LIST,
  payload: isFetchingUserList
})

export const setData = data => ({
  type: types.SET_DATA,
  payload: data
})

export const setMultipleData = data => ({
  type: types.SET_MULTIPLE_DATA,
  payload: data
})

export const setObservations = data => ({
  type: types.SET_OBSERVATIONS,
  payload: data
})

export const setPushOffers = data => ({
  type: types.SET_PUSH_OFFERS,
  payload: data
})
