import React, {useEffect, useState} from 'react'
import compose from 'recompose/compose'
import moment from 'moment'
import _ from 'lodash'
import {withI18n} from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import {ModalStyled} from './styled'
import Modal from '@material-ui/core/Modal'
import {ChipCustom, ModalHeader, RegularButton} from '../../atoms'
import {ReactComponent as ModalIcon} from '../../../assets/images/user-white.svg'
import SelectTags from "../select-tags";

const customStyles = {
  overflow: 'scroll'
}

const ModalChangeTagsQuery = ({ open, handleClose, i18n, tagsTypes, currentTags, onSubmit }) => {
  const [date] = React.useState(new Date())

  const [tagsShown, setTagsShown] = useState({});
  const [tagsForSelect, setTagsForSelect] = useState([]);

  const getTagsToShow = () => {
    const tags = _.cloneDeep(currentTags)
    let tagsReduce = tags.reduce((accu, theTag) => {
      _.map(tagsTypes, (theTagType) => {
        if (theTag.name === theTagType.name) {
          accu[theTag.id] = theTag;
          accu[theTag.id].color = theTagType.color;
        }
      })
      return accu;
    }, {})
    setTagsShown(tagsReduce)
  }

  useEffect(() => {
    getTagsToShow()
  }, [currentTags])

  useEffect(() => {
    tagsTypes && getTagsForSelect();
  }, [tagsShown]);

  const onClickSubmit = (e) => {
    e.preventDefault()
    let tagIds = Object.keys(tagsShown);
    onSubmit(tagIds, moment(date).format('YYYY-MM-DD HH:mm:ss'))
    setTagsShown([]);
  }

  const renderTags = () => {
    return (
      _.map(tagsShown, (theTagShown) => {
        return <ChipCustom targetId={theTagShown.id} onDelete={removeTags} label={theTagShown.name} color={theTagShown.color} />
      }))
  }

  const removeTags = (e) => {
    const idTagToRemove = parseInt(e.currentTarget.getAttribute('targetid'));
    let newTags = {...tagsShown};
    delete newTags[idTagToRemove];
    setTagsShown(newTags)
  }

  const handleAddTag = (tag) => {
    const idTagToAdd = tag.id
    let tagToAdd = _.find(tagsTypes, {id: idTagToAdd});
    let newTags = {...tagsShown, [idTagToAdd]: tagToAdd};
    setTagsShown(newTags)
  }

  const getTagsForSelect = () => {
    let idsNotAvaible = Object.keys(tagsShown);
    let selectValues = tagsTypes;
    if (idsNotAvaible) {
      selectValues = tagsTypes.filter(theTagType => !idsNotAvaible.includes(theTagType.id.toString()));
    }
    setTagsForSelect(selectValues);
  }

  return (
    <Modal
      style={customStyles}
      className="modal-wrapper"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <ModalStyled>
        <ModalHeader Icon={<ModalIcon />} title={i18n.modalChangeTags.title} closeClick={handleClose} />
        <div className="modal-body">
          <form onSubmit={onClickSubmit}>
            <Grid container alignItems="center" spacing={1}>
              <div className="selectTagsWrapper">
                <SelectTags options={tagsForSelect} onChange={(e) => handleAddTag(e)}/>
              </div>
              {renderTags()}
            </Grid>

            <div style={{marginTop: '45px'}}>
              <div className="button-wrapper" >
                <RegularButton title={i18n.update} />
              </div>
            </div>
          </form>
        </div>
      </ModalStyled>
    </Modal>
  )
}

ModalChangeTagsQuery.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  tagsTypes: PropTypes.array,
  currentTags: PropTypes.array,
  module: PropTypes.string,
}

const enharce = compose(withI18n)

export default enharce(ModalChangeTagsQuery)
