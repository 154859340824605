import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import { ModalHeader, RegularButton } from '../../atoms'
import i18n from '../../../assets/i18n'
import { ModalStyled } from './styled'
import Checkbox from '@material-ui/core/Checkbox'
import {basename, downloadUrl} from "../../../utils/url";

const ModalDownloadFiles = ({ handleClose, open, files }) => {
  const [checkedFiles, setCheckedFiles] = React.useState([])
  const disabledDownload = checkedFiles.length === 0 ? true : false

  const handleChange = key => {
    const existsKeyIndex = _.indexOf(checkedFiles, key)

    if (existsKeyIndex === -1) {
      setCheckedFiles([...checkedFiles, key])
    } else {
      setCheckedFiles(checkedFiles.filter(e => e !== key))
    }
  }

  const handleOnClickDownload = () => {
    _.map(checkedFiles, (fileId) => {
      const fileUrl = _.get(files, `${fileId}.url`, '')
      if (fileUrl) {
        downloadUrl(fileUrl)
      }
    })
  }

  const formatFiles = _.map(files, (file, key) => {
    const fileLabel = _.get(file, 'label', '')
    const fileUrl = _.get(file, 'url', '')
    const isChecked = _.indexOf(checkedFiles, key) > -1 ? true : false
    const fileName = decodeURIComponent(basename(fileUrl))
    return (
      <Grid key={key} container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={1}>
          <Checkbox
            checked={isChecked}
            onChange={() => {
              handleChange(key)
            }}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </Grid>
        <Grid item xs={12} sm={11}>
          <label className="file-name">
            <span className="file-label">{fileLabel}:</span> <a href={fileUrl} target="_blank" rel="noopener noreferrer">{fileName}</a>
          </label>
        </Grid>
      </Grid>
    )
  })

  return (
    <Modal open={open} onClose={handleClose} className="modal-wrapper" aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      <ModalStyled>
        <ModalHeader title={i18n.modalDownloadFiles.title} closeClick={handleClose} />
        <div className="modal-body">
          {formatFiles}
          <div className="button-wrapper">
            <RegularButton handleOnClick={handleOnClickDownload} title="Descargar seleccionados" disabled={disabledDownload} />
          </div>
        </div>
      </ModalStyled>
    </Modal>
  )
}

ModalDownloadFiles.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  files: PropTypes.array
}

export default ModalDownloadFiles
