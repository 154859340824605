import React from 'react'

const icon = props => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M18.3327 9.99984C18.3327 14.6022 14.6017 18.3332 9.99935 18.3332C5.39698 18.3332 1.66602 14.6022 1.66602 9.99984C1.66602 5.39746 5.39698 1.6665 9.99935 1.6665C14.6017 1.6665 18.3327 5.39746 18.3327 9.99984ZM9.99935 8.5415C10.3445 8.5415 10.6243 8.82133 10.6243 9.1665V14.1665C10.6243 14.5117 10.3445 14.7915 9.99935 14.7915C9.65417 14.7915 9.37435 14.5117 9.37435 14.1665V9.1665C9.37435 8.82133 9.65417 8.5415 9.99935 8.5415ZM9.99935 7.49984C10.4596 7.49984 10.8327 7.12674 10.8327 6.6665C10.8327 6.20627 10.4596 5.83317 9.99935 5.83317C9.53911 5.83317 9.16602 6.20627 9.16602 6.6665C9.16602 7.12674 9.53911 7.49984 9.99935 7.49984Z"
          fill="#6699FF"/>
  </svg>
)

export default icon