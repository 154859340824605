import LocalizedStrings from 'react-localization'
import es from './es'
import en from './en'
import pt from './pt'
import ca from './ca' // Catalán
import fr from './fr'
import es_mth from './es_mth'
import en_mth from './en_mth'

import 'moment/locale/es'

export default new LocalizedStrings({
  es,
  en,
  pt,
  ca, // Catalán
  fr,
  es_mth,
  en_mth,
})
