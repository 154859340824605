import styled from 'styled-components'

const UserNameTitleStyled = styled.div`

    #svgFavorite{
      transform: scale(1.2);
    }

  @media print {
    p {
      font-size: 33px !important;
      line-height: 33px !important;

      svg {
        width: 55px !important;
        height: 55px !important;
      }
    }
  }
  p {
    font-family: 'sans-serif'
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    position: relative;
    padding: 15px 0;
    color: #181A1C;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    aling-item:center;


    span {
      position: relative;
      padding-right: 115px;
      flex:0,50%;

      svg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
    div {
      margin-right: 10px;
      flex:0,25%;
    }
  }
`

export default UserNameTitleStyled
