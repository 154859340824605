import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import { withI18n } from '../../hocs'
import FilterButtonStyled from './styled'

const FilterButton = ({ value, total, label, isSelected, onClick, disabled }) => (
  <FilterButtonStyled isSelected={isSelected} disabled={disabled}>
    <Button variant="contained" className="ft-button" onClick={onClick} color={isSelected ? 'primary' : 'default'} disabled={disabled}>
      <span className="ft-label">{label}</span>
      <span className="ft-value">{`(${value} / ${total})`}</span>
    </Button>
  </FilterButtonStyled>
)

FilterButton.defaultProps = {
  value: 0,
  total: 0,
  label: '',
  isSelected: false,
  disabled: false
}

FilterButton.propTypes = {
  total: PropTypes.number,
  value: PropTypes.number,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

const enharce = compose(withI18n)

export default enharce(FilterButton)
