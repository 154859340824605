import React, {useEffect} from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'
import moment from 'moment'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeader, RegularButton, DateInput, TimeInput, InputEditor } from '../../atoms'
import { SimpleDialog } from '../../molecules'
import { ReactComponent as ModalIcon } from '../../../assets/images/calendar.svg'
import useDefaultMessages from "../../../api/queries/useDefaultMessages";
import {MODULES} from "../../../config/constants";
import {useAppContext} from "../../../context/appContext";
import {useLocation} from "react-router";

const ModalRequireDocQuery = ({ open, handleClose, i18n, onSubmit }) => {

  const {module, clearContextIfNeeded} = useAppContext();

  const location = useLocation();
  const [openPopup, setOpenPopup] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [date, setDate] = React.useState(new Date())
  const [time, setTime] = React.useState(moment().format('HH:mm'))

  const {data: defaultMessages} = useDefaultMessages(module);

  useEffect(() => {
    clearContextIfNeeded(MODULES.currentModule());
  }, [module, location.pathname]);

  function handleClickOpenPopup() {
    setOpenPopup(true)
  }

  const handleClosePopup = value => {
    setOpenPopup(false)

    if (value) {
      const body = value.body || ''

      setMessage(body)
    }
  }

  const clear = () => {
    setMessage('')
    setDate(new Date())
    setTime(moment().format('HH:mm'))
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleClose()

    const limit_date = new moment(`${moment(date).format('YYYY/DD/MM')} ${time}`, 'YYYY/DD/MM HH:mm').format('YYYY-MM-DD HH:mm:ss')

    onSubmit({
      message,
      limit_date,
    })

    clear()
  }

  const submitDisabled = _.isEmpty(message)
  return (
    <>
      <Modal
        className="modal-announcement-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={i18n.modalRequireDoc.requireDoc} closeClick={handleClose} />
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="flex-end" spacing={3}>
                <Grid item xs={12} sm={4}>
                  <label>{i18n.modalRequireDoc.limitDate}</label>
                  <DateInput fontSize="small" name="date" onChange={date => setDate(date)} value={date} />
                </Grid>
                <Grid item xs={12} sm={3} className="grid-decoration">
                  <TimeInput fontSize="small" name="time" onChange={time => setTime(time)} value={time} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalRequireDoc.infoToUpload}</label>
                  <InputEditor
                    fontSize="small"
                    placeholder={i18n.modalRequireDoc.writeInfoToUpload}
                    name="message"
                    value={message}
                    onChange={value => setMessage(value)}
                  />
                </Grid>
              </Grid>
              <div className="button-wrapper">
                <p onClick={handleClickOpenPopup}>{i18n.modalRequireDoc.defaultMessages}</p>
                <SimpleDialog defaultMessages={defaultMessages} open={openPopup} onClose={handleClosePopup} />
                <RegularButton type="submit" title={i18n.modalRequireDoc.sendRequireDoc} disabled={submitDisabled} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

ModalRequireDocQuery.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

const enharce = compose(withI18n)

export default enharce(ModalRequireDocQuery)
