import styled from 'styled-components'

const JobOfferApplicationsStyled = styled.div`
  .containerHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    padding: 36px 49px;
    border-radius: 25px;
    background: #ffffff;
    gap: 50px;
  }

  .sectionSecond {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;

    label {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0px;
      margin-right: 20px;
    }
    input {
      font-size: 14px;
      font-family: 'Poppins';
    }

    & h2 {
      font-size: 28px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0;
    }
    & h5 {
      color: #7a808d;
      font-weight: 400;
      line-height: inherit;
    }

    & p {
      font-weight: 400;
      color: #7a808d;
    }
  }

  .MuiTableHead-root th {
    font-weight: bold;
    color: black;
  }

    .MuiTableHead-root th:nth-child(n+2) {
        text-align: left !important;
    }

    .MuiTableHead-root th:nth-child(n+9) {
      text-align: center !important;
  }
    
  .button-custom {
      float: right;
  }
    
  .avatarField {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
      
    .createdAt {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: start;
    }
      
    .avatarFieldSubgroup {
        display: flex;
        justify-content: space-between;
    }
  }
    
  .read {
      font-weight: 400;
  }
  
  .not-read {
      font-weight: 700;
  }
`

export default JobOfferApplicationsStyled
