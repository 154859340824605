import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import {QUERY_KEYS} from '../../config/constants'

async function getIaEvaluationProfiles({queryKey}) {
  let [_key, {searchTerm, isActive, range}] = queryKey;

  let filters = {name: searchTerm, is_active: isActive};
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''

  range = range ? encodeURI(JSON.stringify(range)) : '';
  let sort = ['created_at', 'DESC'];
  sort = encodeURI(JSON.stringify(sort));

  const response = await get(`/api/evaluation_profiles?filter=${filters}&range=${range}&sort=${sort}`);

  return response.data;
}

export function useIaEvaluationProfiles(searchTerm, isActive, range) {
  return useQuery([`${QUERY_KEYS.IA_EVALUATION}:${isActive ? 'active' : 'inactive'}`, {searchTerm, isActive, range}], getIaEvaluationProfiles);
}

async function getIaEvaluationProfile({queryKey}) {
  let [_key, {id}] = queryKey;
  if (!id) return null;

  const response = await get(`/api/evaluation_profiles/${id}`);

  return response.data;
}

export function useIaEvaluationProfile(id) {
  return useQuery([`${QUERY_KEYS.IA_EVALUATION_ROW}:${id}`, {id}], getIaEvaluationProfile);
}

async function getEvaluationProfileOptions() {
  return await get('/api/evaluation_profile_options');
}

export function useEvaluationProfileOptions() {
  return useQuery([`options:${QUERY_KEYS.IA_EVALUATION}`], getEvaluationProfileOptions);
}
