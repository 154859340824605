import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import * as FiltersUtils from "../../utils/filters";
import {MODULES, QUERY_KEYS, SECTION_KEYS} from "../../config/constants";
import allGroupsStatusType from "../../utils/all-groups-status-type";


async function getTotalCount({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  let [_key, {module, sectionKey, filters, slug}] = queryKey;
  if (!module || sectionKey === SECTION_KEYS.SAVED_SEARCHES || !filters) return;
  if (sectionKey === SECTION_KEYS.FAVORITES) filters = {...filters, isFavorite: true};

  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  return await get(`/api/module/${module}/inbox-count?filter=${filters}${slug ? '&groupByStatus=1' : ''}`);
}

export default function useTotalCount(module, sectionKey, oldFilters, slug = null) {
  let filters = {...oldFilters};

  if (sectionKey === SECTION_KEYS.SAVED_SEARCHES_ITEM && Object.keys(filters).length <= 0) filters = null;
  if (sectionKey === SECTION_KEYS.SAVED_SEARCHES_ITEM && module === MODULES.ID_TALENT_ACQUISITION && filters) {
    let allStatus = allGroupsStatusType();

    if (!filters.estado) {
      filters.estado = allStatus;
    } else {

      let statusFilters = filters.estado;
      statusFilters.map(status => allStatus.includes(status.value));
      filters.estado = statusFilters;
    }
  }
  return useQuery([`${module}:${sectionKey}:${QUERY_KEYS.TOTAL_COUNT}${slug ? ':'+slug : ''}`, {module, sectionKey, filters, slug}], getTotalCount);
}