import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeaderProcess, RegularButton, RegularButtonOutline, InputField, CustomAlert, RichTextEditor, ColorPicker, IconWarning } from '../../atoms'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Box, FormControlLabel, Switch } from '@material-ui/core';
import { Radio } from '@material-ui/core';

const customStyles = {
  overflow: 'scroll'
}

const ModalEditState = ({ open, handleClose, i18n, onSubmitEditState, statusItem, phaseId, phasesData, color, description }) => {
  const [state, setState] = useState(statusItem.name);
  const [descriptionStatus, setDescriptionStatus] = useState(description);
  const [changedPhase, setChangedPhase] = useState(phaseId);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [showAlert, setShowAlert] = useState(false);
  const [originalState, setOriginalState] = useState(statusItem);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSwatchColor, setSelectedSwatchColor] = useState(color);
  const defaultColor = '#1976d2';
  const [checkedSwitch, setCheckedSwitch] = useState(false);
  const [smsChecked, setSmsChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [whatsappChecked, setWhatsappChecked] = useState(false);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
      setOriginalState(statusItem);
      setState(statusItem.name);
      setSelectedSwatchColor(color);
      setDescriptionStatus(description);
      setChangedPhase(phaseId);

      const notificationMethods = statusItem.notification_methods || [];
      setCheckedSwitch(notificationMethods.length > 0);
      setSmsChecked(notificationMethods.includes('sms'));
      setEmailChecked(notificationMethods.includes('email'));
      setWhatsappChecked(notificationMethods.includes('whatsapp'));
    }
  }, [open, statusItem.name, color, description, phaseId]);


  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);

      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  const oncChangeState = e => {
    setState(e.target.value)
  }

  const options = (phasesData?.data ?? []);

  const onClickSubmit = async e => {
    e.preventDefault()

    if (!statusItem) {
      return;
    }

    if (state.trim() === '') {
      setAlert({ message: i18n.phases_states.alertStateNameRequired, severity: 'info' });
      setShowAlert(true);
      return;
    }

    const cleanedDescription = descriptionStatus.replace(/<[^>]*>/g, '');

    if (cleanedDescription.trim() === '') {
      setAlert({ message: i18n.phases_states.alertMessageFeedback, severity: 'info' });
      setShowAlert(true);
      return;
    }

    const selectedNotificationMethods = [];
    if (smsChecked) {
      selectedNotificationMethods.push('sms');
    }
    if (emailChecked) {
      selectedNotificationMethods.push('email');
    }
    if (whatsappChecked) {
      selectedNotificationMethods.push('whatsapp');
    }

    const updatedState = { id: statusItem.id, name: state, phaseId: phaseId, color: selectedSwatchColor, description: descriptionStatus, changedPhase: changedPhase, notification_methods: selectedNotificationMethods };

    try {
      await onSubmitEditState(updatedState)
      setAlert({ message: i18n.phases_states.updatedStatus, severity: 'success' });
      setShowAlert(true);

      setTimeout(() => {
        handleClose();
        setShowAlert(false);
      }, 2000);
    } catch (error) {
      setAlert({ message: i18n.phases_states.errorUpdatedStatus, severity: 'error' });
    }

  }
  const resetModalValues = () => {
    if (isOpen) {
      setIsOpen(false);
      setState(originalState ? originalState.name : '');
      setSelectedSwatchColor(originalState ? originalState.color : color);
      setDescriptionStatus(originalState ? originalState.description : '');
      setChangedPhase(originalState ? originalState.changedPhase : phaseId);
    }
  };

  const handleColorChange = (newColor) => {
    setSelectedSwatchColor(newColor.hex);
  };

  const handleQuillChange = (value) => {
    setDescriptionStatus(value);
  }

  const handlePhaseChange = (e, value) => {
    if (value) {
      setChangedPhase(value.id);
    }
  }

  const handleChangeSwitch = (e) => {
    setCheckedSwitch(e.target.checked);
    if (!e.target.checked) {
      setSmsChecked(false);
      setEmailChecked(false);
      setWhatsappChecked(false);
    }
  };

  const handleRadioChange = (selectedOption) => {
    setSmsChecked(selectedOption === 'sms');
    setEmailChecked(selectedOption === 'email');
    setWhatsappChecked(selectedOption === 'whatsapp');
  };

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => {
          handleClose();
          resetModalValues();
        }}
      >
        <ModalStyled>
          <ModalHeaderProcess title={i18n.phases_states.modalEditStateTitle} closeClick={() => handleClose()} hasCloseIcon={true} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12}>
                  <label>{i18n.phases_states.labelState}</label>
                  <InputField placeholder={i18n.phases_states.placeholderState} value={state} onChange={oncChangeState} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.phases_states.labelPhase}</label>
                  <Autocomplete
                    className='autocomplete-custom'
                    options={options}
                    onChange={handlePhaseChange}
                    getOptionLabel={(option) => option.name}
                    defaultValue={(phasesData?.data ?? []).find((phase) => phase.id === phaseId) || {}}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    sx={{ width: '500px' }}
                  />

                </Grid>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.phases_states.labelColor}</label>
                  <div className="containerColorPicker">
                    <ColorPicker
                      defaultColor={defaultColor}
                      onChange={handleColorChange}
                      color={color}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.phases_states.feedbackMessage}</label>
                  <RichTextEditor
                    onChange={handleQuillChange}
                    placeholder={i18n.phases_states.placeholderDescriptionStatus}
                    value={descriptionStatus}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} className='container-notification' >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start" }}>
                  <Switch
                    checked={checkedSwitch}
                    onChange={handleChangeSwitch}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'switchA' }}
                  />
                  <span>{i18n.phases_states.questionNotified}</span>
                </Box>
                {checkedSwitch && (
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginLeft: '58px' }}>
                      <span>{i18n.phases_states.selectNotificationMethods}</span>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '56px', gridGap: '5px', marginTop: '10px' }}>
                      <IconWarning />
                      <span>{i18n.phases_states.infoSelectNotificationMethods}</span>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start", marginLeft: '58px' }} >
                      <FormControlLabel
                        className='optional'
                        control={<Radio checked={smsChecked} />}
                        label={i18n.phases_states.smsMethod}
                        onChange={() => handleRadioChange('sms')}
                      />
                      <FormControlLabel
                        className='optional'
                        control={<Radio checked={emailChecked} />}
                        label={i18n.phases_states.emailMethod}
                        onChange={() => handleRadioChange('email')}
                      />
                      <FormControlLabel
                        className='optional'
                        control={<Radio checked={whatsappChecked} />}
                        label={i18n.phases_states.whatsapMethod}
                        onChange={() => handleRadioChange('whatsapp')}
                      />
                    </Box>
                  </>
                )}
              </Grid>

              <div className="button-wrapper">
                {showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
                <RegularButtonOutline title={i18n.phases_states.buttonCancel} handleOnClick={handleClose} />
                <RegularButton className="button-custom" title={i18n.phases_states.buttonSave} handleOnClick={onClickSubmit} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(ModalEditState)