import React from 'react'
import PropTypes from 'prop-types'
import { ButtonStyled } from './style'
import ArrowDropDownIcon from '@material-ui/icons/KeyboardArrowDown'


const ButtonDropdown = ({ title, onClick, open, menuList, disabled }) => {
    return (
        <>
            <ButtonStyled
                size="small"
                aria-owns={open ? menuList : undefined}
                aria-haspopup="true"
                disabled={disabled}
                onClick={onClick}>
                <span>{title}</span>
                <ArrowDropDownIcon />
            </ButtonStyled>
        </>
    )
}

ButtonDropdown.propType = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    open: PropTypes.bool,
    menuList: PropTypes.string
}

export default ButtonDropdown;
