import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material-ui/core/LinearProgress'
import { MTLinearPercentStyled } from './styled'

const MTLinearPercent = ({ value, className }) => {
  if (value < 0) value = 0
  return (
    <MTLinearPercentStyled className={className}>
      <span className="value">{value.toFixed()}%</span>
      <LinearProgress variant="determinate" value={value} className="progress" />
    </MTLinearPercentStyled>
  )
}

MTLinearPercent.propTypes = {
  value: PropTypes.number
}

MTLinearPercent.defaultProps = {
  value: 0
}

export default MTLinearPercent
