import React from 'react'

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" opacity="0.6">
    <path
      d="M12.0008 15.4249C10.9484 15.4249 9.89638 15.1559 8.93704 14.618L2.01944 10.7388C1.01633 10.1763 0.395315 9.08859 0.398575 7.90011C0.401878 6.71163 1.02894 5.62771 2.03513 5.07125L8.97016 1.23626C10.8679 0.186872 13.1338 0.186872 15.0315 1.23626L21.9665 5.07125C22.9727 5.62766 23.5998 6.71158 23.6031 7.90011C23.6063 9.08859 22.9853 10.1763 21.9823 10.7388L15.0646 14.618C14.1055 15.1559 13.053 15.4248 12.0008 15.4249ZM12.0008 2.34468C11.251 2.34468 10.5013 2.53366 9.8178 2.91163L2.88286 6.74666C2.46387 6.97836 2.21286 7.41168 2.21151 7.90565C2.21016 8.39963 2.45869 8.83444 2.87643 9.06869L9.79406 12.9478C11.176 13.7228 12.8259 13.7228 14.2078 12.9478L21.1255 9.06869C21.5432 8.83444 21.7918 8.39967 21.7904 7.90565C21.7891 7.41163 21.5381 6.97836 21.1191 6.74666L14.1839 2.91163C13.5004 2.5337 12.7506 2.34468 12.0008 2.34468V2.34468ZM9.39402 2.07394H9.39445H9.39402ZM15.0646 23.194L23.1252 18.6741C23.5663 18.4268 23.7321 17.8523 23.4955 17.3911C23.2589 16.9299 22.7095 16.7566 22.2683 17.0039L14.2077 21.5238C12.8259 22.2988 11.176 22.2988 9.7905 21.5219L1.72997 17.0494C1.28768 16.804 0.739031 16.9799 0.504411 17.4422C0.269704 17.9045 0.43791 18.4781 0.880115 18.7234L8.93708 23.194C9.89642 23.732 10.9485 24.0009 12.0009 24.0009C13.053 24.0009 14.1055 23.7319 15.0646 23.194V23.194ZM15.0646 18.9297L23.1252 14.4098C23.5663 14.1625 23.7321 13.588 23.4955 13.1268C23.2589 12.6656 22.7095 12.4923 22.2683 12.7396L14.2077 17.2595C12.8259 18.0345 11.176 18.0344 9.7905 17.2576L1.72997 12.7851C1.28768 12.5397 0.739031 12.7156 0.504411 13.1779C0.269704 13.6402 0.43791 14.2138 0.880115 14.4591L8.93708 18.9297C9.89642 19.4676 10.9485 19.7366 12.0009 19.7366C13.053 19.7366 14.1055 19.4676 15.0646 18.9297V18.9297Z"
      fill="#ffffff"
    />
  </svg>
)

export default Icon
