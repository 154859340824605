import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import {SaveSearchesOperations} from '../../../redux/savesearches'
import * as FiltersUtils from '../../../utils/filters'

const mapStateToProps = (state) => {

  return {
    data: state.saveSearches.data,
    total: state.saveSearches.data ? state.saveSearches.data.length: 999,
    isFetching: state.saveSearches.isFetching,
    saveSearchData: state.saveSearches.data,
    searchFilters: state.saveSearches.filters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteSaveSearches: (ids, module) => dispatch(SaveSearchesOperations.deleteSaveSearches(ids, module)),
    fetchSaveSearch: (id, module) => dispatch(SaveSearchesOperations.fetchSaveSearch(id, module)),
    setFilters: (filters, title) => dispatch(SaveSearchesOperations.setFilters(filters, title)),
    setAuxFilters: (filters) => dispatch(SaveSearchesOperations.setAuxFilters(filters)),
    parseFiltersFromApiToFrontFormat: (filtersInApiFormat) => dispatch(FiltersUtils.parseFiltersFromApiToFrontFormat(filtersInApiFormat))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n
)

export default enharce(View)
