import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import {MODULES, QUERY_KEYS} from "../../config/constants";

async function getInboxHeaders({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  const [_key, {module}] = queryKey;
  if (!module || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;
  return await get(`/api/module/${module}/inbox_fields`)
}

export default function useInboxFields(module) {
  return useQuery([`${module}:${QUERY_KEYS.FIELDS}`, {module}], getInboxHeaders)
}