import React, {useEffect, useMemo, useState} from 'react'
import GaugeStatisticStyled from './styled'
import { ReactComponent as UserIcon } from '../../../assets/images/stats.svg'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { useTheme } from '@material-ui/core'

const GaugeStatistic = ({title = null, overrideTitle = null, data = null, unit = ''}) => {
  const { primary } = useTheme();

  const [needleValue, setNeedleValue] = useState(0);
  const [needleLabel, setNeedleLabel] = useState(0);

  const [realMaxValue, setRealMaxValue] = useState(0);

  const [minValue, setMinValue] = useState(0);
  const [minLabel, setMinLabel] = useState(0);

  const [maxValue, setMaxValue] = useState(100);
  const [maxLabel, setMaxLabel] = useState(100);

  const [renderData, setRenderData] = useState([]);

  useEffect(() => {
    let min = data && !isNaN(data.min) ? parseInt(data.min) : 0;
    let max = data && !isNaN(data.max) ? parseInt(data.max) : 100;
    let value = data && !isNaN(data.value) ? parseInt(data.value) : 0;

    setRealMaxValue(max);
    if (min === 0 && max === 0) {
      max = 1;
    }

    setMinLabel(min);
    setNeedleLabel(value);

    // Ajustamos el valor para que sea entre 0 y (max - min) cuando hay valores negativos
    setMaxValue(max - min);
    setNeedleValue(value - min);

    let dataObj = [
      { min, max, value: value - min },
      { min, max, value: (max - min) - (value - min) },
    ];

    setRenderData(dataObj);
  }, [data]);

  const RADIAN = Math.PI / 180;

  const cx = 250;
  const cy = 175;
  const iR = 50;
  const oR = 100;
  const width = 300;
  const height = 300;

  const formatDecimal = (value) => {
    if(unit === '' && !isNaN(value)) {
      try {
        value = Intl.NumberFormat("es-ES").format(value);
      } catch(e) {
        console.warn(`Error al procesar valor ${value}`)
      }
    }

    return value;
  }

  const needle = (cx, cy, iR, oR) => {
    const ang = 180.0 * (1 - needleValue / maxValue);
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const mx = cx + (oR + width * 0.03) * cos;
    const my = cy + (oR + width * 0.03) * sin;

    // Ajustamos el centro de la aguja
    const nx = cx + ((oR + width * 0.1) * cos);
    const ny = cy + ((oR + width * 0.05) * sin);


    const nw = 4;
    // Sumamos 4 y 2 para compensar el ancho de la aguja
    return (
      <g>
        <path d={`M${cx+nw},${cy+nw}L${mx+nw},${my+nw}`} strokeWidth="5" stroke="#444" fill="none" strokeLinecap="round"/>
        <circle cx={cx+4} cy={cy+4} r={width * 0.03} fill={primary} stroke="#444" strokeWidth={nw}/>
        <text x={nx+(nw/2)} y={ny} textAnchor="middle" className="value-percent">
          {formatDecimal(needleLabel) + unit}
        </text>
      </g>
    )
  };

  const chart = useMemo(
    () => {
        return <div className="chart-wrapper" style={{width: '100%', height: height}}>
          <ResponsiveContainer width={512} height="100%">
            <PieChart className="gauge-chart">
              <text x={cx-75} y={cy+20} textAnchor="middle" dominantBaseline="middle">
                {formatDecimal(minLabel)} {unit}
              </text>
              <text x={cx+75} y={cy+20} textAnchor="middle" dominantBaseline="middle">
                {formatDecimal(realMaxValue)} {unit}
              </text>

              <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={renderData}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                fill="#8884d8"
                stroke="none"
              >
                {renderData.map((entry, index) => {
                  return <Cell key={`cell-${index}`} fill={index === 0 ? primary : 'rgba(150, 150, 150, 0.5)'}/>
                })}
              </Pie>
              {needle(cx, cy, iR, oR)}
            </PieChart>
          </ResponsiveContainer>
        </div>
    }, [renderData]);

  return (
    <GaugeStatisticStyled>
      {overrideTitle}
      {!overrideTitle && title &&
      <div className="header-stat">
          {<UserIcon />}
          <p>{title}</p>
        </div>
      }
      {chart}
    </GaugeStatisticStyled>
  )
}

export default GaugeStatistic
