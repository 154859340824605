import {InboxActions} from './'
import _ from 'lodash'
import 'core-js'
import * as FavoritesActions from '../favorites/actions'
import {MODULES} from "../../config/constants";

export const initInbox = () => (dispatch, getState, { api }) => {
  dispatch(InboxActions.setIsFetching(true))
  Promise.all([
    api.getStatusTypes(), 
    api.getReportFields(),
  ],
  )
  .then(([status, reportFields]) => {
    dispatch(InboxActions.setStatusTypes(status))
    dispatch(InboxActions.setReportFields(reportFields))
  })
  .finally(() => {
    dispatch(InboxActions.setIsFetching(false))
  })
}

export const fetchInboxFilters = (module) => (dispatch, getState, { api, handleError }) => {
  dispatch(InboxActions.setIsFetching(true))

  return api
    .getInboxFilters(module)
    .then(reponses => {
      dispatch(InboxActions.setInboxFilters(reponses))
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(InboxActions.setIsFetching(false)))
}

export const fetchInboxData = (range, sort) => (dispatch, getState, { api, handleError }) => {
  dispatch(InboxActions.setIsFetching(true))

  Promise.all([api.getInboxData(range, sort), api.getInboxHeaders()])
    .then(reponses => {
      dispatch(InboxActions.setData(reponses[0].data, reponses[0].length, reponses[0].total))
      dispatch(InboxActions.setHeaders(reponses[1], reponses[1].length))
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(InboxActions.setIsFetching(false)))
}

export const fetchInboxFields = () => (dispatch, getState, { api, handleError }) => {
    dispatch(InboxActions.setIsFetching(true))

    return Promise.all([api.getInboxFilters()])
        .then(reponses => {
            dispatch(InboxActions.setInboxFilters(reponses[0]))
        })
        .catch(error => {
            dispatch(handleError(error))
            throw error
        })
        .finally(() => dispatch(InboxActions.setIsFetching(false)))
}

export const changeStatus = (ids, status, date, module) => (dispatch, getState, { api, handleError }) => {
  const newStatus = _.map(ids, id =>
    {return { id, status_id: status, date }}
  )

  dispatch(InboxActions.setIsFetching(true))

  return api
    .changeStatus(newStatus, module)
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(InboxActions.setIsFetching(false)))
}

export const changeTags = (ids, tags, date, module) => (dispatch, getState, { api, handleError }) => {
  dispatch(InboxActions.setIsFetching(true))

  return api
    .changeTags(ids, tags, module)
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(InboxActions.setIsFetching(false)))
}

export const deleteCandidature = (candidatureId, module) => (dispatch, getState, { api, handleError }) => {
  dispatch(InboxActions.setIsFetching(true))

  return api
    .deleteInboxCandidature(candidatureId, module)
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(InboxActions.setIsFetching(false)))
}

export const postFavorites = ids => (dispatch, getState, { api, handleError }) => {
  dispatch(FavoritesActions.setIsFetching(true))

  // optimistic update
  /*
  const { inboxData } = getState().inbox
  const { data } = inboxData
  const newData = _.map(data, (item) =>{
    const itemToChange = _.find(ids, { id: item.id })
    return itemToChange?{itemToChange, is_favorite: true}:item
  })
  dispatch(InboxActions.setData(newData, inboxData.length, inboxData.total))
  */
  return api
    .postFavorites(ids)
    .then(() => dispatch(fetchInboxData()))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(FavoritesActions.setIsFetching(false)))
}

export const deleteFavorites = ids => (dispatch, getState, { api, handleError }) => {
  dispatch(FavoritesActions.setIsFetching(true))

  // optimistic update
  /*
  const { inboxData } = getState().inbox
  const { data } = inboxData
  const newData = _.map(data, (item) =>{
    const itemToChange = _.find(ids, { id: item.id })
    return itemToChange?{itemToChange, is_favorite: false}:item
  })
  dispatch(InboxActions.setData(newData, inboxData.length, inboxData.total))
  */
  return api
    .deleteFavorites(ids)
    .then(() => dispatch(fetchInboxData()))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(FavoritesActions.setIsFetching(false)))
}

export const updateInterviewerNotes = ( id, note, module = MODULES.ID_TALENT_ACQUISITION) => (dispatch, getState, { api }) => {
  return api.updateInterviewerNotes({"id": id,"note": note, "module": module});
}
