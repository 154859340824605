import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import BoxVideoInterviewStyled from './styled'
import { CalendarIconEvaluativeTests, CustomAlert } from '../../atoms'
import { Chip } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import ReactPlayer from 'react-player'
import RegularButtonOutline from '../../atoms/regular-button-outline'
import moment from 'moment'
import { changeVideoInterviewStatus } from '../../../api/index'
import { useRegenerateUrl } from '../../../api/queries/useRegenerateUrl'
import { useAppContext } from '../../../context/appContext'
import Tooltip from '@material-ui/core/Tooltip'
import { PlayCircleOutline, VideocamOff } from '@material-ui/icons'

const BoxVideoInterview = ({ i18n, evaluativeConfig, profileData }) => {
  const { module } = useAppContext()

  const statusOptions = [
    { id: 1, name: `${i18n.evaluativeConfig.pending}`, status: 'pending' },
    { id: 2, name: `${i18n.evaluativeConfig.in_review}`, status: 'in_review' },
    { id: 3, name: `${i18n.evaluativeConfig.suitable_in_other_process}`, status: 'suitable_in_other_process' },
    { id: 4, name: `${i18n.evaluativeConfig.suitable}`, status: 'suitable' },
    { id: 5, name: `${i18n.evaluativeConfig.not_suitable}`, status: 'not_suitable' },
    { id: 6, name: `${i18n.evaluativeConfig.resignation}`, status: 'resignation' },
    { id: 7, name: `${i18n.evaluativeConfig.no_show}`, status: 'no_show' }
  ]

  const [value, setValue] = useState(statusOptions.find(option => option.status === evaluativeConfig.status) || statusOptions[0])
  const [isRegenerateURlSuccess, setIsRegenerateURlSuccess] = useState(null)
  const [selectedQuestion, setSelectedQuestion] = useState(evaluativeConfig && evaluativeConfig.questions ? evaluativeConfig.questions[0] : null)
  const [isChangeStatusSuccess, setIsChangeStatusSuccess] = useState(false)

  const formattedStartDate = moment.utc(evaluativeConfig.init_date).format('DD/MM/YYYY')
  const formattedEndDate = moment.utc(evaluativeConfig.end_date).format('DD/MM/YYYY')

  const { isError, refetch } = useRegenerateUrl(module, evaluativeConfig.id, profileData.id)

  useEffect(() => {
    if (isChangeStatusSuccess || isRegenerateURlSuccess) {
      const timeoutId = setTimeout(() => {
        setIsChangeStatusSuccess(false)
        setIsRegenerateURlSuccess(false)
      }, 3000)

      return () => clearTimeout(timeoutId)
    }
  }, [isChangeStatusSuccess, isRegenerateURlSuccess])

  const handleChangeStatus = async (event, newValue) => {
    try {
      await changeVideoInterviewStatus({
        id: evaluativeConfig.id,
        status: newValue.status,
        candidateId: profileData.id
      })
      setValue(newValue)
      setIsChangeStatusSuccess(true)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error al cambiar el estado de la videoentrevista', error)
    }
  }

  const handleRegenerateUrl = async () => {
    try {
      await refetch()
      setIsRegenerateURlSuccess(true)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error al regenerar la url de la videoentrevista', error)
    }
  }

  return (
    <>
      <BoxVideoInterviewStyled>
        <Grid className="boxInfo" container justifyContent="space-between">
          <Grid container className="header-box">
            <Grid item xs={4} sm={4} className="header-text">
              <div className="header-calendar">
                <CalendarIconEvaluativeTests />
                <span>{`${formattedStartDate} - ${formattedEndDate}`}</span>
              </div>
              <p>{evaluativeConfig.name} {!evaluativeConfig.active ? <b className="evaluative-config-disabled">{i18n.evaluativeTests.inactive}</b> :''}</p>
            </Grid>
            {evaluativeConfig.active && <Grid item xs={8} sm={8} container alignItems="center" justifyContent="flex-end">
              <Grid item className="header-text-button">
                <RegularButtonOutline title={i18n.evaluativeTests.buttonRegenerateUrl} onClick={handleRegenerateUrl} />
              </Grid>
              <Grid item className="header-dropdown">
                <span>{i18n.evaluativeTests.statusVideoInterview}</span>
                <Autocomplete
                  size="small"
                  value={value}
                  options={statusOptions}
                  getOptionLabel={option => option.name}
                  style={{ width: 200 }}
                  renderInput={params => <TextField {...params} variant="outlined" />}
                  disableClearable={true}
                  onChange={handleChangeStatus}
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <div className="alert-container">
                  {isRegenerateURlSuccess && <CustomAlert className="custom-alert" severity="success" message={i18n.evaluativeTests.successRegenerateUrl} />}
                  {isChangeStatusSuccess && <CustomAlert severity="success" message={i18n.evaluativeTests.successChangeStatus} />}
                  {isError && <CustomAlert severity="error" message={i18n.evaluativeTests.errorRegenerateUrl} />}
                </div>
              </Grid>
            </Grid>}
          </Grid>
          {evaluativeConfig.status !== 'pending' && (
            <Grid className="wrapper-video" container>
              <div className="container-videos">
                <Grid item className="wrapper-questions">
                  {evaluativeConfig.questions.map((data, index) => (

                    <Grid
                      item
                      key={index}
                      onClick={() => setSelectedQuestion(data)}
                      className={`container-item-video ${selectedQuestion === data ? 'active' : ''}`}
                    >
                      <Grid item className="wrapper-video-question">
                        <Grid item className="content-video">
                          <Grid item className="content-text">
                            <Grid container alignItems="center">
                              <div style={{ marginRight: '1rem' }}>
                                {data.video ? <PlayCircleOutline /> : <VideocamOff />}
                              </div>
                              <Chip label={`${index + 1}° ${i18n.evaluativeTests.titleQuestion}`} className="chip-custom" />
                            </Grid>
                            {data.text.length > 180 ? (
                              <Tooltip title={data.text} arrow>
                                <p>{data.text}</p>
                              </Tooltip>
                            ) : (
                              <p>{data.text}</p>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </div>

              <Grid item container xs={6} sm={6} lg={6} className="container-video-player" justifyContent="center">
                {!selectedQuestion.video && (
                  <div className="custom-message">
                    <CustomAlert severity="error" message={i18n.evaluativeTests.infoUploadVideo} />
                  </div>
                )}
                <ReactPlayer className="custom-video-player" controls url={selectedQuestion.video} config={{
                  file: {
                    forceVideo: true
                  }
                }} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </BoxVideoInterviewStyled>
    </>
  )
}

export default BoxVideoInterview
