import React from 'react'
import PropTypes from 'prop-types'
import {ReportsTable} from '../../organism'

const Reports = ({ classes }) => {

  return (<div className={classes.root}>
       <ReportsTable/>
     </div>)
}

Reports.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default Reports
