import React, {Component} from 'react'
import {AppBarInbox, ProcessTable, ProfileFeedbackInfo} from '../../organism'
import {MODULES} from '../../../config/constants'

class InboxPeople extends Component {
  render() {
    const { classes, filters, i18n } = this.props
    return (
      <div className={classes.root}>
        <AppBarInbox 
          showActions
          filterName={i18n.feedback.filter}
          module={MODULES.ID_FEEDBACK}
          section={"feedback"}
        />
        <ProcessTable 
          title={i18n.feedback.title}
          filters={filters} 
          module={MODULES.ID_FEEDBACK} 
          showChangeStatus
          rowViewRender={(id) => <ProfileFeedbackInfo id={id} />}
          notificationMultipleEnabled={true}
        />
      </div>
    )
  }
}

export default InboxPeople
