import React, {useEffect, useState} from "react";
import DragM from "dragm";
import { Modal } from "antd";
import "antd/dist/antd.css";
import {useDispatch} from "react-redux";
import {InboxOperations} from "../../../redux/inbox";
import ButtonWithIcon from "../../atoms/button-with-icon";
import i18n from '../../../assets/i18n'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ModalDraggableStyled} from "./styled";
import {split} from "lodash";
import moment from "moment";
import {manageQueriesWithPrefix, refetchQueriesByKeys} from "../../../api/mutations/mutationCallbacks";
import {QUERY_ACTIONS, QUERY_KEYS} from "../../../config/constants";
import {updateInterviewerNotes} from "../../../api";


const BuildTitle = ({ modalDom, title = '' }) => {


  const updateTransform = transformStr => {
    modalDom = document.getElementsByClassName("ant-modal-wrap" //modal的class是ant-modal-wrap
    )[0];
    modalDom.style.transform = transformStr;
  };

  useEffect( () => {
    // eslint-disable-next-line
    modalDom = document.getElementsByClassName("ant-modal-wrap" //modal的class是ant-modal-wrap
    )[0];

  }, []);

    return (
      <DragM updateTransform={ updateTransform }>
        <div>{ title }</div>
      </DragM>
    );

};

const DraggableModal = ({ id, interviewerNote = '', titleTab, setInterviewerNote, user, module = null, slug = null, className = null }) => {
  const dispatch = useDispatch();

  const [ visible, setVisible ] = useState( false );
  const [ auxNote, setAuxNote ] = useState( split(interviewerNote, '-*{}*-')[0]);

  const showModal = ( e ) => {

    e.preventDefault();
    setVisible( true );

    setTimeout(() => {
    document.querySelector("body").style.overflow = 'auto'

    }, 300)

  };

  const handleOk = ( e ) => {
    e.preventDefault();
    let note = `${ auxNote }-*{}*-${ user.name }-*{}*-${ moment().format('LTS') }-*{}*-${ moment().format('L') }`;
    if (className) {
      updateInterviewerNotes({id, note, class: className}, module).then(() => {
        manageQueriesWithPrefix([`${QUERY_KEYS.PERSON}:${id}`], QUERY_ACTIONS.REFETCH);
      }).catch(() => {

      });
    } else {
      dispatch(InboxOperations.updateInterviewerNotes( id, note, module ));
    }

    setInterviewerNote(note);

    if (module && slug) {
      setTimeout(() => {
        refetchQueriesByKeys([`${module}:${QUERY_KEYS.PROFILE}:${slug}`]);
      }, 1500)
    }
    setVisible( false );
  };

  const  handleCancel = ( ) => {
    setVisible( false );
  };

    const title = <BuildTitle title={titleTab} />;


    return (
      <>
          <ButtonWithIcon title={i18n.interviewerNotes.edit} onClick={ showModal }  />
        <Modal
          style={{minWidth: "750px"}}
          mask={ false }
          okText={ i18n.dialog.save }
          cancelText={ i18n.dialog.decline }
          title={ title }
          visible={ visible }
          onOk={ handleOk }
          onCancel={ handleCancel }
        >
          <ModalDraggableStyled>
            <CKEditor
              editor={ ClassicEditor }
              config={{
                toolbar: [ 'heading', '|', 'bold', 'italic','Underline', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList' ]
              } }
              data={ auxNote }
              onChange={ ( event, editor ) => {
                setAuxNote( editor.getData())
              } }
            />
          </ModalDraggableStyled>
        </Modal>
      </>
    );

}


export default DraggableModal;