import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import LinearPercentWithTitleStyled from './styled'
import LinearProgress from '@material-ui/core/LinearProgress'
import i18n from '../../../assets/i18n';


const LinearPercentWithTitle = ({ title, value }) => {
    if( title === "Vacio") return null;

    return (
        <LinearPercentWithTitleStyled>
            <p className="linearPercentLine">
                <div className='title-name'>
                    <p className='title'>{i18n.genericText[title] || title}</p>
                    <p className='value'>{value > 0 ? value.toFixed() : 0}%</p>
                </div>
                <LinearProgress value={value} variant="determinate" />
            </p>
        </LinearPercentWithTitleStyled>
    )
}

LinearPercentWithTitle.defaultProps = {
    value: 0
}

LinearPercentWithTitle.propTypes = {
    tile: PropTypes.string,
    value: PropTypes.number,
}

const enharce = compose()

export default enharce(LinearPercentWithTitle)