import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import { QUERY_KEYS } from '../../config/constants'

async function getTags() {
  return await get(`api/tags`);
}

export function useTags() {
  return useQuery([`${QUERY_KEYS.TAGS}`], getTags)
}