import {createTheme} from '@material-ui/core/styles'
import colors from '../assets/colors'
import _ from 'lodash'

export const clientMaterialTheme = client => {
  const clientBackground = _.get(client, 'background_color', colors.blue)
  const clientColorMain = _.get(client, 'text_color_principal', colors.white)
  const clientColorSecondary = _.get(client, 'text_color_secondary', colors.whiteAlpha50)

  return createTheme({
    colors,

    primary: clientBackground,
    textPrimary: clientColorMain,
    textSecondary: clientColorSecondary,

    fonts: {
      primary: 'Inter'
    },

    shadows: Array(25).fill('none'),

    constants: {
      MENU_WIDTH: 80,
      DRAWER_WIDTH: 300
    },

    typography: {
      useNextVariants: true,
      fontFamily: ['Inter'].join(','),
      fontSize: 18
    },

    palette: {
      primary: {
        main: clientBackground,
        contrastText: clientColorMain
      },
      secondary: {
        main: clientBackground,
        contrastText: colors.white
      }
    },

    overrides: {
      MuiButton: {
        root: {
          height: 50,
          fontWeight: 'bold',
          fontSize: 14,
          textTransform: 'unset'
        }
      },
      MuiTypography: {
        h1: {
          color: colors.black,
          fontWeight: 600,
          fontSize: 28,
          display: 'inline'
        },
        caption: {
          fontSize: 'inherit',
          fontFamily: 'inherit',
          lineHeight: 'inherit',
          fontWeight: 'inherit',
        }
      },
      MuiTable: {
        root: {}
      },
      MuiTableRow: {
        root: {
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgb(248 249 249) !important',
          },
        },
        footer: {
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        },
        head: {
          '&:hover': {
            boxShadow: 'none !important',
            cursor: 'inherit',
            backgroundColor: `rgb(235 237 238) !important`,
          },
          '& th:hover': {
            backgroundColor: 'rgb(248 249 249) !important',
          },
        }
      },
      MuiTableBody: {
        root: {
          '& tr:first-child td': {
            padding: '25px 16px 16px 16px'
          },
        }
      },
      MuiDialogContent: {
        root: {
          fontSize: 18,
        }
      },
      MuiTableHead: {
        root: {
          borderTop: '1px solid rgb(216 216 217)',
          borderBottom: '1px solid rgb(216 216 217)',
          textAlign: 'center',
        }
      },
      MuiTableCell: {
        head: {
          paddingRight: '0px',
          color: '#666',
          // backgroundColor: 'transparent !important',
          backgroundColor: `rgb(235 237 238) !important`,
          textAlign: 'center !important',
          fontSize: 12,
        },
        body: {
          textAlign: 'center',
        },
        footer: {
          fontSize: '12px !important',
          color: '#666 !important',
          backgroundColor: `rgb(235 237 238) !important`,
          borderBottom: '1px solid rgb(216 216 217)',
          fontWeight: 'bold'
        }
      },
      MuiTableSortLabel: {
        root: {
          color: '#181A1C',
          fontWeight: 700,
          fontSize: 12,

          '&$active': {
            color: '#666',
          },
          '&:hover': {
            color: '#666',
          },
          '&:focus': {
            color: '#666',
          }
        },
        icon: {
          color: `${clientBackground} !important`,
        }
      },
      MuiTablePagination:{
        input: {
          background: '#f3f3f3',
          border: '1px solid #d8d8d9'
        }
      },
      MuiLinearProgress: {
        root: {
          '&.MuiLinearProgress-indeterminate': {
            width: '100%',
            position: 'fixed',
            left: '80px',
            top: '0px',
          }
        }
      },
      MuiTooltip: {
        tooltip: {
          opacity: 0.95,
          fontSize: 12,
          fontFamily: 'Poppins',
          fontWeight: 400,
          color: colors.black,
          backgroundColor: '#fff',
          border: "1px solid " + clientBackground ,
          padding: "10px",
        }
      }
    }
  })
}

export default clientMaterialTheme()
