import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import { DrawerListItem } from '../'
import React from "react";

export const MenuContainer = styled(props => <Menu {...props}/>)`
  .MuiMenu-paper {
    // border: 1px solid #666;
  }
  .MuiList-root {
    // padding-top: 0; 
    // padding-bottom: 0;
  }
`
export const Item = styled(props => <DrawerListItem {...props}/>)`
  & svg {
    fill: ${({ theme }) => theme.colors.grey};
  }
  & span {
    &.MuiTouchRipple-root {
      ${props => props.backgroundColorRow ? '' +
        'border-right: 5px solid ' + props.backgroundColorRow+';' +
        'background-color: ' + props.backgroundColorRow+'0c;' +
        'border-bottom: 1px dotted ' + props.theme.colors.grey + ';' 
      : ''};
    }
    
    color: #303030;
    
    div { 
      text-align: left;
    }
  }
`
