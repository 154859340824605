import React, { useEffect, useState } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import {
  ModalHeaderProcess,
  RegularButton,
  RegularButtonOutline,
  InputField,
  CustomAlert,
  ColorPicker
} from '../../atoms'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useMutation } from "@tanstack/react-query";
import {patchTag, postTag} from '../../../api/index';
import { CircularProgress } from '@material-ui/core'
import {useTheme} from "@material-ui/styles";

const customStyles = {
  overflow: 'scroll'
}

const ModalCreateTag = ({ open, handleClose, i18n, isCreate, editingTag, onDataUpdate, moduleOptions }) => {
  const theme = useTheme()

  const [tagName, setTagName] = useState('');
  const [tagDescription, setTagDescription] = useState('');
  const [tagColor, setTagColor] = useState('');
  const [tagModule, setTagModule] = useState('');
  const [matchingMin, setMatchingMin] = useState(null);
  const [matchingMax, setMatchingMax] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [isDisableButton, setIsDisableButton] = useState(false);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
      resetModalValues();
    }
  }, [open]);

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  const handleChangeSelectedModule = (value) => {
    setTagModule(value);
  }

  const createTag = async (data) => {
    try {
      const response = await postTag(data);
      return response.data;
    } catch (error) {
      console.error("Error while creating/updating tag:", error);
      setIsDisableButton(true);
      setAlert({ message: i18n.tag_management.errorCreateTag, severity: 'error' });
      throw error;
    }
  }

  const udpateTag = async (data) => {
    try {
      const response = await patchTag(data);
      return response.data;
    } catch (error) {
      setIsDisableButton(true);
      setAlert({ message: i18n.tag_management.errorUpdateTag, severity: 'error' });
      throw error;
    }
  }

  const mutationCreateTag = useMutation(createTag);
  const mutationUpdateTag = useMutation(udpateTag);

  const handleCreateTag = async (e) => {
    e.preventDefault();

    if (tagName.trim() === '') {
      setAlert({ message: i18n.tag_management.nameRequired, severity: 'info' });
      return;
    }

    if (tagDescription.trim() === '') {
      setAlert({ message: i18n.tag_management.descriptionRequired, severity: 'info' });
      return;
    }

    if (!tagModule || (typeof tagModule === 'object' && Object.keys(tagModule).length === 0)) {
      setAlert({ message: i18n.tag_management.moduleRequired, severity: 'info' });
      return;
    }

    const tagModuleId = tagModule.id;

    try {
      let data;
      if (editingTag?.id) {
        data = {
          id: editingTag.id,
          name: tagName,
          description: tagDescription,
          color: tagColor ? tagColor : theme.primary,
          module: tagModuleId,
          matching_min: matchingMin,
          matching_max: matchingMax,
        };
        await mutationUpdateTag.mutateAsync(data);

        setAlert({ message: i18n.tag_management.editedTag, severity: 'success' });
        setShowAlert(true);
        onDataUpdate();

      } else {
        data = {
          name: tagName,
          description: tagDescription,
          color: tagColor ? tagColor : theme.primary,
          module: tagModuleId,
          matching_min: matchingMin,
          matching_max: matchingMax,
        };
        await mutationCreateTag.mutateAsync(data);
        setAlert({ message: i18n.tag_management.createdTag, severity: 'success' });
        setShowAlert(true);
        onDataUpdate();

      }
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.error("Error while creating/updating tag:", error);
    }
  };

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    if (fieldName === 'name') {
      setTagName(value);
    } else if (fieldName === 'description') {
      setTagDescription(value);
    } else if (fieldName === 'matching_min') {
      setMatchingMin(value);
    } else if (fieldName === 'matching_max') {
      setMatchingMax(value);
    }
  }

  const handleColorChange = (e) => {
    if (e && e.hex) setTagColor(e.hex);
  }

  useEffect(() => {
    if (!isCreate && editingTag) {
      setTagName(editingTag.name || '');
      setTagDescription(editingTag.description || '');
      setTagColor(editingTag.color || '');
      const moduleOption = moduleOptions.find(option => option.id === editingTag.module);
      setTagModule(moduleOption || '');
      setMatchingMin(editingTag.matching_min);
      setMatchingMax(editingTag.matching_max);
    }
  }, [isCreate, editingTag]);

  const resetModalValues = () => {
    if (isOpen && isCreate) {
      setTagName('');
      setTagDescription('');
      setTagColor('');
      setTagModule('');
      setMatchingMin(null);
      setMatchingMax(null);
    }
  }

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => {
          handleClose();
          resetModalValues();
        }}
      >
        <ModalStyled>
          <ModalHeaderProcess title={isCreate ? i18n.tag_management.modalTitleCreate : i18n.tag_management.modalTitleUpdate} closeClick={() => handleClose()} hasCloseIcon={true} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12}>
                  <label className='required'>{i18n.tag_management.labelName}</label>
                  <InputField placeholder={i18n.tag_management.placeholderName}
                    value={tagName}
                    onChange={(e) => handleInputChange(e, "name")} />
                  <label className='required'>{i18n.tag_management.labelDescription}</label>
                  <InputField placeholder={i18n.tag_management.placeholderDescription}
                    value={tagDescription}
                    onChange={(e) => handleInputChange(e, "description")}
                  />
                  <label className='required'>{i18n.tag_management.labelColor}</label>
                  <ColorPicker onChange={handleColorChange} color={tagColor ? tagColor : theme.primary} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className='required'>{i18n.tag_management.labelModule}</label>
                  <Autocomplete
                    className='autocomplete-custom'
                    value={tagModule}
                    options={moduleOptions}
                    onChange={(event, value) => handleChangeSelectedModule(value)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={i18n.tag_management.placeholderModule} variant="outlined" value={tagModule ? tagModule.name : ''} />
                    )}
                    sx={{ width: '500px' }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <label>{i18n.tag_management.labelMatchingMin}</label>
                    <InputField placeholder={i18n.tag_management.placeholderMatchingMin}
                      value={matchingMin}
                      onChange={(e) => handleInputChange(e, "matching_min")}
                    />
                    <label>{i18n.tag_management.labelMatchingMax}</label>
                    <InputField placeholder={i18n.tag_management.placeholderMatchingMax}
                      value={matchingMax}
                      onChange={(e) => handleInputChange(e, "matching_max")}
                    />
                </Grid>
              </Grid>

              <div className="button-wrapper">
                {showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
                {mutationCreateTag.isLoading && <CircularProgress />}
                {mutationUpdateTag.isLoading && <CircularProgress />}
                <RegularButtonOutline title={i18n.tag_management.buttonCancel} handleOnClick={handleClose} />
                <RegularButton className="button-custom" title={isCreate ? i18n.tag_management.buttonCreateTag : i18n.tag_management.buttonUpdateTag} onClick={handleCreateTag} disabled={isDisableButton} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(ModalCreateTag)