import React from 'react'
import PropTypes from 'prop-types'
import {AppBarInbox, ExitInterviewStatisticsItems} from '../../organism'
import { MODULES } from '../../../config/constants'
import eventTracker from '../../../utils/eventTracker';

const Statistics = ({ classes }) => {
  eventTracker.track('EIStatisticsView', {
    url: document.location.href,
  })
  return (
    <div className={classes.root}>
        <AppBarInbox
            module={MODULES.ID_EXIT_INTERVIEW}
            section={"statistic"}
            counter={false}
        />
      <ExitInterviewStatisticsItems module={MODULES.ID_EXIT_INTERVIEW} />
    </div>
  )
}

Statistics.propTypes = {
  classes: PropTypes.object.isRequired
}

export default Statistics
