import React, { useEffect, useState } from 'react'
import { StatesStyled } from './styled'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import { InputField, RegularButtonOutline, RichTextEditor, ColorPicker, IconWarning } from '../../atoms'
import { DndContext, closestCenter } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { ListItem } from '../../molecules'
import Select from 'react-select'
import { CustomAlert } from '../../atoms'
import { useMutation } from '@tanstack/react-query'
import usePhases from '../../../api/queries/usePhasesProcesses'
import { useAppContext } from '../../../context/appContext'
import { postState, patchState, patchStateOrder, deleteState } from '../../../api'
import { onMutatePhaseOrState } from '../../../api/mutations/mutationCallbacks'
import { PHASES_STATUS_TYPES } from '../../../config/constants'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'

const Status = ({ i18n, allPhasesData }) => {
  const { module } = useAppContext()
  const [selectedPhase, setSelectedPhase] = useState(null)
  const [userValuesState, setUserValuesState] = useState([])
  const [stateName, setStateName] = useState('')
  const [alert, setAlert] = useState({ message: '', severity: '' })
  const [saveChangesAlert, setSaveChangesAlert] = useState({ message: '', severity: '' });
  const [hasChanges, setHasChanges] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showChangesAlert, setShowChangesAlert] = useState(false)
  const [descriptionStatus, setDescriptionStatus] = useState(i18n.phases_states.feedbackMessageDefaultCustom);
  const defaultColor = '#1976d2';
  const [selectedSwatchColor, setSelectedSwatchColor] = useState(defaultColor);
  const [checkedSwitch, setCheckedSwitch] = useState(false);
  const [smsChecked, setSmsChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [whatsappChecked, setWhatsappChecked] = useState(false);
  const { data: phasesData, refetch: refetchPhases } = usePhases(module, data => data)
  const statusData = phasesData.data.find(state => {
    return state.id == selectedPhase;
  })?.status_candidates ?? [];

  const handlePhaseSelect = selectedOption => {
    setSelectedPhase(selectedOption.id)
    setHasChanges(false);
  }

  useEffect(() => {
    if (statusData) {
      if (JSON.stringify(userValuesState) !== JSON.stringify(statusData)) {
        setUserValuesState(statusData)
      }
    }
  }, [statusData])

  const createState = async order => {
    if (stateName) {
      let selectedNotificationMethods = [];

      if(checkedSwitch) {
        if (smsChecked) {
          selectedNotificationMethods.push('sms');
        }

        if (emailChecked) {
          selectedNotificationMethods.push('email');
        }

        if (whatsappChecked) {
          selectedNotificationMethods.push('whatsapp');
        }
      }

      const data = {
        name: stateName,
        type: PHASES_STATUS_TYPES.CUSTOM,
        phase: selectedPhase,
        order: order,
        color: selectedSwatchColor ? selectedSwatchColor : defaultColor,
        description: descriptionStatus,
        notification_methods: selectedNotificationMethods
      }
      const response = await postState(selectedPhase, data, module)

      return response.data
    }
  }

  const editState = async (id, phaseId, updatedData) => {
    const response = await patchState(id, phaseId, updatedData, module)
    return response.data
  }

  const onSubmitEditState = async updatedState => {
    const { id, name, phaseId, color, description, changedPhase, notification_methods } = updatedState
    try {
      await mutationEditState.mutateAsync({ id, name, phaseId, color, description, changedPhase, notification_methods }, {
        onSuccess: () => {
          onMutatePhaseOrState(module, [], 'mutationEditState');
        }
      })

      const updatedUserValuesState = userValuesState.map(state => (state.id === id ? { ...state, name, color, description, changedPhase, notification_methods } : state))
      setUserValuesState(updatedUserValuesState)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error al editar la fase:', error)
    }
  }

  const onSubmitDeleteState = async updatedState => {
    const { id, phaseId } = updatedState;
    const toReturnResponse = {
      hasBeenDeleted: false,
    };

    try {
      const response = await mutationDeleteState.mutateAsync({ id, phaseId }, {
        onSuccess: () => {
          onMutatePhaseOrState(module, [], 'mutationDeleteState');
        }
      })
      if (response.result === false) {
        Object.assign(toReturnResponse, {
          hasBeenDeleted: false,
          usedByCandidatures: response.total,
        });
      } else {
        toReturnResponse.hasBeenDeleted = true;
      }
      return toReturnResponse;
    } catch (error) {
      return Object.assign(toReturnResponse, { error: error });
    }
  }

  const updateStateOrder = async updatedOrder => {
    const { data, fullResponse } = await patchStateOrder(updatedOrder)
    if (fullResponse.status !== 200) {
      setSaveChangesAlert({ message: i18n.phases_states.errorOrderStatusUpdate, severity: 'error' })
      throw new Error('Network response was not ok')
    } else {
      setSaveChangesAlert({ message: i18n.phases_states.changesSavedSuccessfully, severity: 'success' })
    }

    return data
  }

  const handleSaveChanges = async () => {
    if (hasChanges) {
      const updatedOrder = userValuesState.map(({ id, order }) => ({ id, order }))
      mutationPatchOrder.mutate(updatedOrder, {
        onSuccess: () => {
          onMutatePhaseOrState(module, [], 'mutationPatchOrder');
        }
      })
    }
  }

  const mutationSubmitState = useMutation(createState)
  const mutationEditState = useMutation(updatedState => {
    const color = updatedState.color && updatedState.color.css ? updatedState.color.css.backgroundColor : updatedState.color;
    return editState(updatedState.id, updatedState.phaseId, {
      name: updatedState.name,
      color: color,
      description: updatedState.description.replace(new RegExp('\\r?\\n', 'g'), '<br />'),
      phase: updatedState.changedPhase,
      notification_methods: updatedState.notification_methods
    });
  });

  const mutationPatchOrder = useMutation(updateStateOrder)
  const mutationDeleteState = useMutation(deleteState)

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);

      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [alert]);


  useEffect(() => {
    if (saveChangesAlert.message) {
      setShowChangesAlert(true);

      const timer = setTimeout(() => {
        setShowChangesAlert(false);
        setSaveChangesAlert({ message: '', severity: '' });
        setHasChanges(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [saveChangesAlert]);

  const handleStateNameChange = e => {
    if (alert !== '') {
      setAlert('')
    }
    setStateName(e.target.value)
  }

  const handleAddState = async () => {
    if (!selectedPhase) {
      setAlert({ message: i18n.phases_states.alertSelectPhase, severity: 'info' });
      return;
    }

    if (stateName.trim() === '') {
      setAlert({ message: i18n.phases_states.alertStateNameRequired, severity: 'info' });
      return;
    }

    const cleanedDescription = descriptionStatus.replace(/<[^>]*>/g, '');

    if (cleanedDescription.trim() === '') {
      setAlert({ message: i18n.phases_states.alertMessageFeedback, severity: 'info' });
      return;
    }

    const selectedPhaseData = phasesData.data.find(phase => phase.id === selectedPhase);
    if (selectedPhaseData) {
      const existingState = selectedPhaseData.status_candidates.find(value => value.name === stateName);
      if (!existingState) {
        try {
          const newOrder = userValuesState.length + 1;

          const newStateData = await mutationSubmitState.mutateAsync(newOrder, {
            onSuccess: () => {
              onMutatePhaseOrState(module, [], 'mutationSubmitState');
            }
          });

          if (newStateData) {
            setUserValuesState(prevState => [...prevState, newStateData]);
            setAlert({ message: i18n.phases_states.alertCreateStatus, severity: 'success' });
            setStateName('');
            setSelectedSwatchColor(defaultColor);
            setCheckedSwitch(false);
          } else {
            // eslint-disable-next-line no-console
            console.error('mutationSubmitState.data is not defined correctly');
          }
        } catch (error) {
          setAlert({ message: i18n.phases_states.alertErrorCreateStatus, severity: 'error' });
        }
      } else {
        setAlert({ message: i18n.phases_states.alertStatusExists, severity: 'info' });
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('The selected phase was not found in phasesData');
    }
  };

  const handleDragEndState = event => {
    const { active, over } = event

    setUserValuesState(prevUserValues => {
      const oldIndex = prevUserValues.findIndex(state => state.id === active.id)
      const newIndex = prevUserValues.findIndex(state => state.id === over.id)

      if (oldIndex !== newIndex) {
        setHasChanges(true)
      }

      const updatedUserValuesState = arrayMove(userValuesState, oldIndex, newIndex)

      updatedUserValuesState.forEach((item, index) => {
        item.order = index + 2
      })

      const receivedStatus = updatedUserValuesState.find(status => status.type === PHASES_STATUS_TYPES.REGISTERED)
      const hiredStatus = updatedUserValuesState.find(status => status.type === PHASES_STATUS_TYPES.HIRED)

      if (receivedStatus) receivedStatus.order = 1
      if (hiredStatus) hiredStatus.order = 99

      return [...updatedUserValuesState]
    })
  }

  const handleColorChange = (newColor) => {
    setSelectedSwatchColor(newColor.hex);
  };

  const handleQuillChange = (value) => {
    setDescriptionStatus(value);
  }

  const handleChangeSwitch = (e) => {
    setCheckedSwitch(e.target.checked);
    if (!e.target.checked) {
      setSmsChecked(false);
      setEmailChecked(false);
      setWhatsappChecked(false);
    }
  };

  const handleRadioChange = (selectedOption) => {
    setSmsChecked(selectedOption === 'sms');
    setEmailChecked(selectedOption === 'email');
    setWhatsappChecked(selectedOption === 'whatsapp');
  };

  return (
    <StatesStyled>
      <Grid item className="sectionThird" xs={12} sm={12}>
        <h2>{i18n.phases_states.titleState}</h2>
        <p>{i18n.phases_states.descriptionState}</p>
        <p>{i18n.phases_states.descriptionTwoState}</p>

        <Grid container>
          <Grid item xs={12} sm={12}>
            <label>{i18n.phases_states.assignStateToPhase}</label>
            <Select
              className="selectPhase"
              options={allPhasesData}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder={i18n.phases_states.placeholderSelectPhase}
              onChange={handlePhaseSelect}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <label>{i18n.phases_states.labelState}</label>
            <InputField placeholder={i18n.phases_states.placeholderState} value={stateName} onChange={handleStateNameChange} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <label>{i18n.phases_states.labelColor}</label>
          <ColorPicker
            defaultColor={selectedSwatchColor}
            onChange={handleColorChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <label>{i18n.phases_states.feedbackMessage}</label>
          <RichTextEditor
            onChange={handleQuillChange}
            placeholder={i18n.phases_states.placeholderDescriptionStatus}
            value={descriptionStatus}
          />
        </Grid>

        <Grid item xs={12} sm={12} >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start" }}>
            <Switch
              checked={checkedSwitch}
              onChange={handleChangeSwitch}
              name="checkedA"
              inputProps={{ 'aria-label': 'switchA' }}
            />
            <span>{i18n.phases_states.questionNotified}</span>
          </Box>

          {checkedSwitch && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginLeft: '58px' }}>
                <span>{i18n.phases_states.selectNotificationMethods}</span>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '56px', gridGap: '5px', marginTop: '10px' }}>
                <IconWarning />
                <span>{i18n.phases_states.infoSelectNotificationMethods}</span>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start", marginLeft: '58px' }} >
                <FormControlLabel
                  className='optional'
                  control={<Radio checked={smsChecked} />}
                  label={i18n.phases_states.smsMethod}
                  onChange={() => handleRadioChange('sms')}
                />
                <FormControlLabel
                  className='optional'
                  control={<Radio checked={emailChecked} />}
                  label={i18n.phases_states.emailMethod}
                  onChange={() => handleRadioChange('email')}
                />
                <FormControlLabel
                  className='optional'
                  control={<Radio checked={whatsappChecked} />}
                  label={i18n.phases_states.whatsapMethod}
                  onChange={() => handleRadioChange('whatsapp')}
                />
              </Box>
            </>
          )}
        </Grid>

        <div className="containerButtonForm">
          {showAlert && !showChangesAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
          {mutationSubmitState.isLoading && <CircularProgress />}
          <RegularButtonOutline type="submit" title={i18n.phases_states.titleButtonState} onClick={handleAddState} />
        </div>

        <div className="containerDragAndDrop">
          <div className="containerDragAndDropList">
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEndState}>
              <SortableContext items={[...userValuesState]} strategy={verticalListSortingStrategy}>
                {selectedPhase && statusData && statusData.length === 0 && (
                  <CustomAlert severity="info" message={i18n.phases_states.alertThereAreNoStatusesInPhase} />
                )}
                {userValuesState &&
                  userValuesState.length > 0 &&
                  userValuesState.map(item => {
                    return (
                      <ListItem
                        i18n={i18n}
                        item={item}
                        key={item.id}
                        onSubmitEditState={onSubmitEditState}
                        onSubmitDelete={onSubmitDeleteState}
                        elementType="state"
                        phaseId={selectedPhase}
                        phasesData={phasesData}
                        module={module}
                      />
                    )
                  })}
              </SortableContext>
            </DndContext>
          </div>
        </div>

        <div className="containerButtonForm">
          {showChangesAlert && <CustomAlert severity={saveChangesAlert.severity} message={saveChangesAlert.message} />}
          {mutationPatchOrder.isLoading && <CircularProgress />}
          {hasChanges && <RegularButtonOutline type="submit" title={i18n.phases_states.buttonAllSave} onClick={handleSaveChanges} />}
        </div>
      </Grid>
    </StatesStyled>
  )
}

export default Status
