import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Grid from '@material-ui/core/Grid'
import ProfileButtonsStyled from './styled'
import {BackButton} from '../../atoms'
import ArrowBack from '@material-ui/icons/KeyboardArrowLeft'
import ArrowForward from '@material-ui/icons/KeyboardArrowRight'
import {MODULES} from '../../../config/constants'
import {useStore} from "react-redux";

const HeaderProfileButtons = ({
                                i18n,
                                match,
                                userId,
                                onChangeProfile,
                                dataInboxRows,
                                location,
                                total,
                                totalRQuery,
                                tablePage,
                                tableSetPage,
                                tableProcessFeching,
                                tablePerPage,
                                dataCitationRows,
                                citationPage,
                                citationPageSize,
                                citationSetPage,
                                citationIsFetching,
                                initPlanning,
                                fetchItemsInbox,
                                fetchCitationsData,
                                history,
                                client
                              }) => {
  const [id, setId] = useState(userId || _.get(match, 'params.slug'));
  const [loadingNextPage, setLoadingNextPage] = React.useState(false);
  const [loadingPreviousPage, setLoadingPreviousPage] = React.useState(false);
  const [currentTableRows, setCurrentTableRows] = React.useState([]);
  const [currentObjectResponse, setCurrentObjectResponse] = React.useState([]);
  const [currentItemNum, setCurrentItemNum] = React.useState(1);

  let store = useStore();

  useEffect(() => {
    onInit();
    // eslint-disable-next-line
  }, []);

  const handleOnChangePage = (newId) => {
    setId(newId);
    onChangeProfile(newId);
  }

  const pageGetCurrentIndexFromTable = (rows = currentTableRows) => {
    return _.findIndex(rows, {id});
  }

  const weAreInPlanningSection = () => {
    return location.pathname.search('talent_acquisition/planning') !== -1;
  }

  const refetchCitationsData = async () => {
    await initPlanning();
  };

  const onInit = () => {

    let currentPage = tablePage;
    let currentPerPage = tablePerPage;
    let currentIsProcessing = tableProcessFeching;
    let initCurrentTableRows;

    if (weAreInPlanningSection()) {
      currentPage = citationPage;
      currentPerPage = citationPageSize;
      currentIsProcessing = citationIsFetching;
    }

    // wait until data is loaded
    if (
      (loadingNextPage !== false && loadingNextPage !== currentPage) || currentIsProcessing
      || (loadingPreviousPage !== false && loadingPreviousPage !== currentPage) || currentIsProcessing
    ) {
      return false;
    }

    if (weAreInPlanningSection()) {
      if (_.isEmpty(dataCitationRows)) {
        refetchCitationsData().then(() => {
          setCurrentObjectResponse(dataCitationRows);
          setCurrentTableRows(dataCitationRows);
          initCurrentTableRows = dataCitationRows;
        });
      } else {
        setCurrentObjectResponse(dataCitationRows);
        // extract candidatures to use same behaviour
        initCurrentTableRows = dataCitationRows.data.map((row) => {
          row.candidature.itemCount = row.itemCount;
          return row.candidature;
        })
          setCurrentTableRows(initCurrentTableRows);
      }
    } else {
      setCurrentObjectResponse(dataInboxRows);
      initCurrentTableRows = dataInboxRows.data;
      setCurrentTableRows(dataInboxRows.data);
    }

    if (!id || !initCurrentTableRows) {
      return false;
    }

    if (loadingNextPage !== false && initCurrentTableRows[0].itemCount === ((loadingNextPage * currentPerPage) + 1)) {
      handleOnChangePage(initCurrentTableRows[0].id);
      setLoadingNextPage(false);
      return false;
    }

    if (loadingPreviousPage !== false && initCurrentTableRows[0].itemCount === ((loadingPreviousPage * currentPerPage) + 1)) {
      handleOnChangePage(initCurrentTableRows[currentPerPage - 1].id);
      setLoadingPreviousPage(false);
      return false;
    }

    let currentIndexFromMapper = -1;
    if (!initCurrentTableRows[pageGetCurrentIndexFromTable(initCurrentTableRows)]) {
      let key = client && client.id ? `profileIdMapper-${client.id}` : 'profileIdMapper';
      let idMapper = localStorage.getItem(key);
      currentIndexFromMapper = _.findIndex(initCurrentTableRows, {id: idMapper});

      if (currentIndexFromMapper) {
        setCurrentItemNum(currentIndexFromMapper + 1)
        return true;
      } else {
        return false;
      }
    }

    setCurrentItemNum(initCurrentTableRows[pageGetCurrentIndexFromTable(initCurrentTableRows)].itemCount);

    return true;
  }

  const hasNextPage = () => {
    return currentObjectResponse && currentItemNum + 1 <= (totalRQuery ? totalRQuery : total);
  }

  const hasPreviousPage = () => {
    return currentItemNum - 1 >= 1;
  }

  const getNextPageId = () => {
    let currentIndex = pageGetCurrentIndexFromTable();
    if (currentIndex < 0) {
      let key = client && client.id ? `profileIdMapper-${client.id}` : 'profileIdMapper';
      let idMapper = localStorage.getItem(key);
      const indexFromMapper = currentIndex > -1 ? currentIndex : _.findIndex(currentTableRows, {id: idMapper});
      if (currentTableRows[indexFromMapper + 1]) {
        history.push(`/${MODULES.ID_TALENT_ACQUISITION}/planning/profile/${currentTableRows[indexFromMapper + 1].id}`);
      } else {
        changePageInTable(+1);
      }
      return;
    }
    if (currentTableRows[currentIndex + 1]) {

      if (weAreInPlanningSection()) {
        history.push(`/${MODULES.ID_TALENT_ACQUISITION}/planning/profile/${currentTableRows[currentIndex + 1].id}`);

      } else {
        history.push(`/profile/${MODULES.ID_TALENT_ACQUISITION}/${currentTableRows[currentIndex + 1].id}`);
      }

      return handleOnChangePage(currentTableRows[currentIndex + 1].id);
    } else {
      changePageInTable(+1);
    }
    return false;
  }

  const getPreviousPageId = () => {
    const currentIndex = pageGetCurrentIndexFromTable();
    if (currentIndex < 0) {
      let key = client && client.id ? `profileIdMapper-${client.id}` : 'profileIdMapper';
      let idMapper = localStorage.getItem(key);
      const indexFromMapper = currentIndex > -1 ? currentIndex : _.findIndex(currentTableRows, {id: idMapper});
      if (currentTableRows[indexFromMapper - 1]) {
        history.push(`/${MODULES.ID_TALENT_ACQUISITION}/planning/profile/${currentTableRows[indexFromMapper - 1].id}`);
      } else {
        changePageInTable(-1);
      }
      return;
    }
    if (currentTableRows[currentIndex - 1]) {

      if (weAreInPlanningSection()) {
        history.push(`/${MODULES.ID_TALENT_ACQUISITION}/planning/profile/${currentTableRows[currentIndex - 1].id}`);
      } else {
        history.push(`/profile/${MODULES.ID_TALENT_ACQUISITION}/${currentTableRows[currentIndex - 1].id}`);
      }
      return handleOnChangePage(currentTableRows[currentIndex - 1].id);
    } else {
      changePageInTable(-1);
    }
    return false;
  }

  const changePageInTable = (sumOrRest) => {
    let newTablePage;
    let state = store.getState();
    let filters = state.saveSearches.filtersInState;

    if (!weAreInPlanningSection()) {
      newTablePage = tablePage + sumOrRest;
      tableSetPage(newTablePage);

      const initialRange = (newTablePage === 0 ? 0 : newTablePage) * tablePerPage;
      const finishRange = initialRange + tablePerPage - 1;
      let range = [initialRange, finishRange];
      let sort = state.processes.sort;

      fetchItemsInbox(filters, range, sort, MODULES.ID_TALENT_ACQUISITION).then(() => {
        let state = store.getState();
        let dataInboxRows = state.processes.dataInbox.data;
        setCurrentTableRows(dataInboxRows)

        // return handleOnChangePage(dataInboxRows[sumOrRest > 0 ? 0 : tablePerPage-1].id);
        return handleOnChangePage(dataInboxRows[sumOrRest > 0 ? 0 : dataInboxRows.length-1].id);
      })
    } else {
      newTablePage = citationPage + sumOrRest;
      citationSetPage(newTablePage);

      const initialRange = (newTablePage === 0 ? 0 : newTablePage) * tablePerPage;
      const finishRange = initialRange + tablePerPage - 1;
      let range = [initialRange, finishRange];
      let sort = state.citations.sort;

      fetchCitationsData(filters, range, sort).then(() => {
        let state = store.getState();
        let dataCitationsRows = state.citations.data.data;
        setCurrentTableRows(dataCitationsRows);

        // return handleOnChangePage(dataCitationsRows[sumOrRest > 0 ? 0 : tablePerPage-1].candidature.id);
        return handleOnChangePage(dataCitationsRows[sumOrRest > 0 ? 0 : dataCitationsRows.length-1].candidature.id);
      })
    }
    if (sumOrRest > 0) {
      setLoadingNextPage(newTablePage);
    } else {
      setLoadingPreviousPage(newTablePage);
    }
  }

  return currentObjectResponse && (totalRQuery ? totalRQuery : total) ? (
    <ProfileButtonsStyled>
      <Grid container alignItems="center">
        <Grid item sm={3}>
          <BackButton title={i18n.headerProfileButtons.backToApplications}
                      onClick={() => {localStorage.getItem('backLocation') ? history.push(localStorage.getItem('backLocation')) : history.goBack();}}/>
        </Grid>
        <Grid item sm={9} className={'buttons-wrapper'}>
          <p className={"header-pagination-count"}>
            <span>
              <span className="currentItemNumber">{currentItemNum}</span> / {currentObjectResponse ? (totalRQuery ? totalRQuery : total) : 0}

            </span>
          </p>

          <ButtonGroup color="primary" aria-label="Split button" className={"header-profile-arrows"}>

            <Button variant="contained"
              onClick={() => getPreviousPageId()}
              disabled={hasPreviousPage() === false}
            >
              <span>&#8205;</span>
              <ArrowBack />
            </Button>

            <Button variant="contained"
              onClick={() => getNextPageId()}
              disabled={hasNextPage() === false}
            >
              <span>&#8205;</span>
              <ArrowForward />
            </Button>

          </ButtonGroup>
        </Grid>
      </Grid>

    </ProfileButtonsStyled>
  ) : null
}

export default HeaderProfileButtons
