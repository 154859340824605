import i18n from "../../../assets/i18n"
import PropTypes from 'prop-types'
import React from "react"
import _map from 'lodash/map'
import moment from 'moment'


import avaiOptions from '../../../utils/status-options'
import { RegularSelectStyled } from "./style"

const SelectChangeStatusInbox = ({ onSubmit, options, currentStatus, isDisabled }) => {

    //const [status, setStatus] = useState(-1)
    //const [date, setDate] = useState(new Date())
    const newOptions = options ? _map(options, ({ id }) => { return { value: id, id, label: i18n.status[id] } }) : avaiOptions()

    const onChangeStatus = label => {
        //label && setStatus(label)
        onSubmit(label, moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
        //setStatus(-1)
    }


    return (
        <RegularSelectStyled disabled={ isDisabled } placeholder={currentStatus || i18n.processesTable.changeStatus} name="availability" onChange={onChangeStatus} options={newOptions} />
    )
}

SelectChangeStatusInbox.propTypes = {
    onSubmit: PropTypes.func,
    options: PropTypes.object,
}

export default SelectChangeStatusInbox;
