import React, {useEffect, useState} from 'react'
import BoxSelectionFunnelStyled from './styled'
import { Chip } from '@material-ui/core'
import { IconSelectionFunnel } from '../../atoms';
import { useTheme } from '@material-ui/styles'

const BoxSelectionFunnel = ({ i18n, phasesData, profileData, allPhasesData, selectionStatusValue = null }) => {
    const theme = useTheme()
    const [selectionStatus, setSelectionStatus] = useState(null);

    useEffect(() => {
        if (selectionStatusValue) {
            setSelectionStatus(selectionStatusValue);
        } else if (profileData) {
            setSelectionStatus(profileData.selection_statuses)
        }
    }, [profileData, selectionStatusValue]);

    const filteredPhasesData =
        profileData && selectionStatus && phasesData
        && phasesData.data && phasesData.data.map((phase) => ({
            ...phase,
            status_candidates: phase.status_candidates
                && phase.status_candidates.filter(
                    (status) => status.id === selectionStatus.id
                )
        }));

    const getChipStyle = (phase, selectedPhaseId=false) => {
        //const baseColor = phase ? phase.color : '#d3d3d3';

        if (!selectedPhaseId || phase.id === selectedPhaseId) {
            return { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText };
        } else {
            const currentIndex = phasesData.data.findIndex((p) => p.id === phase.id);

            const selectedIndex = phasesData.data.findIndex((p) => p.id === selectedPhaseId);

            return currentIndex > selectedIndex
                ? { backgroundColor: theme.palette.grey["200"] } //  backgroundColor: '#d3d3d3', color: '#A9A8A8'
                : { backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText}; // backgroundColor: '#FFFFFF', border: `1px solid ${baseColor}`
        }
    };

    const selectedPhaseId = profileData && selectionStatus
        ? phasesData.data
            .map((phase) => ({
                ...phase,
                status_candidates: phase.status_candidates
                    ? phase.status_candidates.filter(
                        (status) => status.id === selectionStatus.id
                    )
                    : [],
            }))
            .find((phase) => phase.status_candidates.length > 0)?.id || null
        : null;

    return (
        <BoxSelectionFunnelStyled>
            <div className='wrapper_funnel'  >
                <div className={allPhasesData ? 'container-title' : ''} direction='column' justifyContent="center" >
                    <div className='title-funnel'>
                        <div className='wrapper-title'>
                            <div className='container-icon'>
                                <IconSelectionFunnel />
                            </div>
                            <span>{i18n.phases_states.selectionFunnel}</span>
                        </div>
                    </div>
                    {profileData && selectionStatus ? (
                        <div className='title-current-state'>
                            <p>{i18n.phases_states.currenteState}</p>
                        </div>
                    ) : null}
                </div>

                <div container className='wrapper-phases' >

                    <div className='group-phases'>

                        <div className="phases-container">
                            <div className='line-horizontal'></div>
                            {profileData && selectionStatus ? (
                                <>
                                    {filteredPhasesData && filteredPhasesData.map((phase) => (
                                        <div className="phase" key={phase.id}>
                                            <div className='container-titles'>
                                                <Chip label={phase.name} style={getChipStyle(phase, selectedPhaseId)} />
                                            </div>                        
                                            <div className="status-container">
                                                {phase.status_candidates &&
                                                    phase.status_candidates.map((status) => (
                                                        <Chip
                                                            key={status.id}
                                                            label={status.name}
                                                            style={{ backgroundColor: status ? status.color : '#d3d3d3' }}
                                                        />
                                                    ))}
                                            </div>
                                        </div>
                                    )
                                    )}
                                </>
                            ) : allPhasesData ? allPhasesData.map((phase) => (
                                <div className="phase" key={phase.id}>
                                    <div className='container-titles'>
                                        <Chip
                                            label={phase.name}
                                            style={getChipStyle(phase)}
                                        />
                                    </div>
                                    <div className="status-container">
                                        {phase.status_candidates
                                            ? phase.status_candidates.map((status) => {
                                                return (
                                                    <Chip
                                                        key={status.id}
                                                        label={status.name}
                                                        style={{ backgroundColor: status ? status.color : '#d3d3d3' }}
                                                    />
                                                );
                                            })
                                            : null}
                                    </div>
                                </div>
                            )
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </BoxSelectionFunnelStyled>
    )
}

export default BoxSelectionFunnel
