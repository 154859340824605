import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withI18n } from '../../hocs'
import { StatisticsOperations } from '../../../redux/statistics'
import {MODULES} from "../../../config/constants";

const mapStateToProps = state => {
  return {
    globalData: state.statistics.globalData,
    positionData: state.statistics.positionData,
    isFetching: state.statistics.isFetching,
    filtersAvailable: state.statistics.filtersAvailable,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchGlobalStatistics: (data, module, filtersActive) => dispatch(StatisticsOperations.fetchGlobalStatistics(data, module, filtersActive)),
    initStats: (data, module = MODULES.ID_ON_BOARDING, filtersActive = {}, needReset = true) => dispatch(StatisticsOperations.initStats(data, module, filtersActive, needReset))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n
)

export default enharce(View)
