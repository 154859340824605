import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import PropTypes from 'prop-types'
import * as S from './styled'
import { SpinnerIcon } from '../'
import i18n from "../../../assets/i18n";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const isPdf = file => {
  return file.search(/\.(pdf)/i) !== -1
}

const canPreview = file => {
  return file.search(/\.(png|jpg|jpeg|gif)/i) !== -1
}

function MTDocument({ url, width, name, selected, onClick }) {
  const [numPages, setNumPages] = useState(null)
  /*const [pageNumber, setPageNumber] = useState(1);*/
  const pageNumber = 1

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  /*
  const getFormat = (file) => {
    const dotIndex = file.lastIndexOf('.')
    return file.substring(dotIndex)
  }*/

  const Placeholder = () => (
    <S.Placeholder>
      <S.IconPlaceholder />
      <S.PlaceholderFormat>{i18n.process.noPreview}</S.PlaceholderFormat>
    </S.Placeholder>
  )

  Placeholder.propTypes = {
    file: PropTypes.object
  }
  /*
  const nextPage = () => {
    setPageNumber(numPages?numPages:pageNumber + 1)
  }

  const previousPage = () => {
    setPageNumber(pageNumber <= 1 ? 1 :pageNumber - 1)
  }
*/
  return (
    <S.Container>
      <S.Content selected={selected} onClick={onClick}>
        {isPdf(url) ? (
            <Document
            file={{url}}
            className="DocPDF"
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <S.Placeholder>
                <SpinnerIcon />
              </S.Placeholder>
            }
          >
            <Page className="DocPage" width={width} pageNumber={pageNumber} />
          </Document>
        ) : (
          <>{canPreview(url) ? 
            <S.Image className="DocImg" src={url} width={width} /> :
            <Placeholder className="DocDefault" file={url} />
          }</>
        )}
        {numPages && (
          <S.CurrentPage>
            {pageNumber} / {numPages}
          </S.CurrentPage>
        )}
        {/*<S.PaginateLeft onClick={previousPage} />*/}
        {/*<S.PaginateRight onClick={nextPage} />*/}
      </S.Content>
      {name && <S.Name value={name} onClick={onClick} />}
    </S.Container>
  )
}

MTDocument.propTypes = {
  url: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  name: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func
}

MTDocument.defaultProps = {
  url: '',
  width: 200,
  height: 280,
  name: '',
  selected: false,
  onClick: () => null
}

export default MTDocument
