import React from 'react'
import PropTypes from 'prop-types'
import { MTDateStyled } from './styled'
import moment from 'moment'

const MTDate = ({ date }) => <MTDateStyled>{moment(date).format('D/M/YYYY')}</MTDateStyled>

MTDate.propTypes = {
  date: PropTypes.string
}

export default MTDate
