import styled from 'styled-components'

const CardEvaluativeTestsStyled = styled.div`
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #ffffff;
    width: 450px;
    height: 225px;
    padding: 24px 24px 20px 24px;
    border-radius: 15px;
    box-shadow: 0px 0px 1px 0px #e3e4e5, 0px 3px 12px 0px rgba(9, 30, 66, 0.14);
  }

  .card-header {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(209, 210, 211, 0);
    width: 100%;
    height: 45%;
  }

  .card-header-text {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(209, 210, 211, 0);
  }

  .card-pretitle {
    color: #76787c;
    font-size: 14px;
    font-weight: 400;
  }

  .card-title {
    color: #1b1e25;
    font-size: 20px;
    font-weight: 600;
  }

  .container-icon {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    background: #f6f6f6;
    cursor: pointer;
  }

  .card-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    padding-top: 20px;
    width: 100%;
  }

  .card-text {
    color: #646464;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
  }

  .button-custom-card {
    border-radius: 10px;
    color: #ffffff;
    width: 30%;
  }

  .card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chip-custom {
    background: #1b1e25 !important;
    color: #ffffff !important;
    font-size: 14px;
    font-weight: 600;
    height: auto;
  }
`

export default CardEvaluativeTestsStyled
