import styled from 'styled-components'
import { createStyles } from '@material-ui/core/styles'
import { SimpleMenu, DrawerMenuItem } from '../../atoms'
import React from "react";

export default theme =>
  createStyles({
    root: {
      height: '100vh',
      minHeight: 700
    },
    staticList: {
      width: 250,
      background: theme.palette.primary.main,
      height: '100%'
    },
    list: {
      width: theme.constants.DRAWER_WIDTH,
      background: theme.palette.primary.main,
      height: '100%'
    },
    listContainer: {
      margin: 0,
      padding: 0,
      position: 'relative'
    },
    fullList: {
      width: 'auto'
    },
    menu: {
      width: theme.constants.MENU_WIDTH,
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.primary.main,
      height: '100%',
      alignItems: 'center'
    },
    iconInbox: {
      paddingTop: 8
    },
    iconCalendar: {
      paddingTop: 6
    },
    iconPushes: {
        width: 24, height: 24,
    },
    iconStatistics: {
      paddingTop: 8
    },
    iconDashboard: {
      paddingTop: 8
    },
    iconModules: {
      paddingTop: 6
    },

  })

export const ModeSelector = styled(props => <SimpleMenu {...props}/>)`
  background-color: ${({ theme }) => theme.colors.blackAlpha30} !important;
  margin-bottom: 30px !important;
  border-bottom: solid 1px ${({ theme }) => theme.textSecondary} !important;
`

export const MenuButton = styled(props => <DrawerMenuItem {...props}/>)`
  background-color: ${({ theme }) => theme.colors.whiteAlpha24} !important;
  margin-bottom: 30px !important;
  margin-top: auto !important;
  width: 41px !important;
  height: 41px !important;
`

