import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MTFieldsStyled } from './styled'
import { InboxUtils } from '../../../redux/inbox'

const MTFields = ({ values, mapValues, useCapitalize = true }) => (
  <MTFieldsStyled useCapitalize={useCapitalize ? 'capitalize': 'none'}>
    {_.map(values, (value, label) => {
      if (_.isArray(value)) {
        return _.map(value, (multiValue, multiLabel) => (
          <span className="tags" key={multiLabel}>
            {label === 'estado' ? InboxUtils.getStatusText(multiValue) : multiValue}
          </span>
        ))
      }else if(typeof value === 'object'){
        let item = { value: null, label: '' }
        _.map(Object.keys(value), (newLabel) => {
          item = {
            value: label, label: `${item.label} ${item.value?' - ':''}${value[newLabel] ? value[newLabel] : '/'}`
          }
        })
        return (
          <span className="tags" key={item.value}>
            {item.label}
          </span>
        )
      } else if (!_.isEmpty(mapValues)) {
        return (
          <span className="tags" key={label}>
            {_.get(_.find(mapValues, v => v.remote_name === value), 'name', value)}
          </span>
        )
      } else {
        return (
          <span className="tags" key={label}>
            {label === 'estado' ? InboxUtils.getStatusText(value) : value}
          </span>
        )
      }
    })}
  </MTFieldsStyled>
)

MTFields.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  mapValues: PropTypes.array
}

export default MTFields
