import styled,{ keyframes } from 'styled-components'

const animateIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const animateOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;


const ListItemStyled = styled.div`
  .dragHandle {    

    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: 10px;
    padding: 18px 20px;
    background-color: #fff;
    box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
    outline: none;
    border-radius: calc(4px / var(--scale-x, 1));
    box-sizing: border-box;
    /* font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #76787c; */
    width: 100%;




    /* margin-bottom: 10px;  
    display: flex;
   
    align-items: center;
    padding: 18px 20px;
    background-color: #fff;
    box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
    outline: none;
    border-radius: calc(4px / var(--scale-x, 1));
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;        */
  }

  .dragIcon {
    display: flex;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
    /* touch-action: none; */
    cursor: grab;
    /* flex: 0 0 auto; */
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    background-color: transparent;
    /* -webkit-tap-highlight-color: transparent;     */
  }

  .optionsIcons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  .editIcon {
    cursor: pointer;
    display: flex;
  }

  .trashIcon {
    cursor: pointer;
    display: flex;
  }

  .listItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;   
    /* gap: 10px;  */
   animation: ${animateIn} 0.2s ease-in-out;
    &.listItem-exit {
      animation: ${animateOut} 0.2s ease-in-out;
  }
}
  .content {
    overflow: hidden;
    color: #76787c;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    user-select: none;
  }
  
`

export default ListItemStyled
