import _, {cloneDeep} from 'lodash'
import moment from 'moment'
import {
  resetData,
  setData,
  setDataFields,
  setInitialData,
  setIsFetching,
  setManualStatusTypes,
  setModule,
  setReady,
  setReportFields,
  setStatusTypes,
  setTagsTypes,
  setTotal
} from './actions'
import fileDownload from 'js-file-download'
import {MODULES} from '../../config/constants'
import {SaveSearchesActions, SaveSearchesOperations} from '../savesearches'

import 'core-js'
import {fetchInboxFilters} from "../inbox/operations";
import {toast} from "react-toastify";
import {getInboxData} from "../../api";
import i18n from "../../assets/i18n";
import {setFilterGroup} from "../savesearches/actions";

export const initInbox = (filters, range, sort, module, filtersGroups, isSavedSearchPage = false) => (dispatch, getState, { api }) => {
    let newFilters = {...filters}
    setReady(false)
    if(getState().processes.module && getState().processes.module !== module){
      dispatch(SaveSearchesOperations.setFilters({}))
      dispatch(SaveSearchesOperations.setAuxFilters({}))
      dispatch(SaveSearchesOperations.setSearchFilters({}))
      dispatch(SaveSearchesActions.setFilterGroup(-1))
      newFilters = {}
      dispatch(resetData())

    }else{
      dispatch(setData({data:[], total: 0}))
    }
    dispatch(setIsFetching(true))
    if(filtersGroups) dispatch(fetchFilterGroupInbox(filtersGroups, module))

    if (module === MODULES.ID_TALENT_ACQUISITION) {
      dispatch(setTotal(0));
      api.getInboxCount(filters, module)
        .then(response => {
          dispatch(setTotal(response));
        });
    }

    Promise.all([
      api.getInboxHeaders(module), 
      MODULES.ID_ON_BOARDING !== module && MODULES.ID_EXIT_INTERVIEW !== module ? api.getStatusTypes(module): null,
      _.get(newFilters, 'isFavorite')?api.getFavorites(newFilters, range, sort, module):api.getInboxData(newFilters, range, sort, module),
      api.getReportFields(module),
      api.getTagsTypes(module),
    ],
    )
    .then(([fields, status, data, reportFields, tags]) => {
      dispatch(setModule(module))
      dispatch(setDataFields(fields))
      dispatch(setStatusTypes(status))
      let manualStatus = status ? status.filter(item => item.automatic === false) : [];
      dispatch(setManualStatusTypes(manualStatus))
      dispatch(setTagsTypes(tags))
      if (module === MODULES.ID_EMPLOYEE_LINE && isSavedSearchPage && filters !== {}) {
        let firstFilterGroup = '';
        let keys = Object.keys(data);
        let resultFilters = cloneDeep(newFilters)
        if (keys.includes('total_status_received')) {
          resultFilters['state.name'] = i18n.status.received;
          firstFilterGroup = 'received';
        } else if (keys.includes('total_status_in_process')) {
          resultFilters['state.name'] = i18n.status.in_process;
          firstFilterGroup = 'in_process';
        } else if (keys.includes('total_status_pending_info')) {
          resultFilters['state.name'] = i18n.status.pending_info;
          firstFilterGroup = 'pending_info';
        } else if (keys.includes('total_status_processed')) {
          resultFilters['state.name'] = i18n.status.processed;
          firstFilterGroup = 'processed';
        } else if (keys.includes('total_status_canceled')) {
          resultFilters['state.name'] = i18n.status.canceled;
          firstFilterGroup = 'canceled';
        }
        getInboxData(resultFilters, range, sort, module).then(res => {
          dispatch(setFilterGroup(firstFilterGroup));
          dispatch(setData(res))
          dispatch(setTotal(res.total))
        })
      } else {
        dispatch(setData(data))
      }
      if (module !== MODULES.ID_TALENT_ACQUISITION && !(module === MODULES.ID_EMPLOYEE_LINE && isSavedSearchPage)) dispatch(setTotal(data.total))
      dispatch(setReportFields(reportFields))
      dispatch(setReady(true))
    })
    .finally(() => {
      dispatch(setIsFetching(false))
    })
}

export const fetchItemsInbox = (filters, range, sort, module, filtersGroups, isSaveSearchPage = false, filterGroupSelected = null) => (dispatch, getState, { api }) => {
  dispatch(setData({data:[], total: 0}))
  dispatch(setIsFetching(true))
  const fetchData = _.get(filters, 'isFavorite')?api.getFavorites:api.getInboxData
  if(filtersGroups && !_.isEmpty(filtersGroups)){
    dispatch(fetchFilterGroupInbox(filtersGroups, module))
  }

  if (module === MODULES.ID_TALENT_ACQUISITION) {
    dispatch(setTotal(0));
    api.getInboxCount(filters, module)
      .then(response => {
        dispatch(setTotal(response));
      });
  }

  return fetchData(filters, range, sort, module)
    .then(response => {
      if (module === MODULES.ID_EMPLOYEE_LINE && isSaveSearchPage && filterGroupSelected && filterGroupSelected < 0) {
          let firstFilterGroup = '';
          let keys = Object.keys(response);
          let resultFilters = cloneDeep(filters)
          if (keys.includes('total_status_received')) {
            resultFilters['state.name'] = i18n.status.received;
            firstFilterGroup = 'received';
          } else if (keys.includes('total_status_in_process')) {
            resultFilters['state.name'] = i18n.status.in_process;
            firstFilterGroup = 'in_process';
          } else if (keys.includes('total_status_pending_info')) {
            resultFilters['state.name'] = i18n.status.pending_info;
            firstFilterGroup = 'pending_info';
          } else if (keys.includes('total_status_processed')) {
            resultFilters['state.name'] = i18n.status.processed;
            firstFilterGroup = 'processed';
          } else if (keys.includes('total_status_canceled')) {
            resultFilters['state.name'] = i18n.status.canceled;
            firstFilterGroup = 'canceled';
          }
          getInboxData(resultFilters, range, sort, module).then(res => {
            dispatch(setFilterGroup(firstFilterGroup));
            dispatch(setData(res))
            dispatch(setTotal(res.total))
          })
      } else {
        dispatch(setData(response))
      }
      if (module !== MODULES.ID_TALENT_ACQUISITION && !(module === MODULES.ID_EMPLOYEE_LINE && isSaveSearchPage && filterGroupSelected === -1)) dispatch(setTotal(response.total));
    })
    .finally(() => dispatch(setIsFetching(false)))
}

export const fetchInboxCount = (filters, module) => (dispatch, getState, {api}) => {
  dispatch(setTotal(0));
  return api.getInboxCount(filters, module)
    .then(response => {
      dispatch(setTotal(response));
    });
}

export const fetchFilterGroupInbox = (filters, module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  const fetchData = _.get(filters, 'isFavorite')?api.getFavorites:api.getInboxData
  return fetchData(filters, [0,4], ["",""], module)
    .then(response => {
      dispatch(setInitialData(response))
    })
    .finally(() => dispatch(setIsFetching(false)))
}

export const fetchProcessesFields = (module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  api
    .getInboxHeaders(module)
    .then(response => {
      dispatch(setDataFields(response))
    })
    .finally(() => dispatch(setIsFetching(false)))
}

export const postFavorite = (ids, isFavorite, queryParams, module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  const { processes } = getState()
    const newDataInbox = { ...processes.dataInbox }
    _.map(ids, (id) => {
      newDataInbox.data[_.findIndex(newDataInbox.data, {id})].is_favorite	= isFavorite
    })
    dispatch(setData(newDataInbox))
    const fetchFav = isFavorite?api.postFavorites:api.deleteFavorites
    fetchFav(ids, module)
    .then(() => {
      const { filters, range, sort } = queryParams
      const fetchData = _.get(filters, 'isFavorite')?api.getFavorites:api.getInboxData
      fetchData(filters, range, sort, module)
      .then(response => {
        dispatch(setData(response))
      })
      .finally(() => dispatch(setIsFetching(false)))
    })
}

export const requireDoc = (ids, data) => (dispatch, getState, { api, handleError, toast, i18n }) => {
  dispatch(setIsFetching(true))
  const promises = _.map(ids, id =>
    api.requireDoc({
      ...data,
      candidature: { id }
    })
  )

  return Promise.all(promises)
    .then(() => {
      toast(i18n.requireDoc.docRequired, { type: toast.TYPE.SUCCESS })
    })
    .then(() => {})
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(setIsFetching(false)))
}

export const markAsRead = (ids, queryParams, module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  const { processes } = getState()
    const newDataInbox = { ...processes.dataInbox }
    _.map(ids, (id) => {
      newDataInbox.data[_.findIndex(newDataInbox.data, {id})].was_read	= true
    })
    dispatch(setData(newDataInbox))
  api.postReads(ids, module)
  .then(() => {
    const { filters, range, sort } = queryParams
    dispatch(fetchItemsInbox(filters, range, sort, module))
  })
  .finally(() => {
    dispatch(setIsFetching(false))
  })
}

export const unmarkAsRead = (ids, queryParams, module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  const { processes } = getState()
    const newDataInbox = { ...processes.dataInbox }
    _.map(ids, (id) => {
      newDataInbox.data[_.findIndex(newDataInbox.data, {id})].was_read	= false
    })
    dispatch(setData(newDataInbox))
  api.deleteReads(ids, module)
  .then(() => {
    const { filters, range, sort } = queryParams
    dispatch(fetchItemsInbox(filters, range, sort, module))
  })
  .finally(() => {
    dispatch(setIsFetching(false))
  })
}

export const fetchStatusTypes = (module) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))
  api
    .getStatusTypes(module)
    .then(response => {
      dispatch(setStatusTypes(response))
    })
    .finally(() => dispatch(setIsFetching(false)))
}


export const changeStatus = (ids, status, queryParams, date, module, filterGroups) => (dispatch, getState, { api, handleError }) => {

  dispatch(setIsFetching(true))
  const newStatus = _.map(ids, id =>{
    return {id, status_id: status, date}
  })
  const { processes } = getState()
    const newDataInbox = { ...processes.dataInbox }
    _.map(ids, (id) => {
      const index = _.findIndex(newDataInbox.data, {id})
      const item = newDataInbox.data[index]
      item['state.id']?item['state.id'] = status: item['estado']	= status
    })
    dispatch(setData(newDataInbox))
  return api
    .changeStatus(newStatus, module)
    .then(() => {
      const { filters, range, sort } = queryParams
      dispatch(fetchItemsInbox(filters, range, sort, module, filterGroups))
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(dispatch(setIsFetching(false))))
}

export const changeTags = (ids, tags, queryParams, module, filterGroups) => (dispatch, getState, { api, handleError }) => {

  dispatch(setIsFetching(true))
  return api
    .changeTags(ids, tags, module)
    .then(() => {
      const { filters, range, sort } = queryParams
      dispatch(fetchInboxFilters(module))
      dispatch(fetchItemsInbox(filters, range, sort, module, filterGroups))
    }).then()
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(dispatch(setIsFetching(false))))
}

export const exportCsv = (fields, filters, sort, module) => (dispatch, getState, { api }) => {
  return api.getInboxCsv(fields, filters, sort, module).then(response => {
    const date = moment().format('DD-MM-YYYY')
    fileDownload(new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${module}_${date}.xlsx`)
  })
}

export const exportCsvOnBackground = (fields, filters, sort, module, isPlanning = false, receivers) => (dispatch, getState, { api }) => {
  return api.getInboxCsvOnBackground(fields, filters, sort, module, isPlanning, receivers)
}

export const postOffer = (obj) => (dispatch, getState, { api, i18n }) => {
  return api.postOffer(obj).then(() => toast(i18n.offerModal.areUSure.sendCompleted, { type: toast.TYPE.SUCCESS }))
}


export const refetchTagsTypes = ( module ) => (dispatch, getState, { api }) => {

  setReady(false)
  dispatch(setIsFetching(true));

  Promise.all([ api.getTagsTypes(module) ]).then(([ tags ]) => dispatch(setTagsTypes( tags ))).finally( () => {dispatch(setIsFetching( false ))});
}

export const fetchReportFields = ( module ) => (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true))

  Promise.all([
      api.getReportFields(module)
    ],
  )
    .then(([ reportFields ]) => {
      dispatch(setReportFields(reportFields))
    })
    .finally(() => {
      dispatch(setIsFetching(false))
    })

}