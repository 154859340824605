import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import InputFieldStyled from './styled'
import _map from 'lodash/map'
import { IconEye, IconEyeOff } from '../../atoms'

const InputField = ({ fontSize, placeholder, defaultValue, name, label, type, handleOnChange, value, autoComplete, handleRef, onEyeIconClick, showEyeIcon, ...rest }) => {
  const [showPassword, setShowPassword] = useState(type === 'text' && type !== 'password');

  useEffect(() => {
    setShowPassword(type === 'text' && type !== 'password');
  }, [type]);

  const toggleShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
    onEyeIconClick && onEyeIconClick(!showPassword);
  };

  const inputType = showPassword ? type : 'password';

  let newValue = value
  if (newValue === null) {
    newValue = ''
  }

  if (value && Array.isArray(value)) {
    _map(value, item => (newValue = newValue ? `${newValue};${item}` : item))
  }

  const onPasteHandle = (e) => {
    if (e.clipboardData) {
      let content = e.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, content.replaceAll("\n", ";").replace(/;\s*$/, ""));
      e.preventDefault();
      return
    }
  }

  return (
    <InputFieldStyled fontSize={fontSize}>
      <div className="input-container">
        <input
          name={name}
          onPaste={onPasteHandle}
          onChange={handleOnChange ? e => handleOnChange(e.target.value, e.target.name, label || value) : null}
          autoComplete={autoComplete}
          placeholder={placeholder}
          value={newValue}
          type={inputType}
          defaultValue={defaultValue}
          ref={handleRef}
          {...rest}
        />
        <div className="eye-icon" onClick={toggleShowPassword}>
          {showEyeIcon ? (type === 'password' ? <IconEyeOff /> : <IconEye />) : ""}
        </div>
      </div>
    </InputFieldStyled>
  )
}

InputField.defaultProps = {
  type: 'text',
  autoComplete: 'off',
  fontSize: 'normal',
  defaultValue: '',
  showEyeIcon: false,

}

const enharce = compose()

export default enharce(InputField)
