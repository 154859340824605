import React from 'react'
import compose from 'recompose/compose'
import {withI18n} from '../../hocs'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import colors from '../../../assets/colors'
import TableStatisticStyled from './styled'
import {ReactComponent as StatsIcon} from '../../../assets/images/stats.svg'
import {MTCircularPercent, MTText} from '../../atoms'

const MTPercent = value => <MTCircularPercent value={value} />

const TableStatistic = ({ title, i18n, data }) => {
  const tableOptions = {
    emptyRowsWhenPaging: false,
    paginationType: 'stepped',
    loadingType: 'linear',
    headerStyle: { backgroundColor: colors.grey, textAlign: 'center' },
    pageSize: 20,
    pageSizeOptions: [20, 50, 100, 200],
  }

  const tableComponents = {
    Container: MTContainer,
    Toolbar: () => ''
  }

  const columns = [
    {
      title: i18n.processesStatisticsItems.processes,
      field: 'name',
      render: rowData => TextItem(rowData.name),
      cellStyle: { textAlign: 'center' }
    },
    {
      title: i18n.processesStatisticsItems.total,
      field: 'total',
      render: rowData => TextItem(rowData.total),
      cellStyle: { textAlign: 'center' }
    },
    {
      title: i18n.processesStatisticsItems.recived,
      field: 'received_percent',
      render: rowData => MTPercent(Math.round(rowData.received_percent)),
      cellStyle: { textAlign: 'center' }
    },
    {
      title: i18n.processesStatisticsItems.suitablesVsRecibidos,
      field: 'in_process_percent',
      render: rowData => MTPercent(Math.round(rowData.in_process_percent)),
      cellStyle: { textAlign: 'center' }
    },
    {
      title: i18n.processesStatisticsItems.suitables,
      field: 'processed_percent	',
      render: rowData => MTPercent(Math.round(rowData.processed_percent	)),
      cellStyle: { textAlign: 'center' }
    }
  ]

  return (
    <TableStatisticStyled>
      <div className="header-stat">
        <StatsIcon />
        <p>{title}</p>
      </div>
      <div className="table-wrapper">
        <MaterialTable
          search={false}
          columns={columns}
          data={data ? data : []}
          options={tableOptions}
          components={tableComponents}
          localization={i18n.tableLocalization}
          draggable={false}
        />
      </div>
    </TableStatisticStyled>
  )
}

const MTContainer = props => <div {...props} />

const TextItem = value => <MTText value={value} />

TableStatistic.propTypes = {
  title: PropTypes.string,
  i18n: PropTypes.object.isRequired,
  data: PropTypes.array,
  isFetching: PropTypes.bool
}

TableStatistic.defaultProps = {
  title: '',
  data: [],
  isFetching: false
}

const enharce = compose(withI18n)

export default enharce(TableStatistic)
