import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Icon = ({ fill = 'none', stroke = 'white', width = '2' }) => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" fill={`${fill ? fill : 'none'}`} xmlns="http://www.w3.org/2000/svg">
    <path d="M3 3L9 9" stroke={`${stroke ? stroke : 'white'}`} stroke-width={`${width ? width : '2'}`} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3 9L9 3" stroke={`${stroke ? stroke : 'white'}`} stroke-width={`${width ? width : '2'}`} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </SvgIcon>
)

export default Icon
