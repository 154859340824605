import styled from 'styled-components'
import colors from '../../../assets/colors'

const AvatarMatch = styled.div`
  .avatar-wrapper {
    display: inline-block;
  }

  .value-percent {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.theme.primary};
    text-align: center;
  }

  .copy {
    font-weight: 600;
    font-size: 26px;
    line-height: 26px;
    letter-spacing: 0.03em;
    text-align: center;
    color: ${colors.black};
  }

  .circle-wrapper {
    position: relative;
    text-align: center;

    svg circle {
      stroke-width: 1;
    }

    .image-bg {
      position: absolute;
      top: 49%;
      left: 50%;
      width: 83%;
      height: 83%;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      img {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .top-circle {
    color: ${colors.greyAlpha25};
  }

  .bottom-circle {
    position: absolute;
    left: 0;
  }

  @media print {
    
    .MuiCircularProgress-colorPrimary {
      width: 160px !important;
      height: 160px !important;
    }
  
    .value-percent {
      color: ${props => props.theme.primary}!important;
    }

    .circle-wrapper {
      .image-bg {
        img {
          display: block !important;
        }
      }
    }
  }
`

export default AvatarMatch
