import React, { memo } from 'react'
import CircleMultipleStatisticStyled from './styled'
import { ReactComponent as UserIcon } from '../../../assets/images/stats.svg'
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer } from 'recharts';
import i18n from '../../../assets/i18n'
import { useColorPalette, assignColorByValueAndData } from '../use-color-palette'
import { isArray } from 'lodash'

const CircleMultipleStatistic = ({ title = null, overrideTitle = null, data = [], field = 'x', height = 300 }) => {
  const style = {
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  };

  const palette = useColorPalette(isArray(data) ? data.length : 8);

  if (data && data.length > 0) {
    data.sort((a, b) => (a[field] - b[field]));
    data.forEach((item, idx) => {
      item[field] = !isNaN(item[field]) && item[field] > 0 ? Math.round(item[field]) : item[field];
    });
    data = assignColorByValueAndData(data, field, palette);
  }

  const ChartMemo = memo(({ data = [] }) => {
    return (<div className="chart-wrapper" style={{ width: '100%', height: height }}>
      <ResponsiveContainer width="100%" height="160%">
        {data && data.length > 0 ?
          <RadialBarChart cx="40%" cy="65%" innerRadius="10%" outerRadius="80%" data={data}
                          barSize={15}
                          startAngle={180}
                          endAngle={0}>
            <RadialBar
              minAngle={30}
              dataKey={field}
              background
              label={{ position: 'end', fill: '#333' }}
            />
            <Legend layout="vertical" verticalAlign="middle" align="right"/>
          </RadialBarChart>
          :
          <></>
        }
      </ResponsiveContainer>
    </div>);
  });
  ChartMemo.displayName = 'ChartMemo';

  return (
    <CircleMultipleStatisticStyled>
      {overrideTitle}
      {!overrideTitle && title &&
        <div className="header-stat">
          {<UserIcon />}
          <p>{title}</p>
        </div>
      }

      {(data && data.length) ? <ChartMemo data={data} /> :
        <div className="chart-wrapper" style={{ width: '100%', height: height }}>
          <div className="no-data-available">{i18n.dashboard.no_data_available}</div>
        </div>
      }

    </CircleMultipleStatisticStyled>
  )
}


export default CircleMultipleStatistic
