import * as types from './types'

const initialState = {
  isFetching: false,
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_DATA:
      return { ...state, data: action.payload }
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.RESET_DATA:
      return initialState
    default:
      return state
  }
}
