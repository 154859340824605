import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {AppBarInbox, OnboardingProfileInfo, ProcessTable, SaveSearchesTable} from '../../organism'
import {PageTitleSubtitle} from '../../atoms'
import {MODULES} from '../../../config/constants'

const SavedSearches = ({ classes, i18n, searchFilters, searchFilterTitle, resetFilters }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    resetFilters()
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.root}>
      <AppBarInbox
        showActions
        module={MODULES.ID_ON_BOARDING}
        saveSearchOption={false}
        canEditFilters={false}
        canCloneSearch
        forceTotal={total}
        section={"savedSearches"}
      />
      {_.isEmpty(searchFilters) ? (
          <SaveSearchesTable module={MODULES.ID_ON_BOARDING} setTotal={setTotal}/>
      ) : (
        <>
          <PageTitleSubtitle title={i18n.inboxPeople.filtersSearch} subtitle={searchFilterTitle} module={MODULES.ID_ON_BOARDING} />
          <ProcessTable
            showFilterGroup
            showChangeStatus
            filters={searchFilters}
            module={MODULES.ID_ON_BOARDING}
            rowViewRender={id => <OnboardingProfileInfo id={id}/>}
            resetFiltersOnLoad={false}
          />

        </>
      )}
    </div>
  )
}

export default SavedSearches
