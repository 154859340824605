export const printIframe = (divName) =>{
    //Ceamos iframe con contenido para imprimir
    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("id", "iframeToPrint");
    ifrm.style.width = "0";
    ifrm.style.height = "0";
    document.body.appendChild(ifrm);
    //Elemento a imprimir
    var printContents = document.getElementById(divName).innerHTML;
    setTimeout(() => {
      let newDocument = document.cloneNode(true);
      //Situar elemento a imprimir dentro del DOM del iframe
      newDocument.getElementById('main-content').innerHTML = printContents;
      //Imprimir Iframe
      setTimeout(() => {
        ifrm.contentWindow.document.write(newDocument.documentElement.innerHTML);
        ifrm.focus();
        ifrm.contentWindow.print();
        ifrm.remove();
      }, 2000)
      return false;
    }, 2000)
  }

  export const print = () =>{
    window.print()
  }