import React from 'react'

const icon = props => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6745 2C15.1637 2 15.4083 2 15.6385 2.05526C15.8425 2.10425 16.0376 2.18506 16.2166 2.29472C16.4184 2.4184 16.5914 2.59135 16.9373 2.93726L19.0627 5.06274C19.4086 5.40865 19.5816 5.5816 19.7053 5.78343C19.8149 5.96237 19.8957 6.15746 19.9447 6.36154C20 6.59171 20 6.8363 20 7.32548L20 14C20 16.8003 20 18.2004 19.455 19.27C18.9757 20.2108 18.2108 20.9757 17.27 21.455C16.2004 22 14.8003 22 12 22V22C9.19974 22 7.79961 22 6.73005 21.455C5.78924 20.9757 5.02433 20.2108 4.54497 19.27C4 18.2004 4 16.8003 4 14L4 10C4 7.19974 4 5.79961 4.54497 4.73005C5.02434 3.78924 5.78924 3.02433 6.73005 2.54497C7.79961 2 9.19974 2 12 2L14.6745 2Z" stroke="#7A808D" stroke-width="2" stroke-linejoin="round" />
    <path d="M15 2V3.8C15 4.9201 15 5.48016 15.218 5.90798C15.4097 6.28431 15.7157 6.59027 16.092 6.78201C16.5198 7 17.0799 7 18.2 7H20" stroke="#7A808D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.5 14.75L12 16.25L13.5 14.75" stroke="#7A808D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 11.75L12 16.25" stroke="#7A808D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

export default icon
