import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import { MTText } from '../'
import colors from '../../../assets/colors'
import { getCitationStatusTypeColor } from '../../../models/citations'

const MTCitationStatusTextColor = ({ value, i18n, options, onClick }) => {
  return <MTText
    value={value ? i18n.citationStatus[value] : ''}
    color={getCitationStatusTypeColor(value) ?? colors.blue}
    onClick={onClick}
    {...options}
  />
}

MTCitationStatusTextColor.propTypes = {
  value: PropTypes.object,
  i18n: PropTypes.object
}

const enharce = compose(withI18n)

export default enharce(MTCitationStatusTextColor)
