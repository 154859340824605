import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import {QUERY_KEYS} from '../../config/constants'
import {parseFiltersFromFrontToApiFormat} from "../../utils/filters";
import {addItemCount} from "../index";
import * as FiltersUtils from "../../utils/filters";
import eventTracker from '../../utils/eventTracker';

async function getJobOffers({queryKey}) {
  let [_key, {filters, range, sort}] = queryKey;

  filters = parseFiltersFromFrontToApiFormat(filters);
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  range = range ? encodeURI(JSON.stringify(range)) : '';
  sort = encodeURI(JSON.stringify(sort));

  eventTracker.track('JobOffersFilter', {
    url: document.location.href,
    filters: filters,
    range: range,
    sort: sort,
  })

  return await get(`/api/job_offers?filter=${filters}&range=${range}&sort=${sort}`);
}

export function useJobOffers(filters, range, sort) {
  return useQuery([`${QUERY_KEYS.JOB_OFFERS}`, {filters, range, sort}], getJobOffers);
}

async function getJobOffersAssignOptions() {
  return await get(`/api/job_offer_assign_options`);
}

export function useAssignJobOffersOptions() {
  return useQuery([`options:${QUERY_KEYS.JOB_OFFERS}:assign_candidates`], getJobOffersAssignOptions);
}

async function getJobOffer({queryKey}) {
  let [_key, {id}] = queryKey;
  if (!id) return null;
  return await get(`/api/job_offers/${id}`);
}

export function useJobOffer(id) {
  return useQuery([`${QUERY_KEYS.JOB_OFFER_ROW}:${id}`, {id}], getJobOffer);
}

async function getJobOfferApplications({queryKey}) {
  let [_key, {job_offer_id, filters, range, sort}] = queryKey;

  filters = {...filters, "job_offer.id": job_offer_id}
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  let rangeEncoded = range ? encodeURI(JSON.stringify(range)) : '';
  sort = encodeURI(JSON.stringify(sort));

  return await get(`/api/job_offer_applications?filter=${filters}&range=${rangeEncoded}&sort=${sort}`).then(res => {
    res.data = addItemCount(res.data, range);
    return res;
  });
}

export function useJobOfferApplications(job_offer_id, filters, range, sort) {
  return useQuery([`${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`, {job_offer_id, filters, range, sort}], getJobOfferApplications);
}

async function getJobOfferLocationOptions() {
  return await get(`/api/job_offer_location_options`);
}

export function useJobOfferLocationOptions() {
  return useQuery([`options:${QUERY_KEYS.JOB_OFFERS}:locations`], getJobOfferLocationOptions);
}

async function getJobOfferRecruitmentSourcesOptions() {
  return await get(`/api/job_offer_recruitment_sources_options`);
}

export function useJobOfferRecruitmentSourcesOptions() {
  return useQuery([`options:${QUERY_KEYS.JOB_OFFERS}:recruitment_sources`], getJobOfferRecruitmentSourcesOptions);
}


async function getJobOfferCandidatureSettingsOptions() {
  return await get(`/api/job_offer_candidature_settings_options`);
}

export function useJobOfferCandidatureSettingsOptions() {
  return useQuery([`options:${QUERY_KEYS.JOB_OFFERS}:candidature_settings`], getJobOfferCandidatureSettingsOptions);
}

async function getJobOfferActiveNamesOptions() {
  return await get(`/api/job_offer_name_options/active`);
}

export function useJobOfferActiveNamesOptions() {
  return useQuery([`options:${QUERY_KEYS.JOB_OFFERS}:names:active`], getJobOfferActiveNamesOptions);
}

async function getJobOfferInactiveNamesOptions() {
  return await get(`/api/job_offer_name_options/inactive`);
}

export function useJobOfferInactiveNamesOptions() {
  return useQuery([`options:${QUERY_KEYS.JOB_OFFERS}:names:inactive`], getJobOfferInactiveNamesOptions);
}
