import StatusGroupType from "../models/status-group-type";

export default () => {
  let allStatus = [];
  let keys = Object.keys(StatusGroupType);
  keys.map(key => {
    allStatus = allStatus.concat(StatusGroupType[key].status);
  });

  return allStatus;
}
