import { createStyles } from '@material-ui/core/styles'

export default theme =>
    createStyles({
        '@global': {
            body: {
                backgroundColor: theme.colors.pageBackground,
                minWidth: 320
            }
        },
        root: {
            width: '100%',
            padding: '30px 60px'
        }
    })
