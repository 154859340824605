import styled from 'styled-components'
import colors from '../../../assets/colors'
import { headerStatStyles, noDataStyles, chartWrapperStyles } from '../dynamic-widget/styled'

const CircleMultipleStatisticStyled = styled.div`
  && {
    min-width: 200px;
    height: auto;
  }
  
  ${headerStatStyles}
  
  ${noDataStyles}
  
  ${chartWrapperStyles}
  
  .chart-wrapper {
    padding: 0;
    min-height: 300px;
  }
  .no-data-available {
    border-radius: inherit;
  }
`

export default CircleMultipleStatisticStyled
