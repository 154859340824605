import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import {MODULES, QUERY_KEYS} from "../../config/constants";


async function getObservationsData({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  let [_key, {module, slug}] = queryKey;
  if (!module || !slug || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;

  return get(`/api/module/${module}/identifier/${slug}/observations`)
}

export default function useObservations(module, slug) {
  return useQuery([`${module}:${QUERY_KEYS.OBSERVATIONS}:${slug}`, {module, slug}], getObservationsData, {enabled: module !== null && module !== undefined && slug !== null && slug !== undefined});
}