import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const DateInputStyled = styled.div`
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }

  .MuiFormControl-marginNormal {
    margin: 0;
  }

  .MuiInputAdornment-positionEnd {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  input {
    min-height: 40px;
    background: ${colors.white};
    border: 1px solid ${colors.greyAlpha50};
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    outline: none;
    width: 100%;
    appearance: none;
    cursor: pointer;

    ::placeholder {
      color: #9999b3;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #9999b3;
      opacity: 1;
    }

    ${props =>
      props.fontSize === 'small' &&
      css`
        font-size: 14px;
        line-height: 18px;
        ::placeholder {
          font-size: 14px;
          line-height: 18px;
        }
      `}
  }
`

export default DateInputStyled
