import styled from 'styled-components'
import colors from '../../../assets/colors'

const BoxPDAHeaderStyled = styled.div`
  
  && {
    padding: 30px 0 10px 0;  
  }

  .title-line {
    margin-bottom: 20px;
    .pda-logo{
      height: 20px
    }
  }
  
  img.pdf{
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
    width: 140px;
  }

  .values-pda{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
  }

  .container-repna{
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .pda-repna{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  .MuiChip-root {
    background-color: ${colors.greyAlpha50};;
    box-shadow: 0px 0px 1px 0px rgba(227, 228, 229, 1), 0px 3px 12px 0px rgba(9, 30, 66, 0.14);
    border: none;
  }

  .values-repna{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }    
`
export default BoxPDAHeaderStyled
