import styled from 'styled-components'
import colors from '../../../assets/colors'

const BoxInfoHeaderStyled = styled.div`
  @media print {
    .header-table {
      background: ${props => props.theme.primary}!important;
      -webkit-print-color-adjust: exact;
      padding-left: 25px;

      p {
        color: ${colors.white}!important;
      }
    }
    //#wrap-table {
    //  background: #FFFFFF;
    //  box-shadow: 0 4px 6px rgb(98 102 113 / 6%), 0 2px 2px rgb(98 102 113 / 6%);
    //  border-radius: 6px;
    //  padding: 20px 30px;
    //  width: 100%;
    //}
    #wrap-table {
      background-color: ${colors.white}!important;
      padding: 0 !important;
    }
  }

  && {
    width: 100%;
    border-radius: 8px;
    height: 100%

    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.03em;
      position: relative;
      display: inline-block;
    }

    .header-table {
      background: ${props => props.theme.primary};
      -webkit-print-color-adjust: exact;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 15px 30px;

      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.03em;
        color: ${props => props.theme.textPrimary};
        margin-bottom: 0;
        padding-left: 45px;

        svg {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          margin-right: 10px;

          path {
            fill: ${props => props.theme.textPrimary};
          }
        }
      }
    }

    #wrap-table {
      padding: 0 30px 0 52px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      height: 100%;

      .value{
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .row-table {
      border-top: 1px solid ${colors.greyAlpha25};
      padding: 10px 0;

      :first-child {
        border-top: 0;
      }
    }
  }
`

export default BoxInfoHeaderStyled
