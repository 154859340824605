import { useQuery } from '@tanstack/react-query';
import { get } from '../utils';
import { QUERY_KEYS } from '../../config/constants';

async function getEvaluativeTestsTemplates(module) {
    return await get(`/api/module/${module}/evaluative-feature-templates`);
}

export default function useEvaluativeTestsTemplates(module) {
    return useQuery([`${module}:${QUERY_KEYS.EVALUATIVE_TESTS}`, { module }], () => getEvaluativeTestsTemplates(module));
}
