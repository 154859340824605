import React, { useEffect, useState } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeaderProcess, RegularButton } from '../../atoms'
import { patchRpaIntegration } from '../../../api'
import { useMutation } from "@tanstack/react-query";
import { INTEGRATIONS_RPA } from '../../../config/constants';

const customStyles = {
  overflow: 'scroll'
}

const ModalDisableIntegration = ({ open, handleClose, i18n, integrationData, onDataUpdate, self_data }) => {
  const [toggleCurrentStatus, setToggleCurrentStatus] = useState(null);
  const [integrationDataState, setIntegrationDataState] = useState(null);

  useEffect(() => {
    if (open) {
      const newCurrentStatus = integrationData ? integrationData.active : false;
      setToggleCurrentStatus(newCurrentStatus);
      setIntegrationDataState(integrationData);
    }

  }, [open, integrationData]);

  const options = [
    { id: INTEGRATIONS_RPA.INFOJOBS_PROVIDER, name: i18n.keywords_rpa.sourceInfojobs },
    { id: INTEGRATIONS_RPA.EPRESELEC_PROVIDER, name: i18n.keywords_rpa.sourceEpreselec },
    { id: INTEGRATIONS_RPA.COMPUTRABAJO_PROVIDER, name: i18n.keywords_rpa.sourceComputrabajo },
    { id: INTEGRATIONS_RPA.PANDAPE_PROVIDER, name: i18n.keywords_rpa.sourcePandape },
  ];

  const providerName = options.find(option => integrationData && option.id === integrationData.provider)?.name;

  const changeStatusIntegration = async (data) => {
    const response = await patchRpaIntegration(data.id, self_data.client_id, data);
    return response.data;
  }

  const mutationChangeStatusIntegration = useMutation(changeStatusIntegration);

  const handleClickDisable = async (e) => {
    e.preventDefault();
    try {
      if (integrationDataState && integrationDataState.id) {
        await mutationChangeStatusIntegration.mutateAsync(
          {
            id: integrationDataState.id,
            active: !integrationDataState.active,
          },
          {
            onSuccess: () => {
              handleClose();
              onDataUpdate();
            },
          }
        );
      }
    } catch (error) {
      console.error('Error in Disable or Enable Integration:', error);
    }
  };

  const handleModalClose = () => {
    setToggleCurrentStatus(null);
    setIntegrationDataState(null);
    handleClose();
  };

  const getTitleForConfirmation = () => {
    if (integrationDataState && toggleCurrentStatus) {
      return i18n.keywords_rpa.confirmDisabledIntegration
        .replace('{position}', `${integrationDataState.candidature.name || ''}`)
        .replace('{provider}', `${providerName || ''}`);
    } else if (integrationDataState) {
      return i18n.keywords_rpa.confirmEnabledIntegration
        .replace('{position}', `${integrationDataState.candidature.name || ''}`)
        .replace('{provider}', `${providerName || ''}`);
    }
    return '';
  };

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleModalClose}
      >
        <ModalStyled>
          <ModalHeaderProcess closeClick={handleModalClose} hasCloseIcon={true} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center" justifyContent='center'>
                <h4>{getTitleForConfirmation()}</h4>
              </Grid>
              <div className="button-wrapper">
                <RegularButton
                  className="button-custom"
                  title={integrationDataState && integrationDataState.id
                    ? !toggleCurrentStatus
                      ? i18n.keywords_rpa.buttonConfirmEnabledIntegration
                      : i18n.keywords_rpa.buttonConfirmDisabledIntegration
                    : null}
                  onClick={handleClickDisable}
                /></div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(ModalDisableIntegration)