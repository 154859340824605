import React from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import {FunnelStatisticStyled, FunnelItem, FunnelValue, FunnelInfo} from './styled'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import i18n from '../../../assets/i18n'
import {isArray, isNumber} from "lodash";

const FunnelStatistic = ({title, overrideTitle = null, funnel, data, extraData, isDashboard = false }) => {
    const opacity = ["33", "66", "AA", "CC", "FF"]
    if (!funnel || funnel.length === 0) funnel = data;

  const formatDecimal = (value) => {
    if(!isNaN(value)) {
      try {
        value = Intl.NumberFormat("es-ES").format(value);
      } catch(e) {
        console.warn(`Error al procesar valor ${value}`)
      }
    }

    return value;
  }

    return (
      <FunnelStatisticStyled isDashboard={isDashboard}>
        {overrideTitle ? overrideTitle :
          <Grid item xs={12} className="label-stat funnel-title">
                <span>
                {title}
                </span>
            {isNumber(extraData) && <span className="extra-data">{i18n.onboardingStatisticsItems.leaving} {extraData}</span>}
          </Grid>
        }
        <Grid container spacing={0} style={{padding: '10px'}}>
            <Grid item xs={12} className="label-stat">
            {funnel && funnel.map(function(node, item, list) {
              let node_percent = 0;
              let node_employees = null;
              if (!isArray(node)) {
                node_employees = node.employees;
                node_percent = node.percent;
              }
              return (
              <Grid key={item} container spacing={0}>
                  <Divider className="full" />
                  <Grid item xs={8} md={6} className="value-stat">
                    <FunnelValue>{node_employees ? formatDecimal(node_employees) : ''}</FunnelValue>
                    <FunnelItem top={item ? (list[item-1].percent ? list[item-1].percent : list[item-1]) : 100}
                                  bottom={node_percent ? node_percent : (node.percent ? node.percent : node)} opacity={opacity[item]} >
                      </FunnelItem>
                  </Grid>
                  <Grid item xs={4} md={6} className="container-info">
                      <FunnelInfo>
                          <div className="funnel-title">{node.name ? i18n.dashboard.funnel[node.name] : `${i18n.onboardingStatisticsItems.phase} ${item+1}`}</div>
                          <div className="funnel-value" >{node_percent ? Math.round(node_percent) : (isNumber(node.percent) ? node.percent : node)} %</div>
                      </FunnelInfo>
                  </Grid>
              </Grid>
              );
            })
            }
            <Divider className="full" />
            </Grid>

        </Grid>
      </FunnelStatisticStyled>
  )
}

FunnelStatistic.propTypes = {
  title: PropTypes.string,
  funnel: PropTypes.array,
}

FunnelStatistic.defaultProps = {
  title: "Funnel", funnel: []
}

const enharce = compose(withI18n)

export default enharce(FunnelStatistic)
