import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Icon = ({ ...props }) => (
  <SvgIcon {...props}>
      <path d="M30 15C30 23.2797 23.2797 30 15 30C6.72027 30 0 23.2909 0 15C0 6.70905 6.72027 0 15 0C23.2797 0 30 6.72027 30 15Z" fill="#F4304A"/>
      <path d="M8.49292 10.3778C8.49292 8.88562 9.41289 7.67395 10.5348 7.67395C11.6567 7.67395 12.5767 8.88562 12.5767 10.3778C12.5767 11.8699 11.6567 13.0816 10.5348 13.0816C9.41289 13.0816 8.49292 11.8699 8.49292 10.3778Z" fill="white"/>
      <path d="M17.4121 10.2655C17.4121 8.77331 18.3321 7.56165 19.454 7.56165C20.5759 7.56165 21.4959 8.77331 21.4959 10.2655C21.4959 11.7576 20.5759 12.9693 19.454 12.9693C18.3321 12.9693 17.4121 11.7576 17.4121 10.2655Z" fill="white"/>
      <path d="M6 19.6447C6.2356 20.3628 7.17801 21.8661 8.38968 21.8661C9.60135 21.8661 10.4316 18.3994 12.1032 18.3994C13.7749 18.3994 15.0314 22.371 16.3777 22.371C17.724 22.371 18.9693 19.7121 20.7083 19.7121C22.4473 19.7121 23.4121 20.8115 23.9731 22.2027" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round"/>
  </SvgIcon>
)

export default Icon
