import React from 'react'
import { ProfileProcessesInfo } from '../../organism'
import ProfileStyled from './styled'

const ProfileProcesses = () => {
  return (
    <ProfileStyled>
      <ProfileProcessesInfo />
    </ProfileStyled>
  )
}

export default ProfileProcesses
