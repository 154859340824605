import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Icon = ({fill}) => (
  <SvgIcon id='svgCircle' viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.99992" cy="7" r="6.66667" fill={fill}/>
  </SvgIcon>
)

export default Icon
