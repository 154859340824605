import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import { operations as OnboardingOperations } from '../../../redux/profileOnboarding'
import {ProcessesActions} from "../../../redux/processes";


const mapStateToProps = state => {
 return ({
    data: state.profileOnboarding.data,
    dataInboxRows: state.processes.dataInbox,
    tablePage: state.processes.page,
    tablePerPage: state.processes.perPage,
    filters: state.saveSearches.filters,
  })
}

const mapDispatchToProps = dispatch => {
  return {
    addFav: (id, isFAv, module) => dispatch(OnboardingOperations.addFav(id, isFAv, module)),
    deleteOnboarding: (id, module) => dispatch(OnboardingOperations.deleteOnboarding(id, module)),
    tableSetPage: ( page ) => dispatch(ProcessesActions.setPage(page)),
  }
}

const enharce = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withI18n)

export default enharce(View)
