import React from 'react'
import PropTypes from 'prop-types'
import {ProcessTable, ExitInterviewProfileInfo, AppBarInbox} from '../../organism'
import {MODULES} from '../../../config/constants'

const Favorites = ({ classes, i18n }) =>{
  return (
    <div className={classes.root}>
        <AppBarInbox
            showActions
            module={MODULES.ID_EXIT_INTERVIEW}
            section={"favorites"}
        />
      <ProcessTable
        title={i18n.favoritesProcessTable.title}
        module={MODULES.ID_EXIT_INTERVIEW}
        defaultFilters={{isFavorite: true}}
        showChangeStatus
        rowViewRender={(id) => <ExitInterviewProfileInfo id={id} />}
      />
    </div>
  )
}

Favorites.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default Favorites
