import * as types from './types'

const initialState = {
  data: null,
  isFetching: false,
  auxFilters: {},
  filters: {},
  filtersInState: {},
  filterTitle: null,
  filterGroupSelected: -1,
  searchFilters: {},
  searchFilterTitle: null,
  page: null,
  sort: null,
  pageSize: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_DATA:
      return { ...state, data: action.payload }
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_FILTERS:
      return { ...state, filters: { ...action.payload.filters }, filterTitle: action.payload.title }
    case types.SET_SEARCH_FILTERS:
      return { ...state, searchFilters: { ...action.payload.filters }, searchFilterTitle: action.payload.title }
    case types.SET_AUX_FILTERS:
      return { ...state, auxFilters: { ...action.payload } }
    case types.SET_SEARCH_FILTER_GROUP:
      return { ...state, filterGroupSelected: action.payload }
    case types.SET_PAGE:
      return { ...state, page: action.payload }
    case types.SET_SORT:
      return { ...state, sort: action.payload }
    case types.SET_PAGE_SIZE:
      return { ...state, pageSize: action.payload }
    case types.ADD_FILTERS_TO_STATE:
      return { ...state, filtersInState: action.payload }
    default:
      return state
  }
}
