import styled from 'styled-components'

const BoxSelectionFunnelStyled = styled.div`
  .wrapper_funnel {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    box-shadow: 0 4px 6px rgba(98, 102, 113, 0.06), 0 2px 2px rgba(98, 102, 113, 0.06);
    border-radius: 6px;
  }

  .title-funnel {
    display: flex;
    width: 210px;
    padding: 10px;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 3px 5px 0px rgba(9, 30, 66, 0.14);

    .wrapper-title {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: row;
      justify-content: center;

      span {
        color: #000;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .title-current-state {
    width: auto;
    padding: 10px;
    align-items: center;

    p {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      padding-left: 45px;
    }
  }

  .container-icon {
    display: flex;
    padding: 6px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 35px;
    background: #d1d2d3;
  }

  .container {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }

  .wrapper-phases {
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 0 10px;
    box-sizing: border-box;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(63, 67, 80, 0.24);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  .container-titles {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .group-phases {
    display: flex;
    padding: 15px 2px;
    align-items: center;
    gap: 10px;
    color: #1b1e25;
    text-align: center;
    font-size: 14px;
    font-weight: 600;

    .title-phases {
      display: flex;
      font-size: 13px;
      font-weight: 600;
    }
  }
  .phases-container {
    display: flex;
    gap: 25px;
    align-content: flex-start;
    position: relative;
  }

  .phase {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    min-height: auto;
    width: 100%;
    z-index: 1;
  }

  .status-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;
  }

  .MuiChip-label {
    font-size: 12px !important;
    font-weight: 600 !important;
    font-family: Poppins !important;
  }

  .line-horizontal {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 1px;
    width: calc(96% - 10px);
    background-color: #e0e0e0;
    margin-left: 2px;
    position: absolute;
    margin: 15px;
  }

  @media (max-width: 1200px) {
    .container-title {
      display: none;
    }
  }
`

export default BoxSelectionFunnelStyled
