import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, ProcessesStatisticsItems } from '../../organism'
import { MODULES } from '../../../config/constants'

const Statistics = ({ classes }) => (
  <div className={classes.root}>
    <AppBar module={MODULES.ID_EMPLOYEE_LINE} />
    <ProcessesStatisticsItems module={MODULES.ID_EMPLOYEE_LINE} />
  </div>
)

Statistics.propTypes = {
  classes: PropTypes.object.isRequired
}

export default Statistics
