import React from 'react'

const icon = () => (
  <svg
    fill="none"
    stroke="white"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path stroke="none" d="M0 0h24v24H0z" opacity={0.6} />
    <path d="M12 15 A4 4 0 0 1 8 19 A4 4 0 0 1 4 15 A4 4 0 0 1 12 15 z" opacity={0.6} />
    <path d="M10.85 12.15L19 4M18 5l2 2M15 8l2 2" opacity={0.6} />
  </svg>
)

export default icon
