import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import i18n from '../../../assets/i18n'
import { SelectChangeStatusFunnelStyle } from './styled'

export default function SelectChangeStatusFunnel({ options, currentStatus, onSelectedItemChange, disabled = false, label = true, placeholder = null }) {
  const [value, setValue] = React.useState(currentStatus);

  return (
    <SelectChangeStatusFunnelStyle>
      <Autocomplete
        id="change-status-funnel"
        size="small"
        value={value}
        options={options}
        groupBy={(option) => option.phase.name}
        getOptionLabel={(option) => option.name}
        style={{width: 220}}
        renderInput={(params) => <TextField {...params} placeholder={placeholder ? placeholder : ""} label={label ? i18n.profileInfo.selectionStatus : null} variant="outlined" />}
        disableClearable={true}
        onChange={(event, newValue) => {
          setValue(newValue);
          onSelectedItemChange(newValue);
        }}
        disabled={disabled === true}
      />
    </SelectChangeStatusFunnelStyle>
  );
}
