import _ from 'lodash'
import statusOptions from '../../utils/status-options'

export const isLogedUser = state => _.size(_.get(state, 'auth.token')) > 0

export const isUnauthorizedError = error => _.get(error, 'code') === 401

export const getStatusText = value => {
  const selected = _.find(statusOptions(), option => option.value === value)
  const statusLabel = _.get(selected, 'label', value)
  return statusLabel
}
