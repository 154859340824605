import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Modules } from '../../organism'
import { PageTitle } from '../../atoms'

const InboxPeople = ({ classes, i18n }) => (
  <div className={classes.root}>
    <AppBar />
    <PageTitle title={i18n.modules.title} />
    <Modules />
  </div>
)

InboxPeople.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default InboxPeople
