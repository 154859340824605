import React from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal'
import { ModalStyled } from './styled'
import { withI18n } from '../../hocs'

const ModalView = ({ open, handleClose, children }) => {
  return (
    <div>
      <Modal
        style={{ overflow: 'scroll' }}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={!!open}
        onClose={handleClose}
      >
        <ModalStyled>
          <div className="modal-body">
            {children && React.cloneElement(children, { onGoBack: handleClose })}
          </div>
        </ModalStyled>
      </Modal>
    </div>
  )
}

ModalView.propTypes = {
  open: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
}

ModalView.defaultProps = {
  filtersValues: {},
  filterName: '',
  children: <></>,
  title: ''
}

const enharce = compose(withI18n)

export default enharce(ModalView)
