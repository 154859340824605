import StatusType from '../models/status-type'
import i18n from '../assets/i18n'

export default () =>{
  return [
    { value: StatusType.NOT_INTERESTED, id: StatusType.NOT_INTERESTED, label: i18n.modalChangeStatus.not_interested, name: 'estado' },
    { value: StatusType.HIRED, id: StatusType.HIRED, label: i18n.modalChangeStatus.hired, name: 'estado' },
    { value: StatusType.BOOKING, id: StatusType.BOOKING, label: i18n.modalChangeStatus.booking, name: 'estado' },
    { value: StatusType.NOT_ATTEND, id: StatusType.NOT_ATTEND, label: i18n.modalChangeStatus.not_attend, name: 'estado' },
    { value: StatusType.CITED_PHASE_2, id: StatusType.CITED_PHASE_2, label: i18n.modalChangeStatus.cited_phase2, name: 'estado' },
    { value: StatusType.DISCARDED, id: StatusType.DISCARDED, label: i18n.modalChangeStatus.discarded, name: 'estado' },
    { value: StatusType.DISCARDED_PHASE_2, id: StatusType.DISCARDED_PHASE_2, label: i18n.modalChangeStatus.descarded_phase2, name: 'estado' },
    { value: StatusType.NOT_INTERESTED_PHASE_2, id: StatusType.NOT_INTERESTED_PHASE_2, label: i18n.modalChangeStatus.not_interested_phase2, name: 'estado' },
    { value: StatusType.NOT_ATTEND_PHASE_2, id: StatusType.NOT_ATTEND_PHASE_2, label: i18n.modalChangeStatus.not_attend_phase2, name: 'estado' },
    { value: StatusType.IN_PROGRESS, id: StatusType.IN_PROGRESS, label: i18n.modalChangeStatus.in_progress, name: 'estado' },
    { value: StatusType.DOC_REQUIRED, id: StatusType.DOC_REQUIRED, label: i18n.modalChangeStatus.doc_required, name: 'estado' },
    { value: StatusType.DOC_RECEIVED, id: StatusType.DOC_RECEIVED, label: i18n.modalChangeStatus.doc_received, name: 'estado' },
    { value: StatusType.PENDING_UPDATE, id: StatusType.PENDING_UPDATE, label: i18n.modalChangeStatus.pending_update, name: 'estado' }
  ]
}