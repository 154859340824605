import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Icon = () => (
  <SvgIcon width="13" height="14" viewBox="0 0 13 14" fill="none">
    <path
      d="M2.474 4H10.5255C10.7865 4 11 4.2135 11 4.4745V4.5255C11 4.7865 10.7865 5 10.5255 5H2.474C2.213 5 2 4.7865 2 4.5255V4.4745C2 4.2135 2.213 4 2.474 4Z"
      fill="black"
    />
    <path
      d="M10.5255 6.5H2.474C2.213 6.5 2 6.7135 2 6.9745V7.0255C2 7.2865 2.213 7.5 2.474 7.5H10.5255C10.7865 7.5 11 7.2865 11 7.0255V6.9745C11 6.7135 10.7865 6.5 10.5255 6.5Z"
      fill="black"
    />
    <path
      d="M10.5255 9H2.474C2.213 9 2 9.2135 2 9.4745V9.5255C2 9.7865 2.213 10 2.474 10H10.5255C10.7865 10 11 9.7865 11 9.5255V9.4745C11 9.2135 10.7865 9 10.5255 9Z"
      fill="black"
    />
  </SvgIcon>
)

export default Icon
