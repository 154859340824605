import * as types from './types'
import _ from 'lodash'
import i18n from '../../assets/i18n'

const initialState = {
  data: null,
  isFetching: false,
  responsible: null,
  filters: null,
  filtersExtend: null,
  page: 0,
  sort: null,
  pageSize: 20,
  reportFields: [],
  rowsSelected: [],
  editCounter: 0,
  whatsappCounter: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_DATA:
      return { ...state, data: action.payload }
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_RESPONSIBLE:
      return { ...state, responsible: action.payload, filtersExtend: [{
          id: -2,
          answers: _.map(action.payload, 'id'),
          description: null,
          module_type: {id: "talent_acquisition", name: "talent_acquisition"},
          name: i18n.modalAnnouncement.responsible,
          order: 0,
          remote_name: "user.id",
          type: "select",
          filter_block: { name: i18n.modalAnnouncement.filterBlockCitation }
        }]}
    case types.SET_PAGE:
      return { ...state, page: action.payload }
    case types.SET_SORT:
      return { ...state, sort: action.payload }
    case types.SET_PAGE_SIZE:
      return { ...state, pageSize: action.payload }
    case types.SET_REPORT_FIELDS:
      return { ...state, reportFields: action.payload }
    case types.SET_ROWS_SELECTED:
      return { ...state, rowsSelected: action.payload }
    case types.SET_EDIT_COUNTER:
      return { ...state, editCounter: action.payload }
    case types.SET_WHATSAPP_COUNTER:
      return { ...state, whatsappCounter: action.payload }
    default:
      return state
  }
}
