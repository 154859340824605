import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import { QUERY_KEYS } from '../../config/constants'

async function getUsers() {
  return await get(`/api/users`)
}

export function useUsers() {
  return useQuery([QUERY_KEYS.USERS], () => getUsers());
}

async function getUsersForFilters() {
  return await get(`/api/users-for-filters`)
}

export function useUsersForFilters(options = {}) {
  if (typeof options !== 'object') {
    options = {};
  }
  return useQuery([QUERY_KEYS.USERS_FOR_FILTERS], () => getUsersForFilters(), options);
}

async function getUser(id) {
  return await get(`/api/users/${id}`)
}

export function useUser(id) {
  return useQuery([QUERY_KEYS.USER, id], () => (id ? getUser(id) : null));
}