import styled from 'styled-components'
import colors from '../../../assets/colors'

export const ModalStyled = styled.div`
  && {
    border-radius: 25px;
    margin: 0 auto;
    max-width: 100%;
    margin-top: 26vh;
    width: 600px;
    outline: none;

    .modal-body {
      background-color: ${colors.white};
      padding: 0 50px 50px 50px;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
      outline: none;
      font-family: 'Poppins';

      .grid-decoration {
        position: relative;
        ::before {
          content: '-';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -4px;
        }
      }

      h4 {
        text-align: center;
      }

      h5 {
        text-align: center;
        font-style: italic;
      }

      input {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins';
      }

      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        margin-top: 20px;
      }

      .button-wrapper {
        text-align: right;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 10px;

        button {
          margin-top: 15px;
        }
      }

      .button-custom {
        border-radius: 10px;
        color: #ffffff;
      }

      .wrapper-tags {
        background: #ffffff;
        border-radius: 8px;
        border: 1.5px solid ${colors.blackAlpha41};
        padding: 12px 16px 12px 16px;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-start;
        box-shadow: 0px 0px 4px 0px rgba(23, 25, 28, 0.05);
      }

      .wrapper-data {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        h5 {
          font-weight: 400;
        }
      }
    }
    .loading-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
      width: 300px;
      margin: 0 auto;
      margin-top: 20px;
    }

    .MuiCircularProgress-root {
      margin: 0 auto;
      width: 25px !important;
      height: 25px !important;
    }

    .disabled-input {
      background-color: #f0f0f0;
      color: #888888;
      user-select: none;
    }
  }
`
