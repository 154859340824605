import styled from 'styled-components'

const PhasesStatesStyled = styled.div`
  .containerHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    padding: 36px 49px;
    border-radius: 25px;
    background: #ffffff;
    gap: 50px;
  }

  .sectionFirstText {
    width: 100%;

    h2 {
      font-size: 28px;
      font-weight: 600;
      color: #000000;
    }
    h5 {
      font-weight: 400;
      color: #7a808d;
      margin-bottom: 33px;
    }
  }

  .containerSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 150px;

    @media screen and (max-width: 780px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .phasesContainer {
    flex: 1;
    max-width: 100%;
  }

  .statesContainer {
    flex: 1;
    max-width: 100%;
  }
`

export default PhasesStatesStyled
