import styled from 'styled-components'
import colors from '../../../assets/colors'

const MyProfileIaEvaluationWizardStyled = styled.div`
  .containerHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    padding: 36px 49px;
    border-radius: 25px;
    background: #ffffff;
    gap: 50px;
  }

  .sectionSecond {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;

    label {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0px;
      margin-right: 20px;
    }
    input {
      font-size: 14px;
      font-family: 'Poppins';
      width: 25vw;
    }

    & h2 {
      font-size: 28px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0;
    }
    & h5 {
      color: #7a808d;
      font-weight: 400;
      line-height: inherit;
    }

    & p {
      font-weight: 400;
      color: #7a808d;
    }
  }
    
  .alertInfoValuesSection {
      display: flex;
      justify-content: flex-start;
      gap: 32px;
      color: #000;
      align-items: center;
      position: relative;
      
      .iconAlertInfo {
          position: absolute;
          left: 15px;
      }
      
      .alertInfo1 {
          margin-left: 20px;
      }
  }  
    
  .autocomplete-custom {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 82px !important;
    width: 25vw !important;
    min-width: 150px!important;
  }

  .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-input:first-child {
    padding-left: 12px !important;
  }
  .MuiAutocomplete-inputRoot {
    padding: 4px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 12px) !important;
  }
    
  .containerButton {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    height: 36px;
  }
    
  .configureCriteriaSection {
      margin: 16px;
      padding: 0;
      min-width: 200px;
      background-color: ${colors.white};
      border-radius: 10px;
      box-shadow: 0 2px 10px -6px;
  }
    
  .relevant_radio {
      color: #F6C036!important;
  }
    
  .desired_radio {
      color: #37BC6A!important;
  }
    
  .mandatory_radio {
      color: #D86262!important;
  }
    
  .ignore_radio {
      color: #646464!important;
  }
`

export default MyProfileIaEvaluationWizardStyled
