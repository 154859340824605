import React from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'

import {Tooltip} from "@material-ui/core";
import OFFER_CANDIDATURE_STATUS from "../../../models/offer-candidature-status-type";
import {ReactComponent as Interested} from "../../../assets/images/userStatus/suitable.svg";
import {ReactComponent as NotInterested} from "../../../assets/images/userStatus/no-suitable.svg";
import {ReactComponent as NotAttend} from "../../../assets/images/userStatus/to-be-confirmed.svg";

const OfferStateImage = ({ state, i18n, bigSize = false }) => {

  if (!state) {
    return <></> // ignore
  }

  let icon

  if (state === OFFER_CANDIDATURE_STATUS.INTERESTED)
    icon = <Interested width={bigSize ? 24 : 18} height={bigSize ? 24 : 18}/>
  if (state === OFFER_CANDIDATURE_STATUS.NOT_INTERESTED)
    icon = <NotInterested width={bigSize ? 24 : 18} height={bigSize ? 24 : 18}/>
  if (state === OFFER_CANDIDATURE_STATUS.UNANSWERED)
    icon = <NotAttend width={bigSize ? 24 : 18} height={bigSize ? 24 : 18}/>

  return <Tooltip title={i18n.offerStates[state]}>{icon}</Tooltip>
}

OfferStateImage.defaultProps = {

}

OfferStateImage.propTypes = {
  offerHasCandidature: PropTypes.object
}

const enharce = compose(withI18n)

export default enharce(OfferStateImage)
