import React, { useEffect, useState } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeaderProcess, RegularButton, CustomAlert } from '../../atoms'
import RegularButtonOutline from '../../atoms/regular-button-outline'
import { useMutation } from "@tanstack/react-query";
import CircularProgress from '@material-ui/core/CircularProgress'

const customStyles = {
  overflow: 'scroll'
}

const ModalConfirmIntegration = ({ open, handleClose, i18n, formData, isEdit, handleCreateIntegration, handleUpdateIntegration, onDataUpdate, isDisabledButton, setIsDisabledButton }) => {

  const [showAlert, setShowAlert] = useState(false)
  const [alert, setAlert] = useState({ message: '', severity: '' });

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const mutationCreateIntegration = useMutation(handleCreateIntegration);
  const mutationUpdateIntegration = useMutation(handleUpdateIntegration);

  const handleConfirmIntegration = async (e) => {
    e.preventDefault();
    try {
      const integrationData = {
        candidature: formData.positionUser.id,
        provider: formData.recruitmentSource.id,
        concat_url: formData.concatUrl,
        user: formData.username,
        password: formData.passwordUser,
        keyword: formData.generatedKeyword,
        login_url: formData.ePreselecUrl,
      };
      setIsDisabledButton(true);

      if (isEdit) {
        await mutationUpdateIntegration.mutateAsync(integrationData);
        setAlert({ message: i18n.keywords_rpa.successUpdateIntegration, severity: 'success' });
        setShowAlert(true);
      } else {
        await mutationCreateIntegration.mutateAsync(integrationData);
        setAlert({ message: i18n.keywords_rpa.successCreateIntegration, severity: 'success' });
        setShowAlert(true);
      }
      setTimeout(() => {
        handleModalClose();
        handleClose();
        onDataUpdate();
      }, 2000);
    } catch (error) {
      if (isEdit) {
        setAlert({ message: i18n.keywords_rpa.errorUpdateIntegration, severity: 'error' });
        setShowAlert(true);
      } else {
        setAlert({ message: i18n.keywords_rpa.errorCreateIntegration, severity: 'error' });
        setShowAlert(true);
        console.error('Error durante la mutación:', error);
      }
    }
  };

  const handleModalClose = () => {
    handleClose();
  };

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleModalClose}
      >
        <ModalStyled>
          <ModalHeaderProcess closeClick={handleModalClose} hasCloseIcon={true} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center" justifyContent='center'>
                <h4>{isEdit ? i18n.keywords_rpa.messageConfirmDataEdit : i18n.keywords_rpa.messageConfirmData}</h4>
                <div className="wrapper-data">
                  <h5>{i18n.keywords_rpa.titlePosition} &quot;{formData.positionUser.name}&quot;</h5>
                  <h5>{i18n.keywords_rpa.titleSource} {formData.recruitmentSource.name}</h5>
                  {formData && formData.concatUrl && <h5>{i18n.keywords_rpa.titleConcatUrl} {formData.concatUrl}</h5>}
                  <h5>{i18n.keywords_rpa.titleUser} {formData.username}</h5>
                  <h5>{i18n.keywords_rpa.titleKeyword} {formData.generatedKeyword || formData.keyword}</h5>
                </div>
              </Grid>
              <div className="button-wrapper">
                <RegularButtonOutline title={i18n.keywords_rpa.buttonCancelIntegration} handleOnClick={handleClose} />
                <RegularButton
                  className="button-custom"
                  title={i18n.keywords_rpa.buttonConfirmIntegration}
                  onClick={handleConfirmIntegration}
                  disabled={isDisabledButton}
                />
              </div>       
              </form>
            <div className="loading-container">
              {mutationCreateIntegration.isLoading && <CircularProgress />}
              {mutationUpdateIntegration.isLoading && <CircularProgress />}
              {showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
            </div>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(ModalConfirmIntegration)