import React, {useCallback, useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import {RegularButton} from '../../atoms'
import qs from 'qs'
import _ from 'lodash'
import * as api from '../../../api'
import * as S from './styled'
import offerCandidatureStatusTypes from '../../../models/offer-candidature-status-type'
import Typography from "@material-ui/core/Typography";

const LandingOffer = withRouter(({ classes, location, i18n }) => {

  const search = decodeURIComponent(_.get(location, 'search'))
  const params = qs.parse(search, { ignoreQueryPrefix: true })
  const [response, setResponse] = useState({})
  const [isEnded, setIsEnded] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isExpired, setIsExpired] = useState(false)
  const [isError, setIsError] = useState(false)

  const data = useCallback(async () => {
    try {
      setIsFetching(true)
      const response = await api.getOfferByNotification(params.notificationId, params.offerId, params.candidatureId, params.pin)
      setResponse(response.data)
      if(new Date() > new Date(response.data.offer.date_to)){
        setIsExpired(true)
      }
      checkForAutoUpdate();

    } catch (e) {
      setIsFetching(false)
      setIsError(true)
    }
  }, [params.notificationId, params.offerId, params.candidatureId, params.pin])

  const checkForAutoUpdate = () => {
    if (params.state) {
      changeState(params.state).finally(() => setIsFetching(false));
    } else {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    data()
    // eslint-disable-next-line
  }, [])

  let changeState = (newState) => api.patchOfferByNotification(params.notificationId, params.offerId, params.candidatureId, params.pin, newState).then(() => {
    setIsEnded(true)
  });

  return (
    <Container component="main" className={classes.root}>
      {isEnded === false && isExpired === false && response.offer && !isFetching ? (
        <>
          <S.InfoContainer>
            <S.Text>
              {i18n.landingOffer.title} <span className="bold">{response.candidature.nombre}</span>,
            </S.Text>
            <S.Text>
              <span dangerouslySetInnerHTML={{__html: i18n.landingOffer.body}}/> <span className="bold">{response.offer.name}</span>.
            </S.Text>
            <S.Text className="uppercase bold">{i18n.landingOffer.offerInfo}</S.Text>
            <S.Text dangerouslySetInnerHTML={{__html: response.offer.description}}>
            </S.Text>
          </S.InfoContainer>
          <S.ButtonsContainer>
            <S.ButtonContainer>
              <RegularButton
                title={i18n.landingOffer.interested}
                color="#1BB247"
                onClick={() => {
                  changeState(offerCandidatureStatusTypes.INTERESTED)
                }}
              />
            </S.ButtonContainer>
            <S.ButtonContainer>
              <RegularButton
                title={i18n.landingOffer.notInterested}
                color="#D3241F"
                onClick={() => {
                  changeState(offerCandidatureStatusTypes.NOT_INTERESTED)
                }}
              />
            </S.ButtonContainer>
          </S.ButtonsContainer>
        </>
      ) : (
        ''
      )}
      {isError ? (
        <S.InfoContainer>
          <S.Warning className="align-center">{i18n.landingOffer.notExist}</S.Warning>
        </S.InfoContainer>
      ) : (
        ''
      )}
      {isExpired ? (
          <S.InfoContainer>
            <Typography className={classes.message} dangerouslySetInnerHTML={{ __html: i18n.landingOffer.expiredDescription }} />
          </S.InfoContainer>
      ) : ''}
      {isEnded ? (
          <S.InfoContainer>
            <Typography className={classes.message} dangerouslySetInnerHTML={{ __html: i18n.landingOffer.endDescription }} />
          </S.InfoContainer>
      ) : ''}
    </Container>
  )
})

LandingOffer.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object
}

export default LandingOffer
