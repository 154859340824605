import { actions } from './'
import 'core-js'
import { toast } from 'react-toastify'
import { MODULES } from '../../config/constants'
import {replaceUrlTextByHtmlLink} from "../../utils/url";

export const initProcess = (id, moduleId=MODULES.ID_EMPLOYEE_LINE) => (dispatch) => {
  dispatch(actions.resetData())
  dispatch(fetchProcess(id, moduleId))
  dispatch(fetchComments(id, moduleId))
}

export const fetchProcess = (id, moduleId=MODULES.ID_EMPLOYEE_LINE) => (dispatch, getState, { api }) => {
  dispatch(actions.setIsFetching(true))
  api.getInboxItem(id, moduleId).then(response => {
    dispatch(actions.setData(response))
  })
  dispatch(actions.setIsFetching(false))
}

export const fetchComments = (id, moduleId=MODULES.ID_EMPLOYEE_LINE) => (dispatch, getState, { api }) => {
  dispatch(actions.setIsFetching(true))
  api
    .getUserObservations(id, moduleId)
    .then(response => {
      dispatch(actions.setComments(response))
    })
    .finally(() => {
      dispatch(actions.setIsFetching(false))
    })
}

export const addComment = (id, comment, moduleId=MODULES.ID_EMPLOYEE_LINE) => (dispatch, getState, { api, i18n }) => {
  dispatch(actions.setIsFetching(true))
  api
    .postUserObservations(id, comment, moduleId)
    .then(() => {
      dispatch(fetchComments(id, moduleId))
      toast('Comentario añadido', { type: toast.TYPE.SUCCESS })
    })
    .catch(() => toast(i18n.generalError, { type: toast.TYPE.ERROR }))
    .finally(() => {
      dispatch(actions.setIsFetching(false))
    })
}

export const changeStatus = (id, status, moduleId=MODULES.ID_EMPLOYEE_LINE) => (dispatch, getState, { api, handleError, i18n }) => {
  dispatch(actions.setIsFetching(true))
  const newData = { ...getState().profileProcesses.data, state: status }
  dispatch(actions.setData(newData))
  return api
    .changeStatus(
      [
        {
          id: id,
          status_id: status.id
        }
      ],
      moduleId
    )
    .then(() => {
      dispatch(fetchProcess(id, moduleId))
      toast(i18n.process.modifiedStatus, { type: toast.TYPE.SUCCESS })
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(dispatch(actions.setIsFetching(false))))
}

export const addFav = (id, isFav, moduleId=MODULES.ID_EMPLOYEE_LINE) => (dispatch, getState, { api }) => {
  dispatch(actions.setIsFetching(true))
  if (isFav) {
    const newData = { ...getState().profileProcesses.data, is_favorite: true }
    dispatch(actions.setData(newData))
    api
      .postFavorites([id], moduleId)
      .then(() => {
        dispatch(fetchProcess(id, moduleId))
        toast('Modificado proceso', { type: toast.TYPE.SUCCESS })
      })
      .finally(() => {
        dispatch(actions.setIsFetching(false))
      })
  } else {
    const newData = { ...getState().profileProcesses.data, is_favorite: false }
    dispatch(actions.setData(newData))
    api
      .deleteFavorites([id], moduleId)
      .then(() => {
        dispatch(fetchProcess(id, moduleId))
        toast('Modificado proceso', { type: toast.TYPE.SUCCESS })
      })
      .finally(() => {
        dispatch(actions.setIsFetching(false))
      })
  }
}

export const markAsRead = (id, moduleId=MODULES.ID_EMPLOYEE_LINE) => (dispatch, getState, { api }) => {
  api.postReads([id], moduleId)
}

export const sendEmailCustom = (id, subject, message, moduleId = MODULES.ID_EMPLOYEE_LINE) => (dispatch, getState, { api, i18n }) => {
  message = replaceUrlTextByHtmlLink(message)
  api
    .postMailCustom(id, subject, message, moduleId)
    .then(() => {
      dispatch(fetchComments(id, moduleId))
      toast(i18n.modalMailCustom.sendCompleted, { type: toast.TYPE.SUCCESS })
    })
    .finally(() => {
      dispatch(actions.setIsFetching(false))
    })
}

export const sendEmailsCustom = (ids, subject, message, moduleId = MODULES.ID_EMPLOYEE_LINE) => (dispatch, getState, { api, i18n }) => {
  message = replaceUrlTextByHtmlLink(message)

  return Promise.all(ids.map((id) =>
    api.postMailCustom(id, subject, message, moduleId)
  ))
  .then(() => {
    toast(i18n.modalMailCustom.sendCompleted, { type: toast.TYPE.SUCCESS })
  })
}

export const deleteProcess = (id, moduleId) => (dispatch, getState, { api, handleError }) => {
  dispatch(actions.setIsFetching(true))
  return api
    .deleteInboxCandidature(id, moduleId)
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(actions.setIsFetching(false)))
}
