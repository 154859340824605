import React from 'react'

const icon = props => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7789 2.41909C11.8727 2.2413 12.1273 2.2413 12.2211 2.41908L14.9991 7.68439C15.0353 7.75295 15.1012 7.80085 15.1776 7.81407L21.0437 8.82904C21.2417 8.86332 21.3204 9.10547 21.1803 9.24962L17.0312 13.5187C16.9771 13.5743 16.952 13.6518 16.963 13.7286L17.8104 19.6212C17.839 19.8201 17.633 19.9698 17.4526 19.8811L12.1103 17.2542C12.0407 17.22 11.9593 17.22 11.8897 17.2542L6.54736 19.8811C6.36698 19.9698 6.16098 19.8201 6.1896 19.6212L7.03701 13.7286C7.04804 13.6518 7.02286 13.5743 6.96883 13.5187L2.81968 9.24962C2.67958 9.10548 2.75826 8.86332 2.95634 8.82904L8.82239 7.81407C8.89877 7.80085 8.96471 7.75295 9.00088 7.68439L11.7789 2.41909Z"
      stroke="#F6C036" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default icon