import React, { useState } from 'react'
import compose from 'recompose/compose'
import * as S from './styled'
import PropTypes from 'prop-types'
import { MTDocument, ModalHeader } from '../../atoms'
import { DocumentsListViewer } from '../'
import FileIcon from '../../../assets/images/file.svg'
import _ from 'lodash'

const Panel = ({ value, check, onChange, options }) => {
  const [docModal, setDocModal] = useState(false);

  const handleDocumentClick = id => {
    setDocModal(id);
  }

  const handleCloseModal = () => {
    handleDocumentClick(null);
  }

  const handleCheckChange = item => {
    const { id } = item;
    if (_.find(value, { id: id })) {
      const newValue = [...value];
      _.remove(newValue, { id: id });
      onChange(newValue);
    } else {
      const newValue = [...value];
      newValue.push(item);
      onChange(newValue);
    }
  }

  return (
    <>
      <S.Container className="DocList">
        {options.map(item => {
          const { id, file, name } = item;
          const isNotChecked = !_.find(value, { id: id });
          return (
            <S.Item key={id} className="DocItem">
              {check && <S.Check checked={!isNotChecked} onChange={() => handleCheckChange(item)} />}
              <MTDocument
                className="DocButton"
                width={200}
                url={file}
                name={
                  <S.Link className="DocLink" href={file}  target="_blank">
                    {name}
                  </S.Link>
                }
                onClick={() => handleDocumentClick(id)}
              />
            </S.Item>
          )
        })}
      </S.Container>
      <S.ModalContainer open={docModal} onClose={() => null}>
        <S.ModalContent>
          <ModalHeader title="Documentos" Icon={<img alt="icon header" src={FileIcon} />} closeClick={handleCloseModal} />
          <DocumentsListViewer options={options} selected={docModal} />
        </S.ModalContent>
      </S.ModalContainer>
    </>
  )
}

Panel.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  check: PropTypes.bool,
  options: PropTypes.array
}

Panel.defaultValues = {
  value: [],
  onChange: () => null,
  check: false,
  options: []
}

const enharce = compose()

export default enharce(Panel)
