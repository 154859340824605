import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import _ from 'lodash'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { toast } from 'react-toastify'
import * as api from '../api/'
import * as reducers from '../redux/'
import i18n from '../assets/i18n/'
import { AuthOperations } from '../redux/auth'
import { ErrorOperations } from '../redux/error/'
import { TOKEN_KEY } from './constants'

//Add all reducers to black list
const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: _.map(reducers, (_, key) => key)
}

//Add reducer-properties to white list
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: [TOKEN_KEY, 'token', 'id', 'email', 'data', 'url', 'client']
}

const rootReducer = combineReducers({
  ...reducers,
  auth: persistReducer(authPersistConfig, reducers.auth)
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

//Middlewares
const thunkMiddleware = thunk.withExtraArgument({ i18n, api, toast, handleError: ErrorOperations.handleError })
const loggerMiddleware = createLogger({ collapsed: true })
const middlewares =
  process.env.NODE_ENV === 'production' || typeof window !== 'undefined' ? applyMiddleware(thunkMiddleware) : applyMiddleware(thunkMiddleware, loggerMiddleware)

//process.env.NODE_ENV
const store = createStore(persistedReducer, middlewares)
const persistor = persistStore(store, {}, () => store.dispatch(AuthOperations.checkSession()))

export { store, persistor }
