import styled from 'styled-components'

const ModulesStyled = styled.div`
  .title-container {
    margin: 38px 0;

    .title {
      align-items: flex-end;
      display: flex;
    }
  }

  .modules-wrapper {
    padding: 50px 0;
  }

  .buttons-wrapper {
    text-align: right;
  }
  
  .container-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    
    &.is-fetching div{
      background-color: #f2f2f2;
    }
    
    & > div {
      font-size: 1.3em;
    }
  }
  
  .phases_container {
    background-color: white;
  }

  .MuiTypography-h6 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
  }
  
  .bJaNID {
    text-align: center;
  }

  .MuiFormControl-root.MuiTextField-root.MTableToolbar-searchField-156 {
    margin-top: 64px;
  }
`

export default ModulesStyled
