import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { withI18n } from '../../hocs'
import styles from './styles'
import {ReadsOperations} from "../../../redux/reads";
import {SaveSearchesActions} from "../../../redux/savesearches";

const mapStateToProps = state => {
  return {
    //filterGroupSelected: state.saveSearches.filterGroupSelected,
    data: state.processes.dataInbox,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    postReads: offerId => dispatch(ReadsOperations.postReads([offerId])),
    //setFilterGroupSelected: selected => dispatch(SaveSearchesActions.setFilterGroup(selected)),
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withStyles(styles)
)

export default enharce(View)
