import {ProfileActions} from './'
import 'core-js'
import _ from 'lodash'
import {MODULES} from "../../config/constants";
import {toast} from "react-toastify";
import i18n from "../../assets/i18n";

export const fetchUser = (candidatureId) => (dispatch, getState, { api, handleError }) => {

  dispatch(ProfileActions.setIsFetching(true))
  return api
    .getInboxItem(candidatureId, MODULES.ID_TALENT_ACQUISITION)
    .then(dataRes => dispatch(ProfileActions.setData(dataRes)))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(ProfileActions.setIsFetching(false)))
}

export const fetchUserList = candidatureIds => (dispatch, getState, { api, handleError }) => {
  dispatch(ProfileActions.setIsFetchingUserList(true))

  const usersPromises = _.map(candidatureIds, (candidatureId)=> api.getInboxItem(candidatureId))

  return Promise.all(usersPromises)
    .then(dataRes => {
      const observationsPromises = _.map(dataRes, user => {
        return api.getUserObservations(user.id)
      })

      return Promise.all(observationsPromises)
        .then(obsRes => {
          _.map(dataRes, (user, key) => {
            _.assign(dataRes[key], { observations: obsRes[key] })
          })
          dispatch(ProfileActions.setMultipleData(dataRes))
        })
        .finally(dispatch(ProfileActions.setIsFetchingUserList(false)))
    })
    .catch(error => {
      dispatch(ProfileActions.setIsFetchingUserList(false))
      dispatch(handleError(error))
      throw error
    })
}

export const fetchObservations = candidatureId => (dispatch, getState, { api, handleError }) => {
  dispatch(ProfileActions.setIsFetchingObservations(true))
  return api
    .getUserObservations(candidatureId)
    .then(dataRes => {
      dispatch(ProfileActions.setObservations(dataRes))
    })
    .catch(error => {
      dispatch(ProfileActions.setIsFetchingObservations(false))
      dispatch(handleError(error))
      throw error
    })
    .finally(() => {
      dispatch(ProfileActions.setIsFetchingObservations(false))
    })
}

export const postObservations = (candidatureId, message) => (dispatch, getState, { api, handleError }) => {
  dispatch(ProfileActions.setIsFetchingObservations(true))

  return api
    .postUserObservations(candidatureId, message)
    .then(() => dispatch(fetchObservations(candidatureId)))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(ProfileActions.setIsFetchingObservations(false)))
}

export const fetchPushOffers = candidatureId => (dispatch, getState, { api, handleError }) => {
  dispatch(ProfileActions.setIsFetchingPushOffers(true))
  return api
    .getPushOffersByCandidature(candidatureId)
    .then(dataRes => {
      dispatch(ProfileActions.setPushOffers(dataRes))
    })
    .catch(error => {
      dispatch(ProfileActions.setIsFetchingPushOffers(false))
      dispatch(handleError(error))
      throw error
    })
    .finally(() => {
      dispatch(ProfileActions.setIsFetchingPushOffers(false))
    })
}

export const updateProfileField = (profile, field, value, phase = null) => () => {
  try {
    profile = {...profile, [field]: value};
    let editableFields = profile['editable_fields'];
    if (phase && editableFields[phase]) {
      editableFields = [editableFields[phase]];
    } else if (!Array.isArray(editableFields)) {
      editableFields = [editableFields];
    }

    if (Array.isArray(editableFields)) {
      editableFields.map(item => {
        if (item.remote_name === field) {
          item.value = value;
        }
        return item;
      });
    } else {
      // eslint-disable-next-line no-console
      console.error("editableFields is not an array:", editableFields);
    }

    profile = {...profile, editable_fields: editableFields};
    toast.success(i18n.boxEditableInfo.success);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error in updateProfileField:", e);
    toast.error(i18n.boxEditableInfo.error);
  }
}
