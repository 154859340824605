import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Icon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" >
    <path d="M7 18L6.8 18C5.11984 18 4.27976 18 3.63803 17.673C3.07354 17.3854 2.6146 16.9265 2.32698 16.362C2 15.7202 2 14.8802 2 13.2L2 12.8C2 11.1198 2 10.2798 2.32698 9.63803C2.6146 9.07354 3.07354 8.6146 3.63803 8.32698C4.27976 8 5.11984 8 6.8 8L17.2 8C18.8802 8 19.7202 8 20.362 8.32698C20.9265 8.6146 21.3854 9.07354 21.673 9.63803C22 10.2798 22 11.1198 22 12.8L22 13.2C22 14.8802 22 15.7202 21.673 16.362C21.3854 16.9265 20.9265 17.3854 20.362 17.673C19.7202 18 18.8802 18 17.2 18L17 18" stroke="#7A808D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <rect x="17" y="22" width="10" height="8" rx="2" transform="rotate(180 17 22)" stroke="#7A808D" stroke-width="2" stroke-linejoin="round" />
    <path d="M17 8L7 8L7 5.2C7 4.0799 7 3.51984 7.21799 3.09202C7.40973 2.71569 7.7157 2.40973 8.09202 2.21799C8.51984 2 9.0799 2 10.2 2L13.8 2C14.9201 2 15.4802 2 15.908 2.21799C16.2843 2.40973 16.5903 2.71569 16.782 3.09202C17 3.51984 17 4.07989 17 5.2L17 8Z" stroke="#7A808D" stroke-width="2" stroke-linejoin="round" />
  </SvgIcon>
)

export default Icon
