import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import {operations as OnboardingOperations} from '../../../redux/profileOnboarding'
import {CitationsOperations} from "../../../redux/citations";
import {FavoritesOperations} from "../../../redux/favorites";
import {MODULES} from "../../../config/constants";
import {ProcessesOperations} from "../../../redux/processes";

const mapStateToProps = state => ({
    data: state.profileOnboarding.data,
    user: state.auth.data,
    isFetching: state.profileOnboarding.isFetching,
    tagsTypes: state.processes.tagsTypes
})

const mapDispatchToProps = dispatch => {
  return {
    fetchOnboarding: id => dispatch(OnboardingOperations.fetchOnboarding(id)),
    initOnboarding: id => dispatch(OnboardingOperations.initOnboarding(id)),
    markAsRead: id => dispatch(OnboardingOperations.markAsRead(id)),
    postFavorites: (ids) => dispatch(CitationsOperations.postFavorites(ids, MODULES.ID_ON_BOARDING)),
    deleteFavorites: ids => dispatch(FavoritesOperations.deleteFavorites(ids, MODULES.ID_ON_BOARDING)),
    changeTags: (ids, tags, queryParams, module, showFilterGroup) => dispatch(ProcessesOperations.changeTags(ids, tags, queryParams, module, showFilterGroup)),
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n
)

export default enharce(View)
