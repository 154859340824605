import React, { useEffect, useState } from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'
import moment from 'moment-timezone'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeader, RegularButton, InputField, DateInput, TimeInput, InputEditor } from '../../atoms'
import { AlertDialog, SimpleDialog } from '../../molecules'
import { ReactComponent as ModalIcon } from '../../../assets/images/calendar.svg'
import { useLocation } from "react-router";
import useDefaultMessages from "../../../api/queries/useDefaultMessages";
import { useAppContext } from "../../../context/appContext";
import { getCurrentSectionKey, INTEGRATIONS, MODULES, SECTION_KEYS } from "../../../config/constants";
import Switch from '@material-ui/core/Switch';
import Divider from "@material-ui/core/Divider";

const ModalAnnouncementQuery = ({
  open,
  handleClose,
  i18n,
  onSubmit,
  self,
  sms,
  rowsSelected = 0,
  data = null,
  suggestChangeStatusModal,
  isEditFromTab,
 }) => {

  const { module, clearContextIfNeeded } = useAppContext();
  const currentLocation = useLocation();

  const [openPopup, setOpenPopup] = React.useState(false)
  const [isSwitchActive, setIsSwitchActive] = React.useState(false)
  const [eventName, setEventName] = React.useState('')
  const [optionalAttendees, setOptionalAttendees] = React.useState('')
  const [meetingData, setMeetingData] = React.useState(null);
  const [location, setLocation] = React.useState('')
  const [interviewer, setInterviewer] = React.useState('');
  const [message, setMessage] = React.useState('')

  const [date, setDate] = React.useState(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Sumar un día
    return currentDate;
  });

  const [startTime, setStartTime] = React.useState(moment().add(1, 'days').set({ hour: 8, minute: 0 }).format('HH:mm'));
  const [endTime, setEndTime] = React.useState(moment().add(1, 'days').set({ hour: 9, minute: 0 }).format('HH:mm'));
  const [isConfirmationModalOnCitations, setIsConfirmationModalOnCitations] = useState(false)
  const [external_id, setExternalId] = useState(null)
  const [citation_id, setCitationId] = useState(null);

  const { data: defaultMessages } = useDefaultMessages(module);

  const userTimeZone = moment.tz.guess();
  // const userTimeZone = 'Europe/London';

  useEffect(() => {
    clearContextIfNeeded(MODULES.currentModule());
  }, [module, currentLocation.pathname]);

  useEffect(() => {
    if (data && !_.isEmpty(data)) {
      if (data.hasOwnProperty('citationDate')) {
        let tmpDate = null;

        let timezonedInitDate = null;
        let timezonedEndDate = null;
        let startTime = null;
        let endTime = null;

        if(data.citationDate && data.citationDate.date) {
          tmpDate = moment.tz(data.citationDate.date, data.citationDate.timezone);
          timezonedInitDate = tmpDate.clone().tz(userTimeZone);
          startTime = timezonedInitDate.format('HH:mm');
        }
        if(data.citationEndDate && data.citationEndDate.date) {
          tmpDate = moment.tz(data.citationEndDate.date, data.citationEndDate.timezone);
          timezonedEndDate = tmpDate.clone().tz(userTimeZone);
          endTime = timezonedEndDate.format('HH:mm')
        }

        setDate(timezonedInitDate ? timezonedInitDate.toDate() : (new Date()));
        setStartTime(startTime);
        setEndTime(endTime);
      }

      setEventName(data.hasOwnProperty('eventName') ? data.eventName : '');
      setOptionalAttendees(getCurrentSectionKey() !== SECTION_KEYS.INBOX && data.hasOwnProperty('optionalAttendees') ? data.optionalAttendees : '');
      setLocation(data.hasOwnProperty('location') ? data.location : '');
      setInterviewer(data.hasOwnProperty('interviewer') ? data.interviewer : '');
      setMessage(data.hasOwnProperty('message') ? data.message : '');
      setIsSwitchActive(data.hasOwnProperty('external_id') &&
        data.hasOwnProperty('link_meeting') &&
        !_.isEmpty(data.link_meeting));
      setExternalId((data.hasOwnProperty('external_id') && data.external_id !== '') ? data.external_id : null);
      let auxMeetingData = [];
      auxMeetingData['attendees'] = data.hasOwnProperty('attendees') ? data.attendees : [];
      auxMeetingData['organizer'] = data.hasOwnProperty('organizer') ? data.organizer : [];
      auxMeetingData['link_meeting'] = data.hasOwnProperty('link_meeting') ? data.link_meeting : '';
      setMeetingData(auxMeetingData);
      setCitationId(data.hasOwnProperty('id') ? data.id : null);
    }

  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleClickOpenPopup() {
    setOpenPopup(true)
  }

  const handleClosePopup = value => {
    setOpenPopup(false)

    if (value) {
      const eventName = value.event_name || ''
      const optionalAttendees = value.optionalAttendees || ''
      const interviewer = value.interviewer || ''
      const subject = value.subject || ''
      const location = value.location || ''
      const body = value.body || ''

      setEventName(eventName ? eventName : subject)
      setOptionalAttendees(optionalAttendees)
      setInterviewer(interviewer)
      setLocation(location)
      setMessage(body)
    }
  }

  const clear = () => {
    setEventName('')
    setOptionalAttendees('')
    setLocation('')
    setInterviewer('')
    setMessage('')
    setDate(new Date())
    setStartTime(moment().format('HH:mm'))
    setEndTime(moment().format('HH:mm'))
    setIsSwitchActive(false)
    setExternalId(null)
    setCitationId(null)
  }

  const handleSubmit = (e, send = false) => {
    e.preventDefault();
    if (send) {
      handleClose();
      submitAction();

      if(suggestChangeStatusModal)  {
        suggestChangeStatusModal.setIsOpenModalSuggestChangeStatus(true);
      }
    } else {
      setIsConfirmationModalOnCitations(true);
    }
  }

  const submitAction = () => {
    const citation_date = new moment(`${moment(date).format('YYYY/DD/MM')} ${startTime}`, 'YYYY/DD/MM HH:mm').format('YYYY-MM-DD HH:mm:ss');
    const citation_end_date = new moment(`${moment(date).format('YYYY/DD/MM')} ${endTime}`, 'YYYY/DD/MM HH:mm').format('YYYY-MM-DD HH:mm:ss');
    const sms_notification = sms;

    onSubmit({
      event_name: eventName,
      optional_attendees: optionalAttendees,
      location,
      citation_date,
      citation_end_date,
      online_meeting: isSwitchActive,
      interviewer,
      sms_notification,
      message,
      external_id,
      citation_id,
    })
    setIsConfirmationModalOnCitations(false);

    clear();
  }

  const toggleSwitch = () => setIsSwitchActive(!isSwitchActive);

  const validateDates = () => {
    const startTimeMoment = moment(startTime, 'HH:mm');
    const endTimeMoment = moment(endTime, 'HH:mm');

    return !startTimeMoment.isAfter(endTimeMoment);

  }

  const getCurrentIntegration = () => {
    if (self && INTEGRATIONS) {
      let currentIntegration = _.find(INTEGRATIONS, { event_type: self.event_type })
      return currentIntegration ? currentIntegration : null;
    }
    return null
  }

  return (
    <>
      <Modal
        className="modal-announcement-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <AlertDialog
            open={isConfirmationModalOnCitations}
            onClose={() => setIsConfirmationModalOnCitations(false)}
            onSuccess={(e) => handleSubmit(e, true)}
            title={i18n.process.deleteModalTitle}
            text={rowsSelected && rowsSelected > 1 ? i18n.modalAnnouncement.confirmation.description_create_group.replaceAll('{num}', rowsSelected)
              : i18n.modalAnnouncement.confirmation.description_create_individual
            }
          />

          <ModalHeader Icon={<ModalIcon />}
            title={isEditFromTab ? i18n.modalAnnouncement.editQuoteCall : i18n.modalAnnouncement.quoteCall}
            closeClick={handleClose} />
          <div className="modal-body">
            <form onSubmit={handleSubmit} >
              {getCurrentIntegration() &&
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <label>{i18n.modalAnnouncement.new_event}</label>
                    <InputField
                      fontSize="small"
                      placeholder={i18n.modalAnnouncement.new_event}
                      name="event_name"
                      value={eventName}
                      style={{ fontSize: '13px' }}
                      handleOnChange={value => {
                        return setEventName(value);
                      }}
                    />
                  </Grid>
                </Grid>
              }

              <Grid container alignItems="flex-end" spacing={3}>
                <Grid item xs={12} sm={4}>
                  <label>{i18n.modalAnnouncement.citationDate}</label>
                  <DateInput fontSize="small" name="date" onChange={e => setDate(e)} value={date} />
                </Grid>
                <Grid item xs={12} sm={6} className="grid-decoration" style={{ display: "flex", alignItems: "center", gap: 16 }}>
                  <TimeInput
                    fontSize="small"
                    name="start_time"
                    onChange={setStartTime}
                    value={startTime}
                  />
                  <span style={{ fontSize: 14 }}>{i18n.modalAnnouncement.to_time}</span>
                  <TimeInput
                    fontSize="small"
                    name="end_time"
                    onChange={setEndTime}
                    value={endTime}
                    isTo={true}
                  />
                </Grid>
              </Grid>
              {getCurrentIntegration() && <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalAnnouncement.online_meeting}</label>
                  <Grid item xs={12} sm={12}>
                    <img src={require(`../../../assets/images/${getCurrentIntegration().logo}`).default} height={20} style={{ marginRight: 8 }} alt={getCurrentIntegration().event_type} />
                    <Switch
                      checked={isSwitchActive}
                      onClick={() => { toggleSwitch() }}
                      name={"online_meeting"}
                      value="isSwitchActive"
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                </Grid>
              </Grid>}
              {!isSwitchActive && <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalAnnouncement.location}</label>
                  <InputField
                    fontSize="small"
                    placeholder={i18n.modalAnnouncement.writeLocation}
                    name="location"
                    value={location}
                    style={{ fontSize: '13px' }}
                    handleOnChange={value => setLocation(value)}
                  />
                </Grid>
              </Grid>}

              {!getCurrentIntegration() && <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalAnnouncement.interviewer}</label>
                  <InputField
                    fontSize="small"
                    placeholder={i18n.modalAnnouncement.writeInterviewer}
                    name="interviewer"
                    value={interviewer}
                    handleOnChange={value => setInterviewer(value)}
                  />
                </Grid>
              </Grid>}

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.modalAnnouncement.notes}</label>
                  <InputEditor
                    fontSize="small"
                    placeholder={getCurrentIntegration() ? i18n.modalAnnouncement.writeDetailsIntegration : i18n.modalAnnouncement.writeDetails}
                    name="message"
                    value={message}
                    onChange={value => setMessage(value)}
                  />
                </Grid>
              </Grid>

              {getCurrentIntegration() &&
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <label>{i18n.modalAnnouncement.invite_people}</label>
                    <InputField
                      style={{ fontSize: '13px' }}
                      placeholder={i18n.modalAnnouncement.invite_people_placeholder}
                      name="optionalAttendees"
                      value={optionalAttendees}
                      handleOnChange={value => setOptionalAttendees(value)}
                    />
                  </Grid>
                </Grid>
              }


              {/* Meeting Attendees */}
              {meetingData && external_id &&
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <label>{i18n.modalAnnouncement.attendees}</label>
                    <div>
                      {meetingData.attendees && meetingData.attendees.filter(at => at.type === 'required').map((attendee, index) => {
                        let color;
                        let status;
                        switch (attendee.status.response) {
                          case 'accepted':
                            color = 'green';
                            status = i18n.modalAnnouncement.accepted;
                            status = i18n.modalAnnouncement.accepted;
                            break;
                          case 'declined':
                          case 'rejected':
                            color = 'red';
                            status = i18n.modalAnnouncement[attendee.status.response];
                            break;
                          case 'tentative':
                            color = 'orange';
                            status = i18n.modalAnnouncement.tentative;
                            break;
                          case 'replan':
                            color = 'orange';
                            status = i18n.citationStatus.replan;
                            break;
                          default:
                            color = 'gray';
                            status = i18n.modalAnnouncement.none;
                        }
                        return (
                          <Grid container key={index}>
                            <Grid item xs={12} sm={7}>
                              <p style={{ fontSize: '14px' }} title={attendee.emailAddress.address}>
                                <a href={attendee.profile_link ? attendee.profile_link : '#'}
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   className='attendee-link'>{attendee.name}</a>
                              </p>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              {meetingData.organizer.emailAddress && meetingData.organizer.emailAddress.address === attendee.emailAddress.address ? (
                                <strong style={{ color: 'blue', fontSize: '14px', marginLeft: '5px' }}>{i18n.modalAnnouncement.organizer}</strong>) : (
                                <strong style={{ color: color, fontSize: '14px', marginLeft: '5px' }}>{status}</strong>)
                              }
                            </Grid>
                          </Grid>
                        )
                      })}
                      <Divider className='boxInfoHeaderDivider' />
                      <p style={{ fontSize: '12px', marginTop: '12px' }}>{i18n.modalAnnouncement.optionals}:</p>
                      {meetingData.attendees && meetingData.attendees.filter(at => at.type === 'optional').map((attendee, index) => {
                        let color;
                        let status;
                        switch (attendee.status.response) {
                          case 'accepted':
                            color = 'green';
                            status = i18n.modalAnnouncement.accepted;
                            status = i18n.modalAnnouncement.accepted;
                            break;
                          case 'declined':
                          case 'rejected':
                            color = 'red';
                            status = i18n.modalAnnouncement[attendee.status.response];
                            break;
                          case 'tentative':
                            color = 'orange';
                            status = i18n.modalAnnouncement.tentative;
                            break;
                          case 'replan':
                            color = 'orange';
                            status = i18n.citationStatus.replan;
                            break;
                          default:
                            color = 'gray';
                            status = i18n.modalAnnouncement.none;
                        }
                        return (
                          <Grid container key={index}>
                            <Grid item xs={12} sm={7}>
                              <p style={{ fontSize: '14px' }}>{attendee.emailAddress.address}</p>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              {meetingData.organizer.emailAddress && attendee.emailAddress &&
                                meetingData.organizer.emailAddress.address === attendee.emailAddress.address ? (
                                <strong style={{ color: 'blue', fontSize: '14px', marginLeft: '5px' }}>{i18n.modalAnnouncement.organizer}</strong>
                              ) : (
                                <strong style={{ color: color, fontSize: '14px', marginLeft: '5px' }}>{status}</strong>
                              )
                              }
                            </Grid>
                          </Grid>
                        )
                      })}

                    </div>
                  </Grid>
                </Grid>
              }

              <div className="button-wrapper">
                <p onClick={handleClickOpenPopup}>{i18n.modalAnnouncement.defaultMessages}</p>
                <SimpleDialog defaultMessages={defaultMessages} open={openPopup} onClose={handleClosePopup} />
                <RegularButton disabled={!validateDates()} type="submit" title={isEditFromTab ? i18n.modalAnnouncement.updateCitation : i18n.modalAnnouncement.sendCitation} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

ModalAnnouncementQuery.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

const enharce = compose(withI18n)

export default enharce(ModalAnnouncementQuery)
