/* eslint-disable react/display-name */
import React, {useEffect, useRef, useState} from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import SaveSearchesTableStyled from './styled'
import {MTMultipleFields, MTText, MTToolbar} from '../../atoms'
import i18n from '../../../assets/i18n'
import moment from 'moment'
import _, {isObject} from 'lodash'
import {withRouter} from 'react-router'
import useSavedSearchesList from "../../../api/queries/useSavedSearchesList";
import {
  refetchQueriesByKeys
} from "../../../api/mutations/mutationCallbacks";
import {useMutation} from "@tanstack/react-query";
import * as api from "../../../api";
import {MODULES, QUERY_KEYS} from "../../../config/constants";
import useInboxFilters from "../../../api/queries/useInboxFilters";
import { useAppContext } from '../../../context/appContext'
import { FeatureGuard } from '../../../utils/guard'
import {useFunnelStatusTypes, useGetPhasesOfStatusTypesQuery} from "../../../api/queries/useFunnelStatusTypes";
import {parseFilterFromIdToLabel} from "../../../utils/filters";

const SaveSearchesTableQuery = ({ history, sort, module, setTotal, setSavedSearchesListToContext, updateParentRowsSelected }) => {
  const { filters, setFilters } = useAppContext();
  const [selectedRows, setSelectedRows] = useState([]);

  const {data: savedSearchesList, isFetched} = useSavedSearchesList(sort, module);
  const {data: moduleFilters, isFetched: moduleFiltersIsFetched} = useInboxFilters(module);
  const {data: funnelStatusTypes} = useFunnelStatusTypes(MODULES.ID_TALENT_ACQUISITION);
  const phasesOfStatusTypes = useGetPhasesOfStatusTypesQuery(MODULES.ID_TALENT_ACQUISITION);

  const mutationDeleteSaveSearches = useMutation(api.deleteSaveSearchesInBatch);
  const tableRef = useRef(null)

  const updateTableData = () => tableRef && tableRef.current && tableRef.current.onQueryChange();

  useEffect(() => {
    updateTableData();
    if (isFetched && moduleFiltersIsFetched) {
      setSavedSearchesListToContext(savedSearchesList, moduleFilters)
    }
    // eslint-disable-next-line
  }, [savedSearchesList, moduleFilters])

  useEffect(() => {
    if (updateParentRowsSelected) updateParentRowsSelected(selectedRows);
  }, [selectedRows])// eslint-disable-line react-hooks/exhaustive-deps

  const MTTitle = rowData => <MTText value={rowData.title}/>
  const MTDate = rowData => <MTText value={moment(rowData.date).format('DD/MM/YYYY')}/>
  const MTFields = rowData => <MTMultipleFields values={JSON.parse(rowData.fields)}/>

  const columns = [
    {title: i18n.saveSearchesTable.title, field: 'title', render: MTTitle},
    {title: i18n.saveSearchesTable.date, field: 'date', render: MTDate},
    {title: i18n.saveSearchesTable.fields, field: 'fields', render: MTFields}
  ]

  const checkRow = event => {
    setSelectedRows(event);
  }

  const clearSelectedRows = () => {
    setSelectedRows([]);
    tableRef && tableRef.current && tableRef.current.onAllSelected(false)
  }

  const toolbarOptions = [
    {
      id: 'delete',
      label: i18n.saveSearchesTable.delete,
      onClick: () => {
        if (_.size(selectedRows) > 0) {
          const ids = _.map(selectedRows, 'id')
          mutationDeleteSaveSearches.mutateAsync({ids, module})
            .then(() => {
              refetchQueriesByKeys([`${module}:${QUERY_KEYS.SAVED_SEARCHES}`])
            });
          clearSelectedRows()
        }
      }
    }
  ];

  const tableOptions = {
    selection: true,
    cellStyle: {textAlign: 'left !important'},
    paging: false,
    loadingType: 'linear',
    pageSize: 20,
    pageSizeOptions: [20, 50, 100, 200],
  }

  const tableComponents = {
    Container: props => <div {...props} />,
    Toolbar: () => (
      <MTToolbar module={module} options={toolbarOptions}
                  disabled={_.isEmpty(selectedRows)}>
      </MTToolbar>
    )
  }

  const onRowClick = (event, rowData) => {
    const saveSearchId = rowData.id
    if (FeatureGuard.canAccessToFunnelStatus() === true) {
      const savedSearchFilters = filters['saved-searches'][saveSearchId];
      filters['inbox'] = savedSearchFilters;
      setFilters(filters);
      history.push(`/${module}/inbox`);
    } else {
      history.push(`/${module}/saved-searches/${saveSearchId}`)
    }
  }

  const dataQuery = async () => {
    if (!savedSearchesList || savedSearchesList.length <= 0 || !funnelStatusTypes || !phasesOfStatusTypes) {
      return {data: [], page: 0, totalCount: 0}
    }

    let formattedSavedSearchesList = savedSearchesList.map(item => {
      let fields = item.fields;
      fields = JSON.parse(fields);
      if (isObject(fields)) {
        Object.keys(fields).map(key => {
          switch (key) {
            case 'selection_phase':
              fields.selection_phase = fields.selection_phase.map(phase => parseFilterFromIdToLabel(phase, phasesOfStatusTypes, null, 'name'));
              break;
            case 'selection_status':
              fields.selection_status = fields.selection_status.map(status => parseFilterFromIdToLabel(status, funnelStatusTypes, ['data'], 'name'));
              break;
            default:
              break;
          }
        });
      }
      return {...item, fields: JSON.stringify(fields)};
    });

    setTotal(formattedSavedSearchesList.length);
    return {data: formattedSavedSearchesList ? formattedSavedSearchesList : [], page: 0, totalCount: formattedSavedSearchesList.length}
  }

  return (
    <SaveSearchesTableStyled>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        isLoading={!isFetched}
        onRowClick={onRowClick}
        data={dataQuery}
        options={tableOptions}
        onSelectionChange={checkRow}
        components={tableComponents}
        localization={i18n.tableLocalization}
      />
    </SaveSearchesTableStyled>
  )
}

SaveSearchesTableQuery.propTypes = {
  i18n: PropTypes.object.isRequired,
  searchFilters: PropTypes.object
}

const enharce = compose(withRouter)

export default enharce(SaveSearchesTableQuery)
