import React, {useEffect, useState} from 'react'
import {AppBarInbox, ProcessTable, ProfileInfo} from '../../organism'
import {BackButton, PageTitleSubtitle} from '../../atoms'
import {useParams} from "react-router";
import {MODULES} from "../../../config/constants";

const SavedSearchesItem = ({
                             classes,
                             i18n,
                             searchFilters,
                             searchFilterTitle,
                             filterGroupSelected,
                             fetchSaveSearch,
                             parseFiltersFromApiToFrontFormat,
                             setFilters,
                             setAuxFilters,
                             saveSearchData,
                             history
                           }) => {
  const [isReady, setIsReady] = useState(false)
  const [saveSearchQuery, setSaveSearchQuery] = useState({})
  const {moduleId, slug} = useParams();

  useEffect(() => {
    fetchSaveSearch(slug, moduleId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (saveSearchData && saveSearchData.fields) {
      const filters = parseFiltersFromApiToFrontFormat(JSON.parse(saveSearchData.fields));
      setFilters(filters, saveSearchData.title);
      setAuxFilters(filters);

      setSaveSearchQuery({
        filters: filters,
        range: [0,19],
        sort: ["",""]
      })

      setIsReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSearchData])

  return (
    <div className={classes.root}>
      <AppBarInbox
        showActions
        module={moduleId}
        saveSearchOption={ true }
        canEditFilters={false}
        section={"savedSearches"}
        isSaveSearchItem={1}
      />
      <BackButton title={ i18n.saveSearchesTable.backToSaveSearches }  onClick={ () => {  history.push(window.location.pathname.replace(/\/\d+/, '')) } }/>
      {isReady &&
        <>
          <PageTitleSubtitle title={ i18n.inboxPeople.filtersSearch } subtitle={ searchFilterTitle } module={ moduleId }/>
          <ProcessTable
            showFilterGroup={moduleId !== MODULES.ID_ON_BOARDING}
            showChangeStatus
            filters={searchFilters}
            filterGroupSelected={filterGroupSelected}
            module={moduleId}
            rowViewRender={(id) => <ProfileInfo id={id}/>}
            resetFiltersOnLoad={true}
            saveSearchQuery={saveSearchQuery}
          />
        </>
      }
    </div>
  )
}

export default SavedSearchesItem
