import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid'
import {toast} from 'react-toastify'
import PropTypes from 'prop-types'
import ProfileButtonsStyled from './styled'
import {BackButton} from '../../atoms'
import {AlertDialog, ProfileActionsButtons} from '../../molecules'
import {MODULES} from '../../../config/constants'
import routes from '../../../config/routes'
import _ from "lodash";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/KeyboardArrowLeft";
import ArrowForward from "@material-ui/icons/KeyboardArrowRight";

const HeaderProfileExitInterview = ({
                                      history,
                                      i18n,
                                      data,
                                      deleteProcess,
                                      match,
                                      onChangeProfile,
                                      userId,
                                      dataInboxRows,
                                      tablePage,
                                      tableSetPage,
                                      tableProcessFeching,
                                      tablePerPage,
                                    }) => {

  const [removeModal, setRemoveModal] = React.useState(false)
  const currentModuleId = match.params.moduleId || MODULES.ID_EXIT_INTERVIEW

  const handleDeleteProcess = () => {
    if (data.id) {
      deleteProcess(data.id, currentModuleId).then(() => {
        toast.success(i18n.process.deleteSuccess, {
          position: toast.POSITION.BOTTOM_CENTER
        })
        history.push(routes.exitInterviewInbox + '?keepFilters=1');
      })
    }
  }

  const [id, setId] = useState(userId || _.get(match, 'params.slug'))
  const [loadingNextPage, setLoadingNextPage] = React.useState(false)
  const [loadingPreviousPage, setLoadingPreviousPage] = React.useState(false)

  let currentTableRows
  let currentObjectResponse
  let currentItemNum = 1

  const handleOnChangePage = (newId) => {
    setId(newId)
    onChangeProfile(newId)
  }

  const pageGetCurrentIndexFromTable = () => {
    return _.findIndex(currentTableRows, {id})
  }

  const hasNextPage = () => {
    return currentItemNum + 1 <= currentObjectResponse.total
  }

  const hasPreviousPage = () => {
    return currentItemNum - 1 >= 1
  }

  const getNextPageId = () => {
    const currentIndex = pageGetCurrentIndexFromTable()
    if (currentTableRows[currentIndex + 1]) {

      history.push(`/profile/${MODULES.ID_EXIT_INTERVIEW}/${currentTableRows[currentIndex + 1].id}`)

      return handleOnChangePage(currentTableRows[currentIndex + 1].id)
    } else {
      changePageInTable(+1)
    }
    return false
  }

  const getPreviousPageId = () => {
    const currentIndex = pageGetCurrentIndexFromTable()
    if (currentTableRows[currentIndex - 1]) {

      history.push(`/profile/${MODULES.ID_EXIT_INTERVIEW}/${currentTableRows[currentIndex - 1].id}`)
      return handleOnChangePage(currentTableRows[currentIndex - 1].id)
    } else {
      changePageInTable(-1)
    }
    return false
  }


  const changePageInTable = (sumOrRest) => {
    let newTablePage
    newTablePage = tablePage + sumOrRest
    tableSetPage(newTablePage)

    if (sumOrRest > 0) {
      setLoadingNextPage(newTablePage)
    } else {
      setLoadingPreviousPage(newTablePage)
    }
  }

  const onInit = () => {

    let currentPage = tablePage
    let currentPerPage = tablePerPage
    let currentIsProcessing = tableProcessFeching

    // wait until data is loaded
    if (
      (loadingNextPage !== false && loadingNextPage !== currentPage) || currentIsProcessing
      || (loadingPreviousPage !== false && loadingPreviousPage !== currentPage) || currentIsProcessing
    ) {
      return false
    }

    currentObjectResponse = dataInboxRows
    currentTableRows = dataInboxRows.data

    if (!id || !currentTableRows) {
      return false
    }

    if (loadingNextPage !== false && currentTableRows[0].itemCount === (loadingNextPage * currentPerPage) + 1) {
      handleOnChangePage(currentTableRows[0].id)
      setLoadingNextPage(false)
      return false
    }

    if (loadingPreviousPage !== false && currentTableRows[0].itemCount === ((loadingPreviousPage * currentPerPage) + 1)) {
      handleOnChangePage(currentTableRows[currentPerPage - 1].id)
      setLoadingPreviousPage(false)
      return false
    }

    if (!currentTableRows[pageGetCurrentIndexFromTable()]) {
      return false
    }

    currentItemNum = currentTableRows[pageGetCurrentIndexFromTable()].itemCount

    return true
  }

  if (!onInit()) {
    return null;
  }

  function getUserName(){
    return currentTableRows[pageGetCurrentIndexFromTable()]?.search_candidature_name
  }

  return (
    <ProfileButtonsStyled className="no-print">
      <Grid container alignItems="center">
        <Grid item sm={3}>
          <BackButton title={i18n.headerProfileButtons.back} onClick={() => {
            localStorage.getItem('backLocation') ? history.push(localStorage.getItem('backLocation')) : history.goBack();
          }}/>
        </Grid>
        <Grid item sm={9} className={'buttons-wrapper header-actions'}>
          <ProfileActionsButtons hasModalAnnouncement={false} userName={getUserName()}/>
          <p className={"header-pagination-count"}>
            <span>
              <span className="currentItemNumber">{currentItemNum}</span> / {currentObjectResponse.total}
            </span>
          </p>
          <ButtonGroup color="primary" aria-label="Split button" className={"header-profile-arrows"}>

            <Button variant="contained" onClick={() => getPreviousPageId()} disabled={hasPreviousPage() === false}>
              <span>&#8205;</span>
              <ArrowBack/>
            </Button>

            <Button variant="contained" onClick={() => getNextPageId()} disabled={hasNextPage() === false}>
              <span>&#8205;</span>
              <ArrowForward/>
            </Button>

          </ButtonGroup>
        </Grid>
      </Grid>
      <AlertDialog open={removeModal} onClose={() => setRemoveModal(false)} onSuccess={handleDeleteProcess}
                   title={i18n.process.deleteModalTitle} text={i18n.process.deleteModalText}/>
    </ProfileButtonsStyled>
  )
}

HeaderProfileExitInterview.propTypes = {
  i18n: PropTypes.object.isRequired,
  data: PropTypes.object,
  match: PropTypes.object,
  setData: PropTypes.func,
  addFav: PropTypes.func,
  onGoBack: PropTypes.func,
  deleteProcess: PropTypes.func
}

export default HeaderProfileExitInterview
