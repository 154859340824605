import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import qs from 'qs'
import _ from 'lodash'
import * as api from '../../../api'

const LandingEmail = ({ classes, location, i18n }) => {
  const [message, setMessage] = useState('')

  useEffect(() => {
    const search = decodeURIComponent(_.get(location, 'search'))
    const params = qs.parse(search, { ignoreQueryPrefix: true })
    const fetchData = async () => {
      try {
        const result = await api.updateCitationStatus(params)
        setMessage(_.get(result, 'data.message', i18n.landingEmail.body))
      } catch (error) {
        setMessage(i18n.landingEmail.body)
      }
    }
    fetchData()
  }, [setMessage, i18n, location])

  return (
    <Container component="main" className={classes.root}>
      {message ? <Typography className={classes.message} dangerouslySetInnerHTML={{ __html: message }} /> : <CircularProgress size={40} />}
    </Container>
  )
}

LandingEmail.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object
}

export default LandingEmail
