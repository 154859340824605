import styled from 'styled-components'
import colors from '../../../assets/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from "react";

export const CircularProgressStyled = styled(props => <CircularProgress {...props}/>)`

  width:${props => props.width ? props.width : '78px'} !important;
  height: ${props => props.height ? props.height : '78px'} !important;

`

export const MTCircularPercentContainerStyled = styled.div`
  line-height: 18px;
  margin-left: ${props => props.left ? "-20px" : '0'};
  p {
    text-align: center;
    font-weight: 700;
    line-height: ${props => props.fontSize ? '8px' : '25px'} !important;
    font-size: ${props => props.fontSize ? props.fontSize : '14px'} !important;
  }
`

export const MTCircularPercentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  
  .value {
    font-size: ${props => props.fontSize ? props.fontSize : '14px'} !important;
    font-weight: 700;
    line-height:18px
    position: absolute;
  }

  .circular-progress {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .top {
      color: ${colors.darkGrey};
    }

    .bottom {
      position: absolute;
      left: 0;
      animation-duration: 0.5s;      
    }

    .valid {
      color: #37bc6a;
    }

    .invalid {
      color: #F4304A;
    }
    
    .theme {
      color: ${props => props.theme.primary};
    }
  }
`
