import * as types from './types'

const initialState = {
    data: [],
    totalItems: 0,
    pageSizeOptions: [16, 32, 64],
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_DATA:
            return {...state, data: action.payload}
        case types.SET_IS_FETCHING:
            return {...state, isFetching: action.payload}
        case types.SET_TOTAL_ITEMS:
            return {...state, totalItems: action.payload}
        default:
            return state
    }
}
