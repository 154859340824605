import styled from 'styled-components'
import colors from '../../../assets/colors'

export const InputStyled = styled.div`
  > div > div {
    min-height: 40px;
    font-size: 14px;
    border: 1px solid ${colors.greyAlpha50};
    box-shadow: none;
    :hover,
    :active,
    :focus {
      border: 1px solid ${colors.greyAlpha50};
    }
  }

  [class*='-placeholder'] {
    color: #9999b3;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
  }

  > div > div span {
    background-color: transparent;
  }

  .dropdownIndicator {
    color: ${colors.greyAlpha50};
  }
`

export const InputHelperStyled = styled.div`
  & {
    margin-top: 10px;
    b {
      font-weight: 400;
      font-size: 14px;
      color: ${props => props.theme.primary};
    }
    &placeholder {
      padding: 0px 13px;
      color: #9999b3;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Poppins';
    }
  }
`

export const TitleStyled = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
  position: relative;
  z-index: 0;
  font-size: 16px;
  text-align: center;

  &.first {
    margin-top: 10px;
  }

  &:before {
    border-top: 2px solid #e4e8ec;
    content: '';
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

  span {
    background: #fff;
    padding: 4px 15px;
    border-radius: 50px;
    border: 1px solid #e4e8ec;
    font-weight: 500;
    font-family: 'Poppins';
  }
`

export const ContainerStyled = styled.div`
  .MuiChip-root {
    margin-left: 20px;
    margin-bottom: 20px;
    background-color: ${props => props.theme.primary + '8f'};
    box-shadow: 0px 0px 1px 0px rgba(227, 228, 229, 1), 0px 3px 12px 0px rgba(9, 30, 66, 0.14);
    border: none;
  }

  .MuiChip-label {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
    opacity: none;
  }

  .MuiInputBase-root {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
  }

  .MuiGrip-container {
    margin-right: 20px;
  }

  .customFilterItem {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 10px;
  }
  .customBlock {
    background: #ffffff;
    border-radius: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 14px;
    box-shadow: 0px 0px 1px 0px rgba(227, 228, 229, 1), 0px 3px 12px 0px rgba(9, 30, 66, 0.14);
  }
`