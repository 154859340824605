import React, { useEffect, useRef, useState } from 'react'
import { ALERT_LEVEL } from '../../../config/constants'
import { useAppContext } from '../../../context/appContext'


const CsvUploader = ({
  fileName = 'csvFile',
  triggerClick = null,
  uploadFn = null,
  callbackFn = null,
  showAlert = true,
  textSuccess = "Ok",
  textError = "Error"
}) => {

  const fileInputRef = useRef(null);
  const { createAlert } = useAppContext();

  useEffect(() => {
    if (triggerClick) {
      triggerClick(() => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      });
    }
  }, [triggerClick]);

  const uploadCsvFile = async (event) => {
    const csvFile = event.target.files[0];
    if(!csvFile) {
      return
    }

    const formData = new FormData();
    formData.append(fileName, csvFile, csvFile.name);

    try {
      if(uploadFn) {
        const response = await uploadFn(formData);
        if(callbackFn) {
          callbackFn(response);
        }

        if(showAlert) {
          if (response.success) {
            createAlert(textSuccess, ALERT_LEVEL.SUCCESS, 5000);
          } else {
            createAlert(textError, ALERT_LEVEL.ERROR, 5000);
          }
        }

        return response;
      }
    } catch (error) {
      console.error('error', error);
      throw error;
    } finally {
      fileInputRef.current.value = null;
    }
  }

  return (
    <input type="file" ref={fileInputRef} onChange={uploadCsvFile}  style={{ display: 'none' }}/>
  )
}

export default CsvUploader;
