import styled from 'styled-components'
import { MTText } from '../../atoms'
import React from 'react'


export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${({columns})=>columns}, 1fr);
  justify-items: stretch;
  column-gap: 30px;
`
export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({underlined, theme})=> underlined && theme?`solid 1px ${theme.colors.greyAlpha25}`:'none'};
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  &:last-child{
    border-bottom: none;
  }
`
export const Label = styled( props => <MTText {...props} />)`
  font-weight: 600;
  line-height: 18px;
  text-align: start;
  color: ${props => props.color ? props.color : '#181A1C !important'};
  padding: 0 10px;
  margin-right: 0;
  font-size: 14px;
`

export const Text = styled(props => <MTText {...props} />)`
  font-weight: normal;
  font-size: 14px;
`
export const Icon = styled.div`
  margin-right: 10px;
`
