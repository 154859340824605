import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import compose from 'recompose/compose'
import * as S from './styled'
import { withI18n } from '../../hocs'
import { ReactComponent as ModalIcon } from '../../../assets/images/notebook.svg'
import { ModalHeader } from '../../atoms'
import ModalForm from './form'
import {ModalReportConfirmation} from "../index";
import {getCurrentSectionKey, SECTION_KEYS} from "../../../config/constants";
import eventTracker from "../../../utils/eventTracker";
import {toast} from "react-toastify";
import _ from "lodash";

const customStyles = {
    overflow: 'scroll'
}

const ExportInboxButton = ({ module, ids, fields, filters, sort, exportCsvOnBackground, i18n, offerFilters }) => {

    const [ open, setOpen ] = useState(false)
    const [ openConfirmation, setOpenConfirmation ] = useState(false);
    const [ reportFields, setReportFields ] = useState([]);

    const closeConfirmation = (receivers) => {
        setOpenConfirmation(false);
        reportFields.push(getCurrentSectionKey() === SECTION_KEYS.PLANNING);

        if(receivers !== 'Close' && !_.isEmpty(receivers)){

            exportCsvOnBackground(reportFields, Object.keys(ids).length ? ids : filters, sort, module, getCurrentSectionKey() === SECTION_KEYS.PLANNING, receivers).then(( res ) => {

                if(res.status === 200){
                    eventTracker.track('ReportExportedBackground Inbox', {
                        status: 'Success'
                    });

                    toast.success(i18n.reports.reportExportedSuccess, {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                } else {
                    eventTracker.track('ReportExportedBackground Inbox', {
                        status: 'Failed'
                    });

                    toast.error(i18n.reports.reportExportedFailed, {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }

            });

        }

    }

    const exportCsvAndClose = (...args) => {
        setReportFields(...args);
        setOpenConfirmation(true);
        setOpen(false);

    }

    if(!fields || fields.length === 0){
        return null;
    }

    return (
        <>
            <Modal
                style={customStyles}
                className="modal-wrapper"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={() => setOpen(false)}
            >
                <S.ModalStyled>
                    <ModalHeader Icon={<ModalIcon />} title={i18n.modalCreateReport.createReport} closeClick={() => setOpen(false)} />
                    <div className="modal-body">
                        {open &&
                            <ModalForm
                                module={module}
                                ids={ids}
                                fields={fields}
                                filters={filters}
                                sort={sort}
                                offerFilters={offerFilters}
                                onSubmit={exportCsvAndClose}
                            />
                        }
                    </div>
                </S.ModalStyled>
            </Modal>
            <S.ButtonExport variant="contained" color="secondary" onClick={() => setOpen(true)} >
                {i18n.processesTable.export}
              ({(ids && ids.length > 0) ? ids.length : i18n.processesTable.all})
            </S.ButtonExport>
          <ModalReportConfirmation showModal={openConfirmation} handleClose={closeConfirmation}/>
        </>
    )
}

const enharce = compose(withI18n)

export default enharce(ExportInboxButton)