import React, { useMemo, useState } from 'react'
import {Card, CardContent, CardHeader} from "@material-ui/core";
import { CustomAlert, InputField, PageTitle, RegularButton, RegularButtonOutline, SpinnerIcon } from '../../atoms'
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import {DashboardCardStyled, DashboardWizardStyled} from "./styled";
import { useAvailableDashboards, useClientDashboards } from '../../../api/queries/useDashboard'
import {useAppContext} from "../../../context/appContext";
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import {assignPanelToClient, createPanel} from "../../../api";
import routes from "../../../config/routes";
import {DashboardPresetItem} from "../../molecules";
import {removeQueriesByKeys} from "../../../api/mutations/mutationCallbacks";
import { MODULES, QUERY_KEYS } from '../../../config/constants'
import { useHistory } from 'react-router-dom'
import i18n from '../../../assets/i18n'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { matchPath, useLocation } from 'react-router'

const DashboardWizard = () => {
  const history = useHistory();
  let {module} = useAppContext();
  module = module || MODULES.ID_TALENT_ACQUISITION;
  let location = useLocation();
  const isAdminSection = matchPath(location.pathname, {path: routes.adminDashboardWizard});

  const [selectCustomDashboard, setSelectCustomDashboard] = useState(false);
  const [isCreatingDashboard, setIsCreatingDashboard] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });

  const {data: preconfiguredDashboards, isLoading} = useAvailableDashboards(module);

  const handleCreateDashboard = () => {
    if (!newDashboardName) {
      setShowAlert(true);
      setAlert({message: i18n.dashboard.wizard.error_name, severity: "error"});
    } else if(!isCreatingDashboard) {
      setIsCreatingDashboard(true);
      createPanel(module, newDashboardName).then((res) => {
        if (res && res.success && res.id) {
          removeQueriesByKeys( [`${module}:${QUERY_KEYS.DASHBOARDS}`]);
          if (isAdminSection) {
            history.push(routes.adminDashboardEdit.replace(':id', res.id));
          } else {
            history.push(routes.dashboardEdit.replace(':module_id', module).replace(':id', res.id));
          }
        }
      }).catch((e) => {
        console.error(e)
        setShowAlert(true);
        setIsCreatingDashboard(false);
        setAlert({message: i18n.dashboard.wizard.error_creating, severity: "error"});
      });
    }
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }

  const handlePresetDashboardClick = (id) => {
    assignPanelToClient(module, id).then(res => {
      removeQueriesByKeys( [`${module}:${QUERY_KEYS.DASHBOARDS}`]);
      history.push(routes.dashboardHome.replace(':module_id', module).replace(':id', res.id));
    }).catch(e => {
      console.log('logg error', e)
    });
  }

  const preconfiguredDashboardList = useMemo(() => {
    if(!preconfiguredDashboards || !preconfiguredDashboards.length) {
      return <></>;
    }

    return preconfiguredDashboards.map(dashboard => {
      return (
        <Grid key={dashboard.id} item xs={12} sm={6} md={4} lg={3}>
          <DashboardPresetItem
            className="wizard"
            handleOnClick={handlePresetDashboardClick}
            id={dashboard.id}
            preview={dashboard.preview_url}
            name={i18n.dashboard.wizard.name.hasOwnProperty(dashboard.name) ? i18n.dashboard.wizard.name[dashboard.name] : dashboard.name}
            description={i18n.dashboard.wizard.description.hasOwnProperty(dashboard.description) ? i18n.dashboard.wizard.description[dashboard.description] : dashboard.description}
          />
        </Grid>
      )
    })
  }, [preconfiguredDashboards]);

  const createCustomDashboard = useMemo(() => {
    return (<DashboardCardStyled onClick={() => { setSelectCustomDashboard(true); }}>
      <Card className={selectCustomDashboard ? ['dashboard_from_scratch_card', 'active'] : ['dashboard_from_scratch_card']}>
        {!selectCustomDashboard ?
          <CardHeader
            title={i18n.dashboard.wizard.new_from_scratch}
            avatar={
              <Avatar variant="square" aria-label="preview_url">
                <AddBoxRoundedIcon fontSize="inherit" style={{fontSize: 60}}/>
              </Avatar>
            }
          />
          :<>
            <CardContent>
              <InputField name="name" placeholder={i18n.dashboard.wizard.placeholder_custom_dashboard} value={newDashboardName} handleOnChange={setNewDashboardName}/>
              <RegularButton title={i18n.dashboard.wizard.create} onClick={handleCreateDashboard}/>
            </CardContent>
            {showAlert && <CustomAlert style={{marginBottom: 15}} severity={alert.severity} message={alert.message}/>}
          </>}
      </Card>
    </DashboardCardStyled>)
  }, [isCreatingDashboard, showAlert, newDashboardName, selectCustomDashboard]);

  const { data: clientDashboards, isLoading: isLoadingClientDashboards } = useClientDashboards(module);

  return (
    <DashboardWizardStyled>
      <Grid container className="title-container" justifyContent='space-between' alignItems='center'>
        <Grid item xs={12} sm={6} className="title">
          <PageTitle title={i18n.dashboard.wizard.title} />
        </Grid>

        {!isLoadingClientDashboards && clientDashboards && clientDashboards.length >0 &&
          (<div style={{display: "flex", gap: 16}}>
            <Button
              onClick={() => {
                history.push(routes.dashboardHome.replace(':module_id', module));
              }}
              color={'secondary'}
            >
              {i18n.dashboard.wizard.viewDashboards}
            </Button>
          </div>)
        }
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} style={{marginBottom: '30px'}}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container className="wizard-grid-content" spacing={6}>
        {!isLoading ? <>
          <Grid container item spacing={4}>
            <Grid item xs={12}>
              <p className="paragraph">{i18n.dashboard.wizard.copy_select_preconfigured_dashboard}</p>
            </Grid>
            {preconfiguredDashboardList}
          </Grid>

          <Grid container item className="custom-dashboard-wrapper">
            <Grid container item spacing={4}>
              <Grid item xs={12}>
                <p className="paragraph">{i18n.dashboard.wizard.copy_select_custom_dashboard}</p>
              </Grid>

              <Grid item xs={12} sm={3}>
                {createCustomDashboard}
              </Grid>
            </Grid>
          </Grid>
          </>
          :
          <Grid item xs={12}>
            <SpinnerIcon/>
          </Grid>
        }
      </Grid>
    </DashboardWizardStyled>
  )
}

export default DashboardWizard
