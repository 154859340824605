import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid'
import MyProfileIaEvaluationStyled from './styled'
import {Box, LinearProgress, TablePagination} from '@material-ui/core'
import InputField from '../../atoms/input-field'
import RegularButton from '../../atoms/regular-button';
import MaterialTable from 'material-table';
import {
  IconActiveUser,
  IconBlockUser,
  IconDot,
  IconEdit,
  IconStar,
  IconThumbsDown,
  IconThumbsUp,
  MTText
} from '../../atoms'
import {
  ModalDisableEvaluationProfile,
  TableToggle
} from '../../molecules'
import {EVALUATION_CRITERIA} from '../../../config/constants'
import _, {find} from 'lodash';
import i18n from '../../../assets/i18n'
import {
  useIaEvaluationProfiles,
} from "../../../api/queries/useIaEvaluationProfile";
import {useEvaluationCriteria} from "../../../api/queries/useIaEvaluationCriteria";
import routes from "../../../config/routes";
import {useHistory} from "react-router-dom";

const MyProfileIaEvaluation = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalDisableOpen, setIsModalDisableOpen] = useState(false);
  const [editingEvaluationData, setEditingEvaluationData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' })

  const [showActivesTable, setShowActivesTable] = useState(true);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [range, setRange] = useState([0, 9]);

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const getRange = (pageVal, perPageVal) => {
    return [pageVal * perPageVal, perPageVal * (pageVal + 1) - 1];
  }

  useEffect(() => {
    setRange(getRange(page, perPage));
  }, [page, perPage]);

  const { data: evaluationCriteria, isLoading: isLoadingCriteria} = useEvaluationCriteria();
  const { data: evaluationProfiles, isLoading} = useIaEvaluationProfiles(searchTerm, showActivesTable, range);

  let tableColumns = {
    name: {
      title: i18n.ia_evaluation.name,
      render: (row) => {
        return <MTText value={row.name} style={{ fontWeight: '400' }} />;
      },
      field: 'name',
      sorting: false,
    },
    job: {
      title: i18n.ia_evaluation.job,
      render: (row) => {
        return <MTText value={row.job.name} style={{ fontWeight: '400' }} />;
      },
      field: 'job',
      sorting: false,
    },
  };

  const actionColumns = {
    edit: {
      title: '',
      render: function EditUserColumn(row) {
        return <div className='custom-edit'
                    onClick={() => handleEditEvaluation(row)} ><IconEdit /></div>
      },
      field: 'edit',
      hidden: false,
      sorting: false,
    },
    disableAndEnable: {
      title: '',
      render: (row) => {
        const isActive = row.is_active;
        const icon = isActive ? <IconBlockUser /> : <IconActiveUser />;

        return (
          <div className='custom-delete' onClick={() => handleDisableAndEnableIconClick(row)}>
            {icon}
          </div>
        );
      },
      field: 'disableAndEnable',
      hidden: false,
      sorting: false,
    }
  };

  let criteriaColumns = {};
  if (evaluationCriteria) {
    evaluationCriteria.map(criteria => {
      criteriaColumns[criteria.keyword] = {
        title: i18n.ia_evaluation.criteria[criteria.keyword],
        render: (row) => {
          let relevance = find(row.evaluation_profile_criterias, {evaluation_criteria: {keyword: criteria.keyword}});
          if (!relevance) return '';
          switch (relevance.relevance_level) {
            case EVALUATION_CRITERIA.RELEVANT:
              return <IconStar/>
            case EVALUATION_CRITERIA.DESIRED:
              return <IconThumbsUp/>
            case EVALUATION_CRITERIA.MANDATORY:
              return <IconThumbsDown/>
            case EVALUATION_CRITERIA.IGNORE:
              return <IconDot/>
            default:
              return '';
          }
        },
        field: criteria.keyword,
        sorting: false,
      };
    });
  }

  tableColumns = {...tableColumns, ...criteriaColumns, ...actionColumns};

  const handleSearchTerm = _.debounce((value) => {
    setSearchTerm(value);
  }, 1500);

  let tableOptions = {
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    cellStyle: { textAlign: 'center' },
    pageSize: perPage,
    pageSizeOptions: [10, 20, 50, 100],
    toolbar: false,
    draggable: false,
    selection: false,
  }
  const handleCreateEvaluation = () => {history.push(routes.iaEvaluationCreate)}
  const handleEditEvaluation = (row) => {history.push(routes.iaEvaluationEdit.replace(':id', row.id))}

  const handleDisableAndEnableIconClick = (row) => {
    setEditingEvaluationData(row);
    setIsModalDisableOpen(true);
  };

  const columnsArray = Object.values(tableColumns);

  columnsArray.forEach(column => {
    if (column.render) {
      column.render.displayName = `${column.field}_render`;
    }
  });

  const handleChangePage = (event, newPage) => {setPage(newPage)}

  const handleChangePerPage = (event) => {
    setPerPage(parseInt(event.target.value,10));
    setPage(0);
  }

  return (
    <MyProfileIaEvaluationStyled>
      <div className="containerHeader">
        <Grid container spacing={4}>
          <Grid item className="sectionSecond" xs={12} sm={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start", gridGap: '15px', maxWidth: "800px", }}>
              <h2>{i18n.ia_evaluation.titleSection}</h2>
              <h5>{i18n.ia_evaluation.descriptionSection}</h5>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-end", gridGap: '15px', justifyContent: 'flex-end' }}>
              <Box sx={{ display: 'flex', alignItems: "flex-end", placeSelf: 'flex-end' }}>
                <RegularButton className="button-custom" title={i18n.ia_evaluation.createEvaluation}
                               onClick={handleCreateEvaluation}
                />
              </Box>
              <Box sx={{ display: 'inline-flex', maxWidth: "367px", alignItems: "center", }}>
                <label>{i18n.ia_evaluation.searchLabel}</label>
                <InputField
                  placeholder={i18n.ia_evaluation.placeholderInputTerm}
                  onChange={(e) => {
                    e.persist();
                    handleSearchTerm(e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Grid>

          {isLoadingCriteria || isLoading ? <LinearProgress/>
            :
            <>
              {<Grid item xs={12} sm={12} >
              <Grid item className="headerTable">
                <div className="relevance_level_legend">
                  <div><IconStar/>{i18n.ia_evaluation.relevance_level[EVALUATION_CRITERIA.RELEVANT]}</div>
                  <div><IconThumbsUp/>{i18n.ia_evaluation.relevance_level[EVALUATION_CRITERIA.DESIRED]}</div>
                  <div><IconThumbsDown/>{i18n.ia_evaluation.relevance_level[EVALUATION_CRITERIA.MANDATORY]}</div>
                  <div><IconDot/>{i18n.ia_evaluation.relevance_level[EVALUATION_CRITERIA.IGNORE]}</div>
                </div>
                <TableToggle
                  buttonLabels={[i18n.ia_evaluation.active, i18n.ia_evaluation.inactive]}
                  handleButtonClick={() => {
                    setShowActivesTable(prevState => !prevState);
                    setPage(0);
                  }}
                  showOpen={showActivesTable}
                />
              </Grid>
                {evaluationProfiles && <MaterialTable
                  data={evaluationProfiles.data}
                  columns={columnsArray}
                  options={tableOptions}
                  components={{
                    Pagination: () => <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      rowsPerPage={perPage}
                      count={evaluationProfiles.total}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangePerPage}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  }}
                />}
              </Grid>}
            </>
          }
          <ModalDisableEvaluationProfile
            open={isModalDisableOpen}
            handleClose={() => setIsModalDisableOpen(false)}
            evaluationData={editingEvaluationData}
            showAlert={showAlert}
            alert={alert}
            setAlert={setAlert}
          />
        </Grid>
      </div>
    </MyProfileIaEvaluationStyled>
  );

};

export default MyProfileIaEvaluation;
