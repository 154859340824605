import styled from 'styled-components'
import colors from '../../../assets/colors'

export const MTVideoStyled = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;

  path {
    fill: ${colors.darkGrey3};
  }

  &:hover path {
    fill: ${props => props.theme.primary};
  }
`
