import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

function useHistoryStack() {
  const [stack, setStack] = useState([]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        setStack(prev => prev.slice(0, prev.length - 1));
      } else if (action === 'PUSH') {
        setStack(prev => [...prev, location.pathname]);
      } else {
        setStack(prev => [...prev.slice(0, prev.length - 1), location.pathname]);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    setStack(prev => [...prev, location.pathname]);
  }, [location.pathname]);

  return stack;
}

export default useHistoryStack;
