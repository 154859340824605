import React, { useState, memo } from 'react'
import compose from 'recompose/compose'
import Grid from '@material-ui/core/Grid'
import BoxIAGenStyled from './styled'
import {withI18n} from '../../hocs'
import BoxKeyValue from '../box-key-value'
import { ButtonWithIcon, MTCircularPercent } from '../../atoms'

const BoxIAGen = ({ title = null, data = {}, i18n, debug = false }) => {
  title = title || i18n.boxIAGen.title

  const [showJsonResponse, setShowJsonResponse] = useState(false)
  const [showIaGenDebug, setShowIaGenDebug] = useState(false)

  const getIAGenScores = (data) => {
    const ia_scores = data.ia_scores || [];

    if(ia_scores.length > 0 && ia_scores[ia_scores.length-1].keyword !== 'others') {
      ia_scores.push({
        keyword: "others",
        score: `- (${i18n.ia_evaluation.relevance_level.others})`,
      });
    }

    return ia_scores.map(i => {
      if(i18n.IAGen?.criterias) {
        i.criteria = i18n.IAGen.criterias[i.keyword] || i.keyword;

        if(i.score === null || i.score === undefined) {
          i.score = i18n.IAGen.criterias['not_evaluated'] || '-';
        }

        i.score = i.score.toString();
        i.score_criteria = i.score

        try {
          const job_criteria = data.ia_criteria.evaluation_criteria[i.keyword];
          if(job_criteria) {
            i.score_criteria = `${i.score} (${i18n.ia_evaluation.relevance_level[job_criteria] || job_criteria})`;
          }
        } catch(e) {
          //
        }
      }

      return i
    })
  }

  const createMarkup = (htmlString) => {
    return { __html: htmlString }
  }

  const RenderIASummary = () => {
    const summary = data.ia_summary || null;
    if(!summary) {
      return <></>
    }

    return (
      <Grid container justifyContent='space-between' alignItems={'baseline'}>
        <Grid xs={12} container item className={'grid-container'}>
          <Grid container item alignItems={'center'}>
            <Grid item xs={12} md={1}>
              <MTCircularPercent value={data.matching} titlez={i18n.boxIAGen.matchTitle} valid={data.matching>=50} fontSize="18px" thickness={4.8} />
            </Grid>

            <Grid item xs={12} md={11}>
              <div className="header-table"><p>{i18n.boxIAGen.summaryTitle}</p></div>
              <p className={'ia-summary'} dangerouslySetInnerHTML={createMarkup(summary)}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getCriteria = (item) => {
    if(item.ignore) {
      return i18n.ia_evaluation.relevance_level.ignore;
    } else if(item.discards) {
      return i18n.ia_evaluation.relevance_level.mandatory;
    } else if(item.relevant) {
      return i18n.ia_evaluation.relevance_level.relevant;
    } else {
      return i18n.ia_evaluation.relevance_level.desired;
    }
  }

  const renderCvDetailedValue = (value) => {
    if(typeof value === "string") {
      return <>{value}</>;
    }

    return <><pre>${JSON.stringify(value, null, 4)}</pre></>;
  }

  const RenderIADebug = ({ data = null }) => {
    const ia_criteria = data;

    if(!debug || !ia_criteria || (!ia_criteria.evaluation && !ia_criteria.candidate_discarded)) {
      return <></>
    }

    return (
        <Grid container className={'grid-container'} style={{ "margin": "2rem auto 3rem" }}>
          <div className="header-table">
            <p>IA Debug (solo visible para usuarios hrbf):</p>
            <div style={{ "margin": "2rem 0 1rem" }}>
              <ButtonWithIcon
                title={"Ver evaluación por IA"}
                onClick={() => setShowIaGenDebug(!showIaGenDebug)}
              />
            </div>
          </div>
          {
            showIaGenDebug &&
            <>
              <Grid item xs={12}>
                <div className={'ia-evaluation'}>
                  {ia_criteria.evaluation.map((item, idx) => (
                    <div key={idx} className={'ia-criteria'}>
                      <h5>
                        {i18n.IAGen.criterias[item.dimension] || item.dimension} - {getCriteria(item)}: <span className={'ia-criteria-result ' + (item.points>=50 ? 'passed' : 'failed')}>{item.points || '-'} puntos</span>
                      </h5>

                      {item.requirements && item.requirements.length > 0 &&
                      <>
                        <span>Requisitos:</span>
                        <ol>
                          {item.requirements.map((requirement, idxR) => (
                            <li key={idxR} style={{ 'margin-bottom': '1rem' }}>
                              <ul>
                                <li className={(requirement.points>=50 ? 'passed' : 'failed')}><strong>Requisito:</strong> <span>{requirement.requirement || '-'}</span></li>
                                <li className={(requirement.points>=50 ? 'passed' : 'failed')}><strong>Puntuación:</strong> <span>{requirement.points || '-'}</span></li>
                                <li><strong>Detalles:</strong> <span>{requirement.offer_req_details || '-'}</span></li>
                                <li><strong>Respuesta del candidato:</strong> <span>{renderCvDetailedValue(requirement.cv_detailed_value || '-')}</span></li>
                                <li><strong>Explicación:</strong> <span>{requirement.explanation || '-'}</span></li>
                              </ul>
                            </li>
                          ))}
                        </ol>
                      </>
                      }
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={'ia-evaluation'}>
                  <ButtonWithIcon
                    title={"Ver respuesta JSON"}
                    onClick={() => setShowJsonResponse(!showJsonResponse)}
                  />
                  {showJsonResponse && <pre style={{"margin": "1rem"}}>{JSON.stringify(ia_criteria, null, 4)}</pre>}
                </div>
              </Grid>
            </>
          }
        </Grid>
    )
  };

  return <>
      <BoxIAGenStyled>
        {title && <div className="header-table"><p>{title}</p></div>}

        <RenderIASummary />

        <BoxKeyValue
            title={i18n.boxIAGen.criteriasTitle}
            data={getIAGenScores(data)}
            dataKey={'criteria'}
            dataValue={'score_criteria'}
            showList={true}
            numCols={2} />

        <RenderIADebug data={data.ia_criteria || null} />

      </BoxIAGenStyled>
    </>
}

const enharce = compose(withI18n)
export default enharce(BoxIAGen)
