import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import ModalHeaderStyled from './styled'
import { ReactComponent as PlanningIcon } from '../../../assets/images/planning.svg'
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg'
import i18n from '../../../assets/i18n'

const ModalHeader = ({ Icon, closeClick, title, ...rest }) => {
  return (
    <ModalHeaderStyled {...rest}>
      <div className="header-modal">
        {Icon}
        <p>{title}</p>
        <CloseIcon className="close-icon" onClick={closeClick} />
      </div>
    </ModalHeaderStyled>
  )
}

ModalHeader.defaultProps = {
  Icon: <PlanningIcon />,
  title: i18n.modalHeader.planning
}

ModalHeader.propTypes = {
  Icon: PropTypes.element,
  title: PropTypes.string,
  closeClick: PropTypes.func
}

const enharce = compose()

export default enharce(ModalHeader)
