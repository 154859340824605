import React, {useEffect, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid'
import JobOfferApplicationsStyled from './styled'
import {Box, LinearProgress, TablePagination} from '@material-ui/core'
import MaterialTable from 'material-table';
import {
  BackButton, ButtonWithIcon,
  ChipCustom,
  FavouriteIcon,
  IconRobot,
  IconIA,
  IconAiTag,
  MTExporterButton,
  MTImage,
  MTText,
  MTToolbar
} from '../../atoms'
import _, {round} from 'lodash';
import i18n from '../../../assets/i18n'
import routes from "../../../config/routes";
import {useHistory} from "react-router-dom";
import {useJobOffer, useJobOfferApplications} from "../../../api/queries/useJobOffers";
import moment from "moment/moment";
import {isJSON} from "../../../utils/validation";
import {jsonToShow} from "../../../utils/formatting";
import {useParams} from "react-router";
import {SearchCandidatureName} from "../../organism/process-table-query/styled";
import {
  QUERY_ACTIONS,
  INTEGRATIONS,
  MODULES,
  QUERY_KEYS,
  ROLES,
  ALERT_LEVEL,
  SECTION_KEYS,
  getCurrentSectionKey
} from "../../../config/constants";
import {AuthUtils} from "../../../redux/auth";
import {useAppContext} from "../../../context/appContext";
import {
  manageQueriesWithPrefix,
  mutationPostCitationCallback
} from "../../../api/mutations/mutationCallbacks";
import {iso8601Format} from "../../../utils/date";
import {useFunnelStatusTypes} from "../../../api/queries/useFunnelStatusTypes";
import {useMutation} from "@tanstack/react-query";
import * as api from "../../../api";
import {
  FilterFormQuery,
  ModalAnnouncementQuery,
  ModalChangeTagsQuery, ModalFilterQuery,
  ModalRequireDocQuery,
  ModalSuggestChangeStatusAnnouncement
} from "../../molecules";
import {StatusType} from "../../../models";
import {FeatureGuard} from "../../../utils/guard";
import Tag from "../../atoms/tag";
import useTagsTypes from "../../../api/queries/useTagsTypes";
import RegularButton from "../../atoms/regular-button";
import {InputStyled} from "../../organism/app-bar-inbox-query/styled";
import Select from "react-select";
import useInboxFilters from "../../../api/queries/useInboxFilters";
import IAGenJobDebug from '../../molecules/ia-gen-job-debug'
import {refetchQueryOnCacheDataLost} from "../../../api/utils";


const JobOfferApplications = ({self, client}) => {
  const {module, filters, setFilters, page, setPage, perPage, setPerPage, range, setRange, sort, setSort, createAlert, clearTargetFilters} = useAppContext();
  const tableRef = useRef(null);
  const history = useHistory();
  const {job_offer_id} = useParams();
  const [currentJobOfferId, setCurrentJobOfferId] = useState(job_offer_id);

  const [selectedRows, setSelectedRows] = useState([]);
  const [totalRowsSelected, setTotalRowsSelected] = useState(0);

  const [tempFilters, setTempFilters] = useState(filters[getCurrentSectionKey()]);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const [announcement, setAnnouncement] = useState(null);
  const [announcementModal, setAnnouncementModal] = useState(false);
  const [isOpenModalSuggestChangeStatus, setIsOpenModalSuggestChangeStatus] = useState(false);

  const [openChangeTagsModal, setOpenChangeTagsModal] = useState(false);
  const [currentTags, setCurrentTags] = useState([]);

  const [requireDoc, setRequireDoc] = useState(null);
  const [requireDocModal, setRequireDocModal] = useState(false);

  const isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_talent_acquisition);

  const { data: inboxFilters } = useInboxFilters(module)
  const { data: jobOfferApplications, isLoading, dataUpdatedAt, refetch} = useJobOfferApplications(job_offer_id, filters[getCurrentSectionKey()], range[getCurrentSectionKey()], sort[getCurrentSectionKey()]);
  const { data: jobOfferDetails, isLoadingJobOfferDetails} = useJobOffer(job_offer_id);
  const {data: statusFunnelTypes, isFetched: isFetchedStatusFunnelTypes} = useFunnelStatusTypes(module);
  const {data: tagsPersonTypes, isFetched: isFetchedTagsPersonTypes} = useTagsTypes(module);

  const mutationUpdateStatesSelectionFunnel = useMutation(api.updateNewStatesSelectionFunnel);
  const mutationPostReads = useMutation(api.addJobOfferApplicationRead);
  const mutationDeleteReads = useMutation(api.removeJobOfferApplicationRead);
  const mutationPostFavs = useMutation(api.addJobOfferApplicationFav);
  const mutationDeleteFavs = useMutation(api.removeJobOfferApplicationFav);
  const mutationChangeTags = useMutation(api.changeTags_query);
  const mutationPostCitation = useMutation(api.postOrPatchCitations);
  const mutationDocRequired = useMutation(api.requireDocQuery);

  const goBackHandler = () => {
    history.push(routes.job_offers);
  }

  const updateTableData = () => tableRef && tableRef.current && tableRef.current.onQueryChange();

  useEffect(() => {
    if (job_offer_id !== currentJobOfferId) {
      setCurrentJobOfferId(job_offer_id);
      clearTargetFilters(getCurrentSectionKey());
    }
  }, [job_offer_id]);

  useEffect(() => {
    refetchQueryOnCacheDataLost(jobOfferApplications, dataUpdatedAt, refetch);
    updateTableData();
  }, [jobOfferApplications]);

  useEffect(() => {
    setTotalRowsSelected(getTotalRowsSelected);
  }, [selectedRows])// eslint-disable-line react-hooks/exhaustive-deps

  const getTotalRowsSelected = () => {
    return selectedRows ? _.map(selectedRows, (item) => item.id).length : 0;
  }

  const getRange = (pageVal, perPageVal) => {
    return [pageVal * perPageVal, perPageVal * (pageVal + 1) - 1];
  }

  useEffect(() => {
    setRange({...range, [getCurrentSectionKey()]: getRange(page[getCurrentSectionKey()], perPage[getCurrentSectionKey()])});
  }, [page, perPage]);

  const handleChangePage = (event, newPage) => {setPage({...page, [getCurrentSectionKey()]: newPage})}

  const handleChangePerPage = (event) => {
    setPerPage({...perPage, [getCurrentSectionKey()]: parseInt(event.target.value,10)});
    setPage({...page, [getCurrentSectionKey()]: 0});
  }

  const handleOrderChange = (column, orderDirection) => {
    setPage({...page, [getCurrentSectionKey()]: 0});
    setSort({...sort, [getCurrentSectionKey()]: orderDirection ? [column.field, orderDirection] : ["",""]});
  }

  const formattedValue = (value) => {
    if (_.isString(value) && (
      moment(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid() ||
      moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ||
      moment(value, 'YYYY-MM-DD', true).isValid()
    )) {
      return moment(value).format('DD/MM/YYYY');
    } else if (_.isString(value) && isJSON(value)) {
      return jsonToShow(value);
    } else if (!_.isArray(value) && !_.isObject(value)) {
      return value;
    }
  }

  const handleRowClick = (e, row) => {
    if (row.person && row.person.id) {
      const ids = [row.id];
      let queriesToRefetch = [`${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`, `${QUERY_KEYS.JOB_OFFERS}`];
      mutationPostReads.mutateAsync({ids}).then(() => {
        manageQueriesWithPrefix(queriesToRefetch, QUERY_ACTIONS.REFETCH);
      });

      history.push(routes.profilePerson.replace(':job_offer_id', job_offer_id).replace(':job_offer_application_id', row.id).replace(':slug', row.person.id));
    }
  }

  const getInitials = fullName => {
    function getInitial(name) {
      return name && name.length ? name[0].toUpperCase() : '';
    }

    const fullNameSplit = fullName ? fullName.split(' ') : false;
    if (fullNameSplit && fullNameSplit.length > 1) {
      return getInitial(fullNameSplit[0]) + getInitial(fullNameSplit[1]);
    }
    return '';
  }

  const renderTags = (field, rowData, shortVersion= true) => {
    return _.map(rowData.tags, tag => {
      return (
        <Grid item>
          <Tag tag={tag} tagTypesReactQuery={tagsPersonTypes} shortVersion={shortVersion}/>
        </Grid>
      )
    })
  }

  const isIAGenEnabled = () => {
    if(!AuthUtils.isDebugUser()) {
      return false;
    }

    return jobOfferDetails && jobOfferDetails.ia_criteria?.length > 0;
  }

  let tableColumns = {
    'job_offer_application_favs': {
      title: i18n.job_offer_applications.is_favorite,
      field: 'job_offer_application_favs',
      render: (row) => {
        return (
          <Grid justifyContent="start" container alignItems='flexStart'>
            <Grid item lg={4}>
              <FavouriteIcon
                favorite={_.get(row, 'job_offer_application_favs')}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handlePostFavorite(!_.get(row, 'job_offer_application_favs'), [row]);
                }}
              />
            </Grid>
            {row && row.candidature && row.candidature.tags && row.candidature.tags.length > 0 &&
              <Grid className="tagsColumn" container alignItems='center' justifyContent='start' item lg={8}>
                {renderTags('tags', row.candidature)}
              </Grid>
            }
          </Grid>)
    }},
    "person.nombre": {
      title: i18n.job_offer_applications.name_and_surname,
      render: (row) => {
        let value = `${row.person.nombre} ${row.person.apellidos}`;
        if(!row.person.nombre && !row.person.apellidos) {
          value = row.person.email;
        }

        return (
          <Grid container justifyContent='start' className="avatarField">
            <Grid item>
              <MTImage foto={row.person.foto} inicials={getInitials(value)}
              />
            </Grid>
            <Grid item>
              <SearchCandidatureName is_read={row.job_offer_application_reads !== null} value={value}/>
            </Grid>
          </Grid>
        )
      },
      field: 'person.nombre',
    },
    "created_at": {
      title: i18n.job_offer_applications.created_at,
      render: (row) => {
        return <MTText className={row.job_offer_application_reads !== null ? "read" : "not-read"} value={formattedValue(row.created_at)} />;
      },
      field: 'created_at',
    },
    "person.area_level2": {
      title: i18n.job_offer_applications.location,
      render: (row) => {
        return <MTText className={row.job_offer_application_reads !== null ? "read" : "not-read"} value={row.person.area_level2} />;
      },
      field: 'person.area_level2',
    },
    "person.ultimo_puesto": {
      title: i18n.job_offer_applications.last_job,
      render: (row) => {
        return <MTText className={row.job_offer_application_reads !== null ? "read" : "not-read"} value={row.person.ultimo_puesto ?? "-"} />;
      },
      field: 'person.ultimo_puesto',
    },
    "person.formacion": {
      title: i18n.job_offer_applications.formation,
      render: (row) => {
        return <MTText className={row.job_offer_application_reads !== null ? "read" : "not-read"} value={row.person.formacion ?? "-"} />;
      },
      field: 'person.formacion',
    },
    'iagen_matching': {
      title: i18n.job_offer_applications.ia_matching,
      render: (row) => {

        // let apto = row.candidature && row.candidature.hasOwnProperty('apto') && row.candidature.apto !== null ? row.candidature.apto : null;
        let color = '#7A808D'
        if (row.matching !== null) {
          color = row.matching >= 50 ? '#53B24A' : '#D95117'
        }

        const hasMatching = !isNaN(row.matching) && row.matching !== null;

        return <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          {hasMatching && <IconIA color={color}/>}
          <MTText className={row.job_offer_application_reads !== null ? 'read' : 'not-read'}
                  value={hasMatching ? `${row.matching > 0 ? round(row.matching) : 0}%` : '-'}
                  style={{ fontSize: 14, color }}/>
        </div>
      },
      field: 'matching',
    },
    "bot_matching": {
      title: i18n.job_offer_applications.match,
      render: (row) => {
        let apto = row.candidature && row.candidature.hasOwnProperty('apto') && row.candidature.apto !== null ? row.candidature.apto : null;
        let color = row.bot_matching !== null && apto !== null ? (apto ? "#53B24A" : "#D95117") : "#7A808D";
        return <div style={{display: "flex", alignItems: "center", gap: 8}}>
          {apto !== null && <IconRobot color={color}/>}
          <MTText className={row.job_offer_application_reads !== null ? "read" : "not-read"} value={!isNaN(row.bot_matching) && row.bot_matching !== null ? `${row.bot_matching > 0 ? round(row.bot_matching) : 0}%` : i18n.job_offer_applications.bot_matching_pending} style={{ fontSize: 14, color}} />
        </div>
      },
      field: 'bot_matching',
    },
    'selection_phase': {
      title: i18n.job_offer_applications.phase,
        render: (row) => {
          return <ChipCustom label={row.selection_status?.phase.name || ""}
                  color={row.selection_status?.phase?.color || "#000"}
                  fontSize={12}
                  fontWeight={row.job_offer_application_reads !== null ? 400 : 700}
                  margin={"0 8px"}
          />
        },
      field: 'selection_status.phase'
    },
    "selection_status": {
      title: i18n.job_offer_applications.selection_status,
        render: (row) => {
          return <ChipCustom label={row.selection_status?.name || ""}
                             color={row.selection_status?.color || "#000"}
                             fontSize={12}
                             fontWeight={row.job_offer_application_reads !== null ? 400 : 700}
                             margin={"0 8px"}
          />
        },
      field: "selection_status.name"

    },
    "person.cv": {
      title: i18n.job_offer_applications.cv,
        render: (row) => {
          return <MTExporterButton cv={row.person?.cv}/>
        },
      field: "person.cv"
    },
  };

  if(!isIAGenEnabled()) {
    delete tableColumns["iagen_matching"];
  }

  const checkRow = (selectedRows) => {
    if (selectedRows.length === 1) {
      let row = selectedRows[0];
      let tags = row.hasOwnProperty('tags') ? row.tags : [];
      setCurrentTags(tags);
    } else {
      setCurrentTags([]);
    }
    setSelectedRows(selectedRows);
  };

  let tableOptions = {
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    cellStyle: { textAlign: 'center' },
    pageSize: perPage[getCurrentSectionKey()],
    pageSizeOptions: [10, 20, 50, 100],
    toolbar: false,
    draggable: false,
    selection: true,
  }

  const dataQuery = async () => {
    try {
      const totalCount = jobOfferApplications ? jobOfferApplications.total : 0;
      if ((!jobOfferApplications || !jobOfferApplications.data || jobOfferApplications.data.length <= 0) || (!tableColumns || tableColumns.length <= 0)) {
        return {data: [], page: 0, totalCount: 0}
      }
      return {data: jobOfferApplications.data, page: page[getCurrentSectionKey()], totalCount}
    } catch (e) {
      return {data: [], page: 0, totalCount: 0}
    }
  }

  const columnsArray = Object.values(tableColumns);

  columnsArray.forEach(column => {
    if (column.render) {
      column.render.displayName = `${column.field}_render`;
    }
  });

  const clearRowsSelected = () => {
    setSelectedRows([]);
    tableRef.current && tableRef.current.onAllSelected(false);
  }

  const handleMarkAsRead = () => {
    if (_.size(selectedRows) > 0) {
      const ids = _.map(selectedRows, 'id');
      let queriesToRefetch = [`${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`, `${QUERY_KEYS.JOB_OFFERS}`];
      mutationPostReads.mutateAsync({ids},
        // mutationMarkAsReadCallback(ids, module, renderData)
      )
        .then(() => {
          manageQueriesWithPrefix(queriesToRefetch, QUERY_ACTIONS.REFETCH);
        });
    }
  }

  const handleUnmarkAsRead = () => {
    if (_.size(selectedRows) > 0) {
      const ids = _.map(selectedRows, 'id');
      let queriesToRefetch = [`${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`, `${QUERY_KEYS.JOB_OFFERS}`];
      mutationDeleteReads.mutateAsync({ids},
        // mutationMarkAsUnreadCallback(ids, module, renderData)
      )
        .then(() => {
          manageQueriesWithPrefix(queriesToRefetch, QUERY_ACTIONS.REFETCH);
        });
    }
  }

  const handlePostFavorite = (isFavorite, ids) => {
    const rows = ids || selectedRows;
    if (_.size(rows) > 0) {
      const ids = _.map(rows, 'id');
      let queriesToRefetch = [`${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`];

      if (isFavorite) {
        mutationPostFavs.mutateAsync({ids}).then(() => {
          manageQueriesWithPrefix(queriesToRefetch, QUERY_ACTIONS.REFETCH);
        });
      } else {
        mutationDeleteFavs.mutateAsync({ids}).then(() => {
          manageQueriesWithPrefix(queriesToRefetch, QUERY_ACTIONS.REFETCH);
        });
      }
    }
  }

  const onSubmitChangeTags = (tags) => {
    setOpenChangeTagsModal(false)
    if (_.size(selectedRows) > 0) {
      let candidatureIds = [];
      selectedRows.map(row => {
        if (row.candidature && row.candidature.id) {
          candidatureIds.push(row.candidature.id);
        }
      });
      let queriesToRefetch = [`${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`, `${QUERY_KEYS.JOB_OFFERS}`];
      mutationChangeTags.mutateAsync({ids: candidatureIds, tags, module})
        .then(() => {
          manageQueriesWithPrefix(queriesToRefetch, QUERY_ACTIONS.REFETCH);
        });
      clearRowsSelected();
    }
  }

  let toolbarOptions = []
  if (!isOnlyReader) {
    toolbarOptions = [
      {
        id: 'markAsRead',
        label: i18n.processesTable.markAsRead,
        onClick: () => {
          handleMarkAsRead()
          clearRowsSelected()
        }
      },
      {
        id: 'unmarkAsRead',
        label: i18n.processesTable.unmarkAsRead,
        onClick: () => {
          handleUnmarkAsRead()
          clearRowsSelected()
        }
      },
      {
        id: 'markFavorite',
        label: i18n.processesTable.markFavorite,
        onClick: () => {
          handlePostFavorite(true)
          clearRowsSelected()
        }
      },
      {
        id: 'removeFavorites',
        label: i18n.processesTable.removeFavorites,
        onClick: () => {
          handlePostFavorite(false)
          clearRowsSelected()
        }
      },
      module === MODULES.ID_TALENT_ACQUISITION ? {
        id: 'announcement',
        label: i18n.peopleTable.quoteCall,
        onClick: ({...props}) => {
          setAnnouncementModal(true)
          setAnnouncement(props)
        }
      } : null,
      module === MODULES.ID_TALENT_ACQUISITION ? {
        id: 'requireDoc',
        label: i18n.peopleTable.requireDoc,
        onClick: ({...props}) => {
          setRequireDocModal(true)
          setRequireDoc(props)
        }
      } : null,
      module !== MODULES.ID_EXIT_INTERVIEW && tagsPersonTypes && tagsPersonTypes.length > 0 ? {
        id: 'changeTags',
        label: i18n.processesTable.changeTags,
        onClick: () => {
          setOpenChangeTagsModal(true)
        }
      } : null,
      // ...actions
    ];
  }

  const getCurrentIntegration = () => {
    if (self && INTEGRATIONS) {
      let currentIntegration = _.find(INTEGRATIONS, {event_type: self.event_type})
      return currentIntegration ? currentIntegration : null;
    }
    return null
  }

  const onSubmitAnnouncement = (data) => {
    if (announcement && _.size(announcement.selectedRows) > 0) {
      let filteredAnnouncement = announcement.selectedRows
        .filter(item => ![StatusType.HIRED, StatusType.DISCARDED, StatusType.DISCARDED_PHASE_2].includes(item.estado));
      const ids = _.map(filteredAnnouncement, 'id');
      data.create = true;
      data.type = QUERY_KEYS.JOB_OFFER_APPLICATIONS;
      mutationPostCitation.mutateAsync({data, ids}, mutationPostCitationCallback(ids, module)).then(() => {
        createAlert(i18n.citations.citationCreated, ALERT_LEVEL.SUCCESS);

        let queriesToRefetch = [];
        let data = [];
        // TODO Fix until we merge to new phase and state system
        let status_id = getCurrentIntegration() ? StatusType.CITED : StatusType.TO_BE_CONFIRMED;
        ids.map(id => {
          data.push({id: id, status_id: status_id, date: new Date()});
          queriesToRefetch.push(`${module}:${QUERY_KEYS.PROFILE}:${id}`)
        });
        // if (!FeatureGuard.canAccessToFunnelStatus()) {
        //   mutationChangeStatus.mutateAsync({data, module},
        //     mutationChangeStatusCallback(ids, module, status_id, renderData))
        //     .then(() => {
        //       refetchInboxesQueriesBySection(queriesToRefetch);
        //     });
        // }
      }).catch(() => {
        createAlert(i18n.citations.citationError, ALERT_LEVEL.ERROR);
      });
      announcement.clearRowsSelected()
    }
  }

  const onItemClick = (id) => {
    _.find(toolbarOptions, {id}).onClick({
      selectedRows,
      // updateQuery: updateData, // TODO: actualizar
      clearRowsSelected
    });
  }

  const onSubmitChangeFunnelStatus = (status) => {
    if (_.size(selectedRows) > 0) {
      const ids = _.map(selectedRows, 'id');
      let data = [];
      let queriesToRefetch = [];
      let queriesToInvalidate = [];
      ids.map(id => {
        data.push({id: id, status_id: status.id, date: iso8601Format(), type: SECTION_KEYS.JOB_OFFER_APPLICATIONS});
        // TODO: Hacer refetch e invalidar queries relacionadas con el perfil del JobOfferApplication.
        // queriesToRefetch.push(`${module}:${QUERY_KEYS.PROFILE}:${id}`)
        // queriesToInvalidate.push(`${QUERY_KEYS.OBSERVATIONS}:${id}`)
      });
      mutationUpdateStatesSelectionFunnel.mutateAsync(data, {
        onSuccess: () => {
          queriesToRefetch.push(`${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`);
          manageQueriesWithPrefix(queriesToRefetch, QUERY_ACTIONS.REFETCH);
          //     invalidateQueriesByKeys(module, queriesToInvalidate);
        },
      });

      createAlert(i18n.processesTable.statusChanged, ALERT_LEVEL.SUCCESS);
      clearRowsSelected();
    }
  }

  const onSubmitRequireDoc = (data) => {
    if (requireDoc && _.size(requireDoc.selectedRows) > 0) {
      const ids = _.map(requireDoc.selectedRows, 'person.id')

      mutationDocRequired.mutateAsync({data, ids, type: SECTION_KEYS.JOB_OFFER_APPLICATIONS}).then(() => {
          createAlert(i18n.requireDoc.docRequired, ALERT_LEVEL.SUCCESS);
        }).catch(() => createAlert(i18n.requireDoc.errorDocRequire, ALERT_LEVEL.ERROR));

      clearRowsSelected();
    }
  }

  const updateTempFilters = (arrObjSelected, name) => {
    setTempFilters(tempFilters => {
      const resultTempFilters = {...tempFilters};
      if ( !arrObjSelected || _.isEmpty(arrObjSelected) || (arrObjSelected.length === 1 && (
        _.get(arrObjSelected, '[0].value', null) === null
        || (!_.get(arrObjSelected, '[0].value', null) && _.get(arrObjSelected, '[0].value', null) !== false)
        || (arrObjSelected[0].value.hasOwnProperty('from') && !_.get(arrObjSelected, '[0].value.from', null) && !_.get(arrObjSelected, '[0].value.to', null))
      ))) {
        _.unset(resultTempFilters, name);
      } else {
        _.assign(resultTempFilters, { [name]: arrObjSelected });
      }

      return resultTempFilters;
    })
  }

  const handleOpenFilterModal = () => {setOpenFilterModal(true)}
  const handleCloseFilterModal = () => {setOpenFilterModal(false)}

  const handleSubmitFilters = () => {
    setFilters({...filters, [getCurrentSectionKey()]: tempFilters});
    setPage({...page, [getCurrentSectionKey()]: 0});
    setRange({...range, [getCurrentSectionKey()]: getRange(0, perPage[getCurrentSectionKey()])});
  }

  const selectedFiltersHandle = (list, event) => {
    const resultFilters = { ...filters[getCurrentSectionKey()] }

    if (event.action === 'remove-value') {
      let filterAssoc = resultFilters[event.removedValue.name]
      let filterToRemove = _.find(filterAssoc, { id: event.removedValue.id })
      _.pull(filterAssoc, filterToRemove)

      if (_.isEmpty(filterAssoc)) {
        _.unset(resultFilters, event.removedValue.name)
      }
      setTempFilters(resultFilters)
      setFilters({ ...filters, [getCurrentSectionKey()]: resultFilters })
    } else if (event.action === 'clear') {
      setTempFilters({})
      setFilters({...filters, [getCurrentSectionKey()]: {}});
      setPage({...page, [getCurrentSectionKey()]: 0});
      setRange({...range, [getCurrentSectionKey()]: [0, 19]});
      setSort({...sort, [getCurrentSectionKey()]: ['', '']});
    }
  }

  const renderSearchOptions = options => {
    let selectOptions = {}

    return (
      <>
        <InputStyled className="selectContainer">
          <Select
            value={options}
            isMulti
            isSearchable={false}
            isClearable={true}
            placeholder={i18n.appBar.filters}
            onChange={selectedFiltersHandle}
            components={{ DropdownIndicator }}
            menuIsOpen={false}
            filterOption={(options, filter) => options.keywords.indexOf(filter.toLowerCase()) >= 0}
            {...selectOptions}
          />
        </InputStyled>
      </>
    )
  }

  const renderSearch = () => {
    let filtersCopy
    let options = []

    let currentFilters = filters[getCurrentSectionKey()]
    filtersCopy = _.cloneDeep(currentFilters)

    _.map(filtersCopy, label => {
      _.map(label, obj => {
        obj.label = (obj.filter && obj.filter.name ? obj.filter.name : obj.name) + ': ' + obj.label
      })

      options = !_.isEmpty(label) ? _.concat(options, label) : []
    })

    return (
      <Box display="flex" sx={{gap: 18, flexWrap: "wrap"}}>
        <RegularButton className="button-custom" title={i18n.job_offer_applications.filter}
                       onClick={handleOpenFilterModal}
        />
        {options.length > 0 ? renderSearchOptions(options) : null}
      </Box>
    )
  }

  const IAGenDebug = () => {
    const [showIADebug, setShowIADebug] = useState(false);
    return (
      <div>
        <ButtonWithIcon
          title={"Ver datos IA del Job"}
          onClick={() => setShowIADebug(!showIADebug)}
        />

        {showIADebug && <IAGenJobDebug jobOffer={jobOfferDetails} />}
      </div>
    );
  }

  return (
    <JobOfferApplicationsStyled>
      <div className="containerHeader">
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: 30}}>
              <BackButton title={i18n.job_offer_applications.backToJobOffers} onClick={goBackHandler}/>
              {inboxFilters && renderSearch()}
            </div>
          </Grid>

          <Grid item className="sectionSecond" xs={12} sm={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start", gridGap: '15px', maxWidth: "800px", }}>
              <h2>{!isLoadingJobOfferDetails && jobOfferDetails ? jobOfferDetails.name : ""}</h2>
            </Box>
          </Grid>

          {isIAGenEnabled() && AuthUtils.isDebugUser() && <IAGenDebug />}

          {(isLoading || isLoadingJobOfferDetails) && <LinearProgress/>}
          {<>
            {<Grid item xs={12} sm={12} >
              <MTToolbar
                module={module}
                totalRowsSelected={totalRowsSelected}
                totalRows={!isLoading && jobOfferApplications ? jobOfferApplications.total : 0}
                onItemClick={onItemClick}
                options={toolbarOptions}
                disabled={_.isEmpty(selectedRows)}
                onSubmitChangeFunnelStatus={onSubmitChangeFunnelStatus}
                funnelStatusTypes={statusFunnelTypes?.data ?? []}
              />
              <MaterialTable
                tableRef={tableRef}
                data={dataQuery}
                isLoading={isLoading}
                columns={columnsArray}
                options={tableOptions}
                onSelectionChange={checkRow}
                onRowClick={handleRowClick}
                onOrderChange={(orderedColumnId, orderDirection) => handleOrderChange(tableColumns[Object.keys(tableColumns)[orderedColumnId]], orderDirection)}
                components={{
                  Pagination: () => <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    rowsPerPage={perPage[getCurrentSectionKey()]}
                    count={!isLoading && jobOfferApplications ? jobOfferApplications.total : 0}
                    page={page[getCurrentSectionKey()]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePerPage}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                }}
              />
            </Grid>}
          </>
          }
        </Grid>
        {module !== MODULES.ID_EXIT_INTERVIEW &&
          <ModalAnnouncementQuery
            open={announcementModal}
            handleClose={() => setAnnouncementModal(false)}
            onSubmit={onSubmitAnnouncement}
            sms={client ? client.sms_available : false}
            self={self ? self : null}
            rowsSelected={totalRowsSelected}
            data={selectedRows}
            suggestChangeStatusModal={{isOpenModalSuggestChangeStatus, setIsOpenModalSuggestChangeStatus}}
          />}
        {module !== MODULES.ID_EXIT_INTERVIEW && FeatureGuard.canAccessToFunnelStatus() && <ModalSuggestChangeStatusAnnouncement
          open={isOpenModalSuggestChangeStatus}
          handleClose={() => setIsOpenModalSuggestChangeStatus(false)}
          i18n={i18n}
          statusFunnelTypes={statusFunnelTypes}
          module={module}
          data={selectedRows}
        />}
        {module !== MODULES.ID_EXIT_INTERVIEW && tagsPersonTypes && tagsPersonTypes.length > 0 &&
          <ModalChangeTagsQuery
            open={openChangeTagsModal}
            handleClose={() => setOpenChangeTagsModal(false)}
            rowsSelected={selectedRows}
            tagsTypes={tagsPersonTypes}
            currentTags={currentTags}
            onSubmit={onSubmitChangeTags}
          />
        }
        {module !== MODULES.ID_EXIT_INTERVIEW && <ModalRequireDocQuery
          open={requireDocModal}
          handleClose={() => setRequireDocModal(false)}
          onSubmit={onSubmitRequireDoc}
        />}

        <ModalFilterQuery
          handleSearchClick={handleSubmitFilters}
          open={openFilterModal}
          handleClose={handleCloseFilterModal}
          title={i18n.job_offers.filter_offers}
        >
          <FilterFormQuery module={module}
                           filters={inboxFilters}
                           filtersValues={tempFilters}
                           updateFiltersValues={updateTempFilters}
          />
        </ModalFilterQuery>
      </div>
    </JobOfferApplicationsStyled>
  );
};

const DropdownIndicator = () => null

export default JobOfferApplications;
