import React, {useEffect, useState} from 'react'
import {AppBarInbox, OnboardingProfileInfo, ProcessTable} from '../../organism'
import {getCurrentSectionKey, MODULES} from '../../../config/constants'
import useTotalCount from "../../../api/queries/useTotalCount";

const InboxOnboarding = ({ classes, filters, i18n }) => {

    const [overrideFilters, setOverrideFilters] = useState(null);
    const {data: totalRQuery, isFetched: isTotalRQueryFetched} = useTotalCount(
      MODULES.currentModule(),
      getCurrentSectionKey(),
      overrideFilters ? overrideFilters : filters
    );

    useEffect(() => {
        if (isTotalRQueryFetched) {
            if (localStorage.getItem('inboxFilter')) {
                let localStorageFilter = localStorage.getItem('inboxFilter');
                localStorage.removeItem('inboxFilter');
                setOverrideFilters(JSON.parse(localStorageFilter));
            }
        }
    }, [totalRQuery]);

    return (
      <div className={classes.root}>
        <AppBarInbox
          showActions
          filterName={i18n.processesTable.filterProcesses}
          module={MODULES.ID_ON_BOARDING}
          forceTotal={totalRQuery && totalRQuery.total ? totalRQuery.total : 0}
          section={"onboardingInbox"}
        />
        <ProcessTable
          title={i18n.onboardingInbox.title}
          filters={filters}
          module={MODULES.ID_ON_BOARDING}
          forceTotal={totalRQuery && totalRQuery.total ? totalRQuery.total : 0}
          overrideFilters={overrideFilters}
          rowViewRender={id => <OnboardingProfileInfo id={id} />}
        />
      </div>
    )
}

export default InboxOnboarding
