import { useQuery } from '@tanstack/react-query';
import { get } from '../utils';

async function getRegenerateUrl(module, testId, candidatureId) {
  return await get(`/api/module/${module}/evaluative-feature/regenerate/${testId}/${candidatureId}`);
}

export function useRegenerateUrl(module, testId, candidatureId) {
  return useQuery(
    [`${module}`, { module, testId, candidatureId }],
    () => getRegenerateUrl(module, testId, candidatureId),
    {
      enabled: false, 
    }
  );
}