import React, {Component} from 'react'
import {AppBarInbox, ProcessTable, ProfileInfo} from '../../organism'
import {MODULES} from '../../../config/constants'

class InboxPeople extends Component {

  render() {
    const { classes, filters, i18n } = this.props
    return (
      <div className={classes.root}>
        <AppBarInbox
          showActions
          filterName={i18n.appBar.filterPeople}
          module={MODULES.ID_TALENT_ACQUISITION}
          filters={filters}
          section={"inboxPeople"}
        />

        <ProcessTable
          title={i18n.inboxPeople.title}
          filters={filters}
          module={MODULES.ID_TALENT_ACQUISITION}
          showChangeStatus
          rowViewRender={id => <ProfileInfo id={id} />}
        />
      </div>
    )
  }
}

export default InboxPeople
