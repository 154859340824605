import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import compose from 'recompose/compose'
import AvatarMatchStyled from './styled'
import CircularProgress from '@material-ui/core/CircularProgress'
import i18n from '../../../assets/i18n'

const AvatarMatch = ({ value, imageUrl }) => {
  const divStyle = imageUrl
    ? {
        backgroundImage: 'url(' + imageUrl + ')',
        cursor: 'pointer'
      }
    : { cursor: 'default' }
  const onClickImage = imageUrl ? (e) => {
    window.open(imageUrl, '_blank')
    e.stopPropagation()
  } : null

  if (value < 0) value = 0

  return (
    <AvatarMatchStyled>
      <div className="avatar-wrapper">
        <div className="circle-wrapper">
          <CircularProgress variant="static" className={'top-circle'} size={155} value={100} />
          <CircularProgress variant="static" className={'bottom-circle'} size={155} value={value} />
          <div className="image-bg" style={divStyle} onClick={onClickImage}>
            {imageUrl ? <img src={imageUrl} alt="User" /> : ''}
          </div>
        </div>
        {(_.isNumber(value)) && 
          <>
            <p className="value-percent">{value}%</p>
            <p className="copy">{i18n.avatarMatch.match}</p>
          </>
        }
      </div>
    </AvatarMatchStyled>
  )
}

AvatarMatch.defaultProps = {
}

AvatarMatch.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  imageUrl: PropTypes.string
}

const enharce = compose()

export default enharce(AvatarMatch)
