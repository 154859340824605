import * as types from './types'

export const setIsFetching = isFetching => ({
  type: types.SET_IS_FETCHING,
  payload: isFetching
})

export const setToken = value => ({
  type: types.SET_AUTH_TOKEN,
  payload: value
})

export const logOut = () => ({
  type: types.LOG_OUT
})

export const setEmail = value => ({
  type: types.SET_EMAIL,
  payload: value
})

export const setId = value => ({
  type: types.SET_ID,
  payload: value
})

export const setLanguaje = value => ({
  type: types.SET_LANGUAJE,
  payload: value
})

export const setSesionLoaded = value => ({
  type: types.SET_SESION_LOADED,
  payload: value
})

export const setData = value => ({
  type: types.SET_DATA,
  payload: value
})

export const setUrl = value => ({
  type: types.SET_URL,
  payload: value
})

export const setClient = value => ({
  type: types.SET_CLIENT,
  payload: value
})
