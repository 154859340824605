import * as types from './types'

export const setIsFetching = isFetching => ({
  type: types.SET_IS_FETCHING,
  payload: isFetching
})

export const setGlobalData = data => ({
  type: types.SET_GLOBAL_DATA,
  payload: data
})

export const setPositionData = data => ({
  type: types.SET_POSITION_DATA,
  payload: data
})

export const setFilters = data => ({
  type: types.SET_FILTERS_AVAILABLE,
  payload: data
})

