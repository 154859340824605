import styled from 'styled-components'

export const TemplateRequestTableStyled = styled.div`
  .MuiTableCell-root {
    font-family: 'Poppins' !important;
    z-index: 0 !important;
  }
  .MuiTableHead-root th:first-child {
    text-align: center !important;
  }

  .MuiTableHead-root th:not(:first-child) {
    text-align: start !important;
  }

  .MuiTableHead-root th:nth-child(2),
  .MuiTableHead-root th:nth-child(6),
  .MuiTableHead-root th:nth-child(7) {
    text-align: center !important;
  }
`
