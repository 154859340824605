import View from './view'
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import styles from './styles'
import {withStyles} from "@material-ui/core/styles";

const mapStateToProps = (state) => {
  return {
    user_id: state.auth && state.auth.data ? state.auth.data.id : null,
    client: state.auth.client,
  }
}

const mapDispatchToProps = () => {
  return {}
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withStyles(styles)
)

export default enharce(View)
