import View from './view'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { withI18n } from '../../hocs'
import styles from './styles'
import { AuthOperations } from '../../../redux/auth'

const mapStateToProps = state => ({
  isFetching: state.auth.isFetching,
  self_data: state.auth.data,
  self_client: state.auth.client,
})

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchMe: () => dispatch(AuthOperations.fetchMe(props.history)),
    disconnectIntegration: (integration, id) => dispatch(AuthOperations.disconnectIntegration(integration, id)),
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n,
  withStyles(styles)
)

export default enharce(View)
