import * as types from './types'

export const setIsFetching = isFetching => ({
  type: types.SET_IS_FETCHING,
  payload: isFetching
})

export const setData = data => ({
  type: types.SET_DATA,
  payload: data
})

export const setTotalItems = totalItems => ({
  type: types.SET_TOTAL_ITEMS,
  payload: totalItems
})
