import styled from 'styled-components'
import colors from '../../../assets/colors'

const AvatarNameStyled = styled.div`
  .avatar-wrapper {
    display: flex;
    align-items: center;
  }

  .image-bg {
    width: 45px;
    height: 45px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-right: 15px;
    background-color: ${colors.greyAlpha26};
  }

  .name {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.03em;
    color: ${colors.black};
  }
`

export default AvatarNameStyled
