import React from 'react'

const icon = props => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.6">
            <rect x="3" y="12" width="6" height="9" rx="2.5" stroke="white" strokeWidth="2" strokeLinejoin="round" />
            <rect x="9" y="8" width="6" height="13" rx="2.5" stroke="white" strokeWidth="2" strokeLinejoin="round" />
            <rect x="15" y="3" width="6" height="18" rx="2.5" stroke="white" strokeWidth="2" strokeLinejoin="round" />
        </g>
    </svg>
)

export default icon
