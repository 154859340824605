import React, { useState } from 'react'
import compose from 'recompose/compose'
import Grid from '@material-ui/core/Grid'
import IAGenJobDebugStyled from './styled'
import {withI18n} from '../../hocs'
import { ButtonWithIcon } from '../../atoms'

const IAGenJobDebug = ({ i18n, jobOffer = {} }) => {
  const [showJsonResponse, setShowJsonResponse] = useState(false)

  const getCriteria = (item) => {
    if(item.ignore) {
      return i18n.ia_evaluation.relevance_level.ignore;
    } else if(item.discards) {
      return i18n.ia_evaluation.relevance_level.mandatory;
    } else if(item.relevant) {
      return i18n.ia_evaluation.relevance_level.relevant;
    } else {
      return i18n.ia_evaluation.relevance_level.desired;
    }
  }

  const renderScore = (option) => {
    const score = option.score || '-'
    const className = ['ia-score'];
    switch(score) {
      case "-":
        break;

      case "DISCARD":
        className.push("discard");
        break;

      default:
        className.push("ok");
        break;
    }
    return (
      <span className={className.join(' ')}>{score}</span>
    )
  }

  const RenderIADebug = () => {
    const ia_criteria = jobOffer.ia_criteria || null;

    if(!ia_criteria) {
      return <></>
    }

    return (
        <Grid container className={'grid-container'}>
          <Grid item xs={12}>
            <div className="header-table"><h3>Datos IA:</h3></div>
          </Grid>

          <Grid container item xs={12} md={6}>
            <Grid item xs={12}>
              <h4>Criterios extraídos por la IA</h4>

              <div className={'ia-evaluation'}>
                {ia_criteria.map((item, idx) => (
                  <div key={idx} className={'ia-criteria'}>
                    <h5>
                      {item.requirement} {item.offer_req_value ? `(${item.offer_req_value})` : ''}
                    </h5>

                    <p>{item.offer_req_details}</p>

                    {item.killer_question?.question &&
                    <>
                      <strong>{item.killer_question.question}</strong>
                      {item.killer_question?.options.length > 0 &&
                      <ul>
                        {item.killer_question.options.map((option, idxR) => (
                          <li key={idxR} style={{ 'margin-bottom': '1rem' }}>
                            <strong>{option.option || '-'}:</strong> {renderScore(option)}
                          </li>
                        ))}
                      </ul>}
                    </>
                    }
                  </div>
                ))}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={'ia-evaluation'}>
                <ButtonWithIcon
                  title={"Ver respuesta JSON"}
                  onClick={() => setShowJsonResponse(!showJsonResponse)}
                />

                {showJsonResponse && <pre style={{"margin": "1rem"}}>
                  {JSON.stringify(ia_criteria, null, 4)}
                </pre>}
              </div>
            </Grid>
          </Grid>

          <Grid container item xs={12} md={6}>
            <Grid item xs={12}>
              <div className="raw-wrapper">
                <h4>Datos de la oferta en la fuente</h4>

                <strong>Nombre del job:</strong>
                <div className="raw-field">
                  {jobOffer.name||''}
                </div>

                <strong>Descripción del job:</strong>
                <div className="raw-field">
                  {jobOffer.description||''}
                </div>

                <strong>Requisitos:</strong>
                <div className="raw-field">
                  {jobOffer.requirements||''}
                </div>

                <strong>Info adicional:</strong>
                <div className="raw-field">
                  {jobOffer.additional_info||''}
                </div>

                <strong>Ubicación:</strong>
                <div className="raw-field">
                  {jobOffer.location||''}
                </div>

                <strong>Killer questions:</strong>
                <div className="raw-field">
                  <pre>
                    {JSON.stringify(jobOffer.killer_questions|| [], null, 4)}
                  </pre>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
    )
  }

  return <>
      <IAGenJobDebugStyled>
        <RenderIADebug />

      </IAGenJobDebugStyled>
    </>
}

const enharce = compose(withI18n)
export default enharce(IAGenJobDebug)
