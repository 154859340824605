import React from 'react'
import _map from 'lodash/map'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import * as S from './styled'
import { DrawerListItem } from '../'

const SimpleMenu = ({ options, onChange, children, value, onClick, text, icon, color, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    onClick(event)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = id => {
    onChange(id)
    setAnchorEl(null)
  }

  return (
    <>
      {children ? (
        <children.type {...children.props} onClick={handleClick}>
          {children}
        </children.type>
      ) : (
        <DrawerListItem
          Icon={icon}
          onClick={handleClick}
          text={text}
          contentRight={options?anchorEl ? <ExpandLess style={{ fill: color }} /> : <ExpandMore style={{ fill: color }} />:null}
          {...rest}
        />
      )}
      {options &&
        <S.MenuContainer anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {_map(options, item => (
            <S.Item key={item.value} backgroundColorRow={item.backgroundColor} Icon={item.icon} selected={item.value === value} onClick={() => handleChange(item.value)} text={item.text} />
            ))}
        </S.MenuContainer>
      }
    </>
  )
}

SimpleMenu.defaultProps = {
  options: [],
  onChange: () => null,
  onClick: () => null
}

SimpleMenu.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  onClick: PropTypes.func
}

export default SimpleMenu
