import React, {useEffect, useMemo, useState} from 'react'
import HeatmapStatisticStyled from './styled'
import { ReactComponent as UserIcon } from '../../../assets/images/stats.svg'
import {Legend, Pie, PieChart, Sector} from "recharts";
import {isArray} from "lodash";
import i18n from '../../../assets/i18n'
import { getPaletteColorByIndex, useColorPalette } from '../use-color-palette'

const translate = (keyword) => {
  return i18n.dashboard.pie[keyword] || keyword;
}

const HeatmapStatistic = ({title = null, data = null, size = 300, unit = '%'}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [renderData, setRenderData] = useState([]);

  const palette = useColorPalette();

  useEffect(() => {
    if (isArray(data) && data.length > 0) {
      let dataWithColors = data.map((item, index) => {return {...item, fill: getPaletteColorByIndex(index, palette)}});
      setRenderData(dataWithColors);
    }
  }, [data]);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  }

  data.forEach((item) => (item.name = translate(item.name)));

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius}
                startAngle={startAngle} endAngle={endAngle} fill={fill}/>
        <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle}
                innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill}/>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
          {`${payload.name}`}
        </text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`${value} (${(percent * 100).toFixed(0)}${unit})`}
        </text>
      </g>
    );
  };

  const chart = useMemo(
    () => {
      return (<div className="pie-wrapper">
        <PieChart width={size} height={size}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={renderData}
            innerRadius={45}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
          />
          <Legend
            height={36}
            layout="horizontal"
          />
        </PieChart>
        </div>
      )
    }, [renderData, activeIndex]);

  return (
    <HeatmapStatisticStyled>
      {title &&
        <div className="header-stat">
          {<UserIcon />}
          <p>{title}</p>
        </div>
      }
      {chart}
    </HeatmapStatisticStyled>
  )
}

export default HeatmapStatistic
