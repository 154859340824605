import styled from 'styled-components'
import colors from '../../../assets/colors'

export const ModalStyled = styled.div`
    && {
        border-radius: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        width: 685px;
        outline: none;

        .error-msg {
            color: ${colors.red};
            margin-top: 10px;
            min-height: 20px;
        }

        .modal-body {
            background-color: ${colors.white};
            padding: 30px 50px;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            outline: none;
            .grid-decoration {
                position: relative;
                ::before {
                    content: '-';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -4px;
                }
            }
            label {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
            }

            .button-wrapper {
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 50px;

                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: right;
                    cursor: pointer;
                }

                button {
                    margin-left: 20px;
                }
            }
        }
    }
`
