import {useQuery} from '@tanstack/react-query';
import {MODULES, QUERY_KEYS} from "../../config/constants";
import {addItemCount} from "../index";
import * as FiltersUtils from "../../utils/filters";
import {get} from "../utils";


async function getInboxData({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  let [_key, {module, filters, filterGroup, rangeArr, sort}] = queryKey;
  if (!module || !filters || ![MODULES.ID_TALENT_ACQUISITION].includes(module)) return;

  filters = FiltersUtils.refactorSavedSearchFiltersByGroupSelected(filters, filterGroup);
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)

  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  const range = rangeArr ? encodeURI(JSON.stringify(rangeArr)) : ''
  sort = sort ? encodeURI(JSON.stringify(sort)) : ''

  const response = await get(`/api/module/${module}/inbox?filter=${filters}&range=${range}${sort ? `&sort=${sort}` : ''}`);

  response.data = addItemCount(response.data, rangeArr);
  return response;
}

export default function useSavedSearchesItem(module, id, filters, filterGroup, rangeArr, sort) {
  if (!filterGroup) filters = null;
  return useQuery([`${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${id}:${filterGroup}`, {module, filters, filterGroup, rangeArr, sort}], getInboxData);
}