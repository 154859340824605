/* eslint-disable react/display-name */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import {
  CardStatistic,
  DateRangePiker,
  TableFactorsValuesPercentages, BarChartStatistic
} from '../../molecules'
import {MTDate, MTText, PageTitle} from '../../atoms'
import i18n from '../../../assets/i18n'
import ProfileInfoStyled from './styled'
import Select from "react-select";
import _map from "lodash/map";
import MaterialTable from "material-table";
import {useAppContext} from "../../../context/appContext";
import {getCurrentSectionKey, MODULES} from "../../../config/constants";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from '@material-ui/core/Tooltip';
import FunnelStatisticLegacy from '../../molecules/funnel-statistic-legacy'

const OnboardingStatisticsItems = ({
                                     fetchGlobalStatistics,
                                     initStats,
                                     globalData,
                                     filtersAvailable,
                                     isFetching,
                                     history,
                                   }) => {

  const {module, clearContextIfNeeded, filters, setFilters} = useAppContext();

  const [focused, setFocused] = useState('');
  const [startDate, setStartDate] = useState(filters && filters[getCurrentSectionKey()]
    && filters[getCurrentSectionKey()].startDate
      ? filters[getCurrentSectionKey()].startDate
      : moment().add(-7, 'days')
  );
  const [endDate, setEndDate] = useState(filters && filters[getCurrentSectionKey()]
    && filters[getCurrentSectionKey()].endDate
      ? filters[getCurrentSectionKey()].endDate
      : moment()
  );

  const [existingFilters, setExistingFilters] = useState({});
  const [dependantOptions, setDependantOptions] = useState({});

  const [employeesWithoutOnboardingData, setEmployeesWithoutOnboardingData] = useState([]);
  const [employeeNumberFilter, setEmployeeNumberFilter] = useState(null);
  const [dniFilter, setDniFilter] = useState(null);
  const [isLeavingFilter, setIsLeavingFilter] = useState(true);
  const [isWorkingFilter, setIsWorkingFilter] = useState(true);
  const [employeesWithoutOnboardingFilteredData, setEmployeesWithoutOnboardingFilteredData] = useState([]);

  useEffect(() => {
    let resultData = employeesWithoutOnboardingData;
    if (employeeNumberFilter) {
      resultData = resultData.filter((item) => {
        return item.numberIdentity.includes(employeeNumberFilter);
      });
    }
    if (dniFilter) {
      resultData = resultData.filter((item) => {
        return item.dni.includes(dniFilter)
      });
    }
    // Si están los dos marcados no filtramos
    if(!isLeavingFilter || !isWorkingFilter) {
      resultData = resultData.filter((item) => {
        return isLeavingFilter ? item.leavingDate : !item.leavingDate
      });
      resultData = resultData.filter((item) => {
        return isWorkingFilter ? !item.leavingDate : item.leavingDate
      });
    }
    setEmployeesWithoutOnboardingFilteredData(resultData)
  }, [employeeNumberFilter, dniFilter, isLeavingFilter, isWorkingFilter]);

  useEffect(() => {
    setEmployeesWithoutOnboardingFilteredData(employeesWithoutOnboardingData);
  }, [employeesWithoutOnboardingData])

  useEffect(() => {
    const employees_without_onboarding = _.get(globalData, 'employees_without_onboarding', []);
    setEmployeesWithoutOnboardingData(employees_without_onboarding);
  }, [globalData])

  const getKeyByValue = (obj, value) => {
    return Object.keys(obj).find(key => {
      if (obj[key] && obj[key].remoteName) {
        return obj[key].remoteName === value;
      }
      return false;
    });
  }

  useEffect(() =>{
    if (filtersAvailable && filtersAvailable.hasOwnProperty('is_dependant_select_options')) {
      setDependantOptions(filtersAvailable.is_dependant_select_options);
    }
    setExistingFilters(filtersAvailable);
    let currentFilters = filters[getCurrentSectionKey()];
    if (currentFilters) {
      currentFilters.filtersAvailable = filtersAvailable;
      setFilters({...filters, [getCurrentSectionKey()]: currentFilters});
    }
  }, [filtersAvailable]);

  useEffect(() =>{
    if (filters && filters[getCurrentSectionKey()]
      && existingFilters && Object.keys(existingFilters).length
      && dependantOptions && Object.keys(dependantOptions).length
    ) {

      let currentStatFilters = filters[getCurrentSectionKey()];
      let responsablesOnFilter = currentStatFilters.responsable;
      let newExistingFilters = Object.assign({}, existingFilters);
      let tecnicoKey = getKeyByValue(newExistingFilters, 'tecnico');
      let centroKey = getKeyByValue(newExistingFilters, 'tienda_contrato');

      if (responsablesOnFilter) {
        let tecnicos = [];
        let centros = [];
        _map(responsablesOnFilter, responsable => {
          tecnicos = tecnicos.concat(Object.keys(dependantOptions[responsable]));
          _map(tecnicos, tecnico => {
            centros = centros.concat(dependantOptions[responsable][tecnico]);
          });
        });
        if(newExistingFilters[tecnicoKey].allOptions){
          tecnicos = tecnicos.map(id => id.toString());
          tecnicos = newExistingFilters[tecnicoKey].allOptions.filter(objeto => {
            return tecnicos.includes(objeto.id.toString());
          });
        }
        newExistingFilters[tecnicoKey] = {...newExistingFilters[tecnicoKey], options: tecnicos};

        if(newExistingFilters[centroKey].allOptions){
          centros = centros.map(id => id.toString());
          centros = newExistingFilters[centroKey].allOptions.filter(objeto => {
            return centros.includes(objeto.id.toString());
          });
        }
        newExistingFilters[centroKey] = {...newExistingFilters[centroKey], options: centros};
        setExistingFilters(newExistingFilters);
      } else {
        newExistingFilters[tecnicoKey] = {...newExistingFilters[tecnicoKey], options: []};
        newExistingFilters[centroKey] = {...newExistingFilters[centroKey], options: []};
        setExistingFilters(newExistingFilters);
      }
    }
  }, [filters]);

  useEffect(() => {
    clearContextIfNeeded(MODULES.currentModule());
  }, [module]);

  useEffect(() => {
    initStats({startDate, endDate}, module, filters[getCurrentSectionKey()], false);
  }, []);

  const getGlobalStatistics = () => fetchGlobalStatistics({startDate, endDate}, module, filters[getCurrentSectionKey()])

  const getDefaultValuesForFilters = (filter) => {
    if (filters && filters[getCurrentSectionKey()] && filters[getCurrentSectionKey()] && filters[getCurrentSectionKey()][filter.remoteName]) {
      let currentFilterValues = [];
      _map(filters[getCurrentSectionKey()][filter.remoteName], item => {
        currentFilterValues.push({label: item, value: item});
      })
      return currentFilterValues;
    }
    return null;
  }

  const addValueFilterActive = (remoteName, elementsSelected) => {
    let filtersActiveValue = filters[getCurrentSectionKey()];
    filtersActiveValue[remoteName] = _map(elementsSelected, element => element.value);
    if (filtersActiveValue[remoteName].length === 0) delete filtersActiveValue[remoteName];
    setFilters({...filters, [getCurrentSectionKey()]: filtersActiveValue});
  }

    const leaving = _.get(globalData, 'leaving', 0);
    const hiring = _.get(globalData, 'hiring', 0);
    const completed = Math.round(_.get(globalData, 'completed', 0));
    const ratio = _.get(globalData, 'ratio', 0);

    let phase_counter = _.get(globalData, 'phase_counter', []);
    phase_counter = phase_counter.map((item) => {
      return {phase: i18n.onboardingStatisticsItems.phase + ' ' + item.phase + ` (${item.value})`, value: item.value};
    });

    let funnel = _.get(globalData, 'funnel', []);
    let hot_issues = _.get(globalData, 'hot_issues', []);

    const columns = [
      { title: i18n.statisticsItems.name, field: 'name', render: row => <MTText value={row.name}/> },
      { title: i18n.statisticsItems.surname, field: 'surname', render: row => <MTText value={row.surname}/>  },
      { title: i18n.statisticsItems.dni, field: 'dni', render: row => <MTText value={row.dni}/>  },
      { title: i18n.statisticsItems.numberIdentity, field: 'numberIdentity', render: row => <MTText value={row.numberIdentity}/>  },
      { title: i18n.statisticsItems.hiringDate, field: 'hiringDate', render: row => row.hiringDate ? <MTDate date={row.hiringDate}/> : '-' },
      { title: i18n.statisticsItems.leavingDate, field: 'leavingDate', render: row => row.leavingDate ? <MTDate date={row.leavingDate}/> : '-'},
    ];

    const tableOptions = {
      emptyRowsWhenPaging: false,
      loadingType: 'linear',
      cellStyle: { textAlign: 'center' },
      pageSize: 20,
      pageSizeOptions: [20, 50, 100, 200],
    }

    return (
      <ProfileInfoStyled>
        <Grid container className="title-container">
          <Grid item xs={12} className="title">
            <PageTitle title={i18n.statistic.title} />
          </Grid>
          <Grid item xs={12} className="buttons-wrapper">
            <div className={"container-filters"+(isFetching ? ' is-fetching' : '') }>
              {existingFilters && _map(existingFilters, filter => {
                if (filter.name) {
                  return <Select
                    isMulti={true}
                    isSearchable={true}
                    isClearable={true}
                    key={filter.name}
                    placeholder={filter.name}
                    options={_map(filter.options, option => {
                      if(option instanceof String){
                        return {value: option, label:option}
                      }
                      return {value: option.id, label:option.label};
                    })}
                    onChange={e => addValueFilterActive(filter.remoteName, e)}
                    defaultValue={getDefaultValuesForFilters(filter)}
                    styles={{
                      control: base => ({
                        ...base,
                        minWidth: 160,
                        maxWidth: 480,
                        textAlign: 'left'
                      }),
                      menuList: base => ({
                        textAlign: 'left',
                        ...base,
                      })
                    }}
                  />}}
              )}

              <DateRangePiker
                startDate={startDate}
                onStartDateChange={startDateValue => {
                  setStartDate(startDateValue)
                  let date = moment(startDateValue, 'DD/MM/YYYY');
                  if (date.isValid()) {
                    setFilters({...filters, [getCurrentSectionKey()]: {...filters[getCurrentSectionKey()], startDate: startDateValue}})
                  }
                }}
                endDate={endDate}
                onEndDateChange={endDateValue => {
                  setEndDate(endDateValue)
                  let date = moment(endDateValue, 'DD/MM/YYYY');
                  if (date.isValid()) {
                    setFilters({...filters, [getCurrentSectionKey()]: {...filters[getCurrentSectionKey()], endDate: endDateValue}})
                  }
                }}
                focused={focused}
                onFocusChange={({focused}) => setFocused(focused)}
                isFetching={isFetching}
                onSubmit={getGlobalStatistics}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={4}>
            <Tooltip title="Número de contratados (en el periodo seleccionado)" placement="top" arrow>
              <div>
                <CardStatistic key={'h1'} title={i18n.onboardingStatisticsItems.hiring} value={hiring} />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Tooltip title="Porcentaje de personas que comenzaron el proceso y han terminado la fase 1" placement="top" arrow>
              <div>
                <CardStatistic key={'r3'} title={i18n.onboardingStatisticsItems.ratio} value={ratio} progressBar={true}/>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Tooltip title="Número de personas en proceso de onboarding (en cualquier fase)" placement="top" arrow>
              <div>
                <CardStatistic key={'c2'} title={i18n.onboardingStatisticsItems.inprogress} value={completed}/>
              </div>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={8}>
            <TableFactorsValuesPercentages data={hot_issues} tableTitle={i18n.onboardingStatisticsItems.hot_issues_title} tableSize={12} hasExtraValue={true} history={history} isOnboarding translation={i18n.onboardingStatisticsItems.hot_issues}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Tooltip title="Funnel de personas en onboarding durante el periodo seleccioando y bajas en periodo" placement="top" arrow>
              <div>
                <FunnelStatisticLegacy title={"Funnel"} funnel={funnel} extraData={leaving} />
              </div>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={12}>
            <Tooltip title="Empleados en proceso de onboarding por fase (dentro del periodo elegido)" placement="top" arrow>
              <div>
                <BarChartStatistic
                  title={i18n.statisticsItems.onboarding_phases}
                  data={phase_counter}
                  xDataKey="phase"
                  yDataKey="value"
                  label={i18n.statisticsItems.phases}
                  isOnboarding={true}
                />
              </div>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={12}>
            <MaterialTable
              title={
                <div>
                    <Tooltip title="Personas que no han realizado ninguna fase" placement="top" arrow>
                      <p style={{marginTop: 32, marginBottom: 0}}>{i18n.statisticsItems.employeesWithoutPhase1}</p>
                    </Tooltip>
                    <div style={{display: "flex", gap: 12}}>
                      <TextField id="standard-basic" label={i18n.statisticsItems.dni}
                                 onChange={(e) => {setDniFilter(e.target.value)}}/>
                      <TextField id="standard-basic" label={i18n.statisticsItems.numberIdentity}
                                 onChange={(e) => {setEmployeeNumberFilter(e.target.value)}}/>
                      <FormControlLabel
                        style={{marginTop: 12}}
                        control={
                          <Checkbox
                            checked={isWorkingFilter}
                            onChange={() => {setIsWorkingFilter(!isWorkingFilter)}}
                            name="isWorking"
                            color="primary"
                          />
                        }
                        label={i18n.statisticsItems.alta}
                      />
                      <FormControlLabel
                          style={{marginTop: 12}}
                          control={
                            <Checkbox
                                checked={isLeavingFilter}
                                onChange={() => {setIsLeavingFilter(!isLeavingFilter)}}
                                name="isLeaving"
                                color="primary"
                            />
                          }
                          label={i18n.statisticsItems.baja}
                      />
                </div>
              </div>
            }
            data={employeesWithoutOnboardingFilteredData}
            columns={columns}
            options={tableOptions}
            localization={i18n.tableLocalization}
           />
          </Grid>
        </Grid>
      </ProfileInfoStyled>
    )
}

OnboardingStatisticsItems.propTypes = {
  module: PropTypes.string,
  initStats: PropTypes.func,
  positionData: PropTypes.array
}

OnboardingStatisticsItems.defaultProps = {
  initStats: () => null
}

export default OnboardingStatisticsItems
/*


        <Grid container>
          <Grid item xs={12}>
            <TableStatisticFeedback data={hot_issues} isFetching={isFetching} title={i18n.feedbackStatisticsItems.category} />
          </Grid>
        </Grid>

 */
