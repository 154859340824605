import React from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { MTCircularPercent } from '../../atoms'
import InfoMatchStyled from './styled'


const InfoMatch = ({ i18n, totalMatch }) => {
    return (
        <InfoMatchStyled >
            <Grid>
                {_.isNumber(totalMatch) && <MTCircularPercent value={totalMatch} title={i18n.avatarMatch.match} />}
            </Grid>
        </InfoMatchStyled >
    )
}

InfoMatch.propTypes = {
    totalMatch: PropTypes.number,
}

const enharce = compose(withI18n)

export default enharce(InfoMatch)
