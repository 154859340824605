import 'core-js'
import _ from 'lodash'
import fileDownload from 'js-file-download'
import {CitationsActions} from './'
import {InboxOperations} from '../inbox'
import {StatusType} from '../../models'
import {setTagsTypes} from "../processes/actions";
import {ProcessesActions} from "../processes";
import * as api from "../../api/index";

export const initPlanning = (filters, sort, range, module) => (dispatch, getState, {api}) => {
  dispatch(CitationsActions.setData({data: [], total: 0}))
  dispatch(CitationsActions.setIsFetching(true))
  Promise.all([api.getReportFields(module), api.getTagsTypes(module), api.getCitationsData(filters, range, sort)])
    .then(([reportFields, tags, citations]) => {
      dispatch(ProcessesActions.setReportFields(reportFields))
      dispatch(CitationsActions.setData(citations))
      dispatch(setTagsTypes(tags))
    })
    .finally(() => {
      dispatch(CitationsActions.setIsFetching(false))
    })
}

export const fetchCitationsData = (filters, range, sort) => (dispatch, getState, {api, handleError}) => {
  dispatch(CitationsActions.setIsFetching(true))
  return api
    .getCitationsData(filters, range, sort)
    .then(reponse => {
      dispatch(CitationsActions.setData(reponse))
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(CitationsActions.setIsFetching(false)))
}

export const postCitations = (ids, data) => (dispatch, getState, {api, handleError, toast, i18n}) => {
  dispatch(CitationsActions.setIsFetching(true))

  const promises = _.map(ids, id =>
    api.postCitation({
      ...data,
      candidature: {id}
    })
  )

  return Promise.all(promises)
    .then(() => {
      toast(i18n.citations.citationCreated, {type: toast.TYPE.SUCCESS})
      return dispatch(InboxOperations.changeStatus(ids, StatusType.TO_BE_CONFIRMED))
    })
    .then(() => {
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(CitationsActions.setIsFetching(false)))
}

export const patchCitations = (ids, data, candidatureIds) => (dispatch, getState, {api, handleError, toast, i18n}) => {
  dispatch(CitationsActions.setIsFetching(true))
  const promises = _.map(ids, id => {
      api.patchCitation({id, ...data})
    }
  )

  return Promise.all(promises)
    .then(() => {
      toast(i18n.citations.citationCreated, {type: toast.TYPE.SUCCESS})
      return dispatch(InboxOperations.changeStatus(candidatureIds, StatusType.TO_BE_CONFIRMED))
    })
    .then(() => {
    }).catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(CitationsActions.setIsFetching(false)))
}

export const getEditCitationsCounter = (ids = []) => (dispatch) => {
  dispatch(CitationsActions.setIsFetching(true));
  return api.getEditCitationsCounter(ids)
    .then(res => dispatch(CitationsActions.setEditCounter(res)))
    .finally(() => dispatch(CitationsActions.setIsFetching(false)));
}

export const fetchCitationsResponsible = () => (dispatch, getState, {api, handleError}) => {
  dispatch(CitationsActions.setIsFetching(true))

  return api
    .getCitationsResponsible()
    .then(reponse => dispatch(CitationsActions.setResponsible(reponse)))
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(CitationsActions.setIsFetching(false)))
}

export const exportCsv = (fields, filters, sort, module) => (dispatch, getState, {api}) => {
  return api.getInboxCsv(fields, filters, sort, module).then(response => {
    fileDownload(new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), `inbox_${module}.xlsx`)
  })
}

export const postFavorites = (ids, isFavorite, module) => (dispatch, getState, {api}) => {
  dispatch(dispatch(CitationsActions.setIsFetching(true)))
  const fetchFav = isFavorite ? api.postFavorites : api.deleteFavorites
  return fetchFav(ids, module).finally(() => dispatch(CitationsActions.setIsFetching(false)))
}

export const changeStatus = (ids, status, date, module) => (dispatch, getState, {api, handleError}) => {
  dispatch(CitationsActions.setIsFetching(true))
  const newStatus = _.map(ids, id => {
    return {id, status_id: status, date}
  })
  return api
    .changeStatus(newStatus, module)
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(CitationsActions.setIsFetching(false)))
}

export const fetchMeetingDetails = (id) => (dispatch, getState, {api, handleError}) => {
    dispatch(CitationsActions.setIsFetching(true))
    return api
        .getMeetingDetails(id)
        .then(response => dispatch(CitationsActions.setMeetingDetails(response)))
        .catch(error => {
        dispatch(handleError(error))
        throw error
        })
        .finally(() => dispatch(CitationsActions.setIsFetching(false)))
}
