import React from 'react'
import {OnboardingProfileInfo} from '../../organism'
import ProfileStyled from './styled'

const Profile = () => {
  return (
    <ProfileStyled>
      <OnboardingProfileInfo/>
    </ProfileStyled>
  )
}

export default Profile
