import View from './view';
import compose from 'recompose/compose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withI18n} from '../../hocs'
import {FavoritesOperations} from '../../../redux/favorites'
import {CitationsActions, CitationsOperations} from '../../../redux/citations'
import {SaveSearchesOperations} from "../../../redux/savesearches";
import {ProcessesOperations} from "../../../redux/processes";

const mapStateToProps = state => {

  return {
    data: state.citations.data,
    isFetching: state.citations.isFetching,
    filters: state.saveSearches.filters,
    page: state.citations.page,
    sort: state.citations.sort,
    total: state.citations.data ? state.citations.data.total : 0,
    pageSize: state.citations.pageSize,
    reportFields: state.processes.reportFields,
    client: state.auth.client,
    auth: state.auth.data,
    rowsSelected: state.citations.rowsSelected,
    inboxModule: state.processes.module,
    tagsTypes: state.processes.tagsTypes,
    user_email: state.auth.email,
    responsibles: state.citations.responsible
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCitationsData: (filters, range, sort) => dispatch(CitationsOperations.fetchCitationsData(filters, range, sort)),
    postFavorites: (ids, module) => dispatch(CitationsOperations.postFavorites(ids, module)),
    deleteFavorites: ids => dispatch(FavoritesOperations.deleteFavorites(ids)),
    changeStatus: (ids, status, date, module) => dispatch(CitationsOperations.changeStatus(ids, status, date, module)),
    postCitations: (ids, data) => dispatch(CitationsOperations.postCitations(ids, data)),
    patchCitations: (ids, data, candidatureIds) => dispatch(CitationsOperations.patchCitations(ids, data, candidatureIds)),
    setFilters: filters => dispatch(SaveSearchesOperations.setFilters(filters)),
    setPage: page => dispatch(CitationsActions.setPage(page)),
    setSort: sort => dispatch(CitationsActions.setSort(sort)),
    setPageSize: pageSize => dispatch(CitationsActions.setPageSize(pageSize)),
    exportCsv: (fields, filters, sort, module) => dispatch(CitationsOperations.exportCsv(fields, filters, sort, module)),
    initPlanning: (filters, sort, range, module) => dispatch(CitationsOperations.initPlanning(filters, sort, range, module)),
    filtersClear: () => dispatch(SaveSearchesOperations.filtersClear()),
    setRowsSelected: (rowsSelected) => dispatch(CitationsActions.setRowsSelected(rowsSelected)),
    requireDocumentation: (ids, queryParams, module) => dispatch(ProcessesOperations.requireDoc(ids, queryParams, module)),
    changeTags: (ids, tags, queryParams, module, showFilterGroup) => dispatch(ProcessesOperations.changeTags(ids, tags, queryParams, module, showFilterGroup)),
    fetchTagsTypes: ( module ) => dispatch(ProcessesOperations.refetchTagsTypes( module ))
  }
}

const enharce = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withI18n
)

export default enharce(View);
