import React from 'react'

const icon = props => (
  <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.4999 10C3.4999 9.44771 3.94761 9 4.4999 9L7.4999 9L7.4999 21L4.4999 21C3.94761 21 3.4999 20.5523 3.4999 20L3.4999 10Z"
      stroke="#37BC6A" strokeWidth="1.5"/>
    <path
      d="M9.74412 20.4961L7.4999 19L7.4999 11L8.39129 11C8.76513 11 9.10775 10.7915 9.27951 10.4594L12.2118 4.79038C12.9947 3.27664 15.2945 4.03366 15.0253 5.7165L14.6852 7.84201C14.588 8.44959 15.0573 9 15.6726 9L19.0403 9C20.3089 9 21.257 10.1659 20.9982 11.4079L19.4974 18.6119C19.2076 20.003 17.9815 21 16.5605 21L11.4082 21C10.8159 21 10.2369 20.8247 9.74412 20.4961Z"
      stroke="#37BC6A" strokeWidth="1.5"/>
  </svg>
)

export default icon