import styled from 'styled-components'

const OfferCardStyled = styled.div`
  .expired_push_offer {
    opacity: 0.6;
  }
  
  .card_container {
  
    &__card {
      min-height: 320px;
      border-radius: 5px;
      box-shadow: 3px 5px rgba(0, 0, 0, 0.2) !important;
      cursor: pointer;
      margin: 15px 0 0;
  
      &-header {
        border-bottom: solid 1px lightgray;
        display: block !important;
  
        &-date_range {
          margin-bottom: 5px;
        }
  
        &-interested {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
  
          span {
  
            &.state-icons {
              position: absolute;
              text-align: right;
              width: 80%;
              margin-top: -9px;
  
              svg{
                width: 30px;
                height: 30px;
                margin-left: 10px;
              }
            }
  
          }
        }
  
        .MuiCardHeader-content {
  
          .MuiTypography-h5 {
            font-size: 14px;
            font-weight: 600;
          }
  
          .MuiTypography-body1 {
            font-size: 12px;
            font-weight: 600;
          }
  
          .MuiCardHeader-title {
            margin-bottom: 3px;
            display: flex;
            align-items: center;
            gap: 8px;
  
            span {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
  
          .MuiCardHeader-subheader {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
          }
        }
      }
  
      &-content {
        min-height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
  
        &-description {
          &-text {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 5em;
            line-height: 1.8em;
          }
        }
  
        &-filters {
          display: flex;
          flex-direction: row-reverse;
          flex-wrap: wrap-reverse;
  
          button {
            background-color: #eaeaea;
            pointer-events: none;
            margin: 2px;
            font-size: 0.9em;
          }
        }
      }
    }
  }

`

export default OfferCardStyled
