import React from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import BoxPDAInfoStyled from './styled'
import { SkillProfile, TitleLine } from "../../atoms"
import FileIcon from '../../../assets/images/file.svg'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'

const BoxPDAInfo = ({ pdaFields, i18n }) => {
  if (!pdaFields) {
    return null
  }

  const getKeyTranslation = (key) => {
    return i18n.pdaKeys[key];
  }

  return (
    <BoxPDAInfoStyled>
      <div className="title-line">
        <TitleLine title={<img alt="PDA Integration" className="pda-logo" src={require('../../../assets/images/pda.svg')} />} />
      </div>
      <Grid container alignItems="center" justifyContent="space-around" >
        <SkillProfile key={'match'} title={i18n.pda.match} value={Math.round(pdaFields.match)} />
        {pdaFields.report && (<>
          <div className="no-print">
            <a href={pdaFields.report} download>
              <Tooltip title="Descargar informe">
                <img src={FileIcon} alt={"Descargar informe"} className='pdf' />
              </Tooltip>
            </a>
          </div>
          <div className="values-pda">
            {Object.keys(pdaFields.repna).map((key, index) => (
              <div key={index} className='container-repna'>
                <span className='values-repna'> {getKeyTranslation(key)} </span>
                <Chip className='pda-repna' variant="outlined" label={pdaFields.repna[key]}></Chip>
              </div>
            ))}
          </div>
        </>)
        }
      </Grid>
    </BoxPDAInfoStyled>
  )
}

BoxPDAInfo.propTypes = {
  i18n: PropTypes.object.isRequired,
  fields: PropTypes.object,
  pdaFields: PropTypes.object,
}

const enharce = compose(withI18n)

export default enharce(BoxPDAInfo)
